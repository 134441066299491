import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Box, Button, Card, CardHeader, Divider } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: 350,
        },
    },
}));

export default function BasicTextFields() {
    const classes = useStyles();

    const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const { gilad, jason, antoine } = state;
    const error = [gilad, jason, antoine].filter((v) => v).length !== 2;


    return (
        <div>
            <Card>
                <CardHeader
                    subheader="The information will be added"
                    title="Admin Profile Details"
                />

                <Divider />



                <form className={classes.root} noValidate autoComplete="off">
                    <div style={{ margin: 10 }}>
                        <TextField
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            helperText="please enter your name "
                            style={{ width: 350 }}
                        />
                    </div>
                    <div style={{ margin: 10 }}>
                        <TextField
                            id="outlined-basic"
                            label="email"
                            variant="outlined"
                            helperText="please enter your email "
                            style={{ width: 350 }}
                        />
                    </div>
                    <div style={{ margin: 10 }}>
                        <TextField
                            id="outlined-basic"
                            label="Mobile"
                            variant="outlined"
                            helperText="please enter your Mobile "
                            style={{ width: 350 }}
                        />
                    </div>
                    <div style={{margin:10}}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Assign responsibility</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={gilad} onChange={handleChange} name="gilad" />}
                                    label="Content Uploader"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={jason} onChange={handleChange} name="jason" />}
                                    label="Content Finder"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={antoine} onChange={handleChange} name="antoine" />}
                                    label="Content Maker"
                                />
                            </FormGroup>
                            <FormHelperText>Be careful</FormHelperText>
                        </FormControl>
                    </div>
                </form>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ margin: 10 }}
                    // onClick={() => { UpdateUserDetails() }}
                    >
                        Save details
                    </Button>
                </Box>

            </Card>
        </div>
    );
}