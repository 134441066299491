import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles, useStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
// import LeftCard from "./slots/LeftCard";
import { Helmet } from "react-helmet";
import { Container } from "@material-ui/core";
import { Route, Link, HashRouter, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { listBoardGet } from "../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../redux/actions/InstituteDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import { listStdGet } from "../../redux/actions/standardAction";
import Menu from "@material-ui/core/Menu";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { listContUsers, listPubUsers } from "../../redux/actions/PubUsersListAction";
import { listSubGet } from "../../redux/actions/subjectsAction";
import Cookie from "js-cookie";
import PublisherAPI from "../../http/publisher";
import { Alert, AlertTitle } from "@material-ui/lab";
import Leftbar from "./slots/Leftbar";
import Copyright from "../user/slots/Copyright";

import FormHelperText from '@material-ui/core/FormHelperText';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import Tour from 'reactour'

const steps = [
  {
    selector: '[data-tut="first-step"]',
    content: 'The various filters help confine the results',
  },
  {
    selector: '[data-tut="second-step"]',
    content: 'The first list displays individual subscribers to content',
  },
  {
    selector: '[data-tut="third-step"]',
    content: 'The second list displays institutions that subscribed to content',
  },

  // ...
];

function createData(name, subject, standard, carbs, protein) {
  return { name, subject, standard, carbs, protein };
}

function Copyright2() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://kgtopg.com/">
        KGToPG
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 250;

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: "flex",
    scrollbarWidth: "thin"
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbar1: {
    paddingRight: 24,
    height: 40,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon1: {
    top: "45vh",
    bottom: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft:70,
    backgroundColor: "#1976d2",
    color: "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "blue",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    scrollbarColor: "green",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  large: {
    width: "80%",
    height: 50,
    borderRadius: 0,
  },
}));

const useStyles3 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  root1: {
    maxWidth: 200,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(3),
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  formControl1: {
    margin: theme.spacing(1),
    minWidth: 320,
    minHeight: 30,
  },
  primary: {
    width: "25%",
    height: 50,
  },
}));

const useStyles4 = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const rows = [
  createData("Andhra Pradesh Govt.Schools", "Maths", 10),
  createData("Telegana Govt.Schools", "Maths", 10),
  createData("Delhi Public School", "Maths", 10),
  createData("Oakridge International School", "Maths", 10),
  createData("Jubilee Hills Public School", "Maths", 10),
  createData("Glendale Academy", "Maths", 10),
  createData("Bharatiya Vidya Bhavan's Public School", "Maths", 10),
  createData("Andhra Pradesh Govt.Schools1", "Maths", 10),
  createData("Telegana Govt.Schools1", "Maths", 10),
  createData("Delhi Public School1", "Maths", 10),
  createData("Oakridge International Schoo1", "Maths", 10),
  createData("Jubilee Hills Public School1", "Maths", 10),
  createData("Glendale Academy1", "Maths", 10),
  createData("Bharatiya Vidya Bhavan's Public School1", "Maths", 10),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis =
    array.length > 0 ? array.map((el, index) => [el, index]) : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "User",
  },
  { id: "Subject", numeric: false, disablePadding: false, label: "Subject" },
  { id: "Standard", numeric: false, disablePadding: false, label: "Standard" },
  { id: "Action", numeric: false, disablePadding: false, label: "Status" },
  // { id: "Action2", numeric: false, disablePadding: false, label: "Action" },
];

const headCells2 = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "State",
  },
  { id: "User Count", numeric: false, disablePadding: false, label: "User Count" },
  { id: "Active", numeric: false, disablePadding: false, label: "Active" },
  { id: "Inactive", numeric: false, disablePadding: false, label: "Inactive" },
  // { id: "Action2", numeric: false, disablePadding: false, label: "Action" },
];

function EnhancedTableHead2(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
        </TableCell>
        {headCells2.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Users List
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const FilterFields = (props) => {
  const classes = useStyles3();
  const [instType, setInstType] = useState("");
  const [boardType, setBoardType] = useState("");
  const [subjectType, setSubjectType] = useState("");
  const [standardType, setStandardType] = useState("");
  const [pubId, setPubId] = useState(2);

  const dispatch = useDispatch();

  const handleInstChange = (event) => {
    console.log("called handleInstChange");
    console.log(`new institute type ${event}`);
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });
    dispatch(listBoardGet(value));
    setInstType(value);
    dispatch(listContUsers(value, boardType, standardType, subjectType));

  };

  const handleBoardChange = (event) => {
    console.log("called handleBoardChange");
    console.log(`new board  ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`);

    setBoardType(value);
    dispatch(listStdGet(instType, value));
    dispatch(listSubGet(value));
    dispatch(listContUsers(instType, value, standardType, subjectType));

  };

  const handleSubChange = (event) => {
    console.log("called handleSubChange");
    console.log(`new subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType(value);

    if (value) {
      dispatch(listContUsers(instType, boardType, standardType, value));
    }
  };

  const handleStdChange = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setStandardType(value);
    //dispatch(listSubGet(instType))

    const pub_id = pubId;
    // if(value){
    //   console.log("handlestdChange ==============================",instType,boardType,value,pub_id)
    //   dispatch(listSearchEbookGet(instType,boardType,value,pub_id))
    // }
    dispatch(listContUsers(instType, boardType, value, subjectType));

  };
  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;

  const instDetailsGet = useSelector((state) => state.instDetails);
  const { loadingInst, instDetails, errorInst } = instDetailsGet;

  const subDetailsGet = useSelector((state) => state.subDetails);
  const { loadingSub, subDetails, errorSub } = subDetailsGet;

  const stdDetailsGet = useSelector((state) => state.stdDetails);
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

  const contUsersListGet = useSelector((state) => state.contUsersGet);
  const { loadingContUsers, contUsers, errorContUsers } = contUsersListGet;

  {
    loadingContUsers && contUsersListGet
      ? console.log("users data is loading")
      : console.log("users data is ", contUsers);
  }

  let rows = [];
  if (!loadingContUsers && contUsersListGet && contUsers && contUsers.data) {
    contUsers.data.map((users, index) => {
      if (users && users.status) {
        users.status.map((usersList, index) => {
          rows.push(usersList);
        });
      }
    });
  }
  // props.usersList(rows)


  useEffect(() => {
    dispatch(listInstGet());
    return () => {
      //
    };
  }, []);

  return (
    <Paper elevation={2} style={{ marginLeft: 10 }} >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
          overflow: "auto",
          overflowX: "initial",
          scrollbarWidth: "thin"
          // flexWrap:"wrap"
          //  marginLeft:20
        }}
      >

        {/* <div > */}

        {/* <div style={{ display: "flex", justifyContent: "center" ,marginTop:10}}>


                          <TextField 
                          id="outlined-basic" 
                          label="Search by Name" 
                          variant="outlined"
                          defaultValue={title}
                          onChange={handleTitleChange}
                    
                              style={{
                                  width:250,
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  display:"inline-block",
                                  lineHeight:"140%"

                    
                              }}
                              
                          />
                      </div>

                      <div style={{ display: "flex", justifyContent: "Left" }}>
                      <Typography>
                              <span
                                  style={{
                                      fontSize: 12,
                                      color: "#464E5F",
                                      marginLeft: 5,
                                      fontFamily: "Poppins, Helvetica, sans-serif",
                                  }}
                              >
                                  Search by  <span
                                      style={{
                                          color: "#464E5F",
                                          fontWeight: "600",
                                          fontFamily: "Poppins, Helvetica, sans-serif",
                                          fontSize: 12
                                      }}>
                                    Name
                          </span>
                              </span>
                          </Typography>

                          </div>

                  </div>

*/}

        {instDetailsGet ? (
          <div style={{ display: "flex" }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Institute Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={instType}
                onChange={handleInstChange}
                label="Institute Type"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {loadingInst && instDetailsGet ? (
                  console.log("loading trueeee")
                ) : instDetails &&
                  instDetails.data &&
                  instDetails.data.length != 0 ? (
                  instDetails.data.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.institute_type_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {list.institute_type_name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Institutes
                  </MenuItem>
                )}
              </Select>

              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Institute
                </span>
              </span>
            </FormControl>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Institute Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={instType}
                onChange={handleInstChange}
                label="Institute Type"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                <MenuItem
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  No Institutes
                </MenuItem>
              </Select>

              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Institute
                </span>
              </span>
            </FormControl>
          </div>
        )}

        {boardDetailsGet &&
          boardDetails &&
          boardDetails.length != 0 &&
          boardDetails.data ? (
          <div style={{ display: "flex" }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Board
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={boardType}
                onChange={handleBoardChange}
                label="Board"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {
                  loading && boardDetailsGet ? (
                    console.log("loading trueeee")
                  ) : boardDetails && boardDetails.length != 0 ? (
                    boardDetails.data.map((list, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={list.institute_board_id}
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {list.institute_board_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  )

                  // boardDetailsMock.map((list, index) => {
                  //     return (
                  //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                  //     )
                  // })
                }
              </Select>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Board
                </span>
              </span>
            </FormControl>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Board
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={boardType}
                onChange={handleBoardChange}
                label="Board"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                <MenuItem
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  No Boards{" "}
                </MenuItem>
              </Select>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Board
                </span>
              </span>
            </FormControl>
          </div>
        )}

        {stdDetailsGet ? (
          <div style={{ display: "flex" }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Standard
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={standardType}
                onChange={handleStdChange}
                label="Standard"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {
                  loadingStd && stdDetailsGet ? (
                    console.log("loading trueeee")
                  ) : stdDetails.length != 0 ? (
                    stdDetails.data.map((list, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={list.id}
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {list.standard}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  )

                  // standardsMockData.map((list, index) => {
                  //     return (
                  //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.stand_name}</MenuItem>
                  //     )
                  // })
                }
              </Select>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Standard
                </span>
              </span>
            </FormControl>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Standard
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={standardType}
                onChange={handleStdChange}
                label="Standard"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                <MenuItem
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  No Standards
                </MenuItem>
              </Select>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Standard
                </span>
              </span>
            </FormControl>
          </div>
        )}

        {subDetails ? (
          <div style={{ display: "flex" }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subjectType}
                onChange={handleSubChange}
                label="Subject"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {loadingSub && subDetails ? (
                  <MenuItem
                    key={0}
                    value="Please Select Board"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    {" "}
                    Please Select Board{" "}
                  </MenuItem>
                ) : subDetails &&
                  subDetails.data &&
                  subDetails.data.subjects.length != 0 ? (
                  subDetails.data.subjects.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.subject_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {list.subject_name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please Select Institute Type
                  </MenuItem>
                )}
              </Select>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Subject
                </span>
              </span>
            </FormControl>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subjectType}
                onChange={handleSubChange}
                label="Subject"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                <MenuItem
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  No Subjects{" "}
                </MenuItem>
              </Select>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Subject
                </span>
              </span>
            </FormControl>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default function UsersListCont() {
  const classes = useStyles2();
  const classes1 = useStyles1();
  const classes4 = useStyles4();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows1, setRows] = useState("");
  const [isTourOpen, setIsTourOpen] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleTourOpen = () => {
    setIsTourOpen(true)
  }

  const contUsersListGet = useSelector((state) => state.contUsersGet);
  const { loadingContUsers, contUsers, errorContUsers } = contUsersListGet;

  {
    loadingContUsers && contUsersListGet
      ? console.log("users data is loading")
      : console.log("users data is ", contUsers);
  }

  let dynrows = [];
  if (!loadingContUsers && contUsersListGet && contUsers && contUsers.data) {
    contUsers.data.map((users, index) => {
      users.status.map((usersList, index) => {
        dynrows.push(usersList);
      });
    });
  }

  console.log("dynRowsssss=========", dynrows);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangeStatus = async (row, stat) => {
    const userId = Cookie.get("kgtopg.partner.user.id");
    const body = {
      publisher_id: userId,
      sub_id: row.subject_id,
      staff_id: row.staff_id,
      classroom_id: row.classroom_id,
      partner_status: stat,
    };

    const resp = await PublisherAPI.postSaveApproveStatus(body);
    // window.location.reload()

    window.alert("Status updated..");
    // dispatch(listPubUsers(props.instType, props.boardType, props.standardType, props.subjectType))
  };

  return (
    <div className={classes.root}>
      <div style={{
        position: 'absolute',
        top: 0,
        right: 0
      }}>
        <LiveHelpIcon
          onClick={handleTourOpen} />
      </div>


      <Leftbar />
      <main className={classes.content}>
        <Helmet>
          <title>Subscribers | Pustak Pubish</title>
        </Helmet>
        <Container
          className={classes.container}
          style={{
            minHeight: "100%",
          }}
        >
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              minHeight: "90%",
              width: "100%",
            }}
          >
            <div style={{ marginTop: 20, marginBottom: 15 }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: 10
                }}>
                <Typography
                  // className={classes.title}
                  id="tableTitle"
                  component="div"
                  /* style={{
                    fontSize: 16,
                    fontWeight: "500",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }} */
                  variant="h3"
                >
                  Subscribers
                </Typography>
              </div>


              <div data-tut="first-step">
                <FilterFields />
              </div>
              {/* usersList={(data) => {setRows(data)}} */}
            </div>

            <div className={classes1.root}
              style={{
                padding: 10,
                display: 'flex',
                flexDirection: 'column'
              }}>

              <div data-tut="second-step">
                <Paper className={classes1.paper}
                >
                  <div
                    style={{
                      padding: 20,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <Typography
                      variant="h4">
                      Individuals
                  </Typography>


                    <FormControl className={classes4.formControl}>
                      <InputLabel id="demo-simple-select-label">Country</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      // value={age}
                      // onChange={handleChange}
                      >
                        <MenuItem value={`India`}>India</MenuItem>
                        <MenuItem value={`Australia`}>Australia</MenuItem>
                        <MenuItem value={`USA`}>USA</MenuItem>
                      </Select>
                    </FormControl>
                  </div>


                  {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

                  {dynrows && dynrows.length ? (
                    <>
                      <TableContainer>
                        <Table
                          className={classes1.table}
                          aria-labelledby="tableTitle"
                          size={dense ? "small" : "medium"}
                          aria-label="enhanced table"
                        >
                          <EnhancedTableHead
                            classes={classes1}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody>
                            {stableSort(dynrows, getComparator(order, orderBy))
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                const isItemSelected = isSelected(
                                  row.institute_name
                                );
                                const labelId = `enhanced-table-checkbox-${index}`;

                                const pending = row.partner_ebook_id == 0;
                                const approved = row.partner_ebook_id == 1;
                                const declined = row.partner_ebook_id == 2;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.institute_name}
                                    selected={isItemSelected}
                                  >
                                    <TableCell padding="checkbox">
                                      {/* <Checkbox
                                      checked={isItemSelected}
                                      onClick={(event) =>
                                        handleClick(event, row.institute_name)
                                      }
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    /> */}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                      align="center"
                                    >
                                      {row.institute_name}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                      align="center"
                                    >
                                      {row.subject_name}
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                      align="center"
                                    >
                                      {row.classroom_name}
                                    </TableCell>
                                    {/* 
                                  <FormControl className={classes.margin}>
                                    <InputLabel id="demo-customized-select-label">Age</InputLabel>
                                    <Select
                                      labelId="demo-customized-select-label"
                                      id="demo-customized-select"
                                      value={age}
                                      onChange={handleChange}
                                      input={<BootstrapInput />}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      <MenuItem value={10}>Ten</MenuItem>
                                      <MenuItem value={20}>Twenty</MenuItem>
                                      <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                  </FormControl> */}

                                    {pending ? (
                                      <TableCell align="center">
                                        <Button
                                          onClick={() => {
                                            // handleChangeStatus(row, 1);
                                          }}
                                          variant="contained"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Active
                                      </Button>
                                      </TableCell>
                                    ) : approved ? (
                                      <TableCell align="center">
                                        <Button
                                          variant="contained"
                                          style={{
                                            backgroundColor: "green",
                                            color: "#fff",
                                          }}
                                        >
                                          Active
                                      </Button>
                                      </TableCell>
                                    ) : (
                                      <TableCell align="center">
                                        <Button variant="contained" disabled>
                                          Inactive
                                      </Button>
                                      </TableCell>
                                    )}

                                    {/* {pending ? (
                                    <TableCell align="center">
                                      <Button
                                        onClick={() => {
                                          // handleChangeStatus(row, 2);
                                        }}
                                        variant="contained"
                                        color="secondary"
                                        style={{ color: "#fff" }}
                                      >
                                        InActive
                                      </Button>
                                    </TableCell>
                                  ) : declined ? (
                                    <TableCell align="center">
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ color: "#fff" }}
                                      >
                                        InActive
                                      </Button>
                                    </TableCell>
                                  ) : (
                                    <TableCell align="center">
                                      <Button variant="contained" disabled>
                                        InActive
                                      </Button>
                                    </TableCell>
                                  )} */}

                                  </TableRow>
                                );
                              })}
                            {emptyRows > 0 && (
                              <TableRow
                                style={{ height: (dense ? 33 : 53) * emptyRows }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </>
                  ) : (
                    <>
                      <Alert severity="warning">
                        <AlertTitle>No Users!</AlertTitle>
                      You may have not selected above or there are no Users —{" "}
                        <strong>check it out!</strong>
                      </Alert>
                    </>
                  )}
                </Paper>
              </div>





              {/* INSTITUTIONS */}
              {/* <div data-tut="third-step">
                <Paper className={classes1.paper}
                >
                  <div
                    style={{
                      padding: 20,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <Typography
                      variant="h3">
                      Institutions
                  </Typography>


                    <FormControl className={classes4.formControl}>
                      <InputLabel id="demo-simple-select-label">Country</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      // value={age}
                      // onChange={handleChange}
                      >
                        <MenuItem value={`India`}>India</MenuItem>
                        <MenuItem value={`Australia`}>Australia</MenuItem>
                        <MenuItem value={`USA`}>USA</MenuItem>
                      </Select>
                    </FormControl>
                  </div>


                  {dynrows && dynrows.length ? (
                    <>
                      <TableContainer>
                        <Table
                          className={classes1.table}
                          aria-labelledby="tableTitle"
                          size={dense ? "small" : "medium"}
                          aria-label="enhanced table"
                        >
                          <EnhancedTableHead2
                            classes={classes1}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody>

                            <TableRow
                              hover
                              role="checkbox"
                              // aria-checked={isItemSelected}
                              tabIndex={-1}
                            // key={row.institute_name}
                            // selected={isItemSelected}

                            >
                              <TableCell padding="checkbox"
                                style={{
                                  padding: 20
                                }}>
                                
                              </TableCell>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              STATE
                            </TableCell>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              100
                            </TableCell>

                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              50
                            </TableCell>

                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                               
                              50
                            </TableCell>


                            </TableRow>

                            <TableRow
                              hover
                              role="checkbox"
                              // aria-checked={isItemSelected}
                              tabIndex={-1}
                            // key={row.institute_name}
                            // selected={isItemSelected}

                            >
                              <TableCell padding="checkbox"
                                style={{
                                  padding: 20
                                }}>
                                
                              </TableCell>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              STATE
                            </TableCell>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              100
                            </TableCell>

                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              50
                            </TableCell>

                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              50
                            </TableCell>


                            </TableRow>

                            <TableRow
                              hover
                              role="checkbox"
                              // aria-checked={isItemSelected}
                              tabIndex={-1}
                            // key={row.institute_name}
                            // selected={isItemSelected}

                            >
                              <TableCell padding="checkbox"
                                style={{
                                  padding: 20
                                }}>
                                
                              </TableCell>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              STATE
                            </TableCell>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              100
                            </TableCell>

                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              50
                            </TableCell>

                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              50
                            </TableCell>


                            </TableRow>

                            <TableRow
                              hover
                              role="checkbox"
                              // aria-checked={isItemSelected}
                              tabIndex={-1}
                            // key={row.institute_name}
                            // selected={isItemSelected}

                            >
                              <TableCell padding="checkbox"
                                style={{
                                  padding: 20
                                }}>
                                
                              </TableCell>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              STATE
                            </TableCell>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              100
                            </TableCell>

                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              50
                            </TableCell>

                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                
                              50
                            </TableCell>


                            </TableRow>

                            {emptyRows > 0 && (
                              <TableRow
                                style={{ height: (dense ? 33 : 53) * emptyRows }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </>
                  ) : (
                    <>
                      <Alert severity="warning">
                        <AlertTitle>No Users!</AlertTitle>
                      You may have not selected above or there are no Users —{" "}
                        <strong>check it out!</strong>
                      </Alert>
                    </>
                  )}
                </Paper>
              </div>
 */}



              
              {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
            </div>
          </Grid>
        </Container>
        <Box pb={4}>
          <Copyright />
        </Box>
      </main>


      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </div>
  );
}
