/**
 * reducer for current curriculum map unit
 */

import * as actions from '../actions/currUnitActions'

export const initialState = {
    currUnit: null,
    loading: false,
    hasErrors: false
}

export default function currUnitReducer(state = initialState, action) {
    switch(action.type) {
        case actions.MAPUNIT_CURR_REQUEST:
            return { ...state, loading: true }
        case actions.MAPUNIT_CURR_SUCCESS:
            console.log(`called map unit  reducer success with payload ${JSON.stringify(action.payload)}`)
            return { currUnit: action.payload, loading: false, hasErrors: false }
        case actions.MAPUNIT_CURR_FAILURE:
            return { ...state, loading: false, hasErrors: true }
        default:
            return state;
    }
}