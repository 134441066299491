import { UNITMAP_LIST_REQUEST, UNITMAP_LIST_SUCCESS, UNITMAP_LIST_FAIL,
UNITADD_REQUEST, UNITADD_SUCCESS, UNITADD_FAILURE } from "../constants/unitMapConstants";

function UnitMapReducer(state = { UnitMap: [] }, action) {
    switch (action.type) {
        case UNITMAP_LIST_REQUEST:
            return { ...state, loadingUnit: true }
        case UNITMAP_LIST_SUCCESS:
            return { ...state, loadingUnit: false, UnitMap: action.payload };
        case UNITMAP_LIST_FAIL:
            return { ...state, loadingUnit: false, errorMap: action.payload }
        case UNITADD_REQUEST:
            return { ...state, loadingUnit: true }
        case UNITADD_SUCCESS:
            return { ...state, loadingUnit: false, UnitMap: action.payload }
        case UNITADD_FAILURE:
            return { ...state, loadingUnit: false, errorMap: action.payload }
        default:
            return state;
    }
}

export { UnitMapReducer }