import { SEARCHEBOOK_LIST_FAIL, SEARCHEBOOK_LIST_REQUEST, SEARCHEBOOK_LIST_SUCCESS } from "../constants/SearchEbookConstants";


function searchEbookReducer(state = { searchEbookData: [] }, action) {
    switch (action.type) {
        case SEARCHEBOOK_LIST_REQUEST:
            return { loadingSearch: true }
        case SEARCHEBOOK_LIST_SUCCESS:
            return { loadingSearch: false, searchEbookData: action.payload };
        case SEARCHEBOOK_LIST_FAIL:
            return { loadingSearch: false, errorSearchEbook: action.payload }
        default:
            return state;
    }

}

export { searchEbookReducer }