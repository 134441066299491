// TrainList Page (MainPage for trainer)

//imports starts
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import { Route, Link, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import PublisherAPI from '../../http/publisher';
import { Avatar, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Box, Container } from '@material-ui/core';
import TrainListResults from './TrainListResults';
import TrainListToolbar from './TrainListToolbar';
import customers from '../../__mocks__/customers'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { Edit } from 'react-feather';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { listUnitMapGet } from '../../redux/actions/unitMapAction';
import { UnitMapReducer } from '../../redux/reducers/unitMapReducer';
import { listCurriculumGet, filterMaps } from '../../redux/actions/curriculumMapAction';
import { CurriculumMapReducer } from '../../redux/reducers/CurriculumMapReducer';
import { updateCurrUnit } from '../../redux/actions/currUnitActions';
import Cookie from 'js-cookie';
import LeftCard from '../trainer/slots/LeftCard';
import { updateCurrMap } from '../../redux/actions/currMapActions';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getUnitLessons } from '../../redux/actions/unitLessonsActions';
import { listAllEventsGet, listTeachVideosGet } from '../../redux/actions/Trainer/EventsGet';
import EventIcon from '@material-ui/icons/Event';
import TrainerVideoHeader from './slots/TrainerVideoHeader';
import TrainerDndList from './slots/TrainerDndList';
//import ends

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    shape: {
        backgroundColor: theme.palette.primary.main,
        width: 20,
        height: 20,
    },
    shape2: {
        backgroundColor: theme.palette.secondary.dark,
        width: 20,
        height: 20,
    },
    shapeCircle: {
        borderRadius: '50%',
    },
}));




const drawerWidth = 250;

const useStyles1 = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0
    },
}));





function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography > {children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}






const UnitplanBox = (props) => {
    console.log("props at unitplan box", props.customerData)

    const classes = useStyles();
    const Rectangle = (props) => { return (<div style={{ backgroundColor: props.color, width: 20, height: 20, borderRadius: 0 }} />) }
    const rectangle2 = <div className={classes.shape2} />;
    const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;

    return (

        <div>


            {props && props.customerData ?
                <div>
                    {/* 
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          > */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Rectangle color="blue" />
                        <Typography
                            // align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            Event Name: {props.customerData.event_name}

                        </Typography>

                    </div>
                    {/* </div> */}

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Rectangle color="blue" />
                        <Typography
                            // align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            Standard: {props.customerData.classroom_std}

                        </Typography>

                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Rectangle color="blue" />
                        <Typography
                            // align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            Institute : {props.customerData.institute_type_name}
                        </Typography>
                    </div>


                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Rectangle color="blue" />
                        <Typography
                            // align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            Subject : {props.customerData.subject_name}
                        </Typography>

                    </div>


                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Rectangle color="blue" />
                        <Typography
                            // align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            Description : {props.customerData.event_des}
                        </Typography>

                    </div>


                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Rectangle color="blue" />
                        <Typography
                            // align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            Tags : {props.customerData.event_tags}
                        </Typography>

                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Rectangle color="blue" />
                        <Typography
                            // align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            Start Time: {props.customerData.start_time}
                        </Typography>

                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Rectangle color="blue" />
                        <Typography
                            // align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            End Time : {props.customerData.end_time}
                        </Typography>

                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Rectangle color="blue" />
                        <Typography
                            // align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="body2"
                            style={{
                                marginLeft: 10,
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            Live : {props.customerData.is_live == true ? "Yes" : "No"}
                        </Typography>

                    </div>


                </div>
                :
                <></>
            }
        </div>
    );
}

const SimpleTabs = (props) => {

    {
        props && props.customerData
            ?

            console.log("props at simpletabs", props.customerData)
            :
            console.log("no data")
    }


    {
        props && props.curriculumData
            ?

            console.log("props at simpletabs curriculum", props.curriculumData)
            :
            console.log("no data")
    }



    const classes = useStyles();
    const [value, setValue] = React.useState(0);




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div
            style={{
                width: '100%'
            }}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Overview" {...a11yProps(0)} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />
                    {/* <Tab label="History" {...a11yProps(1)} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} /> */}
                    {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
            </AppBar>


            <TabPanel value={value} index={0}>



                <div style={{
                    height: 20
                }} />

                {
                    props && props.customerData ?
                        <UnitplanBox customerData={props.customerData} />
                        :
                        props && props.curriculumData ?

                            <UnitplanBox customerData={props.curriculumData[0]} />
                            :
                            <UnitplanBox />
                }

                <div style={{
                    height: 40
                }} />


            </TabPanel>


        </div>
    );
}

const CenteredTabs = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [customerData, setCustomerData] = useState("")



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Item One" />
                <Tab label="Item Two" />
            </Tabs>
        </Paper>
    );
}



const DetailsCard = ({ CurriculumData, editData, customers, customerData, product, ...rest }) => {



    console.log("CirruculumData at right card", CurriculumData)
    console.log("CustomerData at right card", customerData)


    const dispatch = useDispatch()



    const unitMapDetailsGet = useSelector(state => state.unitMap)
    const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet
    const currMap = useSelector(state => state.currMap.currMap)


    console.log("loadingunit", loadingUnit, UnitMap)
    console.log(`currmap in rightcard ${JSON.stringify(currMap)}`)

    /*
    const getUnitDetails = () => {
  
  
      CurriculumData ?
  
        dispatch(listUnitMapGet())
        :
        <></>
  
    }
    */


    const allEventsList = useSelector(state => state.allEventsGet)
    const { loadingAllEvents, allEventsGet, errorAllEvents } = allEventsList


    {
        loadingAllEvents && allEventsList ?
            console.log("data is loading")
            :
            console.log("all events list at trainList", allEventsGet)
    }



    useEffect(() => {
        const userId = Cookie.get('kgtopg.partner.user.id')
        // getUnitDetails()
        dispatch(listCurriculumGet(userId))

        return () => {
            //
        }

    }, [])



    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                // height: '100%'
            }}
            {...rest}
        >
            <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>

                    {
                        customerData ?
                            customerData ?
                                <div style={{ display: 'flex', flexDirection: 'column', fontFamily: "Poppins, Helvetica, sans-serif" }}>
                                    {/* <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      pb: 3
                    }}
                  >
                  </Box> */}




                                    <Typography
                                        align="center"
                                        color="textPrimary"
                                        gutterBottom
                                        variant="body1"
                                        style={{
                                            marginLeft: 10,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <EventIcon />  Event: {customerData.event_name}
                                    </Typography>
                                    <Chip label={"Event starts on " + customerData.start_time} />
                                </div>
                                :

                                console.log("curriculum data at first time loading.....................", allEventsGet)

                            :

                            loadingAllEvents && allEventsList ?
                                <></>
                                :
                                // CurriculumMap.data && CurriculumMap.data.length && CurriculumMap.length != 0 ?
                                allEventsGet && allEventsGet.data && allEventsGet.data.length > 0 ?
                                    <div style={{ display: 'flex', flexDirection: 'column', fontFamily: "Poppins, Helvetica, sans-serif" }}>
                                        <Typography
                                            align="center"
                                            color="textPrimary"
                                            gutterBottom
                                            variant="body1"
                                            style={{
                                                marginLeft: 10,
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <EventIcon /> Event: {allEventsGet.data[0].event_name}
                                        </Typography>
                                        <Chip color="primary" label={"Event starts on " + allEventsGet.data[0].start_time} />

                                    </div>
                                    :
                                    <Alert severity="warning">
                                        <AlertTitle>No Events</AlertTitle>
                                        No Events — <strong>select one from left Table</strong>
                                    </Alert>
                    }
                </div>


                <div
                    style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 20, width: '100%' }}>
                    {
                        customerData ?
                            <Link to={`/publisher-ebook/trainer/event-videos/?eve_id=${customerData.id}`} style={{ textDecoration: "none", color: "#000" }}>
                                <Box
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <EditIcon style={{ height: 16, fontFamily: "Poppins, Helvetica, sans-serif" }} />

                                    <Typography
                                        align="center"
                                        color="textPrimary"
                                        variant="body2"
                                        style={{
                                            fontFamily: "Poppins, Helvetica, sans-serif"
                                        }}
                                    >Event Videos </Typography>
                                </Box>
                            </Link>
                            :
                            allEventsGet && allEventsGet.data && allEventsGet.data.length > 0 ?
                                <Link to={`/publisher-ebook/trainer/event-videos/?eve_id=${allEventsGet.data[0].id}`} style={{ textDecoration: "none", color: "#000" }}>
                                    <Box
                                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <EditIcon style={{ height: 16, fontFamily: "Poppins, Helvetica, sans-serif" }} />

                                        <Typography
                                            align="center"
                                            color="textPrimary"
                                            variant="body2"
                                            style={{
                                                fontFamily: "Poppins, Helvetica, sans-serif"
                                            }}
                                        >Event Videos </Typography>
                                    </Box>
                                </Link>
                                :
                                <></>

                    }

                    {/* <Link to={`/publisher-ebook/trainer/event-videos/?event_id=1`} style={{ textDecoration: "none", color: "#000" }}> */}


                    {/* <Link to={`/publisher-ebook/curriculum-map/set-units/${(currMap) ? currMap.map_id : 0}`} style={{ textDecoration: "none", color: "#000" }}> */}
                    <Box
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <VisibilityIcon style={{ height: 16 }} />
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="body2"
                            style={{
                                fontFamily: "Poppins, Helvetica, sans-serif"
                            }}
                        >
                            View
                        </Typography>
                    </Box>
                    {/* </Link> */}

                    {/* 
          <Box
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <ViewCarouselIcon style={{ height: 16 }} />
            <Typography
              align="center"
              color="textPrimary"
              variant="body2"
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif"
              }}
            >
              Compare
            </Typography>
          </Box>
 */}

                </div>
            </CardContent>
            <Box sx={{ flexGrow: 1 }} />
            <Divider />

            {/* <CenteredTabs /> */}

            <div style={{
                width: '100%'
            }}>
                {/* {loadingCur && curriculumDetailsGet ? */}
                {loadingAllEvents && allEventsList ?
                    <></>
                    :
                    // <SimpleTabs loadingUnit={loadingUnit} UnitMap={UnitMap} />

                    <SimpleTabs customerData={customerData} curriculumData={allEventsGet.data} />

                }
            </div>
        </Card>
    )
}



function TrainerVideos() {
    const classes1 = useStyles1();

    const [customerData, setCustomerData] = useState('')
    const [CurriculumData, setCurriculumData] = useState(null)
    const [instId, setInstId] = useState("")
    const [boardId, setBoardId] = useState("")
    const [stdId, setStdId] = useState("")
    const [subId, setSubId] = useState("")

    // const mapList = useSelector(state => state.curriculumMap.CurriculumMap)
    // console.log(`now updated display maplist ${JSON.stringify(mapList)}`)
    // const currMap = useSelector(state => state.currMap.currMap)

    const dispatch = useDispatch()

    const updateCurrData = (row, CurrData) => {


        console.log("props at maplist in TrainList ", row)
        console.log("CurrData", CurrData)
        setCustomerData(row)
        setCurriculumData(CurrData)
    }

    useEffect(() => {

        console.log(`now updated called useeffect in maplist main page`)

        dispatch(listAllEventsGet())

        return (
            <></>

        )
    }, [])


    const allEventsList = useSelector(state => state.allEventsGet)
    const { loadingAllEvents, allEventsGet, errorAllEvents } = allEventsList


    const UpdateVideoParams = (inst_id, board_id, std_id, sub_id) => {
        setInstId(inst_id)
        setBoardId(board_id)
        setStdId(std_id)
        setSubId(sub_id)

        dispatch(listTeachVideosGet(inst_id,board_id,sub_id,std_id))

    }

    // if (!currMap && mapList && mapList.data && mapList.data[0]) {
    //   // set current map
    //   dispatch(updateCurrMap(mapList.data[0]))
    //   console.log(`now updated current map..`)
    // }

    return (
        <div className={classes1.root}>


            <LeftCard />
            <main className={classes1.content}>


                <Helmet>
                    <title> Trainer Videos| KGtoPG Partner</title>
                </Helmet>
                <Box
                    sx={{
                        backgroundColor: 'background.default',
                        minHeight: '100%',
                        py: 3
                    }}
                >
                    <Container maxWidth={false}>

                        {/* MapListTool bar will have all the search and dropdown filters */}
                        <TrainerVideoHeader videoParams={(inst_id, board_id, std_id, sub_id) => { UpdateVideoParams(inst_id, board_id, std_id, sub_id) }} />



                        <Box sx={{ pt: 3, mt: 5 }} style={{ marginTop: 20 }}>
                            <div
                                style={{ display: 'flex' }}
                            >
                                <TrainerDndList />
                            </div>

                        </Box>
                    </Container>
                </Box>
            </main>
        </div>


    )

}





export default TrainerVideos;
