import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { listDashBoardPubSchools } from '../../../../redux/actions/DashBoardActions';
import { useEffect } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const orders = [
  {
    id: uuid(),
    ref: 'CDD1049',
    amount: 30.5,
    customer: {
      name: 'Ekaterina Tankova'
    },
    createdAt: 1555016400000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1048',
    amount: 25.1,
    customer: {
      name: 'Cao Yu'
    },
    createdAt: 1555016400000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1047',
    amount: 10.99,
    customer: {
      name: 'Alexa Richardson'
    },
    createdAt: 1554930000000,
    status: 'refunded'
  },
  {
    id: uuid(),
    ref: 'CDD1046',
    amount: 96.43,
    customer: {
      name: 'Anje Keizer'
    },
    createdAt: 1554757200000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1045',
    amount: 32.54,
    customer: {
      name: 'Clarke Gillebert'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1044',
    amount: 16.76,
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  }
];

function LatestOrders(props) {

  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(listDashBoardPubSchools())

    return (() => {
      //
    })
  }, [])


  const dashboardSchoolsGet = useSelector(state => state.dashboardSchools)
  const { loadingDashboardSch, DashboardSchools, errorDashSchools } = dashboardSchoolsGet

  {
    loadingDashboardSch && dashboardSchoolsGet ?
      console.log("data is loading")
      :
      console.log("publishers schools", DashboardSchools)
  }


  return (

    <Card {...props}>
      <CardHeader title="Institutions" />
      <Divider />
      {dashboardSchoolsGet && DashboardSchools && DashboardSchools.data && DashboardSchools.data.List_of_Schools && DashboardSchools.data.List_of_Schools.length ?
        <>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                      &nbsp;
                    </TableCell> */}
                    <TableCell>
                      School
              </TableCell>
                    <TableCell sortDirection="desc">
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                        <TableSortLabel
                          active
                          direction="desc"
                        >
                          Date
                  </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      Status
              </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {DashboardSchools.data.List_of_Schools.slice(0, 7).map((order, index) => {
                    let color = 'green'
                    let status = order.status
                    if(status == 'REJECTED')
                      status = 'PENDING'
                    if (order.status == 'PENDING')
                      color = 'orange'
                    else if (order.status == 'REJECTED')
                      color = 'red'
                    return (
                      <TableRow
                        hover
                        key={index}
                      >
                        {/* <TableCell>
                          {index + 1}
                        </TableCell> */}
                        <TableCell>
                          {order.institute_name}
                        </TableCell>
                        <TableCell>
                          {"22/05/2021"} 
                          {/* ##########################3 NEED TO SHOW REAL DATE ###############################33 */}
                        </TableCell>
                        <TableCell>
                          <Chip
                            // color={color}
                            label={status}
                            size="small"
                            style={{
                              backgroundColor: color,
                              color: 'white',
                              height: 16,
                              fontSize: 10
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  }
                  )}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
            style={{
              padding: 10
            }}
          >
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"

            >
              View all
      </Button>
          </Box>
        </>
        :
        <>
          <Alert severity="warning">
            <AlertTitle>No Institutions!</AlertTitle>
          There are no institutions yet using your books — <strong>connect with some!</strong>
          </Alert>
        </>

      }
    </Card>

  )
}
export default LatestOrders;
