import { DASHBOARDHEADERDETAILS_LIST_REQUEST, DASHBOARDHEADERDETAILS_LIST_SUCCESS, DASHBOARDHEADERDETAILS_LIST_FAIL, DASHBOARDCONTPROVDETAILS_LIST_REQUEST, DASHBOARDCONTPROVDETAILS_LIST_SUCCESS, DASHBOARDCONTPROVDETAILS_LIST_FAIL, DASHBOARDPUBLISHERDETAILS_LIST_REQUEST, DASHBOARDPUBLISHERDETAILS_LIST_SUCCESS, DASHBOARDPUBLISHERDETAILS_LIST_FAIL, DASHBOARDSCHOOLSDETAILS_LIST_REQUEST, DASHBOARDSCHOOLSDETAILS_LIST_SUCCESS, DASHBOARDSCHOOLSDETAILS_LIST_FAIL, DASHBOARDPUBGRAPHS_LIST_REQUEST, DASHBOARDPUBGRAPHS_LIST_SUCCESS, DASHBOARDPUBGRAPHS_LIST_FAIL, DASHBOARDCONTGRAPHS_LIST_REQUEST, DASHBOARDCONTGRAPHS_LIST_SUCCESS, DASHBOARDCONTGRAPHS_LIST_FAIL, DASHBOARDSCHOOLSCONTDETAILS_LIST_REQUEST, DASHBOARDSCHOOLSCONTDETAILS_LIST_SUCCESS, DASHBOARDSCHOOLSCONTDETAILS_LIST_FAIL, DASHBOARDPUBDEVICES_LIST_REQUEST, DASHBOARDPUBDEVICES_LIST_SUCCESS, DASHBOARDPUBDEVICES_LIST_FAIL, DASHBOARDCONTDEVICES_LIST_REQUEST, DASHBOARDPCONTDEVICES_LIST_SUCCESS, DASHBOARDCONTDEVICES_LIST_FAIL, DASHBOARDCONTHEADERDETAILS_LIST_REQUEST, DASHBOARDCONTHEADERDETAILS_LIST_SUCCESS, DASHBOARDCONTHEADERDETAILS_LIST_FAIL } from "../constants/DashboardConstants";

function DashboardDetailsReducer(state ={DashboardDetails:[]},action) {

    switch (action.type) {
        case DASHBOARDHEADERDETAILS_LIST_REQUEST:
            return { loadingDashboard: true }
        case DASHBOARDHEADERDETAILS_LIST_SUCCESS:
            return {loadingDashboard:false,DashboardDetails:action.payload};
        case DASHBOARDHEADERDETAILS_LIST_FAIL:
            return {loadingDashboard:false,errorDashboard:action.payload}
        default:
            return state;
    }

}


function DashboardContDetailsReducer(state ={DashboardContDetails:[]},action) {

    switch (action.type) {
        case DASHBOARDCONTHEADERDETAILS_LIST_REQUEST:
            return { loadingContDashboard: true }
        case DASHBOARDCONTHEADERDETAILS_LIST_SUCCESS:
            return {loadingContDashboard:false,DashboardContDetails:action.payload};
        case DASHBOARDCONTHEADERDETAILS_LIST_FAIL:
            return {loadingContDashboard:false,errorDashboardCont:action.payload}
        default:
            return state;
    }

}

function DashboardSchoolsReducer(state ={DashboardSchools:[]},action) {

    switch (action.type) {
        case DASHBOARDSCHOOLSDETAILS_LIST_REQUEST:
            return { loadingDashboardSch: true }
        case DASHBOARDSCHOOLSDETAILS_LIST_SUCCESS:
            return {loadingDashboardSch:false,DashboardSchools:action.payload};
        case DASHBOARDSCHOOLSDETAILS_LIST_FAIL:
            return {loadingDashboardSch:false,errorDashSchools:action.payload}
        default:
            return state;
    }

}

function DashboardContSchoolsReducer(state ={DashboardContSchools:[]},action) {

    switch (action.type) {
        case DASHBOARDSCHOOLSCONTDETAILS_LIST_REQUEST:
            return { loadingDashSchCont: true }
        case DASHBOARDSCHOOLSCONTDETAILS_LIST_SUCCESS:
            return {loadingDashSchCont:false,DashboardContSchools:action.payload};
        case DASHBOARDSCHOOLSCONTDETAILS_LIST_FAIL:
            return {loadingDashSchCont:false,errorDashSchCont:action.payload}
        default:
            return state;
    }

}

function DashboardPublisherReducer(state ={DashboardPublishers:[]},action) {

    switch (action.type) {
        case DASHBOARDPUBLISHERDETAILS_LIST_REQUEST:
            return { loadingDashboardPub: true }
        case DASHBOARDPUBLISHERDETAILS_LIST_SUCCESS:
            return {loadingDashboardPub:false,DashboardPublishers:action.payload};
        case DASHBOARDPUBLISHERDETAILS_LIST_FAIL:
            return {loadingDashboardPub:false,errorDashPub:action.payload}
        default:
            return state;
    }

}

function DashboardContProvReducer(state ={DashboardContProv:[]},action) {

    switch (action.type) {
        case DASHBOARDCONTPROVDETAILS_LIST_REQUEST:
            return { loadingDashboardCont: true }
        case DASHBOARDCONTPROVDETAILS_LIST_SUCCESS:
            return {loadingDashboardCont:false,DashboardContProv:action.payload};
        case DASHBOARDCONTPROVDETAILS_LIST_FAIL:
            return {loadingDashboardCont:false,errorDashCont:action.payload}
        default:
            return state;
    }

}

function DashboardPubGraphsReducers(state ={DashboardPubGraphs:[]},action) {

    switch (action.type) {
        case DASHBOARDPUBGRAPHS_LIST_REQUEST:
            return { loadingDashboardPubGraphs: true }
        case DASHBOARDPUBGRAPHS_LIST_SUCCESS:
            return {loadingDashboardPubGraphs:false,DashboardPubGraphs:action.payload};
        case DASHBOARDPUBGRAPHS_LIST_FAIL:
            return {loadingDashboardPubGraphs:false,errorDashPubGraphs:action.payload}
        default:
            return state;
    }

}

function DashboardContGraphsReducers(state ={DashboardContGraphs:[]},action) {

    switch (action.type) {
        case DASHBOARDCONTGRAPHS_LIST_REQUEST:
            return { loadingDashboardContGraphs: true }
        case DASHBOARDCONTGRAPHS_LIST_SUCCESS:
            return {loadingDashboardContGraphs:false,DashboardContGraphs:action.payload};
        case DASHBOARDCONTGRAPHS_LIST_FAIL:
            return {loadingDashboardContGraphs:false,errorDashContGraphs:action.payload}
        default:
            return state;
    }

}


function DashboardPubDevicesReducers(state ={DashboardPubDevices:[]},action) {

    switch (action.type) {
        case DASHBOARDPUBDEVICES_LIST_REQUEST:
            return { loadingDashboardPubDevices: true }
        case DASHBOARDPUBDEVICES_LIST_SUCCESS:
            return {loadingDashboardPubDevices:false,DashboardPubDevices:action.payload};
        case DASHBOARDPUBDEVICES_LIST_FAIL:
            return {loadingDashboardPubDevices:false,errorDashPubDevices:action.payload}
        default:
            return state;
    }

}


function DashboardContDevicesReducers(state ={DashboardContDevices:[]},action) {

    switch (action.type) {
        case DASHBOARDCONTDEVICES_LIST_REQUEST:
            return { loadingDashboardContDevices: true }
        case DASHBOARDPCONTDEVICES_LIST_SUCCESS:
            return {loadingDashboardContDevices:false,DashboardContDevices:action.payload};
        case DASHBOARDCONTDEVICES_LIST_FAIL:
            return {loadingDashboardContDevices:false,errorDashContDevices:action.payload}
        default:
            return state;
    }

}



export { DashboardDetailsReducer,
    DashboardContDetailsReducer,
    DashboardSchoolsReducer ,
    DashboardPublisherReducer,
    DashboardContProvReducer,
    DashboardPubGraphsReducers,
    DashboardContSchoolsReducer,
    DashboardContGraphsReducers,
    DashboardPubDevicesReducers,
    DashboardContDevicesReducers
}