import { CONTENTDETAILS_LIST_FAIL, CONTENTDETAILS_LIST_REQUEST, CONTENTDETAILS_LIST_SUCCESS, CURRICULUMMAPDETAILS_LIST_FAIL, CURRICULUMMAPDETAILS_LIST_REQUEST, CURRICULUMMAPDETAILS_LIST_SUCCESS, EBOOKDETAILS_LIST_FAIL, EBOOKDETAILS_LIST_REQUEST, EBOOKDETAILS_LIST_SUCCESS } from "../constants/GetAllFIlesConstants";

function allContentGetReducer(state ={allContentGetData:[]},action) {

    switch (action.type) {
        case CONTENTDETAILS_LIST_REQUEST:
            return { loadingAllContent: true }
        case CONTENTDETAILS_LIST_SUCCESS:
            return {loadingAllContent:false,allContentGetData:action.payload};
        case CONTENTDETAILS_LIST_FAIL:
            return {loadingAllContent:false,errorAllContent:action.payload}
        default:
            return state;
    }

}


function allBooksGetReducer(state ={allBooksGet:[]},action) {

    switch (action.type) {
        case EBOOKDETAILS_LIST_REQUEST:
            return { loadingAllBooks: true }
        case EBOOKDETAILS_LIST_SUCCESS:
            return {loadingAllBooks:false,allBooksGet:action.payload};
        case EBOOKDETAILS_LIST_FAIL:
            return {loadingAllBooks:false,errorAllBooks:action.payload}
        default:
            return state;
    }

}


function allCurriculumGetReducer(state ={allCurrGet:[]},action) {

    switch (action.type) {
        case CURRICULUMMAPDETAILS_LIST_REQUEST:
            return { loadingAllCurr: true }
        case CURRICULUMMAPDETAILS_LIST_SUCCESS:
            return {loadingAllCurr:false,allCurrGet:action.payload};
        case CURRICULUMMAPDETAILS_LIST_FAIL:
            return {loadingAllCurr:false,errorAllBooks:action.payload}
        default:
            return state;
    }

}


export { allContentGetReducer ,allBooksGetReducer,allCurriculumGetReducer}