import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Menu, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { listDashBoardContProv, listDashBoardPublishers } from '../../../../redux/actions/DashBoardActions';
import { useDispatch, useSelector } from 'react-redux';
import { listActApprvPubPut, listActivityContGet, listActivityPubGet } from '../../../../redux/actions/ActivityActions';
import { Alert, AlertTitle } from '@material-ui/lab';
import Cookie from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ReactPlayer from "react-player";
import { useHistory } from 'react-router';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AdjustIcon from '@material-ui/icons/Adjust';
import { FiberManualRecord } from '@material-ui/icons';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },

});

const useStyles1 = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));


const useStyles2 = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    // marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    // marginTop: theme.spacing(1),
  },
}))


export default function ActivityTablePub() {
  const classes = useStyles();
  const classes1 = useStyles1()
  const classes2 = useStyles2()
  const dispatch = useDispatch();
  const history = useHistory()
  const [Approval, setApproval] = React.useState('');
  const [roleId, setRoleId] = React.useState("")
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [bookLink, setBookLink] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actvRow, setActvRow] = React.useState(null)

  const ProvId = Cookie.get('kgtopg.partner.user.id')


  useEffect(() => {

    const query = new URLSearchParams(window.location.search);
    const role_id = query.get("role_id");
    setRoleId(query.get("role_id"))

    dispatch(listActivityPubGet(role_id))

    return (() => {
      //
    })
  }, [])

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setActvRow(row)
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const actListPubGet = useSelector(state => state.actListPub)
  const { loadingActPubGet, actPubGet, errorActPubGet } = actListPubGet


  {
    loadingActPubGet && actListPubGet ?
      console.log("data is loading")
      :
      console.log("data at activity table content", actPubGet)
  }



  const handleChange = (event) => {
    setApproval(event.target.value);
  };

  const UpdateAppStatus = async (id) => {
    const body = {
      book_id: id,
      is_published: 1,
      approved_by: ProvId
    }

    console.log(`update approve status pub body ${JSON.stringify(body)}`)

    handleMenuClose()

    await new Promise((resolve, reject) => {
      dispatch(listActApprvPubPut(body, roleId))
      resolve()
    })

    // reload
    // dispatch(listActivityPubGet(roleId))

  }

  const UpdateDecStatus = async (id) => {
    const body = {
      book_id: id,
      is_published: 0,
      approved_by: ProvId
    }

    handleMenuClose()

    await new Promise((resolve, reject) => {
      dispatch(listActApprvPubPut(body, roleId))
      resolve()
    })

    // reload
    // dispatch(listActivityPubGet(roleId))


  }



  const handleClickOpen = (eBookLink) => {
    setBookLink(eBookLink)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };


  const OpenTeachView = (id) => {

    history.push(`/publisher-ebook/settings/view-activity/?book-id=${id}`)
  }
  return (
    <TableContainer component={Paper}>
      {
        actListPubGet && actPubGet && actPubGet.data && actPubGet.data.length != 0 ?
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell align="left">&nbsp;</StyledTableCell> */}
                <StyledTableCell align="left">Details</StyledTableCell>
                <StyledTableCell align="left">Author Name </StyledTableCell>
                {/* <StyledTableCell align="center">Approved by</StyledTableCell> */}
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>


              {actListPubGet && actPubGet && actPubGet.data && actPubGet.data.map((row) => {
                console.log(`row is_published ${row.is_published}`)
                let imgFile = row.ebook_image_url
                if (!imgFile)
                  imgFile = 'https://s29843.pcdn.co/blog/wp-content/uploads/sites/2/2021/02/video-thumbnails-social.png'
                return (
                  <StyledTableRow key={row.ebook_name_text}>
                    {/* <StyledTableCell align="center" component="th" scope="row"
                      style={{
                        padding: 5
                      }}>
                      
                    </StyledTableCell> */}
                    <StyledTableCell align="center" component="th" scope="row"
                      style={{
                        padding: 10
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}>

                        <div
                          style={{
                            marginRight: 10
                          }}>

                          <img onClick={() => OpenTeachView(row.id)} src={imgFile} width={24}
                            style={{
                              borderRadius: 5
                            }} />
                        </div>
                        {row.ebook_name_text}
                      </div>

                    </StyledTableCell>
                    <StyledTableCell align="left"
                      style={{
                        padding: 10
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                        <GroupWorkIcon style={{ height: 16, width: 16, marginRight: 5 }} />
                        {row.ebook_author_name}
                      </div>
                    </StyledTableCell>
                    {/* <StyledTableCell align="center"
                      style={{
                        padding: 0
                      }}>--</StyledTableCell> */}
                    <StyledTableCell align="left"
                      style={{
                        padding: 10
                      }}>


                      {(row.is_published == true) ?
                        <Typography
                          variant="body2"
                          color="green"
                          style={{
                            color: 'green'
                          }}>
                          APPROVED
                        </Typography>
                        :
                        <Typography
                          variant="body2"
                          color="red"
                          style={{
                            color: 'red'
                          }}>
                          DECLINED
                        </Typography>
                      }

                      {/*
                      <FormControl className={classes1.formControl}>
                        <InputLabel id="demo-simple-select-label">Approval</InputLabel>
                        <Select
                          // labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={Approval}
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>
                            <Button style={{ color: "green" }} onClick={() => { UpdateAppStatus(row.id) }}>Approve</Button>
                          </MenuItem>
                          <MenuItem value={20}>
                            <Button color="secondary" onClick={() => { UpdateDecStatus(row.id) }}>Decline</Button>                    </MenuItem>
                        </Select>
                      </FormControl>
                      */}




                    </StyledTableCell>
                    <StyledTableCell align="right"
                      style={{
                        padding: 10
                      }}>
                      {/* 
                      <Button variant="outlined" color="primary" onClick={() => OpenTeachView(row.id)} >
                        Preview
                      </Button>
 */}

                      <React.Fragment>
                        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                            Preview
                          </Button> */}

                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleMenuClick(e, row)}>
                          <MoreVertIcon />
                        </Button>

                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={() => OpenTeachView(actvRow.id)}>Preview</MenuItem>
                          <MenuItem onClick={() => { UpdateAppStatus(actvRow.id) }}>Approve</MenuItem>
                          <MenuItem onClick={() => { UpdateDecStatus(actvRow.id) }}>Decline</MenuItem>
                        </Menu>
                      </React.Fragment>
                      {/* <React.Fragment>
                      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Preview
                        </Button>
                      <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="max-width-dialog-title"
                      >
                        <DialogTitle id="max-width-dialog-title">Preview</DialogTitle>
                        <DialogContent>

                          <ReactPlayer url={row.content_location} width={"550px"} />


                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} color="primary">
                            Close
                            </Button>
                        </DialogActions>
                      </Dialog>
                    </React.Fragment> */}

                    </StyledTableCell>
                  </StyledTableRow>


                )
              }
              )}

            </TableBody>
          </Table>
          :
          <div>
            <Alert severity="error">
              <AlertTitle>No Books Found!</AlertTitle>
                    No Books has been uploaded for this user —{" "}
              <strong>Upload Books in my Books page</strong>
            </Alert>

          </div>
      }
    </TableContainer>
  );
}