import { ALLEVENTSFILGET_LIST_FAIL, ALLEVENTSFILGET_LIST_REQUEST, ALLEVENTSFILGET_LIST_SUCCESS, ALLEVENTSGET_LIST_FAIL, ALLEVENTSGET_LIST_REQUEST, ALLEVENTSGET_LIST_SUCCESS, ALLEVENTSSRCGET_LIST_FAIL, ALLEVENTSSRCGET_LIST_REQUEST, ALLEVENTSSRCGET_LIST_SUCCESS, ALLVIDEOSGET_LIST_FAIL, ALLVIDEOSGET_LIST_REQUEST, ALLVIDEOSGET_LIST_SUCCESS, SINGLEEVENTGET_LIST_REQUEST, SINGLEEVENTSGET_LIST_FAIL, SINGLEEVENTSGET_LIST_SUCCESS, SINGLEVIDEOGET_LIST_FAIL, SINGLEVIDEOGET_LIST_REQUEST, SINGLEVIDEOGET_LIST_SUCCESS, SUBSCRIBERSDETAILSGET_LIST_FAIL, SUBSCRIBERSDETAILSGET_LIST_REQUEST, SUBSCRIBERSDETAILSGET_LIST_SUCCESS, TRAINTEACHVIDEOSGET_LIST_FAIL, TRAINTEACHVIDEOSGET_LIST_REQUEST, TRAINTEACHVIDEOSGET_LIST_SUCCESS, TRAINTEACHVIDEOSTPCGET_LIST_FAIL, TRAINTEACHVIDEOSTPCGET_LIST_REQUEST, TRAINTEACHVIDEOSTPCGET_LIST_SUCCESS } from "../../constants/Trainer/AllEventsGetConstants";

function allEventsGetReducer(state = { allEventsGet: [] }, action) {

    switch (action.type) {
        case ALLEVENTSGET_LIST_REQUEST:
            return { loadingAllEvents: true }
        case ALLEVENTSGET_LIST_SUCCESS:
            return { loadingAllEvents: false, allEventsGet: action.payload };
        case ALLEVENTSGET_LIST_FAIL:
            return { loadingAllEvents: false, errorAllEvents: action.payload }
        default:
            return state;
    }

}


function allVideosGetReducer(state = { allVideosGetData: [] }, action) {

    switch (action.type) {
        case ALLVIDEOSGET_LIST_REQUEST:
            return { loadingAllVideos: true }
        case ALLVIDEOSGET_LIST_SUCCESS:
            return { loadingAllVideos: false, allVideosGetData: action.payload };
        case ALLVIDEOSGET_LIST_FAIL:
            return { loadingAllVideos: false, errorAllVideos: action.payload }
        default:
            return state;
    }

}

function allEventsGetFilReducer(state = { allEventsFilGetData: [] }, action) {

    switch (action.type) {
        case ALLEVENTSFILGET_LIST_REQUEST:
            return { loadingAllFilEvents: true }
        case ALLEVENTSFILGET_LIST_SUCCESS:
            return { loadingAllFilEvents: false, allEventsFilGetData: action.payload };
        case ALLEVENTSFILGET_LIST_FAIL:
            return { loadingAllFilEvents: false, errorAllFilEvents: action.payload }
        default:
            return state;
    }

}

function allEventsGetSrcReducer(state = { allEventsSrcGetData: [] }, action) {

    switch (action.type) {
        case ALLEVENTSSRCGET_LIST_REQUEST:
            return { loadingAllSrcEvents: true }
        case ALLEVENTSSRCGET_LIST_SUCCESS:
            return { loadingAllSrcEvents: false, allEventsSrcGetData: action.payload };
        case ALLEVENTSSRCGET_LIST_FAIL:
            return { loadingAllSrcEvents: false, errorAllSrcEvents: action.payload }
        default:
            return state;
    }

}



function singleEventGetReducer(state = { singleEventGet: [] }, action) {

    switch (action.type) {
        case SINGLEEVENTGET_LIST_REQUEST:
            return { loadingSingleEvent: true }
        case SINGLEEVENTSGET_LIST_SUCCESS:
            return { loadingSingleEvent: false, singleEventGet: action.payload };
        case SINGLEEVENTSGET_LIST_FAIL:
            return { loadingSingleEvent: false, errorSingleEvents: action.payload }
        default:
            return state;
    }

}

function singleVideoGetReducer(state = { singleVideoGet: [] }, action) {

    switch (action.type) {
        case SINGLEVIDEOGET_LIST_REQUEST:
            return { loadingSingleVid: true }
        case SINGLEVIDEOGET_LIST_SUCCESS:
            return { loadingSingleVid: false, singleVideoGet: action.payload };
        case SINGLEVIDEOGET_LIST_FAIL:
            return { loadingSingleVid: false, errorSingleVid: action.payload }
        default:
            return state;
    }

}



function subscribersGetReducer(state = { SubscrGet: [] }, action) {

    switch (action.type) {
        case SUBSCRIBERSDETAILSGET_LIST_REQUEST:
            return { loadingSubscrGet: true }
        case SUBSCRIBERSDETAILSGET_LIST_SUCCESS:
            return { loadingSubscrGet: false, SubscrGet: action.payload };
        case SUBSCRIBERSDETAILSGET_LIST_FAIL:
            return { loadingSubscrGet: false, errorSubscrGet: action.payload }
        default:
            return state;
    }

}


function trainTeachVideosGetReducer(state = { teachVidGet: [] }, action) {

    switch (action.type) {
        case TRAINTEACHVIDEOSGET_LIST_REQUEST:
            return { loadingteachVidGet: true }
        case TRAINTEACHVIDEOSGET_LIST_SUCCESS:
            return { loadingteachVidGet: false, teachVidGet: action.payload };
        case TRAINTEACHVIDEOSGET_LIST_FAIL:
            return { loadingteachVidGet: false, errorteachVidGet: action.payload }
        default:
            return state;
    }

}

function trainTeachVideosTopGetReducer(state = { teachVidTopGet: [] }, action) {

    switch (action.type) {
        case TRAINTEACHVIDEOSTPCGET_LIST_REQUEST:
            return { loadingteachVidTopGet: true }
        case TRAINTEACHVIDEOSTPCGET_LIST_SUCCESS:
            return { loadingteachVidTopGet: false, teachVidTopGet: action.payload };
        case TRAINTEACHVIDEOSTPCGET_LIST_FAIL:
            return { loadingteachVidTopGet: false, errorteachVidTopGet: action.payload }
        default:
            return state;
    }

}



export {
    allEventsGetReducer,
    allVideosGetReducer,
    singleEventGetReducer,
    singleVideoGetReducer,
    allEventsGetFilReducer,
    allEventsGetSrcReducer,
    subscribersGetReducer,
    trainTeachVideosGetReducer,
    trainTeachVideosTopGetReducer,
    
}