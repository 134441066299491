import React, { useEffect, useState } from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import "../../css/sidebar.css"
import { Link } from 'react-router-dom'
import { ExitToApp, ExitToAppOutlined, LaptopWindows, SettingsOutlined } from '@material-ui/icons';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Divider, Input } from '@material-ui/core';
import AddBoxSharpIcon from '@material-ui/icons/AddBoxSharp';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { MenuItem } from '@material-ui/core';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import image from "../../assets/bus.png"
import FilterResults from 'react-filter-search';
import SearchIcon from '@material-ui/icons/Search';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import CropLandscapeOutlinedIcon from '@material-ui/icons/CropLandscapeOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import Cookie from 'js-cookie';

const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
    { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
];


const DisplayCard = (props) => {

    return (
        <Card style={{ width: 270, height: 150, margin: 10, justifyContent: "center", }}>
            <CardMedia
                component="img"
                alt="bus png"
                height="100"
                image={image}
                title="School bus"
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 14,
                    }}
                >
                    {props.list.title}
                </Typography>
            </CardContent>
        </Card>
    )
}



function SidebarContent(props) {

    const [data, setData] = useState([]);
    const [value, setValue] = useState("");
    const [search, setSearch] = useState("")
    const [showContent, setShownContent] = useState(false)



    const handleChange = event => {
        const { value } = event.target;
        this.setState({ value });
    };


    const onTextChange = e => {

        setSearch(e.target.value)
    };

    const filteredData = top100Films.filter(content => {
        return content.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    })

    console.log("props", props)

    useEffect(() => {
        return () => {
            console.log("windows path", window.location.href, window.location.pathname)
            // 
        }
    }, [])
    console.log("windows path in dashboard", window.location.pathname)

    const path = window.location.pathname
    let currPath = "dashboard"
    let dashSel = false;
    let eBookSel = false;
    let mapsSel = false;
    let userSel = false;
    let trnSel = false;

    switch (path) {
        case "/publisher-ebook/dashboard":
            currPath = "dashboard";
            dashSel = true;
            break;
        case "/publisher-ebook/books":
            currPath = "ebooks";
            eBookSel = true;
            break;
        case "/publisher-ebook/curriculum-map":
        case "/curriculum-map/set-units/:id":
            currPath = "maps";
            mapsSel = true;
            break;
        case "/publisher-ebook/users":
            currPath = "users";
            userSel = true;
            break;
        case "/publisher-ebook/trainer":
            currPath = "trainer";
            trnSel = true;
            break;
    }


    // const CONTENTUPLOAD =   Cookie.get('kgtopg.partner.user.CONTENTUPLOAD')
    // const QUALITYASSURANCE =  Cookie.get('kgtopg.partner.user.QUALITYASSURANCE')
    // const SUPERADMIN = Cookie.get('kgtopg.partner.user.SUPERADMIN')
    // const CONTENTMAP = Cookie.get('kgtopg.partner.user.CONTENTMAP')
    // const CURRICULUMMAP =  Cookie.get('kgtopg.partner.user.CURRICULUMMAP')
    // const EBOOKUPLOAD =Cookie.get('kgtopg.partner.user.EBOOKUPLOAD')
    // const TRAINER = Cookie.get('kgtopg.partner.user.TRAINER')


    const roles = Cookie.get('kgtopg.partner.user.Roles')
    const CONTENTUPLOAD = (roles && roles.includes("CONTENTUPLOAD"))
    const QUALITYASSURANCE = (roles && roles.includes("QUALITYASSURANCE"))
    const SUPERADMIN = (roles && roles.includes("SUPERADMIN"))
    const CONTENTMAP = (roles && roles.includes("CONTENTMAP"))
    const CURRICULUMMAP = (roles && roles.includes("CURRICULUMMAP"))
    const EBOOKUPLOAD = (roles && roles.includes("EBOOKUPLOAD"))
    const TRAINER = (roles && roles.includes("TRAINER"))


    console.log("role status",
        CONTENTUPLOAD,
        QUALITYASSURANCE,
        SUPERADMIN,
        CONTENTMAP,
        CURRICULUMMAP,
        EBOOKUPLOAD,
        TRAINER
    )

    return (
        <div style={{ height: "75vh" }}>

            <div>
                {SUPERADMIN ?
                    <Link to="/publisher-ebook/dashboard" style={{ textDecoration: "none" }} >
                        <MenuItem selected={dashSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <DashboardOutlinedIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Dashboard" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                    :
                    <></>
                }

                {(SUPERADMIN || EBOOKUPLOAD) ?
                    <Link to="/publisher-ebook/books" style={{ textDecoration: "none" }} >
                        <MenuItem selected={eBookSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <MenuBookOutlinedIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="My Books" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                    :
                    <></>

                }

                {(SUPERADMIN || TRAINER) ?
                    <Link to="/publisher-ebook/trainer/trainer-videos" style={{ textDecoration: "none" }} >
                        <MenuItem selected={trnSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <RecordVoiceOverIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Trainer" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                    :
                    <></>

                }

                {(SUPERADMIN || CURRICULUMMAP) ?
                    <Link to="/publisher-ebook/curriculum-map" style={{ textDecoration: "none" }}>
                        <MenuItem selected={mapsSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <PostAddIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Curriculum" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                    :
                    <></>
                }


                {
                    SUPERADMIN ?
                        <Link to="/publisher-ebook/users" style={{ textDecoration: "none" }}>
                            <MenuItem selected={userSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                <ListItemIcon>
                                    <div className="Finished">
                                        <GroupOutlinedIcon />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Subscribers" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                        </Link>
                        :
                        <></>
                }

            </div>


        </div>
    )
}

export default SidebarContent;