import axios from "axios";
import Constants from "../../resource/Constants"
import { PUBLISHERTEACHDETAILS_LIST_FAIL, PUBLISHERTEACHDETAILS_LIST_REQUEST, PUBLISHERTEACHDETAILS_LIST_SUCCESS } from "../constants/TeachPreviewConstants";


const listPusblisherTeachGet = (leftPage, rightPage, bookId) => async (dispatch) => {

    try {
        console.log("parameters at publishers list", leftPage, rightPage, bookId)
        dispatch({ type: PUBLISHERTEACHDETAILS_LIST_REQUEST })
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/lesplan-page/?book_id=${bookId}&first_page=${leftPage}&last_page=${rightPage}`)
        //  const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/lesplan-page/?book_id=17&first_page=1&last_page=4`)

        console.log("Publishers List ======================", data)
        dispatch({ type: PUBLISHERTEACHDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: PUBLISHERTEACHDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listPusblisherTeachGet }