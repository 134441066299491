import { ACTIVITYAPPROVALCONTDETAILS_LIST_FAIL, ACTIVITYAPPROVALCONTDETAILS_LIST_REQUEST, ACTIVITYAPPROVALCONTDETAILS_LIST_SUCCESS, ACTIVITYAPPROVALPUBDETAILS_LIST_FAIL, ACTIVITYAPPROVALPUBDETAILS_LIST_REQUEST, ACTIVITYAPPROVALPUBDETAILS_LIST_SUCCESS, ACTIVITYLISTCONTDETAILS_LIST_FAIL, ACTIVITYLISTCONTDETAILS_LIST_REQUEST, ACTIVITYLISTCONTDETAILS_LIST_SUCCESS, ACTIVITYLISTPUBDETAILS_LIST_FAIL, ACTIVITYLISTPUBDETAILS_LIST_REQUEST, ACTIVITYLISTPUBDETAILS_LIST_SUCCESS } from "../constants/ActivityConstants";

function activityListContGetReducer(state = {actContGet: [] }, action) {

    switch (action.type) {
        case ACTIVITYLISTCONTDETAILS_LIST_REQUEST:
            return { loadingActContGet: true }
        case ACTIVITYLISTCONTDETAILS_LIST_SUCCESS:
            return { loadingActContGet: false, actContGet: action.payload };
        case ACTIVITYLISTCONTDETAILS_LIST_FAIL:
            return { loadingActContGet: false, errorActContGet: action.payload }
        default:
            return state;
    }

}
function activityListPubGetReducer(state = { actPubGet: [] }, action) {

    switch (action.type) {
        case ACTIVITYLISTPUBDETAILS_LIST_REQUEST:
            return { loadingActPubGet: true }
        case ACTIVITYLISTPUBDETAILS_LIST_SUCCESS:
            return { loadingActPubGet: false, actPubGet: action.payload };
        case ACTIVITYLISTPUBDETAILS_LIST_FAIL:
            return { loadingActPubGet: false, errorActPubGet: action.payload }
        default:
            return state;
    }

}
function activityContUpdtReducer(state = { actContPut: [] }, action) {

    switch (action.type) {
        case ACTIVITYAPPROVALCONTDETAILS_LIST_REQUEST:
            return { loadingActContPut: true }
        case ACTIVITYAPPROVALCONTDETAILS_LIST_SUCCESS:
            return { loadingActContPut: false, actContPut: action.payload };
        case ACTIVITYAPPROVALCONTDETAILS_LIST_FAIL:
            return { loadingActContPut: false, errorActContPet: action.payload }
        default:
            return state;
    }

}
function activityPubUpdtReducer(state = { actPubPut: [] }, action) {

    switch (action.type) {
        case ACTIVITYAPPROVALPUBDETAILS_LIST_REQUEST:
            return { loadingActPubPut: true }
        case ACTIVITYAPPROVALPUBDETAILS_LIST_SUCCESS:
            return { loadingActPubPut: false, actPubPut: action.payload };
        case ACTIVITYAPPROVALPUBDETAILS_LIST_FAIL:
            return { loadingActPubPut: false, errorActPubPut: action.payload }
        default:
            return state;
    }

}

export {
    activityListContGetReducer,
    activityListPubGetReducer,
    activityContUpdtReducer,
    activityPubUpdtReducer
}