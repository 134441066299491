import axios from "axios";
import Constants from "../../resource/Constants";
import {
    CURRICULUMMAP_LIST_REQUEST, CURRICULUMMAP_LIST_SUCCESS,
    CURRICULUMMAP_LIST_FAIL,
    CURRICULUMMAP_ADD_REQUEST,
    CURRICULUMMAP_ADD_SUCCESS,
    CURRICULUMMAP_ADD_FAILURE,
    FILTERED_LIST_REQUEST, FILTERED_LIST_SUCCESS,
    FILTERED_LIST_FAIL,
    CURRICULUMMAPFILTER_LIST_REQUEST,
    CURRICULUMMAPFILTER_LIST_SUCCESS,
    CURRICULUMMAPFILTER_LIST_FAIL
} from "../constants/CurriculumMapConstants";
import Cookie from 'js-cookie';


const listCurriculumGet = (pubId) => async (dispatch) => {
    try {
        dispatch({ type: CURRICULUMMAP_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + "/pub-map/?pub_id=" + pubId)
        console.log("data in Curriculum map action", pubId, data)
        dispatch({ type: CURRICULUMMAP_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: CURRICULUMMAP_LIST_FAIL, payload: error.message })

    }
}


const filterMaps = (data) => async (dispatch) => {
    try {
        dispatch({ type: FILTERED_LIST_REQUEST })

        // now update data
        console.log(`filtered data ${JSON.stringify(data)}`)
        dispatch({ type: FILTERED_LIST_SUCCESS, payload: data })
    } catch (err) {
        dispatch({ type: FILTERED_LIST_FAIL, payload: err.message })
    }
}


const postNewCurriculum = (data) => async (dispatch) => {
    try {
        // data variable is the new curriculum-map object to be added to previous list

        dispatch({ type: CURRICULUMMAP_ADD_REQUEST })

        // attach new object to the old list and send new list as payload
        const { newObj, mapList } = data;
        let newData = mapList;
        newData.data.unshift(newObj);

        console.log('now adding new map to curriculum list')
        dispatch({ type: CURRICULUMMAP_ADD_SUCCESS, payload: newData })

    } catch (error) {
        dispatch({ type: CURRICULUMMAP_ADD_FAILURE, payload: error.message })
    }
}

const currCurriculum = (data) => {
    return async (dispatch) => {
        try {

        } catch (error) {
            dispatch({ type: CURRICULUMMAP_ADD_FAILURE, payload: error.message })
        }
    }

}


const listCurriculuFilterGet = (InstId,boardId,StdId,SubId) => async (dispatch) => {
    try {
        dispatch({ type: CURRICULUMMAPFILTER_LIST_REQUEST })
        const ProvId = Cookie.get('kgtopg.partner.user.id')
        // const url = `https://api5.kgtopg.com/get-map/?subject_id=108&cls_std=10&inst_type_id=10001&inst_board_id=10003`
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/get-map/?subject_id=${SubId}&cls_std=${StdId}&inst_type_id=${InstId}&inst_board_id=${boardId}&publisher_id=${ProvId}`)
        console.log("data in Curriculum map action filter", ProvId, data)
        dispatch({ type: CURRICULUMMAPFILTER_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: CURRICULUMMAPFILTER_LIST_FAIL, payload: error.message })

    }
}

export { listCurriculumGet, filterMaps, postNewCurriculum, listCurriculuFilterGet }
