import axios from "axios";
import { ALLEVENTSFILGET_LIST_FAIL, ALLEVENTSFILGET_LIST_REQUEST, ALLEVENTSFILGET_LIST_SUCCESS, ALLEVENTSGET_LIST_FAIL, ALLEVENTSGET_LIST_REQUEST, ALLEVENTSGET_LIST_SUCCESS, ALLEVENTSSRCGET_LIST_FAIL, ALLEVENTSSRCGET_LIST_REQUEST, ALLEVENTSSRCGET_LIST_SUCCESS, ALLVIDEOSGET_LIST_FAIL, ALLVIDEOSGET_LIST_REQUEST, ALLVIDEOSGET_LIST_SUCCESS, SINGLEEVENTGET_LIST_REQUEST, SINGLEEVENTSGET_LIST_FAIL, SINGLEEVENTSGET_LIST_SUCCESS, SINGLEVIDEOGET_LIST_FAIL, SINGLEVIDEOGET_LIST_REQUEST, SINGLEVIDEOGET_LIST_SUCCESS, SUBSCRIBERSDETAILSGET_LIST_FAIL, SUBSCRIBERSDETAILSGET_LIST_REQUEST, SUBSCRIBERSDETAILSGET_LIST_SUCCESS, TRAINTEACHVIDEOSGET_LIST_FAIL, TRAINTEACHVIDEOSGET_LIST_REQUEST, TRAINTEACHVIDEOSGET_LIST_SUCCESS, TRAINTEACHVIDEOSTPCGET_LIST_FAIL, TRAINTEACHVIDEOSTPCGET_LIST_REQUEST, TRAINTEACHVIDEOSTPCGET_LIST_SUCCESS } from "../../constants/Trainer/AllEventsGetConstants";
import Constants from "../../../resource/Constants"
import Cookie from 'js-cookie';
const ProvId = Cookie.get('kgtopg.partner.user.id')

const listAllEventsGet = () => async (dispatch) => {

    try {
        dispatch({ type: ALLEVENTSGET_LIST_REQUEST })
    
        // const { data } = await axios.get(Constants.Application.PARTNER_END_URL +`/get-all/`)
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/publisher-event/?publisher_id=${ProvId}`)
        console.log("allEventsGet ======================", data)
        dispatch({ type: ALLEVENTSGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: ALLEVENTSGET_LIST_FAIL, payload: error.message })

    }
}

const listAllVideosGet = (id) => async (dispatch) => {

    try {
        dispatch({ type: ALLVIDEOSGET_LIST_REQUEST })
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL +`/get-video/?event_id=${id}`)
        console.log("listAllVideosGet ======================", data)
        dispatch({ type: ALLVIDEOSGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: ALLVIDEOSGET_LIST_FAIL, payload: error.message })

    }
}

const listAllEventsFilGet = (instId,BoardId,StdId,SubId) => async (dispatch) => {

    try {
        dispatch({ type: ALLEVENTSFILGET_LIST_REQUEST })
        const url= Constants.Application.PARTNER_END_URL +`/filter-event/?institute_type_id=${instId}&institute_board_id=${BoardId}&subject_id=${SubId}&classroom_std=${StdId}&publisher_id=${ProvId}`
        const { data } = await axios.get(url)
        console.log("listAllEventsFilGet ======================", data)
        dispatch({ type: ALLEVENTSFILGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: ALLEVENTSFILGET_LIST_FAIL, payload: error.message })

    }
}

const listAllEventsSrcGet = (searchKey) => async (dispatch) => {

    try {
        dispatch({ type: ALLEVENTSSRCGET_LIST_REQUEST })
        const url= Constants.Application.PARTNER_END_URL +`/search-event/?search=${searchKey}`
        const { data } = await axios.get(url)
        console.log("listAllEventsSrcGet ======================", data)
        dispatch({ type: ALLEVENTSSRCGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: ALLEVENTSSRCGET_LIST_FAIL, payload: error.message })

    }
}



const listSingleEventGet = (id) => async (dispatch) => {

    try {
        dispatch({ type: SINGLEEVENTGET_LIST_REQUEST })
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/event/${id}`)
        console.log("listSingleEventGet ======================", data)
        dispatch({ type: SINGLEEVENTSGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: SINGLEEVENTSGET_LIST_FAIL, payload: error.message })

    }
}



const listSingleVideoGet = (id) => async (dispatch) => {

    try {
        dispatch({ type: SINGLEVIDEOGET_LIST_REQUEST })
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/event-video/${id}`)
        console.log("listSingleVideoGet ======================", data)
        dispatch({ type: SINGLEVIDEOGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: SINGLEVIDEOGET_LIST_FAIL, payload: error.message })

    }
}


const listSubscribersGet = (id) => async (dispatch) => {

    try {
        dispatch({ type: SUBSCRIBERSDETAILSGET_LIST_REQUEST })
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/get-subscribes/?event_id=${id}`)
        console.log("listSubscribersGet ======================", data)
        dispatch({ type: SUBSCRIBERSDETAILSGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: SUBSCRIBERSDETAILSGET_LIST_FAIL, payload: error.message })

    }
}

const listTeachVideosGet = (instId,boardId,subId,stdId) => async (dispatch) => {

    try {
        dispatch({ type: TRAINTEACHVIDEOSGET_LIST_REQUEST })
        const url = Constants.Application.PARTNER_END_URL +`/filter-videos/?institute_type_id=${instId}&institute_board_id=${boardId}&subject_id=${subId}&classroom_std=${stdId}&publisher_id=${ProvId}`
        const { data } = await axios.get(url)
        console.log("listTeachVideoGet url",url)
        console.log("listTeachVideosGet ======================", data)
        dispatch({ type: TRAINTEACHVIDEOSGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: TRAINTEACHVIDEOSGET_LIST_FAIL, payload: error.message })

    }
}


const listTeachVideosTopGet = (instId,boardId,subId,stdId,chapId) => async (dispatch) => {

    try {
        dispatch({ type: TRAINTEACHVIDEOSTPCGET_LIST_REQUEST })
        const url = Constants.Application.PARTNER_END_URL +`/topic-videos/?institute_type_id=${instId}&institute_board_id=${boardId}&subject_id=${subId}&classroom_std=${stdId}&publisher_id=${ProvId}&chapter_id=${chapId}`
        const { data } = await axios.get(url)
        console.log("listTeachVideosTopGet url",url)
        console.log("listTeachVideosTopGet ======================", data)
        dispatch({ type: TRAINTEACHVIDEOSTPCGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: TRAINTEACHVIDEOSTPCGET_LIST_FAIL, payload: error.message })

    }
}


export { 
    listAllEventsGet,
    listAllVideosGet,
    listSingleEventGet,
    listSingleVideoGet,
    listAllEventsFilGet,
    listAllEventsSrcGet,
    listSubscribersGet,
    listTeachVideosGet,
    listTeachVideosTopGet,
}