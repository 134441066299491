import { SUBDETAILS_LIST_FAIL, SUBDETAILS_LIST_REQUEST, SUBDETAILS_LIST_SUCCESS } from "../constants/subjectConstants";


function subDetailsReducer(state = { subDetails: [] }, action) {
    switch (action.type) {
        case SUBDETAILS_LIST_REQUEST:
            return { loadingSub: true }
        case SUBDETAILS_LIST_SUCCESS:
            return { loadingSub: false, subDetails: action.payload };
        case SUBDETAILS_LIST_FAIL:
            return { loadingSub: false, error: action.payload }
        default:
            return state;
    }

}

export { subDetailsReducer }