import axios from "axios";
import Constants from "../../resource/Constants";
import { LESSONPLANS_LIST_REQUEST, LESSONPLANS_LIST_SUCCESS, LESSONPLANS_LIST_FAILURE,
LESSONPLANS_REQUEST, LESSONPLANS_SUCCESS, LESSONPLANS_FAILURE } from "../constants/lessonPlansConstants";
import MapUnitAPI from '../../http/curriculummapunit'

const getLessonPlans = (id) => async (dispatch) => {
    try {

        console.log("id at actions", id)


        dispatch({ type: LESSONPLANS_LIST_REQUEST })

        const response = await MapUnitAPI.getLessonPlans(id) // lesson id
        const { data } = response.data
        console.log("response in list unit lesson plans get ===========", response)
       dispatch({ type: LESSONPLANS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: LESSONPLANS_LIST_FAILURE, payload: error.message })

    }
}

const postNewLessonPlan = (data) => async (dispatch) => {

    dispatch({ type: LESSONPLANS_REQUEST })

    try {
        // data variable is the new curriculum-map object to be added to previous list

        // attach new object to the old list and send new list as payload
        const { newObj, lessonPlanList } = data;

        console.log(`lessonplanlist is ${JSON.stringify(lessonPlanList)}`)
        let newData = lessonPlanList;
        newData.data.Units.push(newObj);

        console.log('now adding new lesson plan to plan list')
        dispatch({ type: LESSONPLANS_SUCCESS, payload: newData })

    } catch (error) {
        dispatch({ type: LESSONPLANS_FAILURE, payload: error.message })
    }
}


export { getLessonPlans, postNewLessonPlan }
