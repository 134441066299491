import { BOARDDETAILS_LIST_FAIL, BOARDDETAILS_LIST_REQUEST, BOARDDETAILS_LIST_SUCCESS } from "../constants/boardDetailsConstants";

function boardDetailsReducer(state ={boardDetails:[]},action) {

    switch (action.type) {
        case BOARDDETAILS_LIST_REQUEST:
            return { loading: true }
        case BOARDDETAILS_LIST_SUCCESS:
            return {loading:false,boardDetails:action.payload};
        case BOARDDETAILS_LIST_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state;
    }

}

export { boardDetailsReducer }