import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles, useStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import LeftCard from "./slots/LeftCard";
import { Helmet } from "react-helmet";
import { CircularProgress, Container } from "@material-ui/core";
import { Route, Link, HashRouter, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { listBoardGet } from "../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../redux/actions/InstituteDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import { listStdGet } from "../../redux/actions/standardAction";
import Menu from "@material-ui/core/Menu";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { listPubUsers } from "../../redux/actions/PubUsersListAction";
import { listSubGet } from "../../redux/actions/subjectsAction";
import Cookie from "js-cookie";
import PublisherAPI from "../../http/publisher";
import { Alert, AlertTitle } from "@material-ui/lab";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Copyright from "../user/slots/Copyright";

import firebase from "firebase";
import Constants from '../../resource/Constants';
import FileUploader from "react-firebase-file-uploader";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from "moment";
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ReceiptIcon from '@mui/icons-material/Receipt';

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}



function Alert2(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles5 = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const useStyles7 = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    minWidth: '98%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const useStyles8 = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    border: 0,
    background: 'lightgray',
    padding: 0,
    height: 30,
    margin: 0
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectControl: {
    paddingBottom: 0,
  }
}));


function createData(name, subject, standard, carbs, protein) {
  return { name, subject, standard, carbs, protein };
}

function PriceFormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [type, setType] = React.useState('PENDING')
  const [payType, setPayType] = React.useState('CASH')
  const [amount, setAmount] = React.useState('')
  const [txn, setTxn] = React.useState('')
  const [bnk, setBnk] = React.useState('')
  const [payProof, setPayProof] = React.useState(true)
  const [ebookDownloadUrls, setEbookDownloadUrls] = useState("");
  const [ImageDownloadUrls, setImageDownloadUrls] = React.useState('')
  const [progress, setProgress] = useState(0);
  const [progressBook, setProgressBook] = useState(0)
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0)
  const [selectedFile, setSelectedFile] = useState("")

  const classes = useStyles7()

  const { row, edit, month } = props

  const handleClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSavePrice = async () => {

    const provId = 0
    const userId = Cookie.get("kgtopg.partner.user.id");

    let status = 0 // always pending
    if (type == 'COMPLETED')
      status = 1

    const body = {
      publisher_id: userId,
      cont_prov_id: provId,
      amount: amount,
      payment_mode: payType,
      txn_id: txn,
      status: 0, // always pending first
      proof_picture: ImageDownloadUrls,
      month_number: month
    }

    let resp;

    if (!edit) {
      resp = await PublisherAPI.postSavePublisherPayment(body)
    } else {
      resp = await PublisherAPI.putSavePublisherPayment(body)
    }
    // show snackbar
    handleClick()
    setTimeout(function () {
      handleClose()

      props.onSavedPayment()

    }, 2000)

  }

  const handleUpdatePrice = async () => {

    const provId = row.cont_prov_id
    const userId = Cookie.get("kgtopg.partner.user.id");

    let status = 0 // always pending
    if (type == 'COMPLETED')
      status = 1

    const body = {
      publisher_id: userId,
      cont_prov_id: provId,
      amount: amount,
      payment_mode: payType,
      txn_id: txn,
      status: 0, // always pending first
      proof_picture: ImageDownloadUrls
    }

    const resp = await PublisherAPI.putSavePublisherPayment(body)
    // show snackbar
    handleClick()
    setTimeout(function () {
      handleClose()

      props.onSavedPayment()

    }, 2000)

  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setPayType(value)

    // logic to show proof button
    if (value === 'CASH') {
      setPayProof(true)
    } else {
      setPayProof(false)
    }

  }

  const handleAmountChange = (event) => {
    const { name, value } = event.target
    setAmount(value)

    props.onReload()
  }

  const handleTxnChange = (event) => {
    const { name, value } = event.target
    setTxn(value)

    props.onReload()
  }

  const handleBnkChange = (event) => {
    const { name, value } = event.target
    setBnk(value)

    props.onReload()
  }

  const handleUploadImageSuccess = async filename => {
    const downloadUrl = await firebase
      .storage()
      .ref("ebookpaymentproof")
      .child(filename)
      .getDownloadURL()


    console.log("image download url", downloadUrl)
    setImageDownloadUrls(downloadUrl)
    if (downloadUrl) {
      setProgress(0)
    }
  }


  const handleUploadStart = () => {
    setIsUploading(true)
    setProgress(0)
  }



  const handleProgressImage = progress => {
    setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  }
  const handleProgressBook = progress => {
    setProgressBook((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  }

  const handleUploadError = error => {
    setIsUploading(false)
  }


  const handleInputChange = (event) => {
    setProgressBook(true)
    setSelectedFile(event.target.files[0])
    if (event.target.files[0] && selectedFile != null) {
      setEbookDownloadUrls(true)
      setProgressBook(false)

    }


    console.log("selected file in handleInputChange", selectedFile, event.target.files[0])
  }

  console.log('payType ' + payType)
  console.log('ImageDownloadUrls ' + ImageDownloadUrls)
  const enableSave = (
    ((payType == 'CASH') && (bnk && amount > 0 && ImageDownloadUrls))
    || (payType != 'CASH' && (bnk && amount > 0))
  )

  return (
    <div>
      {/* <Button color="inverse" variant="contained">
        Update Payment Details
      </Button> */}
      {!edit ?
        <Typography
          onClick={handleClickOpen}
          variant="body2"
          style={{
            color: 'steelblue',
            cursor: 'pointer'
          }}>
          UPDATE PAYMENT DETAILS
        </Typography>
        :
        <Typography
          onClick={handleClickOpen}
          variant="caption"
          color="blue"
          style={{
            color: 'steelblue',
            cursor: 'pointer'
          }}>
          Edit Payment Details
        </Typography>
      }

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add payment </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a payment towards the multimedia provider with details
          </DialogContentText>

          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Payment type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              default={payType}
              value={payType}
              onChange={handleChange}
            >
              <MenuItem value={'CASH'}>Cash Deposit</MenuItem>
              <MenuItem value={'ONLINE'}>Online</MenuItem>
              <MenuItem value={'GOOGLEPAY'}>Google Pay</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Payment status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              onChange={handleChange}
            >
              <MenuItem value={'PENDING'}>PENDING</MenuItem>
              <MenuItem value={'PROCESSING'}>PROCESSING</MenuItem>
              <MenuItem value={'COMPLETED'}>COMPLETED</MenuItem>
              
            </Select>
          </FormControl> */}
          <TextField
            // defaultValue={txn}
            value={bnk}
            onChange={handleBnkChange}

            margin="dense"
            id="name"
            label="Bank Details"

            placeholder="Enter the Bank details"
            fullWidth
          />
          {!payProof &&
            <TextField
              // defaultValue={txn}
              value={txn}
              onChange={handleTxnChange}

              margin="dense"
              id="name"
              label="Transaction Details"

              placeholder="Enter the transaction details (ID)"
              fullWidth
            />
          }
          <TextField
            // defaultValue={amount}
            value={amount}
            onChange={handleAmountChange}

            margin="dense"
            id="name"
            label="Payment Amount"
            type="numeric"
            placeholder="Enter the payment amount"
            fullWidth
          />

          {payProof &&

            <>

              <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 0, marginLeft: 10, marginRight: 20, width: "100%" }}>
                <label style={{ fontFamily: "Poppins, Helvetica, sans-serif", cursor: 'pointer' }}>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      cursor: 'pointer'
                    }}>
                    <ReceiptIcon style={{
                      color: 'steelblue'
                    }} />
                    <Typography
                      variant='body1'
                      style={{
                        marginLeft: 10,
                        color: "#464E5F",
                      }}>
                      Upload Payment Proof
                    </Typography>
                    <FileUploader
                      hidden
                      accept="image/*"
                      storageRef={firebase.storage().ref('ebookpaymentproof')}
                      onUploadStart={handleUploadStart}
                      onUploadError={handleUploadError}
                      onUploadSuccess={handleUploadImageSuccess}
                      onProgress={handleProgressImage}
                    />
                  </div>
                </label>

                <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: 20 }}>
                  {progress ?

                    <CircularProgress />
                    :
                    <></>
                  }
                </div>

                <div style={{ position: 'relative', display: "flex", justifyContent: "flex-start", paddingLeft: 20 }}>
                  {ImageDownloadUrls &&
                    <>
                      <div
                        style={{
                          position: 'absolute',
                          top: 5,
                          right: 5,
                          cursor: 'pointer',
                          zIndex: 3
                        }}>
                        <label>
                          <EditIcon />
                          <FileUploader
                            hidden
                            accept="image/*"
                            storageRef={firebase.storage().ref('ebookpaymentproof')}
                            onUploadStart={handleUploadStart}
                            onUploadError={handleUploadError}
                            onUploadSuccess={handleUploadImageSuccess}
                            onProgress={handleProgressImage}
                          />

                        </label>
                      </div>
                      <img src={ImageDownloadUrls} alt="preview Image" style={{ alignSelf: "flex-start", display: "flex", width: 100 }} />
                      {/* {ImageDownloadUrls} */}
                    </>
                  }
                </div>
              </div>


            </>

            // <Button color="secondary" variant="contained" onClick={() => { }}
            //   style={{
            //     marginTop: 10
            //   }}>
            //   Upload Payment Proof
            // </Button>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={!enableSave} onClick={handleSavePrice} color="primary">
            Save
          </Button>
          <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity="success">
              Payment to multimedia record saved successfully..
            </Alert>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </div >
  );
}

function SimpleMenu(props) {
  const classes = useStyles2()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { row, status, edit } = props

  const pending = status == 0;
  const approved = status == 1;
  const declined = status == 2;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = (row, stat) => {
    props.handleChangeStatus(row, stat);
    handleClose()
  }

  return (
    <div>


      <PriceFormDialog
        edit={edit}
        row={row}
        onReload={props.onReload}
        onSavedPayment={props.onSavedPayment}
        month={props.month} />

      {/* 
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>

        </MenuItem>
        
        <MenuItem onClick={handleClose}>
          <Link className={classes.link} to={`/publisher-ebook/pay-out-history/${row.cont_prov_id}`} style={{ textDecoration: "none" }} >
            <Button>
              View History
            </Button>
          </Link>
        </MenuItem>

      </Menu> */}
    </div>
  );
}

const SalesSelect = (props) => {
  const { row, status } = props

  console.log(`row in sales select ${JSON.stringify(row)}`)
  const classes = useStyles8();
  const [state, setState] = React.useState({
    stat: row.hasOwnProperty('status') ? row.status : 'Hold',
    name: 'hai',
  });

  console.log(`state stat ${state.stat}`)

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: event.target.value,
      stat: value
    });

    props.handleChangeStatus(row, value)
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {/* <InputLabel htmlFor="age-native-simple">Status</InputLabel> */}
      <Select
        native
        value={state.stat}
        onChange={handleChange}
        inputProps={{
          name: 'age',
          id: 'outlined-age-native-simple',
        }}
        style={{
          border: 0,
          height: 30,
          padding: 0,
          fontSize: 13
        }}
        classes={classes.selectControl}
        size="small"
      >
        {/* <option aria-label="None" value="" /> */}

        <option value={'Confirmed'}
          onChange={handleChange}>Confirmed</option>
        <option value={'Renewed'}
          onChange={handleChange}>Renewed</option>
        <option value={'Hold'}
          onChange={handleChange}>Hold</option>
      </Select>
    </FormControl>
  )
}

function Copyright2() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://kgtopg.com/">
        KGToPG
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 250;

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbar1: {
    paddingRight: 24,
    height: 40,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon1: {
    top: "45vh",
    bottom: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft:70,
    backgroundColor: "#1976d2",
    color: "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "blue",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    scrollbarColor: "green",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  large: {
    width: "80%",
    height: 50,
    borderRadius: 0,
  },

  link: {
    color: 'slategrey',
    fontWeight: 'bold'
  }
}));

const useStyles3 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  root1: {
    maxWidth: 200,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(3),
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  formControl1: {
    margin: theme.spacing(1),
    // minWidth: 315,
    minHeight: 30,
    width: '100%'
  },
  primary: {
    width: "25%",
    height: 50,
  },
}));

const useStyles4 = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const rows = [
  createData("Andhra Pradesh Govt.Schools", "Maths", 10),
  createData("Telegana Govt.Schools", "Maths", 10),
  createData("Delhi Public School", "Maths", 10),
  createData("Oakridge International School", "Maths", 10),
  createData("Jubilee Hills Public School", "Maths", 10),
  createData("Glendale Academy", "Maths", 10),
  createData("Bharatiya Vidya Bhavan's Public School", "Maths", 10),
  createData("Andhra Pradesh Govt.Schools1", "Maths", 10),
  createData("Telegana Govt.Schools1", "Maths", 10),
  createData("Delhi Public School1", "Maths", 10),
  createData("Oakridge International Schoo1", "Maths", 10),
  createData("Jubilee Hills Public School1", "Maths", 10),
  createData("Glendale Academy1", "Maths", 10),
  createData("Bharatiya Vidya Bhavan's Public School1", "Maths", 10),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis =
    array.length > 0 ? array.map((el, index) => [el, index]) : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "User",
  },
  { id: "Subject", numeric: false, disablePadding: false, label: "Subject" },
  { id: "Date", numeric: false, disablePadding: false, label: "Standard" },
  // { id: "Invitation", numeric: false, disablePadding: false, label: "Invitation" },
  { id: "Approval Status", numeric: false, disablePadding: false, label: "Approval Status" },
  { id: "Actions", numeric: false, disablePadding: false, label: "Actions" },
];

const headCells2 = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "State",
  },
  { id: "User Count", numeric: false, disablePadding: false, label: "User Count" },
  { id: "Active", numeric: false, disablePadding: false, label: "Active" },
  { id: "Inactive", numeric: false, disablePadding: false, label: "Inactive" },
  // { id: "Action2", numeric: false, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHead2(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
        </TableCell>
        {headCells2.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Sample Books Requests
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const FilterFields = (props) => {
  const classes = useStyles3();
  const [instType, setInstType] = useState("");
  const [boardType, setBoardType] = useState("");
  const [subjectType, setSubjectType] = useState("");
  const [standardType, setStandardType] = useState("");
  const [pubId, setPubId] = useState(2);

  const dispatch = useDispatch();

  const handleInstChange = (event) => {
    console.log("called handleInstChange");
    console.log(`new institute type ${event}`);
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });
    dispatch(listBoardGet(value));
    setInstType(value);
    // dispatch(listPubUsers(value, boardType, standardType, subjectType));

    props.setInstType(value)

  };

  const handleBoardChange = (event) => {
    console.log("called handleBoardChange");
    console.log(`new board  ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`);

    setBoardType(value);
    dispatch(listStdGet(instType, value));
    dispatch(listSubGet(value));
    // dispatch(listPubUsers(instType, value, standardType, subjectType));

    props.setBoardType(value)

  };

  const handleSubChange = async (event) => {
    const userId = Cookie.get("kgtopg.partner.user.id");
    console.log("called handleSubChange");
    console.log(`new subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType(value);

    if (value) {
      // dispatch(listPubUsers(instType, boardType, standardType, value));

      const resp = await PublisherAPI.getSubscribers(userId, value, standardType)
      const subs = (resp && resp.data) ? resp.data : []
      props.handleSetSubs(subs)
    }

    props.setSubType(value)
  };

  const handleStdChange = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setStandardType(value);
    //dispatch(listSubGet(instType))

    // if(value){
    //   console.log("handlestdChange ==============================",instType,boardType,value,pub_id)
    //   dispatch(listSearchEbookGet(instType,boardType,value,pub_id))
    // }
    // dispatch(listPubUsers(instType, boardType, value, subjectType));

    props.setClsStd(value)

  };
  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;

  const instDetailsGet = useSelector((state) => state.instDetails);
  const { loadingInst, instDetails, errorInst } = instDetailsGet;

  const subDetailsGet = useSelector((state) => state.subDetails);
  const { loadingSub, subDetails, errorSub } = subDetailsGet;

  const stdDetailsGet = useSelector((state) => state.stdDetails);
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

  const pubUsersGet = useSelector((state) => state.pubUsersList);
  const { loadingPubUsers, pubUsers, errorPubUsers } = pubUsersGet;

  {
    loadingPubUsers && pubUsersGet
      ? console.log("users data is loading")
      : console.log("users data is ", pubUsers);
  }

  let rows = [];
  if (!loadingPubUsers && pubUsersGet && pubUsers && pubUsers.data) {
    pubUsers.data.map((users, index) => {
      if (users && users.status) {
        users.status.map((usersList, index) => {
          rows.push(usersList);
        });
      }
    });
  }
  // props.usersList(rows)

  console.log("users list while mapping", rows);

  useEffect(() => {
    dispatch(listInstGet());
    return () => {
      //
    };
  }, []);

  return (
    <Paper elevation={2} style={{ marginLeft: 15, width: '98%' }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          overflow: "auto",
          overflowX: "initial",
          scrollbarWidth: "thin",

        }}
      >
        {/* <div > */}

        {/* <div style={{ display: "flex", justifyContent: "center" ,marginTop:10}}>

                          <TextField 
                          id="outlined-basic" 
                          label="Search by Name" 
                          variant="outlined"
                          defaultValue={title}
                          onChange={handleTitleChange}
                    
                              style={{
                                  width:250,
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  display:"inline-block",
                                  lineHeight:"140%"

                    
                              }}
                              
                          />
                      </div>

                      <div style={{ display: "flex", justifyContent: "Left" }}>
                      <Typography>
                              <span
                                  style={{
                                      fontSize: 12,
                                      color: "#464E5F",
                                      marginLeft: 5,
                                      fontFamily: "Poppins, Helvetica, sans-serif",
                                  }}
                              >
                                  Search by  <span
                                      style={{
                                          color: "#464E5F",
                                          fontWeight: "600",
                                          fontFamily: "Poppins, Helvetica, sans-serif",
                                          fontSize: 12
                                      }}>
                                    Name
                          </span>
                              </span>
                          </Typography>

                          </div>

                  </div>

*/}

        {instDetailsGet ? (
          <div style={{ display: "flex", width: '25%' }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Institute Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={instType}
                onChange={handleInstChange}
                label="Select Institute Type"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {loadingInst && instDetailsGet ? (
                  console.log("loading trueeee")
                ) : instDetails &&
                  instDetails.data &&
                  instDetails.data.length != 0 ? (
                  instDetails.data.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.institute_type_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {list.institute_type_name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Institutes
                  </MenuItem>
                )}
              </Select>
              {/* 
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Institute
                </span>
              </span> */}
            </FormControl>
          </div>
        ) : (
          <div style={{ display: "flex", width: '25%' }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Institute Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={instType}
                onChange={handleInstChange}
                label="Select Institute Type"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                <MenuItem
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  No Institutes
                </MenuItem>
              </Select>

              {/* <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Institute
                </span>
              </span> */}
            </FormControl>
          </div>
        )}

        {boardDetailsGet &&
          boardDetails &&
          boardDetails.length != 0 &&
          boardDetails.data ? (
          <div style={{ display: "flex", width: '25%' }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Board
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={boardType}
                onChange={handleBoardChange}
                label="Select Board"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {
                  loading && boardDetailsGet ? (
                    console.log("loading trueeee")
                  ) : boardDetails && boardDetails.length != 0 ? (
                    boardDetails.data.map((list, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={list.institute_board_id}
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {list.institute_board_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  )

                  // boardDetailsMock.map((list, index) => {
                  //     return (
                  //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                  //     )
                  // })
                }
              </Select>
              {/* <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Board
                </span>
              </span> */}
            </FormControl>
          </div>
        ) : (
          <div style={{ display: "flex", width: '25%' }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Board
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={boardType}
                onChange={handleBoardChange}
                label="Select Board"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                <MenuItem
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  No Boards{" "}
                </MenuItem>
              </Select>
              {/* <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Board
                </span>
              </span> */}
            </FormControl>
          </div>
        )}

        {stdDetailsGet ? (
          <div style={{ display: "flex", width: '25%' }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Standard
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={standardType}
                onChange={handleStdChange}
                label="Select Standard"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {
                  loadingStd && stdDetailsGet ? (
                    console.log("loading trueeee")
                  ) : stdDetails.length != 0 ? (
                    stdDetails.data.map((list, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={list.id}
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {list.standard}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  )

                  // standardsMockData.map((list, index) => {
                  //     return (
                  //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.stand_name}</MenuItem>
                  //     )
                  // })
                }
              </Select>
              {/* <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Standard
                </span>
              </span> */}
            </FormControl>
          </div>
        ) : (
          <div style={{ display: "flex", width: '25%' }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Standard
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={standardType}
                onChange={handleStdChange}
                label="Select Standard"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                <MenuItem
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  No Standards
                </MenuItem>
              </Select>
              {/* <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Standard
                </span>
              </span> */}
            </FormControl>
          </div>
        )}

        {subDetails ? (
          <div style={{ display: "flex", width: '25%' }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subjectType}
                onChange={handleSubChange}
                label="Select Subject"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {loadingSub && subDetails ? (
                  <MenuItem
                    key={0}
                    value="Please Select Board"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    {" "}
                    Please Select Board{" "}
                  </MenuItem>
                ) : subDetails &&
                  subDetails.data &&
                  subDetails.data.subjects.length != 0 ? (
                  subDetails.data.subjects.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.subject_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {list.subject_name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please Select Institute Type
                  </MenuItem>
                )}
              </Select>
              {/* <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Subject
                </span>
              </span> */}
            </FormControl>
          </div>
        ) : (
          <div style={{ display: "flex", width: '25%' }}>
            <FormControl variant="outlined" className={classes.formControl1}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subjectType}
                onChange={handleSubChange}
                label="Select Subject"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                <MenuItem
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  No Subjects{" "}
                </MenuItem>
              </Select>
              {/* <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Subject
                </span>
              </span> */}
            </FormControl>
          </div>
        )}
      </div>
    </Paper>
  );
};

function InviteFormDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Send Sample Book
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function PayOutList() {
  const classes = useStyles2();
  const classes1 = useStyles1();
  const classes4 = useStyles4();
  const classes5 = useStyles5();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows1, setRows] = useState("");
  const [open, setOpen] = React.useState(false);
  const [instType, setInstType] = React.useState(0)
  const [boardType, setBoardType] = React.useState(0)
  const [subType, setSubType] = React.useState(0)
  const [clsStd, setClsStd] = React.useState(0)
  const [subs, setSubs] = React.useState(null)
  const [provs, setProvs] = React.useState(null)
  const [year, setYear] = React.useState(`2020-2021`)
  const [month, setMonth] = React.useState('January')
  const [savedPayment, setSavedPayment] = React.useState(false)
  const [more, setMore] = React.useState(false)

  const [openInviteSnack, setOpenInviteSnack] = React.useState(false);

  const dispatch = useDispatch();

  const handleInviteClick = () => {
    setOpenInviteSnack(true);
  };

  const handleInviteClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenInviteSnack(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetSubs = (subs) => {
    setSubs(subs)
  }

  const handleSetProvs = (provs) => {
    setProvs(provs)
  }

  const onSavedPayment = (props) => {
    setSavedPayment(true)
  }

  const handleMoreClicked = (event) => {
    setMore(true)
  }

  const getSubs = async () => {
    const userId = Cookie.get("kgtopg.partner.user.id");
    const resp = await PublisherAPI.getSubscribers(userId, subType, clsStd)
    const subs = (resp && resp.data) ? resp.data : []
    handleSetSubs(subs)
  }

  const getPayOut = async () => {
    const userId = Cookie.get("kgtopg.partner.user.id");
    const resp = await PublisherAPI.getPayOut(userId);
    const provs = (resp && resp.data) ? resp.data : []
    handleSetProvs(provs)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pubUsersGet = useSelector((state) => state.pubUsersList);
  const { loadingPubUsers, pubUsers, errorPubUsers } = pubUsersGet;

  {
    loadingPubUsers && pubUsersGet
      ? console.log("users data is loading==============")
      : console.log("users data is ==============================", pubUsers);
  }

  let dynrows = [];
  if (!loadingPubUsers && pubUsersGet && pubUsers && pubUsers.data) {
    pubUsers.data.map((users, index) => {

      // users.status.map((usersList, index) => {
      // dynrows.push(usersList);
      dynrows.push(users)
      // });
    });
  }

  console.log("dynRowsssss=========", dynrows);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangeStatus = async (row, stat) => {
    const userId = Cookie.get("kgtopg.partner.user.id");
    const userName = Cookie.get("kgtopg.partner.user.name");
    const status = (stat) ? stat : 'Hold'
    const disc = row.hasOwnProperty('discount') ? row.discount : 0

    console.log(`stat is ${stat}`)
    console.log(`status is ${status}`)

    // now active row is row
    let isPaid = 0
    if (status == 'Confirmed' || status == 'Renewed')
      isPaid = 1

    let isNew = true
    if (row.hasOwnProperty('partner_publisher_institute_rel_id'))
      isNew = false

    const body = {
      rel_id: row.partner_publisher_institute_rel_id,
      publisher_id: parseInt(userId),
      // sub_id: row.subject_id,
      // staff_id: row.staff_id,
      // classroom_id: row.classroom_id,
      // classroom_std: row.classroom_std,
      institute_id: row.institute_id,
      discount: parseFloat(disc),
      status: status,
      ispaid: isPaid,
      // publisher_name: (userName) ? userName : 'n/a'
    };

    // const resp = await PublisherAPI.postSaveApproveStatus(body);
    await PublisherAPI.postSavePubInstStatus(body, false); // only update

    // not required now
    /*
    if (stat == 1) {
      // get book details with cost data appended
 
      // now save the cost/financials for the school
      const now = new Date();
      const year = now.getFullYear()
      // set 1 year (annual) subscription for school / institute by default
      const startDt = year + '-06-01'
      const endDt = (year + 1) + '-06-01'
 
      const body2 = {
        publisher_id: userId,
        institute_id: row.institute_id,
        cost: 0.0,
        discount: 0.0,
        final_cost: 0.0,
        started_date: startDt,
        end_date: endDt,
        classroom_std: parseInt(row.classroom_std),
        subject_id: parseInt(row.subject_id)
      }
 
      const resp2 = await PublisherAPI.postPubInstCost(body2)
    }
    */
    // window.location.reload()

    // window.alert("Status updated..");

    // show snackbar
    handleInviteClick()

    // reload
    // dispatch(listPubUsers(instType, boardType, clsStd, subType))
    // getSubs()
    getPayOut()
  };

  function onReload() {
    getPayOut()
  }

  const YEARS = (props) => {
    return (

      <FormControl >
        <InputLabel id="demo-simple-select-label">Year</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={year}
          label="Year"
          onChange={handleYearChange}
        >
          <MenuItem value={`2020-2021`}>2020-2021</MenuItem>
          <MenuItem value={`2021-2022`}>2021-2022</MenuItem>
          <MenuItem value={`2022-2023`}>2022-2023</MenuItem>
        </Select>
      </FormControl>

    )
  }

  const MONTHS = (props) => {
    return (

      <FormControl >
        <InputLabel id="demo-simple-select-label">Month</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={month}
          label="Month"
          onChange={handleMonthChange}
        >
          <MenuItem value={`January`}>January</MenuItem>
          <MenuItem value={`February`}>February</MenuItem>
          <MenuItem value={`March`}>March</MenuItem>
        </Select>
      </FormControl>

    )
  }

  const handleChangeStatusO = async (row, stat) => {
    const userId = Cookie.get("kgtopg.partner.user.id");
    const userName = Cookie.get("kgtopg.partner.user.name");

    console.log('calling change status api')
    const body = {
      publisher_cost_id: row.partner_publisher_cost_id,
      publisher_id: userId,
      is_paid: stat
    };

    const resp = await PublisherAPI.postSavePaidStatus(body);
    // window.location.reload()

    // window.alert("Status updated..");

    // show snackbar
    handleInviteClick()

    // reload
    // dispatch(listPubUsers(instType, boardType, clsStd, subType))
    getSubs()
  };

  const handleYearChange = (event) => {
    const { name, value } = event.target;
    console.log('value is ', value)

    setYear(value)
  }

  const handleMonthChange = (event) => {
    const { name, value } = event.target;
    console.log('value is ', value)

    setMonth(value)
  }

  useEffect(() => {
    getPayOut()

    return {

    }
  }, [])


  const handleInvite = async (row, stat) => {
    const userId = Cookie.get("kgtopg.partner.user.id");

    console.log(`handleinvite row ${JSON.stringify(row)}`);
    const body2 = {
      publisher_id: parseInt(userId),
      sub_id: parseInt(row.subject_id),
      staff_id: parseInt(row.staff_id),
      classroom_id: parseInt(row.classroom_id),
      partner_status: parseInt(stat),
    };

    const body = {
      institute_id: parseInt(row.institute_id),
      classroom_std: parseInt(row.classroom_std),
      subject_id: parseInt(row.subject_id),
      publisher_id: parseInt(userId),
      email: row.institute_email
    }

    const resp = await PublisherAPI.postInvite(body);
    // window.location.reload()

    // window.alert("Status updated..");
    // dispatch(listPubUsers(props.instType, props.boardType, props.standardType, props.subjectType))
    handleInviteClick()
  };

  const MONTHS2 = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const convertNumberToWords = (num2) => {
    var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    function inWords(num) {
      if ((num = num.toString()).length > 9) return 'overflow';
      let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return; var str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
      return str;
    }

    return inWords(num2)
  }

  console.log(`dynrows length ${(provs && provs.data) ? provs.data.length : 0}`)
  let totCost = 0;
  let totPaid = 0;
  let totDue = 0;

  return (
    <div className={classes.root}>
      <LeftCard />
      <main className={classes.content}>
        <Helmet>
          <title>Payables | Pustak Publish</title>
        </Helmet>
        <Container
          className={classes.container}
          style={{
            // minHeight: "100%",
          }}
        >
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              minHeight: "90%",
              width: "100%",
            }}
          >
            <div style={{ marginTop: 20, marginBottom: 15, width: '100%' }}>

              <div style={{
                display: 'flex', justifyContent: 'space-between', width: '100%',
                paddingLeft: 10,
                marginBottom: 0
              }}>
                <Typography
                  id="tableTitle" component="div"
                  variant="h3"
                  style={{

                  }}>
                  Payables
                </Typography>

                {/* <InviteFormDialog /> */}

              </div>

              <div>


              </div>
              {/* 
              <FilterFields
                setInstType={(instType) => { setInstType(instType) }}
                setBoardType={(boardType) => { setBoardType(boardType) }}
                setClsStd={(clsStd) => { setClsStd(clsStd) }}
                setSubType={(subType) => { setSubType(subType) }}
                handleSetSubs={(subs) => { handleSetSubs(subs) }}
              /> */}
              {/* usersList={(data) => {setRows(data)}} */}
            </div>

            <div className={classes1.root}
              style={{
                paddingLeft: 10,
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Paper className={classes1.paper}>
                {provs && provs.data && provs.data.length ?

                  provs.data.map((rows, index) => {
                    let monthIdx = (rows && rows.length) ? rows[0].cont_prov_starting_month : -1
                    const monthName = MONTHS2[monthIdx - 1]
                    totCost = 0;

                    let hasPayProof = false
                    if (monthIdx > -1
                      && index < 2) {
                      return (
                        <>
                          <div
                            style={{
                              padding: 20,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}>
                            <Typography
                              variant="h4">
                              {monthName} month payment details
                            </Typography>

                          </div>

                          {
                            rows && rows.length ? (

                              <>

                                <TableContainer
                                  style={{
                                    padding: 10
                                  }}>
                                  <Table
                                    className={classes1.table}
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                    aria-label="enhanced table"
                                  >

                                    <TableHead>
                                      <TableCell
                                        style={{
                                          padding: 10
                                        }}>
                                        Multimedia Provider
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        style={{
                                          padding: 10
                                        }}>
                                        Payable
                                      </TableCell>

                                    </TableHead>
                                    <TableBody>

                                      {
                                        rows.map((row, index) => {
                                          const isItemSelected = isSelected(
                                            row.content_prov_name
                                          );
                                          const labelId = `enhanced-table-checkbox-${index}`;

                                          let finalCost = parseFloat(row.cont_prov_total_cost)
                                          let paidCost = parseFloat(row.amount)
                                          let unpaidCost = (finalCost - paidCost)
                                          const isPaid = (paidCost >= finalCost)

                                          totCost += finalCost;
                                          totPaid += paidCost;
                                          totDue += unpaidCost;

                                          const status = row.hasOwnProperty('status') ? row.status : 'Hold'
                                          hasPayProof = (!row.publisher_payment_data == 0)

                                          return (
                                            <TableRow
                                              hover
                                              role="checkbox"
                                              aria-checked={isItemSelected}
                                              tabIndex={-1}
                                              key={row.content_prov_name}
                                              selected={isItemSelected}

                                            >

                                              <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                align="left"
                                                style={{
                                                  padding: 5,
                                                  paddingLeft: 10
                                                }}
                                              >
                                                <Link className={classes.link} to={`/publisher-ebook/pay-out-details/${row.content_prov_id}`} style={{ textDecoration: "none" }} >
                                                  {row.content_prov_name}
                                                </Link>

                                              </TableCell>


                                              <TableCell align="right"
                                                component="th"
                                                style={{
                                                  padding: 5
                                                }}>


                                                {/* Rs {finalCost} */}
                                                Rs. {row.cont_prov_total_cost}

                                              </TableCell>
                                            </TableRow>
                                          );
                                        })

                                      }

                                      {/* Total */}
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                      >
                                        <TableCell colspan={1}
                                          align="right"
                                          style={{
                                            padding: 5
                                          }}>
                                          <Typography
                                            variant="h6">Total
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          scope="row"
                                          style={{
                                            padding: 5
                                          }}>
                                          <Typography
                                            variant="h6">
                                            Rs {totCost}
                                          </Typography>

                                        </TableCell>
                                      </TableRow>




                                      {/* GST */}
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                      >
                                        <TableCell colspan={1}
                                          align="right"
                                          style={{
                                            padding: 5
                                          }}>
                                          <Typography
                                            variant="h6">G.S.T (18%)
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          scope="row"
                                          style={{
                                            padding: 5
                                          }}>
                                          <Typography
                                            variant="h6">
                                            Rs {((totCost * 18) / 100)}
                                          </Typography>

                                        </TableCell>
                                      </TableRow>



                                      {/* Grand Total */}
                                      <TableRow>
                                        <TableCell colspan={1}
                                          align="right"
                                          style={{
                                            padding: 5
                                          }}>
                                          <Typography
                                            variant="h6">
                                            Grand Total
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          scope="row"
                                          style={{
                                            padding: 5
                                          }}>
                                          <Typography
                                            variant="h6">
                                            Rs {totCost + ((totCost * 18) / 100)}
                                          </Typography>
                                        </TableCell>

                                      </TableRow>




                                      {/* INFO */}
                                      <TableRow>
                                        <TableCell colspan={1}
                                          align="left"
                                          style={{
                                            padding: 5
                                          }}>
                                          <Typography
                                            variant="body1">
                                            {monthName} payable - Rs
                                            (<b>{convertNumberToWords(totCost + ((totCost * 18) / 100))}</b>) Only
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          style={{
                                            padding: 5
                                          }}>

                                        </TableCell>

                                      </TableRow>

                                    </TableBody>
                                  </Table>

                                  <Table>
                                    <TableRow>
                                      <TableCell colspan={1}
                                        align="left"
                                        style={{
                                          padding: 5
                                        }}>
                                        {/* first time - not paid */}
                                        {(!savedPayment && !hasPayProof) &&
                                          <SimpleMenu row={{}}
                                            onReload={onReload}
                                            onSavedPayment={onSavedPayment}
                                            edit={false}
                                            status={0} handleChangeStatus={({ }, stat) => handleChangeStatus({}, stat)}
                                            month={monthIdx} />
                                        }

                                        {/* when he saves payment */}
                                        {savedPayment &&
                                          <SimpleMenu row={{}}
                                            onReload={onReload}
                                            onSavedPayment={onSavedPayment}
                                            edit={true}
                                            status={0} handleChangeStatus={({ }, stat) => handleChangeStatus({}, stat)}
                                            month={monthIdx} />
                                        }
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        scope="row"
                                        style={{
                                          padding: 5
                                        }}>
                                        {/* 
                                <Typography
                                  variant="body2">
                                  Thanks for the payment. Once we receive confirmation from the bank, we will update you with the same.
                                </Typography> */}
                                        {(savedPayment || hasPayProof) &&
                                          <>
                                            <SimpleMenu row={{}}
                                              onReload={onReload}
                                              onSavedPayment={onSavedPayment}
                                              edit={true}
                                              status={0} handleChangeStatus={({ }, stat) => handleChangeStatus({}, stat)}
                                              month={monthIdx} />
                                            <Alert variant="outlined" severity="warning">
                                              Thanks for the payment — Once we receive confirmation from the bank, we will update you with the same.
                                            </Alert>
                                          </>
                                        }
                                      </TableCell>

                                      <TableCell colspan={1}
                                        align="left"
                                        style={{
                                          padding: 5
                                        }}>

                                      </TableCell>
                                    </TableRow>

                                  </Table>
                                </TableContainer>

                              </>
                            ) : (
                              <>
                                {!loadingPubUsers ?
                                  <Alert severity="warning">
                                    <AlertTitle>No Multimedia Providers!</AlertTitle>
                                    There are no Multimedia Providers connected with you — {"Start adding their content in your books and request for permissions "}
                                    <strong>check it out!</strong>
                                  </Alert>
                                  :
                                  <CircularProgress />
                                }
                              </>
                            )
                          }
                        </>
                      )
                    }
                  })
                  :
                  (<></>)
                }

              </Paper>









              {/* MORE BUTTON */}
              {!more &&
                <Button variant="outlined" onClick={handleMoreClicked}>MORE</Button>
              }


              {more &&
                <>
                  {provs && provs.data && provs.data.length ?

                    provs.data.map((rows, index) => {
                      let monthIdx = (rows && rows.length) ? rows[0].cont_prov_starting_month : -1
                      const monthName = MONTHS2[monthIdx - 1]

                      let hasPayProof = false
                      totCost = 0
                      if (monthIdx > -1
                        && index > 1) {
                        return (
                          <>
                            <div
                              style={{
                                padding: 20,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Typography
                                variant="h4">
                                {monthName} month payment details
                              </Typography>

                            </div>

                            {
                              rows && rows.length ? (

                                <>

                                  <TableContainer
                                    style={{
                                      padding: 10
                                    }}>
                                    <Table
                                      className={classes1.table}
                                      aria-labelledby="tableTitle"
                                      size={dense ? "small" : "medium"}
                                      aria-label="enhanced table"
                                    >

                                      <TableHead>
                                        <TableCell
                                          style={{
                                            padding: 10
                                          }}>
                                          Multimedia Provider
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            padding: 10
                                          }}>
                                          Payable
                                        </TableCell>

                                      </TableHead>
                                      <TableBody>

                                        {
                                          rows.map((row, index) => {
                                            const isItemSelected = isSelected(
                                              row.content_prov_name
                                            );
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            let finalCost = parseFloat(row.cont_prov_total_cost)
                                            let paidCost = parseFloat(row.amount)
                                            let unpaidCost = (finalCost - paidCost)
                                            const isPaid = (paidCost >= finalCost)

                                            totCost += finalCost;
                                            totPaid += paidCost;
                                            totDue += unpaidCost;

                                            const status = row.hasOwnProperty('status') ? row.status : 'Hold'
                                            hasPayProof = (!row.publisher_payment_data == 0)

                                            return (
                                              <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.content_prov_name}
                                                selected={isItemSelected}

                                              >

                                                <TableCell
                                                  component="th"
                                                  id={labelId}
                                                  scope="row"
                                                  align="left"
                                                  style={{
                                                    padding: 5,
                                                    paddingLeft: 10
                                                  }}
                                                >
                                                  <Link className={classes.link} to={`/publisher-ebook/pay-out-details/${row.content_prov_id}`} style={{ textDecoration: "none" }} >
                                                    {row.content_prov_name}
                                                  </Link>

                                                </TableCell>


                                                <TableCell align="right"
                                                  component="th"
                                                  style={{
                                                    padding: 5
                                                  }}>


                                                  {/* Rs {finalCost} */}
                                                  Rs. {row.cont_prov_total_cost}

                                                </TableCell>
                                              </TableRow>
                                            );
                                          })

                                        }

                                        {/* Total */}
                                        <TableRow
                                          hover
                                          role="checkbox"
                                          tabIndex={-1}
                                        >
                                          <TableCell colspan={1}
                                            align="right"
                                            style={{
                                              padding: 5
                                            }}>
                                            <Typography
                                              variant="h6">Total
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            scope="row"
                                            style={{
                                              padding: 5
                                            }}>
                                            <Typography
                                              variant="h6">
                                              Rs {totCost}
                                            </Typography>

                                          </TableCell>
                                        </TableRow>




                                        {/* GST */}
                                        <TableRow
                                          hover
                                          role="checkbox"
                                          tabIndex={-1}
                                        >
                                          <TableCell colspan={1}
                                            align="right"
                                            style={{
                                              padding: 5
                                            }}>
                                            <Typography
                                              variant="h6">G.S.T (18%)
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            scope="row"
                                            style={{
                                              padding: 5
                                            }}>
                                            <Typography
                                              variant="h6">
                                              Rs {((totCost * 18) / 100)}
                                            </Typography>

                                          </TableCell>
                                        </TableRow>



                                        {/* Grand Total */}
                                        <TableRow>
                                          <TableCell colspan={1}
                                            align="right"
                                            style={{
                                              padding: 5
                                            }}>
                                            <Typography
                                              variant="h6">
                                              Grand Total
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            scope="row"
                                            style={{
                                              padding: 5
                                            }}>
                                            <Typography
                                              variant="h6">
                                              Rs {totCost + ((totCost * 18) / 100)}
                                            </Typography>
                                          </TableCell>

                                        </TableRow>




                                        {/* INFO */}
                                        <TableRow>
                                          <TableCell colspan={1}
                                            align="left"
                                            style={{
                                              padding: 5
                                            }}>
                                            <Typography
                                              variant="body1">
                                              {monthName} payable - Rs
                                              (<b>{convertNumberToWords(totCost + ((totCost * 18) / 100))}</b>) Only
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            scope="row"
                                            style={{
                                              padding: 5
                                            }}>

                                          </TableCell>

                                        </TableRow>

                                      </TableBody>
                                    </Table>

                                    <Table>
                                      <TableRow>
                                        <TableCell colspan={1}
                                          align="left"
                                          style={{
                                            padding: 5
                                          }}>
                                          {/* first time - not paid */}
                                          {(!savedPayment && !hasPayProof) &&
                                            <SimpleMenu row={{}}
                                              onReload={onReload}
                                              onSavedPayment={onSavedPayment}
                                              edit={false}
                                              status={0} handleChangeStatus={({ }, stat) => handleChangeStatus({}, stat)}
                                              month={monthIdx} />
                                          }

                                          {/* when he saves payment */}
                                          {savedPayment &&
                                            <SimpleMenu row={{}}
                                              onReload={onReload}
                                              onSavedPayment={onSavedPayment}
                                              edit={true}
                                              status={0} handleChangeStatus={({ }, stat) => handleChangeStatus({}, stat)}
                                              month={monthIdx} />
                                          }
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          scope="row"
                                          style={{
                                            padding: 5
                                          }}>
                                          {/* 
              <Typography
                variant="body2">
                Thanks for the payment. Once we receive confirmation from the bank, we will update you with the same.
              </Typography> */}
                                          {(savedPayment || hasPayProof) &&
                                            <>
                                              <SimpleMenu row={{}}
                                                onReload={onReload}
                                                onSavedPayment={onSavedPayment}
                                                edit={true}
                                                status={0} handleChangeStatus={({ }, stat) => handleChangeStatus({}, stat)}
                                                month={monthIdx} />
                                              <Alert variant="outlined" severity="warning">
                                                Thanks for the payment — Once we receive confirmation from the bank, we will update you with the same.
                                              </Alert>
                                            </>
                                          }
                                        </TableCell>

                                        <TableCell colspan={1}
                                          align="left"
                                          style={{
                                            padding: 5
                                          }}>

                                        </TableCell>
                                      </TableRow>

                                    </Table>
                                  </TableContainer>

                                </>
                              ) : (
                                <>
                                  {!loadingPubUsers ?
                                    <Alert severity="warning">
                                      <AlertTitle>No Multimedia Providers!</AlertTitle>
                                      There are no Multimedia Providers connected with you — {"Start adding their content in your books and request for permissions "}
                                      <strong>check it out!</strong>
                                    </Alert>
                                    :
                                    <CircularProgress />
                                  }
                                </>
                              )
                            }
                          </>
                        )
                      }
                    })
                    :
                    (<></>)
                  }

                </>
              }































              {/* INDIVIDUALS */}
              <Paper className={classes1.paper} style={{ display: 'none' }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

                <div
                  style={{
                    padding: 20,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Typography
                    variant="h4">
                    Individuals
                  </Typography>

                  {/* 
                  <FormControl className={classes4.formControl}>
                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    // value={age}
                    // onChange={handleChange}
                    >
                      <MenuItem value={`India`}>India</MenuItem>
                      <MenuItem value={`Australia`}>Australia</MenuItem>
                      <MenuItem value={`USA`}>USA</MenuItem>
                    </Select>
                  </FormControl> */}
                </div>

                {subs && subs.data && subs.data.length ? (
                  <>

                    <TableContainer
                      style={{
                        padding: 10
                      }}>
                      <Table
                        className={classes1.table}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        aria-label="enhanced table"
                      >
                        {/* <EnhancedTableHead
                          classes={classes1}
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        /> */}
                        <TableHead>
                          <TableCell
                            style={{
                              padding: 10
                            }}>
                            Multimedia Provider
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              padding: 10
                            }}>
                            Subscription Type
                          </TableCell>

                          <TableCell
                            align="right"
                            style={{
                              padding: 10
                            }}>
                            Annual Cost
                          </TableCell>

                        </TableHead>
                        <TableBody>

                          {
                            subs.data.map((row, index) => {
                              const isItemSelected = isSelected(
                                row.institute_name
                              );
                              const labelId = `enhanced-table-checkbox-${index}`;

                              const pending = row.partner_ebook_id == 0;
                              const approved = row.partner_ebook_id == 1;
                              const declined = row.partner_ebook_id == 2;
                              const invited = false; // later with dynamic
                              const date = moment(new Date(row.timestamp)).format("MMM Do YY")
                              console.log('date ', date)

                              const cost = row.cost
                              const discount = row.disc
                              const finalCost = row.finalcost
                              const paidCost = row.paid_cost
                              const unpaidCost = row.unpaid_cost
                              const startDt = moment(new Date(row.start_date)).format("MMM Do YY")
                              const endDt = moment(new Date(row.end_date)).format("MMM Do YY")
                              const isPaid = (row.ispaid) ? true : false

                              const status = row.hasOwnProperty('status') ? row.status : 'Hold'

                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.institute_name}
                                  selected={isItemSelected}

                                >
                                  {/* <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected}
                                      onClick={(event) =>
                                        handleClick(event, row.institute_name)
                                      }
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    align="left"
                                    style={{
                                      padding: 5,
                                      paddingLeft: 10
                                    }}
                                  >
                                    <Link className={classes.link} to={`#`} style={{ textDecoration: "none" }} >
                                      Multimedia {(index + 1)}
                                    </Link>

                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    align="center"
                                    style={{
                                      padding: 5
                                    }}
                                  >
                                    {/* {row.subject_name}<br />
                                    <small>Standard {row.classroom_name}</small> */}

                                    SINGLE USER
                                  </TableCell>

                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    align="right"
                                    style={{
                                      padding: 5
                                    }}
                                  >
                                    {/* <PriceFormDialog /> */}
                                    {paidCost}
                                  </TableCell>


                                </TableRow>
                              );
                            })

                          }

                          {/* GST */}
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell colSpan={2}
                              align="right"
                              style={{
                                padding: 5
                              }}>
                              <Typography
                                variant="h6">G.S.T
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              scope="row"
                              style={{
                                padding: 5
                              }}>
                              <Typography
                                variant="h6">
                                5000
                              </Typography>

                            </TableCell>
                          </TableRow>

                          {/* Grand Total */}
                          <TableRow>
                            <TableCell colSpan={2}
                              align="right"
                              style={{
                                padding: 5
                              }}>
                              <Typography
                                variant="h6">
                                Grand Total
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              scope="row"
                              style={{
                                padding: 5
                              }}>
                              <Typography
                                variant="h6">
                                150000
                              </Typography>
                            </TableCell>
                          </TableRow>


                          {emptyRows > 0 && (
                            <TableRow
                              style={{ height: (dense ? 33 : 53) * emptyRows }}
                            >
                              <TableCell colSpan={6}
                                align="right" />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>


                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <>
                    <Alert severity="warning">
                      <AlertTitle>No Users!</AlertTitle>
                      You may have not selected above or there are no Users —{" "}
                      <strong>check it out!</strong>
                    </Alert>
                  </>
                )}
              </Paper>







              {/* INSTITUTIONS */}
              {/* <Paper className={classes1.paper}
              >
                <div
                  style={{
                    padding: 20,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Typography
                    variant="h3">
                    Institutions
                  </Typography>


                  <FormControl className={classes4.formControl}>
                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    // value={age}
                    // onChange={handleChange}
                    >
                      <MenuItem value={`India`}>India</MenuItem>
                      <MenuItem value={`Australia`}>Australia</MenuItem>
                      <MenuItem value={`USA`}>USA</MenuItem>
                    </Select>
                  </FormControl>
                </div>


                {dynrows && dynrows.length ? (
                  <>
                    <TableContainer>
                      <Table
                        className={classes1.table}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        aria-label="enhanced table"
                      >
                        <EnhancedTableHead2
                          classes={classes1}
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />
                        <TableBody>

                          <TableRow
                            hover
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                          // key={row.institute_name}
                          // selected={isItemSelected}

                          >
                            <TableCell padding="checkbox"
                              style={{
                                padding: 20
                              }}>
                              
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              STATE
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                             
                              100
                            </TableCell>

                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              50
                            </TableCell>

                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              50
                            </TableCell>


                          </TableRow>

                          <TableRow
                            hover
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                          // key={row.institute_name}
                          // selected={isItemSelected}

                          >
                            <TableCell padding="checkbox"
                              style={{
                                padding: 20
                              }}>
                              
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              STATE
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              100
                            </TableCell>

                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              50
                            </TableCell>

                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              50
                            </TableCell>


                          </TableRow>

                          <TableRow
                            hover
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                          // key={row.institute_name}
                          // selected={isItemSelected}

                          >
                            <TableCell padding="checkbox"
                              style={{
                                padding: 20
                              }}>
                              
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              STATE
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              100
                            </TableCell>

                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              50
                            </TableCell>

                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              50
                            </TableCell>


                          </TableRow>

                          <TableRow
                            hover
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                          // key={row.institute_name}
                          // selected={isItemSelected}

                          >
                            <TableCell padding="checkbox"
                              style={{
                                padding: 20
                              }}>
                              
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              STATE
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                             
                              100
                            </TableCell>

                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                             
                              50
                            </TableCell>

                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                            >
                              
                              50
                            </TableCell>


                          </TableRow>

                          {emptyRows > 0 && (
                            <TableRow
                              style={{ height: (dense ? 33 : 53) * emptyRows }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <>
                    <Alert severity="warning">
                      <AlertTitle>No Users!</AlertTitle>
                      You may have not selected above or there are no Users —{" "}
                      <strong>check it out!</strong>
                    </Alert>
                  </>
                )}
              </Paper> */}
              {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
            </div>
          </Grid>
        </Container>


        <Box pb={4}>
          {/* <Copyright /> */}
        </Box>
      </main>
    </div>
  );
}
