import { TableBody } from "@material-ui/core";
import axios from "axios";
import Constants from "../../resource/Constants"
import { ACTIVITYAPPROVALCONTDETAILS_LIST_FAIL, ACTIVITYAPPROVALCONTDETAILS_LIST_REQUEST, ACTIVITYAPPROVALCONTDETAILS_LIST_SUCCESS, ACTIVITYAPPROVALPUBDETAILS_LIST_FAIL, ACTIVITYAPPROVALPUBDETAILS_LIST_REQUEST, ACTIVITYAPPROVALPUBDETAILS_LIST_SUCCESS, ACTIVITYLISTCONTDETAILS_LIST_FAIL, ACTIVITYLISTCONTDETAILS_LIST_REQUEST, ACTIVITYLISTCONTDETAILS_LIST_SUCCESS, ACTIVITYLISTPUBDETAILS_LIST_FAIL, ACTIVITYLISTPUBDETAILS_LIST_REQUEST, ACTIVITYLISTPUBDETAILS_LIST_SUCCESS } from "../constants/ActivityConstants";


const listActivityContGet = (role_id) => async (dispatch) => {

    try {
        dispatch({ type: ACTIVITYLISTCONTDETAILS_LIST_REQUEST })

    
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/prov-activity/?con_prov_id=${role_id}`)
        console.log("listActivityGet ======================", data)
        dispatch({ type: ACTIVITYLISTCONTDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ACTIVITYLISTCONTDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listActivityPubGet = (role_id) => async (dispatch) => {

    try {
        dispatch({ type: ACTIVITYLISTPUBDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/pub-activity/?pub_id=${role_id}`)
        console.log("listActivityGet ======================", data)
        dispatch({ type: ACTIVITYLISTPUBDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ACTIVITYLISTPUBDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listActApprvContPut = (board, roleId) => async (dispatch) => {

    try {
        dispatch({ type: ACTIVITYAPPROVALCONTDETAILS_LIST_REQUEST })

        const url = Constants.Application.PARTNER_END_URL + `/content-status/`
    
        const options ={
            method:"PUT",
            data: board,
            url,
        }
        const { data } = await axios(options)
        console.log("listActivityGet ======================", data)
        dispatch({ type: ACTIVITYAPPROVALCONTDETAILS_LIST_SUCCESS, payload: data })
        dispatch(listActivityContGet(roleId))

    } catch (error) {
        dispatch({ type: ACTIVITYAPPROVALCONTDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listActApprvPubPut = (body, roleId) => async (dispatch) => {

    try {
        dispatch({ type: ACTIVITYAPPROVALPUBDETAILS_LIST_REQUEST })

        const url  = Constants.Application.PARTNER_END_URL + `/ebook-status/`
        const options = {
            method: "PUT",
            data: body,
            url,
        }
        const { data } = await axios(options)
        console.log("listActApprvPubPut ======================", data)
        dispatch({ type: ACTIVITYAPPROVALPUBDETAILS_LIST_SUCCESS, payload: data })
        dispatch(listActivityPubGet(roleId))

    } catch (error) {
        dispatch({ type: ACTIVITYAPPROVALPUBDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listActivityContGet,listActivityPubGet,listActApprvContPut,listActApprvPubPut }