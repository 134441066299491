import axios from "axios";
import Constants from "../../../resource/Constants"
import { SINGLEEVENTDELETE_LIST_FAIL, SINGLEEVENTDELETE_LIST_REQUEST, SINGLEEVENTDELETE_LIST_SUCCESS, SINGLEVIDEODELETE_LIST_FAIL, SINGLEVIDEODELETE_LIST_REQUEST, SINGLEVIDEODELETE_LIST_SUCCESS, SUBSCRIBERSDETAILSDELETE_LIST_FAIL, SUBSCRIBERSDETAILSDELETE_LIST_REQUEST, SUBSCRIBERSDETAILSDELETE_LIST_SUCCESS, TRAINTEACHVIDEOSDELETE_LIST_FAIL, TRAINTEACHVIDEOSDELETE_LIST_REQUEST, TRAINTEACHVIDEOSDELETE_LIST_SUCCESS } from "../../constants/Trainer/AllEventsDelete";
import { listAllEventsGet, listAllVideosGet, listSubscribersGet, listTeachVideosTopGet } from "./EventsGet";

const query = new URLSearchParams(window.location.search);
const event_id = query.get("eve_id");

const listSingleEventDel = (id) => async (dispatch) => {

    try {
        console.log("id at list single delete", id)
        dispatch({ type: SINGLEEVENTDELETE_LIST_REQUEST })
        const url = Constants.Application.PARTNER_END_URL + `/event/${id}`
        const options = {
            method: "DELETE",
            url,
        }
        const { data } = await axios(options)

        console.log("listSingleEventDel ======================", data)
        dispatch({ type: SINGLEEVENTDELETE_LIST_SUCCESS, payload: data })
        dispatch(listAllEventsGet())
    } catch (error) {
        dispatch({ type: SINGLEEVENTDELETE_LIST_FAIL, payload: error.message })

    }
}


const listSingleVideoDel = (id) => async (dispatch) => {

    try {
        dispatch({ type: SINGLEVIDEODELETE_LIST_REQUEST })
        const { data } = await axios.delete(Constants.Application.PARTNER_END_URL + `/event-video/${id}`)
        console.log("listSingleVideoDel ======================", data)
        dispatch({ type: SINGLEVIDEODELETE_LIST_SUCCESS, payload: data })
        dispatch(listAllVideosGet(event_id))
    } catch (error) {
        dispatch({ type: SINGLEVIDEODELETE_LIST_FAIL, payload: error.message })

    }
}

const listSubscribersDel = (id) => async (dispatch) => {

    try {
        dispatch({ type: SUBSCRIBERSDETAILSDELETE_LIST_REQUEST })
        const { data } = await axios.delete(Constants.Application.PARTNER_END_URL + `/event-subscribe/${id}`)
        console.log("listSubscribersDel ======================", data)
        dispatch({ type: SUBSCRIBERSDETAILSDELETE_LIST_SUCCESS, payload: data })
        dispatch(listSubscribersGet(event_id))
    } catch (error) {
        dispatch({ type: SUBSCRIBERSDETAILSDELETE_LIST_FAIL, payload: error.message })

    }
}


const listTrainTeachVidDel = (id) => async (dispatch) => {

    try {
        dispatch({ type: TRAINTEACHVIDEOSDELETE_LIST_REQUEST })
        console.log("id at listTrainTeachVidDel", id)
        const { data } = await axios.delete(Constants.Application.PARTNER_END_URL + `/videos/${id}`)
        console.log("listTrainTeachVidDel ======================", data)
        dispatch({ type: TRAINTEACHVIDEOSDELETE_LIST_SUCCESS, payload: data })
        // dispatch(listTeachVideosTopGet())
    } catch (error) {
        dispatch({ type: TRAINTEACHVIDEOSDELETE_LIST_FAIL, payload: error.message })

    }
}



export {
    listSingleEventDel,
    listSingleVideoDel,
    listSubscribersDel,
    listTrainTeachVidDel
}