/**
 * Reader powered by TurnJS in ReactJS
 */

import React, { Component, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactDOM from "react-dom";
import $ from "jquery";
import "turn.js";
import "../../../css/styles.css";
import API from '../../../http/http'
import PARTNERAPI from '../../../http/httppartner'
import PUBLISHERAPI from '../../../http/publisher'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';
// Core viewer
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
/**imports end here */


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function FloatingActionButtons() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Fab color="primary" aria-label="add">
                <AddIcon />
            </Fab>
            <Fab color="secondary" aria-label="edit">
                <EditIcon />
            </Fab>
            <Fab variant="extended">
                <NavigationIcon className={classes.extendedIcon} />
                Navigate
            </Fab>
            <Fab disabled aria-label="like">
                <FavoriteIcon />
            </Fab>
        </div>
    );
}

class Turn extends React.Component {
    static defaultProps = {
        style: {},
        className: "",
        options: {}
    };

    constructor(props) {
        super(props)
        this.state = {}

    }

    componentDidMount() {
        if (this.el) {
            $(this.el).turn(Object.assign({}, this.props.options));
        }
        document.addEventListener("keydown", this.handleKeyDown, false);
    }

    componentWillUnmount() {
        if (this.el) {
            $(this.el)
                .turn("destroy")
                .remove();
        }
        document.removeEventListener("keydown", this.handleKeyDown, false);
    }

    handleKeyDown = event => {
        if (event.keyCode === 37) {
            $(this.el).turn("previous");
        }
        if (event.keyCode === 39) {
            $(this.el).turn("next");
        }
    };

    render() {
        return (
            <div
                className={this.props.className}
                style={Object.assign({}, this.props.style)}
                ref={el => (this.el = el)}
            >
                {this.props.children}
            </div>
        );
    }
}

const hasWindow = typeof window !== 'undefined';
const width = hasWindow ? window.innerWidth : null;
const height = hasWindow ? window.innerHeight : null;



// const pages = [
//     "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/01.jpg",
//     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
//     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
//     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
//     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
//     "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/06.jpg",
// ];

const TeachView = (props) => {

    const [bookId, setBookId] = useState(null)
    const [bookPages, setBookPages] = useState(null)
    const [bookPdfPath, setBookPdfPath] = useState(null)

    // const [lessonPlan, setLessonPlan] = useState(null)
    // const [mapUnits, setMapUnits] = useState(null)
    let lessonPlan = null
    const mapUnits = props.mapUnits;

    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    console.log("mapunits at TeachView", mapUnits)

    // const FilterMaps = () => {
    //     let found = false;
    //     lessonPlan = null;

    //     console.log(`mapunits while turning ${JSON.stringify(mapUnits)}`);
    //     if (mapUnits) {
    //         mapUnits.map(unt => {
    //             found = false;
    //             const lessons = unt.lesson;

    //             if (lessons) {
    //                 lessons.map(lsn => {
    //                     found = false;
    //                     const plans = lsn.lesson_plan;

    //                     if (plans && plans != 'UNKNOWN' && plans.length > 0) {
    //                         plans.map(plan => {
    //                             found = false;
    //                             if (plan) {
    //                                 console.log('plan ' + JSON.stringify(plan));
    //                                 console.log('plan first page ' + plan.first_page);
    //                                 console.log('plan last page ' + plan.last_page);
    //                                 console.log('page ' + page);
    //                                 if (plan.first_page == page
    //                                     || plan.last_page == page)

    //                                     lessonPlan = plan; // update latest
    //                                 console.log("lesson plan in loop", lessonPlan)
    //                                 found = true;
    //                             }
    //                         })
    //                     }

    //                 })
    //             }
    //         })

    //         // update lesson plan text
    //         if (lessonPlan != null) {
    //             console.log("found lesson plan", lessonPlan);
    //             props.updateLessonPlan(lessonPlan)

    //         } else {
    //             lessonPlan = null; // nullify..
    //             console.log("found lesson plan is null ");
    //             props.updateLessonPlan(null)
    //         }
    //     } else {
    //         lessonPlan = null;
    //         console.log("found lesson plan is null 1");
    //         props.updateLessonPlan(null)
    //     }

    // }

    const PageNumberUpdating = (left, right) => {
        props.getPageNum(left, right)
        let lessonPlan = null
        if (props.mapUnits) {
            props.mapUnits.map(unt => {
                unt.lesson && unt.lesson.map(lsn => {
                    let plans = lsn.lesson_plan;

                    plans && plans != 'UNKNOWN' && plans.length > 0 ?
                        plans.map(plan => {
                            if (plan.first_page == left || plan.last_page == right) {
                                lessonPlan = plan
                                console.log("lesson plan in loop at teach view", lessonPlan)
                            }
                        })
                        :
                        <></>
                })
            })
        }

        if(lessonPlan){
            props.updateLessonPlan(lessonPlan)
        }
    }

    // const options = {

    //     width: width - 400,
    //     height: height - 20,
    //     autoCenter: true,
    //     acceleration: true,
    //     justifyContent: "center",
    //     alignSelf: "center",
    //     elevation: 50,
    //     gradients: !$.isTouch,
    //     when: {
    //         turned: function (e, page) {


    //             console.log(`turning page event ${e}`);

    //             console.log(`turning page ${page}`);
    //             // get lesson plan


    //             console.log("Current view: ====================================================", $(this).turn("view")[0], $(this).turn("view")[1]);
    //             console.log("evnets while turning", e)
    //             console.log("current page image     ++++++++++++++++++++++++++++++++++++++++++", page)

    //             PageNumberUpdating($(this).turn("view")[0], $(this).turn("view")[1])
    //         }
    //     },


    // };


    // const pages = [
    //     "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/01.jpg",
    //     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
    //     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
    //     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
    //     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
    //     "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/06.jpg",
    // ];


    const getEbookContent = async (book_id) => {

        let pages = []
        // const response = await PUBLISHERAPI.GetEbookContentTeachView(book_id)
        // console.log("response at turn book screen", response)
        // let temp = response.data.data.book_details.ebook_details
        // const mapUnits = response.data.data.map_units

        // if(!mapUnits) {
        //     setLessonPlan(null)
        //     setMapUnits(null)
        // } else {
        //     setMapUnits(mapUnits);
        // }

        const temp = props.bookDetails.ebook_details
        const book = props.bookDetails
        const bookPdfPath = book.ebook_location_url

        setBookPdfPath(bookPdfPath)
        console.log(`temp in teachview ${JSON.stringify(temp)}`);

        temp.map((detail, index) => {
            console.log("book page details at turn pages", detail, index)
            // if (index + 1 == detail.page_num) {
            pages.push(detail.page_url)
            // }
        })
        console.log("book page array  at turn book", pages)
        setBookPages(pages)

    }



    const onPageChange = (e: PageChangeEvent) => {
        console.log("page change event at book reader", e)
        // let currPage = e.current_page
        // setLeftPage(e.currentPage);
        // setRightPage(e.currentPage);
         PageNumberUpdating(e.currentPage,e.currentPage)

    };

    useEffect(() => {

        const query = new URLSearchParams(window.location.search)
        const book_id = query.get('book-id')

        if (book_id) {
            getEbookContent(book_id)
        }

    }, [])

    console.log(`bookPages ${bookPages}`)


    return (
        <div style={{ height: "100vh", width: "100%", justifyContent: "center", display: "flex" }}>
            <div style={{ height: "100vh", width: "100%" }}>
                {(bookPdfPath) ?
                <Viewer
                    // fileUrl='/assets/pdf-open-parameters.pdf'
                    // fileUrl='https://arxiv.org/pdf/quant-ph/0410100.pdf'
                    fileUrl={bookPdfPath}
                    plugins={[
                        // Register plugins
                        defaultLayoutPluginInstance,

                    ]}
                    onPageChange={onPageChange}
                    style={{ width: "100%" }}
                // defaultScale={SpecialZoomLevel.PageFit}
                />
                :
                <CircularProgress color="secondary" />
                }
            </div>

            {/* {
                bookPages ?

                    <Turn options={options} className="magazine" >
                        {

                            bookPages.map((page, index) => (
                                <div key={index} >
                                    <img src={page} alt="" style={{ width: "100%", height: height - 20 }} />
                                </div>
                            ))

                        }
                    </Turn >
                    :
                    <div style={{
                        padding: 20
                    }}>
                        Loading..
                    </div>
            } */}

        </div>

    )
}


export default TeachView;