/**
 * reducer for current curriculum map unit lesson
 */

import * as actions from '../actions/currLessonActions'

export const initialState = {
    currLesson: null,
    loading: false,
    hasErrors: false
}

export default function currLessonReducer(state = initialState, action) {
    switch(action.type) {
        case actions.LESSON_CURR_REQUEST:
            return { ...state, loading: true }
        case actions.LESSON_CURR_SUCCESS:
            console.log(`called map unit lesson reducer success with payload ${JSON.stringify(action.payload)}`)
            return { currLesson: action.payload, loading: false, hasErrors: false }
        case actions.LESSON_CURR_FAILURE:
            return { ...state, loading: false, hasErrors: true }
        default:
            return state;
    }
}
