import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Button, LinearProgress, Typography } from "@material-ui/core";
import { useState } from "react";
import logo from "../../../../assets/logo.png";
import publisherLogo from "../../../../assets/PSON.jpeg"
import McgrawHill from "../../../../assets/Mcgrawhill.jpg";
import "../../../../css/sidebar.css";
import Leftbar from "../../slots/Leftbar";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import ActivityTableCont from "./ActivityTable"
import { useHistory } from "react-router";


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue",
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        justifyContent: "space-between",
        minHeight: "100%",
        height: "100%",
        minWidth: "100%",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0,
    },
}));

export default function ActivityPage() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [showButton, setShowButton] = useState(false);
    const [AccordClose, setAccordClose] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [showLogo, setShowLogo] = useState(true);
    const [state, setState] = React.useState({
        right: false,
    });

    const [showSideCont, SetShowSideCont] = useState(false);
    const [path, setPath] = useState("");
    const [readerData, setReaderData] = useState("");
    const [isLoadingscr, setIsLoadingscr] = useState(false);
    const history = useHistory()

    useEffect(() => { }, []);

    const sendDataToReaderScreen = (list, status) => {
        setIsLoading(true);
        console.log("sending..... Data to Reader Screen", list);
        if (list) {
            setReaderData(list);
            setIsLoading(false);
            setShowButton(status);
        }
    };
    const dispatch = useDispatch();

    useEffect(() => {

        return () => {
            //
        };
    }, []);

    const OpenSettings = () => {
        history.push("/publisher-content/settings")
    }


    return (
        <div className={classes.root}>
            {isLoadingscr == false ? (
                <>
                    {/* <Router> */}

                    <CssBaseline />
                    <LinearProgress />

                    <Leftbar />

                    <main className={classes.content}>
                        <Helmet>
                            <title> Admin Activites | KGtoPG Partner</title>
                        </Helmet>

                        {/* <div className={classes.appBarSpacer} /> */}
                        <Container
                            className={classes.container}
                            style={{
                                minHeight: "100%",
                            }}
                        >

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <Typography
                                    variant="h2">
                                        Activities List
                                    </Typography>

                                    <Typography
                                    variant="caption">
                                        List of activities by the admin
                                    </Typography>
                                </div>

                                <Button 
                                style={{
                                    // padding: 5,
                                    height: 35
                                }}
                                variant="contained" color="primary" onClick={() => OpenSettings()}>
                                    Back to settings
                                </Button>

                            </div>
                            <ActivityTableCont />
                        </Container>
                    </main>

                    {/* </Router> */}
                </>
            ) : (
                <></>
            )}
        </div>
    );
}
