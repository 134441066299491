import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Drawer from '@material-ui/core/Drawer';
import {
    Box,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    ButtonGroup,
    Chip,
    FormHelperText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { listInstGet } from '../../redux/actions/InstituteDetailsAction';
import { listBoardGet } from '../../redux/actions/boardDetailsAction';
import { listSubGet } from '../../redux/actions/subjectsAction';
import { listStdGet } from '../../redux/actions/standardAction';
import { listTopGet } from '../../redux/actions/topicAction';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase";
import Constants from "../../resource/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import { listChpGet } from '../../redux/actions/chapterAction';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { listTeachVideosGet, listTeachVideosTopGet } from '../../redux/actions/Trainer/EventsGet';
import VideoThumbnail from 'react-video-thumbnail';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ReactPlayer from "react-player";
import Cookie from 'js-cookie';
import { listTeachVideosPost } from '../../redux/actions/Trainer/EventsPost';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { listTrainTeachVidDel } from '../../redux/actions/Trainer/EventsDelete';
import { listTeachTrainVidPut } from '../../redux/actions/Trainer/EventsPut';


const ProviderId = Cookie.get('kgtopg.partner.user.id')

const config = {
    apiKey: Constants.Application.FIREBASE.API_KEY,
    authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
    //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
    storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles1 = makeStyles((theme) => ({

    formControl: {
        margin: theme.spacing(1),
        minWidth: 360,
        minHeight: 30,
    },
    formControl1: {
        margin: theme.spacing(1),
        minWidth: 350,
        minHeight: 30,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));


const useStyles2 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));


function ChaptersList(props) {
    const { chapList } = props
    const dispatch = useDispatch()
    const classes = useStyles2();

    const SecondaryActions = () => {
        const [delOpen, setDelOpen] = React.useState(false);
        const [Editopen, setEditOpen] = React.useState(false);
        const [videoDownloadUrls, setVideoDownloadUrls] = React.useState("")
        const [isUploading, setIsUploading] = useState(false);
        const [uploadProgress, setUploadProgress] = useState(0);
        const [progressVid, setProgressVid] = useState(0);
        const [isUploadingVid, setIsUploadingVid] = useState(false);
        const [uploadProgressVid, setUploadProgressVid] = useState(0);
        const [showUpldVid, setShowUpldVid] = useState(false)
        const [videoNewDownloadUrls, setVideoNewDownloadUrls] = useState("")


        console.log("props at secondary actions", chapList)

        const handleClickDelOpen = () => {
            setDelOpen(true);
        };

        const handleCloseDel = () => {
            setDelOpen(false);
        };



        const handleClickOpen = () => {
            setEditOpen(true);
        };

        const handleClose = () => {
            setEditOpen(false);
        };


        const handleUploadStartVid = () => {
            setIsUploadingVid(true);
            setUploadProgressVid(0);
        };

        const handleUploadErrorVid = (error) => {
            setIsUploadingVid(false);
        };


        const handleUploadSingleSuccessVid = async (filename) => {
            const downloadURL = await firebase
                .storage()
                .ref("images")
                .child(filename)
                .getDownloadURL();

            console.log("download url", downloadURL);

            if (downloadURL) {
                setProgressVid(0);
            }
            // setFileNames(filename)
            setVideoDownloadUrls(downloadURL);
            // setUploadProgress(100)
            // setIsUploading(false)
        };

        const handleProgressVid = (progress) => {
            setProgressVid(progress);
        };



        const UpdateVideo = () => {
            const body = {
                publisher_id: ProviderId,
                institute_type_id: chapList.institute_type_id,
                institute_board_id: chapList.institute_board_id,
                classroom_std: chapList.classroom_std,
                subject_id: chapList.subject_id,
                chapter_id: chapList.chapter_id,
                topic_id: chapList.topic_id,
                video_location: videoDownloadUrls ? videoDownloadUrls : chapList.video_location,
                video_name: chapList.video_name
            }
            const videoId = chapList.id

            console.log("body at Update Video", body)
            dispatch(listTeachTrainVidPut(body, videoId))

        }



        const DeleteConfirm = (id) => {
            dispatch(listTrainTeachVidDel(id))
        }



        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center" }}>
                <Typography>{chapList.created_date}</Typography>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Tooltip title="Edit" style={{ marginRight: 5 }}>
                        <EditOutlinedIcon color="primary" onClick={handleClickOpen} />
                    </Tooltip>

                    <Tooltip title="Delete" style={{ marginLeft: 5 }}>
                        <DeleteOutlinedIcon color="secondary" onClick={handleClickDelOpen} />
                    </Tooltip>
                </div>

                <div>
                    <Dialog open={delOpen} onClose={handleCloseDel} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Delete Confirmation</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                    <CloseOutlinedIcon color="secondary" style={{ fontSize: 60 }} />
                                </div>
                                Are you sure!, do you want to delete <strong>{chapList ? chapList.video_name : ""}</strong> video?
                            </DialogContentText>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDel} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => DeleteConfirm(chapList.id)} color="primary">
                                Yes,Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>


                <div>

                    <Dialog open={Editopen} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Update Video</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Upload updated new video to <strong>{chapList ? chapList.video_name : ""}</strong>
                            </DialogContentText>

                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: 20,
                                        marginLeft: 15,
                                        marginRight: 20,
                                    }}
                                >
                                    <label
                                        style={{
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            backgroundColor: "steelblue",
                                            color: "white",
                                            padding: 5,
                                            borderRadius: 4,
                                            cursor: "pointer",
                                            textAlign: "center",
                                            width: "100%"
                                        }}

                                    >
                                        Upload New Recorded Video
                                        <FileUploader
                                            hidden
                                            // accept="image/*"
                                            storageRef={firebase.storage().ref("images")}
                                            onUploadStart={handleUploadStartVid}
                                            onUploadError={handleUploadErrorVid}
                                            onUploadSuccess={handleUploadSingleSuccessVid}
                                            onProgress={handleProgressVid}
                                        />
                                    </label>
                                </div>


                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {progressVid ? <CircularProgress /> : <></>}
                                </div>

                                <div style={{ display: "flex", justifyContent: "center", margin: 5 }}>
                                    {videoDownloadUrls && (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <CloudUploadOutlinedIcon style={{ marginRight: 5 }} />
                                            <Typography>
                                                Video Uploaded
                                            </Typography>
                                        </div>
                                    )}

                                </div>

                            </div>


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => UpdateVideo()} color="primary">
                                Upload
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

            </div>
        )
    }


    return (
        <List className={classes.root}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <MenuBookOutlinedIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={chapList.video_name} secondary={<SecondaryActions />} />

            </ListItem>
        </List>
    );
}

const ProvId = Cookie.get('kgtopg.partner.user.id')



export default function TrainTeachVideoPage() {
    const dispatch = useDispatch()
    const classes = useStyles();
    const classes1 = useStyles1();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        right: false,
    });
    const [instType, setInstType] = useState("")
    const [board, setBoard] = useState('')
    const [clrStd, setClrStd] = useState('')
    const [sub, setSub] = useState('')
    const [pubId, setPubId] = useState("")
    const [chap, setChap] = useState("");
    const [chapterName, setChapterName] = useState("")
    const [showChap, setShowChap] = useState(false)
    const [showChapChip, setShowChapChip] = useState(false)
    const [showTopic, setShowTopic] = useState(false)
    const [selectedTopic, setSelectedTopic] = useState("")

    const handleChpChange = (event, chaptername) => {
        console.log("called handleChpChange");
        console.log(`new chapter ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        console.log("chapter name", chaptername);
        setChap(value);

    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleInstTypeChange = (event) => {
        const { name, value } = event.target;

        setInstType(value);
        dispatch(listBoardGet(value))
    }


    const handleBoardChange = (event) => {
        const { name, value } = event.target;

        setBoard(value);
        dispatch(listSubGet(value))
        dispatch(listStdGet(instType, value))
    }


    const handleClrStdChange = (event) => {
        const { name, value } = event.target;
        setClrStd(value);
    }

    const handleSubChange = (event) => {
        const { name, value } = event.target;
        setSub(value);

        dispatch(listChpGet(value, board))
    }

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
        setShowChapChip(false)
        setSelectedTopic("")
    };


    const handleChapdropdwn = () => {
        setShowTopic(false)
        setSelectedTopic("")
    }

    const handleChapUpdStatus = () => {
        setShowChap(false)
        setShowChapChip(true)
        setSelectedTopic(0)
    }

    const handleChapStatus = () => {
        setShowTopic(true)
        setSelectedTopic(1)
    }


    const boardDetailsGet = useSelector(state => state.boardDetails)
    const { loading, boardDetails, error } = boardDetailsGet

    const instDetailsGet = useSelector(state => state.instDetails)
    const { loadingInst, instDetails, errorInst } = instDetailsGet

    const subDetailsGet = useSelector(state => state.subDetails)
    const { loadingSub, subDetails, errorSub } = subDetailsGet

    const stdDetailsGet = useSelector(state => state.stdDetails)
    const { loadingStd, stdDetails, errorStd } = stdDetailsGet

    const chpDetailsGet = useSelector(state => state.chpDetails)
    const { loadingChap, chapDetails, errorChp } = chpDetailsGet


    useEffect(() => {

        dispatch(listInstGet())


        return (() => {
            //
        })

    }, [])



    return (

        <div>

            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>

                    {/* create  add videos button  */}
                    <div style={{ marginRight: 5 }}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                // onClick={handleClickOpen}
                                style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none", boxShadow: "2px 2px 2px #d4d4d6" }}
                                onClick={toggleDrawer(anchor, true)}
                            >
                                Add Videos
                            </Button>
                        </Box>
                    </div>


                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>

                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 5, margin: 5 }}>
                            <Typography style={{ fontSize: 25, fontFamily: "Poppins, Helvetica, sans-serif" }}>
                                Add Videos
                            </Typography>
                        </div>

                        <Divider />

                        {/* insititute type dropdown */}
                        <FormControl variant="outlined" className={classes1.formControl} style={{ marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10 }} required>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                                helperText="Institute type"
                            >
                                Institute Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={instType}
                                onChange={handleInstTypeChange}
                                label="Institute Type*"
                                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                                color={instType != null ? "green" : "blue"}
                            >

                                {(instDetails && instDetails.data) ?
                                    instDetails.data.map((type, index) => {
                                        console.log(`single inst type ${JSON.stringify(type)}`)
                                        return (
                                            <MenuItem key={index} value={type.institute_type_id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{type.institute_type_name}</MenuItem>
                                        )
                                    })
                                    :
                                    <MenuItem key={-2} value={-2} >No Types</MenuItem>
                                }
                            </Select>
                            <FormHelperText id="my-helper-text">Please select <strong>Institute Type</strong></FormHelperText>
                        </FormControl>



                        {/* institute board dropdown */}
                        <FormControl variant="outlined" className={classes1.formControl} style={{ marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10 }} required>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                            >
                                Institute Board
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={board}
                                onChange={handleBoardChange}
                                label="Institute Board*"
                                color={board != null ? "green" : "blue"}
                                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                            >
                                {boardDetails && boardDetails.data ?
                                    boardDetails.data.map((brd, index) => {
                                        return (
                                            <MenuItem key={index} value={brd.institute_board_id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{brd.institute_board_name}</MenuItem>
                                        )
                                    })
                                    :
                                    <MenuItem key={-2} value={-2} >No Types</MenuItem>
                                }

                            </Select>
                            <FormHelperText id="my-helper-text">Please select <strong>Institute board</strong></FormHelperText>
                        </FormControl>


                        {/* institute standards */}
                        <FormControl variant="outlined" className={classes1.formControl} style={{ marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10 }} required>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                            >
                                Standard
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={clrStd}
                                onChange={handleClrStdChange}
                                label="Standard*"
                                color="blue"
                                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                            >

                                {stdDetails && stdDetails.data && stdDetails.data ?
                                    stdDetails.data.map((sb, index) => {
                                        return (
                                            <MenuItem key={index} value={sb.id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{sb.standard}</MenuItem>
                                        )
                                    })
                                    :
                                    <MenuItem key={-2} value={-2} >No Types</MenuItem>
                                }

                            </Select>
                            <FormHelperText id="my-helper-text">Please select <strong>Standard</strong></FormHelperText>

                        </FormControl>

                        {/* subject dropdown */}
                        <FormControl variant="outlined" className={classes1.formControl} style={{ marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10 }} required>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                            >
                                Subject
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={sub}
                                onChange={handleSubChange}
                                label="Subject*"
                                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                            >
                                {subDetails && subDetails.data && subDetails.data.subjects ?
                                    subDetails.data.subjects.map((sb, index) => {
                                        return (
                                            <MenuItem key={index} value={sb.subject_id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{sb.subject_name}</MenuItem>

                                        )
                                    })
                                    :
                                    <MenuItem key={-2} value={-2} >No Types</MenuItem>
                                }

                            </Select>
                            <FormHelperText id="my-helper-text">Please select <strong>Subject</strong></FormHelperText>
                        </FormControl>





                        {

                            showChap || showTopic ?
                                <div>

                                    {
                                        (loadingChap && chpDetailsGet) ? (
                                            <></>
                                        ) : (
                                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                                    <CloseOutlinedIcon color="secondary" style={{ fontSize: 25 }} onClick={() => { handleChapdropdwn() }} />
                                                </div>
                                                <FormControl variant="outlined" className={classes1.formControl}>
                                                    <InputLabel
                                                        id="demo-simple-select-outlined-label"
                                                        style={{ fontSize: 13, color: "#464E5F" }}
                                                    >
                                                        Chapter
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={chap}
                                                        onChange={handleChpChange}
                                                        label="Chapter"
                                                        style={{ fontSize: 13, color: "#464E5F" }}
                                                    >
                                                        {loadingChap && chpDetailsGet ? (
                                                            console.log("loading trueeee")
                                                        ) : chapDetails.data && chapDetails.data.length != 0 ? (
                                                            chapDetails.data.map((list, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={list.chapter_id}
                                                                        style={{
                                                                            fontSize: 13,
                                                                            color: "#464E5F",
                                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                                        }}
                                                                        onClick={() => {
                                                                            setChapterName(list.chapter_title);
                                                                        }}
                                                                    >
                                                                        {list.chapter_title}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        ) : (
                                                            <MenuItem
                                                                style={{
                                                                    fontSize: 13,
                                                                    color: "#464E5F",
                                                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                                                }}
                                                            >
                                                                Please Select Institute Type
                                                            </MenuItem>
                                                        )}
                                                    </Select>

                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#464E5F",
                                                            marginLeft: 5,
                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                        }}
                                                    >
                                                        Please select{" "}
                                                        <span
                                                            style={{
                                                                color: "#464E5F",
                                                                fontWeight: "600",
                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            Chapter
                                                        </span>
                                                        {" "} for adding Videos by Topic
                                                    </span>
                                                </FormControl>
                                            </div>
                                        )}
                                </div>
                                :
                                <></>
                        }



                        {!showChapChip || showTopic ?

                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: 20 }}>
                                <div>
                                    Upload by:
                                    <ButtonGroup variant="contained" color="primary" aria-label="text primary button group" style={{ color: "#fff", marginLeft: 20 }}>
                                        <Button onClick={() => { handleChapUpdStatus() }}>Chapter</Button>
                                        <Button onClick={() => { handleChapStatus() }}>Topic</Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                            :
                            <></>

                        }

                        {
                            showChapChip ?
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", marginLeft: 20 }}>
                                    Upload by:
                                    <Chip
                                        icon={<MenuBookOutlinedIcon />}
                                        variant="outlined"
                                        size="large"
                                        label="Selected Chapter"
                                        onDelete={handleDelete}
                                        color="primary"
                                        style={{ marginLeft: 10 }}
                                    />
                                </div>
                                :
                                <></>
                        }

                        {
                            instType && board && clrStd && sub ?
                                <div style={{ margin: 5 }}>
                                    <VideosDialog
                                        instId={instType}
                                        boardId={board}
                                        clsStd={clrStd}
                                        subId={sub}
                                        selTopic={selectedTopic}
                                        chapter={chap}
                                        chapName={chapterName}
                                    />
                                </div>
                                :
                                <Button variant="contained" color="primary" disabled style={{ margin: 10 }}>
                                    NEXT
                                </Button>
                        }
                    </Drawer >
                </React.Fragment >
            ))
            }








        </div >
    );
}



function VideosDialog(props) {
    const { instId, boardId, clsStd, subId, selTopic, chapter, chapName } = props
    const classes = useStyles();
    const classes1 = useStyles1();
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        right: false,
    });
    const [videoDownloadUrls, setVideoDownloadUrls] = React.useState("")
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [progressVid, setProgressVid] = useState(0);
    const [isUploadingVid, setIsUploadingVid] = useState(false);
    const [uploadProgressVid, setUploadProgressVid] = useState(0);
    const [showUpldVid, setShowUpldVid] = useState(false)
    const [videoNewDownloadUrls, setVideoNewDownloadUrls] = useState("")
    const [chap, setChap] = useState("");
    const [chapterName, setChapterName] = useState(chapName)
    const [tpc, setTpc] = useState("");
    const [topicname, setTopicName] = useState("");





    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleClickOpen = () => {
        setOpen(true);

        console.log("chapter at handleClickOpen", chapter)

        if (chapter) {
            dispatch(listTopGet(chapter));
        }

        if (selTopic == 0) {
            console.log("selected chapter only ")
            dispatch(listTeachVideosGet(instId, boardId, subId, clsStd))
        }

        if (selTopic == 1 && chapter) {
            console.log("slected topic only ...", chapter)
            dispatch(listTeachVideosTopGet(instId, boardId, subId, clsStd, chapter))
        }

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUploadStartVid = () => {
        setIsUploadingVid(true);
        setUploadProgressVid(0);
    };

    const handleUploadErrorVid = (error) => {
        setIsUploadingVid(false);
    };


    const handleUploadSingleSuccessVid = async (filename) => {
        const downloadURL = await firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL();

        console.log("download url", downloadURL);

        if (downloadURL) {
            setProgressVid(0);
        }
        // setFileNames(filename)
        setVideoDownloadUrls(downloadURL);
        // setUploadProgress(100)
        // setIsUploading(false)
    };

    const handleProgressVid = (progress) => {
        setProgressVid(progress);
    };


    const SaveVideo = () => {

        console.log("topic  name at saveVIDEO",topicname)
        const body = {
            publisher_id: ProvId,
            institute_type_id: instId,
            institute_board_id: boardId,
            classroom_std: clsStd,
            subject_id: subId,
            chapter_id: chap ? chap : chapter,
            video_location: videoDownloadUrls,
            video_name: topicname ? topicname : chapterName,
            topic_id: tpc ? tpc : 0
        }

        dispatch(listTeachVideosPost(body, instId, boardId, clsStd, subId))


    }


    useEffect(() => {




        if (selTopic == 0) {
            dispatch(listTeachVideosGet(instId, boardId, subId, clsStd))
        }

        if (selTopic == 1) {
            dispatch(listTeachVideosTopGet(instId, boardId, subId, clsStd, chapter))
        }

        return (() => {
            //
        })

    }, [])

    const handleChpChange = (event, chaptername) => {
        console.log("called handleChpChange");
        console.log(`new chapter ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        console.log("chapter name", chaptername);
        setChap(value);

    };



    const handleTpcChange = (event) => {
        console.log("called handleTpcChange");
        console.log(`new topic ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        setTpc(value);
        // setTopicName(name)
    };


    const chpDetailsGet = useSelector(state => state.chpDetails)
    const { loadingChap, chapDetails, errorChp } = chpDetailsGet

    const topDetailsGet = useSelector((state) => state.topDetails);
    const { loadingTop, TopDetails, errorTop } = topDetailsGet;

    const trainTeachVidGet = useSelector(state => state.trainTeachVideoGet)
    const { loadingteachVidGet, teachVidGet, errorteachVidGet } = trainTeachVidGet


    const trainTeachVidTopGet = useSelector(state => state.trainTeachVideosTopGet)
    const { loadingteachVidTopGet, teachVidTopGet, errorteachVidTopGet } = trainTeachVidTopGet

    {
        loadingteachVidTopGet && teachVidTopGet ?
            console.log("data is loading....")
            :
            console.log("data is loadingteachVidTopGet", teachVidTopGet)
    }


    {
        loadingTop && TopDetails ?
            console.log("topic data is loading")
            :
            console.log("topic data is loading ", TopDetails)
    }

    {
        loadingteachVidGet && trainTeachVidGet ?
            console.log("data is loading at trainteachvideo page")
            :
            console.log("data trainTeachVidGet", teachVidGet)
    }


    return (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleClickOpen} fullWidth>
                Next
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >

                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <strong>Videos</strong>
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Paper style={{ display: "flex", flexDirection: "row", }}>
                    <Paper elevation={4} style={{ width: "20%", height: "90vh" }}>

                        {
                            selTopic == 0 ?

                                trainTeachVidGet && teachVidGet && teachVidGet.data && teachVidGet.data.length ?

                                    teachVidGet.data.map((list, index) => {
                                        return (
                                            <Paper elevation={4} style={{ margin: 5, borderRadius: 5 }}>
                                                <ChaptersList chapList={list} />
                                            </Paper>


                                        )
                                    })
                                    :
                                    <></>

                                :

                                trainTeachVidGet && teachVidTopGet && teachVidTopGet.data && teachVidTopGet.data.length ?
                                    teachVidTopGet.data.map((list, index) => {
                                        return (
                                            <Paper elevation={4} style={{ margin: 5, borderRadius: 5 }}>
                                                <ChaptersList chapList={list} />
                                            </Paper>


                                        )
                                    })
                                    :
                                    <></>


                        }


                    </Paper>

                    <Paper style={{ width: "80%", height: "90vh", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>

                        {
                            selTopic == 0 ?

                                trainTeachVidGet && teachVidGet && teachVidGet.data && teachVidGet.data.length ?

                                    teachVidGet.data.map((list, index) => {
                                        return (
                                            <div >
                                                <div style={{ marginTop: 20, marginLeft: 20, width: 250, height: "25vh" }}>
                                                    <ReactPlayer url={list.video_location} width="250" height="25vh" style={{ border: "1px solid #e0e0e0" }} playIcon pip={false} />
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography>
                                                        {list.video_name}
                                                    </Typography>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <></>
                                :
                                trainTeachVidGet && teachVidTopGet && teachVidTopGet.data && teachVidTopGet.data.length ?
                                    teachVidTopGet.data.map((list, index) => {
                                        return (
                                            <div >
                                                <div style={{ marginTop: 20, marginLeft: 20, width: 250, height: "25vh" }}>
                                                    <ReactPlayer url={list.video_location} width="250" height="25vh" style={{ border: "1px solid #e0e0e0" }} playIcon pip={false} />
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography>
                                                        {list.video_name}
                                                    </Typography>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <></>


                        }

                        <div>
                            {['right'].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <Button onClick={toggleDrawer(anchor, true)}>
                                        <Paper
                                            variant="outlined"
                                            square
                                            style={{
                                                width: 250,
                                                height: 170,
                                                marginTop: 15,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                alignContent: "center"
                                            }}

                                        >
                                            <AddOutlinedIcon style={{ fontSize: 80 }} />
                                        </Paper>
                                    </Button>
                                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>


                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: 20,
                                                    marginLeft: 15,
                                                    marginRight: 20,
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                        backgroundColor: "steelblue",
                                                        color: "white",
                                                        padding: 5,
                                                        borderRadius: 4,
                                                        cursor: "pointer",
                                                        textAlign: "center",
                                                        width: "100%"
                                                    }}

                                                >
                                                    Upload New Recorded Video
                                                    <FileUploader
                                                        hidden
                                                        // accept="image/*"
                                                        storageRef={firebase.storage().ref("images")}
                                                        onUploadStart={handleUploadStartVid}
                                                        onUploadError={handleUploadErrorVid}
                                                        onUploadSuccess={handleUploadSingleSuccessVid}
                                                        onProgress={handleProgressVid}
                                                    />
                                                </label>
                                            </div>


                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {progressVid ? <CircularProgress /> : <></>}
                                            </div>

                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {videoDownloadUrls && (
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <CloudUploadOutlinedIcon style={{ marginRight: 5 }} />
                                                        <Typography>
                                                            Video Uploaded
                                                        </Typography>
                                                    </div>
                                                )}

                                            </div>



                                            {
                                                !selTopic ?

                                                    loadingChap && chpDetailsGet ? (
                                                        <div style={{ display: "flex", justifyContent: "center" }}>

                                                        </div>
                                                    ) : (
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            <FormControl variant="outlined" className={classes1.formControl}>
                                                                <InputLabel
                                                                    id="demo-simple-select-outlined-label"
                                                                    style={{ fontSize: 13, color: "#464E5F" }}
                                                                >
                                                                    Chapter
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    value={chap}
                                                                    onChange={handleChpChange}
                                                                    label="Chapter"
                                                                    style={{ fontSize: 13, color: "#464E5F" }}
                                                                >
                                                                    {loadingChap && chpDetailsGet ? (
                                                                        console.log("loading trueeee")
                                                                    ) : chapDetails.data && chapDetails.data.length != 0 ? (
                                                                        chapDetails.data.map((list, index) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    value={list.chapter_id}
                                                                                    style={{
                                                                                        fontSize: 13,
                                                                                        color: "#464E5F",
                                                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setChapterName(list.chapter_title);
                                                                                    }}
                                                                                >
                                                                                    {list.chapter_title}
                                                                                </MenuItem>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <MenuItem
                                                                            style={{
                                                                                fontSize: 13,
                                                                                color: "#464E5F",
                                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                            }}
                                                                        >
                                                                            Please Select Institute Type
                                                                        </MenuItem>
                                                                    )}
                                                                </Select>
                                                                <span
                                                                    style={{
                                                                        fontSize: 12,
                                                                        color: "#464E5F",
                                                                        marginLeft: 5,
                                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                                    }}
                                                                >
                                                                    Please select{" "}
                                                                    <span
                                                                        style={{
                                                                            color: "#464E5F",
                                                                            fontWeight: "600",
                                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        Chapter
                                                                    </span>
                                                                </span>
                                                            </FormControl>
                                                        </div>
                                                    )

                                                    :

                                                    loadingTop && topDetailsGet ? (
                                                        <div style={{ display: "flex", justifyContent: "center" }}>

                                                        </div>
                                                    ) : (
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            <FormControl variant="outlined" className={classes1.formControl}>

                                                                <InputLabel
                                                                    id="demo-simple-select-outlined-label"
                                                                    style={{ fontSize: 13, color: "#464E5F" }}
                                                                >
                                                                    Topic
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    value={tpc}
                                                                    onChange={handleTpcChange}
                                                                    label="Topic"
                                                                    style={{ fontSize: 13, color: "#464E5F" }}
                                                                >
                                                                    {loadingTop && topDetailsGet ? (
                                                                        console.log("loading trueeee")
                                                                    ) : TopDetails.data && TopDetails.data.length != 0 ? (
                                                                        TopDetails.data.map((list, index) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    value={list.topic_id}
                                                                                    style={{
                                                                                        fontSize: 13,
                                                                                        color: "#464E5F",
                                                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setTopicName(list.topic_title);
                                                                                    }}
                                                                                >
                                                                                    {list.topic_title}
                                                                                </MenuItem>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <MenuItem
                                                                            style={{
                                                                                fontSize: 13,
                                                                                color: "#464E5F",
                                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                            }}
                                                                        >
                                                                            Please Select Institute Type
                                                                        </MenuItem>
                                                                    )}
                                                                </Select>
                                                                <span
                                                                    style={{
                                                                        fontSize: 12,
                                                                        color: "#464E5F",
                                                                        marginLeft: 5,
                                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                                    }}
                                                                >
                                                                    Please select{" "}
                                                                    <span
                                                                        style={{
                                                                            color: "#464E5F",
                                                                            fontWeight: "600",
                                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        Topic
                                                                    </span>
                                                                </span>
                                                            </FormControl>
                                                        </div>
                                                    )


                                            }

                                        </div>








                                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                                            <Button variant="contained" color="primary" onClick={() => { SaveVideo() }}>
                                                Save Video
                                            </Button>
                                        </div>
                                    </Drawer>
                                </React.Fragment>
                            ))}
                        </div>






                    </Paper>
                </Paper>
            </Dialog>
        </div>
    );
}

