import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Cookie from 'js-cookie';
import AuthAPI from '../../../http/auth';
import { useDispatch, useSelector } from 'react-redux';
import { signinAuth } from '../../../redux/actions/UserAuthAction';
import Constants from '../../../resource/Constants';
import { CircularProgress } from '@material-ui/core';
import Copyright from '../slots/Copyright'
import LOGO from '../../../assets/PustakLogo.png'
import pusthakPublishLogo from "../../../assets/PustakPublishLogin.png"
import pustakPublish from "../../../assets/PustakPublish.png"
import profilePic from "../../../assets/profilepic.jpg"

function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright2() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const useStyles = makeStyles((theme) => ({
    paper: {
        // marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default function AccountSwitch() {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [passw, setPassw] = useState("");
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [respRole, setRespRole] = useState("")
    const [respData, setRespData] = useState("")

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    const userName = Cookie.get('kgtopg.partner.user.name')
    // let CONTENTUPLOAD = Cookie.get('kgtopg.partner.user.CONTENTUPLOAD')
    // let QUALITYASSURANCE = Cookie.get('kgtopg.partner.user.QUALITYASSURANCE')
    // let SUPERADMIN = Cookie.get('kgtopg.partner.user.SUPERADMIN')
    // let CONTENTMAP = Cookie.get('kgtopg.partner.user.CONTENTMAP')
    // let CURRICULUMMAP = Cookie.get('kgtopg.partner.user.CURRICULUMMAP')
    // let EBOOKUPLOAD = Cookie.get('kgtopg.partner.user.EBOOKUPLOAD')
    // let TRAINER = Cookie.get('kgtopg.partner.user.TRAINER')



    const navigateToPublisher = (role, userData) => history.push({ pathname: '/publisher-ebook/dashboard', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToPublisherEbooks = (role, userData) => history.push({ pathname: '/publisher-ebook/books', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToPublisherCurriculum = (role, userData) => history.push({ pathname: '/publisher-ebook/curriculum-map', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToPublisherTrainer = (role, userData) => history.push({ pathname: '/publisher-ebook/trainer', state: { role: role, roleData: userData } });//eg.history.push('/login');

    const navigateToContent = (role, userData) => history.push({ pathname: '/publisher-content/dashboard', state: { role: role, roleData: userData } });//eg.history.push('/login');
    const navigateToContUpload = (role, userData) => history.push({ pathname: '/publisher-content/content', state: { role: role, roleData: userData } });//eg.history.push('/login');






    const doLogin = async () => {

        setIsLoading(true)
        if (!email || !passw) {

            setIsLoading(false)
            return;
        }

        const body = {
            user_email: email,
            user_password: passw
        }

        try {
            const resp = await AuthAPI.doLoginUser(body)
            console.log(`response after login  ${JSON.stringify(resp)}`);






            if (resp && resp.data.token) {
                const response = await AuthAPI.doLogin(body)
                // console.log(`response after login after authentication  ${JSON.stringify(resp)}`);
                console.log("response after login authentication", response)

                if (response && response.data) {
                    setRespRole(response.data.role)
                    setRespData(response.data)
                }

                const roles = Cookie.get('kgtopg.partner.user.Roles')
                console.log("roles at login screen", roles)
                const CONTENTUPLOAD = (roles && roles.includes("CONTENTUPLOAD"))
                const QUALITYASSURANCE = (roles && roles.includes("QUALITYASSURANCE"))
                const SUPERADMIN = (roles && roles.includes("SUPERADMIN"))
                const CONTENTMAP = (roles && roles.includes("CONTENTMAP"))
                const CURRICULUMMAP = (roles && roles.includes("CURRICULUMMAP"))
                const EBOOKUPLOAD = (roles && roles.includes("EBOOKUPLOAD"))
                const TRAINER = (roles && roles.includes("TRAINER"))



                console.log("role status CONTENT UPLOAD at login", CONTENTUPLOAD)
                console.log("role status QUALITYASSURANCE at login ", QUALITYASSURANCE)
                console.log("role status SUPERADMIN at login ", SUPERADMIN)
                console.log("role status CONTENTMAP at login ", CONTENTMAP)
                console.log("role status CURRICULUMMAP at login", CURRICULUMMAP)
                console.log("role status EBOOKUPLOAD at login ", EBOOKUPLOAD)
                console.log("role status TRAINER at login", TRAINER)


                if (response && response.data && response.data.role == "EBOOK_PROVIDER") {
                    if (SUPERADMIN) {
                        navigateToPublisher(response.data.role, response.data)
                    } else if (EBOOKUPLOAD) {
                        navigateToPublisherEbooks(response.data.role, response.data)
                    } else if (CONTENTMAP) {
                        navigateToPublisherCurriculum(response.data.role, response.data)
                    } else if (CURRICULUMMAP) {
                        navigateToPublisherCurriculum(response.data.role, response.data)
                    } else if (TRAINER) {
                        navigateToPublisherTrainer(response.data.role, response.data)
                    }
                }

                if (response && response.data && response.data.role == "CONTENT_PROVIDER") {
                    console.log("entering in content provider SUPERADMIN", SUPERADMIN)
                    console.log("entering in content provider CONTENTUPLOAD", CONTENTUPLOAD)

                    if (SUPERADMIN) {
                        console.log("entering in content provider dashboard ")
                        navigateToContent(response.data.role, response.data)

                    } else if (CONTENTUPLOAD) {
                        console.log("entering in content provider content upload ")
                        navigateToContUpload(response.data.role, response.data)
                    } else if (QUALITYASSURANCE) {
                        console.log("entering in content provider QualityAssurance ")
                        navigateToContUpload(response.data.role, response.data)
                    }
                }
            } else {
                setOpenSnack(true)
            }
        } catch (e) {
            console.log(`exception authenticating user ${e.message}`)
        }

        setIsLoading(false)


    }





    const doAuth = async (resp) => {

        console.log("response at doAuth", resp)

        if (resp && resp.data) {



            const body = {
                user_email: resp.data.user_email,
                user_password: resp.data.user_password
            }
            // dispatch(signinAuth(body))


            const response = await AuthAPI.doAuth(body)
            console.log(`response after login Authentication ${JSON.stringify(response)}`);

            // navigateTo()

        }


    }

    const handleEmailChanged = (event) => {
        const { name, value } = event.target;

        setEmail(value);
    }

    const handlePasswChanged = (event) => {
        const { name, value } = event.target;

        setPassw(value);
    }

    // const logo = 'https://kgtopg.com/schooler/img/logo_small.png';
    const logo = LOGO


    const dispatch = useDispatch()


    const getUserAuthResp = useSelector(state => state.userSigninAuth)
    const { loadingAuth, userInfo, errorAuth } = getUserAuthResp


    {
        loadingAuth && userInfo ?
            <></>
            :
            console.log("authentication response", userInfo)

    }


    useEffect(() => {

        const userLoggedIn = Cookie.get('kgtopg.partner.user.login')
        const userRole = Cookie.get('kgtopg.partner.user.role')

        const roles = Cookie.get('kgtopg.partner.user.Roles')
        console.log("roles at login screen", roles)
        const CONTENTUPLOAD = (roles && roles.includes("CONTENTUPLOAD"))
        const QUALITYASSURANCE = (roles && roles.includes("QUALITYASSURANCE"))
        const SUPERADMIN = (roles && roles.includes("SUPERADMIN"))
        const CONTENTMAP = (roles && roles.includes("CONTENTMAP"))
        const CURRICULUMMAP = (roles && roles.includes("CURRICULUMMAP"))
        const EBOOKUPLOAD = (roles && roles.includes("EBOOKUPLOAD"))
        const TRAINER = (roles && roles.includes("TRAINER"))


        console.log("role status CONTENT UPLOAD at login", CONTENTUPLOAD)
        console.log("role status QUALITYASSURANCE at login ", QUALITYASSURANCE)
        console.log("role status SUPERADMIN at login ", SUPERADMIN)
        console.log("role status CONTENTMAP at login ", CONTENTMAP)
        console.log("role status CURRICULUMMAP at login", CURRICULUMMAP)
        console.log("role status EBOOKUPLOAD at login ", EBOOKUPLOAD)
        console.log("role status TRAINER at login", TRAINER)


        if (userLoggedIn) {
            if (userRole == Constants.Application.ROLE_CONT_PROV) {
                if (SUPERADMIN) {
                    console.log("entering in content provider dashboard ")
                    navigateToContent(respRole, respData)

                } else if (CONTENTUPLOAD) {
                    console.log("entering in content provider content upload ")
                    navigateToContUpload(respRole, respData)
                } else if (QUALITYASSURANCE) {
                    console.log("entering in content provider QualityAssurance ")
                    navigateToContUpload(respRole, respData)
                }

            } else if (userRole == Constants.Application.ROLE_EBOOK_PUB) {

                if (SUPERADMIN) {
                    navigateToPublisher(respRole, respData)
                } else if (EBOOKUPLOAD) {
                    navigateToPublisherEbooks(respRole, respData)
                } else if (CONTENTMAP) {
                    navigateToPublisherCurriculum(respRole, respData)
                } else if (CURRICULUMMAP) {
                    navigateToPublisherCurriculum(respRole, respData)
                } else if (TRAINER) {
                    navigateToPublisherTrainer(respRole, respData)
                }
            }

        }





        return () => {
            //
        }

    }, [])




    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div>


                <div style={{ display: "flex", flexDirection: "column", marginTop: 50, justifyContent: "center", alignItems: "center" }}>
                    <img src={profilePic} alt="user profile pic" height="30%" width="30%" style={{ borderRadius: "50%" }} />
                    <Typography style={{ fontSize: 50, fontFamily: "Poppins, Helvetica, sans-serif", width: "100%", display: "flex", justifyContent: "center" }}>
                        Welcome,Pusthak Content!
                    </Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: 25, marginLeft: 10, justifyContent: "center", alignItems: "center" }}>
                    {/* <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                    
                </Avatar> */}
                    {/* <Typography component="h1" variant="h5">
                    Sign in
                </Typography> */}

                    {(!isLoading) ?
                        <Button>
                            <div
                                onClick={doLogin}
                                style={{
                                    margin: 10,
                                    borderRadius: 50,
                                    width: "200%",
                                    height: 50,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 15,
                                    backgroundColor: "blueviolet",
                                    color: "#fff"
                                }}
                            >
                                Pustak Content Provider
                            </div>
                        </Button>
                        :
                        <Button
                            disabled={isLoading}
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={doLogin}
                            style={{ margin: 10 }}

                        >
                            <CircularProgress />
                        </Button>

                    }


                    {(!isLoading) ?
                        <Button>
                            <div
                                onClick={doLogin}
                                style={{
                                    margin: 10,
                                    borderRadius: 50,
                                    width: "200%",
                                    height: 50,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 15,
                                    backgroundColor: "blueviolet",
                                    color: "#fff"
                                }}
                            >
                                Pustak Publisher
                            </div>
                        </Button>
                        :
                        <Button
                            disabled={isLoading}
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={doLogin}
                            style={{ margin: 10 }}

                        >
                            <CircularProgress />
                        </Button>

                    }

                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                        <Alert2 onClose={handleSnackClose} severity="warning">
                            Authentication failed!
                        </Alert2>
                    </Snackbar>


                </div>
            </div>
            <Box mt={25}>
                <Copyright />
            </Box>
        </Container>
    );
}