import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';


export default function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://pustak.co/">
                Pustak.co
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
