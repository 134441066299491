import axios from "axios";
import Constants from "../../resource/Constants";
import { UNITLESSONS_LIST_REQUEST, UNITLESSONS_LIST_SUCCESS, UNITLESSONS_LIST_FAILURE,
UNITLESSONS_REQUEST, UNITLESSONS_SUCCESS, UNITLESSONS_FAILURE } from "../constants/unitLessonsConstants";
import MapUnitAPI from '../../http/curriculummapunit'

const getUnitLessons = (id) => async (dispatch) => {
    try {

        console.log("id at actions", id)


        dispatch({ type: UNITLESSONS_LIST_REQUEST })

        const response = await MapUnitAPI.getUnitLessons(id)
        const { data } = response.data
        console.log("data in list unit lessons get ===========", data)
        dispatch({ type: UNITLESSONS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: UNITLESSONS_LIST_FAILURE, payload: error.message })

    }
}

const postNewLesson = (data) => async (dispatch) => {

    dispatch({ type: UNITLESSONS_REQUEST })

    try {
        // data variable is the new curriculum-map object to be added to previous list

        // attach new object to the old list and send new list as payload
        const { newObj, lessonList } = data;

        console.log(`lessonlist is ${JSON.stringify(lessonList)}`)
        let newData = lessonList;
        newData.data.Units.push(newObj);

        console.log('now adding new lesson to lessons list')
        dispatch({ type: UNITLESSONS_SUCCESS, payload: newData })

    } catch (error) {
        dispatch({ type: UNITLESSONS_FAILURE, payload: error.message })
    }
}


export { getUnitLessons, postNewLesson }
