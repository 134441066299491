import axios from "axios";
import Constants from "../../../resource/Constants"
import { SINGLEEVENTPOST_LIST_REQUEST, SINGLEEVENTPOST_LIST_SUCCESS, SINGLEEVENTSPOST_LIST_FAIL, SINGLEVIDEOPOST_LIST_FAIL, SINGLEVIDEOPOST_LIST_REQUEST, SINGLEVIDEOPOST_LIST_SUCCESS, SUBSCRIBERSDETAILSPOST_LIST_FAIL, SUBSCRIBERSDETAILSPOST_LIST_REQUEST, SUBSCRIBERSDETAILSPOST_LIST_SUCCESS, TRAINTEACHVIDEOSPOST_LIST_FAIL, TRAINTEACHVIDEOSPOST_LIST_REQUEST, TRAINTEACHVIDEOSPOST_LIST_SUCCESS } from "../../constants/Trainer/AllEventsPost";
import { listAllEventsGet, listAllVideosGet, listSubscribersGet, listTeachVideosGet } from "./EventsGet";


const query = new URLSearchParams(window.location.search);
const event_id = query.get("eve_id");

const listSingleEventPost = (body) => async (dispatch) => {
    try {
        dispatch({ type: SINGLEEVENTPOST_LIST_REQUEST })
        console.log("body at listSingleEventpost",body)

        const url = Constants.Application.PARTNER_END_URL + `/save-event/`
        const options ={
            method:"POST",
            data:body,
            url,
        }
        const { data } = await axios(options)
        console.log("listAllEventsPost ======================", data)
        dispatch({ type: SINGLEEVENTPOST_LIST_SUCCESS, payload: data })
        dispatch(listAllEventsGet())
    } catch (error) {
        dispatch({ type: SINGLEEVENTSPOST_LIST_FAIL, payload: error.message })

    }
}


const listSingleVideoPost = (body) => async (dispatch) => {
    try {
        dispatch({ type: SINGLEVIDEOPOST_LIST_REQUEST })

        const url = Constants.Application.PARTNER_END_URL + `/save-event-video/`
        const options ={
            method:"POST",
            data:body,
            url,
        }
        const { data } = await axios(options)
        console.log("listSingleVideoPost ======================", data)
        dispatch({ type: SINGLEVIDEOPOST_LIST_SUCCESS, payload: data })
        dispatch(listAllVideosGet(event_id))
    } catch (error) {
        dispatch({ type: SINGLEVIDEOPOST_LIST_FAIL, payload: error.message })

    }
}


const listSubscribersPost = (body) => async (dispatch) => {
    try {
        dispatch({ type: SUBSCRIBERSDETAILSPOST_LIST_REQUEST })

        const url = Constants.Application.PARTNER_END_URL + `/save-event-subscribe/`
        const options ={
            method:"POST",
            data:body,
            url,
        }
        const { data } = await axios(options)
        console.log("listSubscribersPost ======================", data)
        dispatch({ type: SUBSCRIBERSDETAILSPOST_LIST_SUCCESS, payload: data })
        dispatch(listSubscribersGet(event_id))
    } catch (error) {
        dispatch({ type: SUBSCRIBERSDETAILSPOST_LIST_FAIL, payload: error.message })

    }
}

const listTeachVideosPost = (body,instId,boardId,clsStd,subId) => async (dispatch) => {
    try {
        dispatch({ type: TRAINTEACHVIDEOSPOST_LIST_REQUEST })

        const url = Constants.Application.PARTNER_END_URL +`/save-videos/`
        const options ={
            method:"POST",
            data:body,
            url,
        }
        const { data } = await axios(options)
        console.log("listTeachVideosPost ======================", data)
        dispatch(listTeachVideosGet(instId,boardId,clsStd,subId))
        dispatch({ type: TRAINTEACHVIDEOSPOST_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: TRAINTEACHVIDEOSPOST_LIST_FAIL, payload: error.message })

    }
}


export {
     listSingleEventPost ,
     listSingleVideoPost,
     listSubscribersPost,
     listTeachVideosPost,
    }