import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { epubmainListItems, epubsecondaryListItems } from './SidebarList';
import TableModel from "../contentmaker/TableModel"
import { Route, Link, BrowserRouter as Router, Switch, Redirect, HashRouter, useHistory } from 'react-router-dom'
import ContentMaking from '../contentmaker/ContentMaking';
// import Footer from "./Footer"
import { Avatar, Button } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import ContentMaking1 from "../contentmaker/ContentMaking1"
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Settings from '../contentmaker/Settings';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import EpubView from './EbookReaderScreen';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SidebarContent from './SidebarScreen';
import contentTable from "./MyBooksScreen"
import Contentlist from './MyBooksScreen';
import ebookContentMaking from "./ebookAddingContentScreen"
import LessonPlan from "./LessonPlanScreen";
import Quiz from "./QuizScreen"
import Ebook from "./UploadEbookScreen"
import { useState } from 'react';
import logo from "../../assets/logo.png"
import publisherLogo from "../../assets/PSON.jpeg"
import McgrawHill from "../../assets/Mcgrawhill.jpg"
import "../../css/sidebar.css"
import MapList from '../curriculummap/MapList';
import UnitList from '../curriculummap/UnitList';
import { ContactlessOutlined, SettingsInputAntennaTwoTone } from '@material-ui/icons';
import SidebarContent1 from './ContentSidebar';
import ContentTable from '../contentmaker/ContentListTable';
// import UploadEbookScreen from './UploadContent';
import PublisherListSideBar from './PublisherListSideBar';
import LeftCard from "./slots/LeftCard"
import { Helmet } from 'react-helmet';
import Copyright from '../user/slots/Copyright';

function Copyright2() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>
            {'Copyright © '}
            <Link color="inherit" href="https://kgtopg.com/">
                KGtoPG
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        display: "flex",
        justifyContent: "center",
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        // justifyContent: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        minWidth: '100%'

    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0
    },
}));



export default function Dashboard(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [showButton, setShowButton] = useState(false)
    const [AccordClose, setAccordClose] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [showLogo, setShowLogo] = useState(true)
    const [state, setState] = React.useState({
        right: false,
    })

    const [showSideCont, SetShowSideCont] = useState(false)
    const [path, setPath] = useState('')
    const [readerData, setReaderData] = useState("")
    const [isLoadingscr, setIsLoadingscr] = useState(false)
    const [sidebarStatus, setSidebarStatus] = useState(false)
    const [sideBarPubData, setSideBarPubData] = useState(null)






    const handleDrawerOpen = () => {
        setOpen(true);
        setAccordClose(true)
        setShowLogo(true)
        SetShowSideCont(false)
    };
    const handleDrawerClose = () => {
        setOpen(false);
        setAccordClose(false)
        setShowLogo(false)

    };



    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


    const hideFab = (list, index) => {
        console.log("hiding the button", list, index)
        setShowButton(true)
        setIsLoadingscr(false)
        console.log("show button at parent", showButton)


    }


    const showSideContent = (status, pubData) => {
        console.log("sidecontent status", status, pubData)

        props.hideSideBar(status)
        props.sideBarProv(pubData)

        setSidebarStatus(status)
        setSideBarPubData(pubData)


        // console.log("AFTER SIDEBAR STATUS UPDATED",status,pubData)
        // SetShowSideCont(status)
        // if (status == true) {
        //     SetShowSideCont(status)
        //     setOpen(false)
        //     setShowLogo(false)
        // } else {
        //     SetShowSideCont(status)
        //     setOpen(true)
        //     setShowLogo(true)
        // }
        // setPath(window.location.pathname)

        // console.log(" callback function status", status, window.location.pathname)
    }

    console.log("windows path in dashboard", window.location.pathname)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    }


    useEffect(() => {



    }, [])


    const sendDataToReaderScreen = (list, status) => {
        setIsLoading(true)
        console.log("sending..... Data to Reader Screen", list)
        if (list) {
            setReaderData(list)
            setIsLoading(false)
            setShowButton(status)
        }

    }



    console.log("state reader data ", readerData)
    return (
        <div className={classes.root}>
            {isLoadingscr == false ?
                // <Router>
                <>
                    <CssBaseline />
                    <LinearProgress />





                    {/* <Drawer
                        variant="permanent"
                        classes={{
                            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                        }}
                        open={open}
                    >



                        {open ?

                            <div className={classes.toolbarIcon}>
                                <Avatar alt="logo" src={McgrawHill} className={classes.large} style={{ display: "flex", justifyContent: "center" }} />
                                <IconButton onClick={handleDrawerClose}>
                                    <ChevronLeftIcon style={{ color: "#3f51b5", marginRight: -20 }} />
                                    <ChevronLeftIcon style={{ color: "#3f51b5" }} />
                                </IconButton>
                            </div>
                            :
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            >
                                <MenuIcon style={{ color: "gray", display: "flex", justifyContent: "center", alignContent: "flex-end", marginLeft: 40, padiing: 10 }} />
                            </IconButton>
                        }

                        <Divider />

                        <List><SidebarContent buttonstatus={(list, index) => { hideFab(list, index) }} hideAccordian={AccordClose} showContent={(status) => { showSideContent(status, path) }} /></List>
                        <Divider />

        
                        {showLogo ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", top: "50vh", position: "relative", width: "60%", alignSelf: "center", alignContent: "flex-end" }}>
                                <img alt="logo" src={logo} style={{ display: "flex", justifyContent: "center", width: 100, height: 50 }} />
                                <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 14, fontWeight: "500" }}>Publisher</span>

                            </div>
                            :
                            <></>
                        }

                    </Drawer>

                 */}


                    {/* {showSideCont ?
                        <div style={{ width: 300, height: "100vh", backgroundColor: "#fff" }}>
                            <List><PublisherListSideBar buttonstatus={(list, index) => { hideFab(list, index) }} hideAccordian={AccordClose} sendDatatoReaderScreen={(list, status) => { sendDataToReaderScreen(list, status) }} /></List>
                        </div>
                        :
                        <></>

                    } */}


                    <LeftCard />

                    <main className={classes.content}>
                        <Helmet>
                            <title>My Books | Publish Pustak</title>
                        </Helmet>

                        <Container className={classes.container}>
                            <Grid container spacing={3}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    // minHeight: '90%',
                                    width: '100%'
                                }}>


                                {/* <Grid item xs={12}>
                              
                                    {
                                        <Redirect exact from="/publisher-ebook" to="/publisher-ebook/mybooks" />
                                    }
                                    <Switch>
                                        <Route exact={true} path="/publisher-ebook/upload" component={Ebook} />
                                        <Route exact={true} path="/publisher-ebook/mapping" render={() => <EpubView showButtonStatus={showButton} readerDataList={readerData} />} />
                                        <Route exact={true} path="/contentmaker/settings" component={Settings} />
                                        <Route exact={true} path="/publisher-ebook/mybooks" render={() => <Contentlist sidebarStatus={(status, pubData) => showSideContent(status, pubData)} />} />
                                        <Route exact={true} path="/publisher/addcontent" component={ebookContentMaking} />
                                        <Route exact={true} path="/publisher/quiz" component={Quiz} />
                                        <Route exact={true} path="/publisher/lessonplan" component={LessonPlan} />
                                        <Route exact={true} path="/publisher/content" component={ContentTable} />
                                        <Route exact={true} path="/publisher-ebook/curriculum-map" component={MapList} />
                                        <Route exact={true} path="/publisher-ebook/curriculum-map/set-units/1" component={UnitList} />
                                        <Route exact={true} path="/publisher-ebook/upload-ebook" component={UploadEbookScreen} />
                                        <Route exact path="/content/1" component={ContentMaking1} /> 
                                    </Switch>
                          
                                </Grid> */}
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    width: '100%'
                                }}>

                                    <Contentlist sidebarStatus={(status, pubData) => showSideContent(status, pubData)} />
                                </div>
                            </Grid>



                            <Box pb={4} mt={4}>
                                <Copyright />
                            </Box>




                            {/* <div style={{ justifyContent: "flex-end", minWidth: "100%", display: "flex" }}> */}
                            {/* <Fab
                                aria-label="add"
                                style={{
                                    alignSelf: "flex-end",
                                    position: "absolute",
                                    right: 5,
                                    backgroundColor: "#ffffff",
                                    borderRadius: 10,
                                    top: 200,
                                    zIndex: 2,
                                }}
                            >
                                <Link to="/publisher-ebook/settings" style={{ textDecoration: "none" }}>
                                    <div style={{
                                        minWidth: 35,
                                        minHeight: 20,
                                        backgroundColor: "gray",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        backgroundColor: "#F3F6F9",
                                        borderRadius: 10
                                    }}
                                    >
                                        <SettingsOutlinedIcon color="primary" style={{ marginTop: 5 }} />
                                    </div>
                                </Link>

                            </Fab> */}
                            {/* </div> */}
                        </Container>
                    </main>
                </>
                // </Router>
                :
                <></>
            }
        </div>
    );
}