import { TRASHDETAILSPOST_LIST_FAIL, TRASHDETAILSPOST_LIST_REQUEST, TRASHDETAILSPOST_LIST_SUCCESS, TRASHDETAILSRESTORE_LIST_FAIL, TRASHDETAILSRESTORE_LIST_REQUEST, TRASHDETAILSRESTORE_LIST_SUCCESS, TRASHDETAILS_LIST_FAIL, TRASHDETAILS_LIST_REQUEST, TRASHDETAILS_LIST_SUCCESS } from "../constants/TrashBinConstants";

function trashbinGetReducer(state ={TrashDetails:[]},action) {

    switch (action.type) {
        case TRASHDETAILS_LIST_REQUEST:
            return { loadingTrashGet: true }
        case TRASHDETAILS_LIST_SUCCESS:
            return {loadingTrashGet:false,TrashDetails:action.payload};
        case TRASHDETAILS_LIST_FAIL:
            return {loadingTrashGet:false,errorTrashGet:action.payload}
        default:
            return state;
    }

}

function trashbinPostReducer(state ={TrashDetailsPost:[]},action) {

    switch (action.type) {
        case TRASHDETAILSPOST_LIST_REQUEST:
            return { loadingTrashPost: true }
        case TRASHDETAILSPOST_LIST_SUCCESS:
            return {loadingTrashPost:false,TrashDetailsPost:action.payload};
        case TRASHDETAILSPOST_LIST_FAIL:
            return {loadingTrashPost:false,errorTrashPost:action.payload}
        default:
            return state;
    }

}

function trashbinRestoreReducer(state ={TrashRestore:[]},action) {

    switch (action.type) {
        case TRASHDETAILSRESTORE_LIST_REQUEST:
            return { loadingTrashRes: true }
        case TRASHDETAILSRESTORE_LIST_SUCCESS:
            return {loadingTrashRes:false,TrashRestore:action.payload};
        case TRASHDETAILSRESTORE_LIST_FAIL:
            return {loadingTrashRes:false,errorTrashRes:action.payload}
        default:
            return state;
    }

}

export { trashbinGetReducer,trashbinPostReducer ,trashbinRestoreReducer}