import axios from "axios";
import Constants from "../../resource/Constants"

import { BOARDDETAILS_LIST_FAIL, BOARDDETAILS_LIST_REQUEST, BOARDDETAILS_LIST_SUCCESS } from "../constants/boardDetailsConstants";

const listBoardGet = (type_id) => async (dispatch) => {

    try {

        // const url = Constants.Application.PLATFORM_END_URL
        // url += `/boards/`
        // console.log("board url", url)

       
        dispatch({ type: BOARDDETAILS_LIST_REQUEST })
        console.log("dispatching type_id ", type_id)

        
        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/inst-type-board/?type_id=${type_id}`)
        
        console.log("board details ======================", data)
        dispatch({ type: BOARDDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: BOARDDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listBoardGet }