import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography
} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';

import { Search as SearchIcon } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { useSelector } from 'react-redux';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  shape2: {
    backgroundColor: theme.palette.secondary.light,
    width: 20,
    height: 20,
  },
  shapeCircle: {
    borderRadius: '50%',
  },
}));

const PubDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Select type</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value={10}>Published or in Draft</option>
          <option value={20}>Draft</option>
          <option value={30}>Thirty</option>
        </Select>
      </FormControl>
    </div>
  )
}

const ActDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Activity</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value={10}>Any Activity</option>
          <option value={20}>All Activities</option>
          <option value={30}>Thirty</option>
        </Select>
      </FormControl>
    </div>
  )
}

const MapListToolbar = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });
  const [open, setOpen] = React.useState(false);
  const rectangle = <div className={classes.shape} />;
  const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;
  const [open2, setOpen2] = React.useState(false);

  const currMap = useSelector(state => state.currMap.currMap)
  const mapId = (currMap != null) ? currMap.id : 0
  const title = (currMap != null) ? currMap.map_title_text : ''

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box {...props}>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <LibraryBooksIcon />

          <div
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <Typography
              color="textSecondary"
              // display="inline"
              // sx={{ pl: 1 }}
              variant="body1"
              style={{
                fontSize: 18,
                marginLeft: 10
              }}
            >
              {title}
            </Typography>
            &nbsp;&nbsp;
            <Chip label={`DRAFT UPDATED ${(currMap) ? 'COMING SOON' : 'n/a'}`} />
          </div>

        </div>
        
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button sx={{ mx: 2 }} style={{ marginRight: 20 }} onClick={handleClick}>
            Export
        </Button>
          <Button
            color="primary"
            variant="contained"
            // onClick={handleClickOpen}
            onClick={handleClick}
          >
            PUBLISH
        </Button>
          <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
            <Alert onClose={handleClose2} severity="info">
              This feature is coming soon!
          </Alert>
          </Snackbar>
        </Box>
      </div>

      <div>
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create New Curriculum Map</DialogTitle>
          <DialogContent>
            <DialogContentText>
              New Curriculum Map lets you setup a new curriculum unit sets with time and pacing.
          </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Curriculum Map Name"
              type="text"
              fullWidth
            />

            <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
              <InputLabel htmlFor="filled-age-native-simple">Template</InputLabel>
              <Select
                native
                value={state.age}
                onChange={handleChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
              >
                <option aria-label="None" value="" />
                <option value={10}>KGtoPG - Default UBD Template</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </Select>
            </FormControl>

            <TextField
              autoFocus
              margin="dense"
              id="tags"
              label="Tags"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
          </Button>
            <Button onClick={handleClose} color="primary">
              Subscribe
          </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* 

      <Box sx={{ mt: 3 }} style={{ marginTop: 20 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: '100%' }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search for curriculum map"
                variant="outlined"
              />
            </Box>

            <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
              <PubDD />
              <div style={{ width: 10 }} />
              <ActDD />
            </div>
          </CardContent>
        </Card>
      </Box>
    
     */}
    </Box>
  )
};

export default MapListToolbar;
