

export const ADMINPUBSENDMAILDETAILS_LIST_REQUEST = 'ADMINPUBSENDMAILDETAILS_LIST_REQUEST'
export const ADMINPUBSENDMAILDETAILS_LIST_SUCCESS = ' ADMINPUBSENDMAILDETAILS_LIST_SUCCESS'
export const ADMINPUBSENDMAILDETAILS_LIST_FAIL = 'ADMINPUBSENDMAILDETAILS_LIST_FAIL'

export const ADMINPUBROLEGETDETAILS_LIST_REQUEST = 'ADMINPUBROLEGETDETAILS_LIST_REQUEST'
export const ADMINPUBROLEGETDETAILS_LIST_SUCCESS = ' ADMINPUBROLEGETDETAILS_LIST_SUCCESS'
export const ADMINPUBROLEGETDETAILS_LIST_FAIL = 'ADMINPUBROLEGETDETAILS_LIST_FAIL'

export const ADMINPUBROLEPUTDETAILS_LIST_REQUEST = 'ADMINPUBROLEPUTDETAILS_LIST_REQUEST'
export const ADMINPUBROLEPUTDETAILS_LIST_SUCCESS = ' ADMINPUBROLEPUTDETAILS_LIST_SUCCESS'
export const ADMINPUBROLEPUTDETAILS_LIST_FAIL = 'ADMINPUBROLEPUTDETAILS_LIST_FAIL'

export const ADMINPUBROLESAVEDETAILS_LIST_REQUEST = 'ADMINPUBROLESAVEDETAILS_LIST_REQUEST'
export const ADMINPUBROLEPSAVEDETAILS_LIST_SUCCESS = ' ADMINPUBROLEPSAVEDETAILS_LIST_SUCCESS'
export const ADMINPUBROLESAVEDETAILS_LIST_FAIL = 'ADMINPUBROLESAVEDETAILS_LIST_FAIL'


export const ADMINCONTSENDMAILDETAILS_LIST_REQUEST = 'ADMINCONTSENDMAILDETAILS_LIST_REQUEST'
export const ADMINCONTSENDMAILDETAILS_LIST_SUCCESS = ' ADMINCONTSENDMAILDETAILS_LIST_SUCCESS'
export const ADMINCONTSENDMAILDETAILS_LIST_FAIL = 'ADMINCONTSENDMAILDETAILS_LIST_FAIL'

export const ADMINCONTROLEGETDETAILS_LIST_REQUEST = 'ADMINCONTROLEGETDETAILS_LIST_REQUEST'
export const ADMINCONTROLEGETDETAILS_LIST_SUCCESS = ' ADMINCONTROLEGETDETAILS_LIST_SUCCESS'
export const ADMINCONTROLEGETDETAILS_LIST_FAIL = 'ADMINCONTROLEGETDETAILS_LIST_FAIL'

export const ADMINCONTROLEPUTDETAILS_LIST_REQUEST = 'ADMINCONTROLEPUTDETAILS_LIST_REQUEST'
export const ADMINCONTROLEPUTDETAILS_LIST_SUCCESS = ' ADMINCONTROLEPUTDETAILS_LIST_SUCCESS'
export const ADMINCONTROLEPUTDETAILS_LIST_FAIL = 'ADMINCONTROLEPUTDETAILS_LIST_FAIL'

export const ADMINCONTROLESAVEDETAILS_LIST_REQUEST = 'ADMINCONTROLESAVEDETAILS_LIST_REQUEST'
export const ADMINCONTROLEPSAVEDETAILS_LIST_SUCCESS = ' ADMINCONTROLEPSAVEDETAILS_LIST_SUCCESS'
export const ADMINCONTROLESAVEDETAILS_LIST_FAIL = 'ADMINCONTROLESAVEDETAILS_LIST_FAIL'







