import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listDashBoardPubGraphs } from '../../../../redux/actions/DashBoardActions';

const Sales = (props) => {
  const theme = useTheme();

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };
  
  const dispatch = useDispatch()
  useEffect(()=>{

 dispatch(listDashBoardPubGraphs())
return(()=>{
  //
})
  },[])

const dashboardPubGraphsGet =  useSelector(state => state.dashboardPubGraphs)
const {loadingDashboardPubGraphs,DashboardPubGraphs,errorDashPubGraphs} = dashboardPubGraphsGet

let latestData = []
let previousData = []
let latestDates= []
{loadingDashboardPubGraphs && dashboardPubGraphsGet ?
  console.log("data is loading")
  :
  console.log("graphs data of pub",DashboardPubGraphs)
}


{ dashboardPubGraphsGet && DashboardPubGraphs && DashboardPubGraphs.data &&DashboardPubGraphs.data.latest?
  // console.log("graphs at pub",DashboardPubGraphs.data)  
    DashboardPubGraphs && DashboardPubGraphs.data.latest.map((list,index)=>{
    // list.latest.map((graphCount) => {
        latestData.push(list.graph)
    // })
  })


  :
console.log("graphs at pub",DashboardPubGraphs)  
}

{ dashboardPubGraphsGet && DashboardPubGraphs && DashboardPubGraphs.data ?
  // console.log("graphs at pub",DashboardPubGraphs.data)  
  DashboardPubGraphs && DashboardPubGraphs.data.previous.map((list,index)=>{
    // list.previous.map((graphCount) => {
      previousData.push(list.graph)
    // })
  })
  :
console.log("graphs at pub",DashboardPubGraphs)  
}

{ dashboardPubGraphsGet && DashboardPubGraphs && DashboardPubGraphs.data &&DashboardPubGraphs.data.latest?
  // console.log("graphs at pub",DashboardPubGraphs.data)  
    DashboardPubGraphs && DashboardPubGraphs.data.latest.map((list,index)=>{
    // list.latest.map((graphCount) => {
      let date = list.date 
      latestDates.push(date)
    // })
  })


  :
console.log("graphs at pub",DashboardPubGraphs)  
}
console.log("latests data of graphs",latestData)
console.log("previous data of graphs",previousData)

const data = {
  datasets: [
    {
      backgroundColor: colors.indigo[500],
      data:latestData ? latestData : [0, 0, 0, 0, 0, 0, 0],
      label: 'This weak'
    },
    {
      backgroundColor: colors.grey[200],
      data:previousData ?previousData:[0, 0, 0, 0, 0, 0, 0],
      label: 'Last weak'
    }
  ],
  labels:latestDates?latestDates: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug'],

};
  return (
    <Card {...props}>
      <CardHeader
        action={(
          <Button
            endIcon={<ArrowDropDownIcon />}
            size="small"
            variant="text"
          >
            Last 7 days
          </Button>
        )}
        title="Latest Usage"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
          style={{
            height: 400
          }}
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box>
    </Card>
  );
};

export default Sales;
