import {
  Avatar,
  Card,
  Box,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { green } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';


function TotalProfit(props) {

  return (
    <Card {...props}
      style={{
        minHeight: '100%'
      }}>
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              REVENUE THIS MONTH
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              ₹{props.count}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: indigo[600],
                height: 56,
                width: 56
              }}
            >
              <MoneyOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2
          }}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            minWidth: '100%',
            width: '100%'
          }}
        >
          
          {/* <ArrowUpwardIcon sx={{ color: green[900] }} /> */}
          <Typography
            variant="body2"
            sx={{
              color: green[900],
              mr: 1
            }}
          >
            {props.percent}%
        </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
            style={{
              marginLeft: 5
            }}
          >
            Since last month
        </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TotalProfit;
