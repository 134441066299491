
import './App.css';
import { Route, Link, BrowserRouter as Router, Switch, Redirect, BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core';

import EbookDashboard from "./pages/ebook/EbookDashboard"
// import ComicsDashboard from "./pages/ebook/ComicsDashboard"
import MapDashboard from './pages/curriculummap/MapDashboard';
import Dashboard from './pages/contentmaker/Dashboard';
import SubjectDashboard from './pages/contentmaker/SubjectDashboard';
import SubjectDashboard2 from './pages/contentmaker/SubjectDashboard2';
import Login from './pages/user/Login';
import DoLogin from './pages/user/DoLogin';
import Logout from './pages/user/Logout';
import { useHistory } from "react-router-dom";
import Settings from './pages/contentmaker/Settings';
// import UploadEbookScreen from './pages/ebook/UploadContent';
import UnitList from './pages/curriculummap/UnitList';
import EventDetails from './pages/trainer/EventDetails';
import MapList from './pages/curriculummap/MapList';
import SettingsEbook from './pages/ebook/SettingsEbook';
import EpubViewScreen from './pages/ebook/EbookReaderScreen';
import BookReaderScreen from './pages/ebook/BookReaderScreen';
import { useState } from 'react';
import MainPage from './pages/user/HomePage';
import PublisherDashboard from './pages/ebook/PublisherDashboard';
import PublisherContent from './pages/ebook/PublisherContent';
import ContentDashboard from './pages/contentmaker/ContentDashboard';
import { Helmet } from 'react-helmet';


import GlobalStyles from './GlobalStyles';
import theme from './theme';
import SchoolsList from './pages/ebook/UsersList';
import InstituteDetails from './pages/ebook/InstituteDetails';
import SubscriberDetails from './pages/ebook/SubscriberDetails';
import TrainList from './pages/trainer/TrainList';
import ProfilePage from './pages/user/ProfilePage';
import UsersListCont from './pages/contentmaker/UsersListCont';
import TeachPreview from './pages/ebook/TeachPreview';
import Publisherlist from './pages/contentmaker/PublishersList';
import PriceSetup from './pages/contentmaker/PriceSetup';
import PriceSetupILC from './pages/contentmaker/PriceSetupILC';
import Cookie from 'js-cookie';
import ActivityPage from './pages/contentmaker/slots/AdminControls/ActivityPage';
import ActivityPagePub from './pages/ebook/slots/AdminControls/ActivityPagePub';
import HelpSupportCont from './pages/contentmaker/slots/Settings/HelpSupport';
import HelpSupportPub from './pages/ebook/slots/Settings/HelpSupportPub';
import TermsConditionsCont from './pages/contentmaker/slots/Settings/TermsConditions';
import TermsConditionsPub from './pages/ebook/slots/Settings/TermsConditions';

import SignUp from './pages/user/SignUp';
import ThankyouSignUp from './pages/user/ThankyouSignUp';
import ForgotPassw from './pages/user/ForgotPassw';
import ChangePassw from './pages/user/ChangePassw';

import EventVideosPage from './pages/trainer/EventVideosPage';
import TrainTeachVideoPage from './pages/trainer/TrainTeachVideoPage';
import TrainerVideos from './pages/trainer/TrainerVideos';
import AccountSwitch from './pages/user/slots/SwitchAccount';
import TrashBinPub from './pages/ebook/slots/TrashBinScreen';
import ComicsDashboard from './pages/ebook/ComicsDashboard';
import SubscriberList from './pages/ebook/SubscriberList';
import PartnerInvites from './pages/contentmaker/PartnerInvites';
import PayInList from './pages/ebook/PayIn';
import PayOutList from './pages/ebook/PayOut';
import PayOutDetailsList from './pages/ebook/PayOutDetailsList';
import PayOutDetailsTypeList from './pages/ebook/PayOutDetailsTypeList';
import PayOutHistoryList from './pages/ebook/PayOutHistoryList';
import ContentProvAccounts from './pages/contentmaker/Accounts';
import ContentProvMonthAccounts from './pages/contentmaker/MonthAccounts';
import PublisherAccounts from './pages/ebook/PublisherAccounts';
import PaymentHistoryList from './pages/contentmaker/PaymentHistoryList';
import LearnSubscribers from './pages/contentmaker/LearnSubscribers';

function App() {

  const [sideStatus, setSideStatus] = useState(false)
  const [sideProvList, setSideProvList] = useState(null)



  const updateSideStatus = (status) => {
    setSideStatus(status)
  }

  const updateSideProvList = (pubData) => {
    setSideProvList(pubData)
  }

  // const roles = Cookie.get('kgtopg.partner.user.roles')
  // const CONTENTUPLOAD = (roles && roles.includes("CONTENTUPLOAD"))
  // const QUALITYASSURANCE = (roles && roles.includes("QUALITYASSURANCE"))
  // const SUPERADMIN = (roles && roles.includes("SUPERADMIN"))
  // const CONTENTMAP = (roles && roles.includes("CONTENTMAP"))
  // const CURRICULUMMAP = (roles && roles.includes("CURRICULUMMAP"))
  // const EBOOKUPLOAD = (roles && roles.includes("EBOOKUPLOAD"))
  // const TRAINER = (roles && roles.includes("TRAINER"))

  // console.log("role status",
  //  CONTENTUPLOAD,
  //   QUALITYASSURANCE, 
  //   SUPERADMIN,
  //   CONTENTMAP,
  //   CURRICULUMMAP,
  //   EBOOKUPLOAD,
  //   TRAINER
  //   )






  const routing = (
    <BrowserRouter>
      <Helmet>
        <title>Publish Pustak | KGtoPG</title>

      </Helmet>

      <Switch>
        <Route exact={true} path="/" component={Login} />
        <Route exact={true} path="/home" component={MainPage} />
        <Route exact={true} path="/profile" component={ProfilePage} />
        <Route exact={true} path="/publisher-content/dashboard" component={ContentDashboard} />
        <Route exact={true} path="/publisher-content/content/:id" component={Dashboard} />
        <Route exact={true} path="/publisher-content/my-media/media-components" component={SubjectDashboard} />
        <Route exact={true} path="/publisher-content/my-media/instructor-led-content" component={SubjectDashboard2} />
        <Route exact={true} path="/publisher-content/users" component={UsersListCont} />
        <Route exact={true} path="/publisher-content/accounts" component={ContentProvAccounts} />
        <Route exact={true} path="/publisher-content/month-accounts/:num" component={ContentProvMonthAccounts} />
        <Route exact={true} path="/publisher-content/payment-history/:id" component={PaymentHistoryList} />
        <Route exact={true} path="/publisher-content/publishers" component={Publisherlist} />
        <Route exact={true} path="/publisher-content/learn-subscribers" component={LearnSubscribers} />
        <Route exact={true} path="/publisher-content/price-settings" component={PriceSetup} />
        <Route exact={true} path="/publisher-content/price-settings/ilc" component={PriceSetupILC} />
        <Route exact={true} path="/publisher-content/settings" component={Settings} />
        <Route exact={true} path="/publisher-content/settings/help" component={HelpSupportCont} />
        <Route exact={true} path="/publisher-content/settings/terms-conditions" component={TermsConditionsCont} />
        <Route exact={true} path="/publisher-content/admin-activity" component={ActivityPage} />
        <Route exact={true} path="/publisher-ebook/dashboard" component={PublisherDashboard} />
        <Route exact={true} path="/publisher-ebook/content" component={PublisherContent} />
        <Route exact={true} path="/publisher-ebook/books/academics" render={() => <EbookDashboard hideSideBar={(status) => { updateSideStatus(status) }} sideBarProv={(pubData) => { updateSideProvList(pubData) }} />} />
        <Route exact={true} path="/publisher-ebook/accounts" component={PublisherAccounts} />
        {/* <Route exact={true} path="/publisher-ebook/books/others" render={() => <ComicsDashboard hideSideBar={(status) => { updateSideStatus(status) }} sideBarProv={(pubData) => { updateSideProvList(pubData) }} />} /> */}
        <Route exact={true} path="/publisher-ebook/mapping" render={() => <EpubViewScreen hideSide={sideStatus} sideProvList={sideProvList} />} />
        <Route exact={true} path="/publisher-ebook/users" component={SchoolsList} />
        <Route exact={true} path="/publisher-ebook/subscribers" component={SubscriberList} />
        <Route exact={true} path="/publisher-ebook/pay-in" component={PayInList} />
        <Route exact={true} path="/publisher-ebook/pay-out" component={PayOutList} />
        <Route exact={true} path="/publisher-ebook/pay-out-details/:id" component={PayOutDetailsList} />
        <Route exact={true} path="/publisher-ebook/pay-out-history/:id" component={PayOutHistoryList} />
        <Route exact={true} path="/publisher-ebook/pay-out-details-type/:id/:subId" component={PayOutDetailsTypeList} />
        <Route exact={true} path="/publisher-ebook/institute-details/:id" component={InstituteDetails} />
        <Route exact={true} path="/publisher-ebook/subscriber-details/:id" component={SubscriberDetails} />
        
        <Route exact={true} path="/publisher-content/invites" component={PartnerInvites} />
        <Route exact={true} path="/publisher-ebook/books/others" component={ComicsDashboard}/>
        <Route exact={true} path="/publisher-ebook/trainer" component={TrainList} />
        <Route exact={true} path="/publisher-ebook/trainer/event-videos/" component={EventVideosPage} />
        <Route exact={true} path="/pubisher-ebook/trainer/teacher-videos/" component={TrainTeachVideoPage} />
        <Route exact={true} path="/publisher-ebook/teach-preview" component={TeachPreview} />
        <Route exact={true} path="/publisher-ebook/admin-activity" component={ActivityPagePub} />
        <Route exact={true} path="/publisher-ebook/settings" component={SettingsEbook} />
        <Route exact={true} path="/publisher-ebook/settings/view-activity/" component={TeachPreview} />
        <Route exact={true} path="/curriculum-map" component={MapDashboard} />
        <Route exact={true} path="/publisher-ebook/curriculum-map" component={MapList} />
        <Route exact={true} path="/publisher-ebook/trash" component={TrashBinPub}/>
        <Route exact={true} path="/publisher-ebook/settings/help" component={HelpSupportPub} />
        <Route exact={true} path="/publisher-ebook/settings/terms-conditions" component={TermsConditionsPub} />
        {/* <Route exact={true} path="/publisher-ebook/curriculum-map/set-units/1" component={UnitList} /> */}
        <Route exact={true} path="/publisher-ebook/curriculum-map/set-units/:id" component={UnitList} />
        <Route exact={true} path="/publisher-ebook/trainer/event-details/:id" component={EventDetails} />
        <Route eaxct={true} path="/publisher-ebook/trainer/trainer-videos" component={TrainerVideos} />
        <Route exact={true} path="/publisher-ebook/map-content" render={() => <BookReaderScreen hideSide={sideStatus} sideProvList={sideProvList} />} />
        <Route exact path="/logout" component={Logout} />
        {/* external access */}
        <Route exact={true} path="/user/do-login" component={DoLogin} />
        <Route exact={true} path="/do-login" component={DoLogin} />
        <Route exact={true} path="/user/forgot-passw" component={ForgotPassw} />
        <Route exact={true} path="/user/change-passw" component={ChangePassw} />
        <Route exact={true} path="/user/register" component={SignUp} />
        <Route exact={true} path="/user/thank-you" component={ThankyouSignUp} />
        <Route exact={true} path="/user/switch-account" component={AccountSwitch} />
      </Switch>

      {/* <Route exact={true} path="/publisher-ebook/upload-ebook" component={UploadEbookScreen} />
      <Route exact={true} path="/publisher-ebook/curriculum-map/set-units/1" component={UnitList} />
      <Route exact={true} path="/publisher-ebook/curriculum-map" component={MapList} /> */}

    </BrowserRouter>
  )

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>

  );
}

export default App;
