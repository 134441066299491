/**
 * reducer for 
 * content redux
 */

import * as actions from '../actions/contentActions'

export const initialState = {
    content: [],
    loading: false,
    hasErrors: false,
}

export default function contentReducer(state = initialState, action) {
    switch(action.type) {
        case actions.GET_CONTENT:
            return { ...state, loading: true }
        case actions.GET_CONTENT_SUCCESS:
            return { content: action.payload, loading: false, hasErrors: false }
        case actions.GET_CONTENT_FAILURE:
            return { ...state, loading: false, hasErrors: true }

        default:
            return state
    }
}