/**
 * reducer for current curriculum map id
 */

import * as actions from '../actions/currMapIdActions'

export const initialState = {
    currMapId: 0,
    loading: false,
    hasErrors: false
}

export default function currLessonReducer(state = initialState, action) {
    switch(action.type) {
        case actions.CURRICULUMMAPID_CURR_REQUEST:
            return { ...state, loading: true }
        case actions.CURRICULUMMAPID_CURR_SUCCESS:
            console.log(`called map id reducer success with payload ${JSON.stringify(action.payload)}`)
            return { currMapId: action.payload, loading: false, hasErrors: false }
        case actions.CURRICULUMMAPID_CURR_FAILURE:
            return { ...state, loading: false, hasErrors: true }
        default:
            return state;
    }
}
