import { PUBLISHERTEACHDETAILS_LIST_FAIL, PUBLISHERTEACHDETAILS_LIST_REQUEST, PUBLISHERTEACHDETAILS_LIST_SUCCESS } from "../constants/TeachPreviewConstants";

function publisherTeachReducer(state ={pubTeachPlans:[]},action) {

    switch (action.type) {
        case PUBLISHERTEACHDETAILS_LIST_REQUEST:
            return { loadingPubTeach: true }
        case PUBLISHERTEACHDETAILS_LIST_SUCCESS:
            return {loadingPubTeach:false,pubTeachPlans:action.payload};
        case PUBLISHERTEACHDETAILS_LIST_FAIL:
            return {loadingPubTeach:false,errorPubTeach:action.payload}
        default:
            return state;
    }

}

export { publisherTeachReducer }