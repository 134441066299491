/**
 * reducer for current curriculum map unit lesson plan
 */

import * as actions from '../actions/currPlanActions'

export const initialState = {
    currPlan: null,
    loading: false,
    hasErrors: false
}

export default function currPlanReducer(state = initialState, action) {
    switch(action.type) {
        case actions.LESSON_PLAN_CURR_REQUEST:
            return { ...state, loading: true }
        case actions.LESSON_PLAN_CURR_SUCCESS:
            console.log(`called map unit lesson reducer success with payload ${JSON.stringify(action.payload)}`)
            return { currPlan: action.payload, loading: false, hasErrors: false }
        case actions.LESSON_PLAN_CURR_FAILURE:
            return { ...state, loading: false, hasErrors: true }
        default:
            return state;
    }
}
