import { ADMINCONTROLEGETDETAILS_LIST_FAIL, ADMINCONTROLEGETDETAILS_LIST_REQUEST, ADMINCONTROLEGETDETAILS_LIST_SUCCESS, ADMINCONTROLEPSAVEDETAILS_LIST_SUCCESS, ADMINCONTROLEPUTDETAILS_LIST_FAIL, ADMINCONTROLEPUTDETAILS_LIST_REQUEST, ADMINCONTROLEPUTDETAILS_LIST_SUCCESS, ADMINCONTROLESAVEDETAILS_LIST_FAIL, ADMINCONTROLESAVEDETAILS_LIST_REQUEST, ADMINCONTSENDMAILDETAILS_LIST_FAIL, ADMINCONTSENDMAILDETAILS_LIST_REQUEST, ADMINCONTSENDMAILDETAILS_LIST_SUCCESS, ADMINPUBROLEGETDETAILS_LIST_FAIL, ADMINPUBROLEGETDETAILS_LIST_REQUEST, ADMINPUBROLEGETDETAILS_LIST_SUCCESS, ADMINPUBROLEPSAVEDETAILS_LIST_SUCCESS, ADMINPUBROLEPUTDETAILS_LIST_FAIL, ADMINPUBROLEPUTDETAILS_LIST_REQUEST, ADMINPUBROLEPUTDETAILS_LIST_SUCCESS, ADMINPUBROLESAVEDETAILS_LIST_FAIL, ADMINPUBROLESAVEDETAILS_LIST_REQUEST, ADMINPUBSENDMAILDETAILS_LIST_FAIL, ADMINPUBSENDMAILDETAILS_LIST_REQUEST, ADMINPUBSENDMAILDETAILS_LIST_SUCCESS } from "../constants/AdminControlsConstants";


function pubSendMailReducer(state ={pubSendMail:[]},action) {

    switch (action.type) {
        case ADMINPUBSENDMAILDETAILS_LIST_REQUEST:
            return { loadingPubSendMail: true }
        case ADMINPUBSENDMAILDETAILS_LIST_SUCCESS:
            return {loadingPubSendMail:false,pubSendMail:action.payload};
        case ADMINPUBSENDMAILDETAILS_LIST_FAIL:
            return {loadingPubSendMail:false,errorPubSendMail:action.payload}
        default:
            return state;
    }

}

function pubRolesGetReducer(state ={pubRolesGet:[]},action) {

    switch (action.type) {
        case ADMINPUBROLEGETDETAILS_LIST_REQUEST:
            return { loadingPubRoleGet: true }
        case ADMINPUBROLEGETDETAILS_LIST_SUCCESS:
            return {loadingPubRoleGet:false,pubRolesGet:action.payload};
        case ADMINPUBROLEGETDETAILS_LIST_FAIL:
            return {loadingPubRoleGet:false,errorPubRolesGet:action.payload}
        default:
            return state;
    }

}

function pubRolesPutReducer(state ={pubRolesPut:[]},action) {

    switch (action.type) {
        case ADMINPUBROLEPUTDETAILS_LIST_REQUEST:
            return { loadingPubRolePut: true }
        case ADMINPUBROLEPUTDETAILS_LIST_SUCCESS:
            return {loadingPubRolePut:false,pubRolesPut:action.payload};
        case ADMINPUBROLEPUTDETAILS_LIST_FAIL:
            return {loadingPubRolePut:false,errorPubRolesPut:action.payload}
        default:
            return state;
    }

}

function pubRolesSaveReducer(state ={pubRolesSave:[]},action) {

    switch (action.type) {
        case ADMINPUBROLESAVEDETAILS_LIST_REQUEST:
            return { loadingPubRoleSave: true }
        case ADMINPUBROLEPSAVEDETAILS_LIST_SUCCESS:
            return {loadingPubRoleSave:false,pubRolesSave:action.payload};
        case ADMINPUBROLESAVEDETAILS_LIST_FAIL:
            return {loadingPubRoleSave:false,error:action.payload}
        default:
            return state;
    }

}

function contSendMailReducer(state ={contSendMail:[]},action) {

    switch (action.type) {
        case ADMINCONTSENDMAILDETAILS_LIST_REQUEST:
            return { loadingContSendMail: true }
        case ADMINCONTSENDMAILDETAILS_LIST_SUCCESS:
            return {loadingContSendMail:false,contSendMail:action.payload};
        case ADMINCONTSENDMAILDETAILS_LIST_FAIL:
            return {loadingContSendMail:false,errorContSendMail:action.payload}
        default:
            return state;
    }

}


function contRolesGetReducer(state ={contRolesGet:[]},action) {

    switch (action.type) {
        case ADMINCONTROLEGETDETAILS_LIST_REQUEST:
            return { loadingContRoleGet: true }
        case ADMINCONTROLEGETDETAILS_LIST_SUCCESS:
            return {loadingContRoleGet:false,contRolesGet:action.payload};
        case ADMINCONTROLEGETDETAILS_LIST_FAIL:
            return {loadingContRoleGet:false,errorContRoleGet:action.payload}
        default:
            return state;
    }

}

function contRolesPutReducer(state ={contRolesPut:[]},action) {

    switch (action.type) {
        case ADMINCONTROLEPUTDETAILS_LIST_REQUEST:
            return { loadingContRolePut: true }
        case ADMINCONTROLEPUTDETAILS_LIST_SUCCESS:
            return {loadingContRolePut:false,contRolesPut:action.payload};
        case ADMINCONTROLEPUTDETAILS_LIST_FAIL:
            return {loadingContRolePut:false,error:action.payload}
        default:
            return state;
    }

}

function contRolesSaveReducer(state ={contRolesSave:[]},action) {

    switch (action.type) {
        case ADMINCONTROLESAVEDETAILS_LIST_REQUEST:
            return { loadingContRoleSave: true }
        case ADMINCONTROLEPSAVEDETAILS_LIST_SUCCESS:
            return {loadingContRoleSave:false,contRolesSave:action.payload};
        case ADMINCONTROLESAVEDETAILS_LIST_FAIL:
            return {loadingContRoleSave:false,error:action.payload}
        default:
            return state;
    }

}



export { pubRolesGetReducer ,
    pubRolesPutReducer,
    pubRolesSaveReducer,
    contRolesGetReducer,
    contRolesPutReducer,
    contRolesSaveReducer,
    pubSendMailReducer,
    contSendMailReducer,
    
}