import axios from "axios";
import Constants from "../../resource/Constants";
import { SUBJECT_ID_REQUEST, SUBJECT_ID_SUCCESS, SUBJECT_ID_FAIL } from "../constants/subjectIdConstants";



const subjectIdGet = (sub) => async (dispatch) => {
    try {

        console.log("subject at actions", sub)


        dispatch({ type: SUBJECT_ID_REQUEST })

        const data = sub
        dispatch({ type: SUBJECT_ID_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: SUBJECT_ID_FAIL, payload: error.message })

    }
}

export { subjectIdGet }
