import { SINGLEEVENTPUT_LIST_FAIL, SINGLEEVENTPUT_LIST_REQUEST, SINGLEEVENTPUT_LIST_SUCCESS, SINGLEVIDEOPUT_LIST_FAIL, SINGLEVIDEOPUT_LIST_REQUEST, SINGLEVIDEOPUT_LIST_SUCCESS, SUBSCRIBERSDETAILAPUT_LIST_FAIL, SUBSCRIBERSDETAILAPUT_LIST_REQUEST, SUBSCRIBERSDETAILAPUT_LIST_SUCCESS } from "../../constants/Trainer/AllEventsPut";

function singleEventPutReducer(state = { singleEventPut: [] }, action) {

    switch (action.type) {
        case SINGLEEVENTPUT_LIST_REQUEST:
            return { loadingSinglePut: true }
        case SINGLEEVENTPUT_LIST_SUCCESS:
            return { loadingSinglePut: false, singleEventPut: action.payload };
        case SINGLEEVENTPUT_LIST_FAIL:
            return { loadingSinglePut: false, errorsingleEventPut: action.payload }
        default:
            return state;
    }

}

function singleVideoPutReducer(state = { singleVideoPut: [] }, action) {

    switch (action.type) {
        case SINGLEVIDEOPUT_LIST_REQUEST:
            return { loadingSingleVidPut: true }
        case SINGLEVIDEOPUT_LIST_SUCCESS:
            return { loadingSingleVidPut: false, singleVideoPut: action.payload };
        case SINGLEVIDEOPUT_LIST_FAIL:
            return { loadingSingleVidPut: false, errorsingleVideoPut: action.payload }
        default:
            return state;
    }

}

function subscribersPutReducer(state = { subscrPut: [] }, action) {

    switch (action.type) {
        case SUBSCRIBERSDETAILAPUT_LIST_REQUEST:
            return { loadingSubscrPut: true }
        case SUBSCRIBERSDETAILAPUT_LIST_SUCCESS:
            return { loadingSubscrPut: false, subscrPut: action.payload };
        case SUBSCRIBERSDETAILAPUT_LIST_FAIL:
            return { loadingSubscrPut: false, errorSubscrPut: action.payload }
        default:
            return state;
    }

}

function trainTeachVidPutReducer(state = { subscrPut: [] }, action) {

    switch (action.type) {
        case SUBSCRIBERSDETAILAPUT_LIST_REQUEST:
            return { loadingSubscrPut: true }
        case SUBSCRIBERSDETAILAPUT_LIST_SUCCESS:
            return { loadingSubscrPut: false, subscrPut: action.payload };
        case SUBSCRIBERSDETAILAPUT_LIST_FAIL:
            return { loadingSubscrPut: false, errorSubscrPut: action.payload }
        default:
            return state;
    }

}



export {
    singleEventPutReducer,
    singleVideoPutReducer,
    subscribersPutReducer,
    trainTeachVidPutReducer,
    
}