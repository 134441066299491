import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { listDashBoardContSchools, listDashBoardPubSchools } from '../../../../redux/actions/DashBoardActions';
import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Alert, AlertTitle } from '@material-ui/lab';

const orders = [
  {
    id: uuid(),
    ref: 'CDD1049',
    amount: 30.5,
    customer: {
      name: 'Ekaterina Tankova'
    },
    createdAt: 1555016400000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1048',
    amount: 25.1,
    customer: {
      name: 'Cao Yu'
    },
    createdAt: 1555016400000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1047',
    amount: 10.99,
    customer: {
      name: 'Alexa Richardson'
    },
    createdAt: 1554930000000,
    status: 'refunded'
  },
  {
    id: uuid(),
    ref: 'CDD1046',
    amount: 96.43,
    customer: {
      name: 'Anje Keizer'
    },
    createdAt: 1554757200000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1045',
    amount: 32.54,
    customer: {
      name: 'Clarke Gillebert'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1044',
    amount: 16.76,
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  }
];

function LatestOrders(props) {

  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(listDashBoardContSchools())

    return (() => {
      //
    })
  }, [])


  const dashboardContSchoolsGet = useSelector(state => state.dashboardContSchools)
  const { loadingDashSchCont, DashboardContSchools, errorDashSchCont } = dashboardContSchoolsGet

  {
    loadingDashSchCont && dashboardContSchoolsGet ?
      console.log("data is loading")
      :
      console.log("publishers schools", DashboardContSchools)
  }


  return (

    <Card {...props}>
      <CardHeader title="Institutions" />
      <Divider />

      {dashboardContSchoolsGet && DashboardContSchools && DashboardContSchools.data && DashboardContSchools.data.List_of_Schools && DashboardContSchools.data.List_of_Schools.length ?

        <>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 800 }}>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      &nbsp;
              </TableCell>
                    <TableCell>
                      School
              </TableCell>
                    <TableCell sortDirection="desc">
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                        <TableSortLabel
                          active
                          direction="desc"
                        >
                          Date
                  </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      Status
              </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {DashboardContSchools.data.List_of_Schools.slice(0, 7).map((order, index) => {

                    let color = 'green'
                    if (order.status == 'PENDING')
                      color = 'orange'
                    else if (order.status == 'REJECTED')
                      color = 'red'
                    return (
                      <TableRow
                        hover
                        key={index}
                      >
                        <TableCell>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {order.institute_name}
                        </TableCell>
                        <TableCell>
                          {"22/05/2021"}
                        </TableCell>
                        <TableCell>
                          <Chip
                            // color={color}
                            label={order.status}
                            size="small"
                            style={{
                              backgroundColor: color,
                              color: 'white',
                              height: 16,
                              fontSize: 10
                            }}
                          />
                        </TableCell>
                      </TableRow>


                    )
                  })}

                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
            style={{
              padding: 10
            }}
          >
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
            >
              <Link to="/publisher-content/publishers" style={{ textDecoration: "none", }}>
                View all
              </Link>
            </Button>
          </Box>


        </>
        :
        <>
          <Alert severity="warning">
            <AlertTitle>No Institutions!</AlertTitle>
          There are no institutions yet using your content — <strong>connect with some publishers!</strong>
          </Alert>
        </>
      }
    </Card>
  )
}
export default LatestOrders;
