import { SUBJECT_ID_REQUEST, SUBJECT_ID_SUCCESS, SUBJECT_ID_FAIL } from "../constants/subjectIdConstants";

function SubjectIdReducer(state = { subject: {} }, action) {
    switch (action.type) {
        case SUBJECT_ID_REQUEST:
            return { ...state, loadingSub: true }
        case SUBJECT_ID_SUCCESS:
            return { ...state, loadingSub: false, subject: action.payload };
        case SUBJECT_ID_FAIL:
            return { ...state, loadingSub: false, errorMap: action.payload }
        default:
            return state;
    }
}

export { SubjectIdReducer }