import { FILTERED_LIST_REQUEST, FILTERED_LIST_SUCCESS, 
    FILTERED_LIST_FAIL } from "../constants/CurriculumMapConstants";

function filteredMapReducer(state = {filteredMap:[]}, action) {
    switch(action.type){
        case FILTERED_LIST_REQUEST:
            return {loadingCur:true}
        case FILTERED_LIST_SUCCESS:
            console.log(`called filtered list success with data ${JSON.stringify(action.payload)}`)
            return {loadingCur:false,filteredMap:action.payload};
        case FILTERED_LIST_FAIL:
            return {loadingCur:false,error:action.payload}
        
        default:
            return state;
    }
}

export {filteredMapReducer}
