
export const SINGLEEVENTDELETE_LIST_REQUEST = ' SINGLEEVENTDELETE_LIST_REQUEST'
export const SINGLEEVENTDELETE_LIST_SUCCESS = '  SINGLEEVENTDELETE_LIST_SUCCESS'
export const SINGLEEVENTDELETE_LIST_FAIL = ' SINGLEEVENTDELETE_LIST_FAIL'


export const SINGLEVIDEODELETE_LIST_REQUEST = ' SINGLEVIDEODELETE_LIST_REQUEST'
export const SINGLEVIDEODELETE_LIST_SUCCESS = '  SINGLEVIDEODELETE_LIST_SUCCESS'
export const SINGLEVIDEODELETE_LIST_FAIL = ' SINGLEVIDEODELETE_LIST_FAIL'


export const SUBSCRIBERSDETAILSDELETE_LIST_REQUEST = ' SUBSCRIBERSDETAILSDELETE_LIST_REQUEST'
export const SUBSCRIBERSDETAILSDELETE_LIST_SUCCESS = '  SUBSCRIBERSDETAILSDELETE_LIST_SUCCESS'
export const SUBSCRIBERSDETAILSDELETE_LIST_FAIL = ' SUBSCRIBERSDETAILSDELETE_LIST_FAIL'

export const TRAINTEACHVIDEOSDELETE_LIST_REQUEST = 'TRAINTEACHVIDEOSDELETE_LIST_REQUEST'
export const TRAINTEACHVIDEOSDELETE_LIST_SUCCESS = 'TRAINTEACHVIDEOSDELETE_LIST_SUCCESS'
export const TRAINTEACHVIDEOSDELETE_LIST_FAIL = 'TRAINTEACHVIDEOSDELETE_LIST_FAIL'
