import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { green } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';


function TasksProgress(props) {
  return (
    <Card
      sx={{ height: '100%' }}
      {...props}
      style={{
        minHeight: '100%'
      }}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
          style={{

            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              REVENUE PREV MONTH
          </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              ₹{props.count}
          </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: orange[600],
                height: 56,
                width: 56
              }}
            >
              <InsertChartIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2
          }}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            minWidth: '100%',
            width: '100%'
          }}
        >
          {/* { props && props.percent && props.percent.toString().slice(0, 1) == "-" ?
            <ArrowDownwardOutlinedIcon sx={{ color: green[900] }} />
            :
            <ArrowUpwardIcon sx={{ color: green[900] }} />
          } */}
          <Typography
            variant="body2"
            sx={{
              color: green[900],
              mr: 1
            }}
          >
            {props.percent}%
        </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
            style={{
              marginLeft: 5
            }}
          >
            Since last month
        </Typography>
        </Box>
      </CardContent>
    </Card>

  )
}

export default TasksProgress;
