
/**
 * quiz planner
 * drawer
 */

import React, { Component, useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import LessonPlanAPI from '../../../http/lessonplanquiz';
import Constants from '../../../resource/Constants';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import {
    Avatar,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    TextField,
} from "@material-ui/core";

const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
];

const useStyles3 = makeStyles({
    list: {
        width: 250,
        minWidth: 250
    },
    fullList: {
        width: 'auto',
    },
    fullWidth: {
        width: '100%',
        minWidth: '100%'
    }
});


const useStyles2 = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useStyles4 = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));

function ConfirmationDialogRaw(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">Phone Ringtone</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >
                    {options.map((option) => (
                        <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
}));

function ConfirmationDialog() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('Dione');

    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    return (
        <div className={classes.root}>
            <List component="div" role="list">
                <ListItem button divider disabled role="listitem">
                    <ListItemText primary="Interruptions" />
                </ListItem>
                <ListItem
                    button
                    divider
                    aria-haspopup="true"
                    aria-controls="ringtone-menu"
                    aria-label="phone ringtone"
                    onClick={handleClickListItem}
                    role="listitem"
                >
                    <ListItemText primary="Phone ringtone" secondary={value} />
                </ListItem>
                <ListItem button divider disabled role="listitem">
                    <ListItemText primary="Default notification ringtone" secondary="Tethys" />
                </ListItem>
                <ConfirmationDialogRaw
                    classes={{
                        paper: classes.paper,
                    }}
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                />
            </List>
        </div>
    );
}

function AlignItemsList(props) {
    const classes = useStyles4();
    const quiz = props.quiz;

    const onDeleteQuiz = (id) => {
        props.onDeleteQuiz(id);
    }

    return (
        <List className={classes.root}>
            {(quiz && quiz.length) ?
                quiz.map((qst, index) => {
                    let qType = 'Multiple Choice';
                    switch (qst.quiz_question_type_id) {
                        case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_CHOICE:
                            qType = 'Multiple Choice';
                            break;
                        case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_SELECT:
                            qType = 'Multiple Select';
                            break;
                        case Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE:
                            qType = 'True Or False';
                            break;
                        case Constants.Application.QUIZ.ID_QTYPE_TEXT_TYPE:
                            qType = 'Short Answer';
                            break;
                        default:
                            qType = 'None'
                    }

                    return (
                        <>
                            <ListItem alignItems="flex-start"
                            >
                                <ListItemAvatar>
                                    <Avatar alt={qType} src="/static/images/avatar/1.jpg" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={qType}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                                
                                            >
                                                {qst.question_text}
                                            </Typography>
                                            {` — Time ${qst.question_time}`}
                                        </React.Fragment>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <IconButton edge="end" aria-label="delete"

                                            onClick={() => { console.log('click question'); props.onOpenQuiz(qst.quiz_id, qst.question_id, qst, qst.quiz_question_type_id) }}
                                            >
                                            <EditIcon
                                                style={{
                                                    color: 'gray'
                                                }} />
                                        </IconButton>

                                        <IconButton edge="end" aria-label="delete"

                                            onClick={() => {
                                                onDeleteQuiz(qst.quiz_id)
                                            }}>
                                            <DeleteIcon
                                                style={{
                                                    color: 'red'
                                                }} />
                                        </IconButton>
                                    </div>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    )
                })
                :
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="No Quiz" src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                        primary="No Quiz Created Yet!"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    No questions created
                                </Typography>
                                {" — Start creating questions for quiz"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            }


            {/* dummy */}
            {/* 
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Summer BBQ"
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                to Scott, Alex, Jennifer
                </Typography>
                            {" — Wish I could come, but I'm out of town this…"}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Oui Oui"
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                Sandra Adams
                </Typography>
                            {' — Do you have Paris recommendations? Have you ever…'}
                        </React.Fragment>
                    }
                />
            </ListItem>
         */}
        </List>
    );
}

/* 
function getUnpubLessons() {

    // get your unpublished quiz
    const url = '<%= Constants.API_URL %>/quiz/get-unpub-quiz/<%= sLsn.getStaff_lesson_id() %>';

    $.ajax({
        url: url,
        data: 'sid=' + Math.random(),
        async: true,
        method: 'GET',
        success: function (response) {

            // success
            console.log('get unpublished lessons response ' + JSON.stringify(response));

            $('#qlist').html('loading..');
            let qHTML = '';
            let qId = 0;
            let type = 201;
            let typeStr = '';
            let qNum = 1;
            let lbl;
            let bglight = 'bg-light-success'
            if (response && response.length > 0) {

                //					qHTML += '<div class="list-group">';
                //					qHTML += '<div class="list list-hover">';
                qHTML += '<div class="card-body pt-0">';
                response.map(qz => {
                    type = qz.quiz_question_type_id;
                    switch (type) {
                        case parseInt('<%= Constants.ID_QTYPE_MULTIPLE_CHOICE %>'):
                            typeStr = 'Multiple choice';
                            lbl = 'label-danger';
                            bglight = 'bg-light-info';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_MULTIPLE_SELECT %>'):
                            typeStr = 'Multiple select';
                            lbl = 'label-success';
                            bglight = 'bg-light-warning';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_TRUE_FALSE %>'):
                            typeStr = 'True Or False';
                            lbl = 'label-info';
                            bglight = 'bg-light-danger';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_PICTURE_CHOICE %>'):
                            typeStr = 'Picture Choice';
                            lbl = 'label-warning';
                            bglight = 'bg-light-success';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_TEXT_TYPE %>'):
                            typeStr = 'Short Answer';
                            lbl = 'label-danger';
                            bglight = 'bg-light-info';
                            break;
                        case parseInt('<%= Constants.ID_QTYPE_MATCH_FLW %>'):
                            typeStr = 'Match The Following';
                            lbl = 'label-danger';
                            bglight = 'bg-light-info';
                            break;
                    }

                    if (((qNum % 2) == 0)) {

                    } else if (((qNum % 3) == 0)) {

                    } else if (((qNum % 1) == 0)) {

                    } else {

                    }

                    qHTML += '<div  onmouseover="onMouseHoverd(this)" onmouseout="onMouseOutd(this)" class="d-flex justify-content-between text-left align-items-center mb-9 ' + bglight + ' rounded p-5">' +

                        '<div class="symbol symbol-40 symbol-light mr-4">' +
                        '<span class="symbol-label bg-hover-white">' +
                        '<span class="label ' + lbl + '">' + qNum + '</span>' +
                        '</span>' +
                        '</div>' +

                        '<div class="d-flex flex-column flex-grow-1 mr-2">' +
                        '<div class="d-flex justify-content-start">' +
                        '<span class="text-muted font-weight-bold mr-5">' + (typeStr.toUpperCase()) + '</span>' +

                        '<span><i class="fa fa-clock-o" style="color: #808080bf;"></i>&nbsp;<small style="font-size:1em;">' + qz.question_time + '</small></span>' +
                        '</div>' +
                        '<a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">' + qz.question_text + '</a>' +

                        '</div>' +
                        '<div class="qst-close-btn" style="display: none">' +
                        //								      '<div class="dot" style="font-size: 1.5em; font-weight: bold; background: #CCCCCC; width: 30px; height: 30px; border-radius: 50%;">' + 
                        '<span onclick="onDelQuizClicked(\'' + qz.quiz_id + '\', this.parentNode.parentNode)" style="cursor: pointer">' +
                        '<i class="fa fa-times-circle fa-2x text-danger"></i>' +
                        '</span>' +
                        //								      '</div>' + 
                        '</div>' +
                        //'<span class="font-weight-bolder text-warning py-1 font-size-lg">&nbsp;</span>'+

                        '</div>'

                    ++qNum;
                });
                qHTML += '</div>';


                $('#qlist').html(qHTML);
            } else {
                let none = '<div class="p-4">' +
                    '<div class="alert alert-success mb-5 p-5" role="alert" id="noresults">' +
                    '<h4 class="alert-heading">No quiz created yet!</h4>' +
                    '<p>No quiz has been planned recently. Please use the button above to prepare your questions</p>' +
                    '<div class="border-bottom border-white opacity-20 mb-5"></div>' +
                    '<p class="mb-0">You can close this window after setting up the quiz questions.</p>' +
                    '</div>' +
                    '</div>';
                $('#qlist').html(none);
            }
            
        },
        error: function (error) {
            // error page
            console.log('get unpublished lessons error ' + JSON.stringify(error));
        }
    });

}


    function submitOpt() {	
        //debugger
        isinputCheck = false;
        quizQuestion = true;
        let dateStr;
        let today = new Date();
        dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
        console.log('datestr ' + dateStr);
        console.log('submitting multiple option quiz');
        if(Validate()) {
        	
            let timeroptMin = document.getElementById("timeroptm");
            let timeroptSec = document.getElementById("timeropts");
            let timerOpt = timeroptMin.value +":"+ timeroptSec.value;
            //let timerOpt = document.getElementById('timeropt');
            console.log('timer opt ' + timerOpt);
            console.log('timer opt value ' + timerOpt);
            let formData = {
                    "classroom_id": '<%= sLsn.getClassroom_id() %>',
                    "staff_id": '<%= sLsn.getStaff_id() %>',
                    "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
                    "subject_id": '<%= sLsn.getSubject_id() %>',
                    "chapter_id": '<%= sLsn.getChapter_id() %>',
                    "quiz_title": "abcd",
                    "scheduled_date": "2020-07-02",
                    "quiz_ended": 0,
                    "question_type": '<%= Constants.ID_QTYPE_MULTIPLE_CHOICE %>',
                    "question_text":$("#qstOpt").text(),
                    "question_time":'00:'+timerOpt, //'00:'+$(event.target.closest('div')).find('input').val(), // timerOpt.value,
                    "question_url": '',
                    "question_topic_text": "",
                    "question_pts": 1,
                    "options": [
                        {
                            "option_index": 0,
                            "option_text": $("#opt1").val(),
                            "option_url": '',
                            "option_correct": 1
                        },
                        {
                            "option_index": 1,
                            "option_text": $("#opt2").val(),
                            "option_url": '',
                            "option_correct": 0
                        },
                        {
                            "option_index": 2,
                            "option_text": $("#opt3").val(),
                            "option_url": '',
                            "option_correct": 0
                        },
                        {
                            "option_index": 3,
                            "option_text": $("#opt4").val(),
                            "option_url": '',
                            "option_correct": 0
                        }
                    ]
                }

            console.log('multiple choice form data ' + JSON.stringify(formData));

            $.ajax({
                type: 'post',
                url: '<%= Constants.API_URL %>/quiz/add-quiz',
                data: formData,
                success: function(data, textStatus, jqXHR) {
                    // The form was successfully submitted
//	                console.log('submitted successfully!');
                    swal("Your question has been submitted..");
                    console.log(data.insertId);
                    quesnId = data.insertId;
                    $('#quiz-div').hide();
                    $('#quiz-end').show();
                    //replace qbox
                    
                    // call unpublished
                    getUnpubLessons();
                    $drawerQst.toggleClass('quiz-drawer-open');
                    
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    // The submit action failed
                    alert('Failed to add quiz to database, try again.');
                }
            });
        }
        $('.customTextarea').text('');
    }
	
	

function submitChk() {
        console.log('submitting multiple select question')
        isinputCheck = true;
        quizQuestion = true;
        if(Validate()) {

            let timerMin = document.getElementById('timerchkm');
            let timerSec = document.getElementById('timerchks');
//			let timerChk = document.getElementById('timerchk');
//			console.log('timer check value ' + timerChk.value);

            let timerChk = timerMin.value + ":" + timerSec.value;
            let formData = {
                    "classroom_id": '<%= sLsn.getClassroom_id() %>',
                    "staff_id": '<%= sLsn.getStaff_id() %>',
                    "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
                    "subject_id": '<%= sLsn.getSubject_id() %>',
                    "chapter_id": '<%= sLsn.getChapter_id() %>',
                    "quiz_title": "abcd",
                    "scheduled_date": "2020-12-20",
                    "quiz_ended": 0,
                    "question_type": <%= Constants.ID_QTYPE_MULTIPLE_SELECT %>,
                    "question_text": $("#qstChk").text(),
                    //"question_time": '00:'+$(event.target.closest('div')).find('input').val(),//$("#timerchk").val(),
                    "question_time": '00:'+timerChk,
                    "question_url": '',
                    "question_topic_text": "",
                    "question_pts": 1,
                    "options": [
                        {
                            "option_index": 0,
                            "option_text": $("#chktxt1").val(),
                            "option_url": '',
                            "option_correct": $("#chk1").is(":checked")?1:0
                        },
                        {
                            "option_index": 1,
                            "option_text": $("#chktxt2").val(),
                            "option_url": '',
                            "option_correct": $("#chk2").is(":checked")?1:0
                        },
                        {
                            "option_index": 2,
                            "option_text": $("#chktxt3").val(),
                            "option_url": '',
                            "option_correct": $("#chk3").is(":checked")?1:0
                        },
                        {
                            "option_index": 3,
                            "option_text": $("#chktxt4").val(),
                            "option_url": '',
                            "option_correct": $("#chk4").is(":checked")?1:0
                        }
                    ]
                }
    	
            $.ajax({ 
                type: 'post',
                url: '<%= Constants.API_URL %>/quiz/add-quiz',
                data: formData,
                success: function(data, textStatus, jqXHR) {
                    // The form was successfully submitted
//	                console.log('submitted successfully!');
                    swal("Your question has been submitted..");
                    console.log('submitted successfully!');
                    console.log(data.insertId);
                    console.log(data);
                    quesnId = data.insertId;
                    $('#quiz-div').hide();
                    $('#quiz-end').show();
                    

                    // call unpublished
                    getUnpubLessons();
                    $drawerQst.toggleClass('quiz-drawer-open');
                    
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    // The submit action failed
                    alert('Failed to add quiz to database, try again.');
                }
            });
        }
        $('.customTextarea').text('');
    }
	
    function submitBool() {
        isinputCheck = false;
        quizQuestion = true;
              
console.log('submitting boolean question');
if (Validate()) {

    if (tfOp > -1) {
        let timerboolMin = document.getElementById("timerboolm");
        let timerboolSec = document.getElementById("timerbools");
        let timerBool = timerboolMin.value + ':' + timerboolSec.value;
        //let timerBool = document.getElementById('timerbool');
        console.log('boolean question time ' + timerBool);
        let formData = {
            "classroom_id": '<%= sLsn.getClassroom_id() %>',
            "staff_id": '<%= sLsn.getStaff_id() %>',
            "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
            "subject_id": '<%= sLsn.getSubject_id() %>',
            "chapter_id": '<%= sLsn.getChapter_id() %>',
            "quiz_title": "abcd",
            "scheduled_date": "2020-12-20",
            "quiz_ended": 0,
            "question_type": <%= Constants.ID_QTYPE_TRUE_FALSE %>,
            "question_text": $("#qstBool").text(),
            "question_time": '00:' + timerBool, //+$(event.target.closest('div')).find('input').val(),//$("#timerbool").val(),
            "question_url": $("#picurl6").val(),
            "question_topic_text": "",
            "question_pts": 1,
            "options": [
                {
                    "option_index": 0,
                    "option_text": (tfOp == 1) ? "True" : "False",//$("#toggle").is(":checked")?"True":"False",
                    "option_url": '',
                    "option_correct": (tfOp == 1) ? 1 : 0,//$("#toggle").is(":checked")?1:0
                }
                

            ]
        }

        console.log('true false form data ' + JSON.stringify(formData));

        $.ajax({
            type: 'POST',
            url: '<%= Constants.API_URL %>/quiz/add-quiz',
            data: formData,
            success: function (data, textStatus, jqXHR) {
                // The form was successfully submitted
                swal("Your question has been submitted..");
                console.log('submitted successfully!');
                console.log(data.insertId);
                console.log(data);
                quesnId = data.insertId;
                $('#quiz-div').hide();
                $('#quiz-end').show();

                // call unpublished
                getUnpubLessons();
                $drawerQst.toggleClass('quiz-drawer-open');

                // reset option
                tfOp = -1;

            },
            error: function (jqXHR, textStatus, errorThrown) {
                // The submit action failed
                swal('Failed to add quiz to database, try again.');
            }
        });
    } else {
        swal('Please select either True (or) False')
    }
}
$('.customTextarea').text('');
              
          }

function submitPOpt() {
    isinputCheck = false;
    quizQuestion = true;
    let dateStr;
    let today = new Date();

    dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
    console.log('datestr ' + dateStr);
    console.log('submitting picture option quiz');
    if (Validate()) {
        let timerpoptMin = document.getElementById('timerpoptm');
        let timerpoptSec = document.getElementById('timerpopts');
        let timerPopt = timerpoptMin.value + ":" + timerpoptSec.value;
        let formData = {
            "classroom_id": '<%= sLsn.getClassroom_id() %>',
            "staff_id": '<%= sLsn.getStaff_id() %>',
            "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
            "subject_id": '<%= sLsn.getSubject_id() %>',
            "chapter_id": '<%= sLsn.getChapter_id() %>',
            "quiz_title": "abcd",
            "scheduled_date": "2020-07-02",
            "quiz_ended": 0,
            "question_type": '<%= Constants.ID_QTYPE_PICTURE_CHOICE %>',
            "question_text": $("#qstPOpt").text(),
            "question_time": '00:' + timerPopt, //+$(event.target.closest('div')).find('input').val(), //$("#timerpopt").val(),
            "question_url": $("#picurl1").val(),
            "question_topic_text": "",
            "question_pts": 1,
            "options": [
                {
                    "option_index": 0,
                    "option_text": $("#popt1").val(),
                    "option_url": $("#picurl2").val(),
                    "option_correct": 1
                },
                {
                    "option_index": 1,
                    "option_text": $("#popt2").val(),
                    "option_url": $("#picurl3").val(),
                    "option_correct": 0
                },
                {
                    "option_index": 2,
                    "option_text": $("#popt3").val(),
                    "option_url": $("#picurl4").val(),
                    "option_correct": 0
                },
                {
                    "option_index": 3,
                    "option_text": $("#popt4").val(),
                    "option_url": $("#picurl5").val(),
                    "option_correct": 0
                },
            ]
        }

        $.ajax({
            type: 'post',
            url: '<%= Constants.API_URL %>/quiz/add-quiz',
            data: formData,
            success: function (data, textStatus, jqXHR) {
                // The form was successfully submitted
                //	                console.log('submitted successfully!');
                Swal.fire("Your question has been submitted..");
                console.log(data.insertId);
                quesnId = data.insertId;
                $('#quiz-div').hide();
                $('#quiz-end').show();
                //replace qbox

                // call unpublished
                getUnpubLessons();
                $drawerQst.toggleClass('quiz-drawer-open');

            },
            error: function (jqXHR, textStatus, errorThrown) {
                // The submit action failed
                Swal('Failed to save quiz, try again.');
            }
        });
    }
    $('.customTextarea').text('');
}

function submitText() {
    isinputCheck = false;
    quizQuestion = true;
    
    console.log('submitting text question');
    let timerTextM = document.getElementById('timertextm');
    let timerTextS = document.getElementById('timertexts');
    let timerText = timerTextM.value + ":" + timerTextS.value;
    if (Validate()) {

        let formData = {
            "classroom_id": '<%= sLsn.getClassroom_id() %>',
            "staff_id": '<%= sLsn.getStaff_id() %>',
            "staff_lesson_id": '<%= sLsn.getStaff_lesson_id() %>',
            "subject_id": '<%= sLsn.getSubject_id() %>',
            "chapter_id": '<%= sLsn.getChapter_id() %>',
            "quiz_title": "abcd",
            "scheduled_date": "2020-12-20",
            "quiz_ended": 0,
            "question_type": <%= Constants.ID_QTYPE_TEXT_TYPE %>,
            "question_text": $("#qstTxt").text(),
            "question_time": '00:' + timerText,//$(event.target.closest('div')).find('input').val(), //$("#timertxt").val(),
            "question_url": '',
            "question_topic_text": "",
            "question_pts": 1,
            "options": [
                {
                    "option_index": 0,
                    "option_text": $("#ansTxt").val(),
                    "option_url": "",
                    "option_correct": 1,//$("#toggle").is(":checked")?1:0
                }
                
            ]
        }

        console.log('form data to be sent ' + JSON.stringify(formData));

        $.ajax({
            type: 'POST',
            url: '<%= Constants.API_URL %>/quiz/add-quiz',
            data: formData,
            success: function (data, textStatus, jqXHR) {
                // The form was successfully submitted
                swal("Your question has been submitted..");
                console.log('submitted successfully!');
                console.log(data.insertId);
                console.log(data);
                quesnId = data.insertId;
                $('#quiz-div').hide();
                $('#quiz-end').show();

                // call unpublished
                getUnpubLessons();
                $drawerQst.toggleClass('quiz-drawer-open');

                // reset option
                tfOp = -1;

            },
            error: function (jqXHR, textStatus, errorThrown) {
                // The submit action failed
                swal('Failed to add quiz to database, try again.');
            }
        });

    }
    $('.customTextarea').text('');
}
*/

const RadioBox = (props) => {
    const classes = useStyles3()
    const { lsnPlanId, subId, quiz } = props;

    const [qst, setQst] = useState((quiz) ? quiz.question_text : '')
    const [crct, setCrct] = useState('')
    const [op1, setOp1] = useState('')
    const [op2, setOp2] = useState('')
    const [op3, setOp3] = useState('')
    const [op4, setOp4] = useState('')

    const [timeMins, setTimeMins] = useState(0)
    const [timeSecs, setTimeSecs] = useState(15)
    
    const onSubmitOpt = async () => {
        // get all values and call the API
        let dateStr;
        let today = new Date();
        dateStr = today.getYear() + '-' + today.getMonth() + '-' + today.getDate();
        console.log('datestr ' + dateStr);
        console.log('submitting multiple option quiz');

        let timerOpt = timeMins + ":" + timeSecs;
        //let timerOpt = document.getElementById('timeropt');
        console.log('timer opt ' + timerOpt);
        console.log('timer opt value ' + timerOpt);
        let formData = {
            classroom_id: 0,
            staff_id: 0,
            staff_lesson_id: lsnPlanId,
            subject_id: subId,
            chapter_id: 0,
            quiz_title: "abcd",
            scheduled_date: "2020-07-02",
            quiz_ended: 0,
            question_type: Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_CHOICE,
            question_text: qst,
            question_time: '00:' + timerOpt, //'00:'+$(event.target.closest('div')).find('input').val(), // timerOpt.value,
            question_url: '',
            question_topic_text: "",
            question_pts: 1,
            options: [
                {
                    option_index: 0,
                    option_text: crct,
                    option_url: '',
                    option_correct: 1
                },
                {
                    option_index: 1,
                    option_text: op1,
                    option_url: '',
                    option_correct: 0
                },
                {
                    option_index: 2,
                    option_text: op2,
                    option_url: '',
                    option_correct: 0
                },
                {
                    option_index: 3,
                    option_text: op3,
                    option_url: '',
                    option_correct: 0
                }
            ]
        }

        console.log('multiple choice form data ' + JSON.stringify(formData));
        const resp = await LessonPlanAPI.saveQuiz(formData);
        // close create
        props.toggleCreate()
    }

    let btnDisabled = false
    if (!qst
        || !crct
        || !op1
        || !op2
        || !op3
        || (!timeMins && !timeSecs))
        btnDisabled = true

    return (
        <div>
            <Typography
                variant='h5'>
                Multiple Choice
            </Typography>
            <p>
                <Typography
                    variant='caption'>Please enter the correct answer in the Second
                    block, in students view, the options will be shuffled for every
                    one</Typography>
            </p>

            <div
                className={classes.fullWidth}
                style={{
                    marginTop: 20
                }}>

                {/* question */}
                <TextField id="filled-basic" label="Question" variant="filled"
                    placeholder="Question"
                    defaultValue={qst}
                    onChange={(event) => {
                        const val = event.target.value
                        console.log(`changed op question to ${val}`)
                        setQst(val)
                    }}
                    className={classes.fullWidth} />
                <div style={{
                    marginTop: 10
                }}></div>

                {/* correct */}
                <TextField id="filled-basic" label="Correct" variant="filled"
                    placeholder="Enter correct answer"
                    onChange={(event) => {
                        const val = event.target.value
                        console.log(`changed crct ans to ${val}`)
                        setCrct(val)
                    }}
                    className={classes.fullWidth} />


                {/* wrong */}
                <TextField id="filled-basic" label="Option" variant="filled"
                    placeholder="Enter wrong answer"
                    onChange={(event) => {
                        const val = event.target.value
                        console.log(`changed op1 ans to ${val}`)
                        setOp1(val)
                    }}
                    className={classes.fullWidth} />

                {/* wrong */}
                <TextField id="filled-basic" label="Option" variant="filled"
                    placeholder="Enter wrong answer"
                    onChange={(event) => {
                        const val = event.target.value
                        console.log(`changed op2 ans to ${val}`)
                        setOp2(val)
                    }}
                    className={classes.fullWidth} />

                {/* wrong */}
                <TextField id="filled-basic" label="Option" variant="filled"
                    placeholder="Enter wrong answer"
                    onChange={(event) => {
                        const val = event.target.value
                        console.log(`changed op3 ans to ${val}`)
                        setOp3(val)
                    }}
                    className={classes.fullWidth} />

                {/* TIMER */}
                <div style={{
                    marginTop: 20,
                    marginBottom: 20
                }}>
                    <div
                        style={{
                            display: 'flex',
                            alignSelf: 'center',
                            marginBottom: 20
                        }}>
                        Set Timer
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}>
                        <TextField id="outlined-basic" label="Mins" variant="outlined"
                            type="number"
                            defaultValue={timeMins}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    max: 2, min: 0,

                                }
                            }}
                            style={{
                                width: 100
                            }}
                            onChange={(event) => {
                                const val = event.target.value
                                console.log(`changed op mins to ${val}`)
                                setTimeMins(val)
                            }}

                            placeholder="Mins" />
                        <TextField id="outlined-basic" label="Secs" variant="outlined"
                            type="number"
                            defaultValue={timeSecs}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    max: 60, min: 0,

                                }
                            }}
                            style={{
                                width: 100
                            }}
                            onChange={(event) => {
                                const val = event.target.value
                                console.log(`changed op secs to ${val}`)
                                setTimeSecs(val)
                            }}
                            placeholder="Secs" />
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',

                    }}>
                    <Button variant="contained" color="primary"
                        style={{
                            alignSelf: 'flex-end'
                        }}
                        onClick={onSubmitOpt}
                        disabled={btnDisabled}
                    >
                        Save
                    </Button>

                </div>

            </div>
        </div>
    )
}

const RadioBox2 = (props) => {
    return (

        <>
            <div class="question-box" id="radio-box" style="display: none">
                <div class="radio-box-2 question-box-3">
                    <div class="d-flex justify-content-start"></div>
                    <input type="hidden" name="boolval[]" value="false" />


                    <div class="card card-custom" style="padding: 0; border: 0">

                        <div class="card-body" style="padding: 0">
                            <h5>
                                Multiple Choice
                                <p>
                                    <small>Please enter the correct answer in the Second
                                        block, in students view, the options will be shuffled for every
                                        one</small>
                                </p>
                            </h5>
                            <form class="quizForm">
                                <div class="questionSection">
                                    <div class="d-flex mb-2 questionWithImg mt-4">
                                        <span class="customTextarea mcqTextarea sel-q quizQuestionInput"
                                            role="textbox" name="qst" id="qstOpt" contenteditable></span>

                                    </div>

                                </div>

                                <input type="hidden" name="type"
                                    value="2" />

                                <ul class="list-group radiooption mt-2" id="radiooption">
                                    <li class="list-group-item multipleList">
                                        <div class="d-flex">
                                            <input class="form-control radio-box multipleOptions"
                                                type="text" name="op[]" id="opt1" placeholder="Correct answer"
                                                style="border: 0;" />

                                        </div>
                                    </li>
                                    <li class="list-group-item multipleList ">
                                        <div class="d-flex">
                                            <input class="form-control radio-box multipleOptions"
                                                type="text" name="op[]" id="opt2" placeholder="Wrong answer"
                                                style="border: 0;" />

                                        </div>
                                    </li>
                                    <li class="list-group-item multipleList ">
                                        <div class="d-flex">
                                            <input class="form-control radio-box multipleOptions"
                                                type="text" name="op[]" id="opt3" placeholder="Wrong answer"
                                                style="border: 0;" />

                                        </div>
                                    </li>
                                    <li class="list-group-item multipleList ">
                                        <div class="d-flex">
                                            <input class="form-control radio-box multipleOptions"
                                                type="text" name="op[]" id="opt4" placeholder="Wrong answer"
                                                style="border: 0;" />

                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex">
                                <div class="form-group setTimeSection mt-5">
                                    <div class="">
                                        <label>  Set Timer
                                        </label>
                                    </div>
                                    <div class="setTime">
                                        <input id="timeroptm" type="number"
                                            class="maxInputNumber input-text qty text" pattern="[0-9]"
                                            onkeyup="maxInputNumber(this)" name="quantity" min="0" max="3"
                                            placeholder="1" value="01" required />: <input
                                            id="timeropts" type="number"
                                            class="maxInputNumber input-text qty text" pattern="[0-9]"
                                            onkeyup="maxInputNumber(this)" name="quantity" min="0" max="59"
                                            placeholder="00" value="00" required />

                                    </div>

                                    <button type="button" class="btn btn-success saveBtn"
                                        data-dismiss="modal" onclick="submitOpt()">SAVE</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const CheckBox = (props) => {
    const classes = useStyles3()

    const { lsnPlanId, subId, quiz } = props;
    const [qst, setQst] = useState((quiz) ? quiz.question_text : '')
    const [op1Chk, setOp1Chk] = useState(false)
    const [op1, setOp1] = useState('')
    const [op2Chk, setOp2Chk] = useState(false)
    const [op2, setOp2] = useState('')
    const [op3Chk, setOp3Chk] = useState(false)
    const [op3, setOp3] = useState('')
    const [op4Chk, setOp4Chk] = useState(false)
    const [op4, setOp4] = useState('')

    const [timeMins, setTimeMins] = useState(0)
    const [timeSecs, setTimeSecs] = useState(15)

    const onSubmitChk = async () => {

        let timerChk = timeMins + ":" + timeSecs;
        let formData = {
            classroom_id: 0,
            staff_id: 0,
            staff_lesson_id: lsnPlanId,
            subject_id: subId,
            chapter_id: 0,
            quiz_title: "abcd",
            scheduled_date: "2020-12-20",
            quiz_ended: 0,
            question_type: Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_SELECT,
            question_text: qst,
            //"question_time": '00:'+$(event.target.closest('div')).find('input').val(),//$("#timerchk").val(),
            question_time: '00:' + timerChk,
            question_url: '',
            question_topic_text: "",
            question_pts: 1,
            options: [
                {
                    option_index: 0,
                    option_text: op1,
                    option_url: '',
                    option_correct: op1Chk ? 1 : 0
                },
                {
                    option_index: 1,
                    option_text: op2,
                    option_url: '',
                    option_correct: op2Chk ? 1 : 0
                },
                {
                    option_index: 2,
                    option_text: op3,
                    option_url: '',
                    option_correct: op3Chk ? 1 : 0
                },
                {
                    option_index: 3,
                    option_text: op4,
                    option_url: '',
                    option_correct: op4Chk ? 1 : 0
                }
            ]
        }

        let resp;
        
        if(!quiz)
            resp = await LessonPlanAPI.saveQuiz(formData);
        else
            ;
        // close create
        props.toggleCreate()
    }

    let btnDisabled = false

    console.log(`qst ${qst}`)
    console.log(`op1 ${op1}`)
    console.log(`op2 ${op2}`)
    console.log(`op3 ${op3}`)
    console.log(`op4 ${op4}`)
    console.log(`timeMins ${timeMins}`)
    console.log(`timeSecs ${timeSecs}`)

    if (!qst
        || (!op1 && !op2 && !op3 && !op4)
        || (!timeMins && !timeSecs))
        btnDisabled = true

    // TEMPORARY
    if(quiz)
        btnDisabled = true

    return (
        <div>
            <Typography
                variant='h5'>
                Multiple Select
            </Typography>
            <p>
                <Typography
                    variant='caption'>Please enter all answers in the Second block and
                    check which are correct. In students view, the options will be
                    shuffled for every one</Typography>
            </p>

            <div
                className={classes.fullWidth}
                style={{
                    marginTop: 20
                }}>

                {/* question */}
                <TextField id="filled-basic" label="Question" variant="filled"
                    placeholder="Question"
                    defaultValue={qst}
                    onChange={(event) => {
                        const { name, value } = event.target;

                        setQst(value)
                    }}
                    className={classes.fullWidth} />
                <div style={{
                    marginTop: 10
                }}></div>

                {/* answers / choices */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Checkbox
                        checked={op1Chk}
                        // onChange={handleChange}
                        onChange={(event) => {
                            const { name, checked } = event.target
                            console.log(`checkbox op1 change state ${checked}`)

                            setOp1Chk(checked)
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        style={{
                            width: '10%'
                        }}
                    />
                    <TextField id="filled-basic" label="Answer" variant="filled"
                        placeholder="Enter answer"
                        defaultValue={op1}
                        onChange={(event) => {
                            const { name, value } = event.target
                            console.log(`checkbox op1 ${value}`)

                            setOp1(value)
                        }}
                        style={{
                            width: '65%'
                        }}
                    />

                    {op1Chk ?
                        <Chip
                            avatar={<Avatar><CheckIcon /></Avatar>}
                            label="Correct"
                            clickable
                            color="primary"
                            // onDelete={handleDelete}
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                            style={{
                                width: '20%'
                            }}
                        />

                        :

                        <Chip
                            avatar={<Avatar><CloseIcon /></Avatar>}
                            label="Wrong"
                            clickable
                            color="secondary"
                            // onDelete={handleDelete}
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                            style={{
                                width: '20%'
                            }}
                        />

                    }

                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Checkbox
                        checked={op2Chk}
                        // onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        style={{
                            width: '10%'
                        }}
                        onChange={(event) => {
                            const { name, checked } = event.target
                            console.log(`checkbox op2 change state ${checked}`)

                            setOp2Chk(checked)
                        }}
                    />
                    <TextField id="filled-basic" label="Answer" variant="filled"
                        placeholder="Enter answer"
                        defaultValue={op2}
                        onChange={(event) => {
                            const { name, value } = event.target
                            console.log(`checkbox op2 ${value}`)

                            setOp2(value)
                        }}
                        style={{
                            width: '65%'
                        }}
                    />

                    {op2Chk ?
                        <Chip
                            avatar={<Avatar><CheckIcon /></Avatar>}
                            label="Correct"
                            clickable
                            color="primary"
                            // onDelete={handleDelete}
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                            style={{
                                width: '20%'
                            }}
                        />

                        :

                        <Chip
                            avatar={<Avatar><CloseIcon /></Avatar>}
                            label="Wrong"
                            clickable
                            color="secondary"
                            // onDelete={handleDelete}
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                            style={{
                                width: '20%'
                            }}
                        />

                    }

                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Checkbox
                        checked={op3Chk}
                        // onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onChange={(event) => {
                            const { name, checked } = event.target
                            console.log(`checkbox op3 change state ${checked}`)

                            setOp3Chk(checked)
                        }}
                        style={{
                            width: '10%'
                        }}
                    />
                    <TextField id="filled-basic" label="Answer" variant="filled"
                        placeholder="Enter answer"
                        defaultValue={op3}
                        onChange={(event) => {
                            const { name, value } = event.target
                            console.log(`checkbox op3 ${value}`)

                            setOp3(value)
                        }}
                        style={{
                            width: '65%'
                        }}
                    />

                    {op3Chk ?
                        <Chip
                            avatar={<Avatar><CheckIcon /></Avatar>}
                            label="Correct"
                            clickable
                            color="primary"
                            // onDelete={handleDelete}
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                            style={{
                                width: '20%'
                            }}
                        />
                        :
                        <Chip
                            avatar={<Avatar><CloseIcon /></Avatar>}
                            label="Wrong"
                            clickable
                            color="secondary"
                            // onDelete={handleDelete}
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                            style={{
                                width: '20%'
                            }}
                        />

                    }

                </div>


                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Checkbox
                        checked={op4Chk}
                        // onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onChange={(event) => {
                            const { name, checked } = event.target
                            console.log(`checkbox op4 change state ${checked}`)

                            setOp4Chk(checked)
                        }}
                        style={{
                            width: '10%'
                        }}
                    />
                    <TextField id="filled-basic" label="Answer" variant="filled"
                        placeholder="Enter answer"
                        defaultValue={op4}
                        onChange={(event) => {
                            const { name, value } = event.target
                            console.log(`checkbox op4 ${value}`)

                            setOp4(value)
                        }}
                        style={{
                            width: '65%'
                        }}
                    />

                    {op4Chk ?
                        <Chip
                            avatar={<Avatar><CheckIcon /></Avatar>}
                            label="Correct"
                            clickable
                            color="primary"
                            // onDelete={handleDelete}
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                            style={{
                                width: '20%'
                            }}
                        />
                        :
                        <Chip
                            avatar={<Avatar><CloseIcon /></Avatar>}
                            label="Wrong"
                            clickable
                            color="secondary"
                            // onDelete={handleDelete}
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                            style={{
                                width: '20%'
                            }}
                        />

                    }

                </div>

                {/* save button */}

                <div style={{
                    marginTop: 20,
                    marginBottom: 20
                }}>
                    <div
                        style={{
                            display: 'flex',
                            alignSelf: 'center',
                            marginBottom: 20
                        }}>
                        Set Timer
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}>
                        <TextField id="outlined-basic" label="Mins" variant="outlined"
                            type="number"
                            defaultValue={timeMins}
                            onChange={(event) => {
                                const { name, value } = event.target
                                console.log(`checkbox mins ${value}`)

                                setTimeMins(value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    max: 2, min: 0,

                                }
                            }}
                            style={{
                                width: 100
                            }}
                            placeholder="Mins" />
                        <TextField id="outlined-basic" label="Secs" variant="outlined"
                            type="number"
                            defaultValue={timeSecs}
                            onChange={(event) => {
                                const { name, value } = event.target
                                console.log(`checkbox secs ${value}`)

                                setTimeSecs(value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    max: 60, min: 0,

                                }
                            }}
                            style={{
                                width: 100
                            }}
                            placeholder="Secs" />
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',

                    }}>
                    <Button variant="contained" color="primary"
                        disabled={btnDisabled}
                        onClick={onSubmitChk}
                        style={{
                            alignSelf: 'flex-end'
                        }}>
                        Save
                    </Button>
                </div>

            </div>
        </div>
    )
}

const CheckBox2 = (props) => {

    return (
        <div class="question-box" id="check-box">
            <div class="check-box-2 question-box-3">
                <div class="d-flex justify-content-start"></div>
                <input type="hidden" name="boolval[]" value="false" />


                <div class="card card-custom" style="padding: 0; border: 0">

                    <div class="card-body" style="padding: 0">
                        <h5>
                            Multiple Select
                            <p>
                                <small>Please enter all answers in the Second block and
                                    check which are correct. In students view, the options will be
                                    shuffled for every one</small>
                            </p>
                        </h5>
                        <form class="quizForm p-4">
                            <span class="customTextarea msTextarea sel-q quizQuestionInput"
                                role="textbox" name="qst" id="qstChk" contenteditable></span>

                            <input type="hidden" name="type"
                                value="1" /> <br />


                            <ul class="list-group checkoption" id="checkoption">

                                <li class="list-group-item removeBorder">

                                    <div class="">

                                        <div
                                            class="d-flex text-left align-items-center flex-grow-1 inputChecBoxSection">

                                            <label
                                                class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                                                <input class="optCheckbox" onclick="inputSelChecked(this)"
                                                    type="checkbox" value="0" id="chk1" /> <span></span>
                                            </label>

                                            <div
                                                class="d-flex flex-wrap align-items-center justify-content-between w-100">

                                                <div class="d-flex flex-column align-items-center py-2 w-75">

                                                    <input class="form-control check-box" name="check[]"
                                                        id="chktxt1" type="text" placeholder="Write Answer"
                                                        style="border: 0; border-bottom: 1px #DCDCDC solid;" />
                                                </div>

                                                <span
                                                    class="label label-lg label-light-primary label-inline font-weight-bold py-4 isCheckedCorrect"
                                                    id="mul1" style="display: none">Correct</span>

                                            </div>

                                        </div>

                                    </div>
                                </li>
                                <li class="list-group-item removeBorder">

                                    <div class="">

                                        <div
                                            class="d-flex text-left align-items-center flex-grow-1 inputChecBoxSection">

                                            <label
                                                class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                                                <input class="optCheckbox" onclick="inputSelChecked(this)"
                                                    type="checkbox" value="1" id="chk2" /> <span></span>
                                            </label>

                                            <div
                                                class="d-flex flex-wrap align-items-center justify-content-between w-100">

                                                <div class="d-flex flex-column align-items-center py-2 w-75">

                                                    <input class="form-control check-box" name="check[]"
                                                        id="chktxt2" type="text" placeholder="Write Answer"
                                                        style="border: 0; border-bottom: 1px #DCDCDC solid;" />

                                                </div>

                                                <span
                                                    class="label label-lg label-light-primary label-inline font-weight-bold py-4 isCheckedCorrect"
                                                    id="mul2" style="display: none">Correct</span>

                                            </div>

                                        </div>

                                    </div>
                                </li>
                                <li class="list-group-item removeBorder">

                                    <div class="">

                                        <div
                                            class="d-flex text-left align-items-center flex-grow-1 inputChecBoxSection">

                                            <label
                                                class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                                                <input class="optCheckbox" onclick="inputSelChecked(this)"
                                                    type="checkbox" value="2" id="chk3" /> <span></span>
                                            </label>

                                            <div
                                                class="d-flex flex-wrap align-items-center justify-content-between w-100">

                                                <div class="d-flex flex-column align-items-center py-2 w-75">

                                                    <input class="form-control check-box" name="check[]"
                                                        id="chktxt3" type="text" placeholder="Write Answer"
                                                        style="border: 0; border-bottom: 1px #DCDCDC solid;" />

                                                </div>

                                                <span
                                                    class="label label-lg label-light-primary label-inline font-weight-bold py-4 isCheckedCorrect"
                                                    id="mul3" style="display: none">Correct</span>

                                            </div>

                                        </div>

                                    </div>
                                </li>
                                <li class="list-group-item removeBorder">

                                    <div class="">

                                        <div
                                            class="d-flex text-left align-items-center flex-grow-1 inputChecBoxSection">

                                            <label
                                                class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                                                <input class="optCheckbox" onclick="inputSelChecked(this)"
                                                    type="checkbox" value="3" id="chk4" /> <span></span>
                                            </label>

                                            <div
                                                class="d-flex flex-wrap align-items-center justify-content-between w-100">

                                                <div class="d-flex flex-column align-items-center py-2 w-75">

                                                    <input class="form-control check-box" name="check[]"
                                                        id="chktxt4" type="text" placeholder="Write Answer"
                                                        style="border: 0; border-bottom: 1px #DCDCDC solid;" />

                                                </div>

                                                <span
                                                    class="label label-lg label-light-primary label-inline font-weight-bold py-4 isCheckedCorrect"
                                                    id="mul4" style="display: none">Correct</span>

                                            </div>

                                        </div>

                                    </div>
                                </li>
                            </ul>


                        </form>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex">
                            <div class="form-group setTimeSection">
                                <div class="">
                                    <label>  Set Timer
                                    </label>
                                </div>
                                <div class="setTime">
                                    <input id="timerchkm" type="number"
                                        class="maxInputNumber input-text qty text" pattern="[0-9]"
                                        onkeyup="maxInputNumber(this)" name="quantity" min="0" max="2"
                                        placeholder="00" value="01" required />: <input
                                        id="timerchks" type="number"
                                        class="maxInputNumber input-text qty text" pattern="[0-9]"
                                        onkeyup="maxInputNumber(this)" name="quantity" min="0" max="59"
                                        placeholder="00" value="00" required />
                                </div>

                            </div>
                        </div>

                        <button type="button" class="btn btn-success saveBtn"
                            data-dismiss="modal" onclick="submitChk()">SAVE</button>
                    </div>

                </div>

            </div>

        </div>
    )
}

const TrueFalseBox = (props) => {
    const classes = useStyles3()
    const { lsnPlanId, subId, quiz } = props;

    const [qst, setQst] = useState((quiz) ? quiz.question_text : '')
    const [isTrue, setIsTrue] = useState(null)

    const [timeMins, setTimeMins] = useState(0)
    const [timeSecs, setTimeSecs] = useState(15)

    const handleTrueChanged = (val) => {
        setIsTrue(val)
    }

    const onSubmitTF = async () => {

        let timerBool = timeMins + ':' + timeSecs;
        //let timerBool = document.getElementById('timerbool');
        console.log('boolean question time ' + timerBool);
        let formData = {
            classroom_id: 0,
            staff_id: 0,
            staff_lesson_id: lsnPlanId,
            subject_id: subId,
            chapter_id: 0,
            quiz_title: "abcd",
            scheduled_date: "2020-12-20",
            quiz_ended: 0,
            question_type: Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE,
            question_text: qst,
            question_time: '00:' + timerBool, //+$(event.target.closest('div')).find('input').val(),//$("#timerbool").val(),
            question_url: '',
            question_topic_text: "",
            question_pts: 1,
            options: [
                {
                    option_index: 0,
                    option_text: (isTrue == true) ? "True" : "False",//$("#toggle").is(":checked")?"True":"False",
                    option_url: '',
                    option_correct: (isTrue == true) ? 1 : 0,//$("#toggle").is(":checked")?1:0
                }

            ]
        }

        // submit quiz..
        const resp = await LessonPlanAPI.saveQuiz(formData)
        // close create
        props.toggleCreate()
    }

    console.log(`true false value now is ${isTrue}`)

    let btnDisabled = false
    if (!qst
        || isTrue == null

        || (!timeMins
            && !timeSecs))
        btnDisabled = true

    return (
        <div>
            <Typography
                variant='h5'>
                True or False
            </Typography>
            <p>
                <Typography
                    variant='caption'>Please select the following field, true or false.</Typography>
            </p>

            <div
                className={classes.fullWidth}
                style={{
                    marginTop: 20
                }}>

                {/* question */}
                <TextField id="filled-basic" label="Question" variant="filled"
                    placeholder="Question"
                    defaultValue={qst}
                    onChange={(event) => {
                        const { name, value } = event.target;

                        setQst(value)
                    }}
                    className={classes.fullWidth} />
                <div style={{
                    marginTop: 10
                }}></div>


                {/* options */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>

                    <Chip
                        avatar={<Avatar><CheckIcon /></Avatar>}
                        label="True"
                        clickable
                        color="primary"
                        // onDelete={handleDelete}
                        deleteIcon={<DoneIcon />}
                        variant={(isTrue) ? "default" : "outlined"}
                        onClick={() => {

                            console.log(`true false was ${isTrue}`)

                            // not true or false
                            if (!isTrue)
                                handleTrueChanged(true)
                            else
                                handleTrueChanged(null)

                        }}
                    />
                    <div style={{
                        marginLeft: 10
                    }}></div>
                    <Chip
                        avatar={<Avatar><CloseIcon /></Avatar>}
                        label="False"
                        clickable
                        color="secondary"
                        // onDelete={handleDelete}
                        deleteIcon={<DoneIcon />}
                        variant={(isTrue == false) ? "default" : "outlined"}
                        onClick={() => {

                            console.log(`true false was ${isTrue}`)

                            // already clicked true
                            if (isTrue || isTrue == null)
                                handleTrueChanged(false)
                            else if (isTrue == false)
                                handleTrueChanged(null)
                        }}
                    />
                </div>


                {/* save button */}

                <div style={{
                    marginTop: 20,
                    marginBottom: 20
                }}>
                    <div
                        style={{
                            display: 'flex',
                            alignSelf: 'center',
                            marginBottom: 20
                        }}>
                        Set Timer
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}>
                        <TextField id="outlined-basic" label="Mins" variant="outlined"
                            type="number"
                            defaultValue={timeMins}
                            onChange={(event) => {
                                const { name, value } = event.target

                                setTimeMins(value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    max: 2, min: 0,

                                }
                            }}
                            style={{
                                width: 100
                            }}
                            placeholder="Mins" />
                        <TextField id="outlined-basic" label="Secs" variant="outlined"
                            type="number"
                            defaultValue={timeSecs}
                            onChange={(event) => {
                                const { name, value } = event.target

                                setTimeSecs(value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    max: 60, min: 0,

                                }
                            }}
                            style={{
                                width: 100
                            }}
                            placeholder="Secs" />
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',

                    }}>
                    <Button variant="contained" color="primary"
                        disabled={btnDisabled}
                        onClick={onSubmitTF}
                        style={{
                            alignSelf: 'flex-end'
                        }}>
                        Save
                    </Button>
                </div>

            </div>
        </div>
    )
}

const TrueFalseBox2 = (props) => {

    return (
        <div class="question-box" id="true-false-box" style="display: none">
            <div class="truefalse-box-2 question-box-3">
                <div class="d-flex justify-content-start"></div>


                <div class="card card-custom" style="padding: 0; border: 0">

                    <div class="card-body" style="padding: 0;">
                        <form class="quizForm">
                            <div class="trueOrFalseParent">
                                <h5>
                                    True or False
                                    <p>
                                        <small>Please select the following field, true or false.
                                        </small>
                                    </p>
                                </h5>

                                <div class="d-flex tfTextareaabc">
                                    <span class="customTextarea tfTextarea sel-q quizQuestionInput"
                                        role="textbox" name="qst" id="qstBool" contenteditable></span>

                                    <input type="hidden" name="type"
                                        value="3" />
                                    <div class="tfAddImg" id="selectImg_6">

                                    </div>

                                </div>
                                <br />
                                <div class="trueOrFalse-box appendImg_6 mb-5" data="6"
                                    id="pic-box">
                                    <div class="row picoption" id="picoption">
                                        <div class="col-lg-12 ">
                                            <a href="javascript:void(0)" onclick="loadPic(6)"
                                                class="loadpic btn btn-light-success" style="float: left;">
                                                <img src="img/no_image.png" id="photo6"
                                                    class="w-100 fas fa-image" />
                                            </a> <input class="picfile picIsChoice" type="file"
                                                accept="image/x-png,image/gif,image/jpeg"
                                                name="picfile[]" id="picfile6" style="display: none;" /> <input
                                                class="picIsChoice" type="hidden" name="picurl[]"
                                                class="picurl" id="picurl6" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" d-flex justify-content-start align-items-center">
                                <div class=" p-2 d-flex align-items-center mr-1"
                                    style="border: 1px solid gray; border-radius: 0.2em;"
                                    onclick="onQuizTFClicked(this, false)">
                                    <i class="fa fa-times-circle fa-2x text-secondary icontf"
                                        id="qicond"></i>&nbsp;&nbsp;&nbsp;&nbsp;

                                    <span class="text-secondary texttf">FALSE</span>

                                </div>

                                <div class=" p-2 d-flex align-items-center"
                                    style="border: 1px solid gray; border-radius: 0.2em;"
                                    onclick="onQuizTFClicked(this, true)">
                                    <i class="fa fa-check-circle fa-2x text-secondary icontf"
                                        id="qicons"></i>&nbsp;&nbsp;&nbsp;&nbsp;

                                    <span class="text-secondary texttf">TRUE</span>
                                </div>

                            </div>

                            <input type="hidden" class="boolqcount" name="boolqcount[]"
                                value="1" />

                        </form>
                    </div>
                    <div class="card-footer mt-5">
                        <div class="d-flex">
                            <div class="form-group setTimeSection mt-5">
                                <div class="">
                                    <label>  Set Timer
                                    </label>
                                </div>
                                <div class="setTime">
                                    <input id="timerboolm" type="number"
                                        class="maxInputNumber input-text qty text" pattern="[0-9]"
                                        onkeyup="maxInputNumber(this)" name="quantity" min="0" max="3"
                                        placeholder="1" value="01" required />: <input
                                        id="timerbools" type="number"
                                        class="maxInputNumber input-text qty text" pattern="[0-9]"
                                        onkeyup="maxInputNumber(this)" name="quantity" min="0" max="59"
                                        placeholder="00" value="00" required />
                                </div>

                            </div>
                        </div>

                        <button type="button" class="btn btn-success saveBtn"
                            data-dismiss="modal" onclick="submitBool()">SAVE</button>
                    </div>

                </div>

            </div>

        </div>

    )
}

const ShortAnswerBox = (props) => {
    const classes = useStyles3()

    const { lsnPlanId, subId, quiz } = props
    const [qst, setQst] = useState((quiz) ? quiz.question_text : '')
    const [ans, setAns] = useState('')

    const [timeMins, setTimeMins] = useState(0)
    const [timeSecs, setTimeSecs] = useState(15)

    const onSubmitText = async () => {
        console.log('submitting text question');
        let timerTextM = timeMins;
        let timerTextS = timeSecs;
        let timerText = timerTextM + ":" + timerTextS;

        let formData = {
            classroom_id: 0,
            staff_id: 0,
            staff_lesson_id: lsnPlanId,
            subject_id: subId,
            chapter_id: 0,
            quiz_title: "abcd",
            scheduled_date: "2020-12-20",
            quiz_ended: 0,
            question_type: Constants.Application.QUIZ.ID_QTYPE_TEXT_TYPE,
            question_text: qst,
            question_time: '00:' + timerText,//$(event.target.closest('div')).find('input').val(), //$("#timertxt").val(),
            question_url: '',
            question_topic_text: "",
            question_pts: 1,
            options: [
                {
                    option_index: 0,
                    option_text: ans,
                    option_url: "",
                    option_correct: 1,//$("#toggle").is(":checked")?1:0
                }

            ]
        }

        console.log('form data to be sent ' + JSON.stringify(formData));

        // save quiz
        const resp = await LessonPlanAPI.saveQuiz(formData)

        // close create
        props.toggleCreate()
    }

    let btnDisabled = false
    if (!qst
        || !ans
        || (!timeMins && !timeSecs))
        btnDisabled = true

    return (
        <div>
            <Typography
                variant='h5'>
                Short Answer
            </Typography>
            <p>
                <Typography
                    variant='caption'>Please check the correct answer, in students view, the options will be shuffled for every student</Typography>
            </p>

            <div
                className={classes.fullWidth}
                style={{
                    marginTop: 20
                }}>

                {/* question */}
                <TextField id="filled-basic" label="Question" variant="filled"
                    placeholder="Question"
                    defaultValue={qst}
                    onChange={(event) => {
                        const { name, value } = event.target
                        setQst(value)
                    }}
                    className={classes.fullWidth} />
                <div style={{
                    marginTop: 10
                }}></div>


                {/* options */}
                <TextField id="filled-basic" label="Correct Answer" variant="filled"
                    placeholder="Enter Correct Answer"
                    onChange={(event) => {
                        const { name, value } = event.target
                        setAns(value)
                    }}
                    className={classes.fullWidth} />

                {/* save button */}

                <div style={{
                    marginTop: 20,
                    marginBottom: 20
                }}>
                    <div
                        style={{
                            display: 'flex',
                            alignSelf: 'center',
                            marginBottom: 20
                        }}>
                        Set Timer
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}>
                        <TextField id="outlined-basic" label="Mins" variant="outlined"
                            type="number"
                            defaultValue={timeMins}
                            onChange={(event) => {
                                const { name, value } = event.target
                                setTimeMins(value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    max: 2, min: 0,

                                }
                            }}
                            style={{
                                width: 100
                            }}
                            placeholder="Mins" />
                        <TextField id="outlined-basic" label="Secs" variant="outlined"
                            type="number"
                            defaultValue={timeSecs}
                            onChange={(event) => {
                                const { name, value } = event.target
                                setTimeSecs(value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    max: 60, min: 0,

                                }
                            }}
                            style={{
                                width: 100
                            }}
                            placeholder="Secs" />
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',

                    }}>
                    <Button variant="contained" color="primary"
                        disabled={btnDisabled}
                        onClick={onSubmitText}
                        style={{
                            alignSelf: 'flex-end'
                        }}>
                        Save
                    </Button>
                </div>

            </div>
        </div>
    )
}
/* 
const ret = (
    <>

        <div class="quiz-drawer-push">
            <div class="quiz-drawer quiz-drawer-right">
                <div
                    class="offcanvas-header d-flex align-items-center justify-content-between p-5">
                    <h3 class="font-weight-bold m-0">
                        Quiz Planner <small class="text-muted font-size-sm ml-2">Start
                  a quiz by creating questions</small>
                    </h3>
                    <a href="#"
                        class="close-btn2 btn btn-xs btn-icon btn-light btn-hover-primary">
                        <i class="ki ki-close icon-xs text-muted"></i>
                    </a>
                </div>
                <div class="w-100">
                    <button class="qst-btn btn btn-info w-50 mb-1 addQuizBtn" onclick="clickonCreateQzBtn()">
                        Create Question <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                    </button>
                    <h5
                        style="margin-right: 10%; display: flex; justify-content: flex-start; margin-left: 5%; margin-top: 4%; margin-bottom: 2%;"
                        id="questionsCount" class="mt-3">
                        <span class="questionsCountSpan"> </span>&nbsp;Questions
          </h5>

                </div>
                <div class="modal-body" style="height: 100%; padding-top: 0;">

                    <div class="mb-4" id="qlist" style="height: 75%; overflow-y: auto">
                        <ul class="nav nav-list">
                            <li>No quiz created yet..</li>
                        </ul>
                    </div>

                </div>

            </div>

        </div>


        <div class="quiz-drawer-push">
            <div class="quiz-drawer quiz-drawer-qst overflow-auto">
                <div
                    class="offcanvas-header d-flex align-items-center justify-content-between p-5">
                    <h3 class="font-weight-bold m-0">
                        Quiz Planner <small class="text-muted font-size-sm ml-2">Create
                  a question by choosing a type</small>
                    </h3>
                    <a href="#"
                        class="close-btn btn btn-xs btn-icon btn-light btn-hover-primary quiz-close-btn">
                        <i class="ki ki-close icon-xs text-muted"></i>
                    </a>
                </div>
                <div class="modal-body ">
                    <div class="quizTopSectopn ">
                        <div class="dropdown quizDropdown quizDropdownSection"
                            style="text-align: left;">
                            <button
                                class="btn btn-outline-secondary quizDropdownBtn qsttype w-50 "
                                type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                Select Question type <i class="fa fa-angle-down float-right"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="#" onclick="addMultiple()">Multiple
                          Choice</a> <a class="dropdown-item" href="#" onclick="addCheck()">Multiple
                          Select</a> <a class="dropdown-item" href="#" onclick="addTF()">True
                          / False</a> <a class="dropdown-item" href="#"
                                    onclick="addPicChoice()">Picture Choice</a> <a
                                        class="dropdown-item" href="#" onclick="shortAnswer()">Short
                          Answer</a>

                            </div>
                        </div>

                    </div>
                    <div id="qbox"></div>
                </div>

            </div>

        </div>

    </>
)
 */
const QuizBox = (props) => {
    const classes = useStyles3();
    const [qType, setQType] = useState('')
    const [create, setCreate] = useState(false)
    const [quizId, setQuizId] = useState(0)
    const [qstId, setQstId] = useState(0)
    const [qTypeId, setQTypeId] = useState(0)
    const [quiz, setQuiz] = useState(null)

    const toggleCreate = () => {
        setCreate(!create)

        if(create) {
            // show new
            setQuiz(null)
            setQuizId(0)
            setQstId(0)
            setQType('')
            setQTypeId(0)
        }
        props.onUpdateList()
    }

    const handleChange = (event) => {
        const { name, value } = event.target

        setQType(value)
    }

    const handleChangeQTypeId = (qTypeId) => {
        switch(qTypeId) {
            case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_CHOICE:
                setQType('MULTIPLECHOICE')
                break;
            case Constants.Application.QUIZ.ID_QTYPE_MULTIPLE_SELECT:
                setQType(`MULTIPLESELECT`)
                break;
            case Constants.Application.QUIZ.ID_QTYPE_TRUE_FALSE:
                setQType(`TRUEFALSE`)
                break;
            case Constants.Application.QUIZ.ID_QTYPE_TEXT_TYPE:
                setQType(`SHORTANSWER`)
                break;
            
        }
    }

    const onSubmitOpt = () => {
        // show success message from callback

        console.log(`submitted multiple choice successfully..`)
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const quizQuestions = (
        <div style={{
            padding: 20,
            width: 500
        }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 20
                }}>
                <Typography
                    variant='h3'>
                    Quiz Planner&nbsp;&nbsp; <Typography variant='caption'>Start
                        a quiz by creating questions</Typography>
                </Typography>

            </div>

            {/* create question button */}
            <Button variant="contained" color="primary"
                onClick={toggleCreate}>
                Create Question
            </Button>
            <br /><br />

            {(create) &&
                <>
                    <Typography
                        variant='body2'>
                        Create
                        a question by choosing a type
                    </Typography>
                    <br />

                    <div
                        style={{
                            display: 'flex',
                            width: '100%'
                        }}>
                        <FormControl variant="filled" className={classes.formControl}
                            style={{
                                width: '100%'
                            }}>
                            <InputLabel id="demo-simple-select-filled-label">Question Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={qType}
                                onChange={handleChange}
                            >
                                {/* <MenuItem value="">
                            <em>None</em>
                        </MenuItem> */}
                                <MenuItem value={`MULTIPLECHOICE`}>MULTIPLE CHOICE</MenuItem>
                                <MenuItem value={`MULTIPLESELECT`}>MULTIPLE SELECT</MenuItem>
                                <MenuItem value={`TRUEFALSE`}>TRUE OR FALSE</MenuItem>
                                <MenuItem value={`SHORTANSWER`}>SHORT ANSWER</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div style={{
                        marginTop: 20
                    }}>
                        {qType == 'MULTIPLESELECT' &&
                            <CheckBox lsnPlanId={props.lsnPlanId} subId={props.subId} quiz={quiz} toggleCreate={toggleCreate} />
                        }

                        {qType == 'MULTIPLECHOICE' &&
                            <RadioBox lsnPlanId={props.lsnPlanId} subId={props.subId} quiz={quiz} toggleCreate={toggleCreate} />
                        }

                        {qType == 'TRUEFALSE' &&
                            <TrueFalseBox lsnPlanId={props.lsnPlanId} subId={props.subId} quiz={quiz} toggleCreate={toggleCreate} />
                        }


                        {qType == 'SHORTANSWER' &&
                            <ShortAnswerBox lsnPlanId={props.lsnPlanId} subId={props.subId} quiz={quiz} toggleCreate={toggleCreate} />
                        }
                    </div>
                </>
            }
        </div>
    )


    const quizContent = (
        <AlignItemsList
            quiz={props.quiz}
            onDeleteQuiz={(id) => props.onDeleteQuiz(id)}
            onOpenQuiz={(quizId, qstId, quiz, qTypeId) => {
                setQuizId(quizId);
                setQstId(qstId);
                handleChangeQTypeId(qTypeId);
                setQuiz(quiz);

                toggleCreate()
            }} />
    )

    return (
        <>

            {quizQuestions}

            {!create &&
                quizContent
            }
        </>
    )
}


const QuizPlannerDrawer = (props) => {
    const [quiz, setQuiz] = useState(null)

    const onUpdateList = () => {
        getUnpubQuiz(props.lsnPlanId)
    }

    const onDeleteQuiz = async (id) => {

        await LessonPlanAPI.delQuiz(id)
        // update
        getUnpubQuiz(props.lsnPlanId)
    }

    const getUnpubQuiz = async (lsnPlanId) => {
        const resp = await LessonPlanAPI.getUnpubQuiz(lsnPlanId)

        console.log(`unpub quiz response ${JSON.stringify(resp)}`)
        setQuiz(resp.data)
    }

    useEffect(() => {

        getUnpubQuiz(props.lsnPlanId)
        return <></>
    }, [])

    return (
        <QuizBox lsnPlanId={props.lsnPlanId} subId={props.subId} quiz={quiz}
            onDeleteQuiz={(id) => onDeleteQuiz(id)}
            onUpdateList={onUpdateList} />
    )
}

export default QuizPlannerDrawer;