/**
 * @project kgtopg - schooler - partner
 * publisher data services
 */
import API from "./http";
import axios from "axios";
import qs from "querystring";
import Constants from "../resource/Constants";
import Cookie from 'js-cookie';
import { data } from "jquery";


export default {
    async postSaveContent(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/content/";



            console.log("postSaveContent api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSaveBulkContent(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/content/";



            console.log("postSaveContent api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSaveBookSub(data) {
        console.log("save book subscription api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/book-subscription/";



            console.log("postSaveBookSub api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveBookSub api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postUpdateBookSub(data) {
        console.log("update book subscription api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/book-subscription/";



            console.log("postUpdateBookSub api url", url)

            const options = {
                method: "PUT",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postUpdateBookSub api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSaveBulkContent2(data) {

        console.log("data at save bulk content", data)

        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/bulk-content-upload/";

            console.log("bulk upload content api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                // headers: {
                //     "Content-Type": "application/json",
                //     Accept: "application/json",
                //     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                // },

            };

            let response
            response = await axios.post(url, data)
            console.log("postSaveBulkContent2 api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSaveSingleContent(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += '/save-prov-content/'



            console.log("postSaveContent api url", url)

            const options = {
                method: "POST",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSavePriceSetup(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += '/content-prov-cost/'

            console.log("postSavePricingSetup api url", url)

            const options = {
                method: "POST",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postUpdatePriceSetup(data) {
        console.log("update price api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += '/content-prov-cost/'

            console.log("postSavePricingSetup api url", url)

            const options = {
                method: "PUT",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async updateSingleContent(id, data) {
        console.log("update content api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += `/content/${id}`



            console.log("updateContent api url", url)

            const options = {
                method: "PUT",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("updateContent api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSendBulkInvites(data) {
        console.log("postbulkinvites api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += `/school-bulk-emails/`



            console.log("postBulkInvites api url", url)

            const options = {
                method: "POST",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("postBulkInvites api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSavePublisherPayment(data) {
        console.log("postSavePublisherPayment api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/publisher-payment-history/";



            console.log("postSavePublisherPayment api url", url)
            console.log("postSavePublisherPayment api body ", JSON.stringify(data))

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("postSavePublisherPayment api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async putSavePublisherPayment(data) {
        console.log("putSavePublisherPayment api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/publisher-payment-history/";

            console.log("putSavePublisherPayment api url", url)

            const options = {
                method: "PUT",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("putSavePublisherPayment api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async putUpdatePaymentStatus(data) {
        console.log("putUpdatePaymentStatus api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/publisher-payment-history/";



            console.log("putUpdatePaymentStatus api url", url)

            const options = {
                method: "PUT",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("putUpdatePaymentStatus api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async delPricing(userId, costId) {
        console.log("delete pricing api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-prov-content/?pub_id=${data.pub_id}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&cont_f_des=${data.content_f_desc}&cont_len=${data.content_length}&cont_loc=${data.content_location}&cont_size=${data.content_size}&image_url=${data.img_url}&content_ty_id=${data.content_type_id}&chap_id=${data.chapter_id}&chap_name=${data.chapter_name}&top_id=${data.topic_id}&top_name=${data.topic_name}&cont_name=${data.content_name}`
            url += `/content-prov-cost/?content_prov=${userId}&cost_id=${costId}`
            console.log("delete pricing cost api url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };

            let response = await axios(options);
            console.log("delete pricing cost api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSaveSingleEbook(data, id) {

        console.log("data at save single book", data)

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-pub-book/?pub_id=${data.publisher_id}&name=${data.ebook_name_text}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&loc_url=${data.loc_url}&img_url=${data.img_url}&no_chap=${data.no_chap}&no_les=${data.no_les}&no_pgs=${data.no_pgs}`
            // url += '/save-pub-book/'
            // id ? '/update-pdf-jpg/' :
            // url += id ? '/update-ebook-zip/' :'/save-ebook-zip/'
            url += id ? '/update-pdf/' : '/pdf-jpg/'

            console.log("ebook api url", url)

            const options = {
                method: id ? "PUT" : "POST",
                data: data,
                url,
                // headers: {
                //     "Content-Type": "application/json",
                //     Accept: "application/json",
                //     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                // },

            };

            // let response = await axios(options);
            let response = (id) ? await axios.put(url, data) : await axios.post(url, data)


            console.log("postSaveSingleEbook ebook api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSaveSingleEbook2(data, id) {

        console.log("data at save single book", JSON.stringify(data))

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-pub-book/?pub_id=${data.publisher_id}&name=${data.ebook_name_text}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&loc_url=${data.loc_url}&img_url=${data.img_url}&no_chap=${data.no_chap}&no_les=${data.no_les}&no_pgs=${data.no_pgs}`
            // url += '/save-pub-book/'
            // id ? '/update-pdf-jpg/' :
            // url += id ? '/update-ebook-zip/' :'/save-ebook-zip/'
            url += id ? `/ebook/${id}` : '/ebook/'

            console.log("ebook api url", url)

            const options = {
                method: id ? "PUT" : "POST",
                data: data,
                url,
                // headers: {
                //     "Content-Type": "application/json",
                //     Accept: "application/json",
                //     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                // },

            };

            let response = await axios(options);
            // let response = (id) ? await axios.put(url, data) : await axios.post(url, data)


            console.log("postSaveSingleEbook ebook api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSavePaidStatus(data) {

        console.log("data at save paid status", JSON.stringify(data))

        console.log("ispaid api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PLATFORM_END_URL;
            // url += `/save-pub-book/?pub_id=${data.publisher_id}&name=${data.ebook_name_text}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&loc_url=${data.loc_url}&img_url=${data.img_url}&no_chap=${data.no_chap}&no_les=${data.no_les}&no_pgs=${data.no_pgs}`
            // url += '/save-pub-book/'
            // id ? '/update-pdf-jpg/' :
            // url += id ? '/update-ebook-zip/' :'/save-ebook-zip/'
            url += `/publisher-cost-ispaid-update/`

            console.log("ispaid api url", url)

            const options = {
                method: "PUT",
                data: data,
                url,
                // headers: {
                //     "Content-Type": "application/json",
                //     Accept: "application/json",
                //     // 'Authorization': 'Bearer ' + UserSession.authtoken,
                // },

            };

            let response = await axios(options);
            // let response = (id) ? await axios.put(url, data) : await axios.post(url, data)


            console.log("postSavePaidStatus ebook api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },




    async DeleteSingleEbook(bookId) {

        console.log("Delete single book", bookId)

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/save-pub-book/?pub_id=${data.publisher_id}&name=${data.ebook_name_text}&inst_type_id=${data.institute_type_id}&inst_board_id=${data.institute_board_id}&cls_std=${data.classroom_std}&course=${data.course}&sub_id=${data.subject_id}&loc_url=${data.loc_url}&img_url=${data.img_url}&no_chap=${data.no_chap}&no_les=${data.no_les}&no_pgs=${data.no_pgs}`
            // url += '/save-pub-book/'
            url += `/ebook/${bookId}`

            console.log("ebook api url", url)

            const options = {
                method: "DELETE",
                url,

            };



            // let response = await axios(options);
            let response = await axios(options)



            console.log("Delete ebook api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getSubscribers(pubId, subId, clrStd) {

        console.log("get subscribers api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PLATFORM_END_URL;
            url += "/list-subscribers/";
            url += `?publisher_id=${pubId}&subject_id=${subId}&cls_std=${clrStd}`
            console.log("get subscribers api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get subscribers api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getPayOut(pubId) {

        console.log("get payouts api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += "/provider-payout-cost/";
            url += "/provider-payout-cost1/";

            url += `?publisher_id=${pubId}`
            console.log("get payouts api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get payouts api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getContProvPayments(provId) {

        console.log("get contprov payments api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += "/provider-payout-cost-cont/";
            url += '/list-of-months-costs/';
            url += `?content_prov_id=${provId}`
            console.log("get contprov payments api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get contprov payments api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },
    
    async getContProvMonthPayments(provId, month) {

        console.log("get contprov month payments api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += "/provider-payout-cost-cont/";
            url += '/list-month-cost/';
            url += `?content_prov_id=${provId}`
            url += `&month=${month}`
            console.log("get contprov month payments api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get contprov month payments api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },
    async getPayOutHistory(pubId, provId) {

        console.log("get payout history api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/publisher-payment-history/";
            url += `?publisher_id=${pubId}`
            url += `&cont_prov_id=${provId}`
            console.log("get payouts api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get payouts api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getSubPayOut(pubId, provId) {

        console.log("get prov payouts api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/provider-payout-particular-cost1/";
            url += `?publisher_id=${pubId}&content_prov_id=${provId}`
            console.log("get prov payouts api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get prov payouts api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getSubPayOutType(pubId, provId, subId) {

        console.log("get prov payouts type api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/provider-payout-all-type-cost/";
            url += `?publisher_id=${pubId}&content_prov_id=${provId}&subject_id=${subId}`
            console.log("get prov payouts type api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get prov payouts type api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getStandardsSubjects(instId) {
        const pubId = Cookie.get("kgtopg.partner.user.id");
        console.log("get stdsubs api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PLATFORM_END_URL;
            url += "/list-standards-subjects/";
            url += `?publisher_id=${pubId}&institute_id=${instId}`
            console.log("get stdsubs api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get stdsubs api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getStandardsSubjectsCost(instId) {
        const pubId = Cookie.get("kgtopg.partner.user.id");
        console.log("get stdsubs api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PLATFORM_END_URL;
            url += "/list-standards-subjects-cost/";
            url += `?publisher_id=${pubId}&institute_id=${instId}`
            console.log("get stdsubs api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get stdsubs api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getInvites(pubId) {

        console.log("get invites api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/get-publisher-invites/";
            url += `?publisher_id=${pubId}`
            console.log("get invites api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("get invites api response", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetPublisherEbooks() {

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/pub-book/?pub_id=1";



            console.log("ebook api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent ebook api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetPublisherContent(instId, boardId, stdId, subId, chapId, provId) {

        console.log("content api is getting called", provId)
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };


            let url = Constants.Application.PARTNER_END_URL;
            // url += "/content-pub/?content_prov_id=" + provId;
            url += `/content-pub-inst/?content_prov_id=${provId}&institute_type_id=${instId}&institute_board_id=${boardId}&subject_id=${subId}&chapter_id=${chapId}&classroom_std=${stdId}`



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get Content api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getPricingSetup(userId) {

        console.log("pricing setup api is getting called", userId)
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };


            let url = Constants.Application.PARTNER_END_URL;
            // url += "/content-pub/?content_prov_id=" + provId;
            url += `/content-prov-cost/?content_prov=${userId}`
            console.log("pricing setup api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios.get(url);

            console.log("get pricing setup api response", response.data)


            if (response && response.data) {

                return response.data;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async searchContent(q) {
        const provId = Cookie.get("kgtopg.partner.cont.id");
        console.log(`search content api is getting called for ${q}`)
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/search-content/?search=${q}&prov_id=${provId}`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get Content api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetEbookContentOld(book_id) {

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/cont-prov-cont/?book_id=${book_id}`;



            console.log("ebook api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent ebook api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetEbookContent(book_id) {

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/topic-book-subrel-list/?book_id=${book_id}`;
            url += `/cont-prov-cont/?book_id=${book_id}`;


            console.log("ebook api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent ebook api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetEbookContentTeachView(book_id) {

        console.log("ebook api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/teach-book/?book_id=${book_id}`;



            console.log("ebook api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("getEbookContent ebook api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async GetPublisherListCont() {

        console.log("publisher list api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            const ProvId = Cookie.get('kgtopg.partner.cont.id')

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-all-pub/?cont_prov_id=${ProvId}`;



            console.log("publisher list api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("publishers list api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postEbookMappedContentOld(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/save-pub-cont-sub/";



            console.log("postSaveContent api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postEbookMappedContent(data) {
        console.log("api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/topic-book-subrel-list/";

            console.log("postSaveContent api url", url)
            console.log('postSaveContent body ', data)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },




    async deleteBoookMappedContent(id) {
        console.log("api is getting called at delete")
        try {


            let url = Constants.Application.PARTNER_END_URL;
            url += `/topic-book-subrel-detail/${id}/`;



            console.log("delete content api url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("Delete content api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },



    async updateBoookMappedContentOld(body, id) {
        console.log("api is getting called at delete")
        try {


            let url = Constants.Application.PARTNER_END_URL;
            // url += `/content-sub/${id}`;
            url += `/update-single-sub/`;



            console.log("delete content api url", url)

            const options = {
                method: "PUT",
                data: body,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("Delete content api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async addBoookMappedContent(body) {
        console.log("api is getting called at add")
        try {


            let url = Constants.Application.PARTNER_END_URL;
            // url += `/content-sub/${id}`;
            url += `/topic-book-subrel-list/`;

            console.log("Add mapping api url", url)
            console.log('Add mapping body ', body)

            const options = {
                method: "POST",
                data: body,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("Add mapping api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async updateBoookMappedContent(body, id) {
        console.log("api is getting called at update")
        try {


            let url = Constants.Application.PARTNER_END_URL;
            // url += `/content-sub/${id}`;
            url += `/topic-book-subrel-detail/${id}/`;

            console.log("Update mapping api url", url)
            console.log('Update mapping body ', body)

            const options = {
                method: "PUT",
                data: body,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("Update mapping api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getContentProviders() {

        console.log("Content providers api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/content-prov/`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get content api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async DelContentProvider(provDet) {

        console.log("DeletProv providers api is getting called", provDet)
        try {


            const ProvId = Cookie.get('kgtopg.partner.cont.id')

            const body = {
                cont_prov_id: provDet.id,
                pub_id: ProvId
            }

            let url = Constants.Application.PARTNER_END_URL;
            url += `/delete-cont-prov/`;



            console.log("content api url", url)

            const options = {
                method: "DELETE",
                data: body,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get content api response", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async savePubContProv(data) {
        console.log("savePubContProv api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/contprov-pub/";

            console.log("savePubContProv api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("savePubContProv api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getMyContentProviders(bookId) {

        console.log(" getMyContentProviders api is getting called")
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `cont-prov-cont/?book_id=${bookId}`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get content api response", response)


            if (response) {
                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getEbookMappedContentOld(book_id) {

        console.log("getting data of ebook mapped details")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-cont-sub/?book_id=${book_id}`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get  mapped content response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getEbookMappedContent(book_id) {

        console.log("getting data of ebook mapped details")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            // url += `/topic-book-subrel-list/?book_id=${book_id}`;
            url += '/topic-book-subrel-detail1/'+book_id+'/'



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get  mapped content response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSaveApproveStatus(body) {

        console.log("posting approval status with body " + JSON.stringify(body))
        try {

            let url = Constants.Application.PLATFORM_END_URL;
            url += `/approve-reject/?publisher_id=${body.publisher_id}&sub_id=${body.sub_id}&classroom_std=${body.classroom_std}&institute_id=${body.institute_id}&partner_status=${body.partner_status}&publisher_name=${body.publisher_name}`;

            console.log("post approval status api url", url, body)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("post approval status response ", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSavePubInstStatus(body, isNew) {
        console.log("pubinst status with body " + JSON.stringify(body))
        try {

            let url = Constants.Application.PLATFORM_END_URL;
            url += `/partner-publisher-institute-rel/`;

            console.log("pub inst status api url", url, body)

            const options = {
                method: (isNew) ? "POST" : "PUT",
                url,
                data: body,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("pub inst status response ", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postPubInstCost(body) {

        console.log("posting publisher cost for institute with body " + JSON.stringify(body))
        try {

            let url = Constants.Application.PLATFORM_END_URL;
            url += `/publisher-institute-cost/`;

            console.log("post publisher cost api url", url, body)

            const options = {
                method: "POST",
                url,
                data: body,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("post publisher cost response ", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSavePubInvite(body) {

        console.log("posting invite approval status with body " + JSON.stringify(body))
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/publisher-approve/`;

            console.log("post publisher invite approval status api url", url, body)

            const options = {
                method: "POST",
                url,
                data: body,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("post invite approval status response ", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postInvite(body) {

        console.log("posting invite with body " + JSON.stringify(body))
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/send-school-invite/`;

            console.log("posting invite status api url", url, body)

            const options = {
                method: "POST",
                url,
                data: body,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);
            console.log("posting invite status response ", response)

            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async deletePublisher(pubId) {


        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/publisher/${pubId}`;



            console.log("delete publisher url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("delete publisher response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async deleteContProv(provId) {


        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/content-prov/${provId}`;

            console.log("delete content provider url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("delete content provider response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async deleteContent(contId) {


        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/content/${contId}`;



            console.log("delete content url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("delete content response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getPubAdmins(pubId) {

        console.log("getting data of publisher admin details")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-pub-details/?user_id=${pubId}`;



            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get  mapped content response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getPubInvites(pubId) {

        console.log("getting data of publisher admin details")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-pub-details/?pub_id=${pubId}`;
            console.log("invites api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get invites response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async getContProvAdmins(provId) {

        console.log("getting data of content provider admins")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-prov-details/?user_id=${provId}`;

            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get  mapped content response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postSaveFeedback(data) {
        console.log("api is getting called")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += '/feed-back/'

            console.log("postSaveFeedback api url", url)

            const options = {
                method: "POST",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };


            let response = await axios(options);

            console.log("postSaveContent api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getAllSubjects() {


        console.log("getting data of all subjects")
        try {

            let url = Constants.Application.PLATFORM_END_URL;
            url += `/all-subject-details/`;

            console.log("content api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get all subjects response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getMyNotifications(typeId, id) {

        console.log("getting notifications")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/notification-save-retrieve-update/`;
            url += `?login_type_id=${typeId}&to_id=${id}`

            console.log("notifications api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get all notifications response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async doReadNotifications(typeId, id) {

        console.log("read notifications")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/notification-save-retrieve-update/`;

            const body = {
                login_type_id: typeId,
                to_id: id
            }

            console.log("read notifications api url", url)

            const options = {
                method: "PUT",
                url,
                data: body,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("read all notifications response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getMySubjects(contProvId) {

        console.log("getting my subjects")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-contentprov-subjects/`;
            url += `?cont_prov_id=${contProvId}`
            url += `&institute_type_id=0`
            url += `&institute_board_id=0`
            url += `&classroom_std=0`

            console.log("my subjects api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get my subjects response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getMySubjectsILC(contProvId, instType, brdType, clrStd) {

        console.log("getting my subjects ilc api")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/get-contentprov-subjects/`;
            url += `?cont_prov_id=${contProvId}`
            url += `&institute_type_id=${instType}`
            url += `&institute_board_id=${brdType}`
            url += `&classroom_std=${clrStd}`

            console.log("my subjects ilc api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get my subjects ilc api response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postContProvPricing(data) {
        console.log("api is getting called")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += '/content-prov-cost-save-retrieve/'

            console.log("postContProvPricing api url", url)

            const options = {
                method: "POST",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };


            let response = await axios(options);

            console.log("postContProvPricing api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postSaveInvoice(data) {
        console.log("api is getting called")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += '/contentprov-invoice-save/'

            console.log("postSaveInvoice api url", url)

            const options = {
                method: "POST",
                url,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };


            let response = await axios(options);

            console.log("postSaveInvoice api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getSubjectPricing(contProvId, subId) {

        console.log("getting subject pricing")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/content-prov-cost-save-retrieve/`;
            url += `?content_prov_id=${contProvId}&subject_id=${subId}`

            console.log("my subject pricing api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get my subject pricing response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

    async getSubjectILCPricing(contProvId, subId, instType, boardType, standardType) {

        console.log("getting subject ilc pricing")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/ilc/`;
            url += `?content_prov_id=${contProvId}&subject_id=${subId}`
            url += `&institute_type_id=${instType}`
            url += `&institute_board_id=${boardType}`
            url += `&classroom_std=${standardType}`

            console.log("my subject pricing ilc api url", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get my subject pricing ilc response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },
    
    async getMySubjects2(contProvId) {

        console.log("getting my subjects")
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/retrieve-subjects/`;
            url += `?cont_prov_id=${contProvId}`

            console.log("my subjects ", url)

            const options = {
                method: "GET",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("get my subjects response ", response)


            if (response) {

                return response;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },
}