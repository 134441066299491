
export const ACTIVITYLISTCONTDETAILS_LIST_REQUEST = 'ACTIVITYLISTCONTDETAILS_LIST_REQUEST'
export const ACTIVITYLISTCONTDETAILS_LIST_SUCCESS = ' ACTIVITYLISTCONTDETAILS_LIST_SUCCESS'
export const ACTIVITYLISTCONTDETAILS_LIST_FAIL = 'ACTIVITYLISTCONTDETAILS_LIST_FAIL'

export const ACTIVITYLISTPUBDETAILS_LIST_REQUEST = 'ACTIVITYLISTPUBDETAILS_LIST_REQUEST'
export const ACTIVITYLISTPUBDETAILS_LIST_SUCCESS = ' ACTIVITYLISTPUBDETAILS_LIST_SUCCESS'
export const ACTIVITYLISTPUBDETAILS_LIST_FAIL = 'ACTIVITYLISTPUBDETAILS_LIST_FAIL'

export const ACTIVITYAPPROVALCONTDETAILS_LIST_REQUEST = 'ACTIVITYAPPROVALCONTDETAILS_LIST_REQUEST'
export const ACTIVITYAPPROVALCONTDETAILS_LIST_SUCCESS = ' ACTIVITYAPPROVALCONTDETAILS_LIST_SUCCESS'
export const ACTIVITYAPPROVALCONTDETAILS_LIST_FAIL = 'ACTIVITYAPPROVALCONTDETAILS_LIST_FAIL'

export const ACTIVITYAPPROVALPUBDETAILS_LIST_REQUEST = 'ACTIVITYAPPROVALPUBDETAILS_LIST_REQUEST'
export const ACTIVITYAPPROVALPUBDETAILS_LIST_SUCCESS = ' ACTIVITYAPPROVALPUBDETAILS_LIST_SUCCESS'
export const ACTIVITYAPPROVALPUBDETAILS_LIST_FAIL = 'ACTIVITYAPPROVALPUBDETAILS_LIST_FAIL'
