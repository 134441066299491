import axios from "axios";
import Constants from "../../resource/Constants"
import Cookie from 'js-cookie';
import { COMPANYDETCONTGET_LIST_FAIL, COMPANYDETCONTGET_LIST_REQUEST, COMPANYDETCONTGET_LIST_SUCCESS, COMPANYDETCONTPOST_LIST_FAIL, COMPANYDETCONTPOST_LIST_SUCCESS, COMPANYDETPCONTPOST_LIST_REQUEST, COMPANYDETPPUBPOST_LIST_REQUEST, COMPANYDETPUBGET_LIST_FAIL, COMPANYDETPUBGET_LIST_REQUEST, COMPANYDETPUBGET_LIST_SUCCESS, COMPANYDETPUBPOST_LIST_FAIL, COMPANYDETPUBPOST_LIST_SUCCESS } from "../constants/CompanyProfile";
const ProvId = Cookie.get('kgtopg.partner.user.id')

const listCompanyDetPubGet = () => async (dispatch) => {

    try {
        dispatch({ type: COMPANYDETPUBGET_LIST_REQUEST })
        // const { data } = await axios.get(Constants.Application.PARTNER_END_URL +`/get-all/`)
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/company-pub/?publisher_id=${ProvId}`)
        if (data && data.data) {
            let CompData = data.data

            let userId = ProvId
            let companyName = CompData.name
            let companyEmail = CompData.email
            let companyWebsite = CompData.email
            let companyLogo = CompData.logo
            let companyAddress = CompData.address
            let companyPan = CompData.pan
            let companyGst = CompData.gst
            let companyPhone = CompData.phone_number
            let companyBankAccName = CompData.bank_account_name
            let companyBankAccNum = CompData.bank_account_number
            let companyBankAccIFSC = CompData.banck_account_IFSC
            let companyBankAccBranch = CompData.bank_branch
            let companyBankName = CompData.bank_name


            Cookie.set('kgtopg.partner.company.name', companyName,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.email', companyEmail,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.logo', companyLogo,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.phone', companyPhone,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.website', companyWebsite,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.address', companyAddress,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.pan', companyPan,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.gst', companyGst,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.accname', companyBankAccName,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.accnum', companyBankAccNum,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.accifsc', companyBankAccIFSC,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.bankbranch', companyBankAccBranch,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.bankname', companyBankName,{ expires: 90 });
            
        }


        console.log("listCompanyDetPubGet ======================", data)
        dispatch({ type: COMPANYDETPUBGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: COMPANYDETPUBGET_LIST_FAIL, payload: error.message })

    }
}


const listCompanyDetContGet = () => async (dispatch) => {

    try {
        dispatch({ type: COMPANYDETCONTGET_LIST_REQUEST })

        // const { data } = await axios.get(Constants.Application.PARTNER_END_URL +`/get-all/`)
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/company-prov/?cont_prov_id=${ProvId}`)
        console.log("listCompanyDetContGet at actions ======================", data.data)
        if (data && data.data) {
            let CompData = data.data

            let userId = ProvId
            let companyName = CompData.name
            let companyEmail = CompData.email
            let companyWebsite = CompData.email
            let companyLogo = CompData.logo
            let companyAddress = CompData.address
            let companyPan = CompData.pan
            let companyGst = CompData.gst
            let companyPhone = CompData.phone_number
            let companyBankAccName = CompData.bank_account_name
            let companyBankAccNum = CompData.bank_account_number
            let companyBankAccIFSC = CompData.banck_account_IFSC
            let companyBankAccBranch = CompData.bank_branch
            let companyBankName = CompData.bank_name


            Cookie.set('kgtopg.partner.company.name', companyName,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.email', companyEmail,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.logo', companyLogo,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.phone', companyPhone,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.website', companyWebsite,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.address', companyAddress,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.pan', companyPan,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.gst', companyGst,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.accname', companyBankAccName,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.accnum', companyBankAccNum,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.accifsc', companyBankAccIFSC,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.bankbranch', companyBankAccBranch,{ expires: 90 });
            Cookie.set('kgtopg.partner.company.bankname', companyBankName,{ expires: 90 });
        }


        dispatch({ type: COMPANYDETCONTGET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: COMPANYDETCONTGET_LIST_FAIL, payload: error.message })

    }
}


const listCompanyDetPubPost = (body) => async (dispatch) => {
    try {
        dispatch({ type: COMPANYDETPPUBPOST_LIST_REQUEST })
        console.log("body at listCompanyDetPubPost", body)

        const url = Constants.Application.PARTNER_END_URL + `/save-cpub/`
        const options = {
            method: "POST",
            data: body,
            url,
        }
        const { data } = await axios(options)
        console.log("listCompanyDetPubPost ======================", data)
        dispatch({ type: COMPANYDETPUBPOST_LIST_SUCCESS, payload: data })
        // dispatch(listCompanyDetPubGet())
    } catch (error) {
        dispatch({ type: COMPANYDETPUBPOST_LIST_FAIL, payload: error.message })

    }
}


const listCompanyDetContPost = (body) => async (dispatch) => {
    try {
        dispatch({ type: COMPANYDETPCONTPOST_LIST_REQUEST })
        console.log("body at listCompanyDetContPost", body)

        const url = Constants.Application.PARTNER_END_URL + `/save-cprov/`
        const options = {
            method: "POST",
            data: body,
            url,
        }
        const { data } = await axios(options)
        console.log("listCompanyDetContPost at actions ======================", data)
        dispatch({ type: COMPANYDETCONTPOST_LIST_SUCCESS, payload: data })
        // dispatch(listCompanyDetContGet())
    } catch (error) {
        dispatch({ type: COMPANYDETCONTPOST_LIST_FAIL, payload: error.message })

    }
}



export {
    listCompanyDetPubGet,
    listCompanyDetContGet,
    listCompanyDetPubPost,
    listCompanyDetContPost,
}