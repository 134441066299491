/**
 * Logout component
 * which will redirect to login page
 */

import { useEffect } from "react"
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import UserSession from "../../resource/UserSession";

const Logout = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    // simply navigate 
    useEffect(() => {

        // clear redux
        dispatch({ type: 'USER_LOGOUT' })
        UserSession.doLogout()
        history.replace('/');//eg.history.push('/login');
        
        return <></>
    }, [])

    return null
}

export default Logout