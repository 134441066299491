
import { v4 as uuid } from "uuid";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listDashBoardContProv, listDashBoardPublishers } from "../../../../redux/actions/DashBoardActions";
import { Link } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert';

const products = [
  {
    id: uuid(),
    name: "Dropbox",
    imageUrl: "/static/images/products/product_1.png",
    updatedAt: moment().subtract(2, "hours"),
  },
  {
    id: uuid(),
    name: "Medium Corporation",
    imageUrl: "/static/images/products/product_2.png",
    updatedAt: moment().subtract(2, "hours"),
  },
  {
    id: uuid(),
    name: "Slack",
    imageUrl: "/static/images/products/product_3.png",
    updatedAt: moment().subtract(3, "hours"),
  },
  {
    id: uuid(),
    name: "Lyft",
    imageUrl: "/static/images/products/product_4.png",
    updatedAt: moment().subtract(5, "hours"),
  },
  {
    id: uuid(),
    name: "GitHub",
    imageUrl: "/static/images/products/product_5.png",
    updatedAt: moment().subtract(9, "hours"),
  },
];

function LatestProducts(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listDashBoardPublishers());
    return () => {
      //
    };
  }, []);

  const dashboardPublisherGet = useSelector(state => state.dashboardPublishers)
  const { loadingDashboardPub, DashboardPublishers, errorDashPub } = dashboardPublisherGet

  {
    loadingDashboardPub && dashboardPublisherGet ?
      console.log("data is loading")
      :
      console.log("data", DashboardPublishers)
  }



  return (
    <Card {...props}>
      <CardHeader
        subtitle={`${products.length} in total`}
        title="Subscribers"
      />
      <Divider />

      {
        loadingDashboardPub && dashboardPublisherGet ?
          <div style={{
            alignSelf: 'center',
            padding: 20
          }}>
            Loading ...
        </div>
          :

          (DashboardPublishers && DashboardPublishers.data && DashboardPublishers.data.length) ?
            <>
              <List>
                {dashboardPublisherGet && DashboardPublishers && DashboardPublishers.data && DashboardPublishers.data.slice(0, 7).map((product, i) => (
                  <ListItem divider={i < products.length - 1} key={i}>
                    <ListItemAvatar>
                      <img
                        alt={product.pub_name_text}
                        src={product.pub_logo}
                        style={{
                          // height: 48,
                          width: 48,
                          marginRight: 10
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={product.pub_name_text}
                      secondary={` ${product.pub_email_text}`}
                    />
                    {/* <IconButton edge="end" size="small">
                  <MoreVertIcon />
                </IconButton> */}
                  </ListItem>
                ))}
              </List>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 4,
                }}
                style={{
                  padding: 10
                }}
              >
                <Button
                  color="primary"
                  endIcon={<ArrowRightIcon />}
                  size="small"
                  variant="text"
                >
                  <Link to="/publisher-content/users" style={{ textDecoration: "none", }}>
                    View all
                  </Link>
                </Button>
              </Box>
            </>
            :

            <>
              <Alert severity="warning">Your content has no subscribers yet!</Alert>
            </>


      }
    </Card>
  );
}

export default LatestProducts;
