import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Typography,
    ButtonGroup,
    Chip
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Search as SearchIcon } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import MapAPI from '../../../http/curriculummap';
import { useDispatch, useSelector } from 'react-redux';
import { filterMaps, listCurriculuFilterGet, listCurriculumGet, postNewCurriculum } from '../../../redux/actions/curriculumMapAction';
import { CURRICULUMMAP_ADD_SUCCESS } from '../../../redux/constants/CurriculumMapConstants';
import { listBoardGet } from '../../../redux/actions/boardDetailsAction';
import { listInstGet } from '../../../redux/actions/InstituteDetailsAction';
import { listSubGet } from '../../../redux/actions/subjectsAction';
import { listChpGet } from '../../../redux/actions/chapterAction';
import { listStdGet } from '../../../redux/actions/standardAction';
import Cookie from 'js-cookie';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { listSingleEventPost } from '../../../redux/actions/Trainer/EventsPost';
import { listAllEventsFilGet, listAllEventsGet, listAllEventsSrcGet } from '../../../redux/actions/Trainer/EventsGet';
import { ContactsOutlined } from '@material-ui/icons';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase";
import Constants from "../../../resource/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import TrainTeachVideoPage from '../TrainTeachVideoPage';


const config = {
    apiKey: Constants.Application.FIREBASE.API_KEY,
    authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
    //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
    storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 315,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const useStyles1 = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // width: 200,
    },
}));


const TrainerVideoHeader = (props) => {
    const {videoParams} = props
    const classes = useStyles();
    const classes1 = useStyles1()
    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
    });
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [tags, setTags] = React.useState('')
    const [instType, setInstType] = useState("")
    const [board, setBoard] = useState('')
    const [clrStd, setClrStd] = useState('')
    const [sub, setSub] = useState('')
    const [instType2, setInstType2] = useState("")
    const [boardType2, setBoardType2] = useState("")
    const [subjectType2, setSubjectType2] = useState("")
    const [standardType2, setStandardType2] = useState("")
    const [pubId, setPubId] = useState(2)
    const [desc, setDesc] = useState("")
    const [agenda, setAgenda] = useState("")
    const [openSnack, setOpenSnack] = React.useState(false);
    const [searchTxt, setSearchTxt] = React.useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [selectedTime, setSelectedTime] = React.useState(new Date())
    const [selectedDate1, setSelectedDate1] = React.useState(new Date());
    const [selectedTime1, setSelectedTime1] = React.useState(new Date())
    const [formatedTime, setFormatedTime] = React.useState()
    const [formatedTime1, setFormatedTime1] = React.useState()
    const [liveStatus, setLiveStatus] = React.useState(false)
    const [showBtnGrp, setShowBtnGrp] = React.useState(true)
    const [showChip, setShowChip] = React.useState(false)
    const [ImageDownloadUrls, setImageDownloadUrls] = React.useState("")
    const [videoDownloadUrls, setVideoDownloadUrls] = React.useState("")
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [progressVid, setProgressVid] = useState(0);
    const [isUploadingVid, setIsUploadingVid] = useState(false);
    const [uploadProgressVid, setUploadProgressVid] = useState(0);
    const [showUpldVid, setShowUpldVid] = useState(false)



    const boardDetailsGet = useSelector(state => state.boardDetails)
    const { loading, boardDetails, error } = boardDetailsGet

    const instDetailsGet = useSelector(state => state.instDetails)
    const { loadingInst, instDetails, errorInst } = instDetailsGet

    const subDetailsGet = useSelector(state => state.subDetails)
    const { loadingSub, subDetails, errorSub } = subDetailsGet

    const stdDetailsGet = useSelector(state => state.stdDetails)
    const { loadingStd, stdDetails, errorStd } = stdDetailsGet

    const mapList = useSelector(state => state.curriculumMap.CurriculumMap)

    // const instDetails = useSelector(state => state.instDetails.instDetails)
    // const boardDetails = useSelector(state => state.boardDetails.boardDetails)
    // const subDetails = useSelector(state => state.subDetails.subDetails)
    // const stdDetails = useSelector(state => state.stdDetails.stdDetails)



    console.log("selected date is trainslistToolbar", selectedDate)
    console.log("selected date is trainslistToolbar", selectedDate)
    console.log("selected time is trainslistToolbar", selectedTime)
    console.log("selected  time is trainslistToolbar", selectedTime1)


    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleDateChange = (date) => {
        let formatedDate = JSON.stringify(date).slice(1, 11)
        setSelectedDate(formatedDate);
    };

    const handleTimeChange = (date) => {
        let format = date.toString().slice(16, 21)
        console.log("formated Time at handle date change", date.toString())
        setFormatedTime(format)
        setSelectedTime(date);
    };


    const handleDateChange1 = (date) => {
        let formatedDate = JSON.stringify(date).slice(1, 11)
        setSelectedDate1(formatedDate);
    };

    const handleTimeChange1 = (date) => {
        let format = date.toString().slice(16, 21)
        setFormatedTime1(format)
        setSelectedTime1(date);
    };

    function setPublisher() {
        const userId = Cookie.get('kgtopg.partner.user.id')
        setPubId(userId)

        console.log(`pubId is ${userId}`)
    }


    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const handleSearchChanged = (event) => {
        const { name, value } = event.target

        setSearchTxt(value)
        dispatch(listAllEventsSrcGet(value))
        // console.log(`searching for map title ${value}`)

        // // now filter maps from curriculum maps

        // let filteredMaps = []
        // // console.log(`filtered maps to redux original ${JSON.stringify(mapList)}`)
        // if (mapList && mapList.data) {
        //   mapList.data.map((map, index) => {
        //     const title = map.map_title_text.toLowerCase()
        //     const val = value.toLowerCase()

        //     console.log(`includes check title ${title}`)
        //     console.log(`includes check val ${val}`)
        //     if (title.includes(val)) {
        //       filteredMaps.push(map)
        //     }
        //   })
        // }

        // console.log(`filtered maps to redux ${JSON.stringify(filteredMaps)}`)

        // // update filteredMaps
        // dispatch(filterMaps(filteredMaps))

    }

    const handleInstTypeChange = (event) => {
        const { name, value } = event.target;

        setInstType(value);
        dispatch(listBoardGet(value))
    }

    const handleBoardChange = (event) => {
        const { name, value } = event.target;

        setBoard(value);
        dispatch(listSubGet(value))
        dispatch(listStdGet(instType, value))
    }

    const handleClrStdChange = (event) => {
        const { name, value } = event.target;

        setClrStd(value);
    }

    const handleSubChange = (event) => {
        const { name, value } = event.target;

        setSub(value);
    }


    const handleTagsChange = (event) => {
        const { name, value } = event.target;

        setTags(value);
    }


    const handleClick = () => {
        setOpen2(true);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen2(false);
    };

    const handleTitleChange = (event) => {
        const { name, value } = event.target;
        console.log("title changed is", value)
        setTitle(value);
    }


    const handlAgendaChange = (event) => {
        const { name, value } = event.target;
        setAgenda(value)
    }
    // const mapList = props.mapList;
    const currMap = props.currMap;

    const dispatch = useDispatch()

    const handleCreateEvent = async () => {

        if (!title) {
            // alert('Please enter title..');
            setOpenSnack(true);
            return;
        }

        const body = {
            publisher_id: pubId,
            event_name: title,
            institute_type_id: instType,
            institute_board_id: board,
            classroom_std: clrStd,
            subject_id: sub,
            event_des: desc,
            event_image: ImageDownloadUrls,
            event_agenda: agenda,
            event_tags: tags,
            date: selectedDate,
            start_time: selectedDate + " " + formatedTime,
            end_time: selectedDate1 + " " + formatedTime1,
            is_live: liveStatus == true ? 1 : 0,

        }

        console.log("body at create event", body)
        dispatch(listSingleEventPost(body))

    };


    const singleEvePost = useSelector(state => state.singleEventPost)
    const { loadingSinglePost, singleEvntPostData, errorSingleEventPost } = singleEvePost



    {
        loadingSinglePost && singleEvntPostData ?

            console.log("data is loading ...")
            :
            console.log("data at trainListToolBar", singleEvntPostData)

    }

    // if (singleEvntPostData) {
    //   dispatch(listAllEventsGet())
    // }


    const handlDescChange = (event) => {
        const { name, value } = event.target
        setDesc(value)
    }

    const handleBoardType2Change = (event) => {
        console.log('called handleBoardChange')
        console.log(`new board  ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        console.log(`board selected value ${value}`)

        setBoardType2(value)
        dispatch(listStdGet(instType, value))
        dispatch(listSubGet(value))

    }

    const handleSubType2Change = (event) => {
        console.log('called handleSubChange')
        console.log(`new subject ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        setSubjectType2(value)
        // dispatch(listAllEventsFilGet(instType2, boardType2, standardType2, value))
        videoParams(instType2,boardType2,standardType2,value)

    }

    const handleInstType2Change = (event) => {

        console.log('called handleInstChange')
        console.log(`new institute type ${event}`)
        const name = event.target.name;
        const value = event.target.value;
        // setState({
        // ...state,
        // [name]: event.target.value,
        // });
        dispatch(listBoardGet(value))
        setInstType2(value)


    }

    const handleCloseSnack = () => {
        setOpenSnack(false)
    }

    const handleStdType2Change = (event) => {

        console.log('called handleStdChange')
        console.log(`new standard type ${event}`)
        const name = event.target.name;
        const value = event.target.value;
        // setState({
        // ...state,
        // [name]: event.target.value,
        // });

        setStandardType2(value)


    }





    async function getData() {
        await new Promise((resolve, reject) => {
            dispatch(listInstGet())

            resolve()
        })


    }


    const handleDeleteLive = () => {
        setShowChip(false)
        setShowBtnGrp(true)
        setLiveStatus(false)
        console.info('You clicked the delete icon.');
    };

    const handleDeleteRec = () => {
        setShowChip(false)
        setShowBtnGrp(true)
        setLiveStatus(false)
        setShowUpldVid(false)
        console.info('You clicked the delete icon.');
    };



    const LiveStream = () => {
        setShowChip(true)
        setLiveStatus(true)
        setShowBtnGrp(false)
    }

    const RecordedVideo = () => {
        setShowChip(true)
        setLiveStatus(false)
        setShowBtnGrp(false)
        setShowUpldVid(true)
    }


    const handleUploadStart = () => {
        setIsUploading(true);
        setUploadProgress(0);
    };

    const handleUploadError = (error) => {
        setIsUploading(false);
    };


    const handleUploadSingleSuccess = async (filename) => {
        const downloadURL = await firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL();

        console.log("download url", downloadURL);

        if (downloadURL) {
            setProgress(0);
        }
        // setFileNames(filename)
        setImageDownloadUrls(downloadURL);
        // setUploadProgress(100)
        // setIsUploading(false)
    };
    const handleProgress = (progress) => {
        setProgress(progress);
    };




    const handleUploadStartVid = () => {
        setIsUploadingVid(true);
        setUploadProgressVid(0);
    };

    const handleUploadErrorVid = (error) => {
        setIsUploadingVid(false);
    };


    const handleUploadSingleSuccessVid = async (filename) => {
        const downloadURL = await firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL();

        console.log("download url", downloadURL);

        if (downloadURL) {
            setProgressVid(0);
        }
        // setFileNames(filename)
        setVideoDownloadUrls(downloadURL);
        // setUploadProgress(100)
        // setIsUploading(false)
    };

    const handleProgressVid = (progress) => {
        setProgressVid(progress);
    };

    useEffect(() => {
        // set up logged in publisher
        setPublisher()
        // get boards, subjects and standards
        getData()

        return <></>
    }, [])


    console.log("startDate at trainlist toolbar", startDate)

    const allEventsFil = useSelector(state => state.allEventsFilGet)
    const { loadingAllFilEvents, allEventsFilGetData, errorAllFilEvents } = allEventsFil

    const allEventsSrc = useSelector(state => state.allEventsSrcGet)
    const { loadingAllSrcEvents, allEventsSrcGetData, errorAllSrcEvents } = allEventsSrc

    {
        loadingAllFilEvents && allEventsFil ?
            console.log("data is loading")
            :
            console.log("loading all events filter data ", allEventsFilGetData)
    }

    {
        loadingAllSrcEvents && allEventsSrc ?
            console.log("data is loading")
            :
            console.log("loading all events filter data ", allEventsSrcGetData)
    }


    return (
        <Box {...props}>


            {/* Top heading bar  */}
            <div style={{
                display: 'flex',
                marginTop: 10,
                fontFamily: "Poppins, Helvetica, sans-serif",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
            }}
            >
                {/* Trainer Title */}
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, }}>
                    <RecordVoiceOverOutlinedIcon style={{ fonSize: 50, marginRight: 10 }} />
                    <Typography
                        className={classes.title} id="tableTitle" component="div"
                        variant="h2"
                        style={{
                            fontFamily: "Poppins, Helvetica, sans-serif"
                        }}>
                        Trainer
                    </Typography>
                </div>

                <Paper style={{ width: "50%" }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            fontSize="small"
                                            color="action"
                                        >
                                            <SearchIcon style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}

                            placeholder="Search for Created Events"
                            variant="outlined"
                            style={{ fontFamily: "Poppins, Helvetica, sans-serif", width: '100%' }}
                            onChange={handleSearchChanged}
                        />
                    </div>
                </Paper>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {/* create  add videos button  */}
                    <TrainTeachVideoPage />
                </div>

            </div>







            <Paper>



                {/* institute type dropdown */}
                <div style={{ display: "flex", width: "100%", alignItems: "flex-start", padding: 10, marginTop: 10, marginBottom: 10 }}>
                    <div >
                        {instDetails ?
                            <div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 16, color: "#464E5F" }}>Institute Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={instType2}
                                        onChange={handleInstType2Change}
                                        label="Institute Type"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >
                                        {loadingInst && instDetailsGet ?
                                            console.log("loading trueeee")
                                            :
                                            (instDetails && instDetails.data && instDetails.data.length != 0) ?

                                                instDetails.data.map((list, index) => {
                                                    return (
                                                        <MenuItem key={index} value={list.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_type_name}</MenuItem>


                                                    )
                                                })
                                                :
                                                <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Institutes</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </div>

                            :

                            <div style={{ display: "flex" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Institute Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={instType2}
                                        onChange={handleInstType2Change}
                                        label="Institute Type"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >
                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Institutes</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        }
                    </div>



                    {/* board details dropdown */}
                    <div >

                        {boardDetailsGet && boardDetails && boardDetails.length != 0 && boardDetails.data ?

                            <div style={{ display: "flex", }}>
                                <FormControl variant="outlined" className={classes.formControl}>

                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Board</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={boardType2}
                                        onChange={handleBoardType2Change}
                                        label="Board"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >
                                        {(loading && boardDetailsGet) ?
                                            console.log("loading trueeee")
                                            :
                                            (boardDetails && boardDetails.length != 0) ?

                                                boardDetails.data.map((list, index) => {
                                                    return (
                                                        <MenuItem key={index} value={list.institute_board_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>

                                                    )
                                                })
                                                :
                                                <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            :
                            <div style={{ display: "flex", }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Board</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={boardType2}
                                        onChange={handleBoardType2Change}
                                        label="Board"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >
                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Boards </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                        }

                    </div>


                    {/* standards */}
                    <div>
                        {stdDetailsGet ?
                            <div style={{ display: "flex", }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Standard</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={standardType2}
                                        onChange={handleStdType2Change}
                                        label="Standard"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >


                                        {loadingStd && stdDetailsGet ?
                                            console.log("loading trueeee")
                                            :
                                            stdDetails.length != 0 ?

                                                stdDetails.data.map((list, index) => {
                                                    return (
                                                        <MenuItem key={index} value={list.id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.standard}</MenuItem>

                                                    )
                                                })
                                                :
                                                <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </div>

                            :
                            <div style={{ display: "flex", }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Standard</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={standardType2}
                                        onChange={handleStdType2Change}
                                        label="Standard"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >
                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Standards</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        }
                    </div>


                    {/* subjects */}

                    <div>
                        {subDetails ?
                            <div style={{ display: "flex", }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Subject</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={subjectType2}
                                        onChange={handleSubType2Change}
                                        label="Subject"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >
                                        {loadingSub && subDetails ?
                                            <MenuItem key={0} value="Please Select Board" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}> Please Select Board  </MenuItem>
                                            :
                                            (subDetails && subDetails.data && subDetails.data.subjects.length != 0) ?
                                                subDetails.data.subjects.map((list, index) => {
                                                    return (
                                                        <MenuItem key={index} value={list.subject_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.subject_name}</MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            :
                            <div style={{ display: "flex", }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Subject</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={subjectType2}
                                        onChange={handleSubType2Change}
                                        label="Subject"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >
                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Subjects </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        }
                    </div>
                </div>
            </Paper>

        </Box >
    )
};

export default TrainerVideoHeader;
