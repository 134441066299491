import axios from "axios";
import Constants from "../../resource/Constants"
import { TRASHDETAILSPOST_LIST_FAIL, TRASHDETAILSPOST_LIST_REQUEST, TRASHDETAILSPOST_LIST_SUCCESS, TRASHDETAILSRESTORE_LIST_FAIL, TRASHDETAILSRESTORE_LIST_REQUEST, TRASHDETAILSRESTORE_LIST_SUCCESS, TRASHDETAILS_LIST_FAIL, TRASHDETAILS_LIST_REQUEST, TRASHDETAILS_LIST_SUCCESS } from "../constants/TrashBinConstants";
import { listEbooksGet } from "./GetAllFilesAction";
import Cookie from 'js-cookie';

const ProvId = Cookie.get('kgtopg.partner.user.id')

const listTrashBinPost = (id, status) => async (dispatch) => {

    try {
        console.log("id and status at trashbin action", id, status)

        dispatch({ type: TRASHDETAILSPOST_LIST_REQUEST })
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/move-trash/?book_id=${id}&status=${status}`)
        dispatch({ type: TRASHDETAILSPOST_LIST_SUCCESS, payload: data })
        if (status == 0) {
            dispatch(listEbooksGet())
        } else {
            const status = 0
            dispatch(listTrashBinGet(status))
        }
    } catch (error) {
        dispatch({ type: TRASHDETAILSPOST_LIST_FAIL, payload: error.message })
    }
}


const listTrashBinGet = (status) => async (dispatch) => {

    try {

        dispatch({ type: TRASHDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/trash-bin/?status=${status}&publisher_id=${ProvId}`)
        dispatch({ type: TRASHDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: TRASHDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listTrashBinRestore = (id) => async (dispatch) => {

    try {
        dispatch({ type: TRASHDETAILSRESTORE_LIST_REQUEST })
        // const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/inst-type-board/?type_id=${type_id}`)
        // dispatch({ type: TRASHDETAILSRESTORE_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: TRASHDETAILSRESTORE_LIST_FAIL, payload: error.message })

    }
}


export { listTrashBinGet, listTrashBinPost, listTrashBinRestore }