import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Route, Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";

import Cookie from 'js-cookie';
import AuthAPI from '../../../../http/auth';
import { useDispatch, useSelector } from 'react-redux';
import { signinAuth } from '../../../../redux/actions/UserAuthAction';
import Constants from '../../../../resource/Constants';
import { CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Copyright from '../../../user/slots/Copyright';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl, { useFormControl } from '@mui/material/FormControl';


function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright1() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function MyFormHelperText(props) {
    const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
        if (focused) {
            return 'Set this email as primary';
        }

        return 'Set this email as primary';
    }, [focused]);

    return <FormHelperText style={{
        color: 'steelblue'
    }}
    
    onClick={() => {
        props.reverseEmails()
    }}>{helperText}</FormHelperText>;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default function ChangeEmailPub() {

    const userId = Cookie.get("kgtopg.partner.user.id");
    const userName = Cookie.get("kgtopg.partner.user.name");
    const userEmail = Cookie.get("kgtopg.partner.user.email");
    const userPhoto = Cookie.get("kgtopg.partner.user.photo");
    const userPhone = Cookie.get("kgtopg.partner.user.phone");
    const userRole = Cookie.get("kgtopg.partner.user.role");
    const userLogin = Cookie.get("kgtopg.partner.user.login");
    const accname = Cookie.get("kgtopg.partner.user.accname");
    const accnum = Cookie.get("kgtopg.partner.user.accnum");
    const accifsc = Cookie.get("kgtopg.partner.user.accifsc");
    const bankbranch = Cookie.get("kgtopg.partner.user.bankbranch");
    const bankname = Cookie.get("kgtopg.partner.user.bankname");
    const pubuserid = Cookie.get("kgtopg.partner.user.pubuserid");
  

    const classes = useStyles();
    const [email, setEmail] = useState(userEmail);
    const [email2, setEmail2] = useState();
    const history = useHistory();

    const [token, setToken] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [openErrSnack, setOpenErrSnack] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const handleSnackClose = () => {
        setOpenSnack(false)
    }

    const handleErrSnackClose = () => {
        setOpenErrSnack(false)
    }

    const reverseEmails = () => {
        setEmail(email2)
        setEmail2(email)
    }

    const changeEmail = async () => {

        setIsLoading(true)
        if (email && email2) {
            const body = {
                token: token,
                user_email: email
            }

            try {
                // CALL API
                showSuccessSnack(true)
                setDisabled(true)
            } catch (e) {
                console.log(`exception authenticating user ${e.message}`)
            }
        } else {
            showErrSnack()
        }

        setIsLoading(false)

    }

    const changeEmail2 = async () => {

        setIsLoading(true)
        if (email2) {
            const body = {
                token: token,
                user_email: email2
            }

            try {
                // CALL API
                showSuccessSnack(true)
            } catch (e) {
                console.log(`exception authenticating user ${e.message}`)
            }
        } else {
            showErrSnack()
        }

        setIsLoading(false)

    }

    const showSuccessSnack = () => {
        setOpenSnack(true)
    }

    const showErrSnack = () => {
        setOpenErrSnack(true)
    }

    const handleEmailChanged = (event) => {
        const { name, value } = event.target;
        setEmail(value);
    }

    const handleEmail2Changed = (event) => {
        const { name, value } = event.target;
        setEmail2(value);
    }

    // const logo = 'https://kgtopg.com/schooler/img/logo_small.png';


    const dispatch = useDispatch()

    const getUserAuthResp = useSelector(state => state.userSigninAuth)
    const { loadingAuth, userInfo, errorAuth } = getUserAuthResp


    {
        loadingAuth && userInfo ?
            <></>
            :
            console.log("authentication response", userInfo)

    }

    useEffect(() => {

        const userLoggedIn = Cookie.get('kgtopg.partner.user.login')
        const userRole = Cookie.get('kgtopg.partner.user.role')
        const userToken = Cookie.get('kgtopg.partner.user.token')
        const query = new URLSearchParams(window.location.search);
        const tokenId = query.get("token");

        const tokenChk = tokenId ? tokenId : userToken
        setToken(tokenChk)


        return () => {
            //
        }

    }, [])


    return (
        <Container component="main" maxWidth="xs">
            {/* <CssBaseline /> */}
            <div className={classes.paper}>


                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        // defaultValue={userEmail}
                        value={email}
                        onChange={handleEmailChanged}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                // doLogin()
                            }
                        }}
                        margin="normal"
                        required
                        fullWidth
                        type="email"
                        id="email1"
                        label="Primary Email"
                        name="Primary Email"
                        
                    />
                    <TextField
                        variant="outlined"
                        // defaultValue={email2}
                        value={email2}
                        onChange={handleEmail2Changed}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                // doLogin()
                            }
                        }}
                        margin="normal"
                        required
                        fullWidth
                        name="Secondary Email"
                        label="Secondary Email"
                        type="email"
                        id="email2"
                        // disabled={true}
                    />

                    <Button>
                        <MyFormHelperText
                        reverseEmails={reverseEmails} />
                    </Button>


                    <Button
                        disabled={disabled}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => changeEmail()}
                    >
                        {(!isLoading) ?
                            <span>Save Changes</span>
                            :
                            <CircularProgress />
                        }
                    </Button>
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                        <Alert2 onClose={handleSnackClose} severity="success">
                            You will receive a confirmation email to the primary email specified to finish this action
                        </Alert2>
                    </Snackbar>

                    <Snackbar open={openErrSnack} autoHideDuration={6000} onClose={handleErrSnackClose}>
                        <Alert2 onClose={handleErrSnackClose} severity="warning">
                            There was a problem updating emails!
                        </Alert2>
                    </Snackbar>

                </form>
            </div>

            {/* <Box mt={8}>
                <Copyright />
            </Box> */}

        </Container>
    );
}