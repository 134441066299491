import { v4 as uuid } from 'uuid';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { listDashBoardContProv, listDashBoardPublishers } from '../../../../redux/actions/DashBoardActions';
import Alert from '@material-ui/lab/Alert';

const products = [
  {
    id: uuid(),
    name: 'Dropbox',
    imageUrl: '/static/images/products/product_1.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Medium Corporation',
    imageUrl: '/static/images/products/product_2.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Slack',
    imageUrl: '/static/images/products/product_3.png',
    updatedAt: moment().subtract(3, 'hours')
  },
  {
    id: uuid(),
    name: 'Lyft',
    imageUrl: '/static/images/products/product_4.png',
    updatedAt: moment().subtract(5, 'hours')
  },
  {
    id: uuid(),
    name: 'GitHub',
    imageUrl: '/static/images/products/product_5.png',
    updatedAt: moment().subtract(9, 'hours')
  }
];

function LatestProducts(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listDashBoardContProv());
    return () => {
      //
    };
  }, []);

  const dashboardContProvGet = useSelector(state => state.dashboardContProv)
  const { loadingDashboardCont, DashboardContProv, errorDashCont } = dashboardContProvGet

  {
    loadingDashboardCont && dashboardContProvGet ?
      console.log("data is loading")
      :
      console.log("data at DashboardContProv", DashboardContProv)
  }



  return (
    <Card {...props}>
      <CardHeader
        subtitle={`${products.length} in total`}
        title="Subscribers"
      />
      <Divider />
      {
        loadingDashboardCont && dashboardContProvGet ?
          <div
            style={{
              padding: 20
            }}>
            Loading ...
        </div>
          :

          (DashboardContProv && DashboardContProv.data && DashboardContProv.data.length) ?

            <>
              <List>
                {dashboardContProvGet && DashboardContProv && DashboardContProv.data && DashboardContProv.data.slice(0, 7).map((product, i) => {
                  let img = product.prov_cover_url
                  if (!img)
                    img = 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'
                  return (
                    <ListItem divider={i < products.length - 1} key={i}>
                      <ListItemAvatar style={{ width: 50, textOverflow: "ellipsis" }}>
                        <img
                          alt={(product.prov_name_text).slice(0, 5)}
                          src={img}
                          style={{
                            // height: 48,
                            width: 48,
                            textOverflow: "ellipsis"

                          }}

                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={product.prov_name_text}
                        secondary={` ${product.prov_email_text}`}
                      />
                      <IconButton edge="end" size="small">
                        <MoreVertIcon />
                      </IconButton>
                    </ListItem>
                  )
                }
                )}
              </List>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
                style={{
                  padding: 10
                }}
              >
                <Button
                  color="primary"
                  endIcon={<ArrowRightIcon />}
                  size="small"
                  variant="text"
                >
                  View all
                </Button>
              </Box>

            </>
            :

            <>
              <Alert severity="warning">Your books have no subscribers yet!</Alert>
            </>

      }
      {/* <Divider /> */}

    </Card>

  )
}

export default LatestProducts;


