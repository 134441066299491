import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Search as SearchIcon } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AddCurriculumDialog from './slots/AddCurriculumDialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import MapAPI from '../../http/curriculummap';
import { useDispatch, useSelector } from 'react-redux';
import { filterMaps, listCurriculuFilterGet, listCurriculumGet, postNewCurriculum } from '../../redux/actions/curriculumMapAction';
import { CURRICULUMMAP_ADD_SUCCESS } from '../../redux/constants/CurriculumMapConstants';
import { listBoardGet } from '../../redux/actions/boardDetailsAction';
import { listInstGet } from '../../redux/actions/InstituteDetailsAction';
import { listSubGet } from '../../redux/actions/subjectsAction';
import { listChpGet } from '../../redux/actions/chapterAction';
import { listStdGet } from '../../redux/actions/standardAction';
import Cookie from 'js-cookie';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 315,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PubDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });


  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      {/* <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Select type</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option aria-label="None" value="" />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Published or in Draft</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Draft</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl> */}


      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Institute Type</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.age}
          onChange={handleChange}
          label="Institute Type"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >

          <option aria-label="None" value="" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Published or in Draft</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Draft</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl>
    </div>
  )
}

const ActDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      {/* <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Activity</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option aria-label="None" value="" />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl> */}


      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Board</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.age}
          onChange={handleChange}
          label="Activity"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >

          <option aria-label="None" value="" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl>
    </div>
  )
}

const SubDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      {/* <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Activity</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option aria-label="None" value="" />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl> */}


      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Subject</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.age}
          onChange={handleChange}
          label="Activity"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >

          <option aria-label="None" value="" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl>
    </div>
  )
}

const StdDD = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      {/* <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-age-native-simple">Activity</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'filled-age-native-simple',
          }}
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          <option aria-label="None" value="" />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl> */}


      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Standard</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.age}
          onChange={handleChange}
          label="Activity"
          style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
        >

          <option aria-label="None" value="" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />
          <option value={10} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Any Activity</option>
          <option value={20} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>All Activities</option>
          <option value={30} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Thirty</option>
        </Select>
      </FormControl>
    </div>
  )
}

const MapListToolbar = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [title, setTitle] = React.useState('');

  const [tags, setTags] = React.useState('')


  const [instType, setInstType] = useState("")
  const [board, setBoard] = useState('')
  const [clrStd, setClrStd] = useState('')
  const [sub, setSub] = useState('')

  const [instType2, setInstType2] = useState("")
  const [boardType2, setBoardType2] = useState("")
  const [subjectType2, setSubjectType2] = useState("")
  const [standardType2, setStandardType2] = useState("")
  const [pubId, setPubId] = useState(2)
  const [unitCount, setUnitCount] = useState(2)
  const [termNum,setTermNum] = useState(1)


  const boardDetailsGet = useSelector(state => state.boardDetails)
  const { loading, boardDetails, error } = boardDetailsGet

  const instDetailsGet = useSelector(state => state.instDetails)
  const { loadingInst, instDetails, errorInst } = instDetailsGet

  const subDetailsGet = useSelector(state => state.subDetails)
  const { loadingSub, subDetails, errorSub } = subDetailsGet

  const stdDetailsGet = useSelector(state => state.stdDetails)
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet

  const mapList = useSelector(state => state.curriculumMap.CurriculumMap)

  // const instDetails = useSelector(state => state.instDetails.instDetails)
  // const boardDetails = useSelector(state => state.boardDetails.boardDetails)
  // const subDetails = useSelector(state => state.subDetails.subDetails)
  // const stdDetails = useSelector(state => state.stdDetails.stdDetails)

  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = React.useState(false);
  const [searchTxt, setSearchTxt] = React.useState('');

  function setPublisher() {
    const userId = Cookie.get('kgtopg.partner.user.id')
    setPubId(userId)

    console.log(`pubId is ${userId}`)
  }


  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSearchChanged = (event) => {
    const { name, value } = event.target

    setSearchTxt(value)
    console.log(`searching for map title ${value}`)
    // console.log(`map list ${JSON.stringify(mapList)}`)
    // now filter maps from curriculum maps

    let filteredMaps = []
    // console.log(`filtered maps to redux original ${JSON.stringify(mapList)}`)
    if (mapList && mapList.data) {
      mapList.data.map((map, index) => {
        const title = map.map_title_text.toLowerCase()
        const val = value.toLowerCase()

        console.log(`includes check title ${title}`)
        console.log(`includes check val ${val}`)
        if (title.includes(val)) {
          filteredMaps.push(map)
        }
      })
    }

    console.log(`filtered maps to redux ${JSON.stringify(filteredMaps)}`)

    // update filteredMaps
    dispatch(filterMaps(filteredMaps))
  }

  const handleInstTypeChange = (event) => {
    const { name, value } = event.target;

    setInstType(value);
    dispatch(listBoardGet(value))
  }

  const handleBoardChange = (event) => {
    const { name, value } = event.target;

    setBoard(value);
    dispatch(listSubGet(value))
    dispatch(listStdGet(instType, value))
  }

  const handleClrStdChange = (event) => {
    const { name, value } = event.target;

    setClrStd(value);
  }

  const handleSubChange = (event) => {
    const { name, value } = event.target;

    setSub(value);
  }


  const handleTagsChange = (event) => {
    const { name, value } = event.target;

    setTags(value);
  }


  const handleClick = () => {
    setOpen2(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setTitle(value);
  }

  // const mapList = props.mapList;
  const currMap = props.currMap;

  const dispatch = useDispatch()

  const handleCreateMap = async () => {

    if (!title) {
      // alert('Please enter title..');
      setOpenSnack(true);
      return;
    }

    const body = {
      institute_type_id: instType,
      institute_board_id: board,
      classroom_std: clrStd,
      subject_id: sub,
      unit_count: unitCount,
      map_title_text: title,
      publisher_id: pubId,
      map_tags: tags,
      term_number:termNum,
    }

    const resp = await MapAPI.postSaveMap(body);
    console.log(`post save response ${JSON.stringify(resp)}`);
    const mapId = resp.data.data.id;

    // now call dispatch action to save to store and refresh

    const newObj = {
      map_id: mapId,
      publisher_id: pubId,
      subject_id: sub,
      draft_updated_date: "2021-01-01",
      is_published: true,
      institute_type_id: instType,
      institute_board_id: board,
      classroom_std: clrStd,
      unit_count: unitCount,
      map_title_text: title,
      map_tags: tags,
      term_number:termNum,
      published_date: "2021-01-01",
      Total_lesson_count: 15,
      units: []
    }

    // mapList.push(newObj);
    const newData = {
      newObj: newObj,
      mapList: mapList
    }

    console.log(`newData ${JSON.stringify(newData)}`)
    // call middleware thunk
    await new Promise((resolve, reject) => {
      dispatch(postNewCurriculum(newData))
      resolve()
    })

    // fetch all
    await new Promise((resolve, reject) => {
      dispatch(listCurriculumGet())
      resolve()
    })

    // close dialog
    handleClose()

    // show snackbar
    setOpenSuccessSnack(true)
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleCloseSuccessSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccessSnack(false);
  };

  const handleUnitChange = (event) => {
    const { name, value } = event.target

    setUnitCount(value)
  }

  const handleTermChange = (event) => {
    const { name, value } = event.target

    setTermNum(value)
  }

  const handleBoardType2Change = (event) => {
    console.log('called handleBoardChange')
    console.log(`new board  ${event}`)

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`)

    setBoardType2(value)
    dispatch(listStdGet(instType, value))
    dispatch(listSubGet(value))
    dispatch(listCurriculuFilterGet(instType2, value, standardType2, subjectType2))

  }

  const handleSubType2Change = (event) => {
    console.log('called handleSubChange')
    console.log(`new subject ${event}`)

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType2(value)

    dispatch(listCurriculuFilterGet(instType2, boardType2, standardType2, value))

  }

  const handleInstType2Change = (event) => {

    console.log('called handleInstChange')
    console.log(`new institute type ${event}`)
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });
    dispatch(listBoardGet(value))
    setInstType2(value)
    dispatch(listCurriculuFilterGet(value, boardType2, standardType2, subjectType2))


  }


  const handleStdType2Change = (event) => {

    console.log('called handleStdChange')
    console.log(`new standard type ${event}`)
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });

    setStandardType2(value)
    dispatch(listCurriculuFilterGet(instType2, boardType2, value, subjectType2))


  }

  async function getData() {
    await new Promise((resolve, reject) => {
      dispatch(listInstGet())

      resolve()
    })


  }

  useEffect(() => {
    // set up logged in publisher
    setPublisher()
    // get boards, subjects and standards
    getData()

    return <></>
  }, [])

  return (
    <Box {...props}>

      <div style={{
        display: 'flex',
        marginTop: 10,
        fontFamily: "Poppins, Helvetica, sans-serif",
        justifyContent: "space-between",
        alignItems: "center"
      }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {/* <LibraryBooksIcon /> */}
          <Typography
            className={classes.title} id="tableTitle" component="div"
            variant="h2"
            style={{
              marginTop: 10,

            }}>
            Curriculum Plans
          </Typography>
        </div>



        {/* SEARCH BAR */}
        <Paper>
          <div style={{ padding: 0, minWidth: 400 }}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}

              placeholder="Search for curriculum map"
              variant="outlined"
              style={{ fontFamily: "Poppins, Helvetica, sans-serif", width: '100%' }}
              onChange={handleSearchChanged}
            />
          </div>
        </Paper>


        <div>
          <Box
            display="flex"
            justifyContent="flex-end"

          >
            {/* <div>
              <Button style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} onClick={handleClick}>
                Import
              </Button>
              <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
                <Alert onClose={handleClose2} severity="info">
                  This feature is coming soon!
                </Alert>
              </Snackbar>
            </div>

            <div>
              <Button style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} onClick={handleClick}>
                Export
              </Button>
              <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
                <Alert onClose={handleClose2} severity="info">
                  This feature is coming soon!
                </Alert>
              </Snackbar>
            </div> */}

            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >
              Add curriculum map
            </Button>
          </Box>
        </div>
      </div>
      <div>















        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontWeight: "500" }}>Create New Curriculum Map</DialogTitle>
          <DialogContent style={{
            overflow: "auto",
            overflowX: "initial",
            scrollbarWidth: "thin",
          }}>
            <DialogContentText style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
              New Curriculum Map lets you setup a new curriculum unit sets with time and pacing.
            </DialogContentText>
            <TextField
            required
              autoFocus
              margin="dense"
              id="name"
              label="Curriculum Map Name"
              type="text"
              fullWidth
              defaultValue={title}
              onChange={handleTitleChange}
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            />

            <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}  required>
              <InputLabel htmlFor="filled-age-native-simple" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Institute Type</InputLabel>
              <Select
                native
                value={instType}
                onChange={handleInstTypeChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >
                <option key={-1} value={-1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Select</option>

                {(instDetails && instDetails.data) ?
                  instDetails.data.map((type, index) => {
                    console.log(`single inst type ${JSON.stringify(type)}`)
                    return (
                      <option key={index} value={type.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{type.institute_type_name}</option>
                    )
                  })
                  :
                  <option key={-2} value={-2}>No Types</option>
                }

              </Select>
            </FormControl>

            <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}  required>
              <InputLabel htmlFor="filled-age-native-simple" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Institute Board</InputLabel>
              <Select
                native
                value={board}
                onChange={handleBoardChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >

                <option key={-1} value={-1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Select</option>
                {boardDetails && boardDetails.data ?
                  boardDetails.data.map((brd, index) => {
                    return (
                      <option key={index} value={brd.institute_board_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{brd.institute_board_name}</option>
                    )
                  })
                  :
                  <option key={-2} value={-2}>No Boards</option>
                }

              </Select>
            </FormControl>

            <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}  required>
              <InputLabel htmlFor="filled-age-native-simple" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Classroom Standard</InputLabel>
              <Select
                native
                value={clrStd}
                onChange={handleClrStdChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >

                <option key={-1} value={-1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Select</option>
                {stdDetails && stdDetails.data && stdDetails.data ?
                  stdDetails.data.map((sb, index) => {
                    return (
                      <option key={index} value={sb.id} style={{ fontSize: 13, color: "#464E5F" }}>{sb.standard}</option>
                    )
                  })
                  :
                  <option key={-2} value={-2}>No Standards</option>
                }
              </Select>
            </FormControl>

            <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }} required>
              <InputLabel htmlFor="filled-age-native-simple" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Subject</InputLabel>
              <Select
                native
                value={sub}
                onChange={handleSubChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >

                <option key={-1} value={-1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Select</option>
                {subDetails && subDetails.data && subDetails.data.subjects ?
                  subDetails.data.subjects.map((sb, index) => {
                    return (
                      <option key={index} value={sb.subject_id} style={{ fontSize: 13, color: "#464E5F" }}>{sb.subject_name}</option>
                    )
                  })
                  :
                  <option key={-2} value={-2}>No Subjects</option>
                }

              </Select>
            </FormControl>


            <TextField
              autoFocus
              required
              defaultValue={termNum}
              onChange={handleTermChange}
              margin="dense"
              id="tags"
              label="Term Num"
              type="number"
              fullWidth
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            />

            <TextField
              autoFocus
              required
              defaultValue={unitCount}
              onChange={handleUnitChange}
              margin="dense"
              id="tags"
              label="Unit Count"
              type="number"
              fullWidth
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            />

            <TextField
              autoFocus
              required
              defaultValue={tags}
              onChange={handleTagsChange}
              margin="dense"
              id="tags"
              label="Tags"
              type="text"
              fullWidth
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
              Cancel
            </Button>
            <Button onClick={handleCreateMap} color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
              Create
            </Button>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
              <Alert onClose={handleCloseSnack} severity="warning">
                Please enter mandatory details!
              </Alert>
            </Snackbar>

            <Snackbar open={openSuccessSnack} autoHideDuration={6000} onClose={handleCloseSuccessSnack}>
              <Alert onClose={handleCloseSuccessSnack} severity="success">
                Curriculum Map saved successfully..
              </Alert>
            </Snackbar>
          </DialogActions>
        </Dialog>
      </div>





      <Paper style={{
        display: "flex", width: "100%", alignItems: "flex-start", padding: 10, marginTop: 20, marginBottom: 10, overflow: "auto",
        overflowX: "initial",
        scrollbarWidth: "thin",
      }}>

        <div >
          {instDetails ?

            <div>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Institute Type</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={instType2}
                  onChange={handleInstType2Change}
                  label="Institute Type"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  {loadingInst && instDetailsGet ?
                    console.log("loading trueeee")
                    :
                    (instDetails && instDetails.data && instDetails.data.length != 0) ?

                      instDetails.data.map((list, index) => {
                        return (
                          <MenuItem key={index} value={list.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_type_name}</MenuItem>


                        )
                      })
                      :

                      <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Institutes</MenuItem>

                  }

                </Select>
                {/* 
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Institute
    </span>
                </span> */}
              </FormControl>
            </div>

            :

            <div style={{ display: "flex" }}>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Institute Type</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={instType2}
                  onChange={handleInstType2Change}
                  label="Institute Type"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >

                  <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Institutes</MenuItem>


                </Select>
                {/* 
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Institute
</span>
                </span> */}
              </FormControl>
            </div>

          }
        </div>

        <div >

          {boardDetailsGet && boardDetails && boardDetails.length != 0 && boardDetails.data ?

            <div style={{ display: "flex", }}>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Board</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={boardType2}
                  onChange={handleBoardType2Change}
                  label="Board"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  {(loading && boardDetailsGet) ?
                    console.log("loading trueeee")
                    :
                    (boardDetails && boardDetails.length != 0) ?

                      boardDetails.data.map((list, index) => {
                        return (
                          <MenuItem key={index} value={list.institute_board_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>

                        )
                      })
                      :
                      <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                    // boardDetailsMock.map((list, index) => {
                    //     return (
                    //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                    //     )
                    // })

                  }


                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Board
                  </span>
                </span> */}
              </FormControl>
            </div>
            :
            <div style={{ display: "flex", }}>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Board</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={boardType2}
                  onChange={handleBoardType2Change}
                  label="Board"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >

                  <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Boards </MenuItem>

                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Board
                </span>
                </span> */}
              </FormControl>
            </div>

          }

        </div>


        <div>
          {/* standards */}

          {stdDetailsGet ?
            <div style={{ display: "flex", }}>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Standard</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={standardType2}
                  onChange={handleStdType2Change}
                  label="Standard"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >


                  {loadingStd && stdDetailsGet ?
                    console.log("loading trueeee")
                    :
                    stdDetails.length != 0 ?

                      stdDetails.data.map((list, index) => {
                        return (
                          <MenuItem key={index} value={list.id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.standard}</MenuItem>

                        )
                      })
                      :
                      <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                    // standardsMockData.map((list, index) => {
                    //     return (
                    //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.stand_name}</MenuItem>
                    //     )
                    // })
                  }

                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Standard
                    </span>
                </span> */}
              </FormControl>
            </div>

            :
            <div style={{ display: "flex", }}>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Standard</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={standardType2}
                  onChange={handleStdType2Change}
                  label="Standard"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >



                  <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Standards</MenuItem>



                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Standard
        </span>
                </span> */}
              </FormControl>
            </div>

          }
        </div>



        <div>
          {/* subjects */}

          {subDetails ?

            <div style={{ display: "flex", }}>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Subject</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectType2}
                  onChange={handleSubType2Change}
                  label="Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >


                  {loadingSub && subDetails ?

                    <MenuItem key={0} value="Please Select Board" style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}> Please Select Board  </MenuItem>

                    :
                    (subDetails && subDetails.data && subDetails.data.subjects.length != 0) ?

                      subDetails.data.subjects.map((list, index) => {
                        return (
                          <MenuItem key={index} value={list.subject_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.subject_name}</MenuItem>

                        )
                      })
                      :

                      <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                  }

                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Subject
                    </span>
                </span> */}
              </FormControl>
            </div>

            :
            <div style={{ display: "flex", }}>
              <FormControl variant="outlined" className={classes.formControl}>

                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F", }}>Subject</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectType2}
                  onChange={handleSubType2Change}
                  label="Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Subjects </MenuItem>
                </Select>
                {/* <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Subject
                  </span>
                </span> */}
              </FormControl>
            </div>

          }

        </div>



      </Paper>







      {/* <div style={{ display: "flex", width:"100%"}}>
        <Card>
          <CardContent>
            <div>
              <TextField

                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon style={{ fontFamily: "Poppins, Helvetica, sans-serif" }} />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}

                placeholder="Search for curriculum map"
                variant="outlined"
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
              <PubDD />
              <div style={{ width: 10 }} />
              <ActDD />
            </div>
          </CardContent>
        </Card>
      </div> */}
    </Box >
  )
};

export default MapListToolbar;
