import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Button, Divider, LinearProgress, Paper, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import logo from "../../../../assets/logo.png";
import publisherLogo from "../../../../assets/PSON.jpeg"
import McgrawHill from "../../../../assets/Mcgrawhill.jpg";
import "../../../../css/sidebar.css";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import Leftbar from "../Leftbar";
import Cookie from 'js-cookie';

import API from "../../../../http/http";
import PARTNERAPI from "../../../../http/httppartner";
import PUBLISHERAPI from "../../../../http/publisher";
import Copyright from "../../../user/slots/Copyright";


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue",
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        justifyContent: "space-between",
        minHeight: "100%",
        height: "100%",
        minWidth: "100%",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0,
    },
}));

export default function TermsConditionsCont() {
    const classes = useStyles();

    const [isLoadingscr, setIsLoadingscr] = useState(false);

    const userName = Cookie.get('kgtopg.partner.user.name')
    const userEmail = Cookie.get('kgtopg.partner.user.email')
    const userPhone = Cookie.get('kgtopg.partner.user.phone')

    const [name, setName] = useState(userName)
    const [email, setEmail] = useState(userEmail)
    const [phone, setPhone] = useState(userPhone)
    const [subject, setSubject] = useState("")
    const [desc, setDesc] = useState("")


    const dispatch = useDispatch();

    useEffect(() => {

        return () => {
            //
        };
    }, []);

    const postFeedback = async () => {

        const body = {
            email: email,
            name: name,
            contact_number: phone,
            subject: subject,
            description: desc
        }

        const response = await PUBLISHERAPI.postSaveFeedback(body)

        console.log("post feedback response", response)
    }

    return (
        <div className={classes.root}>
            {isLoadingscr == false ? (
                <>
                    {/* <Router> */}

                    <CssBaseline />
                    <LinearProgress />

                    <Leftbar />

                    <main className={classes.content}>
                        <Helmet>
                            <title> Terms &amp; Conditions | KGtoPG Partner</title>
                        </Helmet>

                        {/* <div className={classes.appBarSpacer} /> */}
                        <Container
                            className={classes.container}
                            style={{
                                minHeight: "auto",
                                backgroundColor: "#fff"
                            }}
                        >
                            <div style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center" }}>
                                <div style={{ color: "#19191b", fontWeight: 700, lineHeight: "46px", fontSize: "25px", margin: "32px", justifyContent: "left", fontFamily: "Poppins, Helvetica, sans-serif" }}>
                                    <Typography
                                    variant="h1">Terms &amp; Conditions </Typography>
                                </div>
                            </div>
                            <Divider />
                            <Paper elevation={3}>
                                <div style={{ padding: 5 }}>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>Privacy Policy </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            We value your trust. In order to honour that trust, KGTOPG adheres to ethical standards in gathering, using,
                                            and safeguarding any information you provide. Think and Learn Private Limited (operating under the brand name KGTOPG),
                                            is a leading edtech company, incorporated in India, for imparting learning. This privacy policy governs your use of the
                                            application 'KGTOPG’ - The Learning App' ('Application'), www.kgtopg.com ('Website') and the other associated
                                            applications, products, websites and services managed by the Company. Please read this privacy policy ('Policy')
                                            carefully before using the Application, Website, our services and products, along with the Terms of Use ('ToU')
                                            provided on the Application and the Website. Your use of the Website, Application, or services in connection
                                            with the Application, Website or products ('Services'), or registrations with us through any modes or usage of
                                            any products including through SD cards, tablets or other storage/transmitting device shall signify your acceptance
                                            of this Policy and your agreement to be legally bound by the same. If you do not agree with the terms of this Policy,
                                            do not use the Website, Application our products or avail any of our Services.
                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            User Provided Information
                                         </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            The Application/Website/Services/products obtains the information you provide when you download
                                            and register for the Application or Services or products. When you register with us, you generally
                                            provide (a) your name, age, email address, location, phone number, password and your ward's educational
                                            interests; (b) transaction-related information, such as when you make purchases, respond to any offers
                                            , or download or use applications from us; (c) information you provide us when you contact us for help;
                                            (d) information you enter into our system when using the Application/Services/products, such as while asking
                                            doubts, participating in discussions and taking tests. The said information collected from the users could be
                                            categorized as “Personal Information”, “Sensitive Personal Information” and “Associated Information”.
                                            Personal Information, Sensitive Personal Information and Associated Information (each as individually
                                            defined under this Information Technology (Reasonable security practices and procedures and sensitive
                                            personal data or information) Rules, 2011 (the “Data Protection Rules”)) shall collectively be referred
                                            to as 'Information' in this Policy. We may use the Information to contact you from time to time,
                                            to provide you with the Services, important information, required notices and marketing promotions.
                                            We will ask you when we need more information that personally identifies you (personal information)
                                            or allows us to contact you. We will not differentiate between who is using the device to access
                                            the Application, Website or Services or products, so long as the log in/access credentials match
                                            with yours. In order to make the best use of the Application/Website/Services/products and enable
                                            your Information to be captured accurately on the Application/Website/Services/products, it is
                                            essential that you have logged in using your own credentials. We will, at all times, provide the option
                                            to you to not provide the Personal Information or Sensitive Personal Information, which we seek from you.
                                            Further, you shall, at any time while using the Application/Services/products, also have an option to
                                            withdraw your consent given earlier to us to use such Personal Information or Sensitive Personal
                                            Information. Such withdrawal of the consent is required to be sent in writing to us at the contact
                                            details provided in this Policy below. In such event, however, the Company fully reserves the right
                                            not to allow further usage of the Application or provide any Services/products thereunder to you.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Automatically Collected Information
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            In addition, the Application/products/Services may collect certain information automatically, including,
                                            but not limited to, the type of mobile device you use, your mobile devices unique device ID, the
                                            IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers
                                            you use, and information about the way you use the Application/Services/products. As is true of most
                                            Mobile applications, we also collect other relevant information as per the permissions that you provide
                                            e use an outside credit card processing company to bill you for goods and services. These companies do
                                            not retain, share, store or use personally identifiable information for any other purpose.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>Use of your Personal Information</h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            We use the collected Information to analyse trends, to conduct research, to administer the Application/Services and products, to learn about each user's learning patterns and movements around the Application/Services and products and to gather demographic information and usage behaviour about our user base as a whole. Aggregated and individual, anonymized and non-anonymized data may periodically be transmitted to external service providers to help us improve the Application, products and our Services. We will share your information with third parties only in the ways that are described below in this Policy. We may use the individual data and behavior patterns combined with personal information to provide you with personalized content, and better your learning objectives. Third parties provide certain services which we may use to analyze the data and information to personalize, drive insights and help us better your experience or reach out to you with more value added applications, products, information and services. However, these third party companies do not have any independent right to share this information. We do not sell, trade or share your Information to any third party (except subsidiaries/affiliates of theCompany for related offerings) unless, we have been expressly authorized by you either in writing or electronically to do so. We may at times provide aggregate statistics about our customers, traffic patterns, and related site information to reputable third parties, however this information when disclosed will be in an aggregate form and does not contain any of your Personally Identifiable Information. BYJUS will occasionally send email notices, messages or contact you to communicate about our Services, products and benefits, as they are considered an essential part of the Services/products you have chosen. We may disclose Information:

                                            as required by law, such as to comply with a subpoena, or similar legal process;
                                            to enforce applicable ToU, including investigation of potential violations thereof;
                                            when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, address security or technical issues or respond to a government request;
                                            with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this Policy;
                                            to protect against imminent harm to the rights, property or safety of the Application/Website/ Think and Learn Private Limited or its users or the public as required or permitted by law;
                                            with third party service providers in order to personalize the Application/Website/Services/products for a better user experience and to perform behavioural analysis;
                                            Any portion of the Information containing personal data relating to minors provided by you shall be deemed to be given with the consent of the minor's legal guardian. Such consent is deemed to be provided by your registration with us.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Access to your Personal Information
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            We will provide you with the means to ensure that your Personal Information is correct and current.
                                            If you have filled out a user profile, we will provide an obvious way for you to access and change your profile
                                            from our Application/Services/Website/products. We adopt reasonable security measures to protect your password
                                            from being exposed or disclosed to anyone.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Cookies
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            We send cookies (small files containing a string of characters) to your computer, thereby uniquely
                                            identifying your browser. Cookies are used to track your preferences, help you login faster, and aggregated to
                                            determine user trends. This data is used to improve our offerings, such as providing more content in areas of
                                            greater interest to a majority of users. Most browsers are initially set up to accept cookies, but you can reset
                                            your browser to refuse all cookies or to indicate when a cookie is being sent. Some of our features and services
                                            may not function properly if your cookies are disabled.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Links
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            We may present links in a format that enables us to keep track of whether these links have been followed.
                                            We use this information to improve our customized content. Clicking on links may take you to sites outside our
                                            domain. We are not responsible for the privacy practices of other web sites. We encourage our users to be aware
                                            when they leave our site to read the privacy statements of each and every web site that collects personally
                                            identifiable information. This Privacy Policy applies solely to information collected by our Website.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Alerts
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            We may alert you by email or phone (through sms/call) to inform you about new service offerings of the Company
                                            and its subsidiaries/affiliates or other information which we feel might be useful for you, through theCompany
                                            or its subsidiaries/affiliates.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Public Forums
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            When you use certain features on our website like the discussion forums and you post or share your personal
                                            information such as comments, messages, files, photos, will be available to all users, and will be in the public
                                            domain. All such sharing of information is done at your own risk. Please keep in mind that if you disclose personal
                                            information in your profile or when posting on our forums this information may become publicly available.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Security
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            We are concerned about safeguarding the confidentiality of your Information. We provide physical,
                                            electronic, and procedural safeguards to protect Information we process and maintain. For example, we
                                            limit access to this Information to authorized employees only who need to know that information in order to
                                            operate, develop or improve our Application/Services/products/Website. Please be aware that, although we
                                            endeavor to provide reasonable security for information we process and maintain, no security system can prevent
                                            all potential security breaches.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            How Long Do We Retain User Data?

                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            Currently, we plan to retain user data while an account is active and for at least three years afterward.
                                            We may alter this practice according to legal and business requirements. For example, we may lengthen the retention
                                            period for some data if needed to comply with law or voluntary codes of conduct. Unless otherwise prohibited,
                                            we may shorten the retention period for some types of data if needed to free up storage space.
                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Log information
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            When you access our Website, our servers automatically record information that your browser sends whenever
                                            you visit a website. These server logs may include information such as your web request, internet protocol
                                            address, browser type, browser language, the date and time of your request and one or more cookies that may
                                            uniquely identify your browser.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            User communications
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            When you send an email or other communication to us, we may retain those communications in order to process
                                            your inquiries, respond to your requests and improve our Services.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Changes to this Statement
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            As the Company evolves, our privacy policy will need to evolve as well to cover new situations. You are
                                            advised to review this Policy regularly for any changes, as continued use is deemed approval of all changes.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Your Consent
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            We believe that every user of our Application/Services/products/Website must be in a position to provide
                                            an informed consent prior to providing any Information required for the use of the Application/Services
                                            /products/Website. By registering with us, you are expressly consenting to our collection, processing,
                                            storing, disclosing and handling of your information as set forth in this Policy now and as amended by us.
                                            Processing your information in any way, including, but not limited to, collecting, storing, deleting, using,
                                            combining, sharing, transferring and disclosing information, all of which activities will take place in India.
                                            If you reside outside India your information will be transferred, processed and stored in accordance with
                                            the applicable data protection laws of India.

                                        </p>
                                    </div>

                                    <div>
                                        <h1 style={{ color: "#19191b", fontSize: "24px", fontWeight: 700, lineHeight: 1, margin: 10 }}>
                                            Contact Information
                                        </h1>
                                        <p style={{ color: "#696871", fontSize: "21px", fontWeight: 300, lineHeight: "39px", margin: 10 }}>
                                            Our Grievance Officer shall undertake all reasonable efforts to address your grievances at the earliest
                                            possible opportunity. You may contact us at: Officer: Mr. M.Satish Reddy Address:141-B, Prashasan Nagar,
                                            Jubilee Hills Hyderabad, Telangana - 500033. Email us or Reach out to us on support@kgtopg.com,
                                            Call us :+91 40235 49009 ,+91 40235 49099 in case of any queries.

                                        </p>
                                    </div>

                                </div>
{/* 
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button variant="contained" color="secondary">
                                        Disagree
                                    </Button>
                                    <Button variant="contained" color="primary">
                                        Agree
                                    </Button>
                                </div> */}
                            </Paper>

                            <div style={{
                                padding: 20
                            }}>
                            <Copyright />
                            </div>

                        </Container>
                    </main>

                    {/* </Router> */}
                </>
            ) : (
                <></>
            )}
        </div>
    );
}
