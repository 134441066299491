/**
 * redux actions for
 * content
 */

import Cookie from 'js-cookie';
import PUBLISHERAPI from '../../http/publisher'

export const GET_CONTENT = 'GET_CONTENT_REQUEST';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAILURE = 'GET_CONTENT_FAILURE';

export const getContent = () => ({
    type: GET_CONTENT,
})

export const getContentSuccess = (content) => ({
    type: GET_CONTENT_SUCCESS,
    payload: content
})

export const getContentFailure = () => ({
    type: GET_CONTENT_FAILURE
})

// Combine all in a asynchronous thunk
export function fetchContent(instId,boardId,stdId,subId,chapId,proId) {
    const provId = Cookie.get('kgtopg.partner.cont.id')
    console.log("PROVIDER ID", provId)
    
    return async (dispatch) => {
        dispatch(getContent())

        try {
            const response = await PUBLISHERAPI.GetPublisherContent(instId,boardId,stdId,subId,chapId,provId)
            const data = response.data.data

            console.log("fetch content ================" + JSON.stringify(data))
            dispatch(getContentSuccess(data))
        } catch (error) {
            dispatch(getContentFailure())
        }
    }
}


// search by text
export function searchContent(q) {
    return async (dispatch) => {
        dispatch(getContent()) // set loading true..
        try {
            const response = await PUBLISHERAPI.searchContent(q)

            console.log(`response from search content ${JSON.stringify(response)}`)
            const data = response.data

            dispatch(getContentSuccess(data))
        } catch (error) {
            dispatch(getContentFailure())
        }
    }
}
