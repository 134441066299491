import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  epubmainListItems,
  epubsecondaryListItems,
} from "../ebook/SidebarList";
import TableModel from "./TableModel";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  Redirect,
  HashRouter,
  useHistory,
} from "react-router-dom";
import ContentMaking from "./ContentMaking";
// import Footer from "./Footer"
import { Avatar, Button } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import ContentMaking1 from "./ContentMaking1";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Settings from "./Settings";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import EpubView from "../ebook/EbookReaderScreen";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import SidebarContent from "../ebook/SidebarScreen";
import contentTable from "../ebook/MyBooksScreen";
import Contentlist from "../ebook/MyBooksScreen";
import ebookContentMaking from "../ebook/ebookAddingContentScreen";
import LessonPlan from "../ebook/LessonPlanScreen";
import Quiz from "../ebook/QuizScreen";
import Ebook from "../ebook/UploadEbookScreen";
import { useState } from "react";
import logo from "../../assets/logo.png";
import publisherLogo from "../../assets/PSON.jpeg";
import McgrawHill from "../../assets/Mcgrawhill.jpg";
import "../../css/sidebar.css";
import MapList from "../curriculummap/MapList";
import UnitList from "../curriculummap/UnitList";
import {
  ContactlessOutlined,
  SettingsInputAntennaTwoTone,
} from "@material-ui/icons";
import SidebarContent1 from "../ebook/ContentSidebar";
import ContentTable from "./ContentListTable";
import UploadEbookScreen from "./slots/UploadContent";
import PublisherListSideBar from "../ebook/PublisherListSideBar";
import SidebarContentCont from "./SidebarScreenCont";
import Leftbar from "./slots/Leftbar";
import { Helmet } from "react-helmet";

import Budget from "./slots/dashboard/Budget";
import LatestOrders from "./slots/dashboard/LatestOrders";
import LatestProducts from "./slots/dashboard/LatestProducts";
import Sales from "./slots/dashboard/Sales";
import TasksProgress from "./slots/dashboard/TasksProgress";
import TotalCustomers from "./slots/dashboard/TotalCustomers";
import TotalProfit from "./slots/dashboard/TotalProfit";
import TrafficByDevice from "./slots/dashboard/TrafficByDevice";
import { useDispatch, useSelector } from "react-redux";
import { listDashBoardContHead, listDashBoardHead } from "../../redux/actions/DashBoardActions";
import Copyright from "../user/slots/Copyright";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Cookie from 'js-cookie';
import Tour from 'reactour'
import SwitchAccountCont from "./slots/dashboard/SwitchAccountCont";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PUBLISHERAPI from '../../http/publisher'
import Constants from '../../resource/Constants'

const steps = [
  {
    selector: '[data-tut="first-step"]',
    content: 'The left bar navigates to various pages',
  },
  {
    selector: '[data-tut="second-step"]',
    content: 'The dashboard analytics gives usage stats performance in revenue',
  },
  // ...
];

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbar1: {
    paddingRight: 24,
    height: 40,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon1: {
    top: "45vh",
    bottom: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft:70,
    backgroundColor: "#1976d2",
    color: "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "blue",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    scrollbarColor: "green",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(4),
    justifyContent: "space-between",
    minHeight: "100%",
    // height: "100%",
    minWidth: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  large: {
    width: "80%",
    height: 50,
    borderRadius: 0,
  },
}));

function SimpleBadge(props) {
  const { notifications } = props
  const count = notifications && notifications.length
  return (
    <Badge badgeContent={count} color="secondary">
      <NotifMenu notifications={notifications}
      onReadNotif={props.onReadNotif} />
    </Badge>
  );
}

function NotifMenu(props) {
  const { notifications } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    props.onReadNotif()
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          padding: 0,
          margin: 0,
          width: 10,
          height: 10,
          minWidth: 10
        }}
      >
        <NotificationsIcon color="primary" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {notifications && notifications.length ?
          notifications.map(notif => {
            return (
              <MenuItem onClick={handleClose}>{notif.notif_title}</MenuItem>
            )
          })
          :
          <MenuItem>Nothing to show..</MenuItem>
        }
        {/* 
              <MenuItem onClick={handleClose}>Notification 1</MenuItem>
              <MenuItem onClick={handleClose}>Notification 2</MenuItem>
              <MenuItem onClick={handleClose}>Notitication 3</MenuItem> */}
      </Menu>
    </div>
  );
}

export default function ContentDashboard() {
  const classes = useStyles();
  const role = Cookie.get('kgtopg.partner.user.role')
  const [open, setOpen] = React.useState(true);
  const [showButton, setShowButton] = useState(false);
  const [AccordClose, setAccordClose] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showLogo, setShowLogo] = useState(true);
  const [state, setState] = React.useState({
    right: false,
  });

  const [showSideCont, SetShowSideCont] = useState(false);
  const [path, setPath] = useState("");
  const [readerData, setReaderData] = useState("");
  const [isLoadingscr, setIsLoadingscr] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [notifications, setNotifications] = useState(null)

  const handleDrawerOpen = () => {
    setOpen(true);
    setAccordClose(true);
    setShowLogo(true);
    SetShowSideCont(false);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setAccordClose(false);
    setShowLogo(false);
  };

  const handleTourOpen = () => {
    setIsTourOpen(true)
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const hideFab = (list, index) => {
    console.log("hiding the button", list, index);
    setShowButton(true);
    setIsLoadingscr(false);
    console.log("show button at parent", showButton);
  };

  const showSideContent = (status, path) => {
    console.log("sidecontent status", status);
    SetShowSideCont(status);
    if (status == true) {
      SetShowSideCont(status);
      setOpen(false);
      setShowLogo(false);
    } else {
      SetShowSideCont(status);
      setOpen(true);
      setShowLogo(true);
    }
    setPath(window.location.pathname);

    console.log(" callback function status", status, window.location.pathname);
  };

  console.log("windows path in dashboard", window.location.pathname);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  //  useEffect(() => { }, []);

  const sendDataToReaderScreen = (list, status) => {
    setIsLoading(true);
    console.log("sending..... Data to Reader Screen", list);
    if (list) {
      setReaderData(list);
      setIsLoading(false);
      setShowButton(status);
    }
  };


  const doGetNotif = async () => {

    // try with publisher type
    let loginTypeId = Constants.Application.LOGIN_TYPE_CONTPROV;
    const userId = Cookie.get("kgtopg.partner.user.id");
    let resp = await PUBLISHERAPI.getMyNotifications(loginTypeId, userId)

    console.log('notifications ', JSON.stringify(resp))
    let data = (resp.data && resp.data.data) ? resp.data.data : []
    console.log('first notif ', data)

    if (!data || data.length == 0) {
      // try with content provider type
      loginTypeId = Constants.Application.LOGIN_TYPE_CONTPROV;
      resp = await PUBLISHERAPI.getMyNotifications(loginTypeId, userId)

      console.log('notifications contprov ', JSON.stringify(resp))
      data = (resp.data && resp.data.data) ? resp.data.data : []
      console.log('second notif ', data)
    }

    setNotifications(data)

  }

  const doReadNotif = async () => {

    // try with publisher type
    let loginTypeId = Constants.Application.LOGIN_TYPE_CONTPROV;
    const userId = Cookie.get("kgtopg.partner.user.id");
    let resp = await PUBLISHERAPI.doReadNotifications(loginTypeId, userId)

    console.log('read notifications response ', JSON.stringify(resp))
    
    // setNotifications([])

}

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listDashBoardContHead());
    doGetNotif()

    return () => {
      //
    };
  }, []);

  const dashboardContDetailsGet = useSelector((state) => state.dashboardContDetails);
  const { loadingContDashboard, DashboardContDetails, errorDashboardCont } = dashboardContDetailsGet


  {
    loadingContDashboard && dashboardContDetailsGet
      ? console.log("no data is getting")
      : console.log("dashboardDetails ===== at content maker ", DashboardContDetails);
  }

  console.log("state reader data ", readerData);
  return (
    <>
      <div className={classes.root}>
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0
        }}>
          <LiveHelpIcon
            onClick={handleTourOpen} />
        </div>
        {isLoadingscr == false ? (
          <>
            {/* <Router> */}

            <CssBaseline />
            <LinearProgress />

            <div data-tut="first-step">

              <Leftbar />
            </div>

            <main className={classes.content}>
              <Helmet>
                <title> Dashboard | KGtoPG Partner</title>
              </Helmet>

              {/* <div className={classes.appBarSpacer} /> */}
              <Container
                className={classes.container}
                style={{
                  minHeight: "100%",
                }}
              >
                <Grid
                  container
                  spacing={3}
                  style={{
                    minHeight: "100%",
                  }}
                >
                  {/* Recent Orders */}
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        backgroundColor: "background.default",
                        minHeight: "100%",
                        py: 3,
                      }}
                    >
                      <Container maxWidth={false}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            alignContent: "center"
                          }}
                        >
                          <Typography
                            variant="h3"
                            style={{
                              marginTop: 10,
                              marginBottom: 10
                            }}>
                            Dashboard <Typography variant="caption"></Typography>
                          </Typography>
                          {/* {
                            role == "CONTENT_PROVIDER AND PUBLISHER"
                              ?
                              <div
                                style={{
                                  marginTop: 10,
                                  marginBottom: 10
                                }}
                              >
                                <SwitchAccountCont />
                              </div>
                              :
                              <></>
                          } */}



                          <SimpleBadge
                            onReadNotif={doReadNotif}
                            notifications={notifications} />

                        </div>


                        <div data-tut="second-step">
                          <Grid container spacing={3}>

                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                              {loadingContDashboard && dashboardContDetailsGet ? (
                                <Budget count={0} />
                              ) : (
                                <Budget
                                  count={
                                    DashboardContDetails.data
                                      ? DashboardContDetails.data[0].total_schools_count
                                      : 0
                                  }
                                />
                              )}{" "}
                            </Grid>
                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                              {loadingContDashboard && dashboardContDetailsGet ? (
                                <TotalCustomers count={0} percent={0} />
                              ) : (
                                <TotalCustomers
                                  count={
                                    DashboardContDetails.data
                                      ? DashboardContDetails.data[0].total_contents
                                      : 0
                                  }
                                  percent={16}
                                />
                              )}{" "}
                            </Grid>
                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                              {loadingContDashboard && dashboardContDetailsGet ? (
                                <TotalProfit sx={{ height: "100%" }} count={0} />
                              ) : (
                                <TotalProfit
                                  sx={{ height: '100%' }}
                                  percent={DashboardContDetails.data ? DashboardContDetails.data[0].percentage_status : 0}
                                  count={DashboardContDetails.data ? DashboardContDetails.data[0].total_current_price : 0} />


                              )}
                            </Grid>
                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                              {loadingContDashboard && dashboardContDetailsGet ? (
                                <TasksProgress percent={0.0} count={0} />
                              ) : (

                                <TasksProgress percent={DashboardContDetails.data ? DashboardContDetails.data[0].percentage_diff_current : 0}
                                  count={DashboardContDetails.data ? DashboardContDetails.data[0].last_week_price : 0} />

                              )}{" "}
                            </Grid>
                            
                            <Grid item lg={8} md={12} xl={9} xs={12}>
                              <Sales />
                            </Grid>
                            <Grid item lg={4} md={6} xl={3} xs={12}>
                              <TrafficByDevice sx={{ height: "100%" }} />
                            </Grid>


{/* 
                            <Grid item lg={4} md={6} xl={3} xs={12}>
                              <LatestProducts sx={{ height: "100%" }} />
                            </Grid>
                            <Grid item lg={8} md={12} xl={9} xs={12}>
                              <LatestOrders />
                            </Grid> */}


                          </Grid>
                        </div>
                      </Container>
                    </Box>
                  </Grid>
                </Grid>

                {/* <div style={{ justifyContent: "flex-end", minWidth: "100%", display: "flex" }}>
                                <Fab
                                    aria-label="add"
                                    style={{
                                        alignSelf: "flex-end",
                                        position: "absolute",
                                        right: 5,
                                        backgroundColor: "#ffffff",
                                        borderRadius: 10,
                                        top: 200,
                                        zIndex: 2,
                                    }}
                                >
                                    <Link to="/contentmaker/settings" style={{ textDecoration: "none" }}>
                                        <div style={{
                                            minWidth: 35,
                                            minHeight: 20,
                                            backgroundColor: "gray",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            backgroundColor: "#F3F6F9",
                                            borderRadius: 10
                                        }}
                                        >
                                            <SettingsOutlinedIcon color="primary" style={{ marginTop: 5 }} />
                                        </div>
                                    </Link>

                                </Fab>
                            </div> */}
              </Container>

              <div style={{
                margin: 30
              }}>
                <Copyright />
              </div>

            </main>


            {/* </Router> */}
          </>
        ) : (
          <></>
        )}


      </div>


      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </>
  );
}
