import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Divider, Grid, Toolbar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { useDispatch, useSelector } from "react-redux";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase";
import Constants from "../../../resource/Constants";
import FileUploader from "react-firebase-file-uploader";
import { listBoardGet } from "../../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../../redux/actions/InstituteDetailsAction";
import { listSubGet } from "../../../redux/actions/subjectsAction";
import { listStdGet } from "../../../redux/actions/standardAction";
import { listChpGet } from "../../../redux/actions/chapterAction";
import { listTopGet } from "../../../redux/actions/topicAction";
// import { Alert } from "@material-ui/lab";
import { Snackbar, TextField } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Cookie from "js-cookie";
import Checkbox from '@material-ui/core/Checkbox';

import API from "../../../http/http";
import PARTNERAPI from "../../../http/httppartner";
import PUBLISHERAPI from "../../../http/publisher";
import { listContTypeGet } from "../../../redux/actions/contentTypeAction";
import CircularProgress from "@material-ui/core/CircularProgress";
// import image from "../../../assets/book-na-1.jpg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanel1(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabPanel1.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function a11yProps1(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
    minHeight: 30,
  },
  formControl1: {
    margin: theme.spacing(1),
    minWidth: 350,
    minHeight: 30,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  toolbar: {
    marginRight: 24,
    justifyContent: "space-between",
  },
  button: {
    margin: theme.spacing(1),
    justifyContent: "flex-end",
    alignSelf: "center",
  },
  button1: {
    margin: theme.spacing(1),
    justifyContent: "flex-end",
    justifyItems: "flex-end",
    justifySelf: "flex-end",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    alignContent: "flex-end",
  },
}));

export default function EditPricing(props) {

  const { actvRow } = props

  const classes = useStyles();
  const classes2 = useStyles2();
  const [value, setValue] = React.useState(0);
  const [age, setAge] = React.useState("");
  const [showInputField, SetShowInputField] = useState(false);
  const [selectBoardDetails, setSelectedBoardDetails] = useState("");
  const [boardDetailsState, setBoardDetailsState] = useState(null);
  const [filenames, setFileNames] = useState([]);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [costId, setCostId] = useState((actvRow) ? actvRow.content_prov_cost_id : 0)
  const [instType, setInstType] = useState((actvRow) ? actvRow.institute_type_id : "");
  const [boardType, setBoardType] = useState((actvRow) ? actvRow.board_id : "");
  const [subjectType, setSubjectType] = useState((actvRow) ? actvRow.subject_id : "");
  const [standardType, setStandardType] = useState((actvRow) ? actvRow.classroom_std : '');
  const [contType, setContType] = useState("");
  const [ImageDownloadUrls, setImageDownloadUrls] = useState((actvRow) ? actvRow.image_cover_url : "");
  console.log(`actvRow image url ${(actvRow) ? actvRow.image_cover_url : 'n/a'}`)

  const [chap, setChap] = useState((actvRow) ? actvRow.chapter_id : "");
  const [tpc, setTpc] = useState((actvRow) ? actvRow.topic_id : "");
  const [title, setTitle] = useState((actvRow) ? actvRow.content_name : "");
  const [video, setVideo] = useState((actvRow) ? actvRow.content_location : "");
  const [snackOpen, setSnackOpen] = useState(false);
  const [chaptername, setChapterName] = useState((actvRow) ? actvRow.chapter_name : "");
  const [topicname, setTopicName] = useState((actvRow) ? actvRow.topic_name : "");
  const [progress, setProgress] = useState(0);
  const [bulkProgress, setBulkProgress] = useState(0);
  const [bulkIsUploading, setBulkIsUploading] = useState(false);
  const [sbType, setSbType] = useState((actvRow) ? actvRow.subscript_type : "")
  const [weekCost, setWeekCost] = useState((actvRow) ? actvRow.weekly_cost : 1)
  const [monCost, setMonCost] = useState((actvRow) ? actvRow.monthly_cost : 1)
  const [annumCost, setAnnumCost] = useState((actvRow) ? actvRow.annual_cost : 0)
  const [fromDate, setFromDate] = useState((actvRow) ? actvRow.from_date : new Date)
  const [toDate, setToDate] = useState((actvRow) ? actvRow.to_date : new Date)

  // const [contentprov, setContentprov] = useState(0)
  // const [instTypeID, setinstTypeID] = useState(0)
  // const [boardID, setboardID] = useState(0)
  // const [classroom, setinstTypeID] = useState(0)
  // const [instTypeID, setinstTypeID] = useState(0)


  const [agree, setAgree] = useState(false)

  const [tags, setTags] = useState('')

  const dispatch = useDispatch();

  const handleSnackOpen = (open) => {
    setSnackOpen(open);
  };

  const handleTagsChange = (e) => {
    const { name, value } = e.target

    setTags(value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAgreeToggle = (e) => {
    console.log(e.target.checked)

    setAgree(e.target.checked)
  }

  const handleWeekCostChange = (event) => {
    console.log(`new weekcost ${event}`);
    const { name, value } = event.target;
    setWeekCost(value);
  };

  const handleMonCostChange = (event) => {
    console.log(`new moncost ${event}`);
    const { name, value } = event.target;
    setMonCost(value);
  };

  const handleAnnumCostChange = (event) => {
    console.log(`new annumcost ${event}`);
    const { name, value } = event.target;
    setAnnumCost(value);
  };

  const handleFromDateChange = (event, newValue) => {
    const name = event.target.name;
    const value = event.target.value;

    setFromDate(value);
  };

  const handleToDateChange = (event, newValue) => {
    const name = event.target.name;
    const value = event.target.value;

    setToDate(value);
  };

  const onPrefillData = () => {
    dispatch(listBoardGet(actvRow.institute_type_id)); // school boards
    dispatch(listStdGet(actvRow.institute_type_id));

    dispatch(listSubGet(actvRow.board_id));
    // dispatch(listChpGet(actvRow.subject_id, actvRow.board_id));

  }

  const handleInstChange = (event) => {
    console.log("called handleInstChange");
    console.log(`new institute type ${event}`);
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });

    setInstType(value);
    dispatch(listBoardGet(value));
    dispatch(listStdGet(value));

  };

  const handleBoardChange = (event) => {
    console.log("called handleBoardChange");
    console.log(`new board  ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`);

    setBoardType(value);
    // get subjects
    dispatch(listSubGet(value));
  };

  const handleStdChange = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setStandardType(value);
  };

  const handleSubChange = (event) => {
    console.log("called handleSubChange");
    console.log(`new subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType(value);
    dispatch(listChpGet(value, boardType));
  };

  const handleChpChange = (event, chaptername) => {
    console.log("called handleChpChange");
    console.log(`new chapter ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log("chapter name", chaptername);
    setChap(value);

    dispatch(listTopGet(value));
  };

  const handleSbTypeChange = (event) => {
    console.log("called handleSbTypeChange");
    console.log(`new subscription ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSbType(value);
  };

  const handleContTypeChange = (event) => {
    console.log("called handleTpcChange");
    console.log(`new topic ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setContType(value);
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      height: 30,
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      width: 310,
      padding: "10px 26px 10px 12px",
      placeholder: "Title",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",

        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const showInput = () => {
    SetShowInputField(true);
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadStartBulk = () => {
    setBulkIsUploading(true);
    setBulkProgress(0);
  };

  const handleProgressBulk = (progress) => {
    setBulkProgress(progress);
  };

  const handleUploadErrorBulk = (error) => {
    setBulkIsUploading(false);
  };

  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;

  const instDetailsGet = useSelector((state) => state.instDetails);
  const { loadingInst, instDetails, errorInst } = instDetailsGet;

  const subDetailsGet = useSelector((state) => state.subDetails);
  const { loadingSub, subDetails, errorSub } = subDetailsGet;

  const stdDetailsGet = useSelector((state) => state.stdDetails);
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

  const topDetailsGet = useSelector((state) => state.topDetails);
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;

  const contTypeDetailsGet = useSelector((state) => state.contType);
  const { loadingCont, contTypeDetails, errorCont } = contTypeDetailsGet;
  const [allSubjects, setAllSubjects] = useState(null)
  const [rowCount, setRowCount] = useState(0)

  console.log("chapterDetails", chpDetailsGet);
  console.log("TopicDetails", topDetailsGet);
  console.log("contentDetailsget", contTypeDetails);

  const getAllSubjects = async () => {
    const response = await PUBLISHERAPI.getAllSubjects();
    const subs = response.data;
    console.log('all subjects ', subs)

    setAllSubjects(subs)
  }

  /* called after render completed.. */
  useEffect(() => {
    dispatch(listInstGet());

    if (actvRow) {
      onPrefillData()
    }

    getAllDetails();
    getAllSubjects()
    return () => {
      //
    };
  }, []);

  const getAllDetails = async () => {
    const userId = Cookie.get("kgtopg.partner.user.id");
    await new Promise((resolve, reject) => {
      dispatch(listBoardGet(10001));
      resolve();
    });

    const firstBoardId = actvRow.board_id;

    // we got boards
    await new Promise((resolve, reject) => {
      dispatch(listSubGet(firstBoardId));
      resolve();
    });

    PUBLISHERAPI.getPricingSetup(userId)
  };

  const handleUploadSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("ebook")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    // setFileNames(filename)
    setDownloadUrls(downloadURL);
    if (downloadURL) {
      setBulkProgress(0);
      setBulkIsUploading(false);
    }
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleBulkContentUpload = () => {
    console.log(`ebook url ${downloadUrls}`);

    if (
      !downloadUrls ||
      !instType ||
      !boardType ||
      !subjectType ||
      !standardType
    ) {
      handleSnackOpen(true);
    } else {
      // send data

      // const body = {
      //     institute_type_id: 10001,
      //     institute_board_id: 1001,
      //     classroom_std: 10,
      //     course: 'M.P.C',
      //     subject_id: 108,

      //     content_location: downloadUrls
      // }

      const body = {
        institute_type_id: 10001,
        board_id: 10003,
        classroom_std: 10,
        course: "M.P.C",
        subject_id: 108,
        content_name: "Video.mp4",
        chapter_id: 127,
        chapter_name: "The Rise of Nationalism in Europe",
        topic_id: 61,
        topic_name: "Imperialism and Global Transformation ",
        content_f_desc: "some",
        content_length: "3",
        content_location:
          "https://www.youtube.com/watch?v=TMubSggUOVE&ab_channel=TheOrganicChemistryTutor",
        content_size: 300,
      };

      const resp = PUBLISHERAPI.postSaveBulkContent(body);

      console.log(`response ${JSON.stringify(resp)}`);
    }
  };

  const handlePricingSetupSubmitted = async () => {
    // console.log(`ebook url ${ImageDownloadUrls}`);
    // console.log(`chapter name ${chaptername}`)
    // console.log(`video ${video}`)
    // console.log(`chap ${chap}`)
    // console.log(`tpc ${tpc}`)
    console.log(`instType ${instType}`)
    console.log(`boardType ${boardType}`)
    console.log(`subjectType ${subjectType}`)
    console.log(`stdType ${standardType}`)
    // console.log(`title ${title}`)
    // console.log(`contType ${contType}`)
    console.log(`sbType ${sbType}`)
    console.log(`weebCost ${weekCost}`)
    console.log(`monCost ${monCost}`)
    console.log(`annumCost ${annumCost}`)
    console.log(`fromDate ${fromDate}`)
    console.log(`toDate ${toDate}`)
    console.log(`agree ${agree}`)

    console.log(`checking params...`)
    if (
      !instType ||
      !boardType ||
      !subjectType ||
      !standardType ||
      // !chap ||
      // !tpc ||
      // !contType ||
      // !title ||

      !sbType ||
      // !weekCost ||
      // !monCost ||
      !annumCost ||
      !fromDate ||
      !toDate
      // !ImageDownloadUrls ||
      // !chaptername ||
      // !video
    ) {
      console.log(`showing snackbar..`)
      handleSnackOpen(true);
    } else {
      // send data

      console.log("calling api =========================================");
      // const body = {
      //     institute_type_id: 10001,
      //     institute_board_id: 1001,
      //     classroom_std: 10,
      //     course: 'M.P.C',
      //     subject_id: 108,
      //     content_location: downloadUrls
      // }

      console.log(
        "parameters",
        instType,
        boardType,
        subjectType,
        standardType,
        // chap,
        // tpc,
        // contType,
        // title,
        sbType,
        weekCost,
        monCost,
        annumCost,
        fromDate,
        toDate,
        video,
        ImageDownloadUrls,
        chaptername
      );

      const userId = Cookie.get("kgtopg.partner.user.id");
      const body = {
        cost_id: actvRow.content_prov_cost_id,
        content_prov: userId,
        institute_type_id: instType,
        board_id: boardType,
        classroom_std: standardType,
        // course: `class ${standardType}`,
        subject_id: subjectType,
        // content_name: title,
        subscript_type: sbType,
        weekly_cost: weekCost,
        monthly_cost: monCost,
        annual_cost: annumCost,
        from_date: fromDate,
        to_date: toDate,
        // chapter_id: chap,
        // chapter_name: chaptername,
        // topic_id: tpc,
        // topic_name: topicname,
        // content_f_desc: topicname,
        // content_length: 0,
        // content_location: video,
        // content_size: 0,
        // content_type_id: contType,
        content_prov: userId,
        // image_cover_url: encodeURI(ImageDownloadUrls),
        // image_cover_url: ImageDownloadUrls
      };

      console.log(`content api body ${JSON.stringify(body)}`)
      let resp;
      // if (!actvRow) {
      resp = await new Promise(async (resolve, reject) => {
        await PUBLISHERAPI.postUpdatePriceSetup(body);

        resolve();
      });
      // } else {
      // resp = await new Promise(async (resolve, reject) => {
      // await PUBLISHERAPI.updateSingleContent(actvRow.id, body);

      // resolve();
      // });
      // }

      console.log(`response ${JSON.stringify(resp)}`);

      // close the dialog..
      props.onCloseClicked();

      // also call search content
      // props.onSearchContent("");

      // call reload
      props.onReload()
    }
  };

  console.log(`actvRow image ${(ImageDownloadUrls)}`)
  console.log(`instType ${instType}`)
  console.log(`boardType ${boardType}`)
  console.log(`subjectType ${subjectType}`)
  console.log(`standardType ${standardType}`)
  // console.log(`chap ${chap}`)
  // console.log(`tpc ${tpc}`)
  // console.log(`title ${title}`)
  // console.log(`ImageDownloadUrls ${ImageDownloadUrls}`)
  // console.log(`chaptername ${chaptername}`)
  // console.log(`video ${video}`)
  console.log(`sbType ${sbType}`)
  console.log(`weebCost ${weekCost}`)
  console.log(`monCost ${monCost}`)
  console.log(`annumCost ${annumCost}`)
  console.log(`fromDate ${fromDate}`)
  console.log(`toDate ${toDate}`)
  console.log(`agree ${agree}`)

  let singleUploadDisabled = false;
  let openUploadDisabled = false;

  if (!instType ||
    !boardType ||
    !subjectType ||
    !standardType ||
    !sbType ||
    !weekCost ||
    !monCost ||
    !annumCost ||
    !fromDate ||
    !toDate ||
    // !chap ||
    // !tpc ||
    // !contType ||
    // !title ||
    // ((actvRow && !actvRow.image_cover_url) || 
    // !ImageDownloadUrls ||
    // !chaptername ||
    // !video ||
    !agree)
    singleUploadDisabled = true;


  if (
    !subjectType ||

    !title ||
    // ((actvRow && !actvRow.image_cover_url) || 
    !ImageDownloadUrls ||
    !video ||
    !agree)
    openUploadDisabled = true;

  const onAddRow = () => {
    let rowCnt = rowCount + 1;
    setRowCount(rowCnt);
  }

  const onDelRow = () => {
    let rowCnt = rowCount - 1;
    setRowCount(rowCnt); // refresh
  }

  const row = (

    <div style={{
      display: "flex", justifyContent: "space-between", marginTop: 20,
      alignItems: 'center'
    }}>
      <FormControl>
        <TextField
          id="outlined-basic"
          label="Enter usercount"
          variant="outlined"
          style={{
            // width: 350,
            fontFamily: "Poppins, Helvetica, sans-serif",
          }}
          defaultValue={0}

        />

      </FormControl>
      <Typography
        variant="body1">
        above
      </Typography>

      <FormControl>
        <TextField
          id="outlined-basic"
          label="Enter amount"
          variant="outlined"
          style={{
            // width: 350,
            fontFamily: "Poppins, Helvetica, sans-serif",
          }}
          defaultValue={0}

        />

      </FormControl>

      <DeleteIcon
        onClick={onDelRow}
      />

    </div>

  )

  const priceRows = (
    [...Array(rowCount)].map((e, i) => {
      return (row);
    })
  )

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
          style={{ fontSize: 16, fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          Edit Pricing
        </Typography>

      </Toolbar>
      <Divider style={{}} />

      {/* <AppBar position="static" color="default" style={{ marginTop: "20" }}> */}
      {/* <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
      >

        <Tab
          label="Pricing Details"
          {...a11yProps(0)}
          style={{ fontSize: 12 }}
        />

      </Tabs> */}

      {/* SINGLE UPLOAD PANEL */}
      <TabPanel value={value} index={0}>
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "block",
          }}
        >
          {/* {loadingInst && instDetailsGet ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ alignSelf: "center" }}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Institute type
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={instType}
                  onChange={handleInstChange}
                  label=" Institute type"
                  size="small"
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Plle
                  </MenuItem>
                </Select>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Institute type
                  </span>
                </span>
              </FormControl>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ alignSelf: "center" }}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Institute type
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={instType}
                  onChange={handleInstChange}
                  label=" Institute type"
                  size="small"
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  {loadingInst && instDetailsGet ? (
                    console.log("loading trueeee")
                  ) : instDetails.length != 0 ? (
                    instDetails.data.map((list, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={list.institute_type_id}
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          {list.institute_type_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      No Institutes
                    </MenuItem>
                  )}
                </Select>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Institute type
                  </span>
                </span>
              </FormControl>
            </div>
          )}

          {boardDetailsGet ? (
            loading && boardDetailsGet ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Board/Uni
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={boardType}
                    onChange={handleBoardChange}
                    label="Board/Uni"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Board/Uni
                    </span>
                  </span>
                </FormControl>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Board/Uni
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={boardType}
                    onChange={handleBoardChange}
                    label="Board/Uni"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    {loading && boardDetailsGet ? (
                      console.log("loading trueeee")
                    ) : boardDetails &&
                      boardDetails.data &&
                      boardDetails.data.length != 0 ? (
                      boardDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.institute_board_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.institute_board_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Board/Uni
                    </span>
                  </span>
                </FormControl>
              </div>
            )
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Board/Uni
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={boardType}
                  onChange={handleBoardChange}
                  label="Board/Uni"
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Boards
                  </MenuItem>
                </Select>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Board/Uni
                  </span>
                </span>
              </FormControl>
            </div>
          )}

          {stdDetailsGet ? (
            loadingStd && stdDetailsGet ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Standard/Stream
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={standardType}
                    onChange={handleStdChange}
                    label="Standard/Stream"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    <MenuItem
                      value="0"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  </Select>

                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Standard/Stream
                    </span>
                  </span>
                </FormControl>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Standard/Stream
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={standardType}
                    onChange={handleStdChange}
                    label="Standard/Stream"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    {loadingStd && stdDetailsGet ? (
                      console.log("loading trueeee")
                    ) : stdDetails.length != 0 ? (
                      stdDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.standard}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>

                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Standard/Stream
                    </span>
                  </span>
                </FormControl>
              </div>
            )
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Standard/Stream
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={standardType}
                  onChange={handleStdChange}
                  label="Standard/Stream"
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Standards
                  </MenuItem>
                </Select>

                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Standard/Stream
                  </span>
                </span>
              </FormControl>
            </div>
          )} */}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subjectType}
                onChange={handleSubChange}
                label="Select Subject"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {allSubjects && allSubjects.data && allSubjects.data.length && (
                  allSubjects.data.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.subject_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {list.subject_name}
                      </MenuItem>
                    );
                  })
                )}
              </Select>

            </FormControl>
          </div>




          <div style={{
            display: "flex", justifyContent: "space-around", marginTop: 20,
            alignItems: 'center',
            marginBottom: 30
          }}>

            <FormControl>
              <TextField
                id="outlined-basic"
                label="Enter learn price"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={annumCost}
                onChange={handleAnnumCostChange}
              />

            </FormControl>

          </div>

          <Divider />
          <div style={{
            height: 20
          }}></div>

          <Typography
            variant="subheading">
            Teach Price
          </Typography>

          <div style={{
            display: "flex", justifyContent: "space-around", marginTop: 20,
            alignItems: 'center'
          }}>
            <Typography
              variant="body1">
              Per User
            </Typography>

            <FormControl>
              <TextField
                id="outlined-basic"
                label="Enter amount"
                variant="outlined"
                style={{
                  // width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={annumCost}
                onChange={handleAnnumCostChange}
              />

            </FormControl>

          </div>


          <div style={{
            display: "flex", justifyContent: "space-between", marginTop: 20,
            alignItems: 'center'
          }}>
            <FormControl>
              <TextField
                id="outlined-basic"
                label="Enter usercount"
                variant="outlined"
                style={{
                  // width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={annumCost}
                onChange={handleAnnumCostChange}
              />

            </FormControl>
            <Typography
              variant="body1">
              above
            </Typography>

            <FormControl>
              <TextField
                id="outlined-basic"
                label="Enter amount"
                variant="outlined"
                style={{
                  // width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={annumCost}
                onChange={handleAnnumCostChange}
              />

            </FormControl>

            <></>
          </div>


          {(rowCount > 0)
            ? priceRows
            :
            <></>
          }

          <Divider
            style={{
              margin: 20
            }} />
          <Button variant="contained" endIcon={<AddIcon />}
            onClick={onAddRow}>
            Add
          </Button>






          {/* subscription type */}
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl variant="outlined" className={classes.formControl}>

              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Subscription type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={sbType}
                onChange={handleSbTypeChange}
                label="Topic"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                <MenuItem
                  value={`SINGLEUSER`}
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  SINGLE USER
                </MenuItem>
                <MenuItem
                  value={`LUMPSOME`}
                  style={{
                    fontSize: 13,
                    color: "#464E5F",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  LUMPSOME
                </MenuItem>
              </Select>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Subscription type
                </span>
              </span>
            </FormControl>
          </div> */}





          {/* 
          <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
            <FormControl>
              <TextField
                id="outlined-basic"
                label="Enter Weekly Cost"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={weekCost}
                onChange={handleWeekCostChange}
              />
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Weekly Cost
                  </span>
                </span>
              </Typography>
            </FormControl>

          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
            <FormControl>
              <TextField
                id="outlined-basic"
                label="Enter Monthly Cost"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={monCost}
                onChange={handleMonCostChange}
              />
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Monthly Cost
                  </span>
                </span>
              </Typography>
            </FormControl>

          </div> */}





          {/* <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
            <FormControl>
              <TextField
                id="outlined-basic"
                label="Enter Annum Cost"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={annumCost}
                onChange={handleAnnumCostChange}
              />
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Annum Cost
                  </span>
                </span>
              </Typography>
            </FormControl>

          </div>

          <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
            <FormControl>
              <TextField
                id="outlined-basic"
                // label="Enter From Date"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                type="date"
                defaultValue={fromDate}
                onChange={handleFromDateChange}
              />
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    From Date
                  </span>
                </span>
              </Typography>
            </FormControl>

          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
            <FormControl>
              <TextField
                id="outlined-basic"
                // label="Enter End Date"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                type="date"
                defaultValue={toDate}
                onChange={handleToDateChange}
              />
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    To Date
                  </span>
                </span>
              </Typography>
            </FormControl>

          </div>



          <div
            style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', width: '100%', marginTop: 20 }}
          >
            <Checkbox
              checked={agree}
              onChange={handleAgreeToggle}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <Link to="/publisher-content/settings/terms-conditions" target="_blank" rel="noopener noreferrer">
              <Typography
                variant="body2">
                I agree to Terms &amp; Conditions
              </Typography>
            </Link>
          </div> */}

          <div
            style={{ display: "flex", justifyContent: "center", padding: 20, marginTop: 0, width: '100%' }}
          >
            {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}
            <Button
              disabled={singleUploadDisabled}
              variant="contained"
              color="secondary"
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                textTransform: "none",
                width: '100%'
              }}
              onClick={() => {
                handlePricingSetupSubmitted();
              }}
            >
              Submit
            </Button>
            {/* </Link> */}
          </div>
        </div>
      </TabPanel>

    </div>
  );
}
