/**
 * Reader powered by TurnJS in ReactJS
 */

import React, { Component, useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import "turn.js";
import "../../../css/styles.css";
import API from '../../../http/http'
import PARTNERAPI from '../../../http/httppartner'
import PUBLISHERAPI from '../../../http/publisher'



class Turn extends React.Component {
    static defaultProps = {
        style: {},
        className: "",
        options: {}
    };

    constructor(props) {
        super(props)
        this.state = {}

    }

    componentDidMount() {
        if (this.el) {
            $(this.el).turn(Object.assign({}, this.props.options));
        }
        document.addEventListener("keydown", this.handleKeyDown, false);
    }

    componentWillUnmount() {
        if (this.el) {
            $(this.el)
                .turn("destroy")
                .remove();
        }
        document.removeEventListener("keydown", this.handleKeyDown, false);
    }

    handleKeyDown = event => {
        if (event.keyCode === 37) {
            $(this.el).turn("previous");
        }
        if (event.keyCode === 39) {
            $(this.el).turn("next");
        }
    };

    render() {
        return (
            <div
                className={this.props.className}
                style={Object.assign({}, this.props.style)}
                ref={el => (this.el = el)}
            >
                {this.props.children}
            </div>
        );
    }
}

const hasWindow = typeof window !== 'undefined';
const width = hasWindow ? window.innerWidth : null;
const height = hasWindow ? window.innerHeight : null;


console.log(" windows width at turn js",(width-400)/2,height-20)


// const pages = [
//     "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/01.jpg",
//     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
//     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
//     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
//     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
//     "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/06.jpg",
// ];

const TurnBook = (props) => {

    const [bookId, setBookId] = useState(null)
    const [bookPages, setBookPages] = useState(null)

    const options = {

        width: width - 400,
        height: height - 20,
        autoCenter: true,
        acceleration: true,
        justifyContent: "center",
        alignSelf: "center",
        elevation: 50,
        gradients: !$.isTouch,
        when: {
            turned: function (e, page) {
                console.log("Current view: ====================================================", $(this).turn("view")[0], $(this).turn("view")[1]);
                console.log("evnets while turning", e)
                console.log("current page image     ++++++++++++++++++++++++++++++++++++++++++", page)
                props.getPageNum($(this).turn("view")[0], $(this).turn("view")[1])
            }
        },


    };


    // const pages = [
    //     "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/01.jpg",
    //     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
    //     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
    //     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
    //     "https://i.pinimg.com/originals/50/f7/ed/50f7ed2e36f71000021eca23d1936380.jpg",
    //     "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/06.jpg",
    // ];


    const getEbookContent = async (book_id) => {

        let pages = []
        const response = await PUBLISHERAPI.GetEbookContent(book_id)
        console.log("response at turn book screen", response)
        let temp = response.data.data.ebook_details

        temp.map((detail, index) => {
            console.log("book page details at turn pages", detail, index)
            // if (index + 1 == detail.page_num) {
            pages.push(detail.page_url)
            // }
        })
        console.log("book page array  at turn book", pages)
        setBookPages(pages)

    }



    useEffect(() => {

        const query = new URLSearchParams(window.location.search)
        const book_id = query.get('book_id')

        if (book_id) {
            getEbookContent(book_id)
        }

    }, [])

    console.log(`bookPages ${bookPages}`)


    return (
        <div>
            {
                bookPages ?

                    <Turn options={options} className="magazine" >
                        {

                            bookPages.map((page, index) => (
                                <div key={index} >
                                    <img src={page} alt="" style={{ width: "100%", height: height - 20 }} />
                                </div>
                            ))

                        }
                    </Turn >
                    :
                    <div style={{
                        padding: 20
                    }}>
                        Loading..
                    </div>
            }

        </div>

    )
}


export default TurnBook;