import React, { useEffect, useState } from "react";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {
    Route,
    Link,
    BrowserRouter as Router,
    Switch,
    Redirect,
    HashRouter,
    useHistory,
} from "react-router-dom";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import "../../css/sidebar.css";
import Leftbar from "./slots/Leftbar";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { listDashBoardContHead, listDashBoardHead } from "../../redux/actions/DashBoardActions";
import PropTypes from 'prop-types';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from '@material-ui/core/Button';

import API from '../../http/http'
import PARTNERAPI from '../../http/httppartner'
import PUBLISHERAPI from '../../http/publisher'

import { listBoardGet } from "../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../redux/actions/InstituteDetailsAction";

import { listStdGet } from "../../redux/actions/standardAction";
import { listContUsers, listPubUsers } from "../../redux/actions/PubUsersListAction";
import { listSubGet } from "../../redux/actions/subjectsAction";
import Cookie from "js-cookie";
import PublisherAPI from "../../http/publisher";
import { Alert, AlertTitle } from "@material-ui/lab";
import Copyright from "../user/slots/Copyright";

import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tour from 'reactour'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChipInput from 'material-ui-chip-input'

const steps = [
    {
        selector: '[data-tut="first-step"]',
        content: 'The various filters help confine the results',
    },
    {
        selector: '[data-tut="second-step"]',
        content: 'The list displays publishers connected with you',
    },

    // ...
];

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        scrollbarWidth: "thin"
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue",
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        justifyContent: "space-between",
        minHeight: "100%",
        height: "100%",
        minWidth: "100%",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0,
    },
}));

const useStyles3 = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    root1: {
        maxWidth: 200,
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        marginTop: theme.spacing(3),
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },

    formControl1: {
        margin: theme.spacing(1),
        minWidth: 320,
        minHeight: 30,
    },
    primary: {
        width: "25%",
        height: 50,
    },
}));

const useStyles4 = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}


function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const rows1 = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

const useStyles1 = makeStyles({
    table: {
        minWidth: 700,
    },
});

function InviteFormDialog(props) {
    const [open, setOpen] = React.useState(false);
    const { bookIds, yourChips } = props
    const [snackOpen, setSnackOpen] = React.useState(false);

    const handleClick = () => {
        setSnackOpen(true);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSendInvites = async () => {

        const body = {
            'book_ids': bookIds,
            'emails': yourChips
        }

        // const resp = await PUBLISHERAPI.postSendBulkInvites(body)
        // show snackbar
        handleClick()
        setTimeout(function () {
            handleClose(); // close dialog

        }, 2000)

    }

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Send Invite
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To invite publishers, please enter their email addresses here. We will send the mails
                        to them. Wait for them to connect back to you!
                    </DialogContentText>
                    {/* <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                    /> */}

                    <div style={{
                        paddingLeft: 20,
                        paddingRight: 20
                    }}>
                        <ChipInput
                            defaultValue={yourChips}
                            onChange={(chips) => props.handleChipChange(chips)}
                            fullWidth={true}
                            fullWidthInput={true}
                            placeholder={`Add email`}
                            multiline={true}
                        />
                        {/* <ChipInput
                value={yourChips}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
              /> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSendInvites} color="primary">
                        Send Invites
                    </Button>
                    <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                        <Alert onClose={handleSnackClose} severity="success">
                            Invites sent to publishers successfully..
                        </Alert>
                    </Snackbar>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const FilterFields = (props) => {
    const classes = useStyles3();
    const [instType, setInstType] = useState("");
    const [boardType, setBoardType] = useState("");
    const [subjectType, setSubjectType] = useState("");
    const [standardType, setStandardType] = useState("");
    const [pubId, setPubId] = useState(2);

    const dispatch = useDispatch();

    const handleInstChange = (event) => {
        console.log("called handleInstChange");
        console.log(`new institute type ${event}`);
        const name = event.target.name;
        const value = event.target.value;
        // setState({
        // ...state,
        // [name]: event.target.value,
        // });
        dispatch(listBoardGet(value));
        setInstType(value);
        dispatch(listContUsers(value, boardType, standardType, subjectType));

    };

    const handleBoardChange = (event) => {
        console.log("called handleBoardChange");
        console.log(`new board  ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        console.log(`board selected value ${value}`);

        setBoardType(value);
        dispatch(listStdGet(instType, value));
        dispatch(listSubGet(value));
        dispatch(listContUsers(instType, value, standardType, subjectType));

    };

    const handleSubChange = (event) => {
        console.log("called handleSubChange");
        console.log(`new subject ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        setSubjectType(value);

        if (value) {
            dispatch(listContUsers(instType, boardType, standardType, value));
        }
    };

    const handleStdChange = (event) => {
        console.log("called handleStdChange");
        console.log(`new standard ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        setStandardType(value);
        //dispatch(listSubGet(instType))

        const pub_id = pubId;
        // if(value){
        //   console.log("handlestdChange ==============================",instType,boardType,value,pub_id)
        //   dispatch(listSearchEbookGet(instType,boardType,value,pub_id))
        // }
        dispatch(listContUsers(instType, boardType, standardType, subjectType));

    };
    const boardDetailsGet = useSelector((state) => state.boardDetails);
    const { loading, boardDetails, error } = boardDetailsGet;

    const instDetailsGet = useSelector((state) => state.instDetails);
    const { loadingInst, instDetails, errorInst } = instDetailsGet;

    const subDetailsGet = useSelector((state) => state.subDetails);
    const { loadingSub, subDetails, errorSub } = subDetailsGet;

    const stdDetailsGet = useSelector((state) => state.stdDetails);
    const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

    const contUsersListGet = useSelector((state) => state.contUsersGet);
    const { loadingContUsers, contUsers, errorContUsers } = contUsersListGet;

    {
        loadingContUsers && contUsersListGet
            ? console.log("users data is loading")
            : console.log("users data is ", contUsers);
    }

    let rows = [];
    if (!loadingContUsers && contUsersListGet && contUsers && contUsers.data) {
        contUsers.data.map((users, index) => {
            if (users && users.status) {
                users.status.map((usersList, index) => {
                    rows.push(usersList);
                });
            }
        });
    }
    // props.usersList(rows)


    useEffect(() => {
        dispatch(listInstGet());
        return () => {
            //
        };
    }, []);

    return (
        <Paper elevation={2} style={{ marginLeft: 10 }}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    padding: 10,
                    overflow: "auto",
                    overflowX: "initial",
                    scrollbarWidth: "thin"
                    //  marginLeft:20
                }}
            >

                {/* <div > */}

                {/* <div style={{ display: "flex", justifyContent: "center" ,marginTop:10}}>
  
  
                            <TextField 
                            id="outlined-basic" 
                            label="Search by Name" 
                            variant="outlined"
                            defaultValue={title}
                            onChange={handleTitleChange}
                      
                                style={{
                                    width:250,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    display:"inline-block",
                                    lineHeight:"140%"
  
                      
                                }}
                                
                            />
                        </div>
  
                        <div style={{ display: "flex", justifyContent: "Left" }}>
                        <Typography>
                                <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Search by  <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                      Name
                            </span>
                                </span>
                            </Typography>
  
                            </div>
  
                    </div>
  
  */}

                {instDetailsGet ? (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Institute Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={instType}
                                onChange={handleInstChange}
                                label="Institute Type"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                {loadingInst && instDetailsGet ? (
                                    console.log("loading trueeee")
                                ) : instDetails &&
                                    instDetails.data &&
                                    instDetails.data.length != 0 ? (
                                    instDetails.data.map((list, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={list.institute_type_id}
                                                style={{
                                                    fontSize: 13,
                                                    color: "#464E5F",
                                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                                }}
                                            >
                                                {list.institute_type_name}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem
                                        style={{
                                            fontSize: 13,
                                            color: "#464E5F",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        No Institutes
                                    </MenuItem>
                                )}
                            </Select>

                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Institute
                                </span>
                            </span>
                        </FormControl>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Institute Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={instType}
                                onChange={handleInstChange}
                                label="Institute Type"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    No Institutes
                                </MenuItem>
                            </Select>

                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Institute
                                </span>
                            </span>
                        </FormControl>
                    </div>
                )}

                {boardDetailsGet &&
                    boardDetails &&
                    boardDetails.length != 0 &&
                    boardDetails.data ? (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Board
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={boardType}
                                onChange={handleBoardChange}
                                label="Board"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                {
                                    loading && boardDetailsGet ? (
                                        console.log("loading trueeee")
                                    ) : boardDetails && boardDetails.length != 0 ? (
                                        boardDetails.data.map((list, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={list.institute_board_id}
                                                    style={{
                                                        fontSize: 13,
                                                        color: "#464E5F",
                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                    }}
                                                >
                                                    {list.institute_board_name}
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem
                                            style={{
                                                fontSize: 13,
                                                color: "#464E5F",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                            }}
                                        >
                                            Please Select Institute Type
                                        </MenuItem>
                                    )

                                    // boardDetailsMock.map((list, index) => {
                                    //     return (
                                    //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                                    //     )
                                    // })
                                }
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Board
                                </span>
                            </span>
                        </FormControl>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Board
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={boardType}
                                onChange={handleBoardChange}
                                label="Board"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    No Boards{" "}
                                </MenuItem>
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Board
                                </span>
                            </span>
                        </FormControl>
                    </div>
                )}

                {stdDetailsGet ? (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Standard
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={standardType}
                                onChange={handleStdChange}
                                label="Standard"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                {
                                    loadingStd && stdDetailsGet ? (
                                        console.log("loading trueeee")
                                    ) : stdDetails.length != 0 ? (
                                        stdDetails.data.map((list, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={list.id}
                                                    style={{
                                                        fontSize: 13,
                                                        color: "#464E5F",
                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                    }}
                                                >
                                                    {list.standard}
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem
                                            style={{
                                                fontSize: 13,
                                                color: "#464E5F",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                            }}
                                        >
                                            Please Select Institute Type
                                        </MenuItem>
                                    )

                                    // standardsMockData.map((list, index) => {
                                    //     return (
                                    //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.stand_name}</MenuItem>
                                    //     )
                                    // })
                                }
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Standard
                                </span>
                            </span>
                        </FormControl>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Standard
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={standardType}
                                onChange={handleStdChange}
                                label="Standard"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    No Standards
                                </MenuItem>
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Standard
                                </span>
                            </span>
                        </FormControl>
                    </div>
                )}

                {subDetails ? (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Subject
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={subjectType}
                                onChange={handleSubChange}
                                label="Subject"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                {loadingSub && subDetails ? (
                                    <MenuItem
                                        key={0}
                                        value="Please Select Board"
                                        style={{
                                            fontSize: 13,
                                            color: "#464E5F",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        {" "}
                                        Please Select Board{" "}
                                    </MenuItem>
                                ) : subDetails &&
                                    subDetails.data &&
                                    subDetails.data.subjects.length != 0 ? (
                                    subDetails.data.subjects.map((list, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={list.subject_id}
                                                style={{
                                                    fontSize: 13,
                                                    color: "#464E5F",
                                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                                }}
                                            >
                                                {list.subject_name}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem
                                        style={{
                                            fontSize: 13,
                                            color: "#464E5F",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Please Select Institute Type
                                    </MenuItem>
                                )}
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Subject
                                </span>
                            </span>
                        </FormControl>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Subject
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={subjectType}
                                onChange={handleSubChange}
                                label="Subject"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    No Subjects{" "}
                                </MenuItem>
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Subject
                                </span>
                            </span>
                        </FormControl>
                    </div>
                )}
            </div>
        </Paper>
    );
};


export default function PublishersList() {
    const classes1 = useStyles1();
    const classes = useStyles();
    const classes2 = useStyles4();

    const [isLoadingscr, setIsLoadingscr] = useState(false);

    // table states 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isTourOpen, setIsTourOpen] = useState(false);

    //fetched data
    let [rows, setRows] = useState(null)
    // email addresses
    const [yourChips, setYourChips] = useState([])

    const handleChipChange = (chips) => {
        console.log('chip change called ', chips)
        setYourChips(chips)
    }

    const handleAddChip = (chip) => {
        console.log('onAdd chip called')
        let chips = yourChips
        chips.push(chip)
        setYourChips(chips)
    }

    const handleDeleteChip = (chip, index) => {
        console.log('onDelete chip called')

        // let chips = yourChips.filter(item => item != chip)
        let chips = yourChips.filter((item, idx) => idx !== index)
        setYourChips(chips)
    }

    // table functions
    console.log("rows ", rows)

    const dispatch = useDispatch();

    const handleTourOpen = () => {
        setIsTourOpen(true)
    }

    const getPublisherList = async () => {
        setIsLoadingscr(true)
        const response = await PUBLISHERAPI.GetPublisherListCont()
        console.log("response at publisher list", response.data.data)
        let temp = response.data.data
        if (temp) {
            setRows(temp)
        }
        console.log("rows at publishers list", rows1)

        setIsLoadingscr(false)
    }

    useEffect(() => {

        getPublisherList()

        return () => {
            //
        };
    }, []);

    return (
        <div className={classes.root}>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0
            }}>
                <LiveHelpIcon
                    onClick={handleTourOpen} />
            </div>
            {/* {isLoadingscr == false ? ( */}
            {true ? (
                <>
                    <CssBaseline />
                    <LinearProgress />

                    <Leftbar />

                    <main className={classes.content}>
                        <Helmet>
                            <title> My Publishers | Pustak Publish</title>
                        </Helmet>

                        <Container
                            className={classes.container}
                            style={{
                                minHeight: "100%",
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: 20,
                                    padding: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                <div>
                                    <Typography
                                        // className={classes.title}
                                        id="tableTitle"
                                        component="div"
                                        /* style={{
                                          fontSize: 16,
                                          fontWeight: "500",
                                          fontFamily: "Poppins, Helvetica, sans-serif",
                                        }} */
                                        variant="h3"
                                    >
                                        Co-Partners
                                    </Typography>

                                    <Typography
                                        variant="subtitle2">
                                        Co-Partners you are associated with
                                    </Typography>
                                </div>

                                <div>
                                    <InviteFormDialog handleChipChange={(chips) => { handleChipChange(chips) }} />
                                </div>

                            </div>


                            <div data-tut="first-step">
                                <FilterFields />
                            </div>

                            <div
                                style={{
                                    padding: 20
                                }}
                                data-tut="second-step">
                                {(isLoadingscr) ?
                                    <CircularProgress />
                                    :
                                    (rows && rows.length > 0) ?
                                        <TableContainer component={Paper}>
                                            <Table className={classes1.table} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Name</StyledTableCell>
                                                        <StyledTableCell align="center">Email</StyledTableCell>
                                                        <StyledTableCell align="center">Books Count</StyledTableCell>
                                                        <StyledTableCell align="center">Content Count</StyledTableCell>
                                                        {/* <StyledTableCell align="center">Revenue</StyledTableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>


                                                    {rows.map((row) => (
                                                        <StyledTableRow key={row.name}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {row.pub_name_text}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{row.pub_email_text}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.total_ebooks_count}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.total_content_count}</StyledTableCell>
                                                            {/* <StyledTableCell align="center">{row.total_revenue}</StyledTableCell> */}
                                                        </StyledTableRow>
                                                    ))}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <>
                                            <div className={classes2.root}>
                                                <Alert severity="warning">
                                                    <AlertTitle>No Co-Partners!</AlertTitle>
                                                    Your content is not being used by any publishers yet — <strong>try connect with them!</strong>
                                                </Alert>
                                            </div>
                                        </>

                                }
                            </div>

                        </Container>

                        <Copyright />
                    </main>

                </>
            ) : (
                <></>
            )}



            <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
            />
        </div>
    );
}
