import { EBOOKCONTENT_LIST_REQUEST, EBOOKCONTENT_LIST_SUCCESS, EBOOKCONTENT_LIST_FAIL } from "../constants/ebookContentConstants";
import { EBOOKPOST_LIST_REQUEST, EBOOKPOST_LIST_SUCCESS, EBOOKPOST_LIST_FAIL } from "../constants/ebookContentConstants";


function EbookContentReducer(state = { EbookContent: [] }, action) {
    switch (action.type) {
        case EBOOKCONTENT_LIST_REQUEST:
            return { loadingEbook: true }
        case EBOOKCONTENT_LIST_SUCCESS:
            return { loadingEbook: false, EbookContent: action.payload };
        case EBOOKCONTENT_LIST_FAIL:
            return { loadingEbook: false, errorEbook: action.payload }
        default:
            return state;
    }
}

function EbookContentPostReducer(state = { EbookContentPost: [] }, action) {
    switch (action.type) {
        case EBOOKPOST_LIST_REQUEST:
            return { loadingEbookPost: true }
        case EBOOKPOST_LIST_SUCCESS:
            return { loadingEbookPost: false, EbookContentPost: action.payload };
        case EBOOKPOST_LIST_FAIL:
            return { loadingEbookPost: false, errorEbook: action.payload }
        default:
            return state;
    }
}


export { EbookContentReducer, EbookContentPostReducer }