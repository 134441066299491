import React from 'react';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import TableModel from "./TableModel"
import { Route, Link, BrowserRouter, Switch, Redirect } from 'react-router-dom'

// import Footer from "../Footer"
import { Avatar } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Settings from './Settings';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MapList from '../curriculummap/MapList';
import UnitList from '../curriculummap/UnitList';
import { useDispatch, useSelector } from 'react-redux';
import { listBoardGet } from '../../redux/actions/boardDetailsAction';
import { listInstGet } from '../../redux/actions/InstituteDetailsAction';
import { listSubGet } from '../../redux/actions/subjectsAction';
import { listChpGet } from '../../redux/actions/chapterAction';
import { listStdGet } from '../../redux/actions/standardAction';
import { Alert, AlertTitle } from '@material-ui/lab';

import Cookie from 'js-cookie';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                KgtoPg
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
}));


function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function MapDashboard() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [instType, setInstType] = useState("")
    const [boardType, setBoardType] = useState("")
    const [subjectType, setSubjectType] = useState("")
    const [standardType, setStandardType] = useState("")
    const [pubId, setPubId] = useState(2)

    const boardDetailsGet = useSelector(state => state.boardDetails)
    const { loading, boardDetails, error } = boardDetailsGet

    const instDetailsGet = useSelector(state => state.instDetails)
    const { loadingInst, instDetails, errorInst } = instDetailsGet

    const subDetailsGet = useSelector(state => state.subDetails)
    const { loadingSub, subDetails, errorSub } = subDetailsGet

    const dispatch = useDispatch()

    function setPublisher() {
        const userId = Cookie.get('kgtopg.partner.user.id')
        setPubId(userId)

        console.log(`pubId is ${userId}`)
    }


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const currMap = useSelector(state => state.currMap.currMap)

    const handleBoardChange = (event) => {
        console.log('called handleBoardChange')
        console.log(`new board  ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        console.log(`board selected value ${value}`)

        setBoardType(value)
        dispatch(listStdGet(instType, value))

    }


    const handleSubChange = (event) => {
        console.log('called handleSubChange')
        console.log(`new subject ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        setSubjectType(value)




    }


    const handleInstChange = (event) => {

        console.log('called handleInstChange')
        console.log(`new institute type ${event}`)
        const name = event.target.name;
        const value = event.target.value;
        // setState({
        // ...state,
        // [name]: event.target.value,
        // });
        dispatch(listBoardGet(value))
        setInstType(value)


    }

    async function getData() {
        await new Promise((resolve, reject) => {
            dispatch(listInstGet())

            resolve()
        })


    }

    useEffect(() => {
        // set up logged in publisher
        setPublisher()
        // get boards, subjects and standards
        getData()

        return <></>
    }, [])

    return (
        <div className={classes.root}>
            <BrowserRouter>
                <CssBaseline />
                <LinearProgress />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} >
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}

                        >
                            <MenuIcon style={{ color: "#fff" }} />
                        </IconButton>
                        <Typography component="h1" variant="h5" color="inherit" noWrap className={classes.title} style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 14 }}>
                            Dashboard
                        </Typography>

                        <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                            <Typography
                                component="h1"
                                variant="h5"
                                color="inherit"
                                noWrap
                                className={classes.title}
                                style={{ justifyContent: "center", textAlign: "center", alignItems: "center", alignSelf: "center", margin: 10, fontSize: 14 }}
                            >
                                Hi,
                                 <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 14, fontWeight: "bold" }}>Content Maker</span>
                            </Typography>
                            <Avatar>C</Avatar>
                        </div>

                    </Toolbar>
                    <Divider />
                    <Toolbar className={classes.toolbar1}>
                        <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 14, fontWeight: "bold" }}>Dashboard</span>
                        {/* <div style={{fontFamily: "Poppins, Helvetica, sans-serif",fontSize: 14,marginLeft:20}}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/" onClick={handleClick} style={{color:"#ffffff",textDecoration:"none",fontFamily: "Poppins, Helvetica, sans-serif"}}>
                                contentlist
                            </Link>
                            <Link color="inherit" href="/getting-started/installation/" onClick={handleClick} 
                            style={{color:"#ffffff",textDecoration:"none",fontFamily: "Poppins, Helvetica, sans-serif"}}
                            >
                                Table
                        </Link>
                        </Breadcrumbs>
                    </div> */}
                    </Toolbar>
                </AppBar>




                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >

                    <div className={classes.toolbarIcon}>
                        <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 14, fontWeight: "bold" }}>Partner</span>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon style={{ color: "#3f51b5", marginRight: -20 }} />
                            <ChevronLeftIcon style={{ color: "#3f51b5" }} />
                        </IconButton>
                    </div>

                    <Divider style={{ marginTop: 65 }} />
                    <List>{mainListItems}</List>
                    <Divider />
                    <List>{secondaryListItems}</List>
                </Drawer>



                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />

                    <Container className={classes.container}>
                        <Grid container spacing={3}>

                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Switch>
                                        {
                                            <Redirect exact from="/curriculum-map" to="/curriculum-map/dashboard" />
                                        }

                                        <Route exact path="/curriculum-map/dashboard" component={MapList} />
                                        <Route exact path="/curriculum-map/set-units/1" component={UnitList} />
                                    </Switch>

                                </Paper>
                            </Grid>
                        </Grid>

                        <div style={{ justifyContent: "flex-end", minWidth: "100%", display: "flex" }}>
                            <Fab
                                aria-label="add"
                                style={{
                                    alignSelf: "flex-end",
                                    position: "absolute",
                                    right: 5,
                                    backgroundColor: "#ffffff",
                                    borderRadius: 10,
                                    top: 300
                                }}
                            >
                                <Link to="/settings" style={{ textDecoration: "none" }}>
                                    <div style={{
                                        minWidth: 35,
                                        minHeight: 20,
                                        backgroundColor: "gray",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        backgroundColor: "#F3F6F9",
                                        borderRadius: 10
                                    }}
                                    >
                                        <SettingsOutlinedIcon color="primary" style={{ marginTop: 5 }} />
                                    </div>
                                </Link>

                            </Fab>
                        </div>
                        <Box pt={4}>
                            <Copyright />
                        </Box>
                    </Container>

                </main>



            </BrowserRouter>
        </div>
    )
}