import axios from "axios";
import Constants from "../../resource/Constants";
import { CHPDETAILS_LIST_FAIL, CHPDETAILS_LIST_REQUEST, CHPDETAILS_LIST_SUCCESS } from "../constants/chapterConstants";




const listChpGet = (subId, boardId, clsStd) => async (dispatch) => {
    try {
        dispatch({ type: CHPDETAILS_LIST_REQUEST })

        if(!clsStd) { // backwards compatible
            const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/standard-chapter/?subid=${subId}&board_id=${boardId}`)
            console.log("data in list chapters  get", data)
            dispatch({ type: CHPDETAILS_LIST_SUCCESS, payload: data })
        } else {
            const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/standard-chapter1/?subid=${subId}&board_id=${boardId}&classroom_std=${clsStd}`)
            console.log("data in list chapters  get", data)
            dispatch({ type: CHPDETAILS_LIST_SUCCESS, payload: data })
        }
        

    } catch (error) {
        dispatch({ type: CHPDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listChpGet }