
export const SINGLEEVENTPOST_LIST_REQUEST = ' SINGLEEVENTPOST_LIST_REQUEST'
export const SINGLEEVENTPOST_LIST_SUCCESS = '  SINGLEEVENTPOST_LIST_SUCCESS'
export const SINGLEEVENTSPOST_LIST_FAIL = ' SINGLEEVENTSPOST_LIST_FAIL'


export const SINGLEVIDEOPOST_LIST_REQUEST = ' SINGLEVIDEOPOST_LIST_REQUEST'
export const SINGLEVIDEOPOST_LIST_SUCCESS = '  SINGLEVIDEOPOST_LIST_SUCCESS'
export const SINGLEVIDEOPOST_LIST_FAIL = ' SINGLEVIDEOPOST_LIST_FAIL'

export const SUBSCRIBERSDETAILSPOST_LIST_REQUEST = ' SUBSCRIBERSDETAILSPOST_LIST_REQUEST'
export const SUBSCRIBERSDETAILSPOST_LIST_SUCCESS = '  SUBSCRIBERSDETAILSPOST_LIST_SUCCESS'
export const SUBSCRIBERSDETAILSPOST_LIST_FAIL = ' SUBSCRIBERSDETAILSPOST_LIST_FAIL'


export const TRAINTEACHVIDEOSPOST_LIST_REQUEST = 'TRAINTEACHVIDEOSPOST_LIST_REQUEST'
export const TRAINTEACHVIDEOSPOST_LIST_SUCCESS = 'TRAINTEACHVIDEOSPOST_LIST_SUCCESS'
export const TRAINTEACHVIDEOSPOST_LIST_FAIL = 'TRAINTEACHVIDEOSPOST_LIST_FAIL'
