import axios from "axios";
import Constants from "../../resource/Constants";
import { SUBDETAILS_LIST_FAIL, SUBDETAILS_LIST_REQUEST, SUBDETAILS_LIST_SUCCESS } from "../constants/subjectConstants";




const listSubGet = (boardId) => async (dispatch) => {
    console.log("subjects+++++++++++++++++++++++++++++++++++++++++++++++++", boardId)
    try {
        dispatch({ type: SUBDETAILS_LIST_REQUEST })

        const sUrl = Constants.Application.PLATFORM_END_URL + `/subject-board/?institute_board_id=${boardId}` 
        console.log('subjects url ' + sUrl)
        const { data } = await axios.get(sUrl)
        console.log("data in list subjects  get", data)
        dispatch({ type: SUBDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: SUBDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listSubGet }