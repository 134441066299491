import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Route, Link, BrowserRouter as Router, Switch, Redirect, HashRouter, useHistory } from 'react-router-dom'
import { Box, Button, FormControl, Grid, LinearProgress } from '@material-ui/core';
import { useState } from 'react';
import "../../css/sidebar.css"
import LeftCard from "./slots/LeftCard"
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ReactPlayer from "react-player";
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase";
import Constants from "../../resource/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { listSingleVideoPost } from '../../redux/actions/Trainer/EventsPost';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { listAllVideosGet } from '../../redux/actions/Trainer/EventsGet';
import EventVideoTabs from "./slots/EventVideosTabs"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VideoThumbnail from 'react-video-thumbnail';
import { AlertTitle } from '@material-ui/lab';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { listSingleVideoPut } from '../../redux/actions/Trainer/EventsPut';
import Fab from "@material-ui/core/Fab";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import { listSingleVideoDel } from '../../redux/actions/Trainer/EventsDelete';
import Tooltip from '@material-ui/core/Tooltip';





const config = {
    apiKey: Constants.Application.FIREBASE.API_KEY,
    authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
    //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
    storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>
            {'Copyright © '}
            <Link color="inherit" href="https://kgtopg.com/">
                KGToPG
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles2 = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 10,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const useStyles1 = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        display: "flex",
        justifyContent: "center",
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        // justifyContent: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        minWidth: '100%'

    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0
    },
}));

function AccordianCard(props) {

    const { videoData, id, VideoDataUpd } = props
    const classes2 = useStyles2()
    const classes1 = useStyles1();
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [thumb, setThumb] = useState(false)
    const [videoId, setVideoId] = useState(videoData.id)
    const [videoName, setVideoName] = useState(videoData.video_name)
    const [videoDesc, setVideoDesc] = useState(videoData.video_f_desc)
    const [videoLength, setVideoLength] = useState(videoData.video_length)
    const [videoSize, setVideoSize] = useState(videoData.video_size)
    const [eventId, setEventId] = useState("")
    const [openSnack, setOpenSnack] = useState(false)
    const [progressVid, setProgressVid] = useState(0);
    const [isUploadingVid, setIsUploadingVid] = useState(false);
    const [uploadProgressVid, setUploadProgressVid] = useState(0);
    const [showUpldVid, setShowUpldVid] = useState(false)
    const [videoDownloadUrls, setVideoDownloadUrls] = React.useState(videoData.video_location)
    const [videoNewDownloadUrls, setVideoNewDownloadUrls] = useState("")
    const [state, setState] = React.useState({
        right: false,
    });
    const [openDelSnack, setOpenDelSnack] = useState(false)


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const sendBackData = (vidData) => {
        VideoDataUpd(vidData)
    }



    const UpdateThumb = (thumbnail) => {
        console.log("thumbnail", thumbnail)
        setThumb(true)
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const handleTitleChange = (e) => {
        setVideoName(e.target.value)
    }

    const handleDescChange = (e) => {
        setVideoDesc(e.target.value)
    }


    const handleLengthChange = (e) => {
        setVideoLength(e.target.value)
    }


    const handleSizeChange = (e) => {
        setVideoSize(e.target.value)
    }


    const handleUploadStartVid = () => {
        setIsUploadingVid(true);
        setUploadProgressVid(0);
    };

    const handleUploadErrorVid = (error) => {
        setIsUploadingVid(false);
    };


    const handleUploadSingleSuccessVid = async (filename) => {
        const downloadURL = await firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL();

        console.log("download url", downloadURL);

        if (downloadURL) {
            setProgressVid(0);
        }
        // setFileNames(filename)
        setVideoNewDownloadUrls(downloadURL);
        // setUploadProgress(100)
        // setIsUploading(false)
    };

    const handleProgressVid = (progress) => {
        setProgressVid(progress);
    };

    const handleSnackOpen = () => {
        setOpenSnack(true)
    }

    const handleCloseSnackAlert = () => {
        setOpenSnack(false)
    }

    const SaveVideo = (anchor, status) => {
        const body = {
            event_id: eventId,
            video_name: videoName,
            video_f_desc: videoDesc,
            video_length: videoLength,
            video_location: videoNewDownloadUrls ? videoNewDownloadUrls : videoDownloadUrls,
            video_size: videoSize,
        }
        dispatch(listSingleVideoPut(body, videoId))
        toggleDrawer(anchor, status)
        handleSnackOpen()
    }

    const DeleteVideo = (id) => {
        dispatch(listSingleVideoDel(id))
        setOpenDelSnack(true)
        handleClose()
    }


    const handleCloseSnackDelAlert = () => {
        setOpenDelSnack(true)
    }


    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const event_id = query.get("eve_id");
        setEventId(query.get("eve_id"));


        return (() => {
            //
        })
    }, [])

    return (
        <div className={classes2.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <Typography className={classes2.heading}><strong>Video{id}: </strong>{videoData.video_name}</Typography>
                        </div>
                        <div>
                            <Typography style={{ fontSize: 14 }}>{videoData.video_length} <strong>mins</strong> </Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>

                    {/* <div style={{width:"100%",display:"flex",justifyContent:"flex-start"}}>
                        <strong>Description</strong>
                    </div> */}

                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-start", flexDirection: "column" }}>
                        <strong style={{ fontSize: 16 }}>Description</strong>
                        <Typography>
                            {videoData.video_f_desc}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        {/* <strong> Preview Image</strong> */}
                        <div style={{ display: "flex", justifyContent: "center", position: "absolute", marginTop: 25, alignItems: "center" }}>
                            <PlayCircleOutlineRoundedIcon
                                style={{
                                    fontSize: 50,
                                    color: "grey",
                                    alignSelf: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop:45,
                                    marginLeft:150,
                                }}
                            />
                        </div>
                        <div style={{ width: 300, height: 180, cursor: "pointer" }} onClick={() => { sendBackData(videoData) }} >

                            {/* {
                                thumb ? */}


                            <VideoThumbnail
                                videoUrl={videoData.video_location}
                                thumbnailHandler={(thumbnail) => { UpdateThumb(thumbnail) }}
                                width={350}
                                height={180}
                            />


                            {/* :
                                    <></>
                            } */}

                            {/* <ReactPlayer light={true} url={videoData.video_location} width={350}  height={180} style={{ marginTop: 30, marginLeft: 20 }} controls={true} /> */}

                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            width: "100%",
                            alignSelf: "flex-end",
                            margin: 20

                        }}
                    >

                        <div>
                            {['right'].map((anchor) => (
                                <React.Fragment key={anchor}>

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 10 }} onClick={toggleDrawer(anchor, true)}>
                                        <Tooltip title="Edit">
                                            <EditOutlinedIcon color="primary" />
                                            {/* <Typography>
                                            Edit
                                                </Typography> */}
                                        </Tooltip>
                                    </div>

                                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                        <div>
                                            <Typography
                                                style={{
                                                    fontSize: 20,
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    fontFamily: "Poppins, Helvetica, sans-serif"
                                                }}
                                            >
                                                Edit Video
                                            </Typography>
                                        </div>


                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginLeft: 10,
                                                marginRight: 10,
                                                marginBottom: 10,
                                                marginTop: 5
                                            }}
                                        >
                                            <FormControl >
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="outlined-basic"
                                                    label="Video Title"
                                                    variant="outlined"
                                                    style={{
                                                        width: 350,
                                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                                    }}
                                                    classes={{ root: classes1.customTextField }}
                                                    defaultValue={videoName}
                                                    onChange={(e) => handleTitleChange(e)}
                                                // error={titleErr}
                                                // helperText={titleHelp}
                                                />
                                                <Typography>
                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#464E5F",
                                                            marginLeft: 5,
                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                        }}
                                                    >
                                                        Please enter <span
                                                            style={{
                                                                color: "#464E5F",
                                                                fontWeight: "600",
                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                fontSize: 12
                                                            }}>
                                                            Video Title
                                                        </span>
                                                    </span>
                                                </Typography>

                                            </FormControl>

                                        </div>


                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginLeft: 10,
                                                marginRight: 10,
                                                marginBottom: 10,
                                                marginTop: 5
                                            }}
                                        >
                                            <FormControl >
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="outlined-basic"
                                                    label="Video Description"
                                                    variant="outlined"
                                                    multiline
                                                    style={{
                                                        width: 350,
                                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                                    }}
                                                    classes={{ root: classes1.customTextField }}
                                                    defaultValue={videoDesc}
                                                    onChange={(e) => handleDescChange(e)}
                                                // error={titleErr}
                                                // helperText={titleHelp}
                                                />
                                                <Typography>
                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#464E5F",
                                                            marginLeft: 5,
                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                        }}
                                                    >
                                                        Please enter <span
                                                            style={{
                                                                color: "#464E5F",
                                                                fontWeight: "600",
                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                fontSize: 12
                                                            }}>
                                                            Video Description
                                                        </span>
                                                    </span>
                                                </Typography>

                                            </FormControl>

                                        </div>


                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginLeft: 10,
                                                marginRight: 10,
                                                marginBottom: 10,
                                                marginTop: 5
                                            }}
                                        >
                                            <FormControl >
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="outlined-basic"
                                                    label="Video Length"
                                                    variant="outlined"
                                                    style={{
                                                        width: 350,
                                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                                    }}
                                                    classes={{ root: classes1.customTextField }}
                                                    defaultValue={videoLength}
                                                    onChange={handleLengthChange}
                                                // error={titleErr}
                                                // helperText={titleHelp}
                                                />
                                                <Typography>
                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#464E5F",
                                                            marginLeft: 5,
                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                        }}
                                                    >
                                                        Please enter <span
                                                            style={{
                                                                color: "#464E5F",
                                                                fontWeight: "600",
                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                fontSize: 12
                                                            }}>
                                                            Video Length
                                                        </span>
                                                    </span>
                                                </Typography>

                                            </FormControl>

                                        </div>


                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginLeft: 10,
                                                marginRight: 10,
                                                marginBottom: 10,
                                                marginTop: 5
                                            }}
                                        >
                                            <FormControl >
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="outlined-basic"
                                                    label="Video Size"
                                                    variant="outlined"
                                                    style={{
                                                        width: 350,
                                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                                    }}
                                                    classes={{ root: classes1.customTextField }}
                                                    defaultValue={videoSize}
                                                    onChange={handleSizeChange}
                                                // error={titleErr}
                                                // helperText={titleHelp}
                                                />
                                                <Typography>
                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#464E5F",
                                                            marginLeft: 5,
                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                        }}
                                                    >
                                                        Please enter <span
                                                            style={{
                                                                color: "#464E5F",
                                                                fontWeight: "600",
                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                fontSize: 12
                                                            }}>
                                                            Video Size
                                                        </span>
                                                    </span>
                                                </Typography>

                                            </FormControl>

                                        </div>




                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: 20,
                                                    marginLeft: 15,
                                                    marginRight: 20,
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                        backgroundColor: "steelblue",
                                                        color: "white",
                                                        padding: 5,
                                                        borderRadius: 4,
                                                        cursor: "pointer",
                                                        textAlign: "center",
                                                        width: "100%"
                                                    }}

                                                >
                                                    Upload New Recorded Video
                                                    <FileUploader
                                                        hidden
                                                        // accept="image/*"
                                                        storageRef={firebase.storage().ref("images")}
                                                        onUploadStart={handleUploadStartVid}
                                                        onUploadError={handleUploadErrorVid}
                                                        onUploadSuccess={handleUploadSingleSuccessVid}
                                                        onProgress={handleProgressVid}
                                                    />
                                                </label>
                                            </div>



                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {progressVid ? <CircularProgress /> : <></>}
                                            </div>

                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {videoNewDownloadUrls ? (
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <CloudUploadOutlinedIcon style={{ marginRight: 5 }} />
                                                        <Typography>
                                                            New Video Uploaded
                                                        </Typography>

                                                    </div>
                                                )
                                                    :
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <CloudUploadOutlinedIcon style={{ marginRight: 5 }} />
                                                        <Typography>
                                                            Video Uploaded
                                                        </Typography>

                                                    </div>
                                                }


                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: 20,
                                                marginLeft: 15,
                                                marginRight: 20,
                                            }}

                                            onClick={() => SaveVideo(anchor, false)}
                                        >
                                            <Button variant="contained" color="primary" fullWidth>
                                                Save Edited Video
                                            </Button>
                                        </div>

                                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnackAlert}>
                                            <Alert onClose={handleCloseSnackAlert} severity="success">
                                                Video is saved successfully!
                                            </Alert>
                                        </Snackbar>
                                    </Drawer>
                                </React.Fragment>
                            ))}
                        </div>







                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={handleClickOpen}>
                            <Tooltip title="Delete">
                                <DeleteOutlineOutlinedIcon color="secondary" />
                            </Tooltip>
                            {/* <Typography>
                                Delete
                            </Typography> */}
                        </div>
                        <div>

                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Delete Video"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">

                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <HighlightOffOutlinedIcon style={{ fontSize: 80 }} color="secondary" />
                                        </div>
                                        Are you Sure,Do you want to Delete? This Process is cannot be undone.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={() => DeleteVideo(videoData.id)} color="primary" autoFocus>
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <Snackbar open={openDelSnack} autoHideDuration={6000} onClose={handleCloseSnackDelAlert}>
                            <Alert onClose={handleCloseSnackDelAlert} severity="warning">
                                Video is Deleted successfully!
                            </Alert>
                        </Snackbar>

                    </div>
                </AccordionDetails>
            </Accordion>
        </div>

    )

}

export default function EventVideosPage(props) {
    const classes = useStyles();
    const classes1 = useStyles1();
    const [readerData, setReaderData] = useState("")
    const [isLoadingscr, setIsLoadingscr] = useState(false)
    const [open, setOpen] = useState(false)
    const [state, setState] = React.useState({
        right: false,
    });
    const [videoDownloadUrls, setVideoDownloadUrls] = React.useState("")
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [progressVid, setProgressVid] = useState(0);
    const [isUploadingVid, setIsUploadingVid] = useState(false);
    const [uploadProgressVid, setUploadProgressVid] = useState(0);
    const [showUpldVid, setShowUpldVid] = useState(false)

    const [videoName, setVideoName] = useState("")
    const [videoDesc, setVideoDesc] = useState("")
    const [videoLength, setVideoLength] = useState("")
    const [videoSize, setVideoSize] = useState("")
    const [eventId, setEventId] = useState("")
    const [openSnack, setOpenSnack] = useState(false)
    const [videoUrl, setVideoUrl] = useState("")
    const [videoDes, setVideoDes] = useState("")
    const [viddata, setViddata] = useState("")

    console.log("windows path in dashboard", window.location.pathname)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const GetVideosAll = (id) => {
        dispatch(listAllVideosGet(id))
    }

    const dispatch = useDispatch()

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const event_id = query.get("eve_id");
        setEventId(query.get("eve_id"));
        console.log("event id at event video page", event_id)

        GetVideosAll(event_id)

        return (() => {
            //
        })
    }, [])


    const getEventVideos = useSelector(state => state.allVideosGet)
    const { loadingAllVideos, allVideosGetData, errorAllVideos } = getEventVideos


    {
        loadingAllVideos && getEventVideos ?
            console.log("data is loading at event videos page")
            :
            console.log("data of all vidoes get at event videos", allVideosGetData)
    }


    const handleTitleChange = (e) => {
        setVideoName(e.target.value)
    }

    const handleDescChange = (e) => {
        setVideoDesc(e.target.value)
    }


    const handleLengthChange = (e) => {
        setVideoLength(e.target.value)
    }


    const handleSizeChange = (e) => {
        setVideoSize(e.target.value)
    }


    const handleUploadStartVid = () => {
        setIsUploadingVid(true);
        setUploadProgressVid(0);
    };

    const handleUploadErrorVid = (error) => {
        setIsUploadingVid(false);
    };


    const handleUploadSingleSuccessVid = async (filename) => {
        const downloadURL = await firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL();

        console.log("download url", downloadURL);

        if (downloadURL) {
            setProgressVid(0);
        }
        // setFileNames(filename)
        setVideoDownloadUrls(downloadURL);
        // setUploadProgress(100)
        // setIsUploading(false)
    };

    const handleProgressVid = (progress) => {
        setProgressVid(progress);
    };

    const handleSnackOpen = () => {
        setOpenSnack(true)
    }

    const handleCloseSnackAlert = () => {
        setOpenSnack(false)
    }

    const UpdateVideoData = (vidData) => {
        setVideoUrl(vidData.video_location)
        setVideoDes(vidData.video_f_desc)
        setViddata(vidData)
    }

    const SaveVideo = (anchor, status) => {
        const body = {
            event_id: eventId,
            video_name: videoName,
            video_f_desc: videoDesc,
            video_length: videoLength,
            video_location: videoDownloadUrls,
            video_size: videoSize,
        }
        dispatch(listSingleVideoPost(body))
        toggleDrawer(anchor, status)
        handleSnackOpen()
    }

    return (
        <div className={classes.root}>
            {isLoadingscr == false ?
                // <Router>
                <>
                    <CssBaseline />
                    <LinearProgress />

                    <LeftCard />

                    <main className={classes.content}>
                        <Helmet>
                            <title> Event-Videos | KGtoPG Partner</title>
                        </Helmet>

                        <Container className={classes.container}>
                            <Grid container spacing={1}>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xl={12}
                                    xs={12}
                                >

                                    {/* Top heading bar  */}
                                    <div style={{
                                        display: 'flex',
                                        marginTop: 10,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%"
                                    }}
                                    >
                                        {/* Trainer Title */}
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, }}>
                                            <VideoLibraryOutlinedIcon style={{ fonSize: 60, marginRight: 10 }} />
                                            <Typography
                                                id="tableTitle" component="div"
                                                variant="h2"
                                                style={{
                                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                                    fontSize: 25
                                                }}>
                                                Event Videos
                                            </Typography>
                                        </div>


                                        {/* create  event button  */}
                                        <div>
                                            {['right'].map((anchor) => (
                                                <React.Fragment key={anchor}>
                                                    <div>
                                                        <Box
                                                            display="flex"
                                                            justifyContent="flex-end"
                                                        >
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={toggleDrawer(anchor, true)}
                                                                style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none", boxShadow: "2px 2px 2px #d4d4d6" }}
                                                            >
                                                                Upload Video
                                                            </Button>
                                                        </Box>
                                                    </div>


                                                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} >

                                                        <div>
                                                            <Typography
                                                                style={{
                                                                    fontSize: 20,
                                                                    margin: 10,
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    fontFamily: "Poppins, Helvetica, sans-serif"
                                                                }}
                                                            >
                                                                Upload Video
                                                            </Typography>
                                                        </div>


                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                marginLeft: 10,
                                                                marginRight: 10,
                                                                marginBottom: 10,
                                                                marginTop: 5
                                                            }}
                                                        >
                                                            <FormControl >
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="outlined-basic"
                                                                    label="Video Title"
                                                                    variant="outlined"
                                                                    style={{
                                                                        width: 350,
                                                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                                                    }}
                                                                    classes={{ root: classes1.customTextField }}
                                                                    defaultValue={videoName}
                                                                    onChange={(e) => handleTitleChange(e)}
                                                                // error={titleErr}
                                                                // helperText={titleHelp}
                                                                />
                                                                <Typography>
                                                                    <span
                                                                        style={{
                                                                            fontSize: 12,
                                                                            color: "#464E5F",
                                                                            marginLeft: 5,
                                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                                        }}
                                                                    >
                                                                        Please enter <span
                                                                            style={{
                                                                                color: "#464E5F",
                                                                                fontWeight: "600",
                                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                                fontSize: 12
                                                                            }}>
                                                                            Video Title
                                                                        </span>
                                                                    </span>
                                                                </Typography>

                                                            </FormControl>

                                                        </div>


                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                marginLeft: 10,
                                                                marginRight: 10,
                                                                marginBottom: 10,
                                                                marginTop: 5
                                                            }}
                                                        >
                                                            <FormControl >
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="outlined-basic"
                                                                    label="Video Description"
                                                                    variant="outlined"
                                                                    multiline
                                                                    style={{
                                                                        width: 350,
                                                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                                                    }}
                                                                    classes={{ root: classes1.customTextField }}
                                                                    defaultValue={videoDesc}
                                                                    onChange={(e) => handleDescChange(e)}
                                                                // error={titleErr}
                                                                // helperText={titleHelp}
                                                                />
                                                                <Typography>
                                                                    <span
                                                                        style={{
                                                                            fontSize: 12,
                                                                            color: "#464E5F",
                                                                            marginLeft: 5,
                                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                                        }}
                                                                    >
                                                                        Please enter <span
                                                                            style={{
                                                                                color: "#464E5F",
                                                                                fontWeight: "600",
                                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                                fontSize: 12
                                                                            }}>
                                                                            Video Description
                                                                        </span>
                                                                    </span>
                                                                </Typography>

                                                            </FormControl>

                                                        </div>


                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                marginLeft: 10,
                                                                marginRight: 10,
                                                                marginBottom: 10,
                                                                marginTop: 5
                                                            }}
                                                        >
                                                            <FormControl >
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="outlined-basic"
                                                                    label="Video Length"
                                                                    variant="outlined"
                                                                    style={{
                                                                        width: 350,
                                                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                                                    }}
                                                                    classes={{ root: classes1.customTextField }}
                                                                    defaultValue={videoLength}
                                                                    onChange={handleLengthChange}
                                                                // error={titleErr}
                                                                // helperText={titleHelp}
                                                                />
                                                                <Typography>
                                                                    <span
                                                                        style={{
                                                                            fontSize: 12,
                                                                            color: "#464E5F",
                                                                            marginLeft: 5,
                                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                                        }}
                                                                    >
                                                                        Please enter <span
                                                                            style={{
                                                                                color: "#464E5F",
                                                                                fontWeight: "600",
                                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                                fontSize: 12
                                                                            }}>
                                                                            Video Length
                                                                        </span>
                                                                    </span>
                                                                </Typography>

                                                            </FormControl>

                                                        </div>


                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                marginLeft: 10,
                                                                marginRight: 10,
                                                                marginBottom: 10,
                                                                marginTop: 5
                                                            }}
                                                        >
                                                            <FormControl >
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="outlined-basic"
                                                                    label="Video Size"
                                                                    variant="outlined"
                                                                    style={{
                                                                        width: 350,
                                                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                                                    }}
                                                                    classes={{ root: classes1.customTextField }}
                                                                    defaultValue={videoSize}
                                                                    onChange={handleSizeChange}
                                                                // error={titleErr}
                                                                // helperText={titleHelp}
                                                                />
                                                                <Typography>
                                                                    <span
                                                                        style={{
                                                                            fontSize: 12,
                                                                            color: "#464E5F",
                                                                            marginLeft: 5,
                                                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                                                        }}
                                                                    >
                                                                        Please enter <span
                                                                            style={{
                                                                                color: "#464E5F",
                                                                                fontWeight: "600",
                                                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                                                fontSize: 12
                                                                            }}>
                                                                            Video Size
                                                                        </span>
                                                                    </span>
                                                                </Typography>

                                                            </FormControl>

                                                        </div>




                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    marginTop: 20,
                                                                    marginLeft: 15,
                                                                    marginRight: 20,
                                                                }}
                                                            >
                                                                <label
                                                                    style={{
                                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                                        backgroundColor: "steelblue",
                                                                        color: "white",
                                                                        padding: 5,
                                                                        borderRadius: 4,
                                                                        cursor: "pointer",
                                                                        textAlign: "center",
                                                                        width: "100%"
                                                                    }}

                                                                >
                                                                    Upload Recorded Video
                                                                    <FileUploader
                                                                        hidden
                                                                        // accept="image/*"
                                                                        storageRef={firebase.storage().ref("images")}
                                                                        onUploadStart={handleUploadStartVid}
                                                                        onUploadError={handleUploadErrorVid}
                                                                        onUploadSuccess={handleUploadSingleSuccessVid}
                                                                        onProgress={handleProgressVid}
                                                                    />
                                                                </label>
                                                            </div>



                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                {progressVid ? <CircularProgress /> : <></>}
                                                            </div>

                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                {videoDownloadUrls && (
                                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        <CloudUploadOutlinedIcon style={{ marginRight: 5 }} />
                                                                        <Typography>
                                                                            VideoUploaded
                                                                        </Typography>

                                                                    </div>
                                                                )}


                                                            </div>
                                                        </div>

                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                marginTop: 20,
                                                                marginLeft: 15,
                                                                marginRight: 20,
                                                            }}

                                                            onClick={() => SaveVideo(anchor, false)}
                                                        >
                                                            <Button variant="contained" color="primary" fullWidth>
                                                                Save Video
                                                            </Button>
                                                        </div>

                                                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnackAlert}>
                                                            <Alert onClose={handleCloseSnackAlert} severity="success">
                                                                Video is saved successfully!
                                                            </Alert>
                                                        </Snackbar>



                                                    </Drawer>
                                                </React.Fragment>
                                            ))}
                                        </div>

                                    </div>
                                </Grid>



                                <Grid
                                    item
                                    lg={9}
                                    sm={12}
                                    xl={9}
                                    xs={12}
                                >
                                    {
                                        videoUrl ?
                                            <ReactPlayer url={videoUrl} width="95%" height="80vh" style={{ marginTop: 30, marginLeft: 20 }} controls={true} />
                                            :
                                            getEventVideos && allVideosGetData && allVideosGetData.data && allVideosGetData.data.length > 0 ?
                                                <ReactPlayer url={allVideosGetData.data[0].video_location} width="95%" height="80vh" style={{ marginTop: 30, marginLeft: 20 }} controls={true} />
                                                :
                                                <Alert severity="warning" style={{ width: "95%", marginTop: 30, marginLeft: 20 }}>
                                                    <AlertTitle>No Video in Library</AlertTitle>
                                                    Add Video — <strong>Add videos to this event to view</strong>
                                                </Alert>
                                    }

                                    {
                                        viddata ?
                                            <EventVideoTabs videoData={viddata} />
                                            :
                                            getEventVideos && allVideosGetData && allVideosGetData.data && allVideosGetData.data.length > 0 ?
                                                <EventVideoTabs videoData={allVideosGetData.data[0]} />
                                                :
                                                <Alert severity="warning" style={{ width: "95%", marginTop: 30, marginLeft: 20 }}>
                                                    <AlertTitle>No Video in Library</AlertTitle>
                                                    Add Video — <strong>Add videos to this event to view</strong>
                                                </Alert>

                                    }
                                </Grid>


                                <Grid
                                    item
                                    lg={3}
                                    sm={3}
                                    xl={3}
                                    xs={3}
                                    style={{ marginTop: 20 }}
                                >
                                    {
                                        getEventVideos && allVideosGetData && allVideosGetData.data && allVideosGetData.data.length > 0 ?
                                            allVideosGetData.data.map((list, index) => {
                                                return (
                                                    // <ReactPlayer url={list.video_location} width="100%" height="30%" style={{ marginLeft: 5, marginTop: 10 }} />
                                                    <AccordianCard videoData={list} key={index} id={index + 1} VideoDataUpd={(vidData) => { UpdateVideoData(vidData) }} />
                                                )

                                            })
                                            :
                                            <Alert severity="warning" style={{ marginTop: 10 }}>
                                                <AlertTitle>No Videos!</AlertTitle>
                                                Add Videos— <strong>By using upload video button above</strong>
                                            </Alert>
                                    }
                                </Grid>



                            </Grid>
                        </Container>


                    </main>
                </>

                :
                <></>
            }
        </div>
    );
}