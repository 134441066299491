/**
 * setup action creators for current map
 */

export const CURRICULUMMAP_CURR_REQUEST = 'CURRICULUM_CURR_REQUEST';
export const CURRICULUMMAP_CURR_SUCCESS = 'CURRICULUM_CURR_SUCCESS';
export const CURRICULUMMAP_CURR_FAILURE = 'CURRICULUM_CURR_FAILURE';

// action creators
export const getCurrMap = () => ({ // already returns
    type: CURRICULUMMAP_CURR_REQUEST
})

export const getCurrMapSuccess = (data) => ({
    type: CURRICULUMMAP_CURR_SUCCESS,
    payload: data
})

export const getCurrMapFailure = () => ({
    type: CURRICULUMMAP_CURR_FAILURE
})

// async thunk action to update
export function updateCurrMap(data) {
    return async (dispatch) => {
        dispatch(getCurrMap());

        try {
            console.log(`updating currmap with data ${JSON.stringify(data)}`)
            // update data
            dispatch(getCurrMapSuccess(data))

            // cleanup
        } catch (error) {
            dispatch(getCurrMapFailure())
        }

    }
}