import React, { useEffect, useState } from "react";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {
    Route,
    Link,
    BrowserRouter as Router,
    Switch,
    Redirect,
    HashRouter,
    useHistory,
} from "react-router-dom";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import "../../css/sidebar.css";
import Leftbar from "./slots/Leftbar";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { listDashBoardContHead, listDashBoardHead } from "../../redux/actions/DashBoardActions";
import PropTypes from 'prop-types';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import AddPricing from "./slots/AddPricing";
import EditPricing from "./slots/EditPricing";
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FilterListIcon from '@material-ui/icons/FilterList';
import Input from '@mui/material/Input';

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Snackbar, TextField } from "@material-ui/core";
import { Divider, Grid } from "@material-ui/core";

import API from '../../http/http'
import PARTNERAPI from '../../http/httppartner'
import PUBLISHERAPI from '../../http/publisher'

import { listBoardGet } from "../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../redux/actions/InstituteDetailsAction";

import { listStdGet } from "../../redux/actions/standardAction";
import { listContUsers, listPubUsers } from "../../redux/actions/PubUsersListAction";
import { listSubGet } from "../../redux/actions/subjectsAction";
import Cookie from "js-cookie";
import PublisherAPI from "../../http/publisher";
import { Alert, AlertTitle } from "@material-ui/lab";
import Copyright from "../user/slots/Copyright";

import Menu from '@material-ui/core/Menu';

import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tour from 'reactour'
import UserSession from "../../resource/UserSession";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import Breadcrumbs2 from '@mui/material/Breadcrumbs';
import Link2 from '@mui/material/Link';

const steps = [
    {
        selector: '[data-tut="first-step"]',
        content: 'The various filters help confine the results',
    },
    {
        selector: '[data-tut="second-step"]',
        content: 'The list displays publishers connected with you',
    },

    // ...
];

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        scrollbarWidth: "thin"
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue",
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        justifyContent: "space-between",
        minHeight: "100%",
        height: "100%",
        minWidth: "100%",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0,
    },
}));

const useStyles3 = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    root1: {
        maxWidth: 200,
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        marginTop: theme.spacing(3),
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },

    formControl1: {
        margin: theme.spacing(1),
        minWidth: 320,
        minHeight: 30,
    },
    primary: {
        width: "25%",
        height: 50,
    },
}));

const useStyles4 = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
            </Button>
            {/* <MoreVertIcon  aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} /> */}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={props.toggleDrawer2(true, props.row)}>Edit</MenuItem>
                <MenuItem onClick={handleClickOpen}>Delete</MenuItem>
                {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
            </Menu>

            <Dialog
                open={open}
                onClose={handleClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the pricing?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClose} color="primary">
                        NO
                    </Button>
                    <Button onClick={props.delPricing(props.row)} color="primary" autoFocus>
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}


function handleClick2(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

function BasicBreadcrumbs() {
    return (
        <div role="presentation" onClick={handleClick2}
            style={{
                marginTop: 10
            }}>
            <Breadcrumbs2 aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    to="/publisher-content/my-media/media-components"
                >
                    My Media Components
                </Link>
                <Typography color="text.primary">Subscription Setup (Open)</Typography>
            </Breadcrumbs2>
        </div>
    );
}



const rows1 = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

const useStyles1 = makeStyles({
    table: {
        minWidth: 700,
    },
    textField: {
        padding: '10px !important'
    }
});


const FilterFields = (props) => {
    const classes = useStyles3();
    const [instType, setInstType] = useState("");
    const [boardType, setBoardType] = useState("");
    const [subjectType, setSubjectType] = useState("");
    const [standardType, setStandardType] = useState("");
    const [pubId, setPubId] = useState(2);

    const dispatch = useDispatch();

    const handleInstChange = (event) => {
        console.log("called handleInstChange");
        console.log(`new institute type ${event}`);
        const name = event.target.name;
        const value = event.target.value;
        // setState({
        // ...state,
        // [name]: event.target.value,
        // });
        dispatch(listBoardGet(value));
        setInstType(value);
        dispatch(listContUsers(value, boardType, standardType, subjectType));

    };

    const handleBoardChange = (event) => {
        console.log("called handleBoardChange");
        console.log(`new board  ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        console.log(`board selected value ${value}`);

        setBoardType(value);
        dispatch(listStdGet(instType, value));
        dispatch(listSubGet(value));
        dispatch(listContUsers(instType, value, standardType, subjectType));

    };

    const handleSubChange = (event) => {
        console.log("called handleSubChange");
        console.log(`new subject ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        setSubjectType(value);

        if (value) {
            dispatch(listContUsers(instType, boardType, standardType, value));
        }
    };

    const handleStdChange = (event) => {
        console.log("called handleStdChange");
        console.log(`new standard ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        setStandardType(value);
        //dispatch(listSubGet(instType))

        const pub_id = pubId;
        // if(value){
        //   console.log("handlestdChange ==============================",instType,boardType,value,pub_id)
        //   dispatch(listSearchEbookGet(instType,boardType,value,pub_id))
        // }
        dispatch(listContUsers(instType, boardType, standardType, subjectType));

    };
    const boardDetailsGet = useSelector((state) => state.boardDetails);
    const { loading, boardDetails, error } = boardDetailsGet;

    const instDetailsGet = useSelector((state) => state.instDetails);
    const { loadingInst, instDetails, errorInst } = instDetailsGet;

    const subDetailsGet = useSelector((state) => state.subDetails);
    const { loadingSub, subDetails, errorSub } = subDetailsGet;

    const stdDetailsGet = useSelector((state) => state.stdDetails);
    const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

    const contUsersListGet = useSelector((state) => state.contUsersGet);
    const { loadingContUsers, contUsers, errorContUsers } = contUsersListGet;

    {
        loadingContUsers && contUsersListGet
            ? console.log("users data is loading")
            : console.log("users data is ", contUsers);
    }

    let rows = [];
    if (!loadingContUsers && contUsersListGet && contUsers && contUsers.data) {
        contUsers.data.map((users, index) => {
            if (users && users.status) {
                users.status.map((usersList, index) => {
                    rows.push(usersList);
                });
            }
        });
    }
    // props.usersList(rows)


    useEffect(() => {
        dispatch(listInstGet());
        return () => {
            //
        };
    }, []);

    return (
        <Paper elevation={2} style={{ marginLeft: 10 }}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    padding: 10,
                    overflow: "auto",
                    overflowX: "initial",
                    scrollbarWidth: "thin"
                    //  marginLeft:20
                }}
            >

                {/* <div > */}

                {/* <div style={{ display: "flex", justifyContent: "center" ,marginTop:10}}>
  
  
                            <TextField 
                            id="outlined-basic" 
                            label="Search by Name" 
                            variant="outlined"
                            defaultValue={title}
                            onChange={handleTitleChange}
                      
                                style={{
                                    width:250,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    display:"inline-block",
                                    lineHeight:"140%"
  
                      
                                }}
                                
                            />
                        </div>
  
                        <div style={{ display: "flex", justifyContent: "Left" }}>
                        <Typography>
                                <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Search by  <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                      Name
                            </span>
                                </span>
                            </Typography>
  
                            </div>
  
                    </div>
  
  */}

                {instDetailsGet ? (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Institute Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={instType}
                                onChange={handleInstChange}
                                label="Institute Type"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                {loadingInst && instDetailsGet ? (
                                    console.log("loading trueeee")
                                ) : instDetails &&
                                    instDetails.data &&
                                    instDetails.data.length != 0 ? (
                                    instDetails.data.map((list, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={list.institute_type_id}
                                                style={{
                                                    fontSize: 13,
                                                    color: "#464E5F",
                                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                                }}
                                            >
                                                {list.institute_type_name}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem
                                        style={{
                                            fontSize: 13,
                                            color: "#464E5F",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        No Institutes
                                    </MenuItem>
                                )}
                            </Select>
                            {/* 
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Institute
                                </span>
                            </span> */}
                        </FormControl>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Institute Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={instType}
                                onChange={handleInstChange}
                                label="Institute Type"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    No Institutes
                                </MenuItem>
                            </Select>
                            {/* 
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Institute
                                </span>
                            </span> */}
                        </FormControl>
                    </div>
                )}

                {boardDetailsGet &&
                    boardDetails &&
                    boardDetails.length != 0 &&
                    boardDetails.data ? (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Board
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={boardType}
                                onChange={handleBoardChange}
                                label="Board"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                {
                                    loading && boardDetailsGet ? (
                                        console.log("loading trueeee")
                                    ) : boardDetails && boardDetails.length != 0 ? (
                                        boardDetails.data.map((list, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={list.institute_board_id}
                                                    style={{
                                                        fontSize: 13,
                                                        color: "#464E5F",
                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                    }}
                                                >
                                                    {list.institute_board_name}
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem
                                            style={{
                                                fontSize: 13,
                                                color: "#464E5F",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                            }}
                                        >
                                            Please Select Institute Type
                                        </MenuItem>
                                    )

                                    // boardDetailsMock.map((list, index) => {
                                    //     return (
                                    //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>
                                    //     )
                                    // })
                                }
                            </Select>
                            {/* <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Board
                                </span>
                            </span> */}
                        </FormControl>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Board
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={boardType}
                                onChange={handleBoardChange}
                                label="Board"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    No Boards{" "}
                                </MenuItem>
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Board
                                </span>
                            </span>
                        </FormControl>
                    </div>
                )}

                {stdDetailsGet ? (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Standard
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={standardType}
                                onChange={handleStdChange}
                                label="Standard"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                {
                                    loadingStd && stdDetailsGet ? (
                                        console.log("loading trueeee")
                                    ) : stdDetails.length != 0 ? (
                                        stdDetails.data.map((list, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={list.id}
                                                    style={{
                                                        fontSize: 13,
                                                        color: "#464E5F",
                                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                                    }}
                                                >
                                                    {list.standard}
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem
                                            style={{
                                                fontSize: 13,
                                                color: "#464E5F",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                            }}
                                        >
                                            Please Select Institute Type
                                        </MenuItem>
                                    )

                                    // standardsMockData.map((list, index) => {
                                    //     return (
                                    //         <MenuItem key={index} value={index} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.stand_name}</MenuItem>
                                    //     )
                                    // })
                                }
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Standard
                                </span>
                            </span>
                        </FormControl>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Standard
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={standardType}
                                onChange={handleStdChange}
                                label="Standard"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    No Standards
                                </MenuItem>
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Standard
                                </span>
                            </span>
                        </FormControl>
                    </div>
                )}

                {subDetails ? (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Subject
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={subjectType}
                                onChange={handleSubChange}
                                label="Subject"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                {loadingSub && subDetails ? (
                                    <MenuItem
                                        key={0}
                                        value="Please Select Board"
                                        style={{
                                            fontSize: 13,
                                            color: "#464E5F",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        {" "}
                                        Please Select Board{" "}
                                    </MenuItem>
                                ) : subDetails &&
                                    subDetails.data &&
                                    subDetails.data.subjects.length != 0 ? (
                                    subDetails.data.subjects.map((list, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={list.subject_id}
                                                style={{
                                                    fontSize: 13,
                                                    color: "#464E5F",
                                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                                }}
                                            >
                                                {list.subject_name}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem
                                        style={{
                                            fontSize: 13,
                                            color: "#464E5F",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Please Select Institute Type
                                    </MenuItem>
                                )}
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Subject
                                </span>
                            </span>
                        </FormControl>
                    </div>
                ) : (
                    <div style={{ display: "flex" }}>
                        <FormControl variant="outlined" className={classes.formControl1}>
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                Subject
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={subjectType}
                                onChange={handleSubChange}
                                label="Subject"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >
                                <MenuItem
                                    style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    No Subjects{" "}
                                </MenuItem>
                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select{" "}
                                <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12,
                                    }}
                                >
                                    Subject
                                </span>
                            </span>
                        </FormControl>
                    </div>
                )}
            </div>
        </Paper>
    );
};


const TEACHROW = (props) => {
    const { index, teachSlabs, teachPrc } = props
    return (
        <div style={{
            display: "flex", justifyContent: "space-between", marginTop: 20,
            alignItems: 'center'
        }}

            key={index + 666}>
            <FormControl>
                <TextField
                    key={`teach${index}`}
                    id={`outlined-basic${index}`}
                    label="User count"
                    variant="outlined"
                    style={{
                        width: 150,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    inputProps={{
                        style: {
                            padding: 10
                        }
                    }}
                    // defaultValue={teachSlabs[index]}
                    value={teachSlabs[index]}
                    onChange={(e) => props.handleTeachUserChange(e, index)}
                    // autoFocus="autoFocus"
                    ref={(input) => {
                        if (input) {
                            input.focus();
                        }
                    }}
                />

            </FormControl>
            <Typography
                variant="body1">
                above
            </Typography>

            <FormControl>
                <TextField
                    key={`teach1${index}`}
                    id={`outlined-start-adornment-${index}`}
                    label="Price"
                    variant="outlined"
                    style={{
                        width: 150,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    sx={{ m: 0, p: 0, width: '25ch' }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        style: {
                            // padding: 0
                        }
                    }}
                    // defaultValue={teachPrc[index]}
                    value={teachPrc[index]}
                    size="small"
                    onChange={(e) => props.handleTeachPriceChange(e, index)}
                    // autoFocus="autoFocus"
                    ref={(input) => {
                        if (input) {
                            input.focus();
                        }
                    }}
                />

            </FormControl>

            <DeleteIcon
                style={{
                    cursor: 'pointer'
                }}
                onClick={(e) => props.onDelRow(index)}
            />

        </div>

    )
}


const LEARNROW = (props) => {
    const { index, learnSlabs, learnPrc } = props

    console.log('learnprc at index ' + index + ' is ' + learnPrc[index]);
    return (
        <div style={{
            display: "flex", justifyContent: "space-between", marginTop: 20,
            alignItems: 'center'
        }}
            key={index}>
            <FormControl>
                <TextField
                    key={`learn${index}`}
                    id={`outlined-basic-${index}`}
                    label="User count"
                    variant="outlined"
                    style={{
                        width: 150,
                        padding: 0,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    inputProps={{
                        style: {
                            padding: 10
                        }
                    }}
                    // defaultValue={learnSlabs[index]}
                    value={learnSlabs[index]}
                    onChange={(e) => props.handleLearnUserChange(e, index)}
                    // autoFocus="autoFocus"
                    ref={(input) => {
                        if (input) {
                            input.focus();
                        }
                    }}
                />

            </FormControl>
            <Typography
                variant="body1">
                above
            </Typography>

            <FormControl>
                {/* 
            <InputLabel htmlFor="standard-adornment-amount">Price</InputLabel>
            <Input
                id="standard-adornment-amount"
                value={0}
                // onChange={handleChange('amount')}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
            /> */}

                {/* 
            <TextField
                id="outlined-basic"
                label="Price"
                variant="outlined"
                style={{
                    width: 150,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                inputProps={{
                    style: {
                        padding: 10
                    }
                }}
                defaultValue={0}

            /> */}

                <TextField
                    key={`learn1${index}`}
                    label="Price"
                    id={`outlined-start-adornment-${index}`}
                    variant="outlined"
                    style={{
                        width: 150,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    sx={{ m: 0, p: 0, width: '25ch' }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        style: {
                            // padding: 0
                        }
                    }}
                    // defaultValue={learnPrc[index]}
                    value={learnPrc[index]}
                    size="small"
                    onChange={(e) => props.handleLearnPriceChange(e, index)}
                    // autoFocus="autoFocus"
                    ref={(input) => {
                        if (input) {
                            input.focus();
                        }
                    }}
                />

            </FormControl>

            <DeleteIcon
                style={{
                    cursor: 'pointer'
                }}
                onClick={(e) => props.onDelLearnRow(index)}
            />

        </div>

    )
}

const FIRSTTEACHROW = (props) => {
    const { teachSlabs, teachPrc } = props
    return (

        <FormControl>
            <TextField
                key={`teach-1`}
                id="outlined-start-adornment-1"
                label="Price"
                variant="outlined"
                style={{
                    width: 150,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                sx={{ m: 0, p: 0, width: '25ch' }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    style: {
                        // padding: 0
                    }
                }}
                // defaultValue={teachPrc[0]}
                value={teachPrc[0]}
                onChange={(e) => props.handleTeachPriceChange(e, 0)}
                size="small"
            />

        </FormControl>

    )
}

const FIRSTLEARNROW = (props) => {
    const { learnSlabs, learnPrc } = props

    console.log(`firstlearnrow learnprc `, learnPrc)
    console.log(`firstlearnrow learnprc[0] `, learnPrc[0])
    return (
        <FormControl
        >
            <TextField
                key={`learn-2`}
                id="outlined-start-adornment-2"
                label="Price"
                variant="outlined"
                style={{
                    width: 150,
                    fontFamily: "Poppins, Helvetica, sans-serif",

                }}
                sx={{ m: 0, p: 0, width: '25ch' }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    style: {
                        // padding: 0
                    }
                }}
                // defaultValue={learnPrc[0]}
                value={learnPrc[0]}
                onChange={(e) => props.handleLearnPriceChange(e, 0)}
                size="small"
            // autoFocus="autoFocus"
            />

        </FormControl>
    )
}


const PRICESETUP = (props) => {
    const { pricing, learnSlabs, learnPrc, teachSlabs, teachPrc,
        learnCount, rowCount, } = props;

    const dispatch = useDispatch();

    console.log('learnprc in pricesetup', JSON.stringify(learnPrc))
    console.log('pricing in PRICESETUP ', JSON.stringify(pricing));
    // clear everything first

    if (pricing) {
        console.log('pricing is ', pricing)


    }

    useEffect(() => {
        // resetData()
        // setup slabs and pricing
        // setupSlabsAndPricing()
        return {

        }
    }, [])


    const priceRows = (
        [...Array((rowCount - 1))].map((e, i) => {
            return (<TEACHROW
                teachSlabs={teachSlabs}
                teachPrc={teachPrc}
                key={(i + 1)}
                index={(i + 1)}
                handleTeachUserChange={(e, index) => props.handleTeachUserChange(e, index)}
                handleTeachPriceChange={(e, index) => props.handleTeachPriceChange(e, index)}
                onDelRow={(index) => props.onDelRow(index)}
            />);
        })
    )

    const learnRows = (
        [...Array((learnCount - 1))].map((e, i) => {
            return (<LEARNROW learnSlabs={learnSlabs}
                learnPrc={learnPrc}
                key={(i + 1)}
                index={(i + 1)}
                handleLearnUserChange={(e, index) => props.handleLearnUserChange(e, index)}
                handleLearnPriceChange={(e, index) => props.handleLearnPriceChange(e, index)}
                onDelLearnRow={(index) => props.onDelLearnRow(index)} />);
        })
    )

    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <Typography
                variant="body2">
                Set Price for your entire content you are providing for this subject (videos, audios, images, documents)
            </Typography>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: 20,
                    width: '100%'
                }}>

                {/*                             
            <div style={{
                display: "flex", justifyContent: "space-around", marginTop: 20,
                alignItems: 'center',
                marginBottom: 30,

                width: '50%'

            }}>

                <FormControl>
                    <TextField
                        id="outlined-basic"
                        label="Enter learn price"
                        variant="outlined"
                        style={{
                            width: 350,
                            fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                        defaultValue={annumCost}
                        onChange={handleAnnumCostChange}
                    />

                </FormControl>

            </div> */}


                <div
                    style={{
                        width: '45%',
                        background: '#F5F5F5',
                        borderRadius: 10,
                        padding: 20
                    }}>
                    <Typography
                        variant="h2">
                        Learn
                    </Typography>

                    <Typography
                        variant="caption">
                        Subscription for students to learn
                    </Typography>

                    {/* <Chip label="Learn Price" color="primary" /> */}

                    <div style={{
                        display: "flex", justifyContent: "space-between", marginTop: 20,
                        alignItems: 'center'
                    }}>
                        <Typography
                            variant="h5">
                            Standard Price Per User
                        </Typography>

                        <FIRSTLEARNROW
                            index={0}
                            learnSlabs={learnSlabs}
                            learnPrc={learnPrc}
                            pricing={pricing}
                            handleLearnPriceChange={(e, index) => props.handleLearnPriceChange(e, index)}
                        />

                    </div>



                    <Divider
                        style={{
                            margin: 20
                        }} />

                    <Typography
                        variant="h5">
                        Discount Pricing
                    </Typography>

                    {(learnCount > 0)
                        ? learnRows
                        :
                        <></>
                    }

                    {/* <Divider
                    style={{
                        margin: 20
                    }} /> */}
                    <Button
                        style={{
                            marginTop: 20,
                            textTransform: 'none'
                        }}
                        variant="contained" endIcon={<AddIcon />}
                        onClick={props.onAddLearnRow}>
                        Add Slab
                    </Button>
                </div>


                <Divider />



                {/* TEACH SECTION */}
                <div style={{
                    height: 20
                }}></div>

                <div
                    style={{
                        width: '45%',
                        background: '#F5F5F5',
                        borderRadius: 10,
                        padding: 20
                    }}>
                    <Typography
                        variant="h2">
                        Teach
                    </Typography>

                    <Typography
                        variant="caption">
                        Subscription for teachers to teach
                    </Typography>

                    {/* <Chip label="Teach Price" color="secondary" /> */}


                    <div style={{
                        display: "flex", justifyContent: "space-between", marginTop: 20,
                        alignItems: 'center'
                    }}>
                        <Typography
                            variant="h5">
                            Standard Price Per User
                        </Typography>


                        <FIRSTTEACHROW
                            index={0}
                            teachSlabs={teachSlabs}
                            teachPrc={teachPrc}
                            handleTeachPriceChange={(e, index) => props.handleTeachPriceChange(e, index)}
                        />
                    </div>


                    <Divider
                        style={{
                            margin: 20
                        }} />

                    <Typography
                        variant="h5">
                        Discount Pricing
                    </Typography>


                    {(rowCount > 0)
                        ? priceRows
                        :
                        <></>
                    }


                    <Button variant="contained" endIcon={<AddIcon />}
                        onClick={props.onAddRow}
                        style={{
                            marginTop: 20,
                            textTransform: 'none'
                        }}>
                        Add Slab
                    </Button>
                </div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 20,
                width: '100%'
            }}>
                <Button variant="contained" color="primary"
                    onClick={props.onSavePricing}>SUBMIT</Button>

            </div>
        </div>

    )
}


const ACCORDION = (props) => {
    const { subject, learnSlabs, learnPrc, teachSlabs, teachPrc,
        rowCount, priceRows, learnCount, learnRows, contProvId,
        pricing, isILC, instType, boardType, standardType } = props
    const [sub, setSub] = useState(null)
    const [expanded, setExpanded] = React.useState(false);


    console.log('inside accordion learnprc ', learnPrc)
    console.log('isILC ', isILC)

    const dispatch = useDispatch();

    const insType = localStorage.getItem("pustak.kgtopg.inst_type");
    const brdType = localStorage.getItem("pustak.kgtopg.board_type");
    const stdType = localStorage.getItem("pustak.kgtopg.standard_type");


    const getSubjectPricing = async (subId) => {
        // const provId2 = Cookie.get("kgtopg.partner.cont.id");
        // get data
        let response;

        if (!isILC) {
            response = await PUBLISHERAPI.getSubjectPricing(contProvId, subId)
        } else {
            response = await PUBLISHERAPI.getSubjectILCPricing(contProvId, subId, insType, brdType, stdType)
        }

        console.log(`get subject pricing ${JSON.stringify(response)}`)

        // setApiCalled(false);
        return response;
    }

    // const [pricing, setPricing] = useState(null)
    const getSubPricing = async () => {
        const pricing = await getSubjectPricing(subject.subject_id);
        console.log(`pricing for subject ${JSON.stringify(pricing)}`)
        const data = (pricing && pricing.data && pricing.data.data) ? pricing.data.data : null
        console.log(`pricing for subject data is ${JSON.stringify(data)}`)
        props.setPricing(data)


        // update pricing here
        if (data) {
            const subId = data.subject_id;
            const teachCosts = data.teach_costs;
            const learnCosts = data.learn_costs;
            let teachSlabs2 = []
            let teachPrc2 = []
            let learnSlabs2 = []
            let learnPrc2 = []

            if (teachCosts && teachCosts.length) {
                teachCosts.map(cost => {
                    teachSlabs2.push(cost.user_count)
                    teachPrc2.push(cost.monthly_cost)
                })
            }

            if (learnCosts && learnCosts.length) {
                learnCosts.map(cost2 => {
                    learnSlabs2.push(cost2.user_count)
                    learnPrc2.push(cost2.monthly_cost)
                })
            }

            props.setSubId(subId)

            console.log('teach costs ------ ', teachCosts)
            console.log('learn costs ------ ', learnCosts)

            // reset counts
            if (!teachCosts || !teachCosts.length) {
                props.resetTeachData()
                console.log('resetting teach data')
            } else {

                props.setTeachSlabs(teachSlabs2)
                props.setTeachPrc(teachPrc2)

                props.setRowCount(teachSlabs2.length)
            }

            if (!learnCosts || !learnCosts.length) {
                props.resetLearnData();

                console.log('resetting learn data')
            } else {

                props.setLearnSlabs(learnSlabs2)
                props.setLearnPrc(learnPrc2)

                props.setLearnCount(learnSlabs2.length)

            }

        } else {
            // set up fresh
            props.resetData()
        }
    }

    useEffect(() => {
        // getSubPricing();
        return () => {
            //
        };
    }, []);

    // if (!sub) {
    //     return <></>
    // }


    const handleChange = (panel) => async (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        props.resetData()

        if (isExpanded) {
            // if (!apiCalled) {

            getSubPricing();
            // setApiCalled(true)
            // }
        }

        console.log('handleChange called accordion..')

    };

    return (
        <Accordion expanded={expanded === subject.subject_name} onChange={handleChange(subject.subject_name)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >

                <Typography sx={{ color: 'text.secondary' }}>{subject.subject_name}</Typography>
            </AccordionSummary>
            <AccordionDetails>

                <PRICESETUP pricing={pricing}
                    learnSlabs={learnSlabs}
                    learnPrc={learnPrc}
                    teachSlabs={teachSlabs}
                    teachPrc={teachPrc}
                    handleLearnPriceChange={(e, index) => props.handleLearnPriceChange(e, index)}
                    handleTeachPriceChange={(e, index) => props.handleTeachPriceChange(e, index)}
                    rowCount={rowCount}
                    priceRows={priceRows}
                    learnCount={learnCount}
                    learnRows={learnRows}

                    handleTeachUserChange={(e, index) => props.handleTeachUserChange(e, index)}
                    handleTeachPriceChange={(e, index) => props.handleTeachPriceChange(e, index)}
                    handleLearnUserChange={(e, index) => props.handleLearnUserChange(e, index)}
                    handleLearnPriceChange={(e, index) => props.handleLearnPriceChange(e, index)}

                    onDelRow={(index) => props.onDelRow(index)}
                    onDelLearnRow={(index) => props.onDelLearnRow(index)}

                    onAddRow={props.onAddRow}
                    onAddLearnRow={props.onAddLearnRow}
                    onSavePricing={props.onSavePricing}
                />
            </AccordionDetails>
        </Accordion>
    )
}

const ACCORDIONS = (props) => {
    const { subjects, isILC } = props;
    console.log('all my subjects in accordions ', subjects)
    console.log('learnprc in accordions ', props.learnPrc)

    if (subjects && subjects.data && subjects.data.length) {
        return subjects.data.map(subj => {
            return <ACCORDION subject={subj}
                setPricing={props.setPricing}
                setSubId={props.setSubId}

                learnPrc={props.learnPrc}
                learnSlabs={props.learnSlabs}
                teachSlabs={props.teachSlabs}
                teachPrc={props.teachPrc}
                rowCount={props.rowCount}
                priceRows={props.priceRows}
                learnCount={props.learnCount}
                learnRows={props.learnRows}

                resetTeachData={props.resetTeachData}
                setTeachSlabs={props.setTeachSlabs}
                setTeachPrc={props.setTeachPrc}
                setRowCount={props.setRowCount}
                resetLearnData={props.resetLearnData}
                setLearnSlabs={props.setLearnSlabs}
                setLearnPrc={props.setLearnPrc}
                setLearnCount={props.setLearnCount}
                resetData={props.resetData}
                pricing={props.pricing}

                handleTeachUserChange={(e, index) => props.handleTeachUserChange(e, index)}
                handleTeachPriceChange={(e, index) => props.handleTeachPriceChange(e, index)}
                handleLearnUserChange={(e, index) => props.handleLearnUserChange(e, index)}
                handleLearnPriceChange={(e, index) => props.handleLearnPriceChange(e, index)}
                onDelRow={(index) => props.onDelRow(index)}
                onDelLearnRow={(index) => props.onDelLearnRow(index)}

                onAddRow={props.onAddRow}
                onAddLearnRow={props.onAddLearnRow}
                onSavePricing={props.onSavePricing}
                contProvId={props.contProvId}
                isILC={isILC}
            />
        })
    } else {
        return <></>
    }
}

function ControlledAccordions(props) {
    const { mySubjects, contProvId, isILC } = props;

    const [sbType, setSbType] = useState("")
    const [weekCost, setWeekCost] = useState(1)
    const [monCost, setMonCost] = useState(1)
    const [annumCost, setAnnumCost] = useState(0)
    const [fromDate, setFromDate] = useState(new Date)
    const [toDate, setToDate] = useState(new Date)
    const [allSubjects, setAllSubjects] = useState(null)
    const [rowCount, setRowCount] = useState(1)
    const [learnCount, setLearnCount] = useState(1)

    const [value, setValue] = React.useState(0);
    const [agree, setAgree] = useState(false)

    const [tags, setTags] = useState('')

    const [teachSlabs, setTeachSlabs] = useState([0])
    const [teachPrc, setTeachPrc] = useState([0]) // first default 0
    const [currTeachPrcIndex, setCurrTeachPrcIndex] = useState(0)
    const [currTeachUsrIndex, setCurrTeachUsrIndex] = useState(-1)

    const [learnSlabs, setLearnSlabs] = useState([0])
    const [learnPrc, setLearnPrc] = useState([0])
    const [subId, setSubId] = useState(0)

    const [apiCalled, setApiCalled] = useState(false)
    const [subData, setSubData] = useState(null)
    const [pricing, setPricing] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleSnackClick = () => {
        setOpen(true);
    };

    const handleSnackOpen = (open) => {
        setSnackOpen(open);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    };

    const styles5 = useStyles1()

    const dispatch = useDispatch();

    const handleTagsChange = (e) => {
        const { name, value } = e.target

        setTags(value)
    }

    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };

    const handleAgreeToggle = (e) => {
        console.log(e.target.checked)

        setAgree(e.target.checked)
    }

    const handleWeekCostChange = (event) => {
        console.log(`new weekcost ${event}`);
        const { name, value } = event.target;
        setWeekCost(value);
    };

    const handleMonCostChange = (event) => {
        console.log(`new moncost ${event}`);
        const { name, value } = event.target;
        setMonCost(value);
    };

    const handleAnnumCostChange = (event) => {
        console.log(`new annumcost ${event}`);
        const { name, value } = event.target;
        setAnnumCost(value);
    };

    const handleFromDateChange = (event, newValue) => {
        const name = event.target.name;
        const value = event.target.value;

        setFromDate(value);
    };

    const handleToDateChange = (event, newValue) => {
        const name = event.target.name;
        const value = event.target.value;

        setToDate(value);
    };

    const getAllSubjects = async () => {
        const response = await PUBLISHERAPI.getAllSubjects();
        const subs = response.data;
        console.log('all subjects ', subs)

        setAllSubjects(subs)
    }

    const setupAccordions = async () => {
        // getAllSubjects()
    }

    /* called after render completed.. */
    useEffect(() => {

        // getAllSubjects()

        setSubData(null)
        setupAccordions()
        return () => {
            //
        };
    }, []);


    const action = (
        <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleSnackClose}>
                UNDO
            </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const onAddRow = () => {
        console.log('row count before add ', rowCount)
        let rowCnt = rowCount;
        rowCnt = rowCnt + 1;
        console.log('row cnt after add ', rowCnt)
        // now insert fresh data
        let teachSlb = teachSlabs.slice()
        teachSlb.push(0)
        setTeachSlabs(teachSlb)

        let teachPr = teachPrc.slice()
        teachPr.push(0)
        setTeachPrc(teachPr)

        // update row count
        setRowCount(rowCnt);

        console.log('rowCount after add ', rowCount);
    }

    const onDelRow = (index) => {

        console.log('teachPrc', teachPrc)
        console.log('teachSlabs', teachSlabs)


        console.log('ondelrow called..')
        console.log('delRow index ', index)
        console.log('rowcount before ', rowCount)
        let rowCnt = rowCount;
        rowCnt = rowCnt - 1;

        console.log('rowcount after ', rowCnt)
        // delete from array
        let teachSlb = teachSlabs.slice()
        console.log('teachslb before filter', teachSlabs)

        teachSlb = teachSlb.filter((elm, idx) => parseInt(idx) !== parseInt(index))
        console.log('teachslb after filter ', teachSlb)
        setTeachSlabs(teachSlb)

        // price
        let teachPr = teachPrc.slice()
        teachPr = teachPr.filter((elm, idx) => parseInt(idx) !== parseInt(index))
        setTeachPrc(teachPr)
        setRowCount(rowCnt); // refresh


        // console.log('teachPrc', teachPrc)
        // console.log('teachSlabs', teachSlabs)
    }

    const onAddLearnRow = () => {
        console.log('row count before add ', learnCount)
        let rowCnt = learnCount;
        rowCnt = rowCnt + 1;
        console.log('row cnt after add ', rowCnt)

        let learnSlb = learnSlabs.slice()
        learnSlb.push(0)
        setLearnSlabs(learnSlb)

        // now prices
        let learnPr = learnPrc.slice()
        learnPr.push(0)

        setLearnPrc(learnPr)
        setLearnCount(rowCnt);
    }

    const onDelLearnRow = (index) => {

        console.log('learnPrc', learnPrc)
        console.log('learnSlabs', learnSlabs)

        console.log('ondelrow called..')
        console.log('delRow index ', index)
        console.log('rowcount before ', learnCount)
        let rowCnt = learnCount;
        rowCnt = rowCnt - 1;

        console.log('rowcount after ', rowCnt)
        // delete user
        let learnSlb = learnSlabs.slice()

        console.log('learnslb before ', learnSlabs)
        learnSlb = learnSlb.filter((elm, idx) => parseInt(idx) !== parseInt(index))
        console.log('leanrslb after ', learnSlb)
        setLearnSlabs(learnSlb)

        // delete price
        let learnPr = learnPrc.slice()
        learnPr = learnPr.filter((elm, idx) => parseInt(idx) !== parseInt(index))
        setLearnPrc(learnPr)
        setLearnCount(rowCnt); // refresh
    }

    const handleTeachUserChange = (event, index) => {
        const { value } = event.target;

        console.log(`user change index ${index} and value ${value}`)
        let teachSlb = teachSlabs.slice()

        teachSlb[index] = value

        console.log('current slab ', teachSlb[index])
        setTeachSlabs(teachSlb)
    }

    const handleTeachPriceChange = (event, index) => {
        const { value } = event.target;

        console.log(`price change index ${index} and value ${value}`)
        let teachPr = teachPrc.slice()
        teachPr[index] = value

        console.log('current price ', teachPr[index])
        setTeachPrc(teachPr)
    }

    const handleLearnUserChange = (event, index) => {
        const { value } = event.target;

        console.log(`learn user change index ${index} and value ${value}`)
        let learnSlb = learnSlabs.slice()

        learnSlb[index] = value
        setLearnSlabs(learnSlb)
    }

    const handleLearnPriceChange = (event, index) => {
        const { value } = event.target;

        console.log(`learn price change index ${index} and value ${value}`)
        let learnPr = learnPrc.slice()
        learnPr[index] = value
        setLearnPrc(learnPr)
    }

    const onSavePricing = async () => {
        // save
        const provId2 = Cookie.get("kgtopg.partner.cont.id");

        // setup array of learn combos
        let learnCombo = []
        let teachCombo = []
        learnSlabs.map((slb, idx) => {
            let lPrc = learnPrc[idx]
            const obj = {
                ucount: slb,
                price: lPrc
            }
            learnCombo.push(obj)
        })

        teachSlabs.map((slb2, idx2) => {
            let tPrc = teachPrc[idx2]
            const obj2 = {
                ucount: slb2,
                price: tPrc
            }

            teachCombo.push(obj2)
        })

        let instTypeId = 0
        let instBoardId = 0
        let clrStd = 0

        const insType = localStorage.getItem("pustak.kgtopg.inst_type");
        const brdType = localStorage.getItem("pustak.kgtopg.board_type");
        const stdType = localStorage.getItem("pustak.kgtopg.standard_type");


        if (isILC) {
            console.log('isILC ', isILC)

            instTypeId = insType
            instBoardId = brdType
            clrStd = stdType
        }

        const body = {
            content_prov_id: provId2,
            subject_id: subId,
            learn_costs: learnCombo,
            teach_costs: teachCombo,
            institute_type_id: instTypeId,
            institute_board_id: instBoardId,
            classroom_std: clrStd
        }

        console.log('savepricing body ', body)

        if (!teachCombo.length) {
            alert('Please fill in details before saving..')
        }

        const response = await PUBLISHERAPI.postContProvPricing(body)

        // reset
        // resetData()
        handleSnackOpen(true)
    }

    const resetData = () => {
        // reset ########### BE CAREFUL ############
        resetTeachData()

        resetLearnData()
    }

    const resetTeachData = () => {
        setRowCount(1)
        setTeachPrc([0])
        setTeachSlabs([0])

    }

    const resetLearnData = () => {
        setLearnCount(1)
        setLearnPrc([0])
        setLearnSlabs([0])

    }

    return (
        <div>

            <ACCORDIONS subjects={mySubjects}
                setPricing={setPricing}
                setSubId={setSubId}
                resetTeachData={resetTeachData}
                setTeachSlabs={setTeachSlabs}
                setTeachPrc={setTeachPrc}
                setRowCount={setRowCount}
                resetLearnData={resetLearnData}
                setLearnSlabs={setLearnSlabs}
                setLearnPrc={setLearnPrc}
                setLearnCount={setLearnCount}
                contProvId={contProvId}
                resetData={resetData}
                pricing={pricing}

                learnPrc={learnPrc}
                learnSlabs={learnSlabs}
                teachSlabs={teachSlabs}
                teachPrc={teachPrc}
                rowCount={rowCount}

                learnCount={learnCount}


                handleTeachUserChange={(e, index) => handleTeachUserChange(e, index)}
                handleTeachPriceChange={(e, index) => handleTeachPriceChange(e, index)}
                handleLearnUserChange={(e, index) => handleLearnUserChange(e, index)}
                handleLearnPriceChange={(e, index) => handleLearnPriceChange(e, index)}
                onDelRow={(index) => onDelRow(index)}
                onDelLearnRow={(index) => onDelLearnRow(index)}

                onAddRow={onAddRow}
                onAddLearnRow={onAddLearnRow}
                onSavePricing={onSavePricing}
                contProvId={contProvId}
                isILC={isILC}
            />
            {/*
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1', 108)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>

                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>MATHEMATICS</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    {PRICESETUP}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>SOCIAL SCIENCE</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>

                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    
                    {PRICESETUP}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        ENGLISH
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>

                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    
                    {PRICESETUP}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>CHEMISTRY</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    

                    {PRICESETUP}
                </AccordionDetails>
            </Accordion>
            */}


            <Snackbar
                color="success"
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message="Pricing saved successfully.."
                action={action}
            />
        </div>
    );
}


export default function PriceSetup() {
    const classes1 = useStyles1();
    const classes = useStyles();
    const classes2 = useStyles4();

    const [isLoadingscr, setIsLoadingscr] = useState(false);

    // table states 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [drawOpen, setDrawOpen] = useState(false);
    const [editDrawOpen, setEditDrawOpen] = useState(false);
    const [pricingList, setPricingList] = useState(null)
    const [actvRow, setActvRow] = useState(null)
    const [mySubjects, setMySubjects] = useState(null)
    const [contProvId, setContProvId] = useState(0)

    const [instType, setInstType] = useState((actvRow) ? actvRow.institute_type_id : "");
    const [boardType, setBoardType] = useState((actvRow) ? actvRow.institute_board_id : "");
    const [subjectType, setSubjectType] = useState((actvRow) ? actvRow.subject_id : "");
    const [standardType, setStandardType] = useState((actvRow) ? actvRow.classroom_std : '');

    const [state, setState] = React.useState({
        right: false,
    });

    const boardDetailsGet = useSelector((state) => state.boardDetails);
    const { loading, boardDetails, error } = boardDetailsGet;

    const instDetailsGet = useSelector((state) => state.instDetails);
    const { loadingInst, instDetails, errorInst } = instDetailsGet;

    const subDetailsGet = useSelector((state) => state.subDetails);
    const { loadingSub, subDetails, errorSub } = subDetailsGet;

    const stdDetailsGet = useSelector((state) => state.stdDetails);
    const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

    //fetched data
    let [rows, setRows] = useState(null)

    // table functions
    console.log("rows ", rows)

    const dispatch = useDispatch();

    const handleSnackClose = () => {
        setSnackOpen(false);
    };

    const handleSnackOpen = () => {
        setSnackOpen(true);
    };

    const handleTourOpen = () => {
        setIsTourOpen(true)
    }

    const handleInstChange = (event) => {
        console.log("called handleInstChange");
        console.log(`new institute type ${event}`);
        const name = event.target.name;
        const value = event.target.value;
        // setState({
        // ...state,
        // [name]: event.target.value,
        // });


        // update to local storage
        localStorage.setItem("pustak.kgtopg.inst_type", value)

        setInstType(value);
        dispatch(listBoardGet(value));
        dispatch(listStdGet(value));

    };

    const handleBoardChange = (event) => {
        console.log("called handleBoardChange");
        console.log(`new board  ${event}`);

        const name = event.target.name;
        const value = event.target.value;
        console.log(`board selected value ${value}`);

        // update to local storage
        localStorage.setItem("pustak.kgtopg.board_type", value)

        setBoardType(value);
        // get subjects
        dispatch(listSubGet(value));
    };

    const handleStdChange = (event) => {
        console.log("called handleStdChange");
        console.log(`new standard ${event}`);

        const name = event.target.name;
        const value = event.target.value;

        // update to local storage
        localStorage.setItem("pustak.kgtopg.standard_type", value)
        setStandardType(value);
    };

    const getPriceSetup = async () => {
        const userId = Cookie.get(UserSession.userIdKey);
        setIsLoadingscr(true)

        const resp = await PUBLISHERAPI.getPricingSetup(userId)

        console.log("response at price setup list", resp)
        let temp = resp.data
        if (temp) {
            setRows(temp)
        }

        setIsLoadingscr(false)
    }

    const getMySubjects = async () => {
        const provId2 = Cookie.get("kgtopg.partner.cont.id");
        let response = null

        console.log('my subjects data 1 ', mySubjects)
        setMySubjects([])

        console.log(`getting components subjects`)
        response = await PUBLISHERAPI.getMySubjects(provId2);


        console.log('my subjects data 3', response.data)
        const subs = response.data

        setMySubjects(subs);

    }

    const getMySubjects2 = async () => {
        const userId = Cookie.get(UserSession.userIdKey);
        setContProvId(userId)
        setIsLoadingscr(true)

        const resp = await PUBLISHERAPI.getMySubjects(userId)

        console.log("response at my subjects list", resp)
        let temp = resp.data
        if (temp) {
            setMySubjects(temp)
        }

        setIsLoadingscr(false)
    }


    const setSelections = () => {
        const insType = localStorage.getItem("pustak.kgtopg.inst_type");
        const brdType = localStorage.getItem("pustak.kgtopg.board_type");
        const stdType = localStorage.getItem("pustak.kgtopg.standard_type");

        // update state
        if (insType) {
            setInstType(insType)
            // get boards filled up
            dispatch(listBoardGet(insType));
            dispatch(listStdGet(insType));

        }

        if (brdType)
            setBoardType(brdType)

        if (stdType)
            setStandardType(stdType)
    }

    useEffect(() => {

        // get from local storage
        setSelections()

        getPriceSetup()
        getMySubjects()

        return () => {
            //
        };
    }, []);

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawOpen(open)
        setState({ ...state });

    };

    const toggleDrawer2 = (open, row) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setActvRow(row)
        setEditDrawOpen(open)
        setState({ ...state });

    };

    const delPricing = (row) => async (event) => {
        const userId = Cookie.get(UserSession.userIdKey);

        setIsLoadingscr(true)

        console.log(`trying for delete pricing ${JSON.stringify(row)}`)

        const resp = await PUBLISHERAPI.delPricing(userId, row.content_prov_cost_id);

        setIsLoadingscr(false)
        getPriceSetup()
    }

    return (
        <div className={classes.root}>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0
            }}>
                <LiveHelpIcon
                    onClick={handleTourOpen} />
            </div>
            {/* {isLoadingscr == false ? ( */}
            {true ? (
                <>
                    <CssBaseline />
                    <LinearProgress />

                    <Leftbar />

                    <main className={classes.content}>
                        <Helmet>
                            <title> Multimedia Price Setup For Components | Pustak Publish</title>
                        </Helmet>

                        <Container
                            className={classes.container}
                            style={{
                                minHeight: "100%",
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: 20,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: 10
                                }}>
                                <div>
                                    <Typography
                                        // className={classes.title}
                                        id="tableTitle"
                                        component="div"
                                        /* style={{
                                          fontSize: 16,
                                          fontWeight: "500",
                                          fontFamily: "Poppins, Helvetica, sans-serif",
                                        }} */
                                        variant="h3"
                                    >
                                        Price Setup For Components
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        style={{
                                            fontWeight: 'normal'
                                        }}>
                                        Setup pricing of your content
                                    </Typography>


                                    <BasicBreadcrumbs />

                                </div>
                                {/* old pricing
                                <React.Fragment>
                                    <div data-tut="fourth-step"
                                        style={{
                                            // width: "20%",
                                        }}>
                                        <Button
                                            onClick={toggleDrawer(true)}
                                            variant="contained"
                                            color="primary"
                                            className={classes.primary}
                                            style={{
                                                width: "100%",
                                                backgroundColor: "#3699FF",
                                                textTransform: "none",
                                            }}
                                        >
                                            <AddCircleOutlineIcon />
                                            Add Pricing
                                        </Button>
                                    </div>
                                    <Drawer
                                        anchor={`right`}
                                        open={drawOpen}
                                        onClose={toggleDrawer(false)}
                                    >
                                        <AddPricing

                                            key="addPricing"
                                            onReload={() => getPriceSetup()}
                                            onCloseClicked={(anchor2) => {
                                                toggleDrawer(false)

                                                setState({ ...state });

                                                // show snackbar
                                                handleSnackOpen();
                                                // get list of content back
                                            }}

                                        />
                                    </Drawer>


                                    <Drawer
                                        anchor={`right`}
                                        open={editDrawOpen}
                                        onClose={toggleDrawer2(false)}
                                    >
                                        <EditPricing
                                            actvRow={actvRow}
                                            key="editPricing"
                                            onReload={() => getPriceSetup()}
                                            onCloseClicked={() => {
                                                toggleDrawer2(false)

                                                setState({ ...state });

                                                // show snackbar
                                                handleSnackOpen();
                                                // get list of content back
                                            }}

                                        />
                                    </Drawer>
                                </React.Fragment> */}
                            </div>


                            <div data-tut="first-step"
                                style={{
                                    display: 'none'
                                }}>
                                <FilterFields />
                            </div>

                            <div
                                style={{
                                    padding: 0,
                                    marginTop: 20
                                }}
                                data-tut="second-step">

                                <Typography
                                    variant="h5">
                                    Media Components
                                </Typography>
                                <br />
                                {(isLoadingscr) ?
                                    <CircularProgress />
                                    :
                                    (rows && rows.length > 0) ?







                                        <ControlledAccordions
                                            mySubjects={mySubjects}
                                            contProvId={contProvId}
                                            isILC={false} />

                                        :
                                        <>
                                            <div className={classes2.root}>
                                                <Alert severity="warning">
                                                    <AlertTitle>No Media Components Pricing Setup done yet!</AlertTitle>
                                                    You have not yet setup any pricing for Media Components — <strong>try adding them!</strong>
                                                </Alert>
                                            </div>
                                        </>

                                }
                            </div>


                        </Container>

                        <Copyright />
                    </main>

                </>
            ) : (
                <></>
            )}



            <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
            />
        </div>
    );
}




{/*
                                        <TableContainer component={Paper}>
                                            <Table className={classes1.table} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>SNO #</StyledTableCell>
                                                        
                                                        <StyledTableCell align="center">Subject</StyledTableCell>
                                                        




                                                        <StyledTableCell align="center">Teach Price</StyledTableCell>
                                                        <StyledTableCell align="center">Learn Price</StyledTableCell>

                                                        

                                                        <StyledTableCell align="center">Action</StyledTableCell>
                                                        
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>


                                                    {rows.map((row, index) => (
                                                        <StyledTableRow key={row.content_prov_cost_id}>
                                                            <StyledTableCell component="th" scope="row">
                                                                
                                                                {(index + 1)}
                                                            </StyledTableCell>
                                                            
                                                            <StyledTableCell align="center">{row.sub_name}</StyledTableCell>
                                                            

                                                            
                                                            <StyledTableCell align="center">{row.annual_cost}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.monthly_cost}</StyledTableCell>
                                                            <StyledTableCell align="center"><ActionMenu
                                                                row={row}
                                                                delPricing={delPricing}
                                                                toggleDrawer2={toggleDrawer2}
                                                            /></StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                                            */}
