import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AddAdmin from './AddAdmin';
import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CommentIcon from '@material-ui/icons/Comment';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { listPubRolesGet, listPubRolesPut, listPubSendMail } from '../../../../redux/actions/AdminControlsAction';

import Cookie from 'js-cookie';
import PublisherAPI from '../../../../http/publisher';
import { Alert, AlertTitle } from '@material-ui/lab';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router';
import { updateAdminRoles } from '../../../../redux/actions/adminRolesAction';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//dropdown styles
const useStyles3 = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const useStyles4 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));


//send invite field styles
const useStyles2 = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "auto",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: 250,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));



const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles5 = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

function SimpleDialog(props) {
    const classes = useStyles5();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Remove member</DialogTitle>
            <DialogContent dividers>
                <Typography
                    variant="body1">
                    Do you want to remove the member?
            </Typography>

            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.onDeletePub(props.id)} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogDemo(props) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            {/* <IconButton aria-label="delete" color="secondary" size="small"
                // onClick={() => props.onDeleteCont(props.id)}
                onClick={handleClickOpen}>
                <DeleteOutlineOutlinedIcon fontSize="inherit" />
            </IconButton> */}
            <Typography
            onClick={handleClickOpen}
            variant="body1">
                Delete
            </Typography>
            <SimpleDialog selectedValue={selectedValue} onDeletePub={(id) => props.onDeletePub(id)} id={props.id} open={open} onClose={handleClose} />
        </div>
    );
}

/* Role update dialog */

function SimpleDialog2(props) {
    const dispatch = useDispatch()
    const adminRoles = useSelector(state => state.adminRoles.adminRoles)
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
    const [chkIds, setChkIds] = useState(adminRoles)

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const { pubRolesGet, index, checkIds, roleIds, row, currUsrId } = props;
    console.log(`pubRolesGet in dialog ${pubRolesGet}`)

    // this function is not useful, not called when required...
    const loadChecked = () => {
        // if checked are empty, set with role ids
        console.log(`dialog row id is ${row.id}`)
        console.log(`dialog currUsrId is ${currUsrId}`)
        console.log(`dialog checkIds is ${adminRoles}`)

        let newCheckedIds = []
        if (row.id != currUsrId && currUsrId > 0) {
            // new guy refresh checkedids
            newCheckedIds = []

        } else { // same guy again
            // first time
            newCheckedIds = adminRoles

        }

        if (!newCheckedIds.length) {
            if (roleIds.length) {
                newCheckedIds = roleIds

                console.log(`called  setcheckedids force`)
                setChkIds(newCheckedIds)

                // update to parent through props
                // props.setCheckIds(newCheckedIds)
            }
        }

        // update current user
        props.setCurrUsrId(row.id)
    }

    useEffect(() => {

        // check boxes auto-checked
        loadChecked()
        return (
            <></>
        )
    }, [])

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Choose Roles</DialogTitle>
            <DialogContent dividers>
                <List className={classes.root}>
                    {pubRolesGet && pubRolesGet.map((value, idx) => {
                        const labelId = `checkbox-list-label-${value}`;
                        const isSel = roleIds.indexOf(value.id) > -1

                        return (
                            <MenuItem value={labelId}
                                selected={isSel}>
                                <ListItem key={value} role={undefined} dense button onClick={props.handleToggle(value, index)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={adminRoles.indexOf(value.id) !== -1}
                                            // checked={roleIds.indexOf(value.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <PersonOutlineOutlinedIcon />
                                        <ListItemText id={labelId} primary={value.role_name} />
                                    </div>                                                                {/* <ListItemSecondaryAction>
                                                                    <IconButton edge="end" aria-label="comments">
                                                                        <CommentIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction> */}
                                </ListItem>
                            </MenuItem>
                        );
                    })}
                </List>
            </DialogContent>


            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>

                <Button variant="contained" color="primary" onClick={() => { handleClose(); props.UpdateRoles(row.id) }}>
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    );
}

SimpleDialog2.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogDemo2(props) {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);
    const [checkIds, setCheckIds] = React.useState(props.checkIds)
    const { pubRolesGet, index, roleIds, row, currUsrId } = props;

    console.log(`checkIds inside simpledialogdemo ${props.checkIds}`)

    const loadChecked = () => {
        // if checked are empty, set with role ids
        console.log(`button click row id is ${row.id}`)
        console.log(`button click currUsrId is ${currUsrId}`)

        let newCheckedIds = []
        if (row.id != currUsrId && currUsrId > 0) {
            // new guy refresh checkedids
            newCheckedIds = []

        } else { // same guy again
            // first time
            newCheckedIds = props.checkIds

        }

        if (!newCheckedIds.length) {
            if (roleIds.length) {
                newCheckedIds = roleIds

                console.log(`button click called  setcheckedids force`)
                setCheckIds(newCheckedIds)

                // update to parent through props
                props.setCheckIds(newCheckedIds)

                // update to redux - prefilled
                dispatch(updateAdminRoles(newCheckedIds))
            }
        }

        // update current user
        props.setCurrUsrId(row.id)
    }

    const handleClickOpen = () => {
        setCheckIds(props.checkIds)

        // props.setCheckIds(props.checkIds)
        loadChecked()
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            {/* <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
            <br /> */}
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Roles
            </Button>

            <SimpleDialog2 selectedValue={selectedValue} open={open} onClose={handleClose}
                checkIds={checkIds}
                roleIds={roleIds}
                index={index}
                pubRolesGet={pubRolesGet}
                UpdateRoles={(id) => props.UpdateRoles(id)}
                row={row}
                handleToggle={(value, index) => props.handleToggle(value, index)}
                setCurrUsrId={(id) => props.setCurrUsrId(id)}
                currUsrId={currUsrId}
                setCheckIds={(chkIds) => props.setCheckIds(chkIds)} />
        </div>
    );
}




//sending invite field component
function SendingMailBar(props) {
    const classes = useStyles();
    const classes2 = useStyles2();
    const dispatch = useDispatch()
    const [age, setAge] = React.useState('');
    const [checked, setChecked] = React.useState([]);
    const [checkedId, setCheckedId] = React.useState([]);
    const [email, setEmail] = React.useState("");
    const [openSnack, handleOpenSnack] = React.useState(false)

    const handleSnackClose = () => {
        handleOpenSnack(false)
    }

    const handleChange = (event) => {
        // setAge(event.target.value);
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        const newCheckedId = [...checkedId]
        if (currentIndex === -1) {
            newChecked.push(value);
            newCheckedId.push(value.id)
        } else {
            newChecked.splice(currentIndex, 1);
            newCheckedId.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        setCheckedId(newCheckedId)

    };


    const PostInvite = async () => {
        const userId = Cookie.get('kgtopg.partner.user.id')
        console.log("checkid at posting", checkedId)
        await new Promise((resolve, reject) => {
            dispatch(listPubSendMail(userId, email, checkedId))
            resolve()
        })

        // show snackbar
        handleOpenSnack(true)

        props.handleReloadUsers()
    }

    useEffect(() => {
        console.log(`useeffect called in sendingmailuser`)
        dispatch(listPubRolesGet())

        return (() => {
            //
        })

    }, [])

    const pubSendMailPost = useSelector(state => state.pubSendMail)
    const { loadingPubSendMail, pubSendMail, errorPubSendMail } = pubSendMailPost


    const pubRolesGetData = useSelector(state => state.pubRolesGet)
    const { loadingPubRoleGet, pubRolesGet, errorPubRolesGet } = pubRolesGetData

    {
        loadingPubRoleGet && pubRolesGet ?
            console.log("data is loading")
            :
            console.log("pubRolesGet", pubRolesGet)

    }




    return (
        <div>
            <Paper component="form" className={classes2.root}>
                <IconButton className={classes2.iconButton} aria-label="menu">
                    <EmailOutlinedIcon />
                </IconButton>
                <InputBase
                    className={classes2.input}
                    placeholder="Enter Invitee email address"
                    inputProps={{ 'aria-label': 'Invitee email address' }}
                    onChange={(e) => { setEmail(e.target.value) }}
                />

                <Divider className={classes2.divider} orientation="vertical" />

                {/* <IconButton type="submit" className={classes2.iconButton} aria-label="search">
                    <SearchIcon />
                </IconButton> */}
                <FormControl variant="outlined" className={classes2.formControl} size="small">
                    <InputLabel >
                        <div style={{ display: "flex", justifyContent: "center", justifyItems: "center", alignItems: "center" }}>
                            <PersonOutlineOutlinedIcon />
                           Role
                        </div>
                    </InputLabel>
                    <Select
                        // labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={age}
                        onChange={handleChange}
                        label="Role"
                    >
                        {/* <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        */}

                        <List className={classes.root}>
                            {pubRolesGet && pubRolesGet.map((value, index) => {
                                const labelId = `checkbox-list-label-${value}`;

                                return (
                                    <MenuItem value={labelId}>
                                        <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(value) !== -1}
                                                    tabIndex={-1}
                                                    // disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <PersonOutlineOutlinedIcon />
                                                <ListItemText id={labelId} primary={value.role_name} />
                                            </div>
                                            {/* <ListItemSecondaryAction>
                                                                    <IconButton edge="end" aria-label="comments">
                                                                        <CommentIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction> */}
                                        </ListItem>
                                    </MenuItem>
                                );
                            })}
                        </List>
                        {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                </FormControl>

                <Divider className={classes2.divider} orientation="vertical" />
                <IconButton color="primary" className={classes2.iconButton} aria-label="directions" onClick={() => PostInvite()}>
                    <SendOutlinedIcon />
                </IconButton>
                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert2 onClose={handleSnackClose} severity="success">
                        You have successfully invited the user!
                    </Alert2>
                </Snackbar>
            </Paper>
        </div >
    )

}

// table cells styles
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const useStyles1 = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function AdminControls() {
    const classes = useStyles();
    const classes1 = useStyles1()
    const classes3 = useStyles3()
    const classes4 = useStyles4()
    const history = useHistory()

    const [age, setAge] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState([]);
    const [checkedIds, setCheckedIds] = React.useState([])

    const [allChecked, setAllChecked] = React.useState([])
    const [allCheckedIds, setAllCheckedIds] = React.useState([])
    const [admins, setAdmins] = React.useState(null)
    const [openSnack, handleOpenSnack] = React.useState(false)
    const [currUsrId, setCurrUsrId] = React.useState(0)
    const [opens, setOpens] = React.useState([])
    const [firstTime, setFirstTime] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [actvRow, setActvRow] = React.useState({id:0});

    const handleElClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setActvRow(row);
    };

    const handleElClose = () => {
        setAnchorEl(null);
    };

    const handleSnackClose = () => {
        handleOpenSnack(false)
    }

    const updateCheckIds = (checkIds) => {
        console.log(`updateCheckIds() called.. ${JSON.stringify(checkIds)}`)

        // update redux admin roles ids
        dispatch(updateAdminRoles(checkIds)) // they come when dialog of roles is pre-filled or updated..
        setCheckedIds(checkIds)
    }

    // const [allPubRolesGet, setAllPubRolesGet] = React.useState([])
    const [state, setState] = React.useState({
        right: false,
    });
    const [state1, setState1] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const toggleDrawer1 = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState1({ ...state1, [anchor]: open });
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = (index) => {
        setOpen(true);
    };

    const closeAllOpens = () => {
        let newOpens = []
        if (opens) {
            opens.map(opn => {
                newOpens.push(false)
            })

            console.log(`clearing all opens`)
            setOpens(newOpens)
        }
    }

    const handleToggle = (value) => () => {
        // const currentIndex = checked.indexOf(value);
        // const currentIndex = checkedIds.indexOf(value.id)
        const currentIndex = adminRoles.indexOf(value.id)
        console.log(`handleToggle currenIndex ${currentIndex}`)
        const newChecked = [...checked];
        const newCheckedIds = [...checkedIds]

        console.log(`toggle value value is ${JSON.stringify(value)}`)

        if (currentIndex === -1) {
            newChecked.push(value);
            newCheckedIds.push(value.id)
        } else {
            newChecked.splice(currentIndex, 1);
            newCheckedIds.splice(currentIndex, 1)
        }

        setChecked(newChecked);
        setCheckedIds(newCheckedIds)

        // update redux admin role ids
        dispatch(updateAdminRoles(newCheckedIds))
    };

    const onDeletePub = async (pubId) => {
        await PublisherAPI.deletePublisher(pubId)

        // call all admin users again..
        getPubAdmins()
    }


    const dispatch = useDispatch()

    const UpdateRoles = async (id) => {
        await new Promise((resolve, reject) => {
            dispatch(listPubRolesPut(id, checkedIds))
            resolve()
        })

        // clear selections
        setChecked([])
        setCheckedIds([])
        await new Promise((resolve, reject) => {
            dispatch(updateAdminRoles([])) // reset redux admin role selected ids
            resolve()
        })

        // reload admins
        await new Promise((resolve, reject) => {
            getPubAdmins()
            resolve()
        })

        // show snack
        handleOpenSnack(true)
    }

    let allPubRolesGet = []

    const pubRolesGetData = useSelector(state => state.pubRolesGet)
    const { loadingPubRoleGet, pubRolesGet, errorPubRolesGet } = pubRolesGetData

    const pubRolesPutData = useSelector(state => state.pubRolesPut)
    const { loadingPubRolePut, pubRolesPut, errorPubRolesPut } = pubRolesPutData

    const adminRoles = useSelector(state => state.adminRoles.adminRoles)

    {
        loadingPubRoleGet && pubRolesGet ?
            console.log("data is loading")
            :
            // console.log("pubRolesGet are", pubRolesGet)
            console.log('')

    }
    {
        loadingPubRolePut && pubRolesPut ?
            console.log("data is loading")
            :
            // console.log("pubRolesGet", pubRolesPut)
            console.log('')

    }

    const getPubAdmins = async () => {
        const userId = Cookie.get('kgtopg.partner.user.id')
        const resp = await PublisherAPI.getPubAdmins(userId);

        console.log(`response from get pub admins ${JSON.stringify(resp)}`)

        if (resp) {
            const admins = resp.data.data
            setAdmins(admins)

            // set up the opens array
            let opens = []
            admins.map(adm => {
                opens.push(false)
            })

            setOpens(opens)
        }

    }

    useEffect(() => {

        // dispatch(listPubRolesGet())
        getPubAdmins();
        return (() => {

        })
    }, [])
    /* 
    
        useEffect(() => {
            // set up all checked
    
            let AllPubRolesGet = []
            let AllChecked = []
            let AllCheckedIds = []
    
            rows.map((row) => {
                AllPubRolesGet.push(pubRolesGet)
                AllChecked.push([])
                AllCheckedIds.push([])
            })
    
            // setAllPubRolesGet(AllPubRolesGet)
            // setAllChecked(AllChecked)
            // setAllCheckedIds(AllCheckedIds)
    
        }) */

    // if (!allPubRolesGet || allPubRolesGet.length <= 0
    //     || (!allChecked || allChecked.length <= 0))
    //     return <></>

    // console.log(`allChecked  ${allChecked}`)
    // console.log(`allCheckedIds  ${allCheckedIds}`)
    const openActivityPage = (id) => {
        history.push(`/publisher-ebook/admin-activity/?role_id=${id}`)

    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: 20 }}>
                <div>
                    <Typography
                        variant="h4">
                        Admin Access Controls
                    </Typography>
                </div>
                <div>
                    <SendingMailBar
                        handleReloadUsers={() => getPubAdmins()} />
                </div>

                {/* 
                <div>


                    
                    {['right'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Button variant="contained" color="primary" onClick={toggleDrawer(anchor, true)}>
                                create Admin
                        </Button>
                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                <AddAdmin />
                            </Drawer>
                        </React.Fragment>
                    ))}
                </div>
                 */}
            </div>

            {(admins && admins.length) ?

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Name </StyledTableCell>
                                {/* <StyledTableCell align="center">Email</StyledTableCell> */}
                                <StyledTableCell align="center">Activity</StyledTableCell>
                                <StyledTableCell align="center">Approved</StyledTableCell>
                                {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                                <StyledTableCell align="center">Edit</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {rows.map((row, index) => ( */}
                            {admins.map((row, index) => {
                                let roleIds = []
                                let roles = row.role_name
                                if (roles && roles.length) {
                                    roles.map((role) => {
                                        roleIds.push(role.role_id)
                                    })
                                }
                                /* 
                                                                let newChecked = checked;
                                                                if (!checked.length)
                                                                    newChecked = roleIds
                                
                                                                if (roleIds) {
                                                                    // add unexisting
                                                                    newChecked.map((id, index) => {
                                                                        // check if doesn't exist
                                                                        if (roleIds.indexOf(id) === -1) {
                                                                            // add because it isn't there and has been selected now..
                                                                            roleIds.push(id)
                                
                                                                        }
                                                                    })
                                
                                                                    // now remove non existent in checked
                                                                    roleIds.map((id) => {
                                                                        if (newChecked.indexOf(id) === -1) {
                                                                            // remove as it is non-existent
                                                                            roleIds.splice(roleIds.indexOf(id), 1)
                                                                        }
                                                                    })
                                                                } else {
                                                                    roleIds = newChecked
                                                                }
                                 */
                                return (
                                    <StyledTableRow key={row.pub_name_text}>
                                        <StyledTableCell component="th" scope="row" align="left">
                                            <Typography
                                                variant="h5">
                                                {row.pub_name_text}
                                            </Typography>

                                            <Typography
                                                variant="caption">
                                                {row.pub_email_text}
                                            </Typography>

                                        </StyledTableCell>
                                        {/* <StyledTableCell align="center">{row.pub_email_text}</StyledTableCell> */}
                                        <StyledTableCell align="center">-</StyledTableCell>
                                        <StyledTableCell align="center">
                                            -
                                    </StyledTableCell>
                                        {/* <StyledTableCell align="center">
                                            <Button variant="outlined" onClick={() => { openActivityPage(row.id) }}>View Activity</Button>
                                        </StyledTableCell>
 */}
                                        <StyledTableCell align="center">





                                            <SimpleDialogDemo2
                                                pubRolesGet={pubRolesGet}
                                                checkIds={adminRoles}
                                                roleIds={roleIds}
                                                row={row}
                                                handleToggle={(value, index) => handleToggle(value, index)}
                                                index={index}
                                                UpdateRoles={(id) => UpdateRoles(id)}
                                                setCurrUsrId={(id) => setCurrUsrId(id)}
                                                currUsrId={currUsrId}
                                                setCheckIds={(chkIds) => updateCheckIds(chkIds)} />


                                            {/*
                                            <FormControl className={classes3.formControl} key={index}>
                                                <InputLabel id="demo-controlled-open-select-label">Role</InputLabel>
                                                <Select
                                                    labelId={`demo-controlled-open-select-label_${index}`}
                                                    id={`demo-controlled-open-select_${index}`}
                                                    // open={open}
                                                    // onClose={handleClose}
                                                    // onOpen={handleOpen}
                                                    // value={age}
                                                    onChange={handleChange}
                                                    onOpen={() => {
                                                        // if checked are empty, set with role ids
                                                        console.log(`row id is ${row.id}`)
                                                        console.log(`currUsrId is ${currUsrId}`)

                                                        let newCheckedIds = []
                                                        if (row.id != currUsrId && currUsrId > 0) {
                                                            // new guy refresh checkedids
                                                            newCheckedIds = []

                                                        } else { // same guy again
                                                            // first time
                                                            newCheckedIds = checkedIds

                                                        }

                                                        if (!newCheckedIds.length) {
                                                            if (roleIds.length) {
                                                                newCheckedIds = roleIds

                                                                console.log(`called  setcheckedids force`)
                                                                setCheckedIds(newCheckedIds)
                                                            }
                                                        }

                                                        // update current user
                                                        setCurrUsrId(row.id)

                                                    }}
                                                >
                                                    

                                                    <List className={classes.root}>
                                                        {pubRolesGet && pubRolesGet.map((value) => {
                                                            const labelId = `checkbox-list-label-${value}`;
                                                            // console.log(`value value is ${JSON.stringify(value)}`)
                                                            const isSel = checkedIds.indexOf(value.id) > -1

                                                            return (
                                                                <MenuItem value={labelId}
                                                                    selected={isSel}>
                                                                    <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                checked={checkedIds.indexOf(value.id) !== -1}
                                                                                // checked={roleIds.indexOf(value.id) !== -1}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                            <PersonOutlineOutlinedIcon />
                                                                            <ListItemText id={labelId} primary={value.role_name} />
                                                                        </div>
                                                                        
                                                                    </ListItem>
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </List>
                                                    <MenuItem value="">

                                                        <Button variant="contained" color="primary" onClick={() => { UpdateRoles(row.id) }}>
                                                            Save
</Button>
                                                    </MenuItem>

                                                    
                                                </Select>
                                            </FormControl>
                                                    */}






                                            {/* {['right'].map((anchor) => (
                                        <React.Fragment key={anchor}>

                                            <IconButton aria-label="delete" color="primary" size="small" onClick={toggleDrawer1(anchor, true)}>
                                                <EditOutlinedIcon fontSize="inherit" />
                                            </IconButton>
                                            <Drawer anchor={anchor} open={state1[anchor]} onClose={toggleDrawer1(anchor, false)}>
                                                <AddAdmin />
                                            </Drawer>
                                        </React.Fragment>
                                    ))} */}


                                        </StyledTableCell>

                                        <StyledTableCell align="center">

                                            {/* <SimpleDialogDemo onDeletePub={(id) => onDeletePub(id)} id={row.id} /> */}

                                            {/* <IconButton aria-label="delete" color="secondary" size="small"
                                                onClick={() => onDeletePub(row.id)}>
                                                <DeleteOutlineOutlinedIcon fontSize="inherit" />
                                            </IconButton> */}

                                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleElClick(e, row)}>
                                                <MoreVertIcon />
                                            </Button>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleElClose}
                                            >
                                                <MenuItem onClick={() => { openActivityPage(actvRow.id) }}>Activity</MenuItem>
                                                <MenuItem onClick={handleElClose}>
                                                    <SimpleDialogDemo onDeleteCont={(id) => {onDeletePub(id)}} id={actvRow.id} />
                                                </MenuItem>
                                                {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                                            </Menu>
                                        </StyledTableCell>

                                    </StyledTableRow>
                                )
                            }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Alert severity="warning">
                    <AlertTitle>No Admins!</AlertTitle>
                    You have not yet invited any admins — <strong>send an invite from above now!</strong>
                </Alert>
            }
            {/* update */}
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert2 onClose={handleSnackClose} severity="success">
                    You have successfully updated roles for the user!
                </Alert2>
            </Snackbar>
        </div>
    );
}