import axios from "axios";
import Constants from "../../resource/Constants"
import { ADMINCONTROLEGETDETAILS_LIST_FAIL, ADMINCONTROLEGETDETAILS_LIST_REQUEST, ADMINCONTROLEGETDETAILS_LIST_SUCCESS, ADMINCONTROLEPSAVEDETAILS_LIST_SUCCESS, ADMINCONTROLEPUTDETAILS_LIST_FAIL, ADMINCONTROLEPUTDETAILS_LIST_REQUEST, ADMINCONTROLEPUTDETAILS_LIST_SUCCESS, ADMINCONTROLESAVEDETAILS_LIST_FAIL, ADMINCONTROLESAVEDETAILS_LIST_REQUEST, ADMINPUBROLEGETDETAILS_LIST_FAIL, ADMINPUBROLEGETDETAILS_LIST_REQUEST, ADMINPUBROLEGETDETAILS_LIST_SUCCESS, ADMINPUBROLEPSAVEDETAILS_LIST_SUCCESS, ADMINPUBROLEPUTDETAILS_LIST_FAIL, ADMINPUBROLEPUTDETAILS_LIST_REQUEST, ADMINPUBROLEPUTDETAILS_LIST_SUCCESS, ADMINPUBROLESAVEDETAILS_LIST_FAIL, ADMINPUBROLESAVEDETAILS_LIST_REQUEST, ADMINPUBSENDMAILDETAILS_LIST_REQUEST, ADMINPUBSENDMAILDETAILS_LIST_SUCCESS, ADMINPUBSENDMAILDETAILS_LIST_FAIL, ADMINCONTSENDMAILDETAILS_LIST_REQUEST, ADMINCONTSENDMAILDETAILS_LIST_SUCCESS, ADMINCONTSENDMAILDETAILS_LIST_FAIL } from "../constants/AdminControlsConstants";
import Cookie from 'js-cookie';
import { ADMIN_ROLES_FAIL, ADMIN_ROLES_REQUEST, ADMIN_ROLES_SUCCESS } from "../constants/adminRolesConstants";





const listPubSendMail = (pubId, email, checkIds) => async (dispatch) => {

    try {

        dispatch({ type: ADMINPUBSENDMAILDETAILS_LIST_REQUEST })
        const body = {
            publisher_id: pubId,
            user_email: email,
            role: checkIds
        }
        const url = Constants.Application.PARTNER_END_URL + `/pub-details/`
        const options = {
            method: "POST",
            data: body,
            url
        }
        const { data } = await axios(options)

        console.log("listPubSendMail ======================", data)
        dispatch({ type: ADMINPUBSENDMAILDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ADMINPUBSENDMAILDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listPubRolesGet = () => async (dispatch) => {

    try {

        dispatch({ type: ADMINPUBROLEGETDETAILS_LIST_REQUEST })
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/user-role/`)

        console.log("listPubRolesGet ======================", data)
        dispatch({ type: ADMINPUBROLEGETDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ADMINPUBROLEGETDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listPubRolesPut = (id, checkedData) => async (dispatch) => {



    console.log("checkdata at axios pub roles ====",checkedData)
    try {

        dispatch({ type: ADMINPUBROLEPUTDETAILS_LIST_REQUEST })
        const ProvId = Cookie.get('kgtopg.partner.user.id')

        const body = {
            publisher_id: id,
            role: checkedData,
        }
        const url = Constants.Application.PARTNER_END_URL + `/role-update/`
        const options = {
            method: "PUT",
            data: body,
            url
        }

        const { data } = await axios(options)

        console.log("listPubRolesPut ======================", data)
        dispatch({ type: ADMINPUBROLEPUTDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ADMINPUBROLEPUTDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listPubRolesSave = () => async (dispatch) => {

    try {

        dispatch({ type: ADMINPUBROLESAVEDETAILS_LIST_REQUEST })
        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/inst-type-board/?type_id=10001`)

        console.log("listPubRolesSave ======================", data)
        dispatch({ type: ADMINPUBROLEPSAVEDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ADMINPUBROLESAVEDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listContSendMail = (provId, email, checkId) => async (dispatch) => {

    try {

        dispatch({ type: ADMINCONTSENDMAILDETAILS_LIST_REQUEST })
        const body = {
            cont_prov_id: provId,
            user_email: email,
            role: checkId,
        }

        const url =Constants.Application.PARTNER_END_URL + `/prov-admin-details/`
        const options ={
            method:"POST",
            data:body,
            url,
        }
        const { data } = await axios(options)

        console.log("listPubSendMail ======================", data)
        dispatch({ type: ADMINCONTSENDMAILDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ADMINCONTSENDMAILDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listContRolesGet = () => async (dispatch) => {

    try {

        dispatch({ type: ADMINCONTROLEGETDETAILS_LIST_REQUEST })
        dispatch({ type: ADMIN_ROLES_REQUEST })
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/prov-role/`)
        console.log("listContRolesGet ======================", data)

        let adminRoles = []
        // update the adminRoles reducer also
        data.map(adm => {
            adminRoles.push(adm.id)
        })

        await new Promise((resolve, reject) => {
            dispatch({ type: ADMINCONTROLEGETDETAILS_LIST_SUCCESS, payload: data })
            resolve()
        })
/* 
        await new Promise((resolve, reject) => {
            dispatch({ type: ADMIN_ROLES_SUCCESS, payload: adminRoles })
            resolve()
        })
 */
    } catch (error) {
        dispatch({ type: ADMINCONTROLEGETDETAILS_LIST_FAIL, payload: error.message })
        dispatch({ type: ADMIN_ROLES_FAIL, payload: error.message })

    }
}


const listContRolesPut = (id,checkedData) => async (dispatch) => {
    console.log("checked data at =====",checkedData)

    try {



        dispatch({ type: ADMINCONTROLEPUTDETAILS_LIST_REQUEST })
        // const ProvId = Cookie.get('kgtopg.partner.user.id')
           const ProvId = id
        const body = {
            cont_prov_id: ProvId,
            role: checkedData,
        }
        const url = Constants.Application.PARTNER_END_URL + `/prov-role-update/`

        const options = {
            method: "PUT",
            data: body,
            url
        }
        const { data } = await axios(options)

        console.log("listContRolesPut ======================", data)
        dispatch({ type: ADMINCONTROLEPUTDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ADMINCONTROLEPUTDETAILS_LIST_FAIL, payload: error.message })

    }
}

const listContRolesSave = () => async (dispatch) => {

    try {

        dispatch({ type: ADMINCONTROLESAVEDETAILS_LIST_REQUEST })
        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/inst-type-board/?type_id=10001`)

        console.log("listContRolesSave ======================", data)
        dispatch({ type: ADMINCONTROLEPSAVEDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: ADMINCONTROLESAVEDETAILS_LIST_FAIL, payload: error.message })

    }
}


export { listPubRolesGet, listPubRolesPut, listPubRolesSave, listContRolesGet, listContRolesPut, listContRolesSave, listPubSendMail, listContSendMail }