import axios from "axios";
import Constants from "../../../resource/Constants"
import { SINGLEEVENTPUT_LIST_FAIL, SINGLEEVENTPUT_LIST_REQUEST, SINGLEEVENTPUT_LIST_SUCCESS, SINGLEVIDEOPUT_LIST_FAIL, SINGLEVIDEOPUT_LIST_REQUEST, SINGLEVIDEOPUT_LIST_SUCCESS, SUBSCRIBERSDETAILAPUT_LIST_FAIL, SUBSCRIBERSDETAILAPUT_LIST_REQUEST, SUBSCRIBERSDETAILAPUT_LIST_SUCCESS, TRAINTEACHVIDEOSPUT_LIST_FAIL, TRAINTEACHVIDEOSPUT_LIST_REQUEST, TRAINTEACHVIDEOSPUT_LIST_SUCCESS } from "../../constants/Trainer/AllEventsPut";
import { listAllEventsGet, listAllVideosGet, listSubscribersGet, listTeachVideosGet } from "./EventsGet";
import Cookie from 'js-cookie'

const query = new URLSearchParams(window.location.search);
const event_id = query.get("eve_id");
const  ProvId = Cookie.get('kgtopg.partner.user.id')

const listSingleEventPut = (body, id) => async (dispatch) => {
    try {
        dispatch({ type: SINGLEEVENTPUT_LIST_REQUEST })

        const url = Constants.Application.PARTNER_END_URL + `/event/${id}`
        const options = {
            method: "PUT",
            data: body,
            url,
        }
        const { data } = await axios(options)
        console.log("listSingleEventPut ======================", data)
        dispatch({ type: SINGLEEVENTPUT_LIST_SUCCESS, payload: data })
        dispatch(listAllEventsGet(ProvId))
    } catch (error) {
        dispatch({ type: SINGLEEVENTPUT_LIST_FAIL, payload: error.message })

    }
}


const listSingleVideoPut = (body,videoId) => async (dispatch) => {
    try {
        dispatch({ type: SINGLEVIDEOPUT_LIST_REQUEST })

        const url = Constants.Application.PARTNER_END_URL + `/event-video/${videoId}`
        const options = {
            method: "PUT",
            data: body,
            url,
        }
        const { data } = await axios(options)
        console.log("listSingleVideoPut ======================", data)
        dispatch({ type: SINGLEVIDEOPUT_LIST_SUCCESS, payload: data })
        dispatch(listAllVideosGet(event_id))
    } catch (error) {
        dispatch({ type: SINGLEVIDEOPUT_LIST_FAIL, payload: error.message })

    }
}


const listSubscribersPut = (body,id) => async (dispatch) => {
    try {
        dispatch({ type: SUBSCRIBERSDETAILAPUT_LIST_REQUEST })

        const url = Constants.Application.PARTNER_END_URL + `/event-subscribe/${id}`
        const options = {
            method: "PUT",
            data: body,
            url,
        }
        const { data } = await axios(options)
        console.log("listSubscribersPut ======================", data)
        dispatch({ type: SUBSCRIBERSDETAILAPUT_LIST_SUCCESS, payload: data })
        dispatch(listSubscribersGet(event_id))
    } catch (error) {
        dispatch({ type: SUBSCRIBERSDETAILAPUT_LIST_FAIL, payload: error.message })

    }
}


const listTeachTrainVidPut = (body,id) => async (dispatch) => {
    try {
        dispatch({ type: TRAINTEACHVIDEOSPUT_LIST_REQUEST })

        const url = Constants.Application.PARTNER_END_URL + `/videos/${id}`
        const options = {
            method: "PUT",
            data: body,
            url,
        }
        const { data } = await axios(options)
        console.log("listSubscribersPut ======================", data)
        dispatch({ type: TRAINTEACHVIDEOSPUT_LIST_SUCCESS, payload: data })
        dispatch(listTeachVideosGet(body.institute_type_id,body.institute_board_id,body.subject_id,body.classroom_std))
        console.log("params at list TeachVid put",body.institute_type_id,body.institute_board_id,body.subject_id,body.classroom_id)
    } catch (error) {
        dispatch({ type: TRAINTEACHVIDEOSPUT_LIST_FAIL, payload: error.message })

    }
}



export {
    listSingleEventPut,
    listSingleVideoPut,
    listSubscribersPut,
    listTeachTrainVidPut,
}