
export const ALLEVENTSGET_LIST_REQUEST = ' ALLEVENTSGET_LIST_REQUEST'
export const ALLEVENTSGET_LIST_SUCCESS = '  ALLEVENTSGET_LIST_SUCCESS'
export const ALLEVENTSGET_LIST_FAIL = ' ALLEVENTSGET_LIST_FAIL'

export const ALLVIDEOSGET_LIST_REQUEST = ' ALLVIDEOSGET_LIST_REQUEST'
export const ALLVIDEOSGET_LIST_SUCCESS = '  ALLVIDEOSGET_LIST_SUCCESS'
export const ALLVIDEOSGET_LIST_FAIL = ' ALLVIDEOSGET_LIST_FAIL'


export const ALLEVENTSFILGET_LIST_REQUEST = ' ALLEVENTSFILGET_LIST_REQUEST'
export const ALLEVENTSFILGET_LIST_SUCCESS = '  ALLEVENTSFILGET_LIST_SUCCESS'
export const ALLEVENTSFILGET_LIST_FAIL = ' ALLEVENTSFILGET_LIST_FAIL'


export const ALLEVENTSSRCGET_LIST_REQUEST = ' ALLEVENTSSRCGET_LIST_REQUEST'
export const ALLEVENTSSRCGET_LIST_SUCCESS = '  ALLEVENTSSRCGET_LIST_SUCCESS'
export const ALLEVENTSSRCGET_LIST_FAIL = ' ALLEVENTSSRCGET_LIST_FAIL'

export const SINGLEEVENTGET_LIST_REQUEST = ' SINGLEEVENTSGET_LIST_REQUEST'
export const SINGLEEVENTSGET_LIST_SUCCESS = '  SINGLEEVENTSGET_LIST_SUCCESS'
export const SINGLEEVENTSGET_LIST_FAIL = ' SINGLEEVENTSGET_LIST_FAIL'


export const SINGLEVIDEOGET_LIST_REQUEST = ' SINGLEVIDEOGET_LIST_REQUEST'
export const SINGLEVIDEOGET_LIST_SUCCESS = '  SINGLEVIDEOGET_LIST_SUCCESS'
export const SINGLEVIDEOGET_LIST_FAIL = ' SINGLEVIDEOGET_LIST_FAIL'

export const SUBSCRIBERSDETAILSGET_LIST_REQUEST = ' SUBSCRIBERSDETAILSGET_LIST_REQUEST'
export const SUBSCRIBERSDETAILSGET_LIST_SUCCESS = '  SUBSCRIBERSDETAILSGET_LIST_SUCCESS'
export const SUBSCRIBERSDETAILSGET_LIST_FAIL = ' SUBSCRIBERSDETAILSGET_LIST_FAIL'


export const TRAINTEACHVIDEOSGET_LIST_REQUEST = ' TRAINTEACHVIDEOSGET_LIST_REQUEST'
export const TRAINTEACHVIDEOSGET_LIST_SUCCESS = '  TRAINTEACHVIDEOSGET_LIST_SUCCESS'
export const TRAINTEACHVIDEOSGET_LIST_FAIL = ' TRAINTEACHVIDEOSGET_LIST_FAIL'

export const TRAINTEACHVIDEOSTPCGET_LIST_REQUEST = ' TRAINTEACHVIDEOSTPCGET_LIST_REQUEST'
export const TRAINTEACHVIDEOSTPCGET_LIST_SUCCESS = '  TRAINTEACHVIDEOSTPCGET_LIST_SUCCESS'
export const TRAINTEACHVIDEOSTPCGET_LIST_FAIL = ' TRAINTEACHVIDEOSTPCGET_LIST_FAIL'
