import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Route, Link, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AddCurriculumDialog from './slots/AddCurriculumDialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import MapAPI from '../../http/curriculummap';
import { useDispatch, useSelector } from 'react-redux';
import { listCurriculumGet } from '../../redux/actions/curriculumMapAction';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles';
import { listUnitMapGet } from '../../redux/actions/unitMapAction';
import { updateCurrMap } from '../../redux/actions/currMapActions';
import { listBoardGet } from '../../redux/actions/boardDetailsAction';
import { listInstGet } from '../../redux/actions/InstituteDetailsAction';
import { listSubGet } from '../../redux/actions/subjectsAction';
import { listStdGet } from '../../redux/actions/standardAction';
import Cookie from 'js-cookie'
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
import PublisherAPI from '../../http/publisher';
import { listCurriculumAllGet } from '../../redux/actions/GetAllFilesAction';
const emails = ['username@gmail.com', 'user02@gmail.com'];

function createData(name, published, created, edit, deleteOption) {
  return { name, published, created, edit, deleteOption };
}

function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <CircularProgress /> */}
      <CircularProgress color="secondary" />
    </div>
  );
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'NAME' },
  // { id: 'published', numeric: false, disablePadding: false, label: 'PUBLISHED DATE' },
  // { id: 'created', numeric: false, disablePadding: false, label: 'DRAFT CREATED' },
  { id: 'published', numeric: false, disablePadding: false, label: 'TOTAL UNITS' },
  // { id: 'created', numeric: false, disablePadding: false, label: 'TOTAL CHAPTERS' },
  { id: 'edit', numeric: false, disablePadding: false, label: 'EDIT' },
  { id: 'deleteOption', numeric: false, disablePadding: false, label: 'DELETE' },
];

const useStyles5 = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles5();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Delete Map</DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="body1">
          Do you want to delete the map?
        </Typography>

      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => props.onDeleteMap(props.id)} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  console.log(`props id ${props.id}`)

  return (
    <div>
      {/* <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
          <br />
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
              Open simple dialog
          </Button>
           */}
      <IconButton aria-label="delete" color="secondary" size="small"
        // onClick={() => props.onDeleteCont(props.id)}
        onClick={handleClickOpen}>
        <DeleteOutlineOutlinedIcon fontSize="inherit" />
      </IconButton>
      <SimpleDialog selectedValue={selectedValue} onDeleteMap={(id) => props.onDeleteMap(id)} id={props.id} open={open} onClose={handleClose} />
    </div>
  );
}


const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        // [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        // <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
        //   {numSelected} selected
        // </Typography>
        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
          Curriculum - Maps
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
          Curriculum - Maps
        </Typography>
      )}



      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};




const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));



function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};





function Alert2(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  shape1: {
    backgroundColor: theme.palette.primary.main,
    width: 5,
    height: 20,
  },
  shape2: {
    backgroundColor: theme.palette.secondary.dark,
    width: 5,
    height: 20,
  },
  shape3: {
    backgroundColor: theme.palette.secondary.light,
    width: 5,
    height: 20,
  },
  shapeCircle: {
    borderRadius: '50%',
  },
}));

/* Curriculum map list results main page */
const MapListResults = ({ getCompleteData, sendData, customers, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  // const [page, setPage] = useState(0);
  const rectangle1 = <div className={classes.shape1} />;
  const rectangle2 = <div className={classes.shape2} />;
  const rectangle3 = <div className={classes.shape3} />;

  const [open, setOpen] = React.useState(false);
  const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;

  const currMap = useSelector(state => state.currMap.currMap)

  console.log(`currMap full details ${JSON.stringify(currMap)}`)
  // const mapList = useSelector(state => state.CurriculumMap)

  // current map details
  let idVar = (currMap != null) ? currMap.id : 0
  let titleVar = (currMap != null) ? currMap.map_title_text : ''
  let tagsVar = (currMap != null) ? currMap.map_tags : ''
  let instTypeVar = (currMap != null) ? currMap.institute_type_id : 0
  let boardVar = (currMap != null) ? currMap.institute_board_id : 0
  let clrStdVar = (currMap != null) ? currMap.classroom_std : 0
  let subVar = (currMap != null) ? currMap.subject_id : 0
  let termVar = (currMap != null) ? currMap.term_number : 0
  let unitVar = (currMap != null) ? currMap.unit_count : 0
  console.log(`titleVar ${titleVar}`)
  console.log(`instTypeVar ${instTypeVar}`)
  console.log(`boardVar ${boardVar}`)
  console.log(`clrStdVar ${clrStdVar}`)
  console.log(`subVar ${subVar}`)

  const [id, setId] = React.useState((currMap != null) ? currMap.map_id : 0)
  const [title, setTitle] = React.useState(titleVar);

  // console.log(`currMap title ${currMap.map_title_text}`)
  // console.log(`currMap state title ${title}`)

  const [instType, setInstType] = React.useState((currMap != null) ? currMap.institute_type_id : 10001);
  const [board, setBoard] = React.useState((currMap != null) ? currMap.institute_board_id : 1001);
  const [clrStd, setClrStd] = React.useState((currMap != null) ? currMap.classroom_std : 0);
  const [sub, setSub] = React.useState((currMap != null) ? currMap.subject_id : 108);
  const [tags, setTags] = React.useState(tagsVar)

  const [openSnack, setOpenSnack] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  //  const [mapId, setMapId] = React.useState(1);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [unitCount, setUnitCount] = React.useState(2);
  const [termNum, setTermNum] = useState(1)



  const handleUnitChange = (event) => {
    const { name, value } = event.target
    // update store
    currMap.unit_count = value
    dispatch(updateCurrMap(currMap))
  }


  const handleTermChange = (event) => {
    const { name, value } = event.target
    // update store
    currMap.term_number = value
    dispatch(updateCurrMap(currMap))
  }

  const handleTagsChange = (event) => {

    const { name, value } = event.target;

    setTags(value);

    // update store
    currMap.map_tags = value
    dispatch(updateCurrMap(currMap))
  }

  const handleInstTypeChange = (event) => {
    const { name, value } = event.target;

    setInstType(value);

    // update store
    currMap.institute_type_id = value
    dispatch(listBoardGet(value))
    dispatch(updateCurrMap(currMap))
  }

  const handleBoardChange = (event) => {
    const { name, value } = event.target;

    setBoard(value);

    // update store
    currMap.institute_board_id = value
    dispatch(listSubGet(value))
    dispatch(updateCurrMap(currMap))
  }

  const handleClrStdChange = (event) => {
    const { name, value } = event.target;

    setClrStd(value);
    // update store
    currMap.classroom_std = value
    dispatch(updateCurrMap(currMap))
  }

  const handleSubChange = (event) => {
    const { name, value } = event.target;

    setSub(value);

    // update store
    currMap.subject_id = value
    dispatch(updateCurrMap(currMap))


  }

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setTitle(value);

    // update store
    currMap.map_title_text = value
    dispatch(updateCurrMap(currMap))
  }


  const handleUpdateMap = async () => {
    const userId = Cookie.get('kgtopg.partner.user.id')
    const mapId = (currMap.map_id ? currMap.map_id : currMap.id)

    const title = currMap.map_title_text;
    const instType = currMap.institute_type_id;

    console.log(`current map ${JSON.stringify(currMap)}`)
    console.log(`mapId ${mapId}`)
    console.log(`mapTitle ${title}`)


    if (!title || !mapId) {
      // alert('Please enter title..');
      setOpenSnack(true);
      return;
    }

    const body = {
      institute_type_id: instType,
      institute_board_id: currMap.institute_board_id,
      classroom_std: currMap.classroom_std,
      subject_id: currMap.subject_id,
      unit_count: currMap.unit_count,
      // publisher_id: 1,
      map_title_text: title,
      tags: currMap.map_tags,
      term_number: currMap.term_number,
    }

    const resp = await MapAPI.postUpdateMap(body, mapId);
    console.log(`response from update map api response ${JSON.stringify(resp)}`);

    handleClose() // close dialog

    // reload all maps
    dispatch(listCurriculumGet(userId))

    //    alert('Map updated successfully..')
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };



  // const handleSelectAll = (event) => {
  //   let newSelectedCustomerIds;

  //   if (event.target.checked) {
  //     newSelectedCustomerIds = customers.map((customer) => customer.id);
  //   } else {
  //     newSelectedCustomerIds = [];
  //   }

  //   setSelectedCustomerIds(newSelectedCustomerIds);
  // };

  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedCustomerIds.indexOf(id);
  //   let newSelectedCustomerIds = [];

  //   if (selectedIndex === -1) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
  //   } else if (selectedIndex === 0) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
  //   } else if (selectedIndex === selectedCustomerIds.length - 1) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(
  //       selectedCustomerIds.slice(0, selectedIndex),
  //       selectedCustomerIds.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelectedCustomerIds(newSelectedCustomerIds);
  // };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClickIndex = (event, name, row, CurrData) => {

    console.log("selecting.....", row)
    sendData(row, CurrData)

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);


  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const dispatch = useDispatch()

  const curriculumDetailsGet = useSelector(state => state.curriculumMap)
  const { loadingCur, CurriculumMap, errorCur } = curriculumDetailsGet

  const curriculumFilDetailsGet = useSelector(state => state.curriculumMapFil)
  const { loadingCurFil, CurriculumMapFilter, errorCurFil } = curriculumFilDetailsGet

  const filteredMaps = useSelector(state => state.filteredMap.filteredMap)
  const instDetails = useSelector(state => state.instDetails.instDetails)
  const boardDetails = useSelector(state => state.boardDetails.boardDetails)
  const subDetails = useSelector(state => state.subDetails.subDetails)
  const stdDetails = useSelector(state => state.stdDetails.stdDetails)
  const maps = useSelector(state => state.curriculumMapFil.CurriculumMapFilter);

  console.log(`map list instDetails ${JSON.stringify(instDetails)}`)
  console.log(`map list boardDetails ${JSON.stringify(boardDetails)}`)
  console.log(`map list subDetails ${JSON.stringify(subDetails)}`)
  console.log(`map list stdDetails ${JSON.stringify(stdDetails)}`)

  useEffect(() => {
    console.log("useEffect at curriculum map")
    const userId = Cookie.get('kgtopg.partner.user.id')
    console.log(`userId from COOKIE ============= ${userId}`)


    console.log("userId at curriculum map", userId)

    // dispatch(listCurriculumGet(userId))
    dispatch(listCurriculumAllGet())
    // get boards from api and set to store
    // dispatch(listBoardGet())
    dispatch(listInstGet())
    // dispatch(listSubGet())
    // dispatch(listStdGet())
    return () => {
      //
    }
  }, [])


  const curriculumAllGet = useSelector(state => state.allCurriculumGet)
  const { loadingAllCurr, allCurrGet, errorAllBooks } = curriculumAllGet


  {
    loadingAllCurr && curriculumAllGet ?
      console.log("data is loading ")
      :
      console.log("all curriculum data at maplistResults", allCurrGet)
  }



  const onDeleteMap = async (id) => {
    const userId = Cookie.get('kgtopg.partner.user.id')
    await MapAPI.deleteMap(id)

    // reload
    dispatch(listCurriculumGet(userId))
  }

  console.log("curriculumDetailsGet ++++++++++++++++++++", loadingCur, CurriculumMap)


  // let mapList = (CurriculumMap && CurriculumMap.data) ? CurriculumMap.data : [];
  let mapList = (maps && maps.data) ? maps.data : [];
  console.log(`filtered maps original  list ${JSON.stringify(mapList)}`)
  if (filteredMaps && filteredMaps.length) {
    mapList = filteredMaps
  }

  console.log(`filtered maps to show ${JSON.stringify(mapList)}`)



  return (
    <Card {...rest}>
      <PerfectScrollbar
        style={{
          height: '100%'
        }}>
        <Box sx={{ minWidth: 1050, minHeight: '100%', height: '100%' }}
          style={{
            minHeight: '100%',
            height: '100%'
          }}>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '100%'
            }}>


            <div>
              <EnhancedTableToolbar numSelected={selected.length} />

              {/* {loadingCur && curriculumDetailsGet ? */}
              {
                 (mapList && mapList.length > 0)
                  ?

                  loadingCurFil && CurriculumMapFilter ?
                    // console.log("loading")
                    <CircularIndeterminate />
                    :

                    // (CurriculumMap && CurriculumMap.data && CurriculumMap.data.length && CurriculumMap.data.length != 0) ?
                    (mapList && mapList.length) ?
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                          aria-label="enhanced table"
                        >
                          <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody>
                            {
                              // (CurriculumMap && CurriculumMap.data && CurriculumMap.data.length && CurriculumMap.data.length != 0) ?
                              (mapList && mapList.length) ?
                                stableSort(mapList, getComparator(order, orderBy))
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    console.log(`currMapId is ${JSON.stringify(currMap.id)}`)
                                    console.log(`rowId is ${JSON.stringify(row)}`)
                                    const isSel = (currMap && currMap.map_id == row.map_id)

                                    return (
                                      <TableRow
                                        selected={isSel}
                                        hover
                                        onClick={async (event) => {
                                          console.log(`setting current map.. ${JSON.stringify(row)}`)

                                          // set current map
                                          await new Promise((resolve, reject) => {
                                            dispatch(updateCurrMap(row))
                                            resolve()
                                          })

                                          handleClickIndex(event, row.map_title_text, row, mapList[0])

                                        }}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                      // selected={isItemSelected}
                                      >
                                        <TableCell padding="checkbox">
                                          {/* <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              /> */}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none">
                                          <Typography
                                            variant='body1'
                                            style={{
                                              cursor: 'pointer'
                                            }}
                                          >
                                            {row.map_title_text}
                                          </Typography>
                                        </TableCell>
                                        {/* <TableCell align="center">{row.published_date}</TableCell>
                            <TableCell align="center">{row.draft_updated_date}</TableCell> */}
                                        <TableCell align="center">{row.unit_count}</TableCell>
                                        {/* <TableCell align="center">{row.Total_lesson_count}</TableCell> */}
                                        <TableCell
                                          style={{ textAlign: 'center' }}>
                                          <EditOutlinedIcon style={{ color: "#1a4beb" }}
                                            onClick={async () => {
                                              await new Promise((resolve, reject) => {
                                                dispatch(updateCurrMap(row))
                                                resolve()
                                              })

                                              console.log(`called dispatch for update current map for row ${JSON.stringify(row)}`)
                                              setOpen(true);

                                              // get boards for institute type from currmap
                                              const instTypeId = row.institute_type_id
                                              const boardId = row.institute_board_id
                                              const clrStd = row.classroom_std
                                              const subId = row.subject_id

                                              console.log(`edit insttype ${instTypeId}`)
                                              console.log(`edit board ${boardId}`)
                                              console.log(`edit std ${clrStd}`)
                                              console.log(`edit sub ${subId}`)

                                              // update store
                                              // listInstGet()
                                              await new Promise((resolve, reject) => {
                                                dispatch(listBoardGet(instTypeId))
                                                console.log(`listBoardGet called.. with id ${instTypeId}`)
                                                resolve()
                                              })

                                              await new Promise((resolve, reject) => {
                                                dispatch(listStdGet(boardId))
                                                console.log(`listStdGet called.. with id ${boardId}`)
                                                resolve()
                                              })

                                              await new Promise((resolve, reject) => {
                                                dispatch(listSubGet(boardId))
                                                console.log(`listSubGet called.. with id ${clrStd}`)
                                                resolve()
                                              })

                                              // update store with current object as current map
                                              setInstType(instTypeId);
                                              setBoard(boardId)
                                              setClrStd(clrStd)
                                              setSub(subId)


                                            }}
                                            style={{
                                              cursor: 'pointer',
                                              color: "#1a4beb"
                                            }} />

                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: 'center' }}>
                                          {/* <DeleteOutlineOutlinedIcon style={{ color: "#ed1a59" }} /> */}
                                          <SimpleDialogDemo onDeleteMap={(id) => onDeleteMap(id)} id={row.map_id} />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                :
                                <></>
                            }


                            {(emptyRows > 0) && (
                              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}

                          </TableBody>
                        </Table>
                      </TableContainer>


                      :
                      <Alert severity="warning">
                        <AlertTitle>No Curriculum Plans</AlertTitle>
                        You have not yet created any curriculum plans — <strong>Start by filtering or clicking on button above!</strong>
                      </Alert>

                  :

                  loadingAllCurr && curriculumAllGet ?
                    // console.log("loading")
                    <CircularIndeterminate />
                    :

                    // (CurriculumMap && CurriculumMap.data && CurriculumMap.data.length && CurriculumMap.data.length != 0) ?
                    (allCurrGet && allCurrGet.data && allCurrGet.data.length > 0) ?
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                          aria-label="enhanced table"
                        >
                          <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody>
                            {
                              // (CurriculumMap && CurriculumMap.data && CurriculumMap.data.length && CurriculumMap.data.length != 0) ?
                              (allCurrGet && allCurrGet.data && allCurrGet.data.length > 0) ?
                                stableSort(allCurrGet.data, getComparator(order, orderBy))
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row, index) => {
                                    const isItemSelected = isSelected(row.map_title_text);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    // console.log(`currMapId is ${JSON.stringify(currMap.id)}`)
                                    console.log(`rowId is ${JSON.stringify(row)}`)
                                     const isSel = (currMap && currMap.id == row.id)

                                    return (
                                      <TableRow
                                        // selected={isSel}
                                        hover
                                        onClick={async (event) => {
                                          console.log(`setting current map.. ${JSON.stringify(row)}`)

                                          // set current map
                                          await new Promise((resolve, reject) => {
                                            dispatch(updateCurrMap(row))
                                            resolve()
                                          })

                                          handleClickIndex(event, row.map_title_text, row, mapList[0])

                                        }}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                      // selected={isItemSelected}
                                      >
                                        <TableCell padding="checkbox">
                                          {/* <Checkbox
        checked={isItemSelected}
        inputProps={{ 'aria-labelledby': labelId }}
      /> */}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none">
                                          <Typography
                                            variant='body1'
                                            style={{
                                              cursor: 'pointer'
                                            }}
                                          >
                                            {row.map_title_text}
                                          </Typography>
                                        </TableCell>
                                        {/* <TableCell align="center">{row.published_date}</TableCell>
    <TableCell align="center">{row.draft_updated_date}</TableCell> */}
                                        <TableCell align="center">{row.unit_count}</TableCell>
                                        {/* <TableCell align="center">{row.Total_lesson_count}</TableCell> */}
                                        <TableCell
                                          style={{ textAlign: 'center' }}>
                                          <EditOutlinedIcon style={{ color: "#1a4beb" }}
                                            onClick={async () => {
                                              await new Promise((resolve, reject) => {
                                                dispatch(updateCurrMap(row))
                                                resolve()
                                              })

                                              console.log(`called dispatch for update current map for row ${JSON.stringify(row)}`)
                                              setOpen(true);

                                              // get boards for institute type from currmap
                                              const instTypeId = row.institute_type_id
                                              const boardId = row.institute_board_id
                                              const clrStd = row.classroom_std
                                              const subId = row.subject_id

                                              console.log(`edit insttype ${instTypeId}`)
                                              console.log(`edit board ${boardId}`)
                                              console.log(`edit std ${clrStd}`)
                                              console.log(`edit sub ${subId}`)

                                              // update store
                                              // listInstGet()
                                              await new Promise((resolve, reject) => {
                                                dispatch(listBoardGet(instTypeId))
                                                console.log(`listBoardGet called.. with id ${instTypeId}`)
                                                resolve()
                                              })

                                              await new Promise((resolve, reject) => {
                                                dispatch(listStdGet(boardId))
                                                console.log(`listStdGet called.. with id ${boardId}`)
                                                resolve()
                                              })

                                              await new Promise((resolve, reject) => {
                                                dispatch(listSubGet(boardId))
                                                console.log(`listSubGet called.. with id ${clrStd}`)
                                                resolve()
                                              })

                                              // update store with current object as current map
                                              setInstType(instTypeId);
                                              setBoard(boardId)
                                              setClrStd(clrStd)
                                              setSub(subId)


                                            }}
                                            style={{
                                              cursor: 'pointer',
                                              color: "#1a4beb"
                                            }} />

                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: 'center' }}>
                                          {/* <DeleteOutlineOutlinedIcon style={{ color: "#ed1a59" }} /> */}
                                          <SimpleDialogDemo onDeleteMap={(id) => onDeleteMap(id)} id={row.map_id} />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                :
                                <></>
                            }


                            {(emptyRows > 0) && (
                              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}

                          </TableBody>
                        </Table>
                      </TableContainer>


                      :
                      <Alert severity="warning">
                        <AlertTitle>No Curriculum Plans by default</AlertTitle>
                        You have not yet created any curriculum plans — <strong>Start by filtering or clicking on button above!</strong>
                      </Alert>
              }
            </div>

            <div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>


        </Box>
      </PerfectScrollbar>





      {/* EDIT CURRICULUM MAP FORM */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontWeight: "500" }}>{titleVar} [Edit]</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
            Curriculum Map lets you setup a new curriculum unit sets with time and pacing.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Curriculum Map Name"
            type="text"
            fullWidth
            defaultValue={titleVar}
            onChange={handleTitleChange}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />

          <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
            <InputLabel htmlFor="filled-age-native-simple" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Institute Type</InputLabel>
            <Select
              native
              defaultValue={instTypeVar}
              value={instTypeVar}
              onChange={handleInstTypeChange}
              inputProps={{
                name: 'age',
                id: 'filled-age-native-simple',
              }}
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >

              <option key={-1} value={-1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Select</option>
              {(instDetails && instDetails.data) ?
                instDetails.data.map((type, index) => {
                  console.log(`single inst type ${JSON.stringify(type)}`)
                  return (
                    <option key={index} value={type.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{type.institute_type_name}</option>
                  )
                })
                :
                <option value={-2}>No Types</option>
              }

            </Select>
          </FormControl>

          <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
            <InputLabel htmlFor="filled-age-native-simple" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Institute Board</InputLabel>
            <Select
              native
              defaultValue={boardVar}
              value={boardVar}
              onChange={handleBoardChange}
              inputProps={{
                name: 'age',
                id: 'filled-age-native-simple',
              }}
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >
              <option key={-1} value={-1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Select</option>

              {boardDetails && boardDetails.data ?
                boardDetails.data.map((brd, index) => {
                  return (
                    <option key={index} value={brd.institute_board_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{brd.institute_board_name}</option>
                  )
                })
                :
                <option value={-2}>No Boards</option>
              }

            </Select>
          </FormControl>

          <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
            <InputLabel htmlFor="filled-age-native-simple" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Classroom Standard</InputLabel>
            <Select
              native
              defaultValue={clrStdVar}
              value={clrStdVar}
              onChange={handleClrStdChange}
              inputProps={{
                name: 'age',
                id: 'filled-age-native-simple',
              }}
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >
              <option key={-1} value={-1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Select</option>
              {stdDetails && stdDetails.data && stdDetails.data ?
                stdDetails.data.map((sb, index) => {
                  return (
                    <option key={index} value={sb.id} style={{ fontSize: 13, color: "#464E5F" }}>{sb.standard}</option>
                  )
                })
                :
                <option value={-2}>No Standards</option>
              }
            </Select>
          </FormControl>

          <FormControl variant="filled" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
            <InputLabel htmlFor="filled-age-native-simple" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>Subject</InputLabel>
            <Select
              native
              defaultValue={subVar}
              value={subVar}
              onChange={handleSubChange}
              inputProps={{
                name: 'age',
                id: 'filled-age-native-simple',
              }}
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >

              <option key={-1} value={-1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Select</option>
              {subDetails && subDetails.data && subDetails.data.subjects ?
                subDetails.data.subjects.map((sb, index) => {
                  return (
                    <option key={index} value={sb.subject_id} style={{ fontSize: 13, color: "#464E5F" }}>{sb.subject_name}</option>
                  )
                })
                :
                <option value={-2}>No Subjects</option>
              }

              {/*
              <option aria-label="None" value="" />
              <option value={'ENGLISH'} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>ENGLISH</option>
              <option value={'MATHS'} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>MATHS</option>
              <option value={'SOCIAL STUDIES'} style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>SOCIAL STUDIES</option>
              */}
            </Select>
          </FormControl>


          <TextField
            autoFocus
            defaultValue={termVar}
            onChange={handleTermChange}
            margin="dense"
            id="tags"
            label="Unit Count"
            type="number"
            fullWidth
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />

          <TextField
            autoFocus
            defaultValue={unitVar}
            onChange={handleUnitChange}
            margin="dense"
            id="tags"
            label="Unit Count"
            type="number"
            fullWidth
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />

          <TextField
            autoFocus
            margin="dense"
            id="tags"
            label="Tags"
            type="text"
            fullWidth
            defaultValue={tagsVar}
            onChange={handleTagsChange}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
            Cancel
          </Button>
          <Button onClick={handleUpdateMap} color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
            Update
          </Button>
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
            <Alert2 onClose={handleCloseSnack} severity="warning">
              Please enter mandatory details!
            </Alert2>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </Card>

  );
};

MapListResults.propTypes = {
  customers: PropTypes.array.isRequired
};

export default MapListResults;
