import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listDashBoardContDevices, listDashBoardPubDevices } from '../../../../redux/actions/DashBoardActions';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

const TrafficByDevice = (props) => {
  const theme = useTheme();
  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };



  const dispatch = useDispatch()
  useEffect(() => {

    dispatch(listDashBoardContDevices())

    return (() => {
      //
    })

  }, [])

  const dashboardContDevicesGet = useSelector(state => state.dashboardContDevices)
  const { loadingDashboardContDevices, DashboardContDevices, errorDashContDevices } = dashboardContDevicesGet


  {
    loadingDashboardContDevices && dashboardContDevicesGet ?
      console.log("data isloading")
      :
      console.log("devices list at traffic at cont", DashboardContDevices)
  }
  let Laptop = 0
  let Tablet = 0
  let phone = 0

  {
    loadingDashboardContDevices && dashboardContDevicesGet ?
      console.log("data isloading")
      :
      DashboardContDevices && DashboardContDevices.data ?
        Laptop = DashboardContDevices.data.Desktop
        :
        Laptop = 0
  }

  {
    loadingDashboardContDevices && dashboardContDevicesGet ?
      console.log("data isloading")
      :
      DashboardContDevices && DashboardContDevices.data ?
        Tablet = DashboardContDevices.data.Tablet
        :
        Tablet = 0
  }
  {
    loadingDashboardContDevices && dashboardContDevicesGet ?
      console.log("data isloading")
      :
      DashboardContDevices && DashboardContDevices.data ?
        phone = DashboardContDevices.data.Mobile
        :
        phone = 0
  }

  let data = {}

  if (!Laptop && !Tablet && !phone) {
    data = {
      datasets: [
        {
          data: [0.1, 0.1, 0.1],
          backgroundColor: [
            colors.grey[100],
            colors.grey[300],
            colors.grey[200]
          ],
          borderWidth: 8,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white
        }
      ],
      labels: ['Desktop', 'Tablet', 'Mobile']
    };

  } else {
    data = {
      datasets: [
        {
          data: [Laptop, Tablet, phone],
          backgroundColor: [
            colors.indigo[500],
            colors.red[600],
            colors.orange[600]
          ],
          borderWidth: 8,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white
        }
      ],
      labels: ['Desktop', 'Tablet', 'Mobile']
    };
  }

  const devices = [
    {
      title: 'Desktop',
      value: Laptop,
      icon: LaptopMacIcon,
      color: colors.indigo[500]
    },
    {
      title: 'Tablet',
      value: Tablet,
      icon: TabletIcon,
      color: colors.red[600]
    },
    {
      title: 'Mobile',
      value: phone,
      icon: PhoneAndroidIcon,
      color: colors.orange[600]
    }
  ];


  return (
    <Card {...props}>
      <CardHeader title="Traffic by Device" />
      <Divider />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
          style={{
            height: 335
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
              px={3}
              style={{

                alignSelf: 'center'
              }}
            >
              <Icon color="action"
                style={{
                  alignSelf: 'center'
                }} />
              <Typography
                color="textPrimary"
                variant="body2"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h4"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;
