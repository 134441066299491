import axios from "axios";
import Constants from "../../resource/Constants";
import { SEARCHEBOOK_LIST_REQUEST, SEARCHEBOOK_LIST_SUCCESS, SEARCHEBOOK_LIST_FAIL } from "../constants/SearchEbookConstants";



const listSearchEbookGet = (inst_id, board_id, std_id,pub_id) => async (dispatch) => {
    try {
        dispatch({ type: SEARCHEBOOK_LIST_REQUEST })
        console.log("at lisSearch ebook get ++++++++++++++++++",inst_id, board_id, std_id, pub_id)
        // const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/search-books/?search=${term}`)
        const booksUrl = Constants.Application.PARTNER_END_URL +`/books-inst/?board_id=${board_id}&cls_std=${std_id}&inst_type_id=${inst_id}&pub_id=${pub_id}`
        console.log(`booksUrl ${booksUrl}`)
        const { data } = await axios.get(booksUrl)
        console.log("data in list subjects  get ////////////////////", data)
        dispatch({ type: SEARCHEBOOK_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: SEARCHEBOOK_LIST_FAIL, payload: error.message })

    }
}

export { listSearchEbookGet }