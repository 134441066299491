/**
 * setup action creators for current map unit
 */

export const MAPUNIT_CURR_REQUEST = 'MAPUNIT_CURR_REQUEST';
export const MAPUNIT_CURR_SUCCESS = 'MAPUNIT_CURR_SUCCESS';
export const MAPUNIT_CURR_FAILURE = 'MAPUNIT_CURR_FAILURE';

// action creators
export const getCurrUnit = () => ({ // already returns
    type: MAPUNIT_CURR_REQUEST
})

export const getCurrUnitSuccess = (data) => ({
    type: MAPUNIT_CURR_SUCCESS,
    payload: data
})

export const getCurrUnitFailure = () => ({
    type: MAPUNIT_CURR_FAILURE
})

// async thunk action to update
export function updateCurrUnit(data) {
    return async (dispatch) => {
        dispatch(getCurrUnit());

        try {
            // update data
            dispatch(getCurrUnitSuccess(data))

            // cleanup
        } catch (error) {
            dispatch(getCurrUnitFailure())
        }

    }
}
