import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import "../../css/sidebar.css"
import { Link } from 'react-router-dom'
import { ExitToApp, ExitToAppOutlined, LaptopWindows, SettingsOutlined } from '@material-ui/icons';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Divider, Input } from '@material-ui/core';
import AddBoxSharpIcon from '@material-ui/icons/AddBoxSharp';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { MenuItem } from '@material-ui/core';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import GradeIcon from '@material-ui/icons/Grade';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import image from "../../assets/bus.png"
import FilterResults from 'react-filter-search';
import SearchIcon from '@material-ui/icons/Search';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import CropLandscapeOutlinedIcon from '@material-ui/icons/CropLandscapeOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import Cookie from 'js-cookie';
import SchoolIcon from '@material-ui/icons/School';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import List from '@material-ui/core/List';
import MoneyIcon from '@material-ui/icons/Money';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const useStyles2 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const DisplayCard = (props) => {

    return (
        <Card style={{ width: 270, height: 150, margin: 10, justifyContent: "center", }}>
            <CardMedia
                component="img"
                alt="bus png"
                height="100"
                image={image}
                title="School bus"
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 14,
                    }}
                >
                    {props.list.title}
                </Typography>
            </CardContent>
        </Card>
    )
}

function SimpleAccordion(props) {
    const classes = useStyles();

    const path = window.location.pathname
    let acaSel = false;
    let otSel = false;
    let currPath = '';

    switch (path) {
        case "/publisher-ebook/books/academics":
            currPath = "ebooks";
            acaSel = true;
            break;
        case "/publisher-ebook/books/others":
            currPath = "ebooks";
            otSel = true;
            break;
    }

    return (
        <div className={classes.root}>
            <Accordion

                defaultExpanded={(acaSel || otSel)}
                style={{
                    border: 0,
                    padding: 0
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        margin: 0,
                        border: 0,
                        paddingBottom: 0
                    }}
                >
                    {/* <Typography className={classes.heading}>My Books</Typography> */}


                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',

                        }}>
                        <ListItemIcon>
                            <div className="Finished">
                                <MenuBookOutlinedIcon />
                            </div>
                        </ListItemIcon>
                        <div className="Finished-text">
                            <ListItemText primary="My Books" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                        </div>
                    </div>

                </AccordionSummary>
                <AccordionDetails
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: 0,
                        padding: 0
                    }}>
                    {/* <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget.
                    </Typography> */}
                    <Link to="/publisher-ebook/books/academics" style={{ textDecoration: "none" }} >
                        <MenuItem
                            style={{
                                paddingTop: 0,
                                paddingBottom: 5
                            }}
                            selected={acaSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <SchoolIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Academics" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: 'gray',
                                        padding: 0,
                                        margin: 0
                                    }}>
                                    Curriculum Planner
                                </Typography>

                            </div>
                        </MenuItem>
                    </Link>

                    <Link to="/publisher-ebook/books/others" style={{ textDecoration: "none" }} >
                        <MenuItem
                            style={{
                                paddingTop: 0,
                                paddingBottom: 5
                            }}
                            selected={otSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <LocalLibraryIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Others" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: 'gray',
                                        padding: 0,
                                        margin: 0
                                    }}>
                                    Comics, Morals etc..
                                </Typography>
                            </div>
                        </MenuItem>
                    </Link>
                </AccordionDetails>
            </Accordion>
            {/* 
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Accordion 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className={classes.heading}>Disabled Accordion</Typography>
                </AccordionSummary>
            </Accordion>
         */}
        </div>
    );
}

function SidebarContent(props) {

    const [data, setData] = useState([]);
    const [value, setValue] = useState("");
    const [search, setSearch] = useState("")
    const [showContent, setShownContent] = useState(false)
    const classes = useStyles2()

    const handleChange = event => {
        const { value } = event.target;
        this.setState({ value });
    };


    const onTextChange = e => {

        setSearch(e.target.value)
    };

    console.log("props", props)

    useEffect(() => {
        return () => {
            console.log("windows path", window.location.href, window.location.pathname)
            // 
        }
    }, [])
    console.log("windows path in dashboard", window.location.pathname)

    const path = window.location.pathname
    let currPath = "dashboard"
    let dashSel = false;
    let mediaSel = false;
    let eBookSel = false;
    let mapsSel = false;
    let userSel = false;
    let subSel = false;
    let trnSel = false;
    let invSel = false;
    let payInSel = false;
    let payOutSel = false;

    switch (path) {
        case "/publisher-ebook/dashboard":
            currPath = "dashboard";
            dashSel = true;
            break;
        case "/publisher-ebook/content":
            currPath = "content";
            mediaSel = true;
            break;
        case "/publisher-ebook/books":
            currPath = "ebooks";
            eBookSel = true;
            break;
        case "/publisher-ebook/curriculum-map":
        case "/curriculum-map/set-units/:id":
            currPath = "maps";
            mapsSel = true;
            break;
        case "/publisher-ebook/users":
        case '/publisher-ebook/institute-details/:id':
            currPath = "users";
            userSel = true;
            break;
        case "/publisher-ebook/subscribers":
            currPath = "subscribers";
            subSel = true;
            break;
        case "/publisher-ebook/trainer":
            currPath = "trainer";
            trnSel = true;
            break;
        case "/publisher-ebook/invites":
            currPath = "invites";
            invSel = true;
            break;
        case "/publisher-ebook/pay-in":
            currPath = "pay-in";
            payInSel = true;
            break;
        case "/publisher-ebook/pay-out":
            currPath = "pay-out";
            payOutSel = true;
            break;
    }

    if (path.includes('/publisher-ebook/institute-details/')) {
        currPath = "users";
        userSel = true;
    } else if (path.includes('/publisher-ebook/subscriber-details/')) {
        currPath = "subscribers";
        subSel = true;
    } else if (path.includes('/publisher-ebook/pay-out-details')
        || path.includes('/publisher-ebook/pay-out-history')
    ) {
        currPath = "pay-out";
        payOutSel = true;
    }

    // const CONTENTUPLOAD =   Cookie.get('kgtopg.partner.user.CONTENTUPLOAD')
    // const QUALITYASSURANCE =  Cookie.get('kgtopg.partner.user.QUALITYASSURANCE')
    // const SUPERADMIN = Cookie.get('kgtopg.partner.user.SUPERADMIN')
    // const CONTENTMAP = Cookie.get('kgtopg.partner.user.CONTENTMAP')
    // const CURRICULUMMAP =  Cookie.get('kgtopg.partner.user.CURRICULUMMAP')
    // const EBOOKUPLOAD =Cookie.get('kgtopg.partner.user.EBOOKUPLOAD')
    // const TRAINER = Cookie.get('kgtopg.partner.user.TRAINER')


    const roles = Cookie.get('kgtopg.partner.user.Roles')
    const CONTENTUPLOAD = (roles && roles.includes("CONTENTUPLOAD"))
    const QUALITYASSURANCE = (roles && roles.includes("QUALITYASSURANCE"))
    const SUPERADMIN = (roles && roles.includes("SUPERADMIN"))
    const CONTENTMAP = (roles && roles.includes("CONTENTMAP"))
    const CURRICULUMMAP = (roles && roles.includes("CURRICULUMMAP"))
    const EBOOKUPLOAD = (roles && roles.includes("EBOOKUPLOAD"))
    const TRAINER = (roles && roles.includes("TRAINER"))


    console.log("role status",
        CONTENTUPLOAD,
        QUALITYASSURANCE,
        SUPERADMIN,
        CONTENTMAP,
        CURRICULUMMAP,
        EBOOKUPLOAD,
        TRAINER
    )
    const role = Cookie.get('kgtopg.partner.user.role')

    return (
        <div style={{ height: "68vh" }}>

            <div>
                <List
                    component="nav">
                    {SUPERADMIN ?
                        <Link to="/publisher-ebook/dashboard" style={{ textDecoration: "none" }} >
                            <MenuItem selected={dashSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                <ListItemIcon>
                                    <div className="Finished">
                                        <DashboardOutlinedIcon />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Dashboard" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                        </Link>
                        :
                        <></>
                    }


{/* 
                    {
                        SUPERADMIN && (role == "CONTENT_PROVIDER AND PUBLISHER") ?
                            <Link to="/publisher-ebook/content" style={{ textDecoration: "none" }}>
                                <MenuItem selected={mediaSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                    <ListItemIcon>
                                        <div className="Finished">
                                            <MoneyIcon />
                                        </div>
                                    </ListItemIcon>
                                    <div className="Finished-text">
                                        <ListItemText primary="My Media" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                    </div>
                                </MenuItem>
                            </Link>
                            :
                            <></>
                    } */}

                    {(SUPERADMIN || EBOOKUPLOAD) ?

                        props && props.sidebarOpen ?
                            <SimpleAccordion eBookSel={eBookSel} showContent={(bool, path) => props.showContent(bool, path)} />
                            :
                            <Link to="/publisher-ebook/books/academics" style={{ textDecoration: "none" }} >
                                <IconButton>
                                    <MenuBookOutlinedIcon />
                                </IconButton>
                            </Link>
                        :
                        <></>

                    }
                </List>
                {/* 
                {(SUPERADMIN || TRAINER) ?
                    <Link to="/publisher-ebook/trainer/trainer-videos" style={{ textDecoration: "none" }} >
                        <MenuItem selected={trnSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <RecordVoiceOverIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Trainer" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                    :
                    <></>

                } */}
                {/* 
                {(SUPERADMIN || CURRICULUMMAP) ?
                    <Link to="/publisher-ebook/curriculum-map" style={{ textDecoration: "none" }}>
                        <MenuItem selected={mapsSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <PostAddIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Curriculum" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                    :
                    <></>
                } */}

                {/* SAMPLE BOOKS */}

                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            CRM
                        </ListSubheader>
                    }
                    className={classes.root}
                >
                    {
                        SUPERADMIN ?
                            <Link to="/publisher-ebook/users" style={{ textDecoration: "none" }}>
                                <MenuItem selected={userSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                    <ListItemIcon>
                                        <div className="Finished">
                                            <GroupOutlinedIcon />
                                        </div>
                                    </ListItemIcon>
                                    <div className="Finished-text">
                                        <ListItemText primary="Prospective" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                    </div>
                                </MenuItem>
                            </Link>
                            :
                            <></>
                    }

                    {/* SUBSCRIBERS */}
                    {
                        SUPERADMIN ?
                            <Link to="/publisher-ebook/subscribers" style={{ textDecoration: "none" }}>
                                <MenuItem selected={subSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                    <ListItemIcon>
                                        <div className="Finished">
                                            <PeopleAltIcon />
                                        </div>
                                    </ListItemIcon>
                                    <div className="Finished-text">
                                        <ListItemText primary="My Clients" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                    </div>
                                </MenuItem>
                            </Link>
                            :
                            <></>
                    }

                    {
                        SUPERADMIN ?
                            // <Link to="/publisher-ebook/subscribers" style={{ textDecoration: "none" }}>
                            <MenuItem button >
                                <ListItemIcon>
                                    <div className="Finished">
                                        <PersonPinIcon color="grey" style={{ color: 'lightgrey' }} />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Subscribers" style={{ color: 'lightgrey', fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                            // </Link>
                            :
                            <></>
                    }
                </List>

                {/* PARTNER INVITES */}
                {/* {
                    SUPERADMIN ?
                        <Link to="/publisher-ebook/invites" style={{ textDecoration: "none" }}>
                            <MenuItem selected={invSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                <ListItemIcon>
                                    <div className="Finished">
                                        <GroupAddIcon />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Partner Invites" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                        </Link>
                        :
                        <></>
                } */}

                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Finance
                        </ListSubheader>
                    }
                    className={classes.root}
                >
                    {
                        SUPERADMIN ?
                            <Link to="/publisher-ebook/pay-out" style={{ textDecoration: "none" }}>
                                <MenuItem selected={payOutSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                    <ListItemIcon>
                                        <div className="Finished">
                                            <MoneyIcon />
                                        </div>
                                    </ListItemIcon>
                                    <div className="Finished-text">
                                        <ListItemText primary="Payables" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                    </div>
                                </MenuItem>
                            </Link>
                            :
                            <></>
                    }

                    {
                        SUPERADMIN ?
                            // <Link to="/publisher-ebook/pay-in" style={{ textDecoration: "none" }}>
                            <MenuItem selected={payInSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                <ListItemIcon>
                                    <div className="Finished">
                                        <MonetizationOnIcon style={{ color: 'lightgrey' }} />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Receivables" style={{ color: 'lightgrey', fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                            // </Link>
                            :
                            <></>
                    }
                    {/* 
                    {
                        SUPERADMIN && (role == "CONTENT_PROVIDER AND PUBLISHER") ?
                            <Link to="/publisher-ebook/accounts" style={{ textDecoration: "none" }}>
                                <MenuItem selected={payInSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                    <ListItemIcon>
                                        <div className="Finished">
                                            <MoneyIcon />
                                        </div>
                                    </ListItemIcon>
                                    <div className="Finished-text">
                                        <ListItemText primary="Accounts" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                    </div>
                                </MenuItem>
                            </Link>
                            :
                            <></>
                    } */}

                </List>

            </div>


        </div>
    )
}

export default SidebarContent;
