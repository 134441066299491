import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Route, Link, BrowserRouter as Router, Switch, Redirect, HashRouter, useHistory } from 'react-router-dom'
import { Avatar, Button, } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { useState } from 'react';
import logo from "../../../assets/logo.png"
import publisherLogo from "../../../assets/PSON.jpeg"
import McgrawHill from "../../../assets/Mcgrawhill.jpg"
import "../../../css/sidebar.css"

import LeftCard from "../slots/LeftCard"
import { Helmet } from 'react-helmet';

import Copyright from '../../user/slots/Copyright';
import SwitchAccountPub from '../slots/dashboard/SwitchAccountPub';
import Cookie from 'js-cookie';
import PustakLogin from '../../../assets/PustakLogin.png'
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { listSearchEbookGet } from '../../../redux/actions/searchEbookAction';
import { useDispatch, useSelector } from 'react-redux';
import PUBLISHERAPI from '../../../http/publisher'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import { listTrashBinGet, listTrashBinPost } from '../../../redux/actions/TrashBinAction';
import RestoreFromTrashOutlinedIcon from '@material-ui/icons/RestoreFromTrashOutlined';
import bookCover from "../../../assets/book-na-1.jpg"

function Copyright2() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>
            {'Copyright © '}
            <Link color="inherit" href="https://kgtopg.com/">
                KGToPG
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    root1: {
        // maxWidth: 200,
        maxWidth: 250,
        minHeight: 400
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        // height: '100vh',
        overflow: 'auto',
        height: "100vh",

    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        // justifyContent: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        minWidth: '100%'

    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0
    },
}));


const DeleteDialogSlide = (props) => {
    const { bookId, pubBookData } = props
    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);


    // console.log("pubData at delete confiramtion", pubData, bookId)
    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleCloseDialog = () => {
        setOpen(false);
    };



    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const dispatch = useDispatch()

    const handleDeleteBook = async (bookId) => {
        const status = 0
        console.log("book id  for deleting is ", bookId)
        const response = await PUBLISHERAPI.DeleteSingleEbook(bookId)
        handleCloseDialog()
        setOpenSnack(true)
        dispatch(listTrashBinGet(status))
        // console.log("response for delete ",response)


    }

    return (
        <div>
            <Button color="secondary" onClick={handleClickOpen}>
                Delete
            </Button>

            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you Sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                            <CancelOutlinedIcon color="secondary" style={{ fontSize: 60, }} />
                        </div>
                        Are you sure? Do you really want to delete <strong>{pubBookData.ebook_name_text}</strong> ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" style={{ textTransform: "none" }}>
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteBook(bookId)} color="secondary" style={{ textTransform: "none" }}>
                        Yes,Delete it
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success">
                    Book  is deleted successfully!
                </Alert>
            </Snackbar>
        </div>
    );
}

function BookMenu(props) {
    const { pubBookData, book_id } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem >
                    <RestoreDialog pubBookData={pubBookData} bookId={book_id} />
                </MenuItem>


                <MenuItem onClick={handleClose}>
                    <DeleteDialogSlide
                        pubBookData={pubBookData}
                        bookId={book_id}
                    />
                </MenuItem>


            </Menu>
        </div>
    )
}
function RestoreDialog(props) {
    const { pubBookData, bookId } = props
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);


    console.log("pubBooksData at Movetotrash dialog", pubBookData, bookId)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const restoreBook = (id) => {
        console.log("book id is ", id)
        const status = 1
        dispatch(listTrashBinPost(id, status))
        setOpenSnack(true)
        handleClose()

    }


    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    return (
        <div>
            <Button color="primary" onClick={handleClickOpen}>
                Restore Book
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Restore Book Confirmation"}</DialogTitle>
                <DialogContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <RestoreFromTrashOutlinedIcon color="secondary" style={{ fontSize: 60 }} />
                    </div>
                    <DialogContentText>
                        Are you sure, Do you want to restore <strong>{pubBookData.ebook_name_text}</strong> from trash bin
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => { restoreBook(bookId) }} color="primary" autoFocus>
                        Yes,Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success">
                    Book  is restored successfully!
                </Alert>
            </Snackbar>
        </div >
    );
}



function BooksCard2(props) {
    const { bookData } = props
    const classes = useStyles();

    return (
        <div style={{ margin: 10, width: 200 }}>
            <Card className={classes.root1}>
                <CardMedia
                    component="img"
                    image={bookData.ebook_image_url}
                    title={bookData.ebook_name_text}
                    style={{ resize: "block", objectFit: "cover", height: 200, width: 200 }}

                />
                <CardContent style={{ padding: 0 }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "row", padding: 10, justifyContent: 'space-between', alignItems: "center", }}>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                                style={{
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    fontSize: 16,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                {bookData.ebook_name_text}
                            </Typography>
                        </div>
                        <div>
                            <BookMenu
                                pubBookData={bookData}
                                book_id={bookData.id}

                            />
                        </div>
                    </div>
                </CardContent>
                {/* <CardActionArea>
                </CardActionArea> */}
            </Card>

        </div>
    )
}

const BooksCard = (props) => {
    const { bookData } = props;
    const classes = useStyles();

    return (
        <div style={{ margin: 10, marginBottom: 40, height: 400, position: 'relative' }}>

            <Card className={classes.root1}>

                <CardActionArea
                    style={{
                        height: 360
                    }}>
                    {
                        bookData.ebook_image_url ?
                            <Link to={"/publisher-ebook/map-content?book_id=" + bookData.id} style={{ textDecoration: "none", }} key={props.ind} onClick={() => props.updateSidebar(true, bookData)}>
                                <CardMedia
                                    component="img"
                                    alt={bookData.ebook_name_text}
                                    // height="200"
                                    image={bookData.ebook_image_url ? bookData.ebook_image_url : bookCover}
                                    title={bookData.ebook_name_text}
                                    style={{ resize: "block", objectFit: "cover", height: 360, minWidth: 250 }}
                                />
                            </Link>
                            :
                            <CardMedia
                                component="img"
                                alt={bookData.ebook_name_text}
                                image={bookCover}
                                title={bookData.ebook_name_text}
                                style={{ resize: "block", objectFit: "cover", height: 360, minWidth: 250 }}
                            />
                    }
                </CardActionArea>

                <CardActions
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>

                    <span>
                        {bookData.ebook_name_text}
                    </span>
                    <BookMenu
                        pubBookData={bookData}
                        book_id={bookData.id}

                    />

                    {/*                     
                    <BookMenu
                        pubId={pubId}
                        instType={instType}
                        boardType={boardType}
                        standardType={standardType}
                        pubData={bookData}
                        book_id={bookData.id}
                        ind={props.ind}
                        updateSideStatus={(status, pubData) => { updateSideBarStatus(status, pubData) }}
                        toggleDrawer={(bookData) => props.toggleDrawer(bookData)}
                        subscription={subscription}
                        onReload={props.onReload}
                    /> */}

                </CardActions>
            </Card>
        </div>
    )
}


export default function TrashBinPub(props) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const role = Cookie.get('kgtopg.partner.user.role')
    const [open, setOpen] = React.useState(true);
    const [showButton, setShowButton] = useState(false)
    const [AccordClose, setAccordClose] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [showLogo, setShowLogo] = useState(true)
    const [state, setState] = React.useState({
        right: false,
    })

    const [showSideCont, SetShowSideCont] = useState(false)
    const [path, setPath] = useState('')
    const [readerData, setReaderData] = useState("")
    const [isLoadingscr, setIsLoadingscr] = useState(false)
    const [sidebarStatus, setSidebarStatus] = useState(false)
    const [sideBarPubData, setSideBarPubData] = useState(null)






    const handleDrawerOpen = () => {
        setOpen(true);
        setAccordClose(true)
        setShowLogo(true)
        SetShowSideCont(false)
    };
    const handleDrawerClose = () => {
        setOpen(false);
        setAccordClose(false)
        setShowLogo(false)

    };



    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


    const hideFab = (list, index) => {
        console.log("hiding the button", list, index)
        setShowButton(true)
        setIsLoadingscr(false)
        console.log("show button at parent", showButton)


    }


    const showSideContent = (status, pubData) => {
        console.log("sidecontent status", status, pubData)

        props.hideSideBar(status)
        props.sideBarProv(pubData)

        setSidebarStatus(status)
        setSideBarPubData(pubData)



    }

    console.log("windows path in dashboard", window.location.pathname)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    }


    useEffect(() => {

        const status = 0
        dispatch(listTrashBinGet(status))
        return (() => {
            //
        })
    }, [])

    const trashGet = useSelector(state => state.trashbinGet)
    const { loadingTrashGet, TrashDetails, errorTrashGet } = trashGet

    {
        loadingTrashGet && trashGet ?

            console.log("data is loading ...")
            :
            console.log("TrashDetails is ", TrashDetails)
    }


    const sendDataToReaderScreen = (list, status) => {
        setIsLoading(true)
        console.log("sending..... Data to Reader Screen", list)
        if (list) {
            setReaderData(list)
            setIsLoading(false)
            setShowButton(status)
        }

    }



    console.log("state reader data ", readerData)
    return (
        <div className={classes.root}>
            {isLoadingscr == false ?
                // <Router>
                <>
                    <CssBaseline />
                    <LinearProgress />

                    <LeftCard />

                    <main className={classes.content}>
                        <Helmet>
                            <title>Trash Bin | Publish Pustak</title>
                        </Helmet>

                        <Container className={classes.container}>
                            <Grid container spacing={3}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    minHeight: '90%',
                                    width: '100%'
                                }}>



                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    width: '100%'
                                }}>



                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                backgroundColor: 'background.default',
                                                minHeight: '100%',
                                                py: 3
                                            }}
                                        >
                                            <Container maxWidth={false}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        alignContent: "center"
                                                    }}
                                                >
                                                    <Typography
                                                        id="tableTitle" component="div"
                                                        variant="h3"
                                                        style={{
                                                            marginTop: 20,
                                                            marginBottom: 20
                                                        }}>
                                                        Trash Bin
                                                    </Typography>

                                                </div>
                                            </Container>
                                        </Box>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {loadingTrashGet && trashGet ?
                                        <></>
                                        :
                                        trashGet && TrashDetails && TrashDetails.data && TrashDetails.data.length > 0 ?
                                            TrashDetails.data.map((book, index) => {
                                                return (
                                                    <BooksCard bookData={book} />
                                                )
                                            })
                                            :
                                            <></>
                                    }
                                </div>
                            </Grid>





                        </Container>

                        <div style={{
                            margin: 30
                        }}>
                            <Copyright />
                        </div>

                        {/* <footer>
                    <Box pb={4}>
                                <Copyright />
                        </Box>

                    </footer> */}
                    </main>
                </>
                // </Router>
                :
                <></>
            }
        </div>
    );
}