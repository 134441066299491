/**
 * @project kgtopg - schooler - partner
 * curriculum map unit data services
 */
import API from "./http";
import axios from "axios";
import qs from "querystring";
import Constants from "../resource/Constants";

export default {
    async postSaveMapUnit(data) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += "/save-map-unit/";

            console.log("postSaveMap api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveMapUnit api response", response)

            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },
    async postUpdateMapUnit(data, mapUnitId) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/curriculum-map-unit/${mapUnitId}`;

            console.log("postUpdateMapUnit api url", url)

            const options = {
                method: "PUT",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postUpdateMapUnit api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },
    async getMapUnits(mapId) {
        // console.log("date",stuId,jsDate)
        // we wait
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/unit-map/?map_id=${mapId}/`;

            console.log(`curriculum map units by map url ${url}`);

            let response = await axios.get(url);
            // console.log(`upcoming response ${JSON.stringify(response)}`)
            // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

            // .then(response => {
            // console.log(`upcoming classes response ${JSON.stringify(response)}`)

            // let result = response.data.data
            // let message = response.data.message

            if (response)
                return response
            else
                return false
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async getUnitLessons(unitId) {
        // console.log("date",stuId,jsDate)
        // we wait
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/unit-lesson/?unit_id=${unitId}`;

            console.log(`curriculum  units lessons by map url ${url}`);

            let response = await axios.get(url);
            // console.log(`upcoming response ${JSON.stringify(response)}`)
            // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

            // .then(response => {
            // console.log(`upcoming classes response ${JSON.stringify(response)}`)

            // let result = response.data.data
            // let message = response.data.message

            if (response) {
                console.log(`curriculum  units lessons by map data`, response);
                return response
            }

            else
                return false
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async getLessonPlans(lesnId) {
        // console.log("date",stuId,jsDate)
        // we wait
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/les-plan/?lesson_id=${lesnId}`;

            console.log(`curriculum  units lesson plans by map url ${url}`);

            let response = await axios.get(url);
            // console.log(`upcoming response ${JSON.stringify(response)}`)
            // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

            // .then(response => {
            // console.log(`upcoming classes response ${JSON.stringify(response)}`)

            // let result = response.data.data
            // let message = response.data.message

            if (response) {
                console.log(`curriculum  units lessons plans by map data`, response);
                return response
            }

            else
                return false
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },


    async postAddLessonPlan(data) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/save-lesson-plan/`;

            console.log("postUpdateMapUnit api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postsave api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },


    async postAddLesson(data) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/save-lesson/`;

            console.log("postAddLesson api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };

            let response = await axios(options);

            console.log("postAddLesson api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postUpdateLesson(id, data) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/lesson/${id}`;

            console.log("postUpdateLesson api url", url)

            const options = {
                method: "PUT",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };

            let response = await axios(options);

            console.log("postUpdateLesson api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

    async postUpdateLessonPlan(id, data) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/lesson-plan/${id}`;

            console.log("postUpdateLessonPlan api url", url)
            console.log("postUpdateLessonPlan id", id)
            console.log(`postUpdateLessonPlan body ${JSON.stringify(data)}`)

            const options = {
                method: "PUT",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },
            };

            let response = await axios(options);

            console.log("postUpdateLessonPlan api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },

}