import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import AccountProfile from "./slots/AccountProfile";
import AccountProfileDetails from "./slots/AccountProfileDetails";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import Tour from 'reactour'

const steps = [

  {
    selector: '[data-tut="profile-step"]',
    content: 'Here you can update your profile picture..',
  },
  {
    selector: '[data-tut="profile-details-step"]',
    content: 'The complete details can be updated in this section',
  },
  {
    selector: '[data-tut="logout-step"]',
    content: 'To logout of the application, press the button',
  },
  // ...
];

function ProfilePage() {

  const userId = Cookie.get("kgtopg.partner.user.id");
  const userName = Cookie.get("kgtopg.partner.user.name");
  const userEmail = Cookie.get("kgtopg.partner.user.email");
  const userPhoto = Cookie.get("kgtopg.partner.user.photo");
  const userPhone = Cookie.get("kgtopg.partner.user.phone");
  const userRole = Cookie.get("kgtopg.partner.user.role");
  const userLogin = Cookie.get("kgtopg.partner.user.login");
  const accname = Cookie.get("kgtopg.partner.user.accname");
  const accnum = Cookie.get("kgtopg.partner.user.accnum");
  const accifsc = Cookie.get("kgtopg.partner.user.accifsc");
  const bankbranch = Cookie.get("kgtopg.partner.user.bankbranch");
  const bankname = Cookie.get("kgtopg.partner.user.bankname");
  const pubuserid = Cookie.get("kgtopg.partner.user.pubuserid");
  const [isTourOpen, setIsTourOpen] = useState(false);

  const handleTourOpen = () => {
    setIsTourOpen(true)
  }

  useEffect(() => {
    return () => {
      //
    };
  }, []);

  return (
    <>
      <Helmet>
        <title> Profile | KGtoPG Partner </title>
      </Helmet>
      <div style={{
        position: 'absolute',
        top: 0,
        right: 0
      }}>
        <LiveHelpIcon
          onClick={handleTourOpen} />
      </div>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <AccountProfile
                userId={userId}
                userName={userName}
                userEmail={userEmail}
                userPhoto={userPhoto}
                userRole={userRole}
                userLogin={userLogin}
                accname={accname}
                accnum={accnum}
                accifsc={accifsc}
                bankbranch={bankbranch}
                bankname={bankname}
                pubuserid={pubuserid}
                userPhone={userPhone}
              />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <AccountProfileDetails
                userId={userId}
                userName={userName}
                userEmail={userEmail}
                userPhoto={userPhoto}
                userRole={userRole}
                userLogin={userLogin}
                accname={accname}
                accnum={accnum}
                accifsc={accifsc}
                bankbranch={bankbranch}
                bankname={bankname}
                pubuserid={pubuserid}
                userPhone={userPhone}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </>
  );
}

export default ProfilePage;
