import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { epubmainListItems, epubsecondaryListItems } from './SidebarList';
import TableModel from "../contentmaker/TableModel"
import { Route, Link, BrowserRouter as Router, Switch, Redirect, HashRouter, useHistory } from 'react-router-dom'
import ContentMaking from '../contentmaker/ContentMaking';
// import Footer from "./Footer"
import { Avatar, Button } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import ContentMaking1 from "../contentmaker/ContentMaking1"
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Settings from '../contentmaker/Settings';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import EpubView from './EbookReaderScreen';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SidebarContent from './SidebarScreen';
import contentTable from "./MyBooksScreen"
import Contentlist from './MyBooksScreen';
import ebookContentMaking from "./ebookAddingContentScreen"
import LessonPlan from "./LessonPlanScreen";
import Quiz from "./QuizScreen"
// import Ebook from "./UploadEbookScreen"
import { useState } from 'react';
import logo from "../../assets/logo.png"
import publisherLogo from "../../assets/PSON.jpeg"
import McgrawHill from "../../assets/Mcgrawhill.jpg"
import "../../css/sidebar.css"
import MapList from '../curriculummap/MapList';
import UnitList from '../curriculummap/UnitList';
import { ContactlessOutlined, SettingsInputAntennaTwoTone } from '@material-ui/icons';
import SidebarContent1 from './ContentSidebar';
import ContentTable from '../contentmaker/ContentListTable';
// import UploadEbookScreen from './UploadContent';
import PublisherListSideBar from './PublisherListSideBar';
import LeftCard from "./slots/LeftCard"
import { Helmet } from 'react-helmet';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import Budget from './slots/dashboard/Budget';
import LatestOrders from './slots/dashboard/LatestOrders';
import LatestProducts from './slots/dashboard/LatestProducts';
import Sales from './slots/dashboard/Sales';
import TasksProgress from './slots/dashboard/TasksProgress';
import TotalCustomers from './slots/dashboard/TotalCustomers';
import TotalProfit from './slots/dashboard/TotalProfit';
import TrafficByDevice from './slots/dashboard/TrafficByDevice';
import { useDispatch, useSelector } from 'react-redux';
import { listDashBoardHead } from '../../redux/actions/DashBoardActions';
import TurnBook from './slots/TurnBook';
import TeachView from './slots/TeachView';
import Draggable from "react-draggable";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import API from '../../http/http'
import PARTNERAPI from '../../http/httppartner'
import PUBLISHERAPI from '../../http/publisher'
import ReactPlayer from "react-player";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import noVideo from "../../assets/screenshot.png"
import { listPusblisherTeachGet } from '../../redux/actions/TeachPreviewAction';

import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ReactComponent as RippleIcon } from '../../assets/Ripple38.svg'
import DocViewer from "react-doc-viewer";



function FileViewer(props) {
    const { url } = props
    const docs = [
        { uri: url },
    ];

    return <DocViewer documents={docs} />;
}



const useStyles3 = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        position: 'absolute',
        right: 10,
        bottom: 10
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function FloatingActionButtons(props) {
    const classes = useStyles3();
    console.log(`props in fab ${JSON.stringify(props)}`)
    const bookId = (props.bookId != null) ? props.bookId : 0
    const onToggleDrawer = props.onToggleDrawer
    const anchor = props.anchor

    return (
        <div className={classes.root}>
            {/* 
            <Fab color="primary" aria-label="add">
                <AddIcon />
            </Fab>
            <Fab color="secondary" aria-label="edit">
                <EditIcon />
            </Fab>
 */}
            <Fab variant="extended">
                <NavigationIcon

                    className={classes.extendedIcon} />
                <Button onClick={onToggleDrawer(anchor)}>GUIDE</Button>

            </Fab>
            <Fab aria-label="like">
                <Link to={"/publisher-ebook/map-content?book_id=" + bookId}>
                    {/* <Button color="primary">Back to Ebook</Button> */}
                    <ArrowBackIosIcon />
                </Link>

            </Fab>
        </div>
    );
}


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>
            {'Copyright © '}
            <Link color="inherit" href="https://kgtopg.com/">
                KGToPG
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        display: "flex",
        justifyContent: "center",
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        // justifyContent: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        minWidth: '100%'

    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0
    },
}));

function PaperComponent(props) {
    return (
        <Draggable
            disabled={true}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
const useStyles2 = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

function SwipeableTemporaryDrawer(props) {
    const classes = useStyles2();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [overview, setOverview] = useState("No Data")
    const [overviewMins, setOverviewMins] = useState("No Data")
    const [activity, setActivity] = useState("No Data")
    const [activityMins, setActivityMins] = useState("No Data")
    const [conclusion, setConclusion] = useState("No Data")
    const [conclusionMins, setConclusionMins] = useState("No Data")
    const [Assessment, setAssessment] = useState("No Data")
    const [AssessmentMins, setAssessmentMins] = useState("No Data")

    const publisherTeachGet = useSelector(state => state.publisherTeach)
    const { loadingPubTeach, pubTeachPlans, errorPubTeach } = publisherTeachGet

    let notNull = true;
    let isLoading = false;
    const lessonPlan = props.lessonPlan

    if ((loadingPubTeach == null || loadingPubTeach == 'undefined' || loadingPubTeach == undefined) && (publisherTeachGet == null || publisherTeachGet == 'undefined' || publisherTeachGet == undefined))
        notNull = false;
    else if (loadingPubTeach != null && loadingPubTeach == 'undefined' && loadingPubTeach == undefined && publisherTeachGet != null && publisherTeachGet == 'undefined' && publisherTeachGet == undefined) {
        if (loadingPubTeach && publisherTeachGet)
            isLoading = true;
    }

    console.log(`NOT NULL ${notNull}`)

    {
        // loadingPubTeach && publisherTeachGet ?
        (notNull && isLoading) ?
            console.log("data is loading")
            :
            (notNull && !isLoading && pubTeachPlans && (pubTeachPlans.length || pubTeachPlans.data)) ?
                console.log("publisher teach plans data ", pubTeachPlans.data)
                : console.log('data is null')

    }

    console.log(`loadingpubteach ${loadingPubTeach}`)
    console.log(`publisherteachget ${JSON.stringify(publisherTeachGet)}`)
    console.log(`lessonPlan ${JSON.stringify(lessonPlan)}`)

    // setOverview(pubTeachPlans.overview_text),
    //     setOverviewMins(pubTeachPlans.overview_min_num),
    //     setActivity(pubTeachPlans.activity_text)
    // setActivityMins(pubTeachPlans.activity_min_num)
    // setConclusion(pubTeachPlans.conclusion_text)
    // setConclusionMins(pubTeachPlans.conclusion_min_num)
    // setAssessment(pubTeachPlans.quiz_text)
    // setAssessmentMins(pubTeachPlans.quiz_min_num)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            {/* <List>
                {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List> */}

            <div
                style={{
                    padding: 20
                }}>
                <Typography
                    variant="h5"
                    color="primary">TEACHER GUIDE</Typography>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 20
                }}>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '49%'
                    }}>

                        <div className="overview"
                            style={{
                                marginBottom: 20
                            }}>
                            <Typography
                                variant="h5">
                                Overview
                            </Typography>
                            <Typography
                                variant="body2">
                                {/* {
                                    // loadingPubTeach && publisherTeachGet ?
                                    (notNull && isLoading) ?
                                        "data is loading"
                                        :
                                        (notNull && !isLoading) ?
                                            (pubTeachPlans && (pubTeachPlans.length || pubTeachPlans.data)) ?
                                                pubTeachPlans.data.overview_text : `n/a`
                                            : `n/a`

                                } */}

                                {
                                    lessonPlan ? lessonPlan.overview_text : `n/a`
                                }
                                {/* The Bitbucket Pipelines and Jira Software integration allows your team to automatically track associated builds and deployments to Jira issues. This removes the need to manually keep your Jira issues up to date while giving the entire team visibility into the status of your work across individual issue views, viewing a specific deployments, or looking across work such as Sprint or Epic views. */}
                            </Typography>

                            <Typography
                                variant="caption">
                                {/* {
                                    // loadingPubTeach && publisherTeachGet ?
                                    (notNull && isLoading) ?
                                        "data is loading"
                                        :
                                        (notNull && !isLoading) ?
                                            (pubTeachPlans && (pubTeachPlans.length || pubTeachPlans.data)) ?
                                                pubTeachPlans.data.overview_min_num
                                                : `n/a`
                                            : `n/a`
                                }  */}
                                {
                                    lessonPlan ? lessonPlan.overview_min_num : `n/a`
                                }
                                &nbsp;mins
                            </Typography>
                        </div>
                        <div className="activity">
                            <Typography
                                variant="h5">
                                Activity
                            </Typography>
                            <Typography
                                variant="body2">
                                {/* {
                                    // loadingPubTeach && publisherTeachGet ?
                                    (notNull && isLoading) ?
                                        "data is loading"
                                        :
                                        (notNull && !isLoading) ?
                                            (pubTeachPlans && (pubTeachPlans.length || pubTeachPlans.data)) ?
                                                pubTeachPlans.data.activity_text
                                                : `n/a`
                                            : `n/a`

                                } */}

                                {
                                    lessonPlan ? lessonPlan.activity_text : `n/a`
                                }

                                {/* The Bitbucket Pipelines and Jira Software integration allows your team to automatically track associated builds and deployments to Jira issues. This removes the need to manually keep your Jira issues up to date while giving the entire team visibility into the status of your work across individual issue views, viewing a specific deployments, or looking across work such as Sprint or Epic views. */}
                            </Typography>

                            <Typography
                                variant="caption">
                                {/* {
                                    // loadingPubTeach && publisherTeachGet ?
                                    (notNull && isLoading) ?
                                        "data is loading"
                                        :
                                        (notNull && !isLoading) ?
                                            (pubTeachPlans && (pubTeachPlans.length || pubTeachPlans.data)) ?
                                                pubTeachPlans.data.activity_min_num
                                                : `n/a`
                                            : `n/a`

                                } */}

                                {
                                    lessonPlan ? lessonPlan.activity_min_num : `n/a`
                                }
                                &nbsp;mins
                            </Typography>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '49%'
                    }}>

                        <div className="conclusion"
                            style={{
                                marginBottom: 20
                            }}>
                            <Typography
                                variant="h5">
                                Conclusion
                            </Typography>
                            <Typography
                                variant="body2">
                                {/* {
                                    // loadingPubTeach && publisherTeachGet ?
                                    (notNull && isLoading) ?
                                        "data is loading"
                                        :
                                        (notNull && !isLoading) ?
                                            (pubTeachPlans && (pubTeachPlans.length || pubTeachPlans.data)) ?
                                                pubTeachPlans.data.conclusion_text
                                                : `n/a`
                                            : `n/a`

                                } */}

                                {
                                    lessonPlan ? lessonPlan.conclusion_text : `n/a`
                                }
                                {/* The Bitbucket Pipelines and Jira Software integration allows your team to automatically track associated builds and deployments to Jira issues. This removes the need to manually keep your Jira issues up to date while giving the entire team visibility into the status of your work across individual issue views, viewing a specific deployments, or looking across work such as Sprint or Epic views. */}
                            </Typography>

                            <Typography
                                variant="caption">
                                {/* {
                                    // loadingPubTeach && publisherTeachGet ?
                                    (notNull && isLoading) ?
                                        "data is loading"
                                        :
                                        (notNull && !isLoading) ?
                                            (pubTeachPlans && (pubTeachPlans.length || pubTeachPlans.data)) ?
                                                pubTeachPlans.data.conclusion_min_num
                                                : `n/a`
                                            : `n/a`
                                }  */}
                                {
                                    lessonPlan ? lessonPlan.conclusion_min_num : `n/a`
                                }&nbsp;mins
                            </Typography>
                        </div>
                        <div className="assessment">
                            <Typography
                                variant="h5">
                                Assessment
                            </Typography>

                            <Typography
                                variant="body2">
                                {/* {
                                    // loadingPubTeach && publisherTeachGet ?
                                    (notNull && isLoading) ?
                                        "data is loading"
                                        :
                                        (notNull && !isLoading) ?
                                            (pubTeachPlans && (pubTeachPlans.length || pubTeachPlans.data)) ?
                                                pubTeachPlans.data.quiz_text
                                                : `n/a`
                                            : `n/a`
                                } */}

                                {
                                    lessonPlan ? lessonPlan.quiz_text : `n/a`
                                }
                                {/* The Bitbucket Pipelines and Jira Software integration allows your team to automatically track associated builds and deployments to Jira issues. This removes the need to manually keep your Jira issues up to date while giving the entire team visibility into the status of your work across individual issue views, viewing a specific deployments, or looking across work such as Sprint or Epic views. */}
                            </Typography>
                            <Typography
                                variant="caption">
                                {/* {
                                    // loadingPubTeach && publisherTeachGet ?
                                    (notNull && isLoading) ?
                                        "data is loading"
                                        :
                                        (notNull && !isLoading)
                                            ?
                                            (pubTeachPlans && (pubTeachPlans.length || pubTeachPlans.data)) ?
                                                pubTeachPlans.data.quiz_min_num
                                                : `n/a`
                                            : `n/a`

                                }  */}

                                {
                                    lessonPlan ? lessonPlan.quiz_min_num : `n/a`
                                }&nbsp;mins
                            </Typography>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );

    const bookId = (props.bookId != null) ? props.bookId : 0

    return (
        <div>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}GUIDE</Button> */}
                    <FloatingActionButtons
                        onToggleDrawer={(anc) => toggleDrawer(anc, true)}
                        anchor={anchor}
                        bookId={bookId}
                        style={{zIndex:5}}
                    />
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}

export default function TeachPreview(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [showButton, setShowButton] = useState(false)
    const [AccordClose, setAccordClose] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [showLogo, setShowLogo] = useState(true)
    const [state, setState] = React.useState({
        right: false,
    })

    const [showSideCont, SetShowSideCont] = useState(false)
    const [path, setPath] = useState('')
    const [readerData, setReaderData] = useState("")
    const [isLoadingscr, setIsLoadingscr] = useState(false)
    const [sidebarStatus, setSidebarStatus] = useState(false)
    const [sideBarPubData, setSideBarPubData] = useState(null)
    const [bookId, setBookId] = useState("")
    const [contentData, setContentData] = useState(null)
    const [leftPage, setLeftPage] = useState(null);
    const [rightPage, setRightPage] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false)
    const [contentTypeName, setContentTypeName] = useState(false)
    const [lessonPlanData, setLessonPlan] = useState(null)
    const [mapUnits, setMapUnits] = useState(null)
    const [videoPlaying, setVideoPlaying] = useState(false)
    const [bookDetails, setBookDetails] = useState(null)
    const dispatch = useDispatch()



    const updateLessonPlan = (lessonPlan) => {
        console.log("lessonPlan at Teach Preview screen",lessonPlan)
        setLessonPlan(lessonPlan)
    }

    // console.log("windows path in dashboard", window.location.pathname)



    const getEbookContent = async (book_id) => {
        // let content = []
        console.log(`getting book content..`)
        const response = await PUBLISHERAPI.GetEbookContentTeachView(book_id)
        console.log("response at Teach Preview", response)

        let temp = response.data.data.book_details.cont_book
        const bookDetails = response.data.data.book_details
        const mapUnitsData = response.data.data.map_units;
        console.log(`mapunits in response ${JSON.stringify(mapUnitsData)}`);
        console.log(`book details ${JSON.stringify(bookDetails)}`);

        setBookDetails(bookDetails)
        if (!mapUnitsData) {
            setLessonPlan(null)
            setMapUnits(null)
        } else {
            setMapUnits(mapUnitsData)
        }

        setContentData(temp)
        // temp.map((detail,index)=>{
        //     console.log("mapped content data",detail)
        //     content.push(detail)
        // })


    }


    console.log("content at teach preview", contentData)

    useEffect(() => {
        console.log(`useeffect start`)
        const query = new URLSearchParams(window.location.search);
        const teach_book_id = query.get("book-id");
        console.log(`useeffect before set`)
        setBookId(teach_book_id)

        getEbookContent(teach_book_id)

        return (() => {
            //
        })
    }, [])


    const updatePages = (leftPage, rightPage) => {
        setLeftPage(leftPage);
        setRightPage(rightPage);

        
        dispatch(listPusblisherTeachGet(leftPage, rightPage, bookId))
    };


    const CloseHandleClick = () => {
        setOpenDialog(false);

        setVideoPlaying(false)
    };

    const handleClickOpen = (VideoUrl, type) => {
        console.log("video Url at handle click open", VideoUrl);
        if (!type) {
            type = 'video/mp4'
        }
        setVideoUrl(VideoUrl);
        setContentTypeName(type)
        setOpenDialog(true);

    };


    const pages = [
        "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/01.jpg",
        "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/02.jpg",
        "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/03.jpg",
        "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/04.jpg",
        "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/05.jpg",
        "https://raw.github.com/blasten/turn.js/master/demos/magazine/pages/06.jpg"
    ];



    console.log("state reader data ", readerData)

    console.log(`mapUnits while rendering ${JSON.stringify(mapUnits)}`)

    if(!bookDetails) {
        return <>Loading..</>
    }

    return (
        <div className={classes.root}>
            {isLoadingscr == false ?
                // <Router>
                <>
                    <CssBaseline />
                    <LinearProgress />

                    <LeftCard />

                    <main className={classes.content}>
                        <Helmet>
                            <title>  Teach Preview  | KGtoPG Partner</title>
                        </Helmet>

                        <Container className={classes.container}>



                            {contentData && contentData.length != 0 ? (
                                contentData.map((list, index) => {
                                    console.log(
                                        "left and right ,page1,page2",
                                        list.page_num1,
                                        list.page_num2
                                    );
                                    return leftPage == list.page_num1 ?
                               (
                                        <div style={{ position: "absolute", zIndex: 3 }}>
                                            <Draggable
                                                axis="both"
                                                handle=".handle"
                                                defaultPosition={{ x: (list.x_pos + 95), y: list.y_pos }}
                                                position={null}
                                                grid={[50, 50]}
                                                scale={1}
                                                disabled={true}
                                                // onStart={handleStart}
                                                // onDrag={eventLogger}
                                                // onStop={handleStop}
                                                style={{ zIndex: 2, position: "absolute" }}
                                            >
                                                <div>
                                                    <div className="handle">
                                                        <Button
                                                            aria-label="add"
                                                            size="small"
                                                            style={{
                                                                // backgroundColor: "#ffffff",
                                                                // borderBottomLeftRadius: 0,
                                                                backgroundColor: "Transparent",
                                                                padding: 0,
                                                                border: 0,
                                                                fontSize: 25,
                                                                display: "flex",
                                                                // flexDirection: "column",
                                                            }}
                                                            onClick={() => {
                                                                handleClickOpen(list.content_location, list.content_type_name);
                                                            }}
                                                        >
                                                            {/* <div style={{ marginTop: 15, marginLeft: 3 }}>
                                                                <span
                                                                    style={{
                                                                        textDecoration: "none",
                                                                        fontSize: 10,
                                                                        marginRight: -5,
                                                                        marginTop: 10,
                                                                    }}
                                                                >
                                                                    {index + 1}
                                                                </span>
                                                            </div> */}

                                                            <div
                                                                style={{
                                                                    position: 'relative',
                                                                    width: 38,
                                                                    height: 38,
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}>


                                                                <PlayArrowOutlinedIcon
                                                                    style={{
                                                                        position: 'absolute',
                                                                        alignSelf: 'center',
                                                                        fontSize: 30,
                                                                        color: "grey",
                                                                        // marginLeft: 7,
                                                                        // marginTop: 7,
                                                                        zIndex: 3,
                                                                        width: 24,
                                                                        height: 24
                                                                    }}
                                                                />


                                                                <RippleIcon
                                                                    style={{
                                                                        position: 'absolute',
                                                                        alignSelf: 'center'
                                                                    }} />
                                                            </div>


                                                        </Button>
                                                        {/* 
                                                        <div style={{ marginTop: 10, marginRight: 5 }}>
                                                            <PlayArrowOutlinedIcon
                                                                style={{ fontSize: 30, color: "#3f51b5" }}
                                                            />
                                                        </div>
 */}

                                                    </div>
                                                </div>
                                            </Draggable>
                                        </div>
                                    ) : (
                                        <></>
                                    );
                                })
                            ) : (
                                <></>
                            )}


                            <Dialog
                                open={openDialog}
                                onClose={() => {
                                    CloseHandleClick();
                                }}
                                PaperComponent={PaperComponent}
                                aria-labelledby="draggable-dialog-title"
                            >
                                <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                                    Content Preview
                                </DialogTitle>
                                <DialogContent style={{ width: "100%" }}>
                                    {contentTypeName == "video/mp4" ?
                                        videoUrl ? (
                                            <ReactPlayer url={videoUrl} width={"550px"} controls={true} />
                                        ) : (
                                            <img src={noVideo} alt="No Video" style={{ width: "90%" }} />
                                        )
                                        :
                                        contentTypeName == "application/pdf"
                                            ?
                                            <FileViewer url={videoUrl} />
                                            :
                                            contentTypeName == "image/jpg"
                                                ?
                                                <FileViewer url={videoUrl} />
                                                :
                                                contentTypeName == "audio/mp3"
                                                    ?
                                                    <ReactPlayer url={videoUrl} width={"550px"} playing={videoPlaying} />
                                                    :
                                                    <></>

                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        autoFocus
                                        onClick={() => {
                                            CloseHandleClick();
                                        }}
                                        color="primary"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>


                            <SwipeableTemporaryDrawer bookId={bookId} lessonPlan={lessonPlanData} />

                            {/* 
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                <Link to={"/publisher-ebook/map-content?book_id=" + bookId}>
                                    <Button color="primary">Back to Ebook</Button>
                                </Link>
                            </div> */}

                            <div style={{ width: "90%", height: "100vh", justifyContent: "center", display: "flex" }}>
                                <TeachView
                                    getPageNum={(left, right) => updatePages(left, right)}
                                    bookPages={pages}
                                    bookDetails={bookDetails}
                                    lessonPlan={lessonPlanData}
                                    mapUnits={mapUnits}
                                    updateLessonPlan={(lessonPlan) => updateLessonPlan(lessonPlan)}
                                />
                            </div>
                        </Container>


                    </main>
                </>

                :
                <></>
            }
        </div>
    );
}