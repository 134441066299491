import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { epubmainListItems, epubsecondaryListItems } from '../ebook/SidebarList';
import TableModel from "./TableModel"
import { Route, Link, BrowserRouter as Router, Switch, Redirect, HashRouter, useHistory } from 'react-router-dom'
import ContentMaking from './ContentMaking';
// import Footer from "./Footer"
import { Avatar, Button } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import ContentMaking1 from "./ContentMaking1"
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Settings from './Settings';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import EpubView from '../ebook/EbookReaderScreen';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SidebarContent from '../ebook/SidebarScreen';
import contentTable from "../ebook/MyBooksScreen"
import Contentlist from '../ebook/MyBooksScreen';
import ebookContentMaking from "../ebook/ebookAddingContentScreen"
import LessonPlan from "../ebook/LessonPlanScreen";
import Quiz from "../ebook/QuizScreen"
import Ebook from "../ebook/UploadEbookScreen"
import { useState } from 'react';
import logo from "../../assets/logo.png"
import publisherLogo from "../../assets/PSON.jpeg"
import McgrawHill from "../../assets/Mcgrawhill.jpg"
import "../../css/sidebar.css"
import MapList from '../curriculummap/MapList';
import UnitList from '../curriculummap/UnitList';
import { ContactlessOutlined, SettingsInputAntennaTwoTone } from '@material-ui/icons';
import SidebarContent1 from "../ebook/ContentSidebar";
import SubjectList from './SubjectList';
import UploadEbookScreen from './slots/UploadContent';
import PublisherListSideBar from '../ebook/PublisherListSideBar';
import SidebarContentCont from './SidebarScreenCont';
import Leftbar from './slots/Leftbar';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
        justifyContent: 'space-between',
        minHeight: '100%',
        height: '100%',
        minWidth: '100%'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0
    },
}));



export default function SubjectDashboard2() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [showButton, setShowButton] = useState(false)
    const [AccordClose, setAccordClose] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [showLogo, setShowLogo] = useState(true)
    const [state, setState] = React.useState({
        right: false,
    })

    const [showSideCont, SetShowSideCont] = useState(false)
    const [path, setPath] = useState('')
    const [readerData, setReaderData] = useState("")
    const [isLoadingscr, setIsLoadingscr] = useState(false)
    const [ilc, setIlc] = useState(false)


    const handleDrawerOpen = () => {
        setOpen(true);
        setAccordClose(true)
        setShowLogo(true)
        SetShowSideCont(false)
    };
    const handleDrawerClose = () => {
        setOpen(false);
        setAccordClose(false)
        setShowLogo(false)

    };



    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


    const hideFab = (list, index) => {
        console.log("hiding the button", list, index)
        setShowButton(true)
        setIsLoadingscr(false)
        console.log("show button at parent", showButton)


    }


    const showSideContent = (status, path) => {
        console.log("sidecontent status", status)
        SetShowSideCont(status)
        if (status == true) {
            SetShowSideCont(status)
            setOpen(false)
            setShowLogo(false)
        } else {
            SetShowSideCont(status)
            setOpen(true)
            setShowLogo(true)
        }
        setPath(window.location.pathname)

        console.log(" callback function status", status, window.location.pathname)
    }

    console.log("windows path in dashboard", window.location.pathname)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    }

    localStorage.setItem('publish.pustak.media_type', 'ilc')
    console.log(`content type check ilc`)
        
    const setupILC = () => {
        // const prm = props.match.params.type
        const prm = localStorage.getItem('publish.pustak.media_type')
        
        if(prm == 'ilc')
            setIlc(true)
        
    }


    useEffect(() => {

        // setupILC()

    }, [])


    const sendDataToReaderScreen = (list, status) => {
        setIsLoading(true)
        console.log("sending..... Data to Reader Screen", list)
        if (list) {
            setReaderData(list)
            setIsLoading(false)
            setShowButton(status)
        }

    }



    console.log("state reader data ", readerData)
    return (
        <div className={classes.root}>
            {isLoadingscr == false ?
                <>
                    {/* <Router> */}

                    <CssBaseline />
                    <LinearProgress />


                    <Leftbar />


                    <main className={classes.content}>
                        {/* <div className={classes.appBarSpacer} /> */}
                        <Container className={classes.container}
                        style={{
                            minHeight: '100%'
                        }}>
                            <Grid container spacing={3}
                            style={{
                                minHeight: '100%'
                            }}>

                                {/* Recent Orders */}
                                <Grid item xs={12}>
                                    {/* <Paper className={classes.paper}> */}
                                    {
                                        // <Redirect exact from="/publisher-content" to="/publisher-content/content" />
                                    }
                                    {/* <Switch>
                                        <Route exact={true} path="/publisher/upload" component={Ebook} />
                                        <Route exact={true} path="/publisher/mapping" render={() => <EpubView showButtonStatus={showButton} readerDataList={readerData} />} />
                                        <Route exact={true} path="/contentmaker/settings" component={Settings} />
                                        <Route exact={true} path="/publisher/mybooks" render={() => <Contentlist sidebarStatus={(status, pubData) => showSideContent(status, pubData)} />} />
                                        <Route exact={true} path="/publisher/addcontent" component={ebookContentMaking} />
                                        <Route exact={true} path="/publisher/quiz" component={Quiz} />
                                        <Route exact={true} path="/publisher/lessonplan" component={LessonPlan} />
                                        <Route exact={true} path="/publisher-content/content" component={ContentTable} />
                                        <Route exact={true} path="/publisher/curriculum-map" component={MapList} />
                                        <Route exact={true} path="/publisher/curriculum-map/set-units/1" component={UnitList} />
                                        <Route exact={true} path="/publisher/upload-ebook" component={UploadEbookScreen} />
                                    </Switch> */}
                                    {/* </Paper> */}
                                    <SubjectList />

                                </Grid>
                            </Grid>

                            {/* <div style={{ justifyContent: "flex-end", minWidth: "100%", display: "flex" }}>
                                <Fab
                                    aria-label="add"
                                    style={{
                                        alignSelf: "flex-end",
                                        position: "absolute",
                                        right: 5,
                                        backgroundColor: "#ffffff",
                                        borderRadius: 10,
                                        top: 200,
                                        zIndex: 2,
                                    }}
                                >
                                    <Link to="/publisher-content/settings" style={{ textDecoration: "none" }}>
                                        <div style={{
                                            minWidth: 35,
                                            minHeight: 20,
                                            backgroundColor: "gray",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            backgroundColor: "#F3F6F9",
                                            borderRadius: 10
                                        }}
                                        >
                                            <SettingsOutlinedIcon color="primary" style={{ marginTop: 5 }} />
                                        </div>
                                    </Link>

                                </Fab>
                            </div> */}


                        </Container>
                    </main>

                    {/* </Router> */}
                </>
                :
                <></>
            }
        </div>
    );
}