
export const TRASHDETAILS_LIST_REQUEST = 'TRASHDETAILS_LIST_REQUEST'
export const TRASHDETAILS_LIST_SUCCESS = ' TRASHDETAILS_LIST_SUCCESS'
export const TRASHDETAILS_LIST_FAIL = 'TRASHDETAILS_LIST_FAIL'


export const TRASHDETAILSPOST_LIST_REQUEST = 'TRASHDETAILSPOST_LIST_REQUEST'
export const TRASHDETAILSPOST_LIST_SUCCESS = ' TRASHDETAILSPOST_LIST_SUCCESS'
export const TRASHDETAILSPOST_LIST_FAIL = 'TRASHDETAILSPOST_LIST_FAIL'

export const TRASHDETAILSRESTORE_LIST_REQUEST = 'TRASHDETAILSRESTORE_LIST_REQUEST'
export const TRASHDETAILSRESTORE_LIST_SUCCESS = ' TRASHDETAILSRESTORE_LIST_SUCCESS'
export const TRASHDETAILSRESTORE_LIST_FAIL = 'TRASHDETAILSRESTORE_LIST_FAIL'