import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import kgtopgLogo from "../../assets/logo.png"
import parentChild from "../../assets/parentchild_sq.png"
import schoolerLogo from "../../assets/schooler.png"
import Applier from "../../assets/Applier.png"
import publisher from "../../assets/publisher.jpg"
import { Link } from "react-router-dom";
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const hasWindow = typeof window !== 'undefined';
const width = hasWindow ? window.innerWidth : null;
const height = hasWindow ? window.innerHeight : null;




const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "center",
        alignSelf: "center"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 125,
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        justifyContent: "center",
        alignItems: "center",
        borderRadius:5

    },
}));

export default function HomePage(props) {
    const classes = useStyles();
    const [userRole, setUserRole] = useState(null)
    const [userData, setUserData] = useState(null)

    useEffect(() => {
        console.log("props.location", props.location.state)
        setUserRole(props.location.state.role)
        setUserData(props.location.state.roleData)
    }, [])

    const history = useHistory();
    const navigateToPublisher = (role, userData) => history.push({ pathname: '/publisher-ebook/dashboard', state: { role: role } });//eg.history.push('/login');
    const navigateToContent = (role, userData) => history.push({ pathname: '/publisher-content/dashboard', state: { role: role } });//eg.history.push('/login');

    const NavToPub = (role, userData) => {


        if (role && role == "EBOOK_PROVIDER") {
            navigateToPublisher(role)
        }


        if (role && role == "CONTENT_PROVIDER") {
            navigateToContent(role)
        }

    }


    const DisplayCard = (props) => {
        return (
            <Grid item xs={12} lg={3} md={12} style={{ alignSelf: "center" }} onClick={() => NavToPub(props.role, props.userData)}>
                <Paper className={classes.paper}>
                    <img src={props.logo ? props.logo : kgtopgLogo} alt="kgtopg-logo" style={{ width: '70%', height: 100, objectFit:"contain", cursor: 'pointer' }} />
                </Paper>
            </Grid>
        )
    }

    return (
        <div className={classes.root}>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={kgtopgLogo} alt="kgtopg-logo" style={{ width: 400 }} />
            </div>

            <div style={{ display: "flex", justifyContent: "center", }}>

                <Grid
                    container
                    spacing={3}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "90%",

                    }}
                >
                    <DisplayCard logo={schoolerLogo} name="Schooler" role="Schooler" roleData="" />
                    
                    <DisplayCard logo={Applier} name="Applier" role="Applier" roleData="" />
                    <DisplayCard logo={publisher} name="Publisher" role={userRole} roleData={userData} />

                </Grid>
            </div>

        </div>
    );
}