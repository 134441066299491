import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  ButtonGroup,
  Chip
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Route, Link, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import MapAPI from '../../http/curriculummap';
import { useDispatch, useSelector } from 'react-redux';
import { listCurriculumGet } from '../../redux/actions/curriculumMapAction';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles';
import { listUnitMapGet } from '../../redux/actions/unitMapAction';
import { updateCurrMap } from '../../redux/actions/currMapActions';
import { listBoardGet } from '../../redux/actions/boardDetailsAction';
import { listInstGet } from '../../redux/actions/InstituteDetailsAction';
import { listSubGet } from '../../redux/actions/subjectsAction';
import { listStdGet } from '../../redux/actions/standardAction';
import Cookie from 'js-cookie'
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
import PublisherAPI from '../../http/publisher';
import Fab from "@material-ui/core/Fab";
import { listAllEventsGet } from '../../redux/actions/Trainer/EventsGet';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { listSingleEventDel } from '../../redux/actions/Trainer/EventsDelete';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase";
import Constants from "../../resource/Constants";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';
import { listSingleEventPut } from '../../redux/actions/Trainer/EventsPut';


const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}


const emails = ['username@gmail.com', 'user02@gmail.com'];

function createData(name, published, created, edit, deleteOption) {
  return { name, published, created, edit, deleteOption };
}

function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <CircularProgress /> */}
      <CircularProgress color="secondary" />
    </div>
  );
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'NAME' },
  // { id: 'published', numeric: false, disablePadding: false, label: 'PUBLISHED DATE' },
  // { id: 'created', numeric: false, disablePadding: false, label: 'DRAFT CREATED' },
  // { id: 'published', numeric: false, disablePadding: false, label: 'TOTAL UNITS' },
  { id: 'standard', numeric: false, disablePadding: false, label: 'STANDARD' },
  { id: 'start', numeric: false, disablePadding: false, label: 'START TIME' },
  { id: 'isLive', numeric: false, disablePadding: false, label: 'CASTING ' },
  // { id: 'end', numeric: false, disablePadding: false, label: 'END TIME' },

  // { id: 'created', numeric: false, disablePadding: false, label: 'TOTAL CHAPTERS' },
  { id: 'edit', numeric: false, disablePadding: false, label: 'EDIT' },
  { id: 'deleteOption', numeric: false, disablePadding: false, label: 'DELETE' },
];

const useStyles5 = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});


//delete confirmation dialog
function DeleteConfDialog(props) {
  const classes = useStyles5();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const sendDataBack = (id) => {
    props.onDeleteMap(id)
    handleClose()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete Event</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ justifyContent: "center" }}>
            <div style={{ alignSelf: "center", display: "flex", justifyContent: "center" }}>
              <Fab style={{ backgroundColor: "#fff", }}>
                <DeleteOutlineOutlinedIcon size="large" color="secondary" />
              </Fab>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              Are you Sure, Do you want to delete the Event?
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => sendDataBack(props.id)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

DeleteConfDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};


// Delte Dialog function with deleteicon and confg dialog
function DeleteDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  console.log(`props id ${props.id}`)

  return (
    <div>

      <IconButton aria-label="delete" color="secondary" size="small"
        onClick={handleClickOpen}>
        <DeleteOutlineOutlinedIcon />
      </IconButton>
      <DeleteConfDialog selectedValue={selectedValue} onDeleteMap={(id) => props.onDeleteMap(id)} id={props.id} open={open} onClose={handleClose} />
    </div>
  );
}

function EventEditDialog(props) {
  const { eventDetails } = props
  const dispatch = useDispatch()
  const [pubId, setPubId] = useState("")
  const [eventId, setEventId] = React.useState(eventDetails.id)
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(eventDetails.event_name)
  const [desc, setDesc] = React.useState(eventDetails.event_des)
  const [instType, setInstType] = React.useState(eventDetails.institute_type_id)
  const [board, setBoard] = React.useState(eventDetails.institute_board_id)
  const [clrStd, setClrStd] = React.useState(eventDetails.classroom_std)
  const [sub, setSub] = React.useState(eventDetails.subject_id)
  const [tags, setTags] = React.useState(eventDetails.event_tags)
  const [agenda, setAgenda] = useState(eventDetails.event_agenda)
  const [selectedDate, setSelectedDate] = React.useState(eventDetails.start_time);
  const [selectedTime, setSelectedTime] = React.useState(eventDetails.start_time)
  const [selectedDate1, setSelectedDate1] = React.useState(eventDetails.end_time);
  const [selectedTime1, setSelectedTime1] = React.useState(eventDetails.end_time)
  const [formatedTime, setFormatedTime] = React.useState()
  const [formatedTime1, setFormatedTime1] = React.useState()
  const [ImageDownloadUrls, setImageDownloadUrls] = React.useState(eventDetails.event_image)
  const [newImageDownloadUrls, setNewImageDownloadUrls] = React.useState("")
  const [liveStatus, setLiveStatus] = React.useState(eventDetails.is_live)
  const [openDelSnack, setOpenDelSnack] = React.useState(false)
  const [openSnack, setOpenSnack] = React.useState(false);
  const [showBtnGrp, setShowBtnGrp] = React.useState(eventDetails.is_live ? false : true)
  const [showChip, setShowChip] = React.useState(eventDetails.is_live ? true : false)
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const selDate = eventDetails.start_time
  const selDate1 = eventDetails.end_time

  console.log("eventDetails",eventDetails)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setTitle(value);
  }

  const handleDescChange = (event) => {
    const { name, value } = event.target
    setDesc(value)

  }

  const handleInstTypeChange = (e, id) => {
    const { name, value } = e.target;
    console.log("institute value at edit form", value, id)
    if (id) {
      setInstType(id);
      dispatch(listStdGet())
      dispatch(listBoardGet(id))
    } else {
      setInstType(value);
      dispatch(listStdGet())
      dispatch(listBoardGet(value))
    }

  }

  const handleBoardChange = (event) => {
    const { name, value } = event.target;
    console.log("board value at editform ", value)
    setBoard(value);
  }

  const handleClrStdChange = (event) => {
    const { name, value } = event.target;
    setClrStd(value);
  }

  const handleSubChange = (event) => {
    const { name, value } = event.target;
    setSub(value);
  }

  const handleTagsChange = (event) => {
    const { name, value } = event.target;
    setTags(value);
  }

  const handlAgendaChange = (event) => {
    const { name, value } = event.target;
    setAgenda(value)
  }
  const handleDateChange = (date) => {
    let formatedDate = JSON.stringify(date).slice(1, 11)
    setSelectedDate(formatedDate);
  };

  const handleTimeChange = (date) => {
    let format = date.toString().slice(16, 21)
    console.log("formated Time at handle date change", date.toString())
    setFormatedTime(format)
    setSelectedTime(date);
  };


  const handleDateChange1 = (date) => {
    let formatedDate = JSON.stringify(date).slice(1, 11)
    setSelectedDate1(formatedDate);
  };

  const handleTimeChange1 = (date) => {
    let format = date.toString().slice(16, 21)
    setFormatedTime1(format)
    setSelectedTime1(date);
  };


  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };



  const handleDeleteLive = () => {
    setShowChip(false)
    setShowBtnGrp(true)
    setLiveStatus(false)
    console.info('You clicked the delete icon.');
  };

  const handleDeleteRec = () => {
    setShowChip(false)
    setShowBtnGrp(true)
    setLiveStatus(false)

    console.info('You clicked the delete icon.');
  };



  const LiveStream = () => {
    setShowChip(true)
    setLiveStatus(true)
    setShowBtnGrp(false)
  }

  const RecordedVideo = () => {
    setShowChip(true)
    setLiveStatus(false)
    setShowBtnGrp(false)

  }



  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };


  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setNewImageDownloadUrls(downloadURL);
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  function setPublisher() {
    const userId = Cookie.get('kgtopg.partner.user.id')
    setPubId(userId)

    console.log(`pubId is ${userId}`)
  }




  const handleUpdateEvent = async () => {

    if (!title) {
      // alert('Please enter title..');
      setOpenSnack(true);
      return;
    }

    const body = {
      publisher_id: pubId,
      event_name: title,
      institute_type_id: instType,
      institute_board_id: board,
      classroom_std: clrStd,
      subject_id: sub,
      event_des: desc,
      event_image: newImageDownloadUrls ? newImageDownloadUrls : ImageDownloadUrls,
      event_agenda: agenda,
      event_tags: tags,
      date: selectedDate,
      start_time: selectedDate + " " + formatedTime,
      end_time: selectedDate1 + " " + formatedTime1,
      is_live: liveStatus == true ? 1 : 0,

    }

    console.log("body at create event", body)
    // dispatch(listSingleEventPut(body,eventId))


  };


  useEffect(() => {
    setPublisher()
    dispatch(listBoardGet(instType))
    dispatch(listStdGet())
    dispatch(listSubGet(instType, board))

 

    return (() => {
      //
    })

  }, [])

  const instDetails = useSelector(state => state.instDetails.instDetails)
  const boardDetails = useSelector(state => state.boardDetails.boardDetails)
  const subDetails = useSelector(state => state.subDetails.subDetails)
  const stdDetails = useSelector(state => state.stdDetails.stdDetails)

  return (
    <div 
    style={{
      overflow: "auto",
      overflowX: "initial",
      overflowY: "initial",
      scrollbarWidth: "thin"
    }}>

      <IconButton color="primary" aria-label="add to shopping cart" onClick={handleClickOpen}>
        <EditOutlinedIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        
      >
        <DialogTitle id="form-dialog-title" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontWeight: "500" }}>{eventDetails ? eventDetails.event_name : ""} [Edit]</DialogTitle>
        <DialogContent
          style={{
            overflow: "auto",
            overflowX: "initial",
            overflowY: "initial",
            scrollbarWidth: "thin"
          }}
        >
          <DialogContentText style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
            Edit Event lets you setup a old event with new details
          </DialogContentText>
          <div
            style={{
              overflow: "auto",
              overflowX: "initial",
              overflowY: "initial",
              scrollbarWidth: "thin"
            }}
          >

            {/* title field */}
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <TextField
                required
                id="outlined-required"
                label="Event Name"
                defaultValue="Hello World"
                variant="outlined"
                fullWidth
                type="text"
                autoFocus
                defaultValue={title}
                onChange={handleTitleChange}
                style={{ fontFamily: "Poppins, Helvetica, sans-serif", borderColor: title ? "green" : "blue" }}
                color={title ? "green" : "blue"}
              />
            </div>



            {/* insititute type dropdown */}
            <div style={{ marginTop: 10 }}>
              <FormControl variant="outlined" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  Institute Type
                </InputLabel>
                <Select
                  defaultValue={instType}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  // value={instType}
                  onChange={handleInstTypeChange}
                  label="Institute Type"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >

                  {(instDetails && instDetails.data) ?
                    instDetails.data.map((type, index) => {
                      console.log(`single inst type ${JSON.stringify(type)}`)
                      return (
                        <MenuItem key={index} value={type.institute_type_id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{type.institute_type_name}</MenuItem>
                      )
                    })
                    :
                    <MenuItem key={-2} value={-2} >No Types</MenuItem>
                  }
                </Select>
              </FormControl>
            </div>


            {/* institute board dropdown */}
            <div style={{ marginTop: 10 }}>
              <FormControl variant="outlined" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  Institute Board
                </InputLabel>
                <Select
                  defaultValue={board}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  // value={board}
                  onChange={handleBoardChange}
                  label="Institute Board"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  {boardDetails && boardDetails.data ?
                    boardDetails.data.map((brd, index) => {
                      return (
                        <MenuItem key={index} value={brd.institute_board_id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{brd.institute_board_name}</MenuItem>
                      )
                    })
                    :
                    <MenuItem key={-2} value={-2} >No Boards</MenuItem>
                  }

                </Select>
              </FormControl>
            </div>



            {/* institute standards */}
            <FormControl variant="outlined" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >
                Standard
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                defaultValue={clrStd}
                // value={clrStd}
                onChange={handleClrStdChange}
                label="Standard"
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >

                {stdDetails && stdDetails.data && stdDetails.data ?
                  stdDetails.data.map((sb, index) => {
                    return (
                      <MenuItem key={index} value={sb.id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{sb.standard}</MenuItem>
                    )
                  })
                  :
                  <MenuItem key={-2} value={-2} >No Standards</MenuItem>
                }

              </Select>
            </FormControl>



            {/* subject dropdown */}
            <div style={{ marginTop: 10 }}>
              <FormControl variant="outlined" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue={sub}
                  // value={sub}
                  onChange={handleSubChange}
                  label="Subject"
                  style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
                >
                  {subDetails && subDetails.data && subDetails.data.subjects ?
                    subDetails.data.subjects.map((sb, index) => {
                      return (
                        <MenuItem key={index} value={sb.subject_id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{sb.subject_name}</MenuItem>

                      )
                    })
                    :
                    <MenuItem key={-2} value={-2} >No Subjects</MenuItem>
                  }

                </Select>
              </FormControl>
            </div>

            {/* discription */}
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <TextField
                required
                id="outlined-required"
                label="Event Description"
                variant="outlined"
                fullWidth
                type="text"
                autoFocus
                defaultValue={desc}
                onChange={handleDescChange}
                style={{ fontFamily: "Poppins, Helvetica, sans-serif", borderColor: title ? "green" : "blue" }}
                color={desc ? "green" : "blue"}
                multiline
              />
            </div>



            {/* event agenda  */}
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <TextField
                required
                id="outlined-required"
                label="Event Agenda"
                variant="outlined"
                fullWidth
                type="text"
                autoFocus
                defaultValue={agenda}
                onChange={handlAgendaChange}
                style={{ fontFamily: "Poppins, Helvetica, sans-serif", borderColor: title ? "green" : "blue" }}
                color={agenda ? "green" : "blue"}
                multiline
              />
            </div>




            {/* tags field  */}


            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <TextField
                required
                id="outlined-required"
                label="Event Tags"
                variant="outlined"
                fullWidth
                type="text"
                autoFocus
                defaultValue={tags}
                onChange={handleTagsChange}
                style={{ fontFamily: "Poppins, Helvetica, sans-serif", borderColor: title ? "green" : "blue" }}
                color={title ? "green" : "blue"}
                multiline
              />
            </div>


            <div style={{ marginTop: 15 }}>
              <Typography>
                Start Time:
              </Typography>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">

                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date"
                    format="MM/dd/yyyy"
                    value={selectedDate ? selectedDate : selDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time"
                    value={selectedTime ? selectedDate : selDate}
                    onChange={handleTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>



            <div style={{ marginTop: 15 }}>

              <Typography>
                End Time:
              </Typography>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">

                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date"
                    format="MM/dd/yyyy"
                    value={selectedDate1 ? selectedDate1 : selDate1}
                    onChange={handleDateChange1}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time"
                    value={selectedTime1 ? selectedDate1 : selDate1}
                    onChange={handleTimeChange1}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>


            <div style={{ marginTop: 15 }}>


              <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                <Typography style={{ display: "flex", justifyContent: "flex-start" }}>
                  Video Casting:
                </Typography>

                {/* <Chip
    label="Primary clickable"
    clickable
    color="primary"
    onDelete={handleDelete}
    deleteIcon={<DoneIcon />}
  />
   */}
                {showChip ?
                  (liveStatus) ?

                    <Chip
                      icon={<VideocamOutlinedIcon />}
                      label="Selected Live Streaming"
                      onDelete={handleDeleteLive}
                      color="primary"
                      variant="outlined"

                    />
                    :
                    <Chip
                      icon={<MovieOutlinedIcon />}
                      label="Selected Recorded Video"
                      onDelete={handleDeleteRec}
                      color="primary"
                      variant="outlined"
                    />
                  :
                  <></>
                }


                {
                  showBtnGrp ?

                    <ButtonGroup variant="text" color="primary" aria-label="text primary button group" style={{ display: "flex", justifyContent: "center" }}>
                      <Button onClick={LiveStream} > Live Streaming</Button>
                      <Button onClick={RecordedVideo}>Recorded Video</Button>
                    </ButtonGroup>
                    :
                    <></>
                }

              </div>




              <div style={{ marginBottom: 20 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                    marginLeft: 15,
                    marginRight: 20,
                  }}
                >
                  <label
                    style={{
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      backgroundColor: "steelblue",
                      color: "white",
                      padding: 5,
                      borderRadius: 4,
                      cursor: "pointer",
                      textAlign: "center",
                      width: "100%"
                    }}

                  >
                    Upload Event Image
                    <FileUploader
                      hidden
                      // accept="image/*"
                      storageRef={firebase.storage().ref("images")}
                      onUploadStart={handleUploadStart}
                      onUploadError={handleUploadError}
                      onUploadSuccess={handleUploadSingleSuccess}
                      onProgress={handleProgress}
                    />
                  </label>
                </div>



                <div style={{ display: "flex", justifyContent: "center" }}>
                  {progress ? <CircularProgress /> : <></>}
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>

                  {newImageDownloadUrls ?

                    <img
                      src={newImageDownloadUrls}
                      alt="preview Image"
                      width="100px"
                      style={{
                        alignSelf: "center",
                        display: "flex",
                        width: 75,
                        margin:5

                      }}
                    />
                    :
                    <img
                      src={ImageDownloadUrls}
                      alt="preview Image"
                      width="100px"
                      style={{
                        alignSelf: "center",
                        display: "flex",
                        width: 75,

                      }}
                    />
                  }



                </div>
              </div>

            </div>


          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { handleUpdateEvent() }} color="primary">
            Update
          </Button>

          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
            <Alert2 onClose={handleCloseSnack} severity="warning">
              Event Updated successfully!
            </Alert2>
          </Snackbar>

        </DialogActions>
      </Dialog>
    </div>
  );
}

function UploadDialog(props) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false)
  }


  return (
    <div>
      <IconButton
        aria-label="delete"
        color="secondary"
        size="small"
        onClick={handleClickOpen}
      >
        <BackupOutlinedIcon />
      </IconButton>
      <UploadFormDialog open={open} onClose={handleClose} eventDetails={props.eventDetails} />
    </div>
  )
}


function UploadFormDialog(props) {
  const { open, onClose, eventDetails } = props
  const [videoName, setVideoName] = useState("")
  const [videoDesc, setVideoDesc] = useState("")




  const handleClose = () => {
    onClose()
  };

  return (
    <div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Upload Video</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please upload video to {eventDetails.event_name} Event
          </DialogContentText>
          <TextField
            required
            id="outlined-required"
            label="Required"
            defaultValue="Hello World"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
//toolbar of the table list
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
      })}
    >
      {numSelected > 0 ? (

        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
          Trainer-Events
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
          Trainer-Events
        </Typography>
      )}

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};




const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));



function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};





function Alert2(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  shape1: {
    backgroundColor: theme.palette.primary.main,
    width: 5,
    height: 20,
  },
  shape2: {
    backgroundColor: theme.palette.secondary.dark,
    width: 5,
    height: 20,
  },
  shape3: {
    backgroundColor: theme.palette.secondary.light,
    width: 5,
    height: 20,
  },
  shapeCircle: {
    borderRadius: '50%',
  },
}));





/* Curriculum map list results main page */
const TrainListResults = ({ getCompleteData, sendData, customers, ...rest }) => {
  const classes = useStyles();
  const [editData, setEditData] = useState()
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState()
  const [title, setTitle] = React.useState();
  const [instType, setInstType] = React.useState();
  const [board, setBoard] = React.useState();
  const [clrStd, setClrStd] = React.useState();
  const [sub, setSub] = React.useState();
  const [desc, setDesc] = React.useState();
  const [tags, setTags] = React.useState()
  const [openSnack, setOpenSnack] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [unitCount, setUnitCount] = React.useState(2);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedTime, setSelectedTime] = React.useState(new Date())
  const [selectedDate1, setSelectedDate1] = React.useState(new Date());
  const [selectedTime1, setSelectedTime1] = React.useState(new Date())
  const [openDelSnack, setOpenDelSnack] = React.useState(false)

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (date) => {
    setSelectedTime(date);
  };


  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const handleTimeChange1 = (date) => {
    setSelectedTime1(date);
  };




  const handleDescChange = (event) => {
    const { name, value } = event.target
    setDesc(value)

  }

  const handleTagsChange = (event) => {
    const { name, value } = event.target;
    setTags(value);
  }

  const handleInstTypeChange = (event) => {
    const { name, value } = event.target;

    console.log("institute value at edit form", value)
    setInstType(value);
    dispatch(listStdGet())
    dispatch(listBoardGet(value))
  }

  const handleBoardChange = (event) => {
    const { name, value } = event.target;
    console.log("board value at editform ", value)
    setBoard(value);
  }

  const handleClrStdChange = (event) => {
    const { name, value } = event.target;
    setClrStd(value);
  }

  const handleSubChange = (event) => {
    const { name, value } = event.target;
    setSub(value);
  }

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setTitle(value);


  }


  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };



  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClickIndex = (event, name, row, CurrData) => {

    console.log("selecting.....", row)
    sendData(row, CurrData)

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const dispatch = useDispatch()

  const curriculumDetailsGet = useSelector(state => state.curriculumMap)
  const { loadingCur, CurriculumMap, errorCur } = curriculumDetailsGet

  const curriculumFilDetailsGet = useSelector(state => state.curriculumMapFil)
  const { loadingCurFil, CurriculumMapFilter, errorCurFil } = curriculumFilDetailsGet



  const filteredMaps = useSelector(state => state.filteredMap.filteredMap)
  const instDetails = useSelector(state => state.instDetails.instDetails)
  const boardDetails = useSelector(state => state.boardDetails.boardDetails)
  const subDetails = useSelector(state => state.subDetails.subDetails)
  const stdDetails = useSelector(state => state.stdDetails.stdDetails)
  const maps = useSelector(state => state.curriculumMapFil.CurriculumMapFilter);

  useEffect(() => {
    const userId = Cookie.get('kgtopg.partner.user.id')

    dispatch(listInstGet())
    dispatch(listAllEventsGet())

    return () => {
      //
    }
  }, [])

  const openEditForm = (row) => {
    setOpen(true)
    setEditData(row)
  }


  const onDeleteMap = async (id) => {
    // const userId = Cookie.get('kgtopg.partner.user.id')
    // await MapAPI.deleteMap(id)

    // // reload
    // dispatch(listCurriculumGet(userId))

    console.log("deleting id  at delete function", id)
    dispatch(listSingleEventDel(id))
    dispatch(listAllEventsGet())
    setOpenDelSnack(true)
  }

  const handleCloseDelSnack = () => {
    setOpenDelSnack(false)
  }

  const allEventsList = useSelector(state => state.allEventsGet)
  const { loadingAllEvents, allEventsGet, errorAllEvents } = allEventsList



  {
    loadingAllEvents && allEventsList ?
      console.log("data is loading")
      :
      console.log("data at trainlist results", allEventsGet)
  }




  let mapList = (CurriculumMap && CurriculumMap.data) ? CurriculumMap.data : [];
  console.log(`filtered maps original  list ${JSON.stringify(mapList)}`)
  if (filteredMaps && filteredMaps.length) {
    mapList = filteredMaps
  }

  console.log("edit data at trainlist results", editData)

  const allEventsFil = useSelector(state => state.allEventsFilGet)
  const { loadingAllFilEvents, allEventsFilGetData, errorAllFilEvents } = allEventsFil

  const allEventsSrc = useSelector(state => state.allEventsSrcGet)
  const { loadingAllSrcEvents, allEventsSrcGetData, errorAllSrcEvents } = allEventsSrc

  {
    loadingAllFilEvents && allEventsFil ?
      console.log("data is loading")
      :
      console.log("loading all events filter data at TrainListResults", allEventsFilGetData)
  }

  {
    loadingAllSrcEvents && allEventsSrc ?
      console.log("data is loading")
      :
      console.log("loading all events filter data at TrainListResults ", allEventsSrcGetData)
  }


  return (
    <Card {...rest}>
      <PerfectScrollbar
        style={{
          height: '100%'
        }}>
        <Box sx={{ minWidth: 1050, minHeight: '100%', height: '100%' }}
          style={{
            minHeight: '100%',
            height: '100%'
          }}>



          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '100%'
            }}>


            <div>
              <EnhancedTableToolbar numSelected={selected.length} />

              {loadingAllEvents && allEventsList ?
                <CircularIndeterminate />
                :
                (allEventsGet && allEventsGet.data && allEventsGet.data.length > 0) ?
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {
                          (allEventsGet && allEventsGet.data && allEventsGet.data.length > 0) ?
                            stableSort(allEventsGet.data, getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                const isItemSelected = isSelected(row.event_name);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                // const isSel = (currMap && currMap.id == row.map_id)

                                return (
                                  <TableRow
                                    // selected={isSel}
                                    hover
                                    onClick={(event) => {
                                      console.log(`setting current map.. ${JSON.stringify(row)}`)

                                      // set current map
                                      dispatch(updateCurrMap(row))
                                      handleClickIndex(event, row.event_name, row, allEventsGet.data[0])

                                    }}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.event_name}

                                  >
                                    <TableCell padding="checkbox">

                                    </TableCell>
                                    <TableCell component="th" id={labelId} scope="row" padding="none">
                                      <Typography
                                        variant='body1'
                                        style={{
                                          cursor: 'pointer',
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center"

                                        }}
                                      >
                                        <img src={row.event_image} style={{ width: 40, height: 40, marginRight: 10 }} />
                                        {row.event_name}
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="center">{row.classroom_std}</TableCell>
                                    <TableCell align="center">{row.start_time}</TableCell>
                                    {/* <TableCell align="center">
                                      <UploadDialog eventDetails={row} />
                                    </TableCell> */}
                                    <TableCell align="center">
                                      {row.is_live == 1 ? "Live Video" : "Recorded Video"}

                                    </TableCell>
                                    {/* <TableCell align="center">{row.end_time}</TableCell> */}

                                    <TableCell
                                      style={{ textAlign: 'center' }}>
                                      {/* <EditOutlinedIcon style={{ color: "#1a4beb" }}
                                        onClick={() => { openEditForm(row) }}
                                        style={{
                                          cursor: 'pointer',
                                          color: "#1a4beb"
                                        }} /> */}

                                      <EventEditDialog eventDetails={row} />
                                    </TableCell>
                                    <TableCell
                                      style={{ textAlign: 'center' }}>
                                      <DeleteDialog onDeleteMap={(id) => onDeleteMap(id)} id={row.id} />
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            :
                            <></>
                        }


                        {(emptyRows > 0) && (
                          <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}

                      </TableBody>
                    </Table>
                  </TableContainer>


                  :
                  <Alert severity="warning">
                    <AlertTitle>No Created Events</AlertTitle>
                    You have not yet created any Events for Training — <strong>Start by Clicking above create event button!</strong>
                  </Alert>

              }


              <Snackbar open={openDelSnack} autoHideDuration={6000} onClose={handleCloseDelSnack}>
                <Alert2 onClose={handleCloseDelSnack} severity="error">
                  successfully deleted the event
                </Alert2>
              </Snackbar>
            </div>

            <div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>

          </div>


        </Box>
      </PerfectScrollbar>







      {/* EDIT TRAINER EVENT */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontWeight: "500" }}>{editData ? editData.event_name : ""} [Edit]</DialogTitle>
        <DialogContent>

          <DialogContentText style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
            Edit Event lets you setup a old event with new details
          </DialogContentText>

          {/* title Field */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Event Name"
            type="text"
            fullWidth
            defaultValue={editData ? editData.event_name : ""}
            value={title}
            onChange={handleTitleChange}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />


          {/* descirption of event */}
          <TextField
            autoFocus
            defaultValue={editData ? editData.event_des : ""}
            onChange={handleDescChange}
            margin="dense"
            id="tags"
            label="Event Description"
            fullWidth
            value={desc}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />

          {/* insititute type dropdown */}
          <div style={{ marginTop: 10 }}>
            <FormControl variant="outlined" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >
                Institute Type
              </InputLabel>
              <Select
                defaultValue={editData ? editData.institute_type_id : ""}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                // value={instType}
                onChange={handleInstTypeChange}
                label="Institute Type"
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >

                {(instDetails && instDetails.data) ?
                  instDetails.data.map((type, index) => {
                    console.log(`single inst type ${JSON.stringify(type)}`)
                    return (
                      <MenuItem key={index} value={type.institute_type_id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{type.institute_type_name}</MenuItem>
                    )
                  })
                  :
                  <MenuItem key={-2} value={-2} >No Types</MenuItem>
                }
              </Select>
            </FormControl>
          </div>

          {/* institute board dropdown */}
          <FormControl variant="outlined" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >
              Institute Board
            </InputLabel>
            <Select
              defaultValue={editData ? editData.institute_board_id : ""}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={board}
              onChange={handleBoardChange}
              label="Institute Board"
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >
              {boardDetails && boardDetails.data ?
                boardDetails.data.map((brd, index) => {
                  return (
                    <MenuItem key={index} value={brd.institute_board_id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{brd.institute_board_name}</MenuItem>
                  )
                })
                :
                <MenuItem key={-2} value={-2} >No Boards</MenuItem>
              }

            </Select>
          </FormControl>



          {/* institute standards */}
          <FormControl variant="outlined" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >
              Standard
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={editData ? editData.classroom_std : ""}
              value={clrStd}
              onChange={handleClrStdChange}
              label="Standard"
              style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
            >

              {stdDetails && stdDetails.data && stdDetails.data ?
                stdDetails.data.map((sb, index) => {
                  return (
                    <MenuItem key={index} value={sb.id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{sb.standard}</MenuItem>
                  )
                })
                :
                <MenuItem key={-2} value={-2} >No Standards</MenuItem>
              }

            </Select>
          </FormControl>



          {/* subject dropdown */}
          <div style={{ marginTop: 10 }}>
            <FormControl variant="outlined" style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >
                Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                defaultValue={editData ? editData.subject_id : ""}
                value={sub}
                onChange={handleSubChange}
                label="Subject"
                style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
              >
                {subDetails && subDetails.data && subDetails.data.subjects ?
                  subDetails.data.subjects.map((sb, index) => {
                    return (
                      <MenuItem key={index} value={sb.subject_id} style={{ fontSize: 16, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{sb.subject_name}</MenuItem>

                    )
                  })
                  :
                  <MenuItem key={-2} value={-2} >No Subjects</MenuItem>
                }

              </Select>
            </FormControl>
          </div>



          <TextField
            autoFocus
            margin="dense"
            id="tags"
            label="Tags"
            type="text"
            fullWidth
            defaultValue={editData ? editData.event_tags : ""}
            value={tags}
            onChange={handleTagsChange}
            style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}
          />

          <div style={{ marginTop: 15 }}>
            <Typography>
              Start Time:
            </Typography>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">

                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date"
                  format="MM/dd/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Time"
                  value={selectedTime}
                  onChange={handleTimeChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>



          <div style={{ marginTop: 15 }}>

            <Typography>
              End Time:
            </Typography>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">

                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date"
                  format="MM/dd/yyyy"
                  value={selectedDate1}
                  onChange={handleDateChange1}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Time"
                  value={selectedTime1}
                  onChange={handleTimeChange1}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
            Cancel
          </Button>
          <Button color="primary" style={{ fontFamily: "Poppins, Helvetica, sans-serif" }}>
            Update
          </Button>
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
            <Alert2 onClose={handleCloseSnack} severity="warning">
              Please enter mandatory details!
            </Alert2>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </Card>

  );
};

TrainListResults.propTypes = {
  customers: PropTypes.array.isRequired
};

export default TrainListResults;




