import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { listSubscribersPost } from '../../../redux/actions/Trainer/EventsPost';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { listSubscribersGet } from '../../../redux/actions/Trainer/EventsGet';
import { CircularProgress } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import TextField from '@material-ui/core/TextField';
import { listSubscribersPut } from '../../../redux/actions/Trainer/EventsPut';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { listSubscribersDel } from '../../../redux/actions/Trainer/EventsDelete';
import { AlertTitle } from '@material-ui/lab';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles3 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles3();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
    return { name, calories, fat };
}


const rows1 = [
    createData('Cupcake', 305, 3.7),
    createData('Donut', 452, 25.0),
    createData('Eclair', 262, 16.0),
    createData('Frozen yoghurt', 159, 6.0),
    createData('Gingerbread', 356, 16.0),
    createData('Honeycomb', 408, 3.2),
    createData('Ice cream sandwich', 237, 9.0),
    createData('Jelly Bean', 375, 0.0),
    createData('KitKat', 518, 26.0),
    createData('Lollipop', 392, 0.2),
    createData('Marshmallow', 318, 0),
    createData('Nougat', 360, 19.0),
    createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const useStyles4 = makeStyles({
    table: {
        minWidth: 500,
    },
});


function EditDialog(props) {
    const { subscr } = props
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [email, setEmail] = React.useState(subscr.subscribers_email)
    const [eventId, setEventId] = React.useState("")
    const [subscrId, setSubScrId] = React.useState(subscr.id)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackClick = () => {
        setOpenSnack(true);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const EmailUpdate = () => {
        const body = {
            event_id: eventId,
            subscribers_email: email
        }
        dispatch(listSubscribersPut(body, subscrId))
        handleSnackClick()
        handleClose()
    }


    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const event_id = query.get("eve_id");
        setEventId(event_id)


        return (() => {
            //
        })

    }, [])

    return (
        <div>

            <IconButton color="primary" aria-label="add to shopping cart" onClick={handleClickOpen}>
                <EditOutlinedIcon />
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Edit Attendee Email"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Enter Attendee's new email to update.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        defaultValue={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => EmailUpdate()} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success">
                    Attendee's  Email Updated Successfully!
                </Alert>
            </Snackbar>
        </div>
    );
}



function DeleteDialog(props) {
    const { subscr } = props
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [subscrId, setSubScrId] = React.useState(subscr.id)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackClick = () => {
        setOpenSnack(true);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const DeleteAttendee = () => {
        dispatch(listSubscribersDel(subscrId))
        handleSnackClick()
        handleClose()
    }
    return (
        <div>

            <IconButton color="secondary" aria-label="add to shopping cart" onClick={handleClickOpen}>
                <ClearOutlinedIcon />
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Remove Attendee?"}</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center"
                        }}
                    >
                        <CancelOutlinedIcon color="secondary" style={{ fontSize: 60 }} />
                    </div>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are You Sure, Do You want to remove {subscr.subscribers_email} from this Event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => { DeleteAttendee() }} color="primary">
                        Yes,Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="warning">
                    Attendee removed Successfully!
                </Alert>
            </Snackbar>
        </div>
    );
}



function AttendeesListTable(props) {
    const { rows } = props
    const classes = useStyles4();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    console.log("subscribers list at attendees list table", rows)
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => (
                        <TableRow key={row.subscribers_email}>
                            <TableCell component="th" scope="row">
                                {row.subscribers_email}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                invitation sent on   <Chip size="small" color="primary" label={row.created_date} />
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                <EditDialog subscr={row} />
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                <DeleteDialog subscr={row} />
                            </TableCell>

                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '95%',
        marginLeft: 20,
        backgroundColor: theme.palette.background.paper,
    },
}));


const useStyles1 = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const useStyles2 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function EventVideoTabs(props) {
    const { videoData } = props
    const classes = useStyles();
    const classes1 = useStyles1();
    const classes2 = useStyles2();
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0);
    const [email, setEmail] = React.useState("")
    const [eventId, setEventId] = React.useState("")
    const [open, setOpen] = React.useState(false);

    const handleSnackOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }



    const PostInvite = () => {

        const body = {
            event_id: eventId,
            subscribers_email: email
        }
        dispatch(listSubscribersPost(body))
        handleSnackOpen()
    }


    const [checked, setChecked] = React.useState([0]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const event_id = query.get("eve_id");
        setEventId(event_id)

        dispatch(listSubscribersGet(event_id))
        return (() => {
            //
        })

    }, [])


    const subscrGetList = useSelector(state => state.subscribersGet)
    const { loadingSubscrGet, SubscrGet, errorSubscrGet } = subscrGetList

    {
        loadingSubscrGet && subscrGetList
            ?
            console.log("data is loading...")
            :
            console.log("subscribers data at event videos", SubscrGet)
    }



    return (
        <div className={classes.root}>
            <AppBar position="static" color="#fff">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Overview" {...a11yProps(0)} style={{ textTransform: "none" }} />
                    <Tab label="Q&A" {...a11yProps(1)} style={{ textTransform: "none" }} />
                    <Tab label="Attendees" {...a11yProps(2)} style={{ textTransform: "none" }} />
                    <Tab label="Invintees" {...a11yProps(3)} style={{ textTransform: "none" }} />

                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                >
                    <strong >Description</strong>
                    <Typography>
                        {videoData.video_f_desc}
                    </Typography>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
                Attendees
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex" }}>
                        <Typography style={{ fontSize: 20 }}>
                            Invitees List
                        </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Paper component="form" className={classes1.root}>
                            <IconButton className={classes1.iconButton} aria-label="menu">
                                <MailOutlineOutlinedIcon />
                            </IconButton>
                            <InputBase
                                className={classes1.input}
                                placeholder="Send Invitation to Attendees"
                                inputProps={{ 'aria-label': 'search google maps' }}
                                onChange={(e) => { handleEmailChange(e) }}
                            />
                            {/* <IconButton type="submit" className={classes1.iconButton} aria-label="search">
                                <SearchIcon />
                            </IconButton> */}
                            <Divider className={classes1.divider} orientation="vertical" />
                            <IconButton color="primary" className={classes1.iconButton} aria-label="directions" onClick={() => PostInvite()}>
                                <SendOutlinedIcon />
                            </IconButton>
                        </Paper>


                    </div>

                </div>

                {loadingSubscrGet && subscrGetList
                    ?
                    <CircularProgress color="primary" />
                    :
                    subscrGetList && SubscrGet && SubscrGet.data && SubscrGet.data.length > 0 ?


                        <div style={{ marginTop: 10 }}>
                            <AttendeesListTable rows={SubscrGet.data} />
                        </div>
                        :
                        <Alert severity="warning" style={{ width: "95%", marginTop: 30, marginLeft: 20 }}>
                            <AlertTitle>No Attendees</AlertTitle>
                            Add  Attendees— <strong>by sending mail to them </strong>
                        </Alert>
                }
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Invitation sent Successfully !
                    </Alert>
                </Snackbar>
            </TabPanel>

        </div>
    );
}