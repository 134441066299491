import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import { epubmainListItems, epubsecondaryListItems } from '../../ebook/SidebarList';
// import TableModel from "../TableModel"
import { Route, Link, BrowserRouter as Router, Switch, Redirect, HashRouter, useHistory } from 'react-router-dom'
// import ContentMaking from '../ContentMaking';
// import Footer from "./Footer"
import { Avatar, Button, MenuItem } from '@material-ui/core';
// import { LinearProgress } from '@material-ui/core';
// import ContentMaking1 from "/ContentMaking1"
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
// import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
// import Settings from '../Settings';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import EpubView from '../../ebook/EbookReaderScreen';
// import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
// import SidebarContent from '../../ebook/SidebarScreen';
// import contentTable from "../../ebook/MyBooksScreen"
// import Contentlist from '../../ebook/MyBooksScreen';
// import ebookContentMaking from "../../ebook/ebookAddingContentScreen"
// import LessonPlan from "../../ebook/LessonPlanScreen";
// import Quiz from "../../ebook/QuizScreen"
// import Ebook from "../../ebook/UploadEbookScreen"
import { useState } from 'react';
import logo from "../../../assets/logo.png"
// import publisherLogo from "../../../assets/PSON.jpeg"
import McgrawHill from "../../../assets/Mcgrawhill.jpg"
import "../../../css/sidebar.css"
// import MapList from '../../curriculummap/MapList';
// import UnitList from '../../curriculummap/UnitList';
// import { ContactlessOutlined, SettingsInputAntennaTwoTone } from '@material-ui/icons';
// import SidebarContent1 from "../../ebook/ContentSidebar";
// import ContentTable from '../ContentTable';
// import UploadEbookScreen from '../../ebook/UploadContent';

import PublisherListSideBar from '../../ebook/PublisherListSideBar';
import SidebarContentCont from '../../contentmaker/SidebarScreenCont';
import SidebarContent from '../SidebarScreen';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { SettingsApplicationsOutlined } from '@material-ui/icons';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import pustakLogo from "../../../assets/PustakLogo.png"
import publishLogo from '../../../assets/PustakLogo.png'
import Cookie from 'js-cookie';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import UserSession from '../../../resource/UserSession';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { useDispatch, useSelector } from "react-redux";

import { listChpGet } from "../../../redux/actions/chapterAction";
import { listTopGet } from "../../../redux/actions/topicAction";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }}>
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                KgtoPg
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14

    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0
    },
}));








export default function LeftCard(props) {
    
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [showButton, setShowButton] = useState(false)
    const [AccordClose, setAccordClose] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [showLogo, setShowLogo] = useState(true)
    const [state, setState] = React.useState({
        right: false,
    })

    const userPhoto = (Cookie.get("kgtopg.partner.user.photo")) ? Cookie.get("kgtopg.partner.user.photo") : UserSession.userPhoto;
    const userName = (Cookie.get("kgtopg.partner.user.name")) ? Cookie.get("kgtopg.partner.user.name") : UserSession.userName;

    const [showSideCont, SetShowSideCont] = useState(false)
    const [path, setPath] = useState('')
    const [readerData, setReaderData] = useState("")
    const [isLoadingscr, setIsLoadingscr] = useState(false)

    const teachPrev = (props.teachPrev != null) ? props.teachPrev : false
    const bookId = (props.bookId != null) ? props.bookId : 0
    const bookDetails = props.bookDetails;

    const chpDetailsGet = useSelector((state) => state.chpDetails);
    const { loadingChap, chapDetails, errorChp } = chpDetailsGet;
  
    const topDetailsGet = useSelector((state) => state.topDetails);
    const { loadingTop, TopDetails, errorTop } = topDetailsGet;
  
    const handleDrawerOpen = () => {
        setOpen(true);
        setAccordClose(true)
        setShowLogo(true)
        SetShowSideCont(false)
    };


    const dispatch = useDispatch();

    const handleDrawerClose = () => {
        setOpen(false);
        setAccordClose(false)
        setShowLogo(false)
    }



    const hideStatusBar = () => {
        if (props && props.status == true) {
            handleDrawerClose()
            SetShowSideCont(true)
        }

    }





    const hideFab = (list, index) => {
        console.log("hiding the button", list, index)
        setShowButton(true)
        setIsLoadingscr(false)
        console.log("show button at parent", showButton)


    }



    const showSideContent = (status, path) => {
        console.log("sidecontent status", status)
        SetShowSideCont(status)
        if (status == true) {
            SetShowSideCont(status)
            setOpen(false)
            setShowLogo(false)
        } else {
            SetShowSideCont(status)
            setOpen(true)
            setShowLogo(true)
        }
        setPath(window.location.pathname)

        console.log(" callback function status", status, window.location.pathname)
    }

    console.log("windows path in dashboard", window.location.pathname)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    }


    const sideBarStatus = () => {
        const path = window.location.href
        const query = new URLSearchParams(window.location.search)
        const book_id = query.get('book_id')
        const teach_book_id = query.get('book-id')

        console.log("windows path at  Left Card", path, book_id)

        if (book_id) {
            SetShowSideCont(true)
            setOpen(false)
        }

        if (teach_book_id) {
            setOpen(false)
        }



        if (props.open != null) {
            setOpen(props.open)
            SetShowSideCont(props.open)
            handleDrawerClose()
        }
    }

    const HideSideBar = () => {
        const path = window.location.href
        const query = new URLSearchParams(window.location.search)
        const eve = query.get('eve_id')

        console.log("query at sidebar", eve)
        if (eve) {
            setOpen(false)
        }
    }

    useEffect(() => {
        hideStatusBar()
        sideBarStatus()
        HideSideBar()

    }, [])


    console.log("props at useEffect", props)


    const sendDataToReaderScreen = (list, status) => {
        setIsLoading(true)

        console.log("send Data at left card from publiser", list)

        console.log("sending..... Data to Reader Screen", list)
        if (list) {
            setReaderData(list)
            setIsLoading(false)
            setShowButton(status)
            props.FabStatus(status)
            props.FabMapData(list)
        }

    }



    const pathname = window.location.pathname
    let currPath = "dashboard"
    let settSel = false;
    let helpSel = false;
    let trashsel = false;

    if (pathname === "/publisher-ebook/settings")
        settSel = true;
    else if (pathname === '/publisher-ebook/settings/help')
        helpSel = true;
    else if (pathname === '/publisher-ebook/trash')
      trashsel = true


        return (

            <>

                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >



                    {open ?

                        <div className={classes.toolbarIcon}>
                            <Avatar alt="logo" src={userPhoto} className={classes.small} style={{ display: "flex", justifyContent: "center" }} />
                            <Typography
                                variant="caption">
                                {userName}
                            </Typography>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon style={{ color: "#3f51b5", marginRight: -20 }} />
                                <ChevronLeftIcon style={{ color: "#3f51b5" }} />
                            </IconButton>
                        </div>
                        :
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon style={{ color: "gray", display: "flex", justifyContent: "center", alignContent: "flex-end", marginLeft: 40, padiing: 10 }} />
                        </IconButton>
                    }


                    <Divider />

                    {/* <List> */}
                        <SidebarContent
                            buttonstatus={(list, index) => { hideFab(list, index) }}
                            hideAccordian={AccordClose}
                            showContent={(status) => { showSideContent(status, path) }}
                            sidebarOpen={open}
                        />
                    {/* </List> */}


                    {/* <List>{epubsecondaryListItems}</List> */}

                    <div>
                        <Link to="/publisher-ebook/trash" style={{ textDecoration: "none" }}>
                            <MenuItem selected={trashsel} button >
                                <ListItemIcon>
                                    <div className="Finished">
                                        <DeleteOutlineOutlinedIcon />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Trash Bin" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                        </Link>
                    </div>


                    <div>
                        <Link to="/publisher-ebook/settings/help" style={{ textDecoration: "none" }}>
                            <MenuItem selected={helpSel} button >
                                <ListItemIcon>
                                    <div className="Finished">
                                        <HelpOutlineIcon />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Help &amp; Support" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                        </Link>
                    </div>

                    <div>
                        <Link to="/publisher-ebook/settings" style={{ textDecoration: "none" }}>
                            <MenuItem selected={settSel} button onClick={() => { (props && props.showContent) ? props.showContent(false, window.location.pathname) : console.log(`no props`) }}>
                                <ListItemIcon>
                                    <div className="Finished">
                                        <SettingsOutlinedIcon />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Settings" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                        </Link>
                    </div>

                    <div style={{ position: "relative", bottom: 0, display: "flex", justifyContent: "center" }}>

                        {showLogo && open ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", width: "60%", alignSelf: "center", alignContent: "flex-end", bottom: "0px" }}>
                                <img alt="logo" src={publishLogo} style={{ display: "flex", justifyContent: "center", height: 50 }} />
                                {/* <span style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 14, fontWeight: "500" }}>Publisher</span> */}

                            </div>
                            :
                            <></>
                        }


                    </div>


                </Drawer>

                {/* {showSideCont ?
                        <div style={{ width: 300, height: "100vh", backgroundColor: "#fff" }}>
                            <List><SidebarContent1 buttonstatus={(list, index) => { hideFab(list, index) }} hideAccordian={AccordClose} sendDatatoReaderScreen={(list, status) => { sendDataToReaderScreen(list, status) }} /></List>
                        </div>
                        :
                        <></>

                    } */}


                {showSideCont ?
                    <div style={{ width: '35%', height: "100vh", backgroundColor: "#fff", borderRight: "1px solid #ebebeb", padding: 2 }}>
                        <List>
                            <PublisherListSideBar 
                            bookId={bookId} 
                            bookDetails={bookDetails}
                            teachPrev={teachPrev} buttonstatus={(list, index) => { hideFab(list, index) }} hideAccordian={AccordClose} sendDatatoReaderScreen={(list, status) => { sendDataToReaderScreen(list, status) }} />
                        </List>
                    </div>
                    :
                    console.log("showSideCont", showSideCont)
                }

            </>
        )
}