import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import "../../css/sidebar.css"
import { Link } from 'react-router-dom'
import { ExitToApp, ExitToAppOutlined, LaptopWindows, SettingsOutlined } from '@material-ui/icons';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Divider, Input, MenuItem } from '@material-ui/core';
import AddBoxSharpIcon from '@material-ui/icons/AddBoxSharp';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import image from "../../assets/bus.png"
import FilterResults from 'react-filter-search';
import SearchIcon from '@material-ui/icons/Search';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import CropLandscapeOutlinedIcon from '@material-ui/icons/CropLandscapeOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Cookie from 'js-cookie';
import MoneyIcon from '@material-ui/icons/Money';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SchoolIcon from '@material-ui/icons/School';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import TheatersIcon from '@mui/icons-material/Theaters';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const useStyles2 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


const DisplayCard = (props) => {




    return (
        <Card style={{ width: 270, height: 150, margin: 10, justifyContent: "center", }}>
            <CardMedia
                component="img"
                alt="bus png"
                height="100"
                image={image}
                title="School bus"
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 14,
                    }}
                >
                    {props.list.title}
                </Typography>
            </CardContent>
        </Card>
    )
}


function SimpleAccordion(props) {
    const classes = useStyles();

    let path = window.location.pathname
    let acaSel = false;
    let otSel = false;
    let currPath = '';
    const insType = localStorage.getItem("pustak.kgtopg.inst_type");
    const brdType = localStorage.getItem("pustak.kgtopg.board_type");
    const stdType = localStorage.getItem("pustak.kgtopg.standard_type");
    const prm = localStorage.getItem('publish.pustak.media_type')

    switch (path) {
        case "/publisher-content/my-media/media-components":
        case "/publisher-content/price-settings":
            
            currPath = "media";
            acaSel = true;

            break;
        case "/publisher-content/my-media/instructor-led-content":
        case "/publisher-content/price-settings/ilc":
            currPath = "ilc";
            otSel = true;
            break;
    }

    if(path.includes('/publisher-content/content/')) {

        if(prm == 'ilc') {
            currPath = "ilc";
            otSel = true;
        } else if(prm == 'media') {
                
            currPath = "media";
            acaSel = true;
    
        }
    
    }

    // now adding logic to set current media type
    // set cookie
    Cookie.set("publish.pustak.media_type", currPath)

    // set localstorage
    localStorage.setItem('publish.pustak.media_type', currPath);

    return (
        <div className={classes.root}>
            <Accordion

                defaultExpanded={(acaSel || otSel)}
                style={{
                    border: 0,
                    padding: 0
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        margin: 0,
                        border: 0,
                        paddingBottom: 0
                    }}
                >
                    {/* <Typography className={classes.heading}>My Books</Typography> */}


                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',

                        }}>
                        <ListItemIcon>
                            <div className="Finished">
                                <TheatersIcon />
                            </div>
                        </ListItemIcon>
                        <div className="Finished-text">
                            <ListItemText primary="My Media" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                        </div>
                    </div>

                </AccordionSummary>
                <AccordionDetails
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: 0,
                        padding: 0
                    }}>
                    {/* <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget.
                    </Typography> */}
                    <Link to="/publisher-content/my-media/media-components" style={{ textDecoration: "none" }}>
                        <MenuItem
                            style={{
                                paddingTop: 0,
                                paddingBottom: 5
                            }}
                            selected={acaSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <PermMediaIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Components" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: 'gray',
                                        padding: 0,
                                        margin: 0
                                    }}>
                                    Open Content
                                </Typography>

                            </div>
                        </MenuItem>
                    </Link>

                    <Link to="/publisher-content/my-media/instructor-led-content" style={{ textDecoration: "none" }} >
                        <MenuItem
                            style={{
                                paddingTop: 0,
                                paddingBottom: 5
                            }}
                            selected={otSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <PermMediaIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="ILC" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: 'gray',
                                        padding: 0,
                                        margin: 0
                                    }}>
                                    Instructor Led Content
                                </Typography>
                            </div>
                        </MenuItem>
                    </Link>
                </AccordionDetails>
            </Accordion>
            {/* 
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Accordion 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className={classes.heading}>Disabled Accordion</Typography>
                </AccordionSummary>
            </Accordion>
         */}
        </div>
    );
}


function SidebarContentCont(props) {



    const [data, setData] = useState([]);
    const [value, setValue] = useState("");
    const [search, setSearch] = useState("")
    const [showContent, setShownContent] = useState(false)



    const handleChange = event => {
        const { value } = event.target;
        this.setState({ value });
    };


    const onTextChange = e => {

        setSearch(e.target.value)
    };
    console.log("props", props)

    useEffect(() => {
        return () => {
            console.log("windows path", window.location.href, window.location.pathname)
            // 
        }
    }, [])
    console.log("windows path in dashboard", window.location.pathname)


    const path = window.location.pathname
    let currPath = "dashboard"
    let dashSel = false;
    let contSel = false;
    let pubsSel = false;
    let userSel = false;
    let settSel = false;
    let prcSel = false;
    let invSel = false;
    let paySel = false;
    let learnSel = false;

    switch (path) {
        case "/publisher-content/dashboard":
            currPath = "dashboard";
            dashSel = true;
            break;
        case "/publisher-content/content":
            currPath = "cont";
            contSel = true;
            break;
        case "/publisher-content/users":
            currPath = "users";
            userSel = true;
            break;
        case "/publisher-content/publishers":
            currPath = "publishers";
            pubsSel = true;
            break;
        case "/publisher-content/settings":
            currPath = "settings";
            settSel = true;
            break;
        case "/publisher-content/price-settings":
            currPath = "settings";
            prcSel = true;
            break;
        case "/publisher-content/invites":
            currPath = "invites";
            invSel = true;
            break;

        case "/publisher-content/learn-subscribers":
            currPath = "learn-subscribers";
            learnSel = true;
            break;

        case "/publisher-content/accounts":
            currPath = "accounts";
            paySel = true;
            break;

    }

    if (path.includes("/publisher-content/payment-history")) {
        currPath = "accounts";
        paySel = true;
    }

    if (path.includes('publisher-content/subjects')
        || path.includes('publisher-content/content')) {
        currPath = "cont";
        contSel = true;
    }





    // const CONTENTUPLOAD =   Cookie.get('kgtopg.partner.user.CONTENTUPLOAD')
    // const QUALITYASSURANCE =  Cookie.get('kgtopg.partner.user.QUALITYASSURANCE')
    // const SUPERADMIN = Cookie.get('kgtopg.partner.user.SUPERADMIN')
    // const CONTENTMAP = Cookie.get('kgtopg.partner.user.CONTENTMAP')
    // const CURRICULUMMAP =  Cookie.get('kgtopg.partner.user.CURRICULUMMAP')
    // const EBOOKUPLOAD =Cookie.get('kgtopg.partner.user.EBOOKUPLOAD')
    // const TRAINER = Cookie.get('kgtopg.partner.user.TRAINER')



    const roles = Cookie.get('kgtopg.partner.user.Roles')
    const CONTENTUPLOAD = (roles && roles.includes("CONTENTUPLOAD"))
    const QUALITYASSURANCE = (roles && roles.includes("QUALITYASSURANCE"))
    const SUPERADMIN = (roles && roles.includes("SUPERADMIN"))
    const CONTENTMAP = (roles && roles.includes("CONTENTMAP"))
    const CURRICULUMMAP = (roles && roles.includes("CURRICULUMMAP"))
    const EBOOKUPLOAD = (roles && roles.includes("EBOOKUPLOAD"))
    const TRAINER = (roles && roles.includes("TRAINER"))

    console.log("role status",
        CONTENTUPLOAD,
        QUALITYASSURANCE,
        SUPERADMIN,
        CONTENTMAP,
        CURRICULUMMAP,
        EBOOKUPLOAD,
        TRAINER
    )

    return (
        <div style={{ height: "72vh" }} >

            {/* { window.location.pathname != "/publisher/mapping" ? */}
            <div>

                {SUPERADMIN ?
                    <Link to="/publisher-content/dashboard" style={{ textDecoration: "none" }}>
                        <MenuItem selected={dashSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <DashboardOutlinedIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Dashboard" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                    :
                    <></>
                }

                {
                    (SUPERADMIN || CONTENTUPLOAD || QUALITYASSURANCE) ?
                        <SimpleAccordion contSel={contSel} showContent={(bool, path) => props.showContent(bool, path)} />

                        // <Link to="/publisher-content/subjects" style={{ textDecoration: "none" }}>
                        //     <MenuItem selected={contSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                        //         <ListItemIcon>
                        //             <div className="Finished">
                        //                 <ListAltIcon />
                        //             </div>
                        //         </ListItemIcon>
                        //         <div className="Finished-text">
                        //             <ListItemText primary="My Media" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                        //         </div>
                        //     </MenuItem>
                        // </Link>
                        :
                        <></>
                }
                {/* 
                {
                    SUPERADMIN ?

                        <Link to="/publisher-content/users" style={{ textDecoration: "none" }}>
                            <MenuItem selected={userSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                <ListItemIcon>
                                    <div className="Finished">
                                        <GroupOutlinedIcon />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Subscriptions" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>

                        </Link>
                        :
                        <></>
                }

                {SUPERADMIN ?

                    <Link to="/publisher-content/publishers" style={{ textDecoration: "none" }}>
                        <MenuItem selected={pubsSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <GroupOutlinedIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Co-Partners" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>

                    </Link>
                    :
                    <></>

                } */}

                {/* {
                    SUPERADMIN ?
                        <Link to="/publisher-content/invites" style={{ textDecoration: "none" }}>
                            <MenuItem selected={invSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                <ListItemIcon>
                                    <div className="Finished">
                                        <GroupAddIcon />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="My Publishers" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                        </Link>
                        :
                        <></>
                }

                {
                    SUPERADMIN ?
                        <Link to="/publisher-content/learn-subscribers" style={{ textDecoration: "none" }}>
                            <MenuItem selected={learnSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                                <ListItemIcon>
                                    <div className="Finished">
                                        <GroupAddIcon />
                                    </div>
                                </ListItemIcon>
                                <div className="Finished-text">
                                    <ListItemText primary="Learn Subscribers" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                                </div>
                            </MenuItem>
                        </Link>
                        :
                        <></>
                } */}

                {SUPERADMIN &&
                    <Link to="/publisher-content/accounts" style={{ textDecoration: "none" }}>
                        <MenuItem selected={paySel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <MoneyIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="Revenue" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>
                    </Link>
                }

                {/* 
                {SUPERADMIN ?

                <Link to="/publisher-content/price-settings" style={{ textDecoration: "none" }}>
                    <MenuItem selected={prcSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                        <ListItemIcon>
                            <div className="Finished">
                                <GroupOutlinedIcon />
                            </div>
                        </ListItemIcon>
                        <div className="Finished-text">
                            <ListItemText primary="Price Setup" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                        </div>
                    </MenuItem>

                </Link>
                :
                <></>

                } */}
                {/* 
                {SUPERADMIN ?

                    <Link to="/user/switch-account" style={{ textDecoration: "none" }}>
                        <MenuItem selected={pubsSel} button onClick={() => { props.showContent(false, window.location.pathname) }}>
                            <ListItemIcon>
                                <div className="Finished">
                                    <GroupOutlinedIcon />
                                </div>
                            </ListItemIcon>
                            <div className="Finished-text">
                                <ListItemText primary="switch account" style={{ fontFamily: "Poppins, Helvetica, sans-serif", fontSize: 12 }} />
                            </div>
                        </MenuItem>

                    </Link>
                    :
                    <></>

                } */}


            </div>













            {/* {window.location.pathname == "/publisher/mapping" ? */}


            {/* 
             <div>



                {props.hideAccordian ?

                    <ListSubheader
                        style={{
                            fontFamily: "Poppins, Helvetica, sans-serif",
                            fontSize: 12,
                            color: "#000",
                            display: "flex",
                            justifyContent: "center"
                        }}>
                        CONTENT
                </ListSubheader>
                    :
                    <Typography
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                            margin: 10
                        }}>
                        C
                </Typography>
                }

                <Divider />


                {props.hideAccordian ?


                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="dashboard" style={{ display: "flex", justifyContent: "center" }}>
                                <PersonOutlineOutlinedIcon />
                                <Typography style={{
                                    fontSize: 15, margin: 3
                                }}>Content 1</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Autocomplete
                                id="Search Content"
                                options={top100Films}
                                getOptionLabel={(option) => option.title}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Search Content" variant="outlined" />}
                            />
                            <div
                                style={{
                                    height: 50,
                                    width: 270,
                                    backgroundColor: "#fff",
                                    border: "1px solid #e0e0e0",
                                    borderRadius: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                <SearchIcon />
                                <Input
                                    label="search content"
                                    icon="search"
                                    onChange={(e) => onTextChange(e)}
                                    style={{ outlineColor: "#fff" }}
                                    placeholder="Search Content"
                                />
                            </div>
                        </AccordionDetails>



                        <div style={{ height: 300, overflowY: "scroll", }}>
                            {top100Films ?
                        top100Films.map((list, index) => {
                            return (
                                <DisplayCard list={list} />
                            )
                        })
                        :
                        <div></div>
                    }

                            {filteredData ?
                                filteredData.length != 0 ?
                                    filteredData.map((list, index) => {
                                        return (
                                            <div onClick={() => { props.buttonstatus(list, index) }} >
                                                <DisplayCard list={list} key={index} />
                                            </div>
                                        )
                                    })
                                    :

                                    <Card style={{ justifyContent: "center", display: "flex", width: "90%", margin: 10 }}>
                                        <Typography style={{ fontSize: 14, fontFamily: "Poppins, Helvetica, sans-serif" }} color="textSecondary" gutterBottom>
                                            No Options
                                            </Typography>
                                    </Card>


                                :
                                <Card style={{ justifyContent: "center", display: "flex" }}>
                                    <Typography style={{ fontSize: 14, fontFamily: "Poppins, Helvetica, sans-serif" }} color="textSecondary" gutterBottom>
                                        No Options
                                        </Typography>
                                </Card>

                            }


                        </div>
                    </Accordion>


                    :
                    <div style={{ display: "flex", justifyContent: "center", margin: 10 }} >
                        <PersonOutlineOutlinedIcon />
                    </div>
                }
            </div> */}
            {/* :
                <></>
            } */}
        </div>
    )
}

export default SidebarContentCont;