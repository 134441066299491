// import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listDashBoardContGraphs, listDashBoardPubGraphs } from '../../../../redux/actions/DashBoardActions';
import { Bar } from 'react-chartjs-2';

const data2 = {
  labels: ['Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6'],
  datasets: [
    {
      label: 'Teach Usage',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: 'rgb(255, 99, 132)',
      stack: 'Stack 0',
    },
    // {
    //   label: '# of Blue Votes',
    //   data: [2, 3, 20, 5, 1, 4],
    //   backgroundColor: 'rgb(54, 162, 235)',
    //   stack: 'Stack 0',
    // },
    {
      label: 'Learn Usage',
      data: [3, 10, 13, 15, 22, 30],
      backgroundColor: 'rgb(75, 192, 192)',
      stack: 'Stack 1',
    },
  ],
};

const options2 = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const GroupedBar = () => (
  <>
    {/* <div className='header'>
      <h1 className='title'>Latest Usage</h1>
      <div className='links'>
        <a
          className='btn btn-gh'
          href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/GroupedBar.js'
        >
          Github Source
        </a>
      </div>
    </div> */}
    <Bar data={data2} options={options2} />
  </>
);

const Sales = (props) => {
  const theme = useTheme();

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const dispatch = useDispatch()
  useEffect(() => {

    dispatch(listDashBoardContGraphs())
    return (() => {
      //
    })
  }, [])

  const dashboardContGraphsGet = useSelector(state => state.dashboardContGraphs)
  const { loadingDashboardContGraphs, DashboardContGraphs, errorDashContGraphs } = dashboardContGraphsGet

  let latestData = []
  let previousData = []
  let latestDates = []
  {
    loadingDashboardContGraphs && dashboardContGraphsGet ?
      console.log("data is loading")
      :
      console.log("graphs data of pub", DashboardContGraphs)
  }


  {
    dashboardContGraphsGet && DashboardContGraphs && DashboardContGraphs.data && DashboardContGraphs.data.latest ?
      // console.log("graphs at pub",DashboardPubGraphs.data)  
      DashboardContGraphs && DashboardContGraphs.data.latest.map((list, index) => {
        // list.latest.map((graphCount) => {
        latestData.push(list.graph)
        // })
      })


      :
      console.log("graphs at pub", DashboardContGraphs)
  }


  {
    dashboardContGraphsGet && DashboardContGraphs && DashboardContGraphs.data && DashboardContGraphs.data.previous ?
      // console.log("graphs at pub",DashboardPubGraphs.data)  
      DashboardContGraphs && DashboardContGraphs.data.previous.map((list, index) => {
        // list.latest.map((graphCount) => {
        previousData.push(list.graph)
        // })
      })


      :
      console.log("graphs at pub", DashboardContGraphs)
  }


  {
    dashboardContGraphsGet && DashboardContGraphs && DashboardContGraphs.data && DashboardContGraphs.data.latest ?
      // console.log("graphs at pub",DashboardPubGraphs.data)  
      DashboardContGraphs && DashboardContGraphs.data.latest.map((list, index) => {
        // list.latest.map((graphCount) => {
        let date = list.date
        latestDates.push(date)
        // })
      })


      :
      console.log("graphs at pub", DashboardContGraphs)
  }
  console.log("latests data of graphs cont", latestData)
  console.log("previous data of graphs cont", previousData)

  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: latestData ? latestData : [0, 0, 0, 0, 0, 0, 0],
        label: 'This weak'
      },
      {
        backgroundColor: colors.grey[200],
        data: previousData ? previousData : [0, 0, 0, 0, 0, 0, 0],
        label: 'Last weak'
      }
    ],
    labels: latestDates ? latestDates : ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug']
  };
  return (
    <Card {...props}>
      <CardHeader
        // action={(
        //   <Button
        //     endIcon={<ArrowDropDownIcon />}
        //     size="small"
        //     variant="text"
        //   >
        //     Last 7 days
        //   </Button>
        // )}
        title="Latest Usage"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
          style={{
            height: 415
          }}
        >

          <GroupedBar
            
          />


        </Box>
      </CardContent>
      <Divider />
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box> */}
    </Card>
  );
};

export default Sales;
