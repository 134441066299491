import { PUBUSERSDETAILS_LIST_REQUEST,PUBUSERSDETAILS_LIST_SUCCESS,PUBUSERSDETAILS_LIST_FAIL, CONTUSERSDETAILS_LIST_REQUEST, CONTUSERSDETAILS_LIST_SUCCESS, CONTUSERSDETAILS_LIST_FAIL } from "../constants/PubUsersListConstants";

function PubUsersReducer(state ={pubUsers:[]},action) {

    switch (action.type) {
        case PUBUSERSDETAILS_LIST_REQUEST:
            return { loadingPubUsers: true }
        case PUBUSERSDETAILS_LIST_SUCCESS:
            return {loadingPubUsers:false,pubUsers:action.payload};
        case PUBUSERSDETAILS_LIST_FAIL:
            return {loadingPubUsers:false,errorPubUsers:action.payload}
        default:
            return state;
    }

}

function ContUsersReducer(state ={contUsers:[]},action) {

    switch (action.type) {
        case CONTUSERSDETAILS_LIST_REQUEST:
            return { loadingContUsers: true }
        case CONTUSERSDETAILS_LIST_SUCCESS:
            return {loadingContUsers:false,contUsers:action.payload};
        case CONTUSERSDETAILS_LIST_FAIL:
            return {loadingContUsers:false,errorContUsers:action.payload}
        default:
            return state;
    }

}

export { PubUsersReducer,ContUsersReducer }