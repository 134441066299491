import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Divider, Grid, List, Toolbar } from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { useDispatch, useSelector } from "react-redux";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase";
import Constants from "../../../resource/Constants";
import FileUploader from "react-firebase-file-uploader";
import { listBoardGet } from "../../../redux/actions/boardDetailsAction";
import { listInstGet } from "../../../redux/actions/InstituteDetailsAction";
import { listSubGet } from "../../../redux/actions/subjectsAction";
import { listStdGet } from "../../../redux/actions/standardAction";
import { listChpGet } from "../../../redux/actions/chapterAction";
import { listTopGet } from "../../../redux/actions/topicAction";
// import { Alert } from "@material-ui/lab";
import { Snackbar, TextField } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Cookie from "js-cookie";
import Checkbox from '@material-ui/core/Checkbox';

import API from "../../../http/http";
import PARTNERAPI from "../../../http/httppartner";
import PUBLISHERAPI from "../../../http/publisher";
import { listContTypeGet } from "../../../redux/actions/contentTypeAction";
import CircularProgress from "@material-ui/core/CircularProgress";
// import image from "../../../assets/book-na-1.jpg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import ChipInput from 'material-ui-chip-input'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uploadScreen from '../../../assets/bulk_upload_screen.png';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanel1(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabPanel1.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function a11yProps1(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 100,
    // width: '33%',
    minHeight: 30,
  },
  formControl1: {
    margin: theme.spacing(1),
    minWidth: 350,
    minHeight: 30,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  toolbar: {
    marginRight: 24,
    justifyContent: "space-between",
  },
  button: {
    margin: theme.spacing(1),
    justifyContent: "flex-end",
    alignSelf: "center",
  },
  button1: {
    margin: theme.spacing(1),
    justifyContent: "flex-end",
    justifyItems: "flex-end",
    justifySelf: "flex-end",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    alignContent: "flex-end",
  },
}));


function SubjectsBox(props) {
  const [autoOpen, setAutoOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [subName, setSubName] = useState('Subject')

  const { autoSubs, actvRow } = props

  const onSubjectChanged = (event, value) => {
    console.log('subject changed value ', value)

    if (value) {
      const subId = parseInt(value.id);
      props.setSubjectType(subId)
      setSubName(value.label)
    } else {

      // setAutoOpen(false);

    }
  }

  const setSub = (sub) => {
    if (sub) {
      setSubName(sub.subject_name)
      props.setSubjectType(sub.subject_id)
      setInputValue(sub.subject_name)
    }
  }

  useEffect(() => {

    console.log('actvRow inside subjects box ', actvRow);
    if (actvRow) {
      setSub(actvRow)
    }
    return {

    }
  }, [])

  return (
    (autoSubs && autoSubs.length) ?
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={autoSubs}
        sx={{ width: '90%' }}
        style={{
          width: '90%',
          alignSelf: 'center',
          margin: '0 auto',
          // marginBottom: 20
        }}
        onOpen={() => {
          // only open when in focus and inputValue is not empty
          if (inputValue) {
            setAutoOpen(true);
          }
        }}
        onClose={() => setAutoOpen(false)}
        onInputChange={(e, value, reason) => {
          // setSubName(value);
          console.log('setting inputvalue ', value)
          setInputValue(value);

          // only open when inputValue is not empty after the user typed something
          if (!value) {
            setAutoOpen(false);
          }
        }}
        inputValue={inputValue}
        getOptionLabel={(option) => option.label.toString()}
        onChange={onSubjectChanged}
        open={autoOpen}
        renderInput={(params) => <TextField {...params} label={subName} />}
      />
      :
      <></>
  );
}


export default function UploadContent(props) {

  console.log(`props in upload content modal ${JSON.stringify(props)}`)
  console.log(`props ilc ${props.ilc}`)
  const { actvRow, allSubjects } = props

  const classes = useStyles();
  const classes2 = useStyles2();
  const [value, setValue] = React.useState(0);
  const [age, setAge] = React.useState("");
  const [showInputField, SetShowInputField] = useState(false);
  const [selectBoardDetails, setSelectedBoardDetails] = useState("");
  const [boardDetailsState, setBoardDetailsState] = useState(null);
  const [filenames, setFileNames] = useState([]);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [instType, setInstType] = useState((actvRow) ? actvRow.institute_type_id : "");
  const [boardType, setBoardType] = useState((actvRow) ? actvRow.institute_board_id : "");
  const [subjectType, setSubjectType] = useState((actvRow) ? actvRow.subject_id : "");
  const [standardType, setStandardType] = useState((actvRow) ? actvRow.classroom_std : '');
  const [contType, setContType] = useState("");
  const [ImageDownloadUrls, setImageDownloadUrls] = useState((actvRow) ? actvRow.image_cover_url : "");
  console.log(`actvRow image url ${(actvRow) ? actvRow.image_cover_url : 'n/a'}`)

  const [chap, setChap] = useState((actvRow) ? actvRow.chapter_id : 0);
  const [tpc, setTpc] = useState((actvRow) ? actvRow.topic_id : 0);
  const [title, setTitle] = useState((actvRow) ? actvRow.content_name : "");
  const [video, setVideo] = useState((actvRow) ? actvRow.content_location : "");
  const [snackOpen, setSnackOpen] = useState(false);
  const [chaptername, setChapterName] = useState((actvRow) ? actvRow.chapter_name : "n/a");
  const [topicname, setTopicName] = useState((actvRow) ? actvRow.topic_name : "n/a");
  const [progress, setProgress] = useState(0);
  const [bulkProgress, setBulkProgress] = useState(0);
  const [bulkIsUploading, setBulkIsUploading] = useState(false);
  const [agree, setAgree] = useState(false)

  const [tags, setTags] = useState('')
  const [yourChips, setYourChips] = useState((actvRow) ? actvRow.tags.split(',') : [])
  const [excel, setExcel] = useState(false)
  const [selectedFile, setSelectedFile] = useState("")
  const [open, setOpen] = React.useState(false);

  const autoSubs = []
  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
    {
      label: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
    { label: 'The Good, the Bad and the Ugly', year: 1966 },
    { label: 'Fight Club', year: 1999 },
    {
      label: 'The Lord of the Rings: The Fellowship of the Ring',
      year: 2001,
    },
    {
      label: 'Star Wars: Episode V - The Empire Strikes Back',
      year: 1980,
    },
    { label: 'Forrest Gump', year: 1994 },
    { label: 'Inception', year: 2010 },
    {
      label: 'The Lord of the Rings: The Two Towers',
      year: 2002,
    },
    { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { label: 'Goodfellas', year: 1990 },
    { label: 'The Matrix', year: 1999 },
    { label: 'Seven Samurai', year: 1954 },
    {
      label: 'Star Wars: Episode IV - A New Hope',
      year: 1977,
    },
    { label: 'City of God', year: 2002 },
    { label: 'Se7en', year: 1995 },
    { label: 'The Silence of the Lambs', year: 1991 },
    { label: "It's a Wonderful Life", year: 1946 },
    { label: 'Life Is Beautiful', year: 1997 },
    { label: 'The Usual Suspects', year: 1995 },
    { label: 'Léon: The Professional', year: 1994 },
    { label: 'Spirited Away', year: 2001 },
    { label: 'Saving Private Ryan', year: 1998 },
    { label: 'Once Upon a Time in the West', year: 1968 },
    { label: 'American History X', year: 1998 },
    { label: 'Interstellar', year: 2014 },
    { label: 'Casablanca', year: 1942 },
    { label: 'City Lights', year: 1931 },
    { label: 'Psycho', year: 1960 },
    { label: 'The Green Mile', year: 1999 },
    { label: 'The Intouchables', year: 2011 },
    { label: 'Modern Times', year: 1936 },
    { label: 'Raiders of the Lost Ark', year: 1981 },
    { label: 'Rear Window', year: 1954 },
    { label: 'The Pianist', year: 2002 },
    { label: 'The Departed', year: 2006 },
    { label: 'Terminator 2: Judgment Day', year: 1991 },
    { label: 'Back to the Future', year: 1985 },
    { label: 'Whiplash', year: 2014 },
    { label: 'Gladiator', year: 2000 },
    { label: 'Memento', year: 2000 },
    { label: 'The Prestige', year: 2006 },
    { label: 'The Lion King', year: 1994 },
    { label: 'Apocalypse Now', year: 1979 },
    { label: 'Alien', year: 1979 },
    { label: 'Sunset Boulevard', year: 1950 },
    {
      label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
      year: 1964,
    },
    { label: 'The Great Dictator', year: 1940 },
    { label: 'Cinema Paradiso', year: 1988 },
    { label: 'The Lives of Others', year: 2006 },
    { label: 'Grave of the Fireflies', year: 1988 },
    { label: 'Paths of Glory', year: 1957 },
    { label: 'Django Unchained', year: 2012 },
    { label: 'The Shining', year: 1980 },
    { label: 'WALL·E', year: 2008 },
    { label: 'American Beauty', year: 1999 },
    { label: 'The Dark Knight Rises', year: 2012 },
    { label: 'Princess Mononoke', year: 1997 },
    { label: 'Aliens', year: 1986 },
    { label: 'Oldboy', year: 2003 },
    { label: 'Once Upon a Time in America', year: 1984 },
    { label: 'Witness for the Prosecution', year: 1957 },
    { label: 'Das Boot', year: 1981 },
    { label: 'Citizen Kane', year: 1941 },
    { label: 'North by Northwest', year: 1959 },
    { label: 'Vertigo', year: 1958 },
    {
      label: 'Star Wars: Episode VI - Return of the Jedi',
      year: 1983,
    },
    { label: 'Reservoir Dogs', year: 1992 },
    { label: 'Braveheart', year: 1995 },
    { label: 'M', year: 1931 },
    { label: 'Requiem for a Dream', year: 2000 },
    { label: 'Amélie', year: 2001 },
    { label: 'A Clockwork Orange', year: 1971 },
    { label: 'Like Stars on Earth', year: 2007 },
    { label: 'Taxi Driver', year: 1976 },
    { label: 'Lawrence of Arabia', year: 1962 },
    { label: 'Double Indemnity', year: 1944 },
    {
      label: 'Eternal Sunshine of the Spotless Mind',
      year: 2004,
    },
    { label: 'Amadeus', year: 1984 },
    { label: 'To Kill a Mockingbird', year: 1962 },
    { label: 'Toy Story 3', year: 2010 },
    { label: 'Logan', year: 2017 },
    { label: 'Full Metal Jacket', year: 1987 },
    { label: 'Dangal', year: 2016 },
    { label: 'The Sting', year: 1973 },
    { label: '2001: A Space Odyssey', year: 1968 },
    { label: "Singin' in the Rain", year: 1952 },
    { label: 'Toy Story', year: 1995 },
    { label: 'Bicycle Thieves', year: 1948 },
    { label: 'The Kid', year: 1921 },
    { label: 'Inglourious Basterds', year: 2009 },
    { label: 'Snatch', year: 2000 },
    { label: '3 Idiots', year: 2009 },
    { label: 'Monty Python and the Holy Grail', year: 1975 },
  ];
  if (allSubjects && allSubjects.data && allSubjects.data.length) {
    allSubjects.data.map(sub2 => {
      console.log('sub2 ', sub2)
      const sub = {
        label: sub2.subject_name, id: sub2.subject_id.toString()
      }

      autoSubs.push(sub)
    })
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleSnackOpen = (open) => {
    setSnackOpen(open);
  };

  const handleChipChange = (chips) => {
    console.log('chip change called ', chips)
    setYourChips(chips)
  }

  const handleAddChip = (chip) => {
    console.log('onAdd chip called')
    let chips = yourChips
    chips.push(chip)
    setYourChips(chips)
  }

  const handleDeleteChip = (chip, index) => {
    console.log('onDelete chip called')

    // let chips = yourChips.filter(item => item != chip)
    let chips = yourChips.filter((item, idx) => idx !== index)
    setYourChips(chips)
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleTagsChange = (e) => {
    const { name, value } = e.target

    setTags(value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAgreeToggle = (e) => {
    console.log(e.target.checked)

    setAgree(e.target.checked)
  }

  const handleTitleChange = (event) => {
    console.log(`new title ${event}`);
    const { name, value } = event.target;
    setTitle(value);
  };

  const handleVideoChange = (event) => {
    console.log(`new video ${event}`);

    const { name, value } = event.target;
    setVideo(value);
  };

  const onPrefillData = () => {
    dispatch(listBoardGet(actvRow.institute_type_id)); // school boards
    dispatch(listStdGet(actvRow.institute_type_id));

    dispatch(listSubGet(actvRow.institute_board_id));
    dispatch(listChpGet(actvRow.subject_id, actvRow.institute_board_id));

    dispatch(listTopGet(actvRow.chapter_id));
  }

  const handleInstChange = (event) => {
    console.log("called handleInstChange");
    console.log(`new institute type ${event}`);
    const name = event.target.name;
    const value = event.target.value;
    // setState({
    // ...state,
    // [name]: event.target.value,
    // });


    // update to local storage
    localStorage.setItem("pustak.kgtopg.inst_type", value)

    setInstType(value);
    dispatch(listBoardGet(value));
    dispatch(listStdGet(value));

  };

  const handleBoardChange = (event) => {
    console.log("called handleBoardChange");
    console.log(`new board  ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log(`board selected value ${value}`);

    // update to local storage
    localStorage.setItem("pustak.kgtopg.board_type", value)

    setBoardType(value);
    // get subjects
    dispatch(listSubGet(value));
  };

  const handleStdChange = (event) => {
    console.log("called handleStdChange");
    console.log(`new standard ${event}`);

    const name = event.target.name;
    const value = event.target.value;

    // update to local storage
    localStorage.setItem("pustak.kgtopg.standard_type", value)
    setStandardType(value);
  };

  const handleSubChange = (event) => {
    console.log("called handleSubChange");
    console.log(`new subject ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setSubjectType(value);
    dispatch(listChpGet(value, boardType));
  };

  const handleChpChange = (event, chaptername) => {
    console.log("called handleChpChange");
    console.log(`new chapter ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    console.log("chapter name", chaptername);
    setChap(value);

    dispatch(listTopGet(value));
  };

  const handleTpcChange = (event) => {
    console.log("called handleTpcChange");
    console.log(`new topic ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setTpc(value);
  };

  const handleContTypeChange = (event) => {
    console.log("called handleTpcChange");
    console.log(`new topic ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setContType(value);
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      height: 30,
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      width: 310,
      padding: "10px 26px 10px 12px",
      placeholder: "Title",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",

        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const showInput = () => {
    SetShowInputField(true);
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadStartBulk = () => {
    setBulkIsUploading(true);
    setBulkProgress(0);
  };

  const handleProgressBulk = (progress) => {
    setBulkProgress(progress);
  };

  const handleUploadErrorBulk = (error) => {
    setBulkIsUploading(false);
  };

  const boardDetailsGet = useSelector((state) => state.boardDetails);
  const { loading, boardDetails, error } = boardDetailsGet;

  const instDetailsGet = useSelector((state) => state.instDetails);
  const { loadingInst, instDetails, errorInst } = instDetailsGet;

  const subDetailsGet = useSelector((state) => state.subDetails);
  const { loadingSub, subDetails, errorSub } = subDetailsGet;

  const stdDetailsGet = useSelector((state) => state.stdDetails);
  const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

  const topDetailsGet = useSelector((state) => state.topDetails);
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;

  const contTypeDetailsGet = useSelector((state) => state.contType);
  const { loadingCont, contTypeDetails, errorCont } = contTypeDetailsGet;

  const userId = Cookie.get("kgtopg.partner.cont.id");

  console.log("chapterDetails", chpDetailsGet);
  console.log("TopicDetails", topDetailsGet);
  console.log("contentDetailsget", contTypeDetails);

  const setSelections = () => {
    const insType = localStorage.getItem("pustak.kgtopg.inst_type");
    const brdType = localStorage.getItem("pustak.kgtopg.board_type");
    const stdType = localStorage.getItem("pustak.kgtopg.standard_type");

    // update state
    if (insType) {
      setInstType(insType)
      // get boards filled up
      dispatch(listBoardGet(insType));
      dispatch(listStdGet(insType));

    }

    if (brdType)
      setBoardType(brdType)

    if (stdType)
      setStandardType(stdType)
  }

  /* called after render completed.. */
  useEffect(() => {
    dispatch(listInstGet());

    // get from local storage
    setSelections()

    // override if editing a record
    if (actvRow) {
      onPrefillData()
    }

    getAllDetails();
    return () => {
      //
    };
  }, []);

  const getAllDetails = async () => {
    await new Promise((resolve, reject) => {
      dispatch(listBoardGet(10001));
      resolve();
    });

    const firstBoardId =
      boardDetails && boardDetails.data && boardDetails.data.length
        ? boardDetails.data[0].institute_board_id
        : 0;

    // we got boards
    await new Promise((resolve, reject) => {
      dispatch(listSubGet(firstBoardId));
      resolve();
    });

    dispatch(listContTypeGet());
  };

  const handleUploadSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("ebook")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    // setFileNames(filename)
    setDownloadUrls(downloadURL);
    if (downloadURL) {
      setBulkProgress(0);
      setBulkIsUploading(false);
    }
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleBulkContentUpload = async () => {
    console.log(`ebook url ${downloadUrls}`);

    if (
      !excel ||
      // !instType ||
      // !boardType ||
      !subjectType ||
      // !standardType
      !selectedFile
      // ||
      // !agree
    ) {
      handleSnackOpen(true);
    } else {
      // send data

      // const body = {
      //     institute_type_id: 10001,
      //     institute_board_id: 1001,
      //     classroom_std: 10,
      //     course: 'M.P.C',
      //     subject_id: 108,

      //     content_location: downloadUrls
      // }

      const body2 = {
        institute_type_id: instType,
        institute_board_id: boardType,
        classroom_std: standardType,
        course: "",
        subject_id: subjectType,
        content_name: video,
        chapter_id: chap,
        chapter_name: chaptername,
        topic_id: tpc,
        topic_name: topicname,
        content_f_desc: topicname,
        content_length: "3",
        content_location:
          "https://www.youtube.com/watch?v=TMubSggUOVE&ab_channel=TheOrganicChemistryTutor",
        content_size: 300,
      };


      const body = new FormData()
      body.append('institute_type_id', instType)
      body.append('institute_board_id', boardType)
      body.append('classroom_std', standardType)
      body.append('course', "")
      body.append('subject_id', subjectType)
      // body.append('file', selectedFile)
      body.append('excel_file', selectedFile)
      body.append('content_prov_id', userId)

      const resp = await PUBLISHERAPI.postSaveBulkContent2(body);
      console.log(`response ${JSON.stringify(resp)}`);


      // close the dialog..
      console.log(`props anchor ${props.anchor}`);
      console.log(`props oncloseclicked ${props.onCloseClicked}`);
      props.onCloseClicked(props.anchor);

      // also call search content
      // props.onSearchContent("");

      // call reload
      props.onReload()

      props.handleReloadData()
    }

  };

  const handleExcelChange = (event) => {
    setProgress(0)
    console.log("selected file in handleExcelChange target", event.target.files[0])
    setSelectedFile(event.target.files[0])
    if (event.target.files[0] && selectedFile != null) {
      setExcel(true)
      setProgress(100)
      console.log(`set excel true`)

    }

    console.log("selected file in handleExcelChange", selectedFile, event.target.files[0])
  }

  const handleSingleContentUpload = async () => {
    console.log(`ebook url ${ImageDownloadUrls}`);
    console.log(`chapter name ${chaptername}`)
    console.log(`video ${video}`)
    console.log(`chap ${chap}`)
    console.log(`tpc ${tpc}`)
    console.log(`instType ${instType}`)
    console.log(`boardType ${boardType}`)
    console.log(`subjectType ${subjectType}`)
    console.log(`stdType ${standardType}`)
    console.log(`title ${title}`)
    console.log(`contType ${contType}`)

    console.log(`checking params...`)
    if (
      // !instType ||
      // !boardType ||
      !subjectType ||
      // !standardType ||
      // !chap ||
      // !tpc ||
      // !contType ||
      !title ||
      !ImageDownloadUrls ||
      // !chaptername ||
      !yourChips ||
      !video ||
      (!video.includes('.mp4') && !video.includes('.mp3')  // video or audio not?
        && (!video.includes('.jpg') && !video.includes('.jpeg') && !video.includes('.png') && !video.includes('.gif')) // or image not?
        && (!video.includes('vimeo')) // not vimeo?
      )
    ) {
      console.log(`showing snackbar..`)
      handleSnackOpen(true);
    } else {
      // send data

      console.log("calling api =========================================");
      // const body = {
      //     institute_type_id: 10001,
      //     institute_board_id: 1001,
      //     classroom_std: 10,
      //     course: 'M.P.C',
      //     subject_id: 108,
      //     content_location: downloadUrls
      // }

      console.log(
        "parameters",
        instType,
        boardType,
        subjectType,
        standardType,
        chap,
        tpc,
        contType,
        title,
        video,
        ImageDownloadUrls,
        chaptername
      );


      const body = {
        institute_type_id: instType,
        institute_board_id: boardType,
        classroom_std: standardType,
        course: `class ${standardType}`,
        subject_id: subjectType,
        content_name: title,
        chapter_id: chap,
        chapter_name: chaptername,
        topic_id: tpc,
        topic_name: topicname,
        content_f_desc: topicname,
        content_length: 0,
        content_location: video,
        content_size: 0,
        // content_type_id: contType,
        content_prov_id: userId,
        tags: yourChips.join(','),
        // image_cover_url: encodeURI(ImageDownloadUrls),
        image_cover_url: ImageDownloadUrls
      };

      console.log(`content api body ${JSON.stringify(body)}`)
      let resp;
      if (!actvRow) {
        resp = await new Promise(async (resolve, reject) => {
          await PUBLISHERAPI.postSaveSingleContent(body);

          resolve();
        });
      } else {
        resp = await new Promise(async (resolve, reject) => {
          await PUBLISHERAPI.updateSingleContent(actvRow.id, body);

          resolve();
        });
      }

      console.log(`response ${JSON.stringify(resp)}`);

      // close the dialog..
      console.log(`props anchor ${props.anchor}`);
      console.log(`props oncloseclicked ${props.onCloseClicked}`);
      props.onCloseClicked(props.anchor);

      // also call search content
      // props.onSearchContent("");

      // call reload
      props.onReload()
      props.handleReloadData()
    }
  };

  console.log(`actvRow image ${(ImageDownloadUrls)}`)
  console.log(`instType ${instType}`)
  console.log(`boardType ${boardType}`)
  console.log(`subjectType ${subjectType}`)
  console.log(`standardType ${standardType}`)
  console.log(`chap ${chap}`)
  console.log(`tpc ${tpc}`)
  console.log(`title ${title}`)
  console.log(`ImageDownloadUrls ${ImageDownloadUrls}`)
  console.log(`chaptername ${chaptername}`)
  console.log(`video ${video}`)
  console.log(`agree ${agree}`)

  let singleUploadDisabled = false;
  let bulkUploadDisabled = false;
  let openUploadDisabled = false;

  if (
    // !instType ||
    // !boardType ||
    // !subjectType ||
    // !standardType ||
    // !chap ||
    // !tpc ||
    // !contType ||
    !title ||
    // ((actvRow && !actvRow.image_cover_url) || 
    !ImageDownloadUrls ||
    // !chaptername ||
    !yourChips ||
    !video
    // ||
    // !agree
  )
    singleUploadDisabled = true;


  if (
    !subjectType ||

    !title ||
    // ((actvRow && !actvRow.image_cover_url) || 
    !ImageDownloadUrls ||
    !video
    // ||
    // !agree
  )
    openUploadDisabled = true;


  if (
    !subjectType ||

    !selectedFile ||
    !excel
    // ||
    // ((actvRow && !actvRow.image_cover_url) || 

    // !agree
  )
    bulkUploadDisabled = true;

  console.log(`allsubjects in upload modal ${JSON.stringify(allSubjects)}`)

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
          style={{ fontSize: 16, fontFamily: "Poppins, Helvetica, sans-serif" }}
        >
          Upload Content
        </Typography>

      </Toolbar>
      <Divider style={{ marginBottom: 10 }} />

      {/* <AppBar position="static" color="default" style={{ marginTop: "20" }}> */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
      >
        <Tab
          label="Single Upload"
          {...a11yProps(0)}
          style={{ fontSize: 14 }}
        />
        {(!actvRow && !props.ilc) &&
          <Tab
            label="Bulk Upload"
            {...a11yProps(0)}
            style={{ fontSize: 14 }}
          />
        }

        {/*         
        <Tab
          label="Open Content Upload"
          {...a11yProps(0)}
          style={{ fontSize: 12 }}
        /> */}
      </Tabs>

      {/* </AppBar> */}


      {/* BULK UPLOAD PANEL */}
      {(!actvRow && !props.ilc) &&
        <TabPanel value={value} index={1}>
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "block",
            }}
          >


            {/*             
            {(!props.ilc && instDetailsGet) ? (
              loadingInst && instDetailsGet ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ alignSelf: "center" }}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Institute type
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={instType}
                      onChange={handleInstChange}
                      label="Select Institute Type"
                      size="small"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Institutes
                      </MenuItem>
                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select{" "}
                      <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12,
                        }}
                      >
                        Institute type
                      </span>
                    </span>
                  </FormControl>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ alignSelf: "center" }}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Institute type
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={instType}
                      onChange={handleInstChange}
                      label="Select Institute Type"
                      size="small"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      {loadingInst && instDetailsGet ? (
                        console.log("loading trueeee")
                      ) : instDetails.length != 0 ? (
                        instDetails.data.map((list, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={list.institute_type_id}
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {list.institute_type_name}
                            </MenuItem>
                            // <option key={index} value={list.institute_type_id}
                            //     style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                            // >{list.institute_type_name}</option>
                          );
                        })
                      ) : (
                        <MenuItem
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          No Institutes
                        </MenuItem>
                      )}
                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select{" "}
                      <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12,
                        }}
                      >
                        Institute type
                      </span>
                    </span>
                  </FormControl>
                </div>
              )
            ) :
              !props.ilc && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ alignSelf: "center" }}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Institute type
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={instType}
                      onChange={handleInstChange}
                      label="Select Institute Type"
                      size="small"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Institutes
                      </MenuItem>
                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select{" "}
                      <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12,
                        }}
                      >
                        Institute type
                      </span>
                    </span>
                  </FormControl>
                </div>
              )} */}




            {/* 
            {!props.ilc && boardDetailsGet &&
              boardDetails &&
              boardDetails.data &&
              boardDetails.data.length != 0 ? (
              loading && boardDetailsGet ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Board/Uni
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={boardType}
                      onChange={handleBoardChange}
                      label="Board/Uni"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select{" "}
                      <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12,
                        }}
                      >
                        Board/Uni
                      </span>
                    </span>
                  </FormControl>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Board/Uni
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={boardType}
                      onChange={handleBoardChange}
                      label="Board/Uni"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      {loading && boardDetailsGet ? (
                        console.log("loading trueeee")
                      ) : boardDetails &&
                        boardDetails.data &&
                        boardDetails.data.length != 0 ? (
                        boardDetails.data.map((list, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={list.institute_board_id}
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {list.institute_board_name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Please Select Institute Type
                        </MenuItem>
                      )}
                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select{" "}
                      <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12,
                        }}
                      >
                        Board/Uni
                      </span>
                    </span>
                  </FormControl>
                </div>
              )
            ) :
              !props.ilc &&
              (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Board/Uni
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={boardType}
                      onChange={handleBoardChange}
                      label="Board/Uni"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Boards
                      </MenuItem>
                    </Select>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select{" "}
                      <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12,
                        }}
                      >
                        Board/Uni
                      </span>
                    </span>
                  </FormControl>
                </div>
              )} */}


            {/* 
            {!props.ilc && stdDetailsGet ? (
              loadingStd && stdDetailsGet ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Standard/Stream
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={standardType}
                      onChange={handleStdChange}
                      label="Standard/Stream"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    </Select>

                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select{" "}
                      <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12,
                        }}
                      >
                        Standard/Stream
                      </span>
                    </span>
                  </FormControl>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Standard/Stream
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={standardType}
                      onChange={handleStdChange}
                      label="Standard/Stream"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      {loadingStd && stdDetailsGet ? (
                        console.log("loading trueeee")
                      ) : stdDetails.length != 0 ? (
                        stdDetails.data.map((list, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={list.id}
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              {list.standard}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          style={{
                            fontSize: 13,
                            color: "#464E5F",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                          }}
                        >
                          Please select Institute Type
                        </MenuItem>
                      )}
                    </Select>

                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select{" "}
                      <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12,
                        }}
                      >
                        Standard/Stream
                      </span>
                    </span>
                  </FormControl>
                </div>
              )
            ) :
              !props.ilc &&
              (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Standard/Stream
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={standardType}
                      onChange={handleStdChange}
                      label="Standard/Stream"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Standards
                      </MenuItem>
                    </Select>

                    <span
                      style={{
                        fontSize: 12,
                        color: "#464E5F",
                        marginLeft: 5,
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select{" "}
                      <span
                        style={{
                          color: "#464E5F",
                          fontWeight: "600",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                          fontSize: 12,
                        }}
                      >
                        Standard/Stream
                      </span>
                    </span>
                  </FormControl>
                </div>
              )} */}




            <SubjectsBox
              autoSubs={autoSubs}
              setSubjectType={setSubjectType} />
            <div style={{ height: 20 }}></div>

            {/* 
          <div style={{ display: "flex", justifyContent: "center" }}>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subjectType}
                onChange={handleSubChange}
                label="Select Subject"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {allSubjects && allSubjects.data && allSubjects.data.length && (
                  allSubjects.data.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.subject_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {list.subject_name}
                      </MenuItem>
                    );
                  })
                )}

              </Select>
              
            </FormControl>
          </div> */}


            {/* DOWNLOAD FORMAT LINK */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography
                style={{
                  color: "#464E5F",
                  fontWeight: "600",
                  fontFamily: "Poppins, Helvetica, sans-serif",
                  fontSize: 12,
                  // marginTop: 10,
                  marginBottom: 10,
                  marginRight: 20,
                  marginTop: 20,
                  textDecoration: "none",
                }}
              >
                <a
                  href="/assets/ContentFormat.xlsx"
                  download
                  style={{ textDecoration: "none", color: "steelblue" }}
                  onClick={handleClickOpen}
                >
                  Download Format
                </a>
              </Typography>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
              >
                <DialogTitle id="alert-dialog-title">{"Instructions to prepare bulk upload"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <img src={uploadScreen} width='100%' />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'row'
                      }}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary="Column #1"
                            secondary="Enter the title"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Column #2"
                            secondary="Enter the URL of your content"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Column #3"
                            secondary="Enter the tags seperated by commas as shown in picture above"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Column #4"
                            secondary="Enter the URL of the thumbnail image"
                          />
                        </ListItem>
                      </List>

                      <Typography
                        variant="h1"
                        style={{
                          opacity: '0.2',
                          fontSize: 50
                        }}>
                        EXAMPLE
                      </Typography>
                    </div>

                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {/* <Button onClick={handleClose} color="primary">
                  Disagree
                </Button> */}
                  <Button onClick={handleClose} color="primary" autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>


            {/* UPLOAD BUTTON */}
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 20,
                  marginLeft: 15,
                  marginRight: 20,
                  marginBottom: 20
                }}
              >

                <label
                  // style={{
                  //   fontFamily: "Poppins, Helvetica, sans-serif",
                  //   backgroundColor: "steelblue",
                  //   color: "white",
                  //   padding: 5,
                  //   borderRadius: 4,
                  //   cursor: "pointer",
                  //   width: '80%',
                  //   textAlign: "center",
                  // }}

                  onClick={() => {
                    document.getElementById('bulkfile').click()
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      cursor: 'pointer'
                    }}>
                    <AddBoxIcon style={{
                      color: 'steelblue'
                    }} />
                    <Typography
                      variant='body1'
                      style={{
                        marginLeft: 10,
                        color: "#464E5F",
                      }}>
                      Upload Excelsheet File
                    </Typography>

                  </div>
                  {/* <FileUploader
                  hidden
                  // accept="image/*"
                  storageRef={firebase.storage().ref("images")}
                  onUploadStart={handleUploadStartBulk}
                  onUploadError={handleUploadErrorBulk}
                  onUploadSuccess={handleUploadSuccess}
                  onProgress={handleProgressBulk}
                /> */}

                </label>

                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" type="file" className="form-control" name="upload_file" onChange={handleExcelChange} style={{ display: 'none' }} id="bulkfile" />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {excel &&
                    <CheckCircleOutlinedIcon style={{ color: "green", fontSize: 30 }} />
                    // <img src={bookUpload} alt="preview Image" width="200px" style={{ alignSelf: "center", display: "flex" }} />
                  }
                </div>
              </div>
              {/* 
            <Typography style={{ marginLeft: 15 }}>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Excel Sheet
                </span>
              </span>
            </Typography> */}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {bulkProgress ? <CircularProgress /> : <></>}
            </div>

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        {downloadUrls &&
                            <img src={downloadUrls} alt="preview Image" width="200px" style={{ alignSelf: "center", display: "flex" }} />
                        }
                    </div> */}



            {/* 
          <div
            style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', width: '100%', marginTop: 20 }}
          >
            <Checkbox
              checked={agree}
              onChange={handleAgreeToggle}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <Link to="/publisher-content/settings/terms-conditions" target="_blank" rel="noopener noreferrer">
              <Typography
                variant="body2">
                I agree to Terms &amp; Conditions
              </Typography>
            </Link>
          </div> */}



            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: 15
              }}
            >
              {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}
              <Button
                variant="contained"
                onClick={() => props.onCloseClicked(props.anchor, true)}
              >Cancel</Button>

              <Button
                disabled={bulkUploadDisabled}
                variant="contained"
                color="primary"
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                  textTransform: "none",
                  // width: '100%'
                  // backgroundColor: "#3699FF",
                  // color: '#FFFFFF'
                }}
                onClick={() => {
                  // handleSnackOpen(true)

                  handleBulkContentUpload();
                }}
              >
                Save
              </Button>
              {/* 
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                This is a success message!
              </Alert>
            </Snackbar>
 */}
              <Snackbar
                open={snackOpen}
                // autoHideDuration={6000}
                onClose={() => handleSnackOpen(false)}
              >
                <Alert onClose={() => handleSnackOpen(false)} severity="warning">
                  Please fill up mandatory fields
                </Alert>
              </Snackbar>


              {/* </Link> */}
            </div>
          </div>
        </TabPanel>
      }
      {/* BULk ABOVE COMMENTED OUT */}

      {/* SINGLE UPLOAD PANEL */}
      <TabPanel value={value} index={0}
        style={{
          padding: 0
        }}>
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "block",
          }}
        >



          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}>

            {props.ilc && loadingInst && instDetailsGet ? (
              <div style={{ display: "flex", justifyContent: "center",
              width: '33%%' }}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ alignSelf: "center", width: '100%' }}
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Institute type
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={instType}
                    onChange={handleInstChange}
                    label=" Institute type"
                    size="small"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      width:'100%'
                    }}
                    fullWidth
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please select
                    </MenuItem>
                  </Select>

                </FormControl>
              </div>
            ) :
              props.ilc &&
              (<div style={{ display: "flex", justifyContent: "center",
              width: '33%' }}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ alignSelf: "center", width: '100%' }}
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Institute type
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={instType}
                    onChange={handleInstChange}
                    label=" Institute type"
                    size="small"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    fullWidth
                  >
                    {loadingInst && instDetailsGet ? (
                      console.log("loading trueeee")
                    ) : instDetails.length != 0 ? (
                      instDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.institute_type_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.institute_type_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Institutes
                      </MenuItem>
                    )}
                  </Select>

                </FormControl>
              </div>

              )}

            {props.ilc && boardDetailsGet ? (

              <div style={{ display: "flex", justifyContent: "center",
              width: '33%' }}>
                <FormControl variant="outlined" className={classes.formControl}
                style={{ width: '100%' }}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Board/Uni
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={boardType}
                    onChange={handleBoardChange}
                    label="Board/Uni"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    fullWidth
                  >
                    {loading && boardDetailsGet ? (
                      console.log("loading trueeee")
                    ) : boardDetails &&
                      boardDetails.data &&
                      boardDetails.data.length != 0 ? (
                      boardDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.institute_board_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.institute_board_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>

                </FormControl>
              </div>

            ) :
              props.ilc &&
              (
                <div style={{ display: "flex", justifyContent: "center",
                width: '33%' }}>
                  <FormControl variant="outlined" className={classes.formControl}
                  style={{
                    width: '100%'
                  }}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Board/Uni
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={boardType}
                      onChange={handleBoardChange}
                      label="Board/Uni"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                      fullWidth
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Boards
                      </MenuItem>
                    </Select>

                  </FormControl>
                </div>
              )}





            {props.ilc && stdDetailsGet ? (

              <div style={{ display: "flex", justifyContent: "center",
              width: '33%' }}>
                <FormControl variant="outlined" className={classes.formControl}
                style={{
                  width: '100%'
                }}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Standard/Stream
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={standardType}
                    onChange={handleStdChange}
                    label="Standard/Stream"
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                    fullWidth
                  >
                    {loadingStd && stdDetailsGet ? (
                      console.log("loading trueeee")
                    ) : stdDetails.length != 0 ? (
                      stdDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.standard}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>


                </FormControl>
              </div>

            ) :
              props.ilc &&
              (
                <div style={{ display: "flex", justifyContent: "center",
                width: '33%' }}>
                  <FormControl variant="outlined" className={classes.formControl}
                  style={{
                    width: '100%'
                  }}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Standard/Stream
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={standardType}
                      onChange={handleStdChange}
                      label="Standard/Stream"
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                      fullWidth
                    >
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        No Standards
                      </MenuItem>
                    </Select>


                  </FormControl>
                </div>
              )}
          </div>



















          {/* {subDetailsGet ? (
            loadingSub && subDetailsGet ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={subjectType}
                    onChange={handleSubChange}
                    label="Subject"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Subject
                    </span>
                  </span>
                </FormControl>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={subjectType}
                    onChange={handleSubChange}
                    label="Subject"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    {loadingSub && subDetailsGet ? (
                      console.log("loading trueeee")
                    ) : subDetails.length != 0 ? (
                      subDetails.data.subjects.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.subject_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.subject_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Subject
                    </span>
                  </span>
                </FormControl>
              </div>
            )
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectType}
                  onChange={handleSubChange}
                  label="Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please Select Institute Type
                  </MenuItem>
                </Select>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Subject
                  </span>
                </span>
              </FormControl>
            </div>
          )} */}












          <SubjectsBox
            autoSubs={autoSubs}
            setSubjectType={setSubjectType}
            actvRow={actvRow} />
          <div style={{ height: 20 }}></div>



          {/* 

          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                Select Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={subjectType}
                onChange={handleSubChange}
                label="Select Subject"
                style={{ fontSize: 13, color: "#464E5F" }}
              >
                {allSubjects && allSubjects.data && allSubjects.data.length ? (
                  allSubjects.data.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.subject_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        {list.subject_name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No subjects!
                  </MenuItem>
                )}
              </Select>
              
            </FormControl>
          </div> */}

















          {/*         
          {chpDetailsGet ? (
            loadingChap && chpDetailsGet ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Chapter
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={chap}
                    onChange={handleChpChange}
                    label="Chapter"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Chapter
                    </span>
                  </span>
                </FormControl>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Chapter
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={chap}
                    onChange={handleChpChange}
                    label="Chapter"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    {loadingChap && chpDetailsGet ? (
                      console.log("loading trueeee")
                    ) : chapDetails.length != 0 ? (
                      chapDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.chapter_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                            onClick={() => {
                              setChapterName(list.chapter_title);
                            }}
                          >
                            {list.chapter_title}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Chapter
                    </span>
                  </span>
                </FormControl>
              </div>
            )
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Chapter
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={chap}
                  onChange={handleChpChange}
                  label="Chapter"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Chapters
                  </MenuItem>
                </Select>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Chapter
                  </span>
                </span>
              </FormControl>
            </div>
          )}

          {topDetailsGet ? (
            loadingTop && topDetailsGet ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="outlined-search"
                    label="Search field"
                    type="search"
                    variant="outlined"
                    style={{ width: 250 }}
                  />
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Topic
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={tpc}
                    onChange={handleTpcChange}
                    label="Topic"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Topic
                    </span>
                  </span>
                </FormControl>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Topic
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={tpc}
                    onChange={handleTpcChange}
                    label="Topic"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    {loadingTop && topDetailsGet ? (
                      console.log("loading trueeee")
                    ) : TopDetails.length != 0 ? (
                      TopDetails.data.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.topic_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                            onClick={() => {
                              setTopicName(list.topic_title);
                            }}
                          >
                            {list.topic_title}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Topic
                    </span>
                  </span>
                </FormControl>
              </div>
            )
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Topic
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={tpc}
                  onChange={handleTpcChange}
                  label="Topic"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Topics{" "}
                  </MenuItem>
                </Select>

                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Topic
                  </span>
                </span>
              </FormControl>
            </div>
          )} */}



















          {/* <div style={{ display: "flex", justifyContent: "center" }} >
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Content Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={contType}
                                onChange={handleContTypeChange}
                                label="Content Type"
                                style={{ fontSize: 13, color: "#464E5F" }}
                            >

                                {loadingCont && contTypeDetailsGet ?
                                    console.log("loading trueeee")
                                    :
                                    contTypeDetails.length != 0 ?

                                        contTypeDetails.data.results.map((list, index) => {
                                            return (
                                                <MenuItem key={index} value={list.id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.content_type_name_text}</MenuItem>

                                            )
                                        })
                                        :

                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                                }




                            </Select>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                            >
                                Please select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Content Type
                            </span>
                            </span>
                        </FormControl>

                    </div> */}

          <div style={{
            display: "flex", justifyContent: "center",
            marginTop: 10,
            width: '100%'
          }}>
            <FormControl
              fullWidth
              style={{
                width: '90%'
              }}>
              <TextField
                id="outlined-basic"
                label="Enter Title"
                variant="outlined"
                style={{
                  // width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={title}
                onChange={handleTitleChange}
                fullWidth
              />
              {/* <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Title
                  </span>
                </span>
              </Typography> */}
            </FormControl>
            {/* 
                        
                        <FormControl className={classes.margin}>
                            <BootstrapInput id="demo-customized-textbox" placeholder="Enter Title"
                            ref={(input) => {
                                if (input) {
                                   input.focus();
                                }
                            }}
                            defaultValue={title}
                            onChange={handleTitleChange} />
                            <Typography>
                                <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Please enter <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                        Title
                            </span>
                                </span>
                            </Typography>
                        </FormControl>
                     */}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
              width: '100%'
            }}
          >
            <FormControl
              style={{
                width: '90%'
              }}>
              <TextField
                id="outlined-basic"
                label="Paste Resource URL"
                variant="outlined"
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={video}
                onChange={handleVideoChange}
                fullWidth
              />

              {/* 
                            <BootstrapInput id="demo-customized-textbox" placeholder="Enter video URL"
                                defaultValue={video}
                                onChange={handleVideoChange} />

                             */}
              {/* <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please paste{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    resource URL
                  </span>

                </span>
              </Typography> */}
            </FormControl>

          </div>
          <div
            style={{
              marginBottom: 20,
              marginLeft: 10
            }}>
            <span
              style={{
                fontSize: 12,
                color: "#464E5F",
                marginLeft: 5,
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}>(MP4, MP3, Image, Vimeo)</span>
          </div>


          <div style={{
            paddingLeft: 20,
            paddingRight: 20
          }}>
            <ChipInput
              defaultValue={yourChips}
              onChange={(chips) => handleChipChange(chips)}
              fullWidth={true}
              fullWidthInput={true}
              placeholder={`Add tags`}
              multiline={true}
            />
            {/* <ChipInput
                value={yourChips}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
              /> */}
          </div>

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20
            }}>

            {/* <Typography>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Please enter{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  search tags
                </span>

              </span>
            </Typography> */}


          </div>

          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20
            }}>
            <span
              style={{
                fontSize: 12,
                color: "#464E5F",
                marginLeft: 5,
                fontFamily: "Poppins, Helvetica, sans-serif",
              }}>(Press Enter / Return to save tag)</span>
          </div>


          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: 20,
                marginLeft: 15,
                marginRight: 20,
              }}
            >

              {!ImageDownloadUrls ? (
                <>
                  <label
                  // style={{
                  //   fontFamily: "Poppins, Helvetica, sans-serif",
                  //   backgroundColor: "steelblue",
                  //   color: "white",
                  //   padding: 5,
                  //   borderRadius: 4,
                  //   cursor: "pointer",
                  //   width: '80%',
                  //   textAlign: "center",
                  // }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        cursor: 'pointer'
                      }}>
                      <AddBoxIcon style={{
                        color: 'steelblue'
                      }} />
                      <Typography
                        variant='body1'
                        style={{
                          marginLeft: 10,
                          color: "#464E5F",
                        }}>
                        Add a thumbnail to media
                      </Typography>
                      <FileUploader
                        hidden
                        accept="image/*"
                        storageRef={firebase.storage().ref("images")}
                        onUploadStart={handleUploadStart}
                        onUploadError={handleUploadError}
                        onUploadSuccess={handleUploadSingleSuccess}
                        onProgress={handleProgress}
                      />
                    </div>
                  </label>

                </>
              )
                : (
                  <></>
                )
              }
            </div>
            {/* 
            <Typography style={{ marginLeft: 15 }}>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Cover Image
                </span>
              </span>
            </Typography> */}

            <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }}>
              {progress ? <CircularProgress /> : <></>}
            </div>
            <div style={{ display: "flex", justifyContent: "center", position: 'relative' }}>

              {ImageDownloadUrls && (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      cursor: 'pointer',
                      zIndex: 3
                    }}>
                    <label>
                      <EditIcon />
                      <FileUploader
                        hidden
                        accept="image/*"
                        storageRef={firebase.storage().ref("images")}
                        onUploadStart={handleUploadStart}
                        onUploadError={handleUploadError}
                        onUploadSuccess={handleUploadSingleSuccess}
                        onProgress={handleProgress}
                      />

                    </label>
                  </div>
                  <img
                    src={ImageDownloadUrls}
                    alt="preview Image"
                    width="100px"
                    style={{
                      alignSelf: "center",
                      display: "flex",
                      width: 75,
                      width: 100,
                    }}
                  />
                </>
              )}
              {/* 
              {(actvRow && !ImageDownloadUrls) && (
                <img
                  src={actvRow.image_cover_url}
                  alt="preview Image"
                  width="200px"
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    width: 75,
                    height: 100,
                  }}
                /> */}

            </div>
          </div>
          {/* 
          <div
            style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', width: '100%', marginTop: 20 }}
          >
            <Checkbox
              checked={agree}
              onChange={handleAgreeToggle}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <Link to="/publisher-content/settings/terms-conditions" target="_blank" rel="noopener noreferrer">
              <Typography
                variant="body2">
                I agree to Terms &amp; Conditions
              </Typography>
            </Link>
          </div> */}

          <div
            style={{
              display: "flex", justifyContent: "space-between",
              padding: 20, marginTop: 20, width: '100%'
            }}
          >
            {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}
            <Button
              variant="contained"
              onClick={() => props.onCloseClicked(props.anchor, true)}
            >Cancel</Button>
            <Button
              disabled={singleUploadDisabled}
              variant="contained"
              color="primary"
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                textTransform: "none",
                // width: '100%'
                // backgroundColor: "#3699FF",
                // color: '#FFFFFF'
              }}
              onClick={() => {
                handleSingleContentUpload();
              }}
            >
              Save
            </Button>
            <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
              <Alert onClose={handleSnackClose} severity="warning">
                Something is not right with your input!
              </Alert>
            </Snackbar>

            {/* </Link> */}
          </div>
        </div>
      </TabPanel>

      {/* OPEN UPLOAD PANEL */}
      <TabPanel value={value} index={2}>
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "block",
          }}
        >

          {subDetailsGet ? (
            loadingSub && subDetailsGet ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={subjectType}
                    onChange={handleSubChange}
                    label="Subject"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    <MenuItem
                      style={{
                        fontSize: 13,
                        color: "#464E5F",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                      }}
                    >
                      Please Select Institute Type
                    </MenuItem>
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Subject
                    </span>
                  </span>
                </FormControl>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={subjectType}
                    onChange={handleSubChange}
                    label="Subject"
                    style={{ fontSize: 13, color: "#464E5F" }}
                  >
                    {loadingSub && subDetailsGet ? (
                      console.log("loading trueeee")
                    ) : subDetails.length != 0 ? (
                      subDetails.data.subjects.map((list, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={list.subject_id}
                            style={{
                              fontSize: 13,
                              color: "#464E5F",
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            {list.subject_name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                      >
                        Please Select Institute Type
                      </MenuItem>
                    )}
                  </Select>
                  <span
                    style={{
                      fontSize: 12,
                      color: "#464E5F",
                      marginLeft: 5,
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please select{" "}
                    <span
                      style={{
                        color: "#464E5F",
                        fontWeight: "600",
                        fontFamily: "Poppins, Helvetica, sans-serif",
                        fontSize: 12,
                      }}
                    >
                      Subject
                    </span>
                  </span>
                </FormControl>
              </div>
            )
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subjectType}
                  onChange={handleSubChange}
                  label="Subject"
                  style={{ fontSize: 13, color: "#464E5F" }}
                >
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    Please Select Institute Type
                  </MenuItem>
                </Select>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please select{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Subject
                  </span>
                </span>
              </FormControl>
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl>
              <TextField
                id="outlined-basic"
                label="Enter Title"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={title}
                onChange={handleTitleChange}
              />
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    Title
                  </span>
                </span>
              </Typography>
            </FormControl>


          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 0,
            }}
          >
            <FormControl>
              <TextField
                id="outlined-basic"
                label="Paste Resource URL"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={video}
                onChange={handleVideoChange}
              />

              {/* 
                            <BootstrapInput id="demo-customized-textbox" placeholder="Enter video URL"
                                defaultValue={video}
                                onChange={handleVideoChange} />

                             */}
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please paste{" "}
                  <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12,
                    }}
                  >
                    resource URL
                  </span>
                  (MP4, MP3, Image, Vimeo URL)
                </span>
              </Typography>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <FormControl className={classes.margin}>
              <TextField
                id="outlined-basic"
                label="Enter Multiple Tags For Best Results"
                variant="outlined"
                style={{
                  width: 350,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
                defaultValue={tags}
                onChange={handleTagsChange}
              />
              <Typography>
                <span
                  style={{
                    fontSize: 12,
                    color: "#464E5F",
                    marginLeft: 5,
                    fontFamily: "Poppins, Helvetica, sans-serif",
                  }}
                >
                  Please enter <span
                    style={{
                      color: "#464E5F",
                      fontWeight: "600",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                      fontSize: 12
                    }}>
                    Tags like #heart #parabellum #history
                  </span>
                </span>
              </Typography>
            </FormControl>
          </div>


          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                marginLeft: 15,
                marginRight: 20,
              }}
            >
              <label
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                  backgroundColor: "lightsteelblue",
                  color: "black",
                  padding: 5,
                  borderRadius: 4,
                  cursor: "pointer",
                  width: '100%',
                  textAlign: "center",
                }}
              >
                Upload Cover Image
                <FileUploader
                  hidden
                  // accept="image/*"
                  storageRef={firebase.storage().ref("images")}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSingleSuccess}
                  onProgress={handleProgress}
                />
              </label>
            </div>

            <Typography style={{ marginLeft: 15 }}>
              <span
                style={{
                  fontSize: 12,
                  color: "#464E5F",
                  marginLeft: 5,
                  fontFamily: "Poppins, Helvetica, sans-serif",
                }}
              >
                Select{" "}
                <span
                  style={{
                    color: "#464E5F",
                    fontWeight: "600",
                    fontFamily: "Poppins, Helvetica, sans-serif",
                    fontSize: 12,
                  }}
                >
                  Cover Image
                </span>
              </span>
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {progress ? <CircularProgress /> : <></>}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {ImageDownloadUrls && (
                <img
                  src={ImageDownloadUrls}
                  alt="preview Image"
                  width="100px"
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    width: 75,
                    width: 100,
                  }}
                />
              )}
              {/* 
              {(actvRow && !ImageDownloadUrls) && (
                <img
                  src={actvRow.image_cover_url}
                  alt="preview Image"
                  width="200px"
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    width: 75,
                    height: 100,
                  }}
                /> */}

            </div>
          </div>
          {/* 
          <div
            style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', width: '100%', marginTop: 20 }}
          >
            <Checkbox
              checked={agree}
              onChange={handleAgreeToggle}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <Link to="/publisher-content/settings/terms-conditions" target="_blank" rel="noopener noreferrer">
              <Typography
                variant="body2">
                I agree to Terms &amp; Conditions
              </Typography>
            </Link>
          </div> */}

          <div
            style={{ display: "flex", justifyContent: "center", padding: 20, marginTop: 0, width: '100%' }}
          >
            {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}
            <Button
              disabled={openUploadDisabled}
              variant="contained"

              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                textTransform: "none",
                // width: '100%',
                backgroundColor: "#3699FF",
                color: '#FFFFFF'
              }}
              onClick={() => {
                handleSingleContentUpload();
              }}
            >
              Save
            </Button>
            {/* </Link> */}

            <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
              <Alert onClose={handleSnackClose} severity="warning">
                Something is not right with your input!
              </Alert>
            </Snackbar>
          </div>
        </div>
      </TabPanel>


    </div>
  );
}
