import React, { useEffect, useState } from "react";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from '@material-ui/core/Drawer';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Chip from "@material-ui/core/Chip";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DateRangeIcon from "@material-ui/icons/DateRange";
// import HTML5Backend from "react-dnd-html5-backend";
// import { DragDropContext } from "react-dnd";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { updateCurrLessonPlan } from "../../redux/actions/currPlanActions";
import "react-nice-dates/build/style.css";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, Container } from "@material-ui/core";
import UnitListResults from "./slots/UnitListResults";
import UnitListToolbar from "./slots/UnitListToolbar";
import customers from "../../__mocks__/customers";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Edit } from "react-feather";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import MapAPI from "../../http/curriculummap";
import MapUnitAPI from "../../http/curriculummapunit";
import { useDispatch, useSelector } from "react-redux";
import { listUnitMapGet, postNewUnit } from "../../redux/actions/unitMapAction";
import { UnitMapReducer } from "../../redux/reducers/unitMapReducer";
import { updateCurrMap } from "../../redux/actions/currMapActions";
import { updateCurrUnit } from "../../redux/actions/currUnitActions";
import { updateCurrLesson } from "../../redux/actions/currLessonActions";
import { updateCurrMapId } from "../../redux/actions/currMapIdActions";

import { getUnitLessons } from "../../redux/actions/unitLessonsActions";
import {
  getLessonPlans,
  postNewLessonPlan,
} from "../../redux/actions/lessonPlansActions";
import Constants from "../../resource/Constants";
import ImportContactsOutlinedIcon from "@material-ui/icons/ImportContactsOutlined";
import LeftCard from "../ebook/slots/LeftCard";
import { Alert, AlertTitle } from "@material-ui/lab";
import Slider from "@material-ui/core/Slider";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import ColorPicker from "./slots/ColorPicker"

import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import QuizPlannerDrawer from './slots/QuizPlannerDrawer';
import { listChpGet } from "../../redux/actions/chapterAction";
import { listTopGet } from "../../redux/actions/topicAction";
import { truncate } from "lodash";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const drawerWidth2 = 440;

function Alert2(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const localizer = momentLocalizer(moment);

const myEventsList = [
  {
    id: 0,
    title: "Unit 1: Foundations of number",
    allDay: true,
    start: new Date(2021, 3, 0),
    end: new Date(2021, 3, 1),
  },
  {
    id: 1,
    title: "Unit 2: Additions and Subtractions",
    start: new Date(2021, 3, 7),
    end: new Date(2021, 3, 10),
  },

  {
    id: 2,
    title: "Unit 3: DTS STARTS",
    start: new Date(2021, 4, 13, 0, 0, 0),
    end: new Date(2021, 4, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: "Unit 4: DTS ENDS",
    start: new Date(2020, 10, 6, 0, 0, 0),
    end: new Date(2020, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: "Unit 5: Some Event",
    start: new Date(2021, 3, 9, 0, 0, 0),
    end: new Date(2021, 3, 9, 0, 0, 0),
  },
  {
    id: 5,
    title: "Unit 6: Conference",
    start: new Date(2021, 3, 11),
    end: new Date(2021, 3, 13),
    desc: "Big conference for important people",
  },
  {
    id: 6,
    title: "Unit 7: Meeting",
    start: new Date(2021, 3, 12, 10, 30, 0, 0),
    end: new Date(2021, 3, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
  },
  {
    id: 7,
    title: "Unit 8: Lunch",
    start: new Date(2021, 3, 12, 12, 0, 0, 0),
    end: new Date(2021, 3, 12, 13, 0, 0, 0),
    desc: "Power lunch",
  },
  {
    id: 8,
    title: "Unit 9: Meeting",
    start: new Date(2021, 3, 12, 14, 0, 0, 0),
    end: new Date(2021, 3, 12, 15, 0, 0, 0),
  },
  {
    id: 9,
    title: "Unit 10: Happy Hour",
    start: new Date(2021, 3, 12, 17, 0, 0, 0),
    end: new Date(2021, 3, 12, 17, 30, 0, 0),
    desc: "Most important meal of the day",
  },
  {
    id: 10,
    title: "Unit 11: Dinner",
    start: new Date(2021, 3, 12, 20, 0, 0, 0),
    end: new Date(2021, 3, 12, 21, 0, 0, 0),
  },
  {
    id: 11,
    title: "Unit 12: Birthday Party",
    start: new Date(2021, 3, 13, 7, 0, 0),
    end: new Date(2021, 3, 13, 10, 30, 0),
  },
  {
    id: 12,
    title: "Unit 13: Late Night Event",
    start: new Date(2021, 3, 17, 19, 30, 0),
    end: new Date(2021, 3, 18, 2, 0, 0),
  },
  {
    id: 13,
    title: "Unit 14: Multi-day Event",
    start: new Date(2021, 3, 20, 19, 30, 0),
    end: new Date(2021, 3, 22, 2, 0, 0),
  },
  {
    id: 14,
    title: "Unit 15: Today",
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
];

const drawerWidth = 250;

const useStyles1 = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbar1: {
    paddingRight: 24,
    height: 40,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarIcon1: {
    top: "45vh",
    bottom: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft:70,
    backgroundColor: "#1976d2",
    color: "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "blue",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    scrollbarColor: "green",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    marginTop: 20,
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(4),
    justifyContent: "center",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  large: {
    width: "100%",
    height: 50,
    borderRadius: 0,
  },
}));

const useStyles3 = makeStyles({
  list: {
    width: drawerWidth2,
  },
  fullList: {
    width: 'auto',
  },
});

const config = {
  apiKey: Constants.Application.FIREBASE.API_KEY,
  authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
  //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
  storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}


function PreviewImageDialog(props) {
  const { imageUrl } = props

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          CurrentScale,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          // ZoomIn,
          // ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {/* <div style={{ padding: '0px 2px' }}>
                        <ZoomOut>
                            {(props) => (
                                <button
                                    style={{
                                        backgroundColor: '#357edd',
                                        border: 'none',
                                        borderRadius: '4px',
                                        color: '#ffffff',
                                        cursor: 'pointer',
                                        padding: '8px',
                                    }}
                                    onClick={props.onClick}
                                >
                                    Zoom out
                                </button>
                            )}
                        </ZoomOut>
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <CurrentScale>{(props) => <span>{`${Math.round(props.scale * 100)}%`}</span>}</CurrentScale>
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <ZoomIn>
                            {(props) => (
                                <button
                                    style={{
                                        backgroundColor: '#357edd',
                                        border: 'none',
                                        borderRadius: '4px',
                                        color: '#ffffff',
                                        cursor: 'pointer',
                                        padding: '8px',
                                    }}
                                    onClick={props.onClick}
                                >
                                    Zoom in
                                </button>
                            )}
                        </ZoomIn>
                    </div> */}
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <GoToPreviousPage>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: props.isDisabled ? '#96ccff' : '#357edd',
                      border: 'none',
                      borderRadius: '4px',
                      color: '#ffffff',
                      cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                      padding: '8px',
                    }}
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  >
                    Previous page
                  </button>
                )}
              </GoToPreviousPage>
            </div>
            <div style={{ padding: '0px 2px', width: '4rem' }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: '0px 2px' }}>
              / <NumberOfPages />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <GoToNextPage>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: props.isDisabled ? '#96ccff' : '#357edd',
                      border: 'none',
                      borderRadius: '4px',
                      color: '#ffffff',
                      cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                      padding: '8px',
                    }}
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  >
                    Next page
                  </button>
                )}
              </GoToNextPage>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const renderPage: RenderPage = (props: RenderPageProps) => (
    <div
      style={{
        width: '50%',
        height: '50%'
      }}>
      {props.canvasLayer.children}
      {props.width}
      {/* <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '50%',
            }}
        >
            <div
                style={{
                    color: 'rgba(0, 0, 0, 0.2)',
                    fontSize: `${8 * props.scale}rem`,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    transform: 'rotate(-45deg)',
                    userSelect: 'none',
                }}
            >
                Draft
            </div>
        </div> */}
      {props.annotationLayer.children}
      {props.textLayer.children}
    </div>
  );

  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: defaultTabs => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      // defaultTabs[0], // Bookmarks tab
      defaultTabs[0], // Thumbnails tab
    ],
  });


  const [open, setOpen] = React.useState(false);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState(imageUrl ? imageUrl : " ");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginLeft: 5, }}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{ borderRadius: 50 }}>
        <ImageOutlinedIcon />
      </Button>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">{"Guide Preview"}</DialogTitle>
          <DialogContent
            style={{ scrollbarWidth: "thin" }}
          >

            <div style={{ height: '750px', width: '100%' }}>
              {imageUrl ? (
                <Viewer
                  fileUrl={imageUrl}
                  plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance,

                  ]}
                  // height={'100%'}
                  defaultScale={SpecialZoomLevel.PageFit}
                />

                // <img
                //   src={imageUrl}
                //   alt="preview Image"
                //   width="100px"
                //   style={{
                //     alignSelf: "center",
                //     display: "flex",
                //     width: "100%",
                //     width: "100%",
                //   }}
                // />
              )
                :
                <Alert severity="warning">No preview image available!</Alert>
              }
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              close
            </Button>
          </DialogActions>
        </Dialog>
      </div>


    </div>


  );
}



function GuideImageDialog(props) {
  const { imageUrl } = props
  const [open, setOpen] = React.useState(false);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          CurrentScale,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          // ZoomIn,
          // ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {/* <div style={{ padding: '0px 2px' }}>
                        <ZoomOut>
                            {(props) => (
                                <button
                                    style={{
                                        backgroundColor: '#357edd',
                                        border: 'none',
                                        borderRadius: '4px',
                                        color: '#ffffff',
                                        cursor: 'pointer',
                                        padding: '8px',
                                    }}
                                    onClick={props.onClick}
                                >
                                    Zoom out
                                </button>
                            )}
                        </ZoomOut>
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <CurrentScale>{(props) => <span>{`${Math.round(props.scale * 100)}%`}</span>}</CurrentScale>
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <ZoomIn>
                            {(props) => (
                                <button
                                    style={{
                                        backgroundColor: '#357edd',
                                        border: 'none',
                                        borderRadius: '4px',
                                        color: '#ffffff',
                                        cursor: 'pointer',
                                        padding: '8px',
                                    }}
                                    onClick={props.onClick}
                                >
                                    Zoom in
                                </button>
                            )}
                        </ZoomIn>
                    </div> */}
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <GoToPreviousPage>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: props.isDisabled ? '#96ccff' : '#357edd',
                      border: 'none',
                      borderRadius: '4px',
                      color: '#ffffff',
                      cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                      padding: '8px',
                    }}
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  >
                    Previous page
                  </button>
                )}
              </GoToPreviousPage>
            </div>
            <div style={{ padding: '0px 2px', width: '4rem' }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: '0px 2px' }}>
              / <NumberOfPages />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <GoToNextPage>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: props.isDisabled ? '#96ccff' : '#357edd',
                      border: 'none',
                      borderRadius: '4px',
                      color: '#ffffff',
                      cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                      padding: '8px',
                    }}
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  >
                    Next page
                  </button>
                )}
              </GoToNextPage>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const renderPage: RenderPage = (props: RenderPageProps) => (
    <div
      style={{
        width: '50%',
        height: '50%'
      }}>
      {props.canvasLayer.children}
      {props.width}
      {/* <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '50%',
            }}
        >
            <div
                style={{
                    color: 'rgba(0, 0, 0, 0.2)',
                    fontSize: `${8 * props.scale}rem`,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    transform: 'rotate(-45deg)',
                    userSelect: 'none',
                }}
            >
                Draft
            </div>
        </div> */}
      {props.annotationLayer.children}
      {props.textLayer.children}
    </div>
  );

  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: defaultTabs => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      // defaultTabs[0], // Bookmarks tab
      defaultTabs[0], // Thumbnails tab
    ],
  });

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    props.imageUrl(downloadURL)
    // setUploadProgress(100)
    // setIsUploading(false)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{ scrollbarWidth: "thin" }}
    >
      {/* <Chip
        label="Guide"
        clickable
        color="primary"
      /> */}

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          <label
            style={{
              fontFamily: "Poppins, Helvetica, sans-serif",
              backgroundColor: "lightsteelblue",
              color: "black",
              padding: 5,
              borderRadius: 4,
              cursor: "pointer",
              width: '100%',
              textAlign: "center",

            }}
          >
            <Typography style={{ width: 250, padding: 5 }}>
              Browse Guide Image
            </Typography>

            <FileUploader
              hidden
              // accept="image/*"
              storageRef={firebase.storage().ref("images")}
              onUploadStart={handleUploadStart}
              onUploadError={handleUploadError}
              onUploadSuccess={handleUploadSingleSuccess}
              onProgress={handleProgress}
            />
          </label>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {progress ? <CircularProgress /> : <></>}
          </div>

          {ImageDownloadUrls ?
            <div style={{ marginLeft: 5, marginTop: 10 }} onClick={handleClickOpen}>
              <ImageOutlinedIcon style={{ fontsize: 80 }} />
            </div>
            :
            <></>
          }

        </div>


        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
          >
            {/* <DialogTitle id="alert-dialog-title">{"Guide Preview"}</DialogTitle> */}
            <DialogContent
              style={{ scrollbarWidth: "thin" }}
            >

              <div style={{ height: "750px", width: '100%' }}>
                {ImageDownloadUrls && (
                  <Viewer
                    fileUrl={ImageDownloadUrls}
                    plugins={[
                      // Register plugins
                      defaultLayoutPluginInstance,

                    ]}
                    defaultScale={SpecialZoomLevel.PageFit}
                  />

                  // <img
                  //   src={ImageDownloadUrls}
                  //   alt="preview Image"
                  //   width="100px"
                  //   style={{
                  //     alignSelf: "center",
                  //     display: "flex",
                  //     width: "100%",
                  //     width: "100%",
                  //   }}
                  // />
                )}
              </div>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                close
              </Button>
            </DialogActions>
          </Dialog>
        </div>


      </div>

    </div>
  );
}









function TemporaryDrawer(props) {
  const classes = useStyles3();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const anc = 'right';

  return (
    <div
      style={{
        marginLeft: 10
      }}>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
      {/* {['right'].map((anchor) => ( */}
      <React.Fragment key={anc}>
        {/* <Button onClick={toggleDrawer(anc, true)} variant="outlined" color="primary">QUIZ</Button> */}
        <Chip
          label="Quiz"
          clickable
          color="primary"
          onClick={toggleDrawer(anc, true)}
        />
        <Drawer anchor={anc} open={state[anc]} onClose={toggleDrawer(anc, false)}>
          {/* {list(anc)} */}
          <QuizPlannerDrawer lsnPlanId={props.lsnPlanId} subId={props.subId} />
        </Drawer>
      </React.Fragment>
      {/* ))} */}
    </div>
  );
}

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const CustomizedBreadcrumbs = (props) => {
  const { unitNumber, unitName, lessonName } = props;
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <StyledBreadcrumb
        component="a"
        href="#"
        label={`${unitNumber} ${unitName}`}
        icon={<HomeIcon fontSize="small" />}
        onClick={handleClick}
      />
      <StyledBreadcrumb
        component="a"
        href="#"
        label={lessonName}
        onClick={handleClick}
      />
      <StyledBreadcrumb
        label="Lesson Plans"
        deleteIcon={<ExpandMoreIcon />}
        onClick={handleClick}
        onDelete={handleClick}
      />
    </Breadcrumbs>
  );
};

const MyCalendar = (props) => {
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        eventPropGetter={(event, start, end, isSelected) => {
          console.log("event id " + event.id);
          const id = parseInt(event.id);
          let backgroundColor = "#FF9AA2";
          if ((id + 1) % 2 == 0) {
            backgroundColor = "#FFDAC1"; //'#FFB7B2'
          } else if ((id + 1) % 5 == 0) {
            backgroundColor = "#B5EAD7";
          }
          var style = {
            backgroundColor: backgroundColor,
            borderRadius: "0px",
            opacity: 0.8,
            color: "black",
            border: "0px",
            display: "block",
          };
          return {
            style: style,
          };
        }}
      />
    </div>
  );
};
// const useStyles = makeStyles({
//   root: {
//     flexGrow: 1,
//   },
// });

function MyDateRangePickerCalendar() {
  const today = new Date();
  let last = today;
  last.setDate(today.getDate() + 12);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(last);
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };
  return (
    <div>
      {/*       
      <p>Selected start date: {startDate ? format(startDate, 'dd MMM yyyy', { locale: enGB }) : 'none'}.</p>
      <p>Selected end date: {endDate ? format(endDate, 'dd MMM yyyy', { locale: enGB }) : 'none'}.</p>
      <p>Currently selecting: {focus}.</p> */}

      <DateRangePickerCalendar
        startDate={startDate}
        endDate={endDate}
        focus={focus}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        onFocusChange={handleFocusChange}
        locale={enGB}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: 'center',
    flexWrap: "wrap",
    "& > *": {
      // margin: theme.spacing(0.5),
    },
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  shape2: {
    backgroundColor: theme.palette.secondary.dark,
    width: 20,
    height: 20,
  },
  shape3: {
    backgroundColor: theme.palette.primary.main,
    width: 5,
    height: 20,
  },
  shape4: {
    backgroundColor: theme.palette.secondary.dark,
    width: 5,
    height: 20,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const SimplePlan = (props) => {
  const classes = useStyles();

  const currPlan = useSelector((state) => state.currPlan.currPlan);
  const currLesson = useSelector((state) => state.currLesson.currLesson);

  console.log("currPlan at simplePlan", currPlan)

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(null);
  const [openLessonDialog, setOpenLessonDialog] = useState(null);

  const [openEditPlanDialog, setOpenEditPlanDialog] = useState(false);
  const [openSnackSuccess, setOpenSnackSuccess] = React.useState(false);

  const [overvMins, setOvervMins] = useState(currPlan ? currPlan.overview_min_num : 0)
  const [actvMins, setActvMins] = useState(currPlan ? currPlan.activity_min_num : 0)
  const [conMins, setConMins] = useState(currPlan ? currPlan.conclusion_min_num : 0)
  const [asMins, setAsMins] = useState(currPlan ? currPlan.quiz_min_num : 0)
  const [startingPage, setStartingPage] = useState("");
  const [endingPage, setEndingPage] = useState("");
  const [tpc, setTpc] = useState("");
  const [planGuideImage, setPlanGuideImage] = useState(currPlan ? currPlan.plan_image_url : "")

  useEffect(() => {

    // get current lesson chapter id
    const chapId = currLesson.chapter_id;

    return (() => {
      //
    })
  }, [])

  const handlePageLeftChange = (event) => {
    console.log("called handlePriceChange");

    const name = event.target.name;
    const value = event.target.value;
    currPlan.first_page = value;
    dispatch(updateCurrLessonPlan(currPlan));
    setStartingPage(value);
  };

  const handlePageRightChange = (event) => {
    console.log("called handleAuthorChange");

    const name = event.target.name;
    const value = event.target.value;
    currPlan.last_page = value;
    dispatch(updateCurrLessonPlan(currPlan));
    setEndingPage(value);
  };

  const handleTpcChange = (event) => {
    console.log("called handleTpcChange");

    const name = event.target.name;
    const value = event.target.value;
    currPlan.topic_id = value;
    dispatch(updateCurrLessonPlan(currPlan));
    setTpc(value)
  };


  const topDetailsGet = useSelector((state) => state.topDetails);
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;
  console.log(`topicdetails ${JSON.stringify(TopDetails)}`)

  const handleEditPlanOpen = () => {
    setOpenEditPlanDialog(true);
  };

  const handleEditPlanClose = () => {
    setOpenEditPlanDialog(false);
  };

  const plan = props.plan;
  const index = props.index;

  console.log(`single plan in plan box ${JSON.stringify(plan)}`);

  const planTitle = currPlan ? currPlan.lesson_plan_name : "n/a";

  const planOverviewTxt = currPlan ? currPlan.overview_text : "n/a";
  const planOverview = currPlan ? currPlan.overview_min_num : 0;

  const planActvTxt = currPlan ? currPlan.activity_text : "n/a";
  const planActv = currPlan ? currPlan.activity_min_num : 0;

  const planAssmTxt = currPlan ? currPlan.quiz_text : "n/a";
  const planAssm = currPlan ? currPlan.quiz_min_num : 0;

  const planConTxt = currPlan ? currPlan.conclusion_text : "n/a";
  const planCon = currPlan ? currPlan.conclusion_min_num : 0;

  const startingPageNum = currPlan ? currPlan.first_page : 0;
  const endingPageNum = currPlan ? currPlan.last_page : 0;

  const dispatch = useDispatch();

  const handleCloseSnackSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackSuccess(false);
  };

  const handleAddLessonOpen = () => {
    setOpenLessonDialog(true);
  };

  const handleChangeTitle = (event) => {
    currPlan.lesson_plan_name = event.target.value;
    dispatch(updateCurrLessonPlan(currPlan));
  };

  const handleChangeOverview = (event) => {
    currPlan.overview_min_num = event.target.value;
    dispatch(updateCurrLessonPlan(currPlan));
    setOvervMins(event.target.value)
  };

  const handleChangeOverviewTxt = (event) => {
    currPlan.overview_text = event.target.value;
    dispatch(updateCurrLessonPlan(currPlan));
  };

  const handleChangeActivity = (event) => {
    currPlan.activity_min_num = event.target.value;
    dispatch(updateCurrLessonPlan(currPlan));
    setActvMins(event.target.value)
  };

  const handleChangeActivityTxt = (event) => {
    currPlan.activity_text = event.target.value;
    dispatch(updateCurrLessonPlan(currPlan));
  };

  const handleChangeAssessment = (event) => {
    currPlan.quiz_min_num = event.target.value;
    dispatch(updateCurrLessonPlan(currPlan));
    setAsMins(event.target.value)
  };

  const handleChangeAssessmentTxt = (event) => {
    currPlan.quiz_text = event.target.value;
    dispatch(updateCurrLessonPlan(currPlan));
  };

  const handleChangeConclusion = (event) => {
    currPlan.conclusion_min_num = event.target.value;
    dispatch(updateCurrLessonPlan(currPlan));
    setConMins(event.target.value)
  };

  const handleChangeConclusionTxt = (event) => {
    currPlan.conclusion_text = event.target.value;
    dispatch(updateCurrLessonPlan(currPlan));
  };

  const handleEditPlan = async () => {
    const body = {
      lesson_plan_num: 1,
      overview_min_num: currPlan.overview_min_num,
      overview_text: currPlan.overview_text,
      activity_min_num: currPlan.activity_min_num,
      activity_text: currPlan.activity_text,
      quiz_min_num: currPlan.quiz_min_num,
      quiz_text: currPlan.quiz_text,
      conclusion_min_num: currPlan.conclusion_min_num,
      conclusion_text: currPlan.conclusion_text,
      lesson_plan_name: currPlan.lesson_plan_name,
      first_page: currPlan.first_page,
      last_page: currPlan.last_page,
      topic_id: currPlan.topic_id,
      plan_image_url: planGuideImage
    };

    console.log(`currPlan during update ${JSON.stringify(currPlan)}`);
    console.log(`currPlan during update body ${JSON.stringify(body)}`);
    // call api
    await MapUnitAPI.postUpdateLessonPlan(currPlan.id, body);

    handleEditPlanClose();
    // show success snackbar
    setOpenSnackSuccess(true);
  };

  const updatePlanGuideImage = (imgUrl) => {
    setPlanGuideImage(imgUrl)
  }

  const tpcId = (currPlan) ? currPlan.topic_id : 0;

  const Duration =
    parseInt((overvMins) ? overvMins : 0) +
    parseInt((actvMins) ? actvMins : 0) +
    parseInt((asMins) ? asMins : 0) +
    parseInt((conMins) ? conMins : 0);

  return (
    <div style={{ margin: 10 }}>
      <Accordion expanded={open}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => {
            setOpen(!open);

            // set current plan
            dispatch(updateCurrLessonPlan(props.plan));
          }}
        >
          <Typography className={classes.heading}>
            {plan.lesson_plan_name}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',

            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
                width: '49%'
              }}>
                {/* overview */}
                <div style={{ marginBottom: 20 }}>
                  <Typography variant="h5">Overview</Typography>
                  <Typography variant="body2">{planOverviewTxt}</Typography>
                </div>

                {/* activity */}
                <div style={{ marginBottom: 10 }}>
                  <Typography variant="h5">Activity</Typography>
                  <Typography variant="body2">{planActvTxt}</Typography>
                </div>
              </div>

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
                width: '49%'
              }}>

                {/* conclusion */}
                <div style={{ marginBottom: 20 }}>
                  <Typography variant="h5">Conclusion</Typography>
                  <Typography variant="body2">{planConTxt}</Typography>
                </div>

                {/* assessment */}
                <div style={{ marginBottom: 10 }}>
                  <Typography variant="h5">Assessment</Typography>
                  <Typography variant="body2">{planAssmTxt}</Typography>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <LibraryBooksIcon
                style={{
                  height: 16,
                }}
              />
              &nbsp;
              <Typography
                variant="body2"
                style={{
                  marginRight: 10,
                  fontSize: 12,
                }}
              >
                {plan.overview_min_num} min Overview
              </Typography>
              <Divider orientation="vertical" flexItem />
              <PlaylistAddCheckIcon
                style={{
                  marginLeft: 10,
                  height: 16,
                }}
              />
              &nbsp;
              <Typography
                variant="body2"
                style={{
                  marginRight: 10,
                  fontSize: 12,
                }}
              >
                {plan.activity_min_num} mins Activity
              </Typography>
              <Divider orientation="vertical" flexItem />
              <LocalOfferIcon
                style={{
                  marginLeft: 10,
                  height: 16,
                }}
              />
              &nbsp;
              <Typography
                variant="body2"
                style={{
                  marginRight: 10,
                  fontSize: 12,
                }}
              >
                {plan.conclusion_min_num} min Conclusion
              </Typography>
              <Divider orientation="vertical" flexItem />
              <LocalOfferIcon
                style={{
                  marginLeft: 10,
                  height: 16,
                }}
              />
              &nbsp;
              <Typography
                variant="body2"
                style={{
                  marginRight: 10,
                  fontSize: 12,
                }}
              >
                {plan.quiz_min_num} min Assessment
              </Typography>
              <Divider orientation="vertical" flexItem />
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Chip
                  label="Edit"
                  clickable
                  color="secondary"
                  onClick={handleEditPlanOpen}
                />
              </div>

              <Divider orientation="vertical" flexItem />
              <TemporaryDrawer lsnPlanId={plan.id} subId={0} />
              <Divider orientation="vertical" flexItem style={{ marginLeft: 10 }} />
              <PreviewImageDialog imageUrl={planGuideImage} />
              <Divider orientation="vertical" flexItem style={{ marginLeft: 10 }} />


            </div>

            {/* EDIT PLAN DIALOG */}
            <Dialog
              open={openEditPlanDialog}
              onClose={handleEditPlanClose}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="lg"
            >
              <DialogTitle id="form-dialog-title">Edit Plan</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Edit Plan lets you Add a unit with time and pacing.
                </DialogContentText>
                {/* Title */}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexDirection: "row",
                      width: '80%',
                    }}
                  >
                    <EditIcon style={{ height: 16 }} />

                    <TextField
                      autoFocus
                      // defaultValue={unitTitleEVar}
                      defaultValue={planTitle}
                      onChange={handleChangeTitle}
                      margin="dense"
                      id="name"
                      label="Title"
                      type="text"
                      fullWidth
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  </div>

                  <div
                    style={{ marginRight: 10, marginLeft: 10, alignSelf: 'flex-end', textAlign: 'right' }}
                  >
                    <h5>Duration: {Duration} Mins</h5>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* LEFT */}
                  <div
                    style={{
                      width: "49%",
                    }}
                  >
                    {/* Overview */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        autoFocus
                        // defaultValue={unitTitleEVar}
                        defaultValue={planOverviewTxt}
                        onChange={handleChangeOverviewTxt}
                        margin="dense"
                        id="name"
                        label="Overview Guide"
                        type="text"
                        multiline={true}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        style={{
                          marginLeft: 10,
                        }}
                      />
                      <div
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        <TextField
                          autoFocus
                          // defaultValue={unitLessonsEVar}
                          defaultValue={planOverview}
                          onChange={handleChangeOverview}
                          margin="dense"
                          id="Overview"
                          label="Overview mins"
                          type="number"
                          fullWidth
                          variant="outlined"
                        />
                      </div>
                    </div>

                    {/* Activity */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        autoFocus
                        // defaultValue={unitTitleEVar}
                        defaultValue={planActvTxt}
                        onChange={handleChangeActivityTxt}
                        margin="dense"
                        id="name"
                        label="Activity Guide"
                        type="text"
                        multiline={true}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        style={{
                          marginLeft: 10,
                        }}
                      />

                      <div
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        <TextField
                          autoFocus
                          // defaultValue={unitDaysEVar}
                          defaultValue={planActv}
                          onChange={handleChangeActivity}
                          margin="dense"
                          id="Activity"
                          label="Activity mins"
                          type="number"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>

                  {/* RIGHT */}
                  <div
                    style={{
                      width: "49%",
                    }}
                  >
                    {/* Conclusion */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        autoFocus
                        // defaultValue={unitTitleEVar}
                        defaultValue={planConTxt}
                        onChange={handleChangeConclusionTxt}
                        margin="dense"
                        id="name"
                        label="Conclusion Guide"
                        type="text"
                        multiline={true}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        style={{
                          marginLeft: 10,
                        }}
                      />

                      <div
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        <TextField
                          autoFocus
                          // defaultValue={unitDaysEVar}
                          defaultValue={planAssm}
                          onChange={handleChangeAssessment}
                          margin="dense"
                          id="Assessment"
                          label="Assessment mins"
                          type="number"
                          variant="outlined"
                        />
                      </div>
                    </div>

                    {/* Assessment */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        autoFocus
                        // defaultValue={unitTitleEVar}
                        defaultValue={planAssmTxt}
                        onChange={handleChangeAssessmentTxt}
                        margin="dense"
                        id="name"
                        label="Assessment Guide"
                        type="text"
                        multiline={true}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        style={{
                          marginLeft: 10,
                        }}
                      />

                      <div
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        <TextField
                          autoFocus
                          // defaultValue={unitDaysEVar}
                          defaultValue={planCon}
                          onChange={handleChangeConclusion}
                          margin="dense"
                          id="Conclusion"
                          label="Conclusion mins"
                          type="number"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* page range */}




                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 10,
                    alignContent: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 10,
                      alignContent: "center",
                    }}
                  >
                    <DateRangeOutlinedIcon style={{ marginRight: 10 }} />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            marginBottom: 20,
                            marginRight: 5,
                          }}
                        >
                          <FormControl>
                            <TextField
                              id="outlined-basic"
                              label="E-Book Start Page"
                              variant="outlined"
                              type="number"
                              style={{
                                width: 170,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                              defaultValue={startingPageNum}
                              onChange={handlePageLeftChange}
                            />
                            {/* 
                          <Typography>
                            <span
                              style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Enter{" "}
                              <span
                                style={{
                                  color: "#464E5F",
                                  fontWeight: "600",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                Starting Page No
                              </span>
                            </span>
                          </Typography> */}
                          </FormControl>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            marginBottom: 20,
                            marginLeft: 5,
                          }}
                        >
                          <FormControl>
                            <TextField
                              id="outlined-basic"
                              label="E-Book End Page"
                              variant="outlined"
                              style={{
                                width: 170,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                              type="number"
                              defaultValue={endingPageNum}
                              onChange={handlePageRightChange}
                            />
                            {/* 
                          <Typography>
                            <span
                              style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Enter{" "}
                              <span
                                style={{
                                  color: "#464E5F",
                                  fontWeight: "600",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                Ending Page No
                              </span>
                            </span>
                          </Typography> */}
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>


                  {
                    loadingTop && topDetailsGet ? (
                      <></>
                    ) : (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <FormControl variant="outlined" className={classes.formControl}>


                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            style={{ fontSize: 13, color: "#464E5F" }}
                          >
                            Choose Topic
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            defaultValue={tpcId}
                            onChange={handleTpcChange}
                            label="Topic"
                            style={{ fontSize: 13, color: "#464E5F" }}
                          >
                            {loadingTop && topDetailsGet ? (
                              console.log("loading trueeee")
                            ) : TopDetails.data && TopDetails.data.length != 0 ? (
                              TopDetails.data.map((list, index) => {
                                let selected = false;

                                if (tpcId == list.topic_id)
                                  selected = true

                                return (
                                  <MenuItem
                                    selected={selected}
                                    key={index}
                                    value={list.topic_id}
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}

                                  >
                                    {list.topic_title}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                              >
                                Please Select Lesson
                              </MenuItem>
                            )}
                          </Select>
                          {/* <span
                            style={{
                              fontSize: 12,
                              color: "#464E5F",
                              marginLeft: 5,
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            Please select{" "}
                            <span
                              style={{
                                color: "#464E5F",
                                fontWeight: "600",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                                fontSize: 12,
                              }}
                            >
                              Topic
                            </span>
                          </span> */}
                        </FormControl>
                      </div>
                    )

                  }

                  <div>
                    <GuideImageDialog imageUrl={(imgUrl) => updatePlanGuideImage(imgUrl)} />
                  </div>

                </div>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleEditPlanClose} color="primary">
                  Cancel
                </Button>

                <Button onClick={handleEditPlan} color="primary">
                  Save
                </Button>

                {/* <Button color="warning">Delete</Button> */}
              </DialogActions>
            </Dialog>
            <Snackbar
              open={openSnackSuccess}
              autoHideDuration={6000}
              onClose={handleCloseSnackSuccess}
            >
              <Alert onClose={handleCloseSnackSuccess} severity="success">
                Record saved successfully!
              </Alert>
            </Snackbar>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const SimpleAccordion = (props) => {
  const [open, setOpen] = useState(false);
  const [plans, setPlans] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(null);
  const [openLessonDialog, setOpenLessonDialog] = useState(null);
  const [title, setTitle] = useState("");
  const [overviewMins, setOverViewMins] = useState(0);
  const [activityMins, setActivityMins] = useState(0);
  const [assessmentMins, setAssessmentMins] = useState(0);

  //post lessons

  console.log("props at accordian", props.lessonData);
  const classes = useStyles();

  const rectangle = <div className={classes.shape} />;
  const rectangle2 = <div className={classes.shape2} />;
  const rectangle3 = <div className={classes.shape3} />;
  const rectangle4 = <div className={classes.shape4} />;
  const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;

  const currUnit = useSelector((state) => state.currUnit.currUnit);
  const currLesson = useSelector((state) => state.currLesson.currLesson);
  const lessonPlans = useSelector((state) => state.lessonPlans.lessonPlans);

  useEffect(() => {
    const lessonId = currLesson ? currLesson.id : 0;
    // loadLessonPlans(lessonId)
    return <></>;
  }, []);

  const loadLessonPlans = async (lessonId) => {
    console.log("lessonId at api loadlessonplans", lessonId);
    const lessonPlans = await MapUnitAPI.getLessonPlans(lessonId);
    console.log("respone of lessonPlans", lessonPlans.data.data);
    setPlans(lessonPlans.data.data);
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleAddLessonClose = () => {
    setOpenLessonDialog(false);
  };

  const handleOpenEdit = () => {
    setOpenDialog(true);
  };

  const handleCloseEdit = () => {
    setOpenDialog(false);
  };

  const Plans = () => {
    const lessonPlans = useSelector((state) => state.lessonPlans.lessonPlans);

    if (lessonPlans && lessonPlans.length) {
      return lessonPlans.map((plan, index) => {
        return <SimplePlan plan={plan} currPlan={props.currPlan} index={index} />;
      });
    } else {
      return (
        <>
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Alert severity="warning">No Plans added yet — add them now!</Alert>
          </div>
        </>
      );
    }
  };

  const Lesson = () => {
    return (
      <div>
        <Accordion expanded={open}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {/* {props.data ? */}

            {currLesson ? (
              // props && props.lessonData ?

              <div
                style={{
                  display: "flex",
                }}
              >
                {rectangle}
                <Typography
                  variant="h1"
                  style={{
                    marginLeft: 10,
                    fontSize: 18,
                  }}
                  className={classes.heading}
                >
                  {currLesson.name_text}
                </Typography>
              </div>
            ) : (
              // :
              // <></>
              <></>
            )}
          </AccordionSummary>

          <AccordionDetails>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <LibraryBooksIcon
                style={{
                  height: 16,
                }}
              />
              &nbsp;
              {currLesson ? (
                <Typography
                  variant="body2"
                  style={{
                    marginRight: 10,
                    fontSize: 12,
                  }}
                // onClick={() => { loadLessonPlans(props.lessonData.id) }}
                >
                  {currLesson != null && plans && plans.data && plans.data.data
                    ? plans.data.data.length
                    : 0}{" "}
                  Lessons Plans
                </Typography>
              ) : (
                // props && props.UnitMap && props.UnitMap.data && props.UnitMap.data.Units && props.UnitMap.data.Units.length ?
                //   <Typography
                //     variant="body2"
                //     style={{
                //       marginRight: 10,
                //       fontSize: 12
                //     }}>
                //     {props.UnitMap.data.Units[0].lesson_count} Lesson Plans
                // </Typography>
                //   :
                <Typography
                  variant="body2"
                  style={{
                    marginRight: 10,
                    fontSize: 12,
                  }}
                >
                  0 Lessons Plans
                </Typography>
              )}
              <Divider orientation="vertical" flexItem />
              <PlaylistAddCheckIcon
                style={{
                  marginLeft: 10,
                  height: 16,
                }}
              />
              &nbsp;
              <Typography
                variant="body2"
                style={{
                  marginRight: 10,
                  fontSize: 12,
                }}
              >
                19 Standards Covered
              </Typography>
              <Divider orientation="vertical" flexItem />
              <LocalOfferIcon
                style={{
                  marginLeft: 10,
                  height: 16,
                }}
              />
              &nbsp;
              <Typography
                variant="body2"
                style={{
                  marginRight: 10,
                  fontSize: 12,
                }}
              >
                2 Tags
              </Typography>
              <Divider orientation="vertical" flexItem />
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Chip
                  label="Edit Plan"
                  clickable
                  color="primary"
                  onClick={handleOpenEdit}
                />
              </div>
              <Divider orientation="vertical" flexItem />
            </div>
          </AccordionDetails>

          {/* {props && props.plans && props.plans.length != 0 ? */}

          {/* this is lesson plan */}

          {/* :
          <></>

        } */}
        </Accordion>

        {/* 
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion> */}
      </div>
    );
  };
  return currLesson ? <Plans /> : <></>;
};

const LessonPlan = () => {
  return <div>Lesson Plans</div>;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        width: "100%",
      }}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

const OverviewBox = () => {
  const classes = useStyles();

  return (
    // <div className={classes.root} style={{
    //   width: '100%'
    // }}>
    //   <Grid container spacing={3}>

    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>
    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>

    //   </Grid>

    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <LibraryBooksIcon />
        &nbsp;
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="body2"
          style={
            {
              // marginLeft: 10
            }
          }
        >
          Course overview
        </Typography>
      </div>
      <div style={{}}>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="body2"
          style={
            {
              // marginLeft: 10
            }
          }
        >
          (32 lessons)
        </Typography>
      </div>
    </div>

    // </div>
  );
};

const StandardsBox = () => {
  const classes = useStyles();

  return (
    // <div className={classes.root} style={{
    //   width: '100%'
    // }}>
    //   <Grid container spacing={3}>

    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>
    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>

    //   </Grid>

    <>
      <Typography
        // align="center"
        color="textPrimary"
        gutterBottom
        variant="body2"
        style={
          {
            // marginLeft: 10
          }
        }
      >
        CURRICULUM STANDARDS
      </Typography>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <CheckCircleIcon />
          &nbsp;
          <Typography
            // align="center"
            color="textPrimary"
            gutterBottom
            variant="body2"
            style={
              {
                // marginLeft: 10
              }
            }
          >
            Missouri State Standards - Grade 1 - Mathematics
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <CheckCircleIcon />
          &nbsp;
          <Typography
            // align="center"
            color="textPrimary"
            gutterBottom
            variant="body2"
            style={
              {
                // marginLeft: 10
              }
            }
          >
            HSD Student Friendly Standards - 1st Grade - Maths
          </Typography>
        </div>
      </div>
    </>
    // </div>
  );
};

const UnitplanBox = (props) => {
  const classes = useStyles();
  const [openlesDialog, setOpenLesDialog] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);

  const [lessonSnackOpen, setLessonSnackOpen] = useState(false);

  //add Lesson values

  const [lessName, setlessName] = useState("");
  const [lessDesc, setLessDesc] = useState("");
  const [lessCount, setLessCount] = useState(0);
  const [lessMins, setLessMins] = useState(" ");
  const [lessChap, setLessChap] = useState("")

  const rectangle = <div className={classes.shape} />;
  const rectangle2 = <div className={classes.shape2} />;
  const rectangle3 = <div className={classes.shape3} />;
  const rectangle4 = <div className={classes.shape4} />;
  const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;

  const dispatch = useDispatch();


  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const instId = query.get("inst_id");
    const board_id = query.get("board_id");
    const clsStd = query.get("class_std");
    const subId = query.get("sub_id")

    console.log("query params at unit list", instId, board_id, clsStd, subId)

    dispatch(listChpGet(subId, board_id))

    return (() => {
      //
    })
  }, [])

  const currUnit = useSelector((state) => state.currUnit.currUnit);
  const currLesson = useSelector((state) => state.currLesson.currLesson);

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;


  {
    loadingChap && chapDetails ?
      console.log("data is loading")
      :
      console.log("chapater details at Unit list", chapDetails)
  }

  {
    props && props.UnitMap ? (
      console.log("unit map props at ", props.UnitMap)
    ) : (
      <></>
    );
  }

  const handleLessonSnackClose = () => {
    setLessonSnackOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddLessonOpen = () => {
    if (currUnit) setOpenLesDialog(true);
  };

  const handleAddLessonClose = () => {
    setOpenLesDialog(false);
  };

  const handleLessTitleChange = (event) => {
    const { name, value } = event.target;
    setlessName(value);
  };

  const handleLesDescChange = (event) => {
    const { name, value } = event.target;
    setLessDesc(value);
  };

  const handleLesCountChange = (event) => {
    const { name, value } = event.target;
    setLessCount(value);
  };

  const handleLesMinsChange = (event) => {
    const { name, value } = event.target;
    setLessMins(value);
  };

  const handleColorChange = (color) => {

    setLessMins(color);
  };

  const handleChapter = (event) => {
    const { name, value } = event.target
    setLessChap(value);
  };

  /*
    const getUnitLessonsData = async (unitId) => {
      // getUnitLessons
  
      const lessons = await MapUnitAPI.getUnitLessons(unitId);
  
      console.log("lessons at unitplan box", lessons);
      props.lessonData(lessons);
    };
  */
  const postAddNewLesson = async () => {
    const dataObj = {
      map_unit: currUnit.id,
      name_text: lessName,
      name_less: lessDesc,
      lesson_count: lessCount,
      chapter_id: lessChap,
      minutes_num: 0,
      // need to add color
    };

    console.log(`post add lesson body params ${JSON.stringify(dataObj)}`);

    const resp = await MapUnitAPI.postAddLesson(dataObj);
    console.log("resp at post add new lesson", resp);
    handleAddLessonClose();

    // show snackbar
    setLessonSnackOpen(true);

    // load lessons
    dispatch(getUnitLessons(currUnit.id));
  };

  const loadLessonPlans = async (lessonId) => {
    console.log("lessonId at api loadlessonplans", lessonId);
    const lessonPlans = await MapUnitAPI.getLessonPlans(lessonId);
    console.log("respone of lessonPlans", lessonPlans.data.data);
    // setPlans(lessonPlans.data.data)
  };

  console.log(`lessondata in unitplanbox ${JSON.stringify(props.lessonsData)}`);

  /*
  const UnitData = () => {
    if (props && props.UnitMap && props.UnitMap.data) {

      return props.UnitMap.data.Units.map((units, index) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: "center",
            justifyContent: "flex-start",

          }}
            onClick={async () => {
              // props.sendUnit(units) 

              await new Promise((resolve, reject) => {
                dispatch(updateCurrUnit(units))
                resolve();
              })

              // done waiting..
              getUnitLessonsData(units.map_unit_id)

            }}
          >
            {index % 2 == 0 ?
              <>
                {rectangle3}&nbsp;
                {rectangle2}
              </>
              :
              <>
                {rectangle4}&nbsp;
                {rectangle}
              </>
            }
            <Typography
              // align="center"
              color="textPrimary"
              gutterBottom
              variant="body2"
              style={{
                margin: 10
              }}


            >
              {units.unit_title_text}
            </Typography>
          </div>




        )
      })


    } else {
      return (
        <></>
      )
    }
  }
  */

  const LessonsData = () => {


    if (props && props.lessonsData && props.lessonsData.length) {
      console.log(`lessonsData ${JSON.stringify(props.lessonsData)}`)
      return props.lessonsData.map((lesson, index) => {

        let color = '#FFFFFF';
        if (currLesson && currLesson.id == lesson.id)
          color = '#F5F5F5'
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              justifyContent: "flex-start",
              cursor: "pointer",
              background: color
            }}
            onClick={async () => {
              // props.sendUnit(units)

              await new Promise((resolve, reject) => {
                // dispatch(updateCurrUnit(units))

                dispatch(updateCurrLesson(lesson));

                // get lesson plans
                dispatch(getLessonPlans(lesson.id));

                // fetch topics of chapter
                dispatch(listTopGet(lesson.chapter_id))
                resolve();
              });

              // done waiting..
              // getUnitLessonsData(units.map_unit_id)
              // loadLessonPlans(lesson.id)
            }}
          >
            {index % 2 == 0 ? (
              <>
                {rectangle3}&nbsp;
                {rectangle2}
              </>
            ) : (
              <>
                {rectangle4}&nbsp;
                {rectangle}
              </>
            )}
            <Typography
              // align="center"
              color="textPrimary"
              gutterBottom
              variant="body2"
              style={{
                margin: 10,
              }}
            >
              {lesson.name_text}
            </Typography>
          </div>
        );
      });
    } else {
      return (
        <Alert severity="info">
          <AlertTitle>No Lessons</AlertTitle>
          You have not added any lessons yet!
        </Alert>
      );
    }
  };




  return (
    // <div className={classes.root} style={{
    //   width: '100%'
    // }}>
    //   <Grid container spacing={3}>

    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>
    //     <Grid item xs={6}>
    //       <Paper className={classes.paper}>xs=6</Paper>
    //     </Grid>

    //   </Grid>
    <div
      style={{
        padding: 20,
      }}
    >
      <div
        style={{
          // display: 'flex',
          width: "100%",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography
          // align="center"
          color="textPrimary"
          gutterBottom
          variant="body2"
        >
          LESSONS
        </Typography>

        <LessonsData />
      </div>


      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        {/*           
          <Button variant="contained"
            style={{
              alignSelf: 'flex-end',
              display: 'flex',
              marginBottom: 20,
              marginLeft: 10,
              justifyContent: "flex-end"
            }}
            onClick={handleAddLessonOpen}
          >
            Add Lesson
            </Button> */}

        <Chip
          clickable
          onClick={handleAddLessonOpen}
          color="primary"
          label="Add Lesson"
        />
      </div>

      {/* add Lesson DIALOG */}
      <Dialog
        open={openlesDialog}
        onClose={handleAddLessonClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Lesson</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Unit Map lets you setup a unit with time and pacing.
          </DialogContentText>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <EditIcon style={{ height: 16 }} />

            <TextField
              autoFocus
              // defaultValue={unitTitleEVar}
              onChange={handleLessTitleChange}
              margin="dense"
              id="name"
              label="Title"
              type="text"
              fullWidth
              style={{
                marginLeft: 10,
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <EditIcon style={{ height: 16 }} />

            <TextField
              autoFocus
              // defaultValue={unitTitleEVar}
              onChange={handleLesDescChange}
              margin="dense"
              id="name"
              label="Description"
              type="text"
              fullWidth
              style={{
                marginLeft: 10,
              }}
            />
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <EditIcon style={{ height: 16 }} />

            <TextField
              autoFocus
              // defaultValue={unitTitleEVar}
              onChange={handleLesCountChange}
              margin="dense"
              id="name"
              label="Lesson Duration (days)"
              type="text"
              fullWidth
              style={{
                marginLeft: 10,
              }}
            />
          </div>
 */}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row",
              width: '100%'
            }}
          >
            <EditIcon style={{ height: 16 }} />
            <FormControl required className={classes.formControl} style={{ overflowY: "initial", scrollbarWidth: "thin" }}>
              <InputLabel id="demo-simple-select-required-label">Lesson/Chapter</InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={lessChap}
                onChange={handleChapter}
                className={classes.selectEmpty}
              >


                {loadingChap && chpDetailsGet ? (
                  console.log("loading trueeee")
                ) : chapDetails.data && chapDetails.data.length != 0 ? (
                  chapDetails.data.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={list.chapter_id}
                        style={{
                          fontSize: 13,
                          color: "#464E5F",
                          fontFamily: "Poppins, Helvetica, sans-serif",
                        }}
                        onClick={() => {
                          // setChapterName(list.chapter_title);
                        }}
                      >
                        {list.chapter_title}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    style={{
                      fontSize: 13,
                      color: "#464E5F",
                      fontFamily: "Poppins, Helvetica, sans-serif",
                    }}
                  >
                    No Chapters for this subject..
                  </MenuItem>
                )}


              </Select>
            </FormControl>
          </div>




          {/* <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row",
              marginTop: 10
            }}
          >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ColorLensOutlinedIcon style={{ marginRight: 5 }} />
              <h5>Color:</h5>
              <TextField
                autoFocus
                // defaultValue={unitTitleEVar}
                onChange={handleLesMinsChange}
                margin="dense"
                id="name"
                // label="Lesson Duration (days)"
                type="text"
                fullWidth
                style={{
                  marginLeft: 10,
                }}
              />
            </div>

          </div>
          <div>
            <ColorPicker colorPickCode={(colorCode) => {handleColorChange(colorCode); console.log("color code at dialog box", colorCode)}} />
          </div> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddLessonClose} color="primary">
            Cancel
          </Button>
          <Button onClick={postAddNewLesson} color="primary">
            Save
          </Button>
          {/* <Button onClick={handleClose} color="warning">
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* second unit */}
      {/* <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 10
      }}>
        <div style={{
          display: 'flex'
        }}>
          {rectangle3}&nbsp;
          {rectangle2}
          <Typography
            // align="center"
            color="textPrimary"
            gutterBottom
            variant="body2"
            style={{
              marginLeft: 10
            }}
          >
            UNIT 2: Additions and Subtractions
        </Typography>

        </div>

      </div> */}

      <Snackbar
        open={lessonSnackOpen}
        autoHideDuration={6000}
        onClose={handleLessonSnackClose}
      >
        <Alert onClose={handleLessonSnackClose} severity="success">
          You have successfully added a lesson!
        </Alert>
      </Snackbar>
    </div>
    // </div>
  );
};

/*
const SimpleTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const rectangle = <div className={classes.shape} />;
  const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div
      style={{
        width: '100%'
      }}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="History" {...a11yProps(1)} />
          // <Tab label="Item Three" {...a11yProps(2)} /> 
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <OverviewBox />
        <div style={{
          height: 20
        }} />
        <UnitplanBox unitData={(Data) => { console.log("unitData", Data) }} />

        <div style={{
          height: 40
        }} />

        <StandardsBox />
      </TabPanel>

      <TabPanel value={value} index={1}>
        Coming soon..
      </TabPanel>
      // <TabPanel value={value} index={2}>
      //  Item Three
      </TabPanel>
    </div>
  );
}

const CenteredTabs = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Item One" />
        <Tab label="Item Two" />
      </Tabs>
    </Paper>
  );
}
*/

const MainTabs = (props) => {
  // console.log("updated unit Data", props.updatedUnitData)
  // console.log("lessons data ", props.lessonData.data.data)

  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openlesDialog, setOpenLesDialog] = useState(false);
  const [openlesPlanDialog, setOpenLesPlanDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(null);
  const [openLessonDialog, setOpenLessonDialog] = useState(null);
  const [openLessonPlanDialog, setOpenLessonPlanDialog] = useState(null);
  const [openSnackSuccess, setOpenSnackSuccess] = React.useState(false);
  const [startingPage, setStartingPage] = useState("");
  const [endingPage, setEndingPage] = useState("");

  //add Lesson values

  const [lessName, setlessName] = useState("");
  const [lessDesc, setLessDesc] = useState("");
  const [lessMins, setLessMins] = useState(" ");

  //Edit Lesson values
  const [lessChap, setLessChap] = useState("")


  // add lesson plan
  const [title, setTitle] = useState("");
  const [overviewMins, setOverviewMins] = useState(0);
  const [activityMins, setActivityMins] = useState(0);
  const [conMins, setConMins] = useState(0);
  const [assessmentMins, setAssessmentMins] = useState(0);

  const [overviewTxt, setOverviewTxt] = useState("");
  const [activityTxt, setActivityTxt] = useState("");
  const [conTxt, setConTxt] = useState("");
  const [assessmentTxt, setAssessmentTxt] = useState("");
  const [tpc, setTpc] = useState("");
  const [guideImageUrl, setGuideImageUrl] = useState("")

  const currPlan = useSelector((state) => state.currPlan.currPlan);
  console.log(`currPlan ${JSON.stringify(currPlan)}`);
  const planTitle = "";

  const planOverviewTxt = "";
  const planOverview = 0;
  console.log(`planOverview ${planOverview}`);

  const planActvTxt = "";
  const planActv = 0;

  const planAssmTxt = "";
  const planAssm = 0;

  const planConTxt = "";
  const planCon = 0;

  // const dispatch = useDispatch()

  const Duration =
    parseInt(overviewMins ? overviewMins : 0) +
    parseInt(activityMins ? activityMins : 0) +
    parseInt(assessmentMins ? assessmentMins : 0) +
    parseInt(conMins ? conMins : 0);
  const handleCloseSnackSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackSuccess(false);
  };

  const handleAddLessonOpen2 = () => {
    setOpenLessonDialog(true);
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleChangeOverview = (event) => {
    setOverviewMins(event.target.value);
  };

  const handleChangeOverviewTxt = (event) => {
    setOverviewTxt(event.target.value);
  };

  const handleChangeActivity = (event) => {
    setActivityMins(event.target.value);
  };

  const handleChangeActivityTxt = (event) => {
    setActivityTxt(event.target.value);
  };

  const handleChangeAssessment = (event) => {
    setAssessmentMins(event.target.value);
  };

  const handleChangeAssessmentTxt = (event) => {
    setAssessmentTxt(event.target.value);
  };

  const handleChangeConclusion = (event) => {
    setConMins(event.target.value);
  };

  const handleChangeConclusionTxt = (event) => {
    setConTxt(event.target.value);
  };

  const handleAddOpen = () => {

    // reset all values
    setTitle('')
    setOverviewMins(0)
    setOverviewTxt('')
    setActivityMins(0)
    setActivityTxt('')
    setConMins(0)
    setConTxt('')
    setActivityMins(0)
    setActivityTxt('')
    setAssessmentMins(0)
    setAssessmentTxt('')
    setStartingPage(0)
    setEndingPage(0)
    setTpc(0)
    setOpenAddDialog(true);
  };

  const handleAddClose = () => {
    setOpenAddDialog(false);
  };

  const handleAddLessonPlanOpen = () => {
    setOpenLessonPlanDialog(true);
  };

  const handleAddLessonPlanClose = () => {
    setOpenLessonPlanDialog(false);
  };

  const handleChangeTitle2 = (event) => {
    const { value } = event.target;
    console.log("text value", value);
    setTitle(value);
  };

  const handleChangeOverview2 = (event) => {
    const { value } = event.target;
    console.log("overview value", value);
    setOverviewMins(value);
  };

  const handleChangeActivity2 = (event) => {
    const { value } = event.target;
    console.log("activity value", value);
    setActivityMins(value);
  };

  const handleChangeAssessment2 = (event) => {
    const { value } = event.target;
    console.log("assessment value", value);
    setAssessmentMins(value);
  };

  const currLesson = useSelector((state) => state.currLesson.currLesson);
  const currUnit = useSelector((state) => state.currUnit.currUnit);
  const currMap = useSelector((state) => state.currMap.currMap);
  const currMapId = useSelector((state) => state.currMapId.currMapId);

  console.log(`got currunit ${JSON.stringify(currUnit)}`);

  // const [mapId, setMapId] = React.useState('')
  const [unitNum, setUnitNum] = React.useState("");
  const [unitTitle, setUnitTitle] = React.useState("");
  const [unitLessons, setUnitLessons] = React.useState("");
  const [unitDays, setUnitDays] = React.useState("");
  const [unitColor, setUnitColor] = React.useState("");
  const [unitTags, setUnitTags] = React.useState("");
  const [unitId, setUnitId] = React.useState();

  const lessNameE = currLesson ? currLesson.name_text : "";
  const lessDescE = currLesson ? currLesson.name_less : "";
  const lessDaysE = 0;//currLesson ? currLesson.days_count : "";
  const lessChapE = currLesson ? currLesson.chapter_id : 0;

  const mapId = currMapId != null ? currMapId : 0;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);

  const dispatch = useDispatch();

  const unitMapDetailsGet = useSelector((state) => state.unitMap);
  const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet;

  let unitNumEVar = currUnit != null ? currUnit.unit_number : 0;
  let unitTitleEVar = currUnit != null ? currUnit.unit_title_text : "";
  let unitLessonsEVar = currUnit != null ? currUnit.lesson_count : "";
  let unitDaysEVar = currUnit != null ? currUnit.days_count : "";
  let unitColorEVar = currUnit != null ? currUnit.unit_color : "";
  let unitTagsEVar = currUnit != null ? currUnit.unit_tags : "";
  let unitIdEVar = currUnit != null ? currUnit.id : 0;

  const [unitNumE, setUnitNumE] = React.useState(unitNumEVar);
  const [unitTitleE, setUnitTitleE] = React.useState(unitTitleEVar);
  const [unitLessonsE, setUnitLessonsE] = React.useState(unitLessonsEVar);
  const [unitDaysE, setUnitDaysE] = React.useState(unitDaysEVar);
  const [unitColorE, setUnitColorE] = React.useState(unitColorEVar);
  const [unitTagsE, setUnitTagsE] = React.useState(unitTagsEVar);
  const [unitIdE, setUnitIdE] = React.useState(unitIdEVar);

  console.log(`unitnume ${unitNumE}`);
  console.log(`unitnume var ${unitNumEVar}`);
  console.log(`unittitlee ${unitTitleEVar}`);

  const handleUnitIdChange = (event) => {
    const { name, value } = event.target;
    setUnitIdE(value);
  };

  const handleOpenEdit = () => {
    setOpenDialog(true);
  };

  const handleCloseEdit = () => {
    setOpenDialog(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleUnitNumChange = (event) => {
    const { name, value } = event.target;
    setUnitNum(value);
  };

  const handleUnitTitleChange = (event) => {
    const { name, value } = event.target;
    setUnitTitle(value);
  };

  const handleUnitLessonsChange = (event) => {
    const { name, value } = event.target;
    setUnitLessons(value);
  };

  const handleUnitDaysChange = (event) => {
    const { name, value } = event.target;

    setUnitDays(value);
  };

  const handleUnitTagsChange = (event) => {
    const { name, value } = event.target;

    setUnitTags(value);
  };

  /* EDIT UNIT FIELDS FUNCTIONS */

  const handleUnitNumEChange = (event) => {
    const { name, value } = event.target;
    setUnitNumE(value);
    unitNumEVar = value;

    // update store
    currUnit.unit_number = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitTitleEChange = (event) => {
    const { name, value } = event.target;
    setUnitTitleE(value);
    unitTitleEVar = value;

    // update store
    currUnit.unit_title_text = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitLessonsEChange = (event) => {
    const { name, value } = event.target;
    setUnitLessonsE(value);
    unitLessonsEVar = value;

    // update store
    currUnit.lesson_count = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitDaysEChange = (event) => {
    const { name, value } = event.target;

    setUnitDaysE(value);
    unitDaysEVar = value;

    // update store
    currUnit.days_count = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleUnitTagsEChange = (event) => {
    const { name, value } = event.target;

    setUnitTagsE(value);
    unitTagsEVar = value;

    // update store
    currUnit.unit_tags = value;
    dispatch(updateCurrUnit(currUnit));
  };

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleAddLessonOpen = () => {
    setOpenLesDialog(true);
  };

  const handleAddLessonClose = () => {
    setOpenLesDialog(false);
  };

  const handleLessTitleChange = (event) => {
    const { name, value } = event.target;
    setlessName(value);
  };

  const handleLesDescChange = (event) => {
    const { name, value } = event.target;
    setLessDesc(value);
  };

  const handleLesMinsChange = (event) => {
    const { name, value } = event.target;
    setLessMins(value);
  };

  const handleLessNameEChange = (event) => {
    const { name, value } = event.target;

    // update currlesson
    let currLessonE = currLesson;
    currLessonE.name_text = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleLessDaysEChange = (event) => {
    const { name, value } = event.target;

    // update currlesson
    let currLessonE = currLesson;
    currLessonE.days_count = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleLessTitleEChange = (event) => {
    const { name, value } = event.target;

    let currLessonE = currLesson;
    currLessonE.name_less = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  const handleLessChapEChange = (event) => {
    const { name, value } = event.target;

    console.log(`chapter change ${value}`)

    let currLessonE = currLesson;
    currLessonE.chapter_id = value;

    dispatch(updateCurrLesson(currLessonE));
  };

  // ADD NEW UNIT
  const handleSaveUnit = async () => {
    if (!unitNum || !unitTitle || !unitLessons || !unitDays) {
      setOpenSnack(true);
      return;
    }

    const body = {
      curriculum_map_id: mapId,
      unit_title_text: unitTitle,
      unit_number: unitNum,
      lesson_count: unitLessons,
      days_count: unitDays,
      unit_color: unitColor,
      unit_tags: unitTags,
    };

    console.log(`handleSaveUnit called..`, body);

    const resp = await MapUnitAPI.postSaveMapUnit(body);

    const unitId = resp.data.id;

    const newObj = {
      id: unitId,
      curriculum_map_id: mapId,
      unit_title_text: unitTitle,
      unit_number: unitNum,
      lesson_count: unitDays,
      days_count: unitDays,
      unit_color: unitColor,
      unit_tags: unitTags,
      is_published: false,
    };

    // send this new object along with previous list
    const data = {
      newObj: newObj,
      unitList: UnitMap,
    };

    dispatch(postNewUnit(data));

    console.log(`curriculum map unit save response ${JSON.stringify(resp)}`);

    // close dialog
    handleCloseAdd();
  };

  // UPDATE CURRENT UNIT
  const handleUpdateUnit = async () => {
    if (
      !currUnit.id ||
      !currUnit.unit_number ||
      !currUnit.unit_title_text ||
      !currUnit.lesson_count ||
      !currUnit.days_count
    ) {
      setOpenSnack(true);
      return;
    }

    const body = {
      curriculum_map_id: mapId,
      unit_title_text: currUnit.unit_title_text,
      unit_number: currUnit.unit_number,
      lesson_count: currUnit.lesson_count,
      days_count: currUnit.days_count,
      unit_color: currUnit.unit_color,
      unit_tags: currUnit.unit_tags,
    };

    console.log(`handleUpdateUnit called..  with body ${JSON.stringify(body)}`);

    const resp = await MapUnitAPI.postUpdateMapUnit(body, currUnit.id);
    console.log(`curriculum map unit update response ${JSON.stringify(resp)}`);
    console.log(`going to call units for map ${mapId}`);

    handleClose();

    // now refresh map units
    dispatch(listUnitMapGet(mapId));
  };

  const postAddNewLesson = async () => {
    const dataObj = {
      map_unit: 1,
      name_text: lessName,
      name_less: lessDesc,
      minutes_num: lessMins,
      chapter_id: lessChap
    };

    const resp = await MapUnitAPI.postAddLesson(dataObj);
    console.log("resp at post add new lesson", resp);
  };

  const handleEditLesson = async () => {
    const dataObj = {
      map_unit: currUnit.id,
      name_text: currLesson.name_text,
      name_less: currLesson.name_less,
      minutes_num: currLesson.minutes_num,
      chapter_id: currLesson.chapter_id
    };

    console.log(`post edit lesson body params ${JSON.stringify(dataObj)}`);

    const resp = await MapUnitAPI.postUpdateLesson(currLesson.id, dataObj);
    console.log("resp at post add new lesson", resp);
    handleCloseEdit();

    // load lessons
    dispatch(getUnitLessons(currUnit.id));
  };

  const handleSavePlan = async () => {
    const body = {
      lesson: currLesson.id,
      lesson_plan_num: 1,
      overview_min_num: overviewMins,
      overview_text: overviewTxt,
      activity_min_num: activityMins,
      activity_text: activityTxt,
      quiz_min_num: assessmentMins,
      quiz_text: assessmentTxt,
      conclusion_min_num: conMins,
      conclusion_text: conTxt,
      lesson_plan_name: title,
      first_page: startingPage,
      last_page: endingPage,
      topic_id: tpc,
      plan_image_url: guideImageUrl,
    };

    console.log(`currPlan during save ${JSON.stringify(currPlan)}`);
    console.log(`currPlan during save body ${JSON.stringify(body)}`);

    // show success snackbar
    setOpenSnackSuccess(true);

    console.log(`save plan details ${JSON.stringify(body)}`);

    const lessonPlans = await MapUnitAPI.postAddLessonPlan(body);

    // send this new object along with previous list
    const data = {
      newObj: body,
      lessonPlanList: lessonPlans,
    };

    // call redux to update the list
    /*
    await new Promise((resolve, reject) => {
      dispatch(postNewLessonPlan(data))
      resolve()
    });
    */

    // close dialog
    handleAddClose();

    // update the list of plans
    dispatch(getLessonPlans(currLesson.id));
  };

  // const Lessons = () => {
  //   if (
  //     props &&
  //     props.lessonData &&
  //     props.lessonData.data &&
  //     props.lessonData.data.data &&
  //     props.lessonData.data.data.length != 0
  //   ) {
  //     // console.log("props at lesson Data =======", props.lessonData.data.data)
  //     return props.lessonData.data.data.map((list, index) => {
  //       return (
  //         <div style={{ margin: 10 }}>
  //           <SimpleAccordion
  //             lessonData={list}
  //             key={index}
  //             onClick={() => {
  //               console.log("lesson id=========", list.lesson_id);
  //             }}
  //           />
  //         </div>
  //       );
  //     });
  //   } else {
  //     return <></>;
  //   }
  // };

  const LessonPlans = () => {
    return <SimpleAccordion currPlan={currPlan} />;
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const PageRangeSlider = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState([0, 100]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <div style={{ width: 300 }}>
        <Typography id="range-slider" gutterBottom>
          Pages Range
        </Typography>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
        />
      </div>
    );
  };

  const handlePageLeftChange = (event) => {
    console.log("called handlePriceChange");

    const name = event.target.name;
    const value = event.target.value;
    setStartingPage(value);
  };

  const handlePageRightChange = (event) => {
    console.log("called handleAuthorChange");

    const name = event.target.name;
    const value = event.target.value;
    setEndingPage(value);
  };

  const handleChapter = (event) => {
    const { name, value } = event.target
    setLessChap(value);
    dispatch(listTopGet(value));
  };

  const handleTpcChange = (event) => {
    console.log("called handleTpcChange");
    console.log(`new topic ${event}`);

    const name = event.target.name;
    const value = event.target.value;
    setTpc(value);
  };

  const chpDetailsGet = useSelector((state) => state.chpDetails);
  const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

  const topDetailsGet = useSelector((state) => state.topDetails);
  const { loadingTop, TopDetails, errorTop } = topDetailsGet;


  {
    loadingChap && chapDetails ?
      console.log("chap details data is loading")
      :
      console.log("chap details at Unit list", chapDetails)
  }



  {
    loadingTop && TopDetails ?
      console.log("topic details data is loading")
      :
      console.log("topic details at Unit list", TopDetails)
  }



  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Unit Detail" {...a11yProps(0)} />
          {/* <Tab label="Unit Pacing" {...a11yProps(1)} />
          <Tab label="Curriculum Pacing" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <div
          style={{
            marginBottom: 10,
          }}
        >
          {currLesson ? (
            <Typography variant="h4">{currLesson.name_text}</Typography>
          ) : (
            <Alert severity="warning">
              <AlertTitle>No Lesson chosen</AlertTitle>
              You have not selected a Lesson —{" "}
              <strong>choose from the left panel!</strong>
            </Alert>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {currUnit != null && currLesson ? (
            <>
              <CustomizedBreadcrumbs
                unitNumber={`UNIT ${currUnit.unit_number}`}
                unitName={currUnit.unit_title_text}
                lessonName={currLesson.name_text}
              />
              {/*
              <div>
                UNIT {currUnit.unit_number}&nbsp;:&nbsp;{currUnit.unit_title_text}&nbsp;Lesson&nbsp;{currLesson.name_text}&nbsp;Plans
              </div>
              */}
            </>
          ) : (
            <></>
          )}
          <div
            style={{
              display: "flex",

              alignSelf: "flex-end",
            }}
          >
            {/* <Button variant="contained"
              onClick={handleClickOpenAdd}
              style={{
                alignSelf: 'flex-end',
                display: 'flex',
                marginBottom: 20
              }}>

              Add Unit
            </Button> */}

            {/* <Divider orientation="vertical" flexItem /> */}

            {currLesson && (
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Chip
                  label="Add Plan"
                  clickable
                  color="primary"
                  onClick={handleAddOpen}
                />
              </div>
            )}

            {/* ADD LESSON PLAN DIALOG */}
            <Dialog
              open={openAddDialog}
              onClose={handleAddClose}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="lg"
            >
              <DialogTitle id="form-dialog-title">Add Plan</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Add Plan lets you Add a unit lesson plan with time and pacing.
                </DialogContentText>

                {/* Title */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "80%",
                    }}
                  >
                    <EditIcon style={{ height: 16 }} />

                    <TextField
                      autoFocus
                      // defaultValue={unitTitleEVar}
                      defaultValue={planTitle}
                      onChange={handleChangeTitle}
                      margin="dense"
                      id="name"
                      label="Title"
                      type="text"
                      fullWidth
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  </div>

                  <div
                    style={{ marginRight: 10, marginLeft: 10, alignSelf: 'flex-end', textAlign: 'right' }}
                  >
                    <h5>Duration: {Duration} Mins</h5>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* LEFT */}
                  <div
                    className="p-1 w50"
                    style={{
                      width: "49%",
                    }}
                  >
                    {/* Overview */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        autoFocus
                        // defaultValue={unitTitleEVar}
                        defaultValue={planOverviewTxt}
                        onChange={handleChangeOverviewTxt}
                        margin="dense"
                        id="name"
                        label="Overview Guide"
                        type="text"
                        multiline={true}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        style={{
                          marginLeft: 10,
                        }}
                      />
                      <div
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        <TextField
                          autoFocus
                          // defaultValue={unitLessonsEVar}
                          defaultValue={planOverview}
                          onChange={handleChangeOverview}
                          margin="dense"
                          id="Overview"
                          label="Overview mins"
                          type="number"
                          fullWidth
                          variant="outlined"
                        />
                      </div>
                    </div>

                    {/* Activity */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        autoFocus
                        // defaultValue={unitTitleEVar}
                        defaultValue={planActvTxt}
                        onChange={handleChangeActivityTxt}
                        margin="dense"
                        id="name"
                        label="Activity Guide"
                        type="text"
                        multiline={true}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        style={{
                          marginLeft: 10,
                        }}
                      />

                      <div
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        <TextField
                          autoFocus
                          // defaultValue={unitDaysEVar}
                          defaultValue={planActv}
                          onChange={handleChangeActivity}
                          margin="dense"
                          id="Activity"
                          label="Activity mins"
                          type="number"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>

                  {/* RIGHT */}
                  <div
                    style={{
                      width: "49%",
                    }}
                  >
                    {/* Conclusion */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        autoFocus
                        // defaultValue={unitTitleEVar}
                        defaultValue={planConTxt}
                        onChange={handleChangeConclusionTxt}
                        margin="dense"
                        id="name"
                        label="Conclusion Guide"
                        type="text"
                        multiline={true}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        style={{
                          marginLeft: 10,
                        }}
                      />

                      <div
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        <TextField
                          autoFocus
                          // defaultValue={unitDaysEVar}
                          defaultValue={planCon}
                          onChange={handleChangeConclusion}
                          margin="dense"
                          id="Conclusion"
                          label="Conclusion mins"
                          type="number"
                          variant="outlined"
                        />
                      </div>
                    </div>

                    {/* Assessment */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        autoFocus
                        // defaultValue={unitTitleEVar}
                        defaultValue={planAssmTxt}
                        onChange={handleChangeAssessmentTxt}
                        margin="dense"
                        id="name"
                        label="Assessment Guide"
                        type="text"
                        multiline={true}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        style={{
                          marginLeft: 10,
                        }}
                      />

                      <div
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        <TextField
                          autoFocus
                          // defaultValue={unitDaysEVar}
                          defaultValue={planAssm}
                          onChange={handleChangeAssessment}
                          margin="dense"
                          id="Assessment"
                          label="Assessment mins"
                          type="number"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 10,
                    alignContent: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 10,
                      alignContent: "center",
                    }}
                  >
                    <DateRangeOutlinedIcon style={{ marginRight: 10 }} />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            marginBottom: 20,
                            marginRight: 5,
                          }}
                        >
                          <FormControl>
                            <TextField
                              id="outlined-basic"
                              label="E-Book Start Page"
                              variant="outlined"
                              type="number"
                              style={{
                                width: 170,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                              defaultValue={startingPage}
                              onChange={handlePageLeftChange}
                            />
                            {/* 
                          <Typography>
                            <span
                              style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Enter{" "}
                              <span
                                style={{
                                  color: "#464E5F",
                                  fontWeight: "600",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                Starting Page No
                              </span>
                            </span>
                          </Typography> */}
                          </FormControl>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            marginBottom: 20,
                            marginLeft: 5,
                          }}
                        >
                          <FormControl>
                            <TextField
                              id="outlined-basic"
                              label="E-Book End Page"
                              variant="outlined"
                              style={{
                                width: 170,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                              type="number"
                              defaultValue={endingPage}
                              onChange={handlePageRightChange}
                            />
                            {/* 
                          <Typography>
                            <span
                              style={{
                                fontSize: 12,
                                color: "#464E5F",
                                marginLeft: 5,
                                fontFamily: "Poppins, Helvetica, sans-serif",
                              }}
                            >
                              Enter{" "}
                              <span
                                style={{
                                  color: "#464E5F",
                                  fontWeight: "600",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                Ending Page No
                              </span>
                            </span>
                          </Typography> */}
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>


                  {
                    loadingTop && topDetailsGet ? (
                      <></>
                    ) : (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <FormControl variant="outlined" className={classes.formControl}>


                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            style={{ fontSize: 13, color: "#464E5F" }}
                          >
                            Choose Topic
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={tpc}
                            onChange={handleTpcChange}
                            label="Topic"
                            style={{ fontSize: 13, color: "#464E5F" }}
                          >
                            {loadingTop && topDetailsGet ? (
                              console.log("loading trueeee")
                            ) : TopDetails.data && TopDetails.data.length != 0 ? (
                              TopDetails.data.map((list, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={list.topic_id}
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                    onClick={() => {
                                      // setTopicName(list.topic_title);
                                    }}
                                  >
                                    {list.topic_title}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                              >
                                Please Select Lesson
                              </MenuItem>
                            )}
                          </Select>
                          {/* <span
                            style={{
                              fontSize: 12,
                              color: "#464E5F",
                              marginLeft: 5,
                              fontFamily: "Poppins, Helvetica, sans-serif",
                            }}
                          >
                            Please select{" "}
                            <span
                              style={{
                                color: "#464E5F",
                                fontWeight: "600",
                                fontFamily: "Poppins, Helvetica, sans-serif",
                                fontSize: 12,
                              }}
                            >
                              Topic
                            </span>
                          </span> */}
                        </FormControl>
                      </div>
                    )

                  }

                  <div>
                    <GuideImageDialog imageUrl={(imgUrl) => { setGuideImageUrl(imgUrl) }} />
                  </div>

                </div>


              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddClose} color="primary">
                  Cancel
                </Button>

                <Button onClick={handleSavePlan} color="primary">
                  Save
                </Button>

              </DialogActions>
            </Dialog>

            <Snackbar
              open={openSnackSuccess}
              autoHideDuration={6000}
              onClose={handleCloseSnackSuccess}
            >
              <Alert onClose={handleCloseSnackSuccess} severity="success">
                Record saved successfully!
              </Alert>
            </Snackbar>

            {/*
            <Dialog open={openAddDialog} onClose={handleAddClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Add Plan</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Add Plan lets you Add a unit with time and pacing.
                </DialogContentText>

                <div style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  flexDirection: 'row'
                }}>
                  <EditIcon style={{ height: 16 }} />


                  <TextField
                    autoFocus
                    // defaultValue={unitTitleEVar}
                    defaultValue={title}
                    onChange={handleChangeTitle}
                    margin="dense"
                    id="name"
                    label="Title"
                    type="text"
                    fullWidth
                    style={{
                      marginLeft: 10
                    }}
                  />

                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: "center",
                    marginTop: 10
                  }}>
                  <DateRangeIcon style={{ height: 5 }} />


                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',

                  }}>
                    
                    <div style={{ marginLeft: 20 }}>
                      <TextField
                        autoFocus
                        // defaultValue={unitDaysEVar}
                        onChange={handleChangeActivity}
                        margin="dense"
                        id="Activity"
                        label="Activity mins"
                        type="number"
                      />
                    </div>

                    <div style={{ marginLeft: 20 }}>
                      <TextField
                        autoFocus
                        // defaultValue={unitDaysEVar}
                        onChange={handleChangeAssessment}
                        margin="dense"
                        id="Assessment"
                        label="Assessment mins"
                        type="number"
                      />
                    </div>
                  </div>


                </div>


              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddClose} color="primary">
                  Cancel
                </Button>

                <Button onClick={handleSavePlan} color="primary">
                  Save
                </Button>

                <Button color="warning">
                  Delete
                </Button>

              </DialogActions>
            </Dialog>
          */}

            {currLesson && (
              <>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <Chip
                    label="Edit Lesson"
                    clickable
                    color="secondary"
                    onClick={handleOpenEdit}
                  />
                </div>

                {/* EDIT LESSON UNIT DIALOG */}
                <Dialog
                  open={openDialog}
                  onClose={handleCloseEdit}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Edit Lesson</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Edit Lesson lets you edit a unit.
                    </DialogContentText>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <EditIcon style={{ height: 16 }} />


                      <TextField
                        autoFocus
                        defaultValue={lessNameE}
                        onChange={handleLessNameEChange}
                        margin="dense"
                        id="name"
                        label="Title"
                        type="text"
                        fullWidth
                        style={{
                          marginLeft: 10,
                        }}
                      />
                    </div>


                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "row",
                        // width: "80%",
                      }}
                    >
                      <EditIcon style={{ height: 16 }} />

                      {/* <TextField
                    autoFocus
                    // defaultValue={unitNumEVar}
                    // onChange={handleUnitNumEChange}
                    margin="dense"
                    id="name"
                    label="Number"
                    type="text"
                    style={{
                      marginLeft: 10
                    }}
                  /> */}

                      <TextField
                        autoFocus
                        defaultValue={lessDescE}
                        onChange={handleLessTitleEChange}
                        margin="dense"
                        id="name"
                        label="Lesson Name"
                        type="text"
                        fullWidth
                        style={{
                          marginLeft: 10,
                        }}
                      />
                    </div>

                    {/* 
                    <div
                      style={{
                        width: "20%",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    >
                      <h5>Duration:{Duration} Mins</h5>
                    </div> */}
                    {/* 
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <EditIcon style={{ height: 16 }} />


                      <TextField
                        autoFocus
                        defaultValue={lessDaysE}
                        onChange={handleLessDaysEChange}
                        margin="dense"
                        id="name"
                        label="Duration (days)"
                        type="text"
                        fullWidth
                        style={{
                          marginLeft: 10,
                        }}
                      />
                    </div>
 */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          flexDirection: "row",
                          // width: "80%",
                        }}
                      >
                        <EditIcon style={{ height: 16 }} />
                        <FormControl required className={classes.formControl} style={{ overflowY: "initial", scrollbarWidth: "thin" }}>
                          <InputLabel id="demo-simple-select-required-label">Lesson Chapter</InputLabel>
                          <Select
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            defaultValue={lessChapE}
                            onChange={handleLessChapEChange}
                            className={classes.selectEmpty}
                          >


                            {loadingChap && chpDetailsGet ? (
                              console.log("loading trueeee")
                            ) : chapDetails.data && chapDetails.data.length != 0 ? (
                              chapDetails.data.map((list, index) => {
                                let selected = false
                                if (list.chapter_id == lessChapE)
                                  selected = true

                                return (
                                  <MenuItem
                                    selected={selected}
                                    key={index}
                                    value={list.chapter_id}
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}

                                  >
                                    {list.chapter_title}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontFamily: "Poppins, Helvetica, sans-serif",
                                }}
                              >
                                No Chapters for the subject..
                              </MenuItem>
                            )}


                          </Select>
                        </FormControl>
                      </div>
                    </div>




                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseEdit} color="primary">
                      Cancel
                    </Button>

                    <Button color="primary" onClick={handleEditLesson}>
                      Save
                    </Button>

                  </DialogActions>
                </Dialog>
              </>
            )}

            {currUnit && (
              <Chip
                style={{
                  alignSelf: "flex-end",
                  display: "flex",
                  marginBottom: 20,
                  marginLeft: 10,
                }}
                onClick={handleClickOpen}
                color="secondary"
                label="Edit Unit"
                clickable
              />
            )}

            {/* EDIT UNIT DIALOG */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Edit Unit Map</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Unit Map lets you setup a unit with time and pacing.
                </DialogContentText>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <EditIcon style={{ height: 16 }} />

                  <TextField
                    autoFocus
                    defaultValue={unitNumEVar}
                    onChange={handleUnitNumEChange}
                    margin="dense"
                    id="name"
                    label="Number"
                    type="text"
                    style={{
                      marginLeft: 10,
                    }}
                  />

                  <TextField
                    autoFocus
                    defaultValue={unitTitleEVar}
                    onChange={handleUnitTitleEChange}
                    margin="dense"
                    id="name"
                    label="Title"
                    type="text"
                    fullWidth
                    style={{
                      marginLeft: 10,
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",

                    marginTop: 10,
                  }}
                >
                  <DateRangeIcon style={{ height: 16 }} />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 10,
                    }}
                  >
                    <Typography variant="body2">Pacing</Typography>

                    <Typography variant="duration">
                      SUGGESTED DURATION
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div>
                        <TextField
                          autoFocus
                          defaultValue={unitLessonsEVar}
                          onChange={handleUnitLessonsEChange}
                          margin="dense"
                          id="lessons"
                          label="Lessons"
                          type="number"
                        />
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <TextField
                          autoFocus
                          defaultValue={unitDaysEVar}
                          onChange={handleUnitDaysEChange}
                          margin="dense"
                          id="days"
                          label="Days"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <TextField
                  autoFocus
                  defaultValue={unitTagsEVar}
                  onChange={handleUnitTagsEChange}
                  margin="dense"
                  id="tags"
                  label="Tags"
                  type="text"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleUpdateUnit} color="primary">
                  Save
                </Button>
                {/* <Button onClick={handleClose} color="warning">
                  Delete
                </Button> */}
              </DialogActions>
            </Dialog>

            <Snackbar
              open={openSnack}
              autoHideDuration={6000}
              onClose={handleCloseSnack}
            >
              <Alert onClose={handleCloseSnack} severity="warning">
                Please enter mandatory details!
              </Alert>
            </Snackbar>


            {/* ADD UNIT MAP DIALOG */}
            <Dialog
              open={openAdd}
              onClose={handleCloseAdd}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Add Unit Map</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Unit Map lets you setup a unit with time and pacing.
                </DialogContentText>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <EditIcon style={{ height: 16 }} />

                  <TextField
                    autoFocus
                    defaultValue={unitNum}
                    onChange={handleUnitNumChange}
                    margin="dense"
                    id="name"
                    label="Number"
                    type="text"
                    style={{
                      marginLeft: 10,
                    }}
                  />

                  <TextField
                    autoFocus
                    defaultValue={unitTitle}
                    onChange={handleUnitTitleChange}
                    margin="dense"
                    id="name"
                    label="Title"
                    type="text"
                    fullWidth
                    style={{
                      marginLeft: 10,
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",

                    marginTop: 10,
                  }}
                >
                  <DateRangeIcon style={{ height: 16 }} />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 10,
                    }}
                  >
                    <Typography variant="body2">Pacing</Typography>

                    <Typography variant="duration">
                      SUGGESTED DURATION
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div>
                        <TextField
                          autoFocus
                          defaultValue={unitLessons}
                          onChange={handleUnitLessonsChange}
                          margin="dense"
                          id="lessons"
                          label="Lessons"
                          type="number"
                        />
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <TextField
                          autoFocus
                          defaultValue={unitDays}
                          onChange={handleUnitDaysChange}
                          margin="dense"
                          id="days"
                          label="Days"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <TextField
                  autoFocus
                  defaultValue={unitTags}
                  onChange={handleUnitTagsChange}
                  margin="dense"
                  id="tags"
                  label="Tags"
                  type="text"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAdd} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSaveUnit} color="primary">
                  Save
                </Button>
                {/* <Button onClick={handleCloseAdd} color="warning">
                  Delete
                </Button> */}
              </DialogActions>
            </Dialog>

            {/* add Lesson DIALOG */}
            <Dialog
              open={openlesDialog}
              onClose={handleAddLessonClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Add Lesson </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Unit Map lets you setup a unit with time and pacing.
                </DialogContentText>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <EditIcon style={{ height: 16 }} />

                  <TextField
                    autoFocus
                    // defaultValue={unitTitleEVar}
                    onChange={handleLessTitleChange}
                    margin="dense"
                    id="name"
                    label="Title"
                    type="text"
                    fullWidth
                    style={{
                      marginLeft: 10,
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <EditIcon style={{ height: 16 }} />

                  <TextField
                    autoFocus
                    // defaultValue={unitTitleEVar}
                    onChange={handleLesDescChange}
                    margin="dense"
                    id="name"
                    label="Description"
                    type="text"
                    fullWidth
                    style={{
                      marginLeft: 10,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <EditIcon style={{ height: 16 }} />

                  <TextField
                    autoFocus
                    // defaultValue={unitTitleEVar}
                    onChange={handleLesMinsChange}
                    margin="dense"
                    id="name"
                    label="Lesson Duration (days)"
                    type="text"
                    fullWidth
                    style={{
                      marginLeft: 10,
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={postAddNewLesson} color="primary">
                  Save
                </Button>
                {/* <Button onClick={handleClose} color="warning">
                  Delete
                </Button> */}
              </DialogActions>
            </Dialog>
          </div>
        </div>
        {/* {props && props.lessonData.data && props.lessonData.data.data ?

          props.lessonData.data.data.map((les, index) => {
            <SimpleAccordion lessonData={les} key={index} />
          })
          :
          <></>
        } */}
        {<LessonPlans />}
      </TabPanel>
      {/* 
      <TabPanel value={value} index={1}>
        <MyDateRangePickerCalendar />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MyCalendar />
      </TabPanel> */}
    </div>
  );
};

const UnitLeftCard = ({
  lessonData,
  getLesData,
  UnitMap,
  unitLessons,
  setUnitData,
  product,
  params,
  ...rest
}) => {
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openAddUnit, setOpenAddUnit] = useState(false);
  const [addUnitNumber, setAddUnitNumber] = useState(null);
  const [addUnitTitle, setAddUnitTitle] = useState(null);
  const [addUnitLess, setAddUnitLess] = useState(null);
  const [addunitDays, setAddUnitDays] = useState(null);
  const [addUnitTags, setAddUnitTags] = useState(null);
  const [addUnitColor, setAddUnitColor] = useState("#000");
  const [currUnitName, setCurrUnitName] = useState("Select Unit");
  const [selUnit, setSelUnit] = useState(null);
  const [unitSnackOpen, setUnitSnackOpen] = useState(false);

  const unitMapDetailsGet = useSelector((state) => state.unitMap);

  const units = unitMapDetailsGet.UnitMap;
  const currUnit = useSelector((state) => state.currUnit.currUnit);

  console.log(`got unit lessons in left card ${JSON.stringify(unitLessons)}`);

  const handleUnitSnackClose = () => {
    setUnitSnackOpen(false);
  };

  const handleChange = async (event, child, value) => {
    if (child && child.props && event && event.target) {
      // get the index by checking the name
      let index = -1;

      // console.log(`change unit got event ${JSON.stringify(event.target)}`)
      // console.log(`change unit got index ${JSON.stringify(child.props)}`)

      for (let i = 0; i < UnitMap.data.Units.length; i++) {
        const unt = UnitMap.data.Units[i];
        if (unt.unit_title_text == event.target.value) {
          index = i;
          break;
        }
      }

      if (index > -1) {
        setCurrUnitName(event.target.value);
        console.log(`we got index ${index}`);

        await new Promise((resolve, reject) => {
          // get current unit by index
          setSelUnit(UnitMap.data.Units[index]);
          resolve();
        });

        const currUnit = UnitMap.data.Units[index];
        const currUnitId = currUnit.id;
        // also update current unit
        await new Promise((resolve, reject) => {
          dispatch(updateCurrUnit(currUnit));
          console.log(`new currUnit id ${currUnitId}`);
          resolve()
        })

        // load new lessons
        dispatch(getUnitLessons(currUnitId));

        // take first lesson

        // load lesson plans
      }
    }
  };

  const currMap = useSelector((state) => state.currMap.currMap);
  const currMapId = useSelector((state) => state.currMapId.currMapId);
  // const mapId = currMapId != null ? currMapId : 0;
  const mapId = params.id;

  const dispatch = useDispatch();
  console.log(`lessons data 2 ${JSON.stringify(lessonData)}`);

  const sendUnitData = (units) => {
    setUnitData(units);
  };

  // const unitMapDetailsGet = useSelector(state => state.unitMap)
  // const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet

  // console.log("unit details", UnitMap)

  // useEffect(() => {
  //   dispatch(listUnitMapGet(1))
  //   return () => {
  //     //
  //   }

  // }, [])

  const updateLesData = (lesData) => {
    getLesData(lesData);
  };

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleAddunitOpen = () => {
    setOpenAddUnit(true);
  };

  const handleAddunitClose = () => {
    setOpenAddUnit(false);
  };

  const handleUnitNumChange = (event) => {
    const { value } = event.target;
    setAddUnitNumber(value);
  };

  const handleUnitTitle = (event) => {
    const { value } = event.target;
    setAddUnitTitle(value);
  };

  const handleUnitLess = (event) => {
    const { value } = event.target;
    setAddUnitLess(value);
  };

  const handleUnitDays = (event) => {
    const { value } = event.target;
    setAddUnitDays(value);
  };

  const handleUnitTags = (event) => {
    const { value } = event.target;
    setAddUnitTags(value);
  };

  const handleAddUnitSave = async () => {
    const body = {
      curriculum_map_id: mapId,
      unit_title_text: addUnitTitle,
      unit_number: addUnitNumber,
      lesson_count: addUnitLess,
      days_count: addunitDays,
      unit_color: addUnitColor,
      unit_tags: addUnitTags,
    };

    console.log(`add unit body params ${JSON.stringify(body)}`);

    const resp = await MapUnitAPI.postSaveMapUnit(body);

    console.log("response at handleunitsave ==================", resp);
    handleAddunitClose(); // close dialog

    // open snackbar
    setUnitSnackOpen(true);

    // load all units
    dispatch(listUnitMapGet(mapId));
  };

  const unitOptions = (
    <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      value={currUnitName}
      onChange={(event, index, value) => handleChange(event, index, value)}
      label="Units"
    >
      <MenuItem value="Select Unit">
        <em>Select Unit</em>
      </MenuItem>

      {/*               
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem> */}

      {units && units.data && units.data.Units && units.data.Units.length ? (
        units.data.Units.map((unit, index) => {
          return (
            <MenuItem value={unit.unit_title_text}>
              {unit.unit_title_text}
            </MenuItem>
          );
        })
      ) : (
        <MenuItem value={-1}>No Units</MenuItem>
      )}
    </Select>
  );

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
      style={{
        width: "100%",
      }}
      {...rest}
    >
      <CardContent style={{}}>
        <div style={{}}>
          {/* <FormControl variant="filled" className={classes.formControl}
            style={{
              width: '100%'
            }}>
            <InputLabel id="demo-simple-select-filled-label">UNIT CONTENT</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={age}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

           */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Chip label="COURSE SUMMARY" style={{ marginTop: 10 }} /> */}
            <Typography align="center" color="textPrimary" variant="body1">
              COURSE SUMMARY
            </Typography>
            <Chip
              label="ADD UNIT"
              style={{ marginTop: 10 }}
              onClick={handleAddunitOpen}
              clickable
              color="primary"
            />
          </div>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Units
            </InputLabel>

            {unitOptions}
          </FormControl>
        </div>

        {/* 
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          Description
        </Typography>
         */}

        {/* ADD UNIT DIALOG AT LEFT CARD*/}
        <Dialog
          open={openAddUnit}
          onClose={handleAddunitClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Unit</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Unit Map lets you setup a unit with time and pacing.
            </DialogContentText>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <EditIcon style={{ height: 16 }} />

              <TextField
                autoFocus
                // defaultValue={unitNum}
                onChange={handleUnitNumChange}
                margin="dense"
                id="name"
                label="Number"
                type="text"
                style={{
                  marginLeft: 10,
                }}
              />

              <TextField
                autoFocus
                // defaultValue={unitTitle}
                onChange={handleUnitTitle}
                margin="dense"
                id="name"
                label="Title"
                type="text"
                fullWidth
                style={{
                  marginLeft: 10,
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",

                marginTop: 10,
              }}
            >
              <DateRangeIcon style={{ height: 16 }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                }}
              >
                <Typography variant="body2">Pacing</Typography>

                <Typography variant="duration">SUGGESTED DURATION</Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <TextField
                      autoFocus
                      // defaultValue={unitLessons}
                      onChange={handleUnitLess}
                      margin="dense"
                      id="lessons"
                      label="Lessons"
                      type="number"
                    />
                  </div>

                  <div style={{ marginLeft: 20 }}>
                    <TextField
                      autoFocus
                      // defaultValue={unitDays}
                      onChange={handleUnitDays}
                      margin="dense"
                      id="days"
                      label="Days"
                      type="number"
                    />
                  </div>

                </div>
              </div>
            </div>

            <TextField
              autoFocus
              // defaultValue={unitTags}
              onChange={handleUnitTags}
              margin="dense"
              id="tags"
              label="Tags"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddunitClose} color="primary">
              Cancel
            </Button>

            <Button onClick={handleAddUnitSave} color="primary">
              Save
            </Button>

            {/* <Button color="warning">Delete</Button> */}
          </DialogActions>
        </Dialog>

        <Snackbar
          open={unitSnackOpen}
          autoHideDuration={6000}
          onClose={handleUnitSnackClose}
        >
          <Alert onClose={handleUnitSnackClose} severity="success">
            You have successfully added a Unit!
          </Alert>
        </Snackbar>
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />

      {/* <CenteredTabs /> */}

      <div
        style={{
          width: "100%",
        }}
      >
        <UnitplanBox
          lessonsData={unitLessons}
          sendUnit={(units) => {
            sendUnitData(units);
          }}
          UnitMap={UnitMap}
          lessonData={(lesData) => {
            updateLesData(lesData);
          }}
        />
      </div>
    </Card>
  );
};

const UnitList = (props) => {
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const classes = useStyles1();
  const [unitData, setUnitData] = useState(null);
  const [lessonData, setlessonData] = useState(null);
  const currMap = useSelector((state) => state.currMap.currMap);
  const currUnit = useSelector((state) => state.currUnit.currUnit);

  const unitLessons = useSelector((state) => state.unitLessons.unitLessons);
  const lessonPlans = useSelector((state) => state.lessonPlans.lessonPlans);
  //  const mapId = (currMap != null) ? currMap.map_id : 0

  const dispatch = useDispatch();

  const unitMapDetailsGet = useSelector((state) => state.unitMap);
  const { loadingUnit, UnitMap, errorMap } = unitMapDetailsGet;
  const [firstTime, setFirstTime] = useState(false);
  const mapId = props.match.params.id;

  console.log("unit details", UnitMap);
  console.log(`lessons data ${JSON.stringify(unitLessons)}`);

  useEffect(() => {
    console.log("map id at unit List ", mapId);

    getCurrMap(mapId);

    // get lessons
    return (() => {
      //
    })
  }, []);

  const getCurrMap = async (mapId) => {
    const currMap = await MapAPI.getMapDetails(mapId);

    await new Promise((resolve, reject) => {
      dispatch(updateCurrMap(currMap));
      console.log(`got currmap ${JSON.stringify(currMap)}`)
      resolve()
    })

    await new Promise((resolve, reject) => {
      dispatch(updateCurrMapId(mapId));
      resolve()
    })

    getUnitAndLessons();

  };

  const getUnitAndLessons = async () => {
    await new Promise((resolve, reject) => {
      dispatch(listUnitMapGet(mapId));
      resolve()
    })

    // update current unit to 1
    if (UnitMap && UnitMap.length > 0) {
      console.log(`trying to get lessons`)
      const currUnit = UnitMap[0]
      await new Promise((resolve, reject) => {
        dispatch(updateCurrUnit(currUnit))
        resolve()
      })

      // getUnitLessonsData()

    }

    console.log(`getunitandlessons listunitmapget()`)
  };

  /*

  const getUnitLessonsData = () => {
    const unitId = currUnit.id;
    console.log(`unitid lessons call ${unitId}`);

    const lessons = unitLessons;
    // const lessons = await MapUnitAPI.getUnitLessons(unitId)
    dispatch(getUnitLessons(unitId));

    if (
      lessons &&
      lessons.data &&
      lessons.data.data &&
      lessons.data.data.length
    ) {
      // set current lesson
      dispatch(updateCurrLesson(lessons.data.data[0]));
    }

    setlessonData(lessons);
    setFirstTime(true);
  };



  // set first unit as default current
  if (
    !firstTime &&
    UnitMap &&
    UnitMap.data &&
    UnitMap.data.Units &&
    UnitMap.data.Units.length
  ) {
    dispatch(updateCurrUnit(UnitMap.data.Units[0]));
  }

  */
  console.log(`firstTime ${firstTime}`);

  // also get first unit lessons
  if (!firstTime && currUnit) {
    console.log(`curr unit id ${currUnit.id}`);
    // getUnitLessonsData(currUnit.id);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const updateData = (data) => {
    setUnitData(data);
  };

  const updateLesDatatoState = (lesData) => {
    setlessonData(lesData);
  };

  return (
    <div className={classes.root}>
      <LeftCard open={false} />

      <main
        className={classes.content}
        style={{
          width: "100%",
        }}
      >
        <Container
          className={classes.container}
          style={{
            maxWidth: "100%",
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: "100%",
            }}
          >
            <Helmet>
              <title>Curriculum Maps | KGtoPG Partner</title>
            </Helmet>
            <Box
              sx={{
                backgroundColor: "background.default",
                minHeight: "100%",
                py: 3,
              }}
              style={{
                width: "100%",
                minWidth: "100%",
              }}
            >
              <Container
                style={{
                  maxWidth: "100%",
                }}
              >
                <UnitListToolbar />

                <Box
                  sx={{ pt: 3, mt: 5 }}
                  style={{
                    marginTop: 20,
                    paddingBottom: 20,
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {loadingUnit && unitMapDetailsGet && UnitMap ? (
                      <></>
                    ) : (
                      <UnitLeftCard
                        style={{ width: "29%" }}
                        setUnitData={(data) => {
                          updateData(data);
                        }}
                        UnitMap={UnitMap}
                        unitLessons={unitLessons}
                        getLesData={(lesData) => {
                          updateLesDatatoState(lesData);
                        }}
                        lessonData={lessonData}
                        params={props.match.params}
                      />
                    )}
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "70%",
                      }}
                      style={{
                        width: "70%",
                        marginLeft: 10,
                      }}
                    >
                      <CardContent
                        style={{
                          width: "100%",
                        }}
                      >
                        <MainTabs
                          updatedUnitData={unitData}
                          UnitMap={UnitMap}
                          lessonData={lessonData}
                        />
                      </CardContent>
                    </Card>
                  </div>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default UnitList;
