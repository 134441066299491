
export const CONTENTDETAILS_LIST_REQUEST = 'CONTENTDETAILS_LIST_REQUEST'
export const CONTENTDETAILS_LIST_SUCCESS = ' CONTENTDETAILS_LIST_SUCCESS'
export const CONTENTDETAILS_LIST_FAIL = 'CONTENTDETAILS_LIST_FAIL'


export const EBOOKDETAILS_LIST_REQUEST = 'EBOOKDETAILS_LIST_REQUEST'
export const EBOOKDETAILS_LIST_SUCCESS = ' EBOOKDETAILS_LIST_SUCCESS'
export const EBOOKDETAILS_LIST_FAIL = 'EBOOKDETAILS_LIST_FAIL'


export const CURRICULUMMAPDETAILS_LIST_REQUEST = 'CURRICULUMMAPDETAILS_LIST_REQUEST'
export const CURRICULUMMAPDETAILS_LIST_SUCCESS = ' CURRICULUMMAPDETAILS_LIST_SUCCESS'
export const CURRICULUMMAPDETAILS_LIST_FAIL = 'CURRICULUMMAPDETAILS_LIST_FAIL'

