import axios from "axios";
import Constants from "../../resource/Constants";
import { EBOOKCONTENT_LIST_REQUEST, EBOOKCONTENT_LIST_SUCCESS, EBOOKCONTENT_LIST_FAIL } from "../constants/ebookContentConstants";
import { EBOOKPOST_LIST_REQUEST, EBOOKPOST_LIST_SUCCESS, EBOOKPOST_LIST_FAIL } from "../constants/ebookContentConstants";


const listEbookContentGet = (id) => async (dispatch) => {
    try {

        console.log("id at actions", id)


        dispatch({ type: EBOOKCONTENT_LIST_REQUEST })

        //        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/book-cont/?book_id=${id}`)
        const { data } = await axios.get(Constants.Application.PARTNER_END_URL + `/cont-prov-cont/?book_id=${id}`)
        console.log("data in list subjects  get ebook at listebookcontent ", data)
        dispatch({ type: EBOOKCONTENT_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: EBOOKCONTENT_LIST_FAIL, payload: error.message })

    }
}



const listEbookContentPost = (body) => async (dispatch) => {
    try {

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };
        let url = Constants.Application.PARTNER_END_URL;
        url += "/save-pub-cont-sub/"

        const options = {
            method: "POST",
            data: body,
            url,
            headers,
        }

        dispatch({ type: EBOOKPOST_LIST_REQUEST })

        const { data } = await axios(options);
        console.log("post data respone at listebook content ", data)
        dispatch({ type: EBOOKPOST_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: EBOOKPOST_LIST_FAIL, payload: error.message })

    }
}

export { listEbookContentGet, listEbookContentPost }
